import React from "react";
import styled from "styled-components";
import { format, parseISO } from "date-fns";
import ja from "date-fns/locale/ja";

const TblCellDatetime = styled.td`
  text-align: left;
  padding: 5px 10px;
  border-left: 1px solid #cccccc;
  white-space: pre-wrap;
  word-break: break-word;
  width: 170px;
`;

const HospitalPaymentRowDatetime = (props) => {
  const { appo } = props;

  return (
    <TblCellDatetime>
      {format(parseISO(appo.from), "yyyy/MM/dd(iii)", {
        locale: ja,
      })}
      <br />
      {format(parseISO(appo.from), "HH:mm")}～
      {format(parseISO(appo.to), "HH:mm")}
    </TblCellDatetime>
  );
};

export default HospitalPaymentRowDatetime;
