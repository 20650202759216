import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { DELIVERY_METHOD } from "../../../Utils/const";
import { getPhoneNumberWithHyphen } from "../../../Utils/DisplayLogic";
import { AddressContent } from "../../molecules/address/addressContent";

interface Props {
  contents: Contents;
}

interface Contents {
  medicineDelivery?: MedicineDelivery;
  patient?: Patient;
}

interface Patient {
  familyName: string;
  givenName: string;
  address: Address;
  phoneNumber: string;
}

interface Address {
  prefectureCode: string;
  postalCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
}

interface MedicineDelivery {
  accountStatus: string;
  displaySites: string;
  deliveryMethod: string;
  prefectureCode: string;
  postalCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
  name: string;
  tel: string;
  fax: string;
}

const MedicineDeliveryResource =
  japaneseList.Pages.Medical.medicineDelivery.Typography;

const ACCOUNTSTATUS = {
  ENABLED: "enabled",
};

const TextBolder = styled.div`
  font-weight: 600;
  font-family: Hiragino Kaku Gothic ProN;
  margin-bottom: 10px;
`;

const TextSmall = styled.div`
  word-break: break-word;
  white-space: pre-wrap;
  font-size: 12px;
  width: 250px;
`;

const BodyMargin = styled.div`
  margin: 20px 0;
`;

const Prescription = styled.div`
  font-size: 12px;
  margin-top: 10px;
  display: flex;
`;

const ColumnName = styled.div`
  width: 60px;
`;

const PharmacyText = styled.div`
  width: 170px;
`;

const PatientText = styled.div`
  width: 170px;
`;

const PharmacyWarning = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: #ef5e5e;
  width: 250px;
`;

const formatPostalCode = (postalCode: string) => {
  return postalCode && `${postalCode.slice(0, 3)}-${postalCode.slice(3, 7)}`;
};

export const MedicineDeliveryDetail = (props: Props) => {
  const { contents } = props;
  const { medicineDelivery, patient } = contents;

  return (
    <React.Fragment>
      {medicineDelivery ? (
        <React.Fragment>
          <TextSmall>{MedicineDeliveryResource.j001}</TextSmall>
          <BodyMargin>
            <TextBolder>{MedicineDeliveryResource.j002}</TextBolder>
            <TextSmall>
              {medicineDelivery.deliveryMethod === DELIVERY_METHOD.DELIVERY &&
                MedicineDeliveryResource.j011}
              {medicineDelivery.deliveryMethod ===
                DELIVERY_METHOD.GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME &&
                MedicineDeliveryResource.j012}
              {medicineDelivery.deliveryMethod === DELIVERY_METHOD.NONE &&
                MedicineDeliveryResource.j010}
            </TextSmall>
          </BodyMargin>
          <React.Fragment>
            <TextBolder>{MedicineDeliveryResource.j013}</TextBolder>
            {medicineDelivery.deliveryMethod === DELIVERY_METHOD.DELIVERY && (
              <React.Fragment>
                {medicineDelivery.accountStatus !== ACCOUNTSTATUS.ENABLED && (
                  <PharmacyWarning>
                    {MedicineDeliveryResource.j014}
                  </PharmacyWarning>
                )}
                <Prescription>
                  <ColumnName>{MedicineDeliveryResource.j003}</ColumnName>
                  <PharmacyText>{medicineDelivery.name}</PharmacyText>
                </Prescription>
                <Prescription>
                  <ColumnName>{MedicineDeliveryResource.j004}</ColumnName>
                  {formatPostalCode(medicineDelivery.postalCode)}
                </Prescription>
                <Prescription>
                  <ColumnName>{MedicineDeliveryResource.j005}</ColumnName>
                  <PharmacyText>
                    <AddressContent addressInfo={medicineDelivery} />
                  </PharmacyText>
                </Prescription>
                <Prescription>
                  <ColumnName>{MedicineDeliveryResource.j006}</ColumnName>
                  {medicineDelivery.tel}
                </Prescription>
                <Prescription>
                  <ColumnName>{MedicineDeliveryResource.j007}</ColumnName>
                  {medicineDelivery.fax}
                </Prescription>
              </React.Fragment>
            )}
            {medicineDelivery.deliveryMethod ===
              DELIVERY_METHOD.GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME && (
              <React.Fragment>
                {patient && (
                  <React.Fragment>
                    <Prescription>
                      <ColumnName>{MedicineDeliveryResource.j008}</ColumnName>
                      <PatientText>{`${patient && patient.familyName} ${
                        patient.givenName
                      }`}</PatientText>
                    </Prescription>
                    <Prescription>
                      <ColumnName>{MedicineDeliveryResource.j004}</ColumnName>
                      {formatPostalCode(patient.address.postalCode)}
                    </Prescription>
                    <Prescription>
                      <ColumnName>{MedicineDeliveryResource.j005}</ColumnName>
                      <PatientText>
                        <AddressContent addressInfo={patient.address} />
                      </PatientText>
                    </Prescription>
                    <Prescription>
                      <ColumnName>{MedicineDeliveryResource.j006}</ColumnName>
                      {getPhoneNumberWithHyphen(patient.phoneNumber)}
                    </Prescription>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <TextSmall>
              {medicineDelivery.deliveryMethod === DELIVERY_METHOD.NONE &&
                MedicineDeliveryResource.j010}
            </TextSmall>
          </React.Fragment>
        </React.Fragment>
      ) : (
        <TextSmall>{MedicineDeliveryResource.j009}</TextSmall>
      )}
    </React.Fragment>
  );
};
