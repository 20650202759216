import React from "react";
import styled from "styled-components";
import BrowserInfoPanel from "../molecules/signin/BrowserInfoPanel";
import { Footer } from "../organisms/footers/Footer";
import { TitleTag } from "../atoms/others/TitleTag";
import { japaneseList } from "../../Resources/japaneseList";
import { isLnln } from "../../Utils/checkLnln";
import { Favicon } from "../atoms/others/Favicon";
import Titles from "../../Resources/Titles";

const Root = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Grid = styled.div`
  margin: 0 auto;
  width: 507px;
  height: 726px;

  display: grid;
  grid-template-columns: 507px;
  grid-template-rows: 158px 435px;
`;

const TopBlankArea = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  text-align: center;
`;

const LiftSide = styled.div`
  margin: 0 42px;
`;

const FooterWrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 3 / 4;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;


const SignInTemplate = ({ signInForm,title }) => {
  const serviceName = japaneseList.service.name;
  const siteTypeName = Titles.siteTypeName;
  const _title = title ? siteTypeName + serviceName + " | " + title : serviceName;
  const servicePath = isLnln() ? "lnln" : "carada";
  return (
    <Root>
      <Grid>
        <Favicon servicePath={servicePath} />
        <TitleTag title={_title} />
        <TopBlankArea />
        <LiftSide>
          <BrowserInfoPanel />
          <div>{signInForm}</div>
        </LiftSide>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </Grid>
    </Root>
  );
}

export default SignInTemplate;
