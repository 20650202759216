import gql from "graphql-tag";

export const CreateAppointment = gql`
  mutation CreateAppointment(
    $patientId: ID!
    $from: ID!
    $to: String!
    $menu: InputMenu!
    $medicalDoctorId: String!
  ) {
    createAppointment(
      patientId: $patientId
      from: $from
      to: $to
      menu: $menu
      doctorId: $medicalDoctorId
    ) {
      hospitalId
      createTime
      from
      to
      patient {
        patientId
        familyName
        givenName
        familyNameKana
        givenNameKana
        birthdate
        phoneNumber
        cardStatus
      }
    }
  }
`;
