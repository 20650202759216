import styled from "styled-components";

const Radio = styled.input.attrs({type: 'radio'})`
  width:20px;
  height:20px;
  margin:0;
  padding:0;
  box-sizing:border-box;
  background-color:${p => p.theme.color.fontInvert};
  box-shadow: 0 0 0 3px #fff inset;
  border: 1px solid ${p => p.theme.color.fontVariant};
  border-radius: 10px;
  cursor:pointer;
  -webkit-appearance: none;
  outline:0;
  &:checked{
    background-color:${p => p.theme.color.primary};
    border: 1px solid ${p => p.theme.color.primary};
  }
`;

export default Radio;
