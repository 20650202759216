import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import AssignmentTurnedInOutlined from "@material-ui/icons/AssignmentTurnedInOutlined";
import { japaneseList } from "../../../Resources/japaneseList";
import TooltipBase from "./TooltipBase";
import UnapprovedWarning from "./UnapprovedWarning";
import ImportantNoticeContext from "../../../contexts/ImportantNoticeContext";

const UnapprovedTooltip = (props) => {
  const history = useHistory();
  const importantNoticeContext = useContext(ImportantNoticeContext);

  const Resource = japaneseList.Components.Header;
  const pagePath = "/appointment/unapproved";
  const title = Resource.render.AppBar.Toolbar.div.Tooltip.unapproved;
  const existsUnapproved = props.numberOfUnapprovedAppointment > 0;

  return (
    <React.Fragment>
      <TooltipBase
        title={title}
        onClick={() => {
          history.push(pagePath);
          importantNoticeContext.refreshImportantNotice &&
            importantNoticeContext.refreshImportantNotice();
        }}
        Icon={AssignmentTurnedInOutlined}
        isUnderline={history.location.pathname === pagePath}
        isBadge={existsUnapproved}
      />
      {existsUnapproved && <UnapprovedWarning />}
    </React.Fragment>
  );
};

export default UnapprovedTooltip;
