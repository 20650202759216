// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Divider,
  Typography,
} from "@material-ui/core";
import AccountImage from "../../../../images/account_circle-24px.svg";
import { format } from "date-fns";
import LinkA from "../../../atoms/links/LinkA";

// personal modules
// --------------------------------------------------
import FontSpan from "../../../atoms/fonts/FontSpan";
import { japaneseList } from "../../../../Resources/japaneseList";
import { getAge } from "../../../../Utils/DisplayLogic";
import CurrencyText from "../../payment/CurrencyText";
import NumberFormat from "react-number-format";
import {
  PAYMENT_METHOD,
  TREATMENT_COMPLETED_SELECTED_OPTION,
} from "../../../../Utils/const";
const Resources = japaneseList.Components.Appointment.StatusChangeDialog;

const completeSelect = {
  [TREATMENT_COMPLETED_SELECTED_OPTION.VISITED]: Resources.treatmentComplete,
  [TREATMENT_COMPLETED_SELECTED_OPTION.NOT_VISITED]: Resources.notVisit,
};
const maleGender = Resources.genderMale;

// style
// --------------------------------------------------
const styles = (theme) => ({
  dialogTitleText: {
    fontSize: "20px",
    lineHeight: "0px",
  },

  dialogTitle: {
    padding: "24px",
  },

  dialogContent: {
    padding: "20px 25px 24px 25px",
  },

  dialogActions: {
    padding: "14px 24px 16px 24px",
    margin: "0px",
  },

  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },

  buttonOk: {
    color: theme.button.ok.color,
    width: "120px",
    height: "40px",
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    borderRadius: "6px",
    opacity: "1",
    margin: "0px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    fontWeight: "bold",
  },

  buttonCancel: {
    backgroundColor: "#808080",
    color: "white",
    width: "120px",
    height: "40px",
    background: "#808080 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 0px #505050",
    borderRadius: "6px",
    opacity: "1",
    margin: "0px 20px 0px 0px",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
    fontWeight: "bold",
  },

  containerTable: {
    display: "flex",
    boxSizing: "border-box",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "space-between",
    margin: "8px 0 25px 0",
  },

  tableStyle: {
    borderCollapse: "collapse",
    width: "310px",
    tableLayout: "auto",
  },
  thBirthDay: {
    width: "98px",
  },
  thAge: {
    width: "26px",
  },
  thGender: {
    width: "48px",
  },
  thPhonenumber: {
    width: "120px",
  },

  thStyle: {
    textAlign: "center",
    borderTop: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    backgroundColor: "#eee",
    fontSize: "12px",
    color: "#808080",
  },

  tdStyle: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    color: "#393939",
    whiteSpace: "nowrap",
  },

  tdStyleGenderMale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },

  tdStyleGenderFemale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },

  containerBorder: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
  },

  container: {
    display: "grid",
    gridTemplateColumns: "80px auto",
    gridGap: "20px",
    borderTop: "#ccc solid 1px",
  },

  titleItem: {
    backgroundColor: "#eee",
    padding: "8px 10px 8px 10px",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    color: "#808080",
    fontWeight: "bold",
    textAlign: "left",
    whiteSpace: "pre-wrap",
  },

  drugsNameTitleItem: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },

  contentItem: {
    padding: "10px 20px 10px 0px",
    minHeight: "41px",
    lineHeight: "21px",
    fontSize: "14px",
    color: "#393939",
    textAlign: "left",
    marginTop: "auto",
    marginBottom: "auto",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },

  linkAStyle: {
    color: "#2563C0",
    textDecoration: "underline",
    fontSize: "14px",
    "&:hover": {
      textDecoration: "none",
    },
  },

  maxHeightWidthDialog: {
    maxHeight: "96%",
    maxWidth: "810px",
  },

  nameStyle: {
    fontWeight: "bold",
    fontSize: "16px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },

  nameKanaStyle: {
    fontSize: "12px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },

  medicalFeeNote: {
    marginLeft: "15px",
    fontFamily: "Hiragino Kaku Gothic ProN",
    fontSize: "12px",
    textAlign: "left",
    letterSpacing: "0px",
    color: "#808080",
    opacity: "1",
  },

  messageConfirm: {
    fontSize: "14px",
    marginTop: "25px",
    marginBottom: "15px",
  },
});

// class define
// --------------------------------------------------
class ChangeStatusDialogConfirm extends Component {
  // default state
  // --------------------------------------------------
  static defaultProps = {
    open: false,
    onClose: function () {},
    maxWidth: "md",
    buttonOkText: Resources.buttonOk,
    handleClickOk: function () {},
    buttonCancelText: Resources.buttonCancel,
    handleClickCancel: function () {},
  };

  // constructor
  // --------------------------------------------------
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCancelButton = () => {
    this.props.onClose();
  };

  // render
  // --------------------------------------------------
  render() {
    const { appointment, classes, open } = this.props;
    const patient = appointment.patient;
    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;
    return (
      <Dialog
        open={open}
        maxWidth={false}
        fullWidth={true}
        PaperProps={{ classes: { root: classes.maxHeightWidthDialog } }}
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          <FontSpan weight="bold" className={classes.dialogTitleText}>
            {Resources.DialogTitle}
          </FontSpan>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          {patient && (
            <Grid
              container
              wrap="nowrap"
              style={{ padding: "0px", margin: "0px" }}
            >
              <Grid item style={{ padding: "0px 10px 0px 0px" }}>
                <img src={AccountImage} alt={""} />
              </Grid>
              <Grid item xs style={{ padding: "0px" }}>
                <Typography className={classes.nameStyle}>
                  {patient.familyName} {patient.givenName}
                </Typography>
                <Typography className={classes.nameKanaStyle}>
                  {patient.familyNameKana} {patient.givenNameKana}
                </Typography>
              </Grid>
            </Grid>
          )}
          {patient && (
            <div className={classes.containerTable}>
              <table className={classes.tableStyle}>
                <thead>
                  <tr>
                    <th className={classes.thStyle + " " + classes.thBirthDay}>
                      {Resources.DialogContent.table.thBirthDay}
                    </th>
                    <th className={classes.thStyle + " " + classes.thAge}>
                      {Resources.DialogContent.table.thAge}
                    </th>
                    <th className={classes.thStyle + " " + classes.thGender}>
                      {Resources.DialogContent.table.thGender}
                    </th>
                    <th
                      className={classes.thStyle + " " + classes.thPhonenumber}
                    >
                      {Resources.DialogContent.table.thPhonenumber}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={classes.tdStyle}>
                      {format(new Date(patient.birthdate), "yyyy/MM/dd")}
                    </td>
                    <td className={classes.tdStyle}>
                      {patient.birthdate ? getAge(patient.birthdate) : " "}
                    </td>
                    <td
                      className={
                        patient.gender && patient.gender === maleGender
                          ? classes.tdStyleGenderMale
                          : classes.tdStyleGenderFemale
                      }
                    >
                      {patient.gender}
                    </td>
                    <td className={classes.tdStyle}>
                      <NumberFormat
                        value={patient.phoneNumber}
                        displayType={"text"}
                        format="###-####-####"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <p className={classes.messageConfirm}>
            {Resources.DialogContent.messageConfirm}
          </p>
          <div className={classes.containerBorder}>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resources.DialogContent.containerBorder.status}
              </div>
              <div className={classes.contentItem}>
                {completeSelect[this.props.treatmentInfor.completeSelectOption]}
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resources.DialogContent.containerBorder.treatmentDay}
              </div>
              <div className={classes.contentItem}>
                {this.props.treatmentInfor.treatmentDate}
              </div>
            </div>
            {!isBillingEnterprise && (
              <React.Fragment>
                {patient && patient.organizationId && (
                  <div className={classes.container}>
                    <div className={classes.titleItem}>
                      {Resources.DialogContent.containerBorder.medicalFee}
                    </div>
                    <div className={classes.contentItem}>
                      {Number(
                        this.props.treatmentInfor.completeSelectOption
                      ) === TREATMENT_COMPLETED_SELECTED_OPTION.NOT_VISITED ? (
                        ""
                      ) : (
                        <React.Fragment>
                          <CurrencyText
                            value={this.props.treatmentInfor.medicalFee}
                          />
                          <span className={classes.medicalFeeNote}>
                            {
                              Resources.DialogContent.containerBorder
                                .medicalFeeNoteConfirm
                            }
                          </span>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                )}
                {this.props.treatmentInfor.requireDrugName && (
                  <div className={classes.container}>
                    <div className={classes.titleItem}>
                      {Resources.DialogContent.containerBorder.drugName}
                    </div>
                    <div className={classes.contentItem}>
                      {this.props.treatmentInfor.drugName &&
                        this.props.treatmentInfor.drugName.trim()}
                    </div>
                  </div>
                )}
                {this.props.treatmentInfor.requireDrugQuantity && (
                  <div className={classes.container}>
                    <div className={classes.titleItem}>
                      {Resources.DialogContent.containerBorder.drugQuantity}
                    </div>
                    <div className={classes.contentItem}>
                      {this.props.treatmentInfor.drugQuantity &&
                        this.props.treatmentInfor.drugQuantity.trim()}
                    </div>
                  </div>
                )}
                {patient && patient.organizationId && (
                  <div className={classes.container}>
                    <div className={classes.titleItem}>
                      <div>{Resources.DialogContent.containerBorder.memo}</div>
                    </div>
                    <div className={classes.contentItem}>
                      {this.props.treatmentInfor.memo &&
                        this.props.treatmentInfor.memo.trim()}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
            {isBillingEnterprise && (
              <React.Fragment>
                <div className={classes.container}>
                  <div className={classes.titleItem}>
                    {Resources.DialogContent.containerBorder.billingCategory}
                  </div>
                  <div className={classes.contentItem}>
                    {
                      Resources.DialogContent.containerBorder
                        .billingCategoryOption[
                        this.props.treatmentInfor.billingCategory
                      ]
                    }
                  </div>
                </div>
                <div className={classes.container}>
                  <div className={classes.titleItem}>
                    {Resources.DialogContent.containerBorder.drugNameEWP}
                  </div>
                  <div className={classes.contentItem}>
                    {this.props.treatmentInfor.drugName &&
                      this.props.treatmentInfor.drugName.trim()}
                  </div>
                </div>
                <div className={classes.container}>
                  <div
                    className={`${classes.titleItem} ${classes.drugsNameTitleItem}`}
                  >
                    {Resources.DialogContent.containerBorder.drugQuantity}
                  </div>
                  <div className={classes.contentItem}>
                    {this.props.treatmentInfor.drugQuantity &&
                      this.props.treatmentInfor.drugQuantity.trim()}
                  </div>
                </div>
                <div className={classes.container}>
                  <div className={classes.titleItem}>
                    <div>{Resources.DialogContent.containerBorder.memo}</div>
                  </div>
                  <div className={classes.contentItem}>
                    {this.props.treatmentInfor.memo &&
                      this.props.treatmentInfor.memo.trim()}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </DialogContent>
        <Divider />
        <DialogActions className={classes.dialogActions}>
          <div className={classes.flexContainer}>
            <div>
              <LinkA
                className={classes.linkAStyle}
                onClick={this.props.handleBackButton}
              >
                {Resources.DialogActions.backButton}
              </LinkA>
            </div>
            <div>
              <Button
                className={classes.buttonCancel}
                onClick={this.handleCancelButton}
              >
                {Resources.DialogActions.cancelButton}
              </Button>
              <Button
                className={classes.buttonOk}
                onClick={this.props.handleClickButton}
              >
                {Resources.DialogActions.confirmButtonDialogConfirm}
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ChangeStatusDialogConfirm);
