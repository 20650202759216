import React, { Component } from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";

const Resource = japaneseList.Utils.Enum.AppointmentStatus;

const SubText = styled.div`
  fontsize: 12px;
  line-height: initial;
`;

function getStatusTexts(status, medicalMethod) {
  switch (status) {
    case "rejected":
      return [Resource.rejected.j001];
    case "canceledBeforeApproving":
      return [
        Resource.canceledBeforeApproving.j001,
        Resource.canceledBeforeApproving.j002,
      ];
    case "unapproved":
      return [Resource.unapproved.j001];
    case "beforeExam":
      return medicalMethod === "online"
        ? [Resource.beforeExam.j002]
        : [Resource.beforeExam.j001];
    case "examCompleted":
      return medicalMethod === "online"
        ? [Resource.examCompleted.j002]
        : [Resource.examCompleted.j001];
    case "paymentCompleted":
      return [Resource.paymentCompleted.j001];
    case "canceledDoctor":
      return [Resource.canceledDoctor.j001, Resource.canceledDoctor.j002];
    case "canceledPatientPreviousDay":
      return [
        Resource.canceledPatientPreviousDay.j001,
        Resource.canceledPatientPreviousDay.j002,
      ];
    case "canceledPatientFromToday":
      return [
        Resource.canceledPatientFromToday.j001,
        Resource.canceledPatientFromToday.j002,
      ];
    default:
      return [""];
  }
}

class AppointmentStatus extends Component {
  render() {
    const { appointment, menu } = this.props;
    const [mainText, subText] = getStatusTexts(
      appointment.status,
      menu && menu.medicalMethod
    );

    return (
      <React.Fragment>
        {mainText}
        {subText ? <SubText>{subText}</SubText> : ""}
      </React.Fragment>
    );
  }
}

export default AppointmentStatus;
