import styled from "styled-components";

const Heading1 = styled.h1`
  padding: 0;
  margin: 0 0 30px 0;
  font-size: 20px;
  color: ${(p) => p.theme.heading.color.default};
  :before {
    content: " ";
    background-color: ${(p) => p.theme.heading.bg.default};
    width: 10px;
    height: 24px;
    border-radius: 5px;
    margin: 0 10px 3px 0;
    display: inline-block;
    vertical-align: middle;
  }
`;

export default Heading1;
