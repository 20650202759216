import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { client, GenerateGetInsuranceCardPresignedUrl } from "../../../graphql";
import FontP from "../../atoms/fonts/FontP";
import FontPBold from "../../atoms/fonts/FontPBold";
import DialogInsuranceCardConfirm from "../../molecules/dialogs/DialogInsuranceCardConfirm";
import ZoomIn from "../../../images/icon/zoom_in-24px.svg";

const InsuranceCardAreaResource = japaneseList.Pages.Patient.InsuranceCardArea;

const Title = styled(FontPBold)`
  font-size: 14px;
  margin-bottom: 10px;
`;

const ShowImg = styled.div`
  background-color: white;
  transition-duration: 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  box-shadow: 1px 1px #0000001a;
  width: 212px;
  height: 212px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ShowMessage = styled.div`
  background-color: #eeeeee;
  height: calc(100% - 32px);
  padding: 14px;
`;

const MessageText = styled(FontP)`
  font-size: 14px;
  color: #808080;
`;

const Img = styled.img`
  position: absolute;
  top: 6px;
  right: 6px;
`;

export const InsuranceCardArea = ({ patient, setIsInsuranceCardLoading }) => {
  const { insuranceCard } = patient;
  const [insuranceCardFilePath, setInsuranceCardFilePath] = useState(null);
  const [isOpenInsuranceCardConfirmDialog, setIsOpenIsuranceConfirmDialog] =
    useState(false);

  useEffect(() => {
    if (insuranceCard) {
      loadInsuranceCard(insuranceCard.filePath);
    } else {
      setIsInsuranceCardLoading(false);
    }
  }, [insuranceCard]);

  const loadInsuranceCard = async (filePath) => {
    await client
      .mutate({
        mutation: GenerateGetInsuranceCardPresignedUrl,
        variables: {
          filePath,
        },
      })
      .then((res) => {
        if (res.data.generateGetInsuranceCardPresignedUrl.getSignedUrl) {
          setInsuranceCardFilePath(
            res.data.generateGetInsuranceCardPresignedUrl.getSignedUrl
          );
        }
      });
    setIsInsuranceCardLoading(false);
  };

  const openInsuranceCardConfirmDialog = () => {
    setIsOpenIsuranceConfirmDialog(true);
  };

  const closeInsuranceCardConfirmDialog = () => {
    setIsOpenIsuranceConfirmDialog(false);
  };

  return (
    <React.Fragment>
      <Title>{InsuranceCardAreaResource.j001}</Title>
      {insuranceCardFilePath ? (
        <ShowImg onClick={openInsuranceCardConfirmDialog}>
          <Img src={ZoomIn} />
          <CustomImg src={insuranceCardFilePath} />
        </ShowImg>
      ) : (
        <ShowMessage>
          <MessageText>{InsuranceCardAreaResource.j002}</MessageText>
        </ShowMessage>
      )}
      {insuranceCardFilePath && (
        <DialogInsuranceCardConfirm
          open={isOpenInsuranceCardConfirmDialog}
          filePath={insuranceCardFilePath}
          fileName={insuranceCard.fileName}
          onClose={closeInsuranceCardConfirmDialog}
          handleClickButton={closeInsuranceCardConfirmDialog}
        />
      )}
    </React.Fragment>
  );
};
