import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { NativeSelect } from "@material-ui/core/";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

interface ICustomNativeSelect {
  isValidateError?: boolean;
}
const CustomNativeSelect = styled(NativeSelect)<ICustomNativeSelect>`
  width: 194px;
  line-height: 32px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  background: 0% 0% no-repeat padding-box
    ${(p) =>
      p.disabled
        ? "#eeeeee"
        : p.isValidateError
        ? "#FFF1F2"
        : p.theme.color.primaryVariant3};
  margin: 2px 0;
  select {
    width: calc(100% - 10px);
    padding: 0 0 0 10px;
    height: 32px;
    font-size: 14px;
    color: ${(p) => (p.value ? "" : p.theme.color.fontVariant)};
    &:focus {
      background: 0% 0% no-repeat padding-box
        ${(p) => p.theme.color.primaryVariant3};
    }
    &:disabled {
      color: ${(p) => (p.value ? p.theme.color.font : "")};
    }
  }
  &:hover svg {
    background-color: ${(p) =>
      p.disabled ? "" : p.theme.color.primaryVariant};
  }
`;

// ToDo: Migrate custom theme to d.ts file
const ArrowDropDownIcon = withStyles((theme: any) => ({
  root: {
    backgroundColor: theme.button.ok.backgroundColor,
    width: "32px",
    height: "32px",
    color: "white",
    background: `${theme.color.primary} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 1px 0px ${theme.color.shadow}`,
    borderRadius: "0px 3px 3px 0px",
    marginTop: "-4px",
  },
}))(ArrowDropDown);

const CustomOption = styled.option`
  color: ${(p) => p.theme.color.font};
`;

interface Option {
  optionName: string;
  optionValue: string;
}

interface DialogSelectProps {
  selectedValue: string;
  onChange: () => void;
  disabled: boolean;
  isValidateError?: boolean;
  defaultOptionName: string;
  optionList: Option[];
}

export const DialogSelect = (props: DialogSelectProps) => {
  return (
    <CustomNativeSelect
      disableUnderline
      IconComponent={ArrowDropDownIcon}
      value={props.selectedValue}
      onChange={props.onChange}
      disabled={props.disabled}
      isValidateError={props.isValidateError}
    >
      <CustomOption value={""}>{props.defaultOptionName}</CustomOption>
      {props.optionList.map((option) => (
        <CustomOption value={option.optionValue} key={option.optionValue}>
          {option.optionName}
        </CustomOption>
      ))}
    </CustomNativeSelect>
  );
};
