import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Button, TextField, withStyles } from "@material-ui/core";
import { japaneseList } from "../../../Resources/japaneseList";

const ForgotPasswordResource = japaneseList.Authenticator.ForgotPassword;

/** Specify background color for set forgot password page only.  */
const GlobalStyle = createGlobalStyle`
  body {
    background-color: #ffffff !important;
  }
`;

// TODO: remove MaterialUI Components
const styles = (theme) => {
  return {
    input: {
      backgroundColor: theme.color.primaryVariant3,
      border: `1px solid #DDDDDD`,
      borderRadius: "3px",
      padding: "1px",
    },
    label: {
      paddingBottom: "5px",
      color: "#808080",
      fontWeight: "bold",
      fontFamily: "inherit",
      fontSize: "12px",
      transform: "scale(1)",
    },
    inputPropsStyle: {
      height: "14px",
      padding: "10px",
      WebkitBoxShadow: `0 0 0 30px ${theme.color.primaryVariant3} inset !important`,
      backgroundClip: "content-box",
    },
  };
};

const ContentWrapper = styled.div`
  display: inline;
  max-width: 360px;
  text-align: center;
`;

const Title = styled.div`
  margin-top: 160px;
  font-size: 20px;
  font-weight: bold;
  font-family: inherit;
  color: #333333;
`;

const LinkToHomeWrapper = styled.div`
  margin-top: 10px;
  cursor: pointer;
`;

const LinkToHome = styled.a`
  font-size: 12px;
  color: #2563C0;
  text-decoration-line: underline;
`;

const CautionTextWrapper = styled.div`
  text-align: left;
  margin-top: 20px;
`;

const CautionTextBig = styled.p`
  font-size: 14px;
  color: #EF5E5E;
`;

const CautionTextSmall = styled.p`
  font-size: 12px;
  color: #EF5E5E;
`;

const ForgotPasswordBeforeSendAuthCode = (props) => {
  const {
    classes,
    setEmail,
    isEnabledAuthCodeSubmitButton,
    setIsEnabledAuthCodeSubmitButton,
    requestAuthCode,
    returnToLoginPage,
  } = props;

  const handleInputChangeEmail = (e) => {
    setEmail(e.target.value);
    const currentEmail = e.target.value;
    if (currentEmail) {
      setIsEnabledAuthCodeSubmitButton(true);
    } else {
      setIsEnabledAuthCodeSubmitButton(false);
    }
  };

  return (
    <React.Fragment>
      <ContentWrapper>
        <Title>
          {ForgotPasswordResource.render.Grid.Grid.Typography.j005}
        </Title>
        <TextField
          style={{
            display: "block",
            marginTop: '32px',
          }}
          fullWidth
          label={ForgotPasswordResource.render.Grid.Grid.TextField.j001}
          name="email"
          onChange={(e) => handleInputChangeEmail(e)}
          // Properties applied to the Input element
          InputProps={{
            disableUnderline: true,
            className: classes.input,
          }}
          InputLabelProps={{
            shrink: true,
            className: classes.label,
            focused: false,
          }}
          // Attributes applied to the native input element
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{ className: classes.inputPropsStyle }}
        />
        <Button
          style={{
            display: "block",
            marginTop: '30px',
          }}
          variant="raised"
          color="primary"
          fullWidth
          onClick={() => requestAuthCode()}
          disabled={!isEnabledAuthCodeSubmitButton}
        >
          {ForgotPasswordResource.render.Grid.Grid.Button.j001}
        </Button>
        <LinkToHomeWrapper onClick={() => returnToLoginPage()} >
          <LinkToHome>
            {ForgotPasswordResource.render.Grid.Grid.Button.Typography.j001}
          </LinkToHome>
        </LinkToHomeWrapper>
        <CautionTextWrapper>
          <CautionTextBig>
            {ForgotPasswordResource.render.Grid.Grid.Typography.j002}
          </CautionTextBig>
          <CautionTextSmall>
            {ForgotPasswordResource.render.Grid.Grid.Typography.j003}
          </CautionTextSmall>
          <CautionTextSmall>
            {ForgotPasswordResource.render.Grid.Grid.Typography.j004}
          </CautionTextSmall>
        </CautionTextWrapper>
      </ContentWrapper>
      <GlobalStyle />
    </React.Fragment>
  )
}

export default withStyles(styles)(ForgotPasswordBeforeSendAuthCode);
