import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { japaneseList } from "../../../Resources/japaneseList";
import iconBlockPath from "../../../images/error_outline-24px.svg";
import clearPath from "../../../images/clear-24px.svg";

const unsupportedDeviceAlert = document.getElementById("Alerts");
const UnsupportedDeviceAlertMessage = japaneseList.Utils.UnsupportedDeviceAlert;

const Alert = styled.div`
  width: 320px;
  background: #393939 0% 0% no-repeat padding-box;
  opacity: 0.8;
  padding-bottom: 10px;
  margin-top: 20px;
`;
const Head = styled.div`
  margin-top: 14px;
  margin-left: 14px;
  height: 20px;
  width: 250 px;
  opacity: 1;
  float: left;
`;

const Info = styled.div`
  margin-right: 4px;
  width: 20px;
  height: 20px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;

  float: left;
`;
const Heading = styled.div`
  margin-top: 2px;
  left: 712px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font: W6 12px/21px Hiragino Kaku Gothic ProN;
  font-size: 12px;
  float: left;
  vertical-align: middle;
`;
const Clear = styled.div`
  margin-top: 8px;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  float: right;
  cursor: pointer;
`;
const Content = styled.div`
  margin: 10px 14px 0px;
  line-height: 18px;
  text-align: left;
  letter-spacing: 0px;
  font-size: 12px;
  color: #ffffff;
  opacity: 1;
  display: inline-block;
`;

const LinkVo = styled.div`
  margin: 10px 14px 4px 198px;
  height: 15px;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
`;

const UnsupportedDeviceAlertContent = (props) => {
  return (
    <Alert>
      <Head>
        <Info>
          <img src={iconBlockPath} alt={''}></img>
        </Info>
        <Heading>{UnsupportedDeviceAlertMessage.j001}</Heading>
      </Head>
      <Clear onClick={props.handleClose}>
        <img src={clearPath} alt={''}></img>
      </Clear>
      <Content>{props.unsupportedDeviceAlertMessage}</Content>
      <LinkVo
        onClick={() => {
          props.router.history.push("/contact");
        }}
      >
        {UnsupportedDeviceAlertMessage.j003}
      </LinkVo>
    </Alert>
  );
};

class UnsupportedDeviceAlert extends Component {
  constructor(props) {
    super(props);
    this.container = document.createElement("div");

    this.state = {
      isShowUnsupportedDeviceAlert: true,
      unsupportedDeviceAlertMessage: UnsupportedDeviceAlertMessage.j002,
    };
  }

  componentDidMount() {
    unsupportedDeviceAlert.appendChild(this.container);
    this.unregisterHistoryCallback = this.props.history.listen(() => {
      this.setState({ isShowUnsupportedDeviceAlert: true });
    });
  }

  componentWillUnmount() {
    unsupportedDeviceAlert.removeChild(this.container);
  }

  close = () => {
    this.setState({
      isShowUnsupportedDeviceAlert: false,
    });
  };

  render() {
    const {
      isShowUnsupportedDeviceAlert,
      unsupportedDeviceAlertMessage,
    } = this.state;
    const { props, blockStartMessage } = this.props;
    return ReactDOM.createPortal(
      isShowUnsupportedDeviceAlert && (
        <UnsupportedDeviceAlertContent
          {...props}
          unsupportedDeviceAlertMessage={
            blockStartMessage
              ? blockStartMessage
              : unsupportedDeviceAlertMessage
          }
          handleClose={this.close}
        />
      ),
      this.container
    );
  }
}

export default withRouter(UnsupportedDeviceAlert);
