import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { oneYearLimit } from "../../../Utils/DateUtil";
import { addMonths, startOfDay } from "date-fns";
import Button from "@material-ui/core/Button";
import { FLOW } from "../appointment/Stepper/Constants";
import { isLnln } from "../../../Utils/checkLnln";

const styles = (theme) => ({
  label: {
    fontWeight: "bolder",
    margin: "0 40px",
  },
  root: {
    justifyContent: "space-between",
  },
  nav: {
    marginLeft: "60px",
    display: "flex",
    justifyContent: "space-between",
  },
  navLink: {
    "&:hover": {
      cursor: "pointer",
    },
    textDecoration: "underline",
    marginTop: "2px",
    fontSize: "14px",
    color: "#2563c0",
  },
  buttonAppointment: {
    fontSize: "14px",
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
  buttonSlot: {
    marginLeft: "10px",
    fontSize: "14px",
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
  buttonToday: {
    fontSize: "14px",
    color: "#ffffff",
    fontWeight: "bold",
    position: "absolute",
    marginLeft: "140px",
    borderRadius: "5px",
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
});

const navigate = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
};

const Toolbar = (props) => {
  let { localizer: { messages }, label, view, classes, date } = props;
  const names = ["week", "month"];
  const nextMonth = addMonths(date, 1);
  const isShowNextButton = oneYearLimit({ date: nextMonth, viewType: view });

  const setEvent = (flow) => {
    const today = startOfDay(Date.now());

    const event = {
      start: today,
      end: today,
    };

    props.setActiveStepAndFlow(flow);
    props.onSelectSlot(event);
  };

  return (
    <div className="custom-toolbar">
      <div
        className={
          (isLnln() ? "rbc-toolbarLnln " : "rbc-toolbar ") + classes.root
        }
      >
        <span className="rbc-btn-group">
          {names.map((name) => (
            <button
              type="button"
              key={name}
              className={view === name ? "rbc-active" : null}
              onClick={() => props.onView(name)}
            >
              {messages[name]}
            </button>
          ))}
        </span>
      </div>
      <Button
        onClick={() => props.onNavigate(navigate.TODAY)}
        className={classes.buttonToday}
      >
        {messages.today}
      </Button>

      <span className={classes.nav}>
        <a
          className={classes.navLink}
          onClick={() => props.onNavigate(navigate.PREVIOUS)}
        >
          ＜ {messages.previous}
        </a>
        <span className={classes.label}>{label}</span>
        {isShowNextButton && (
          <a
            className={classes.navLink}
            onClick={() => props.onNavigate(navigate.NEXT)}
          >
            {messages.next} ＞
          </a>
        )}
      </span>

      <span>
        <Button
          variant="raised"
          color="primary"
          className={classes.buttonAppointment}
          onClick={() => setEvent(FLOW.APPOINTMENT)}
        >
          {messages.createAppo}
        </Button>
        <Button
          variant="raised"
          color="primary"
          className={classes.buttonSlot}
          onClick={() => setEvent(FLOW.SLOT)}
        >
          {messages.createSlot}
        </Button>
      </span>
    </div>
  );
};

export default withStyles(styles)(Toolbar);
