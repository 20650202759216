import gql from "graphql-tag";

export const GetAppointmentPatientInsurance = gql`
  query GetAppointmentPatientInsurance($patientId: ID!, $createTime: ID!) {
    appointment(patientId: $patientId, createTime: $createTime) {
      hospitalId
      createTime
      status
      patient {
        patientId
        insuranceCard {
          fileName
          fileType
          filePath
          updatedTime
        }
      }
      menu {
        supportsInsuranceTreatment
      }
    }
  }
`;