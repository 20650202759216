import React from "react";
import BasicTemplate from "../../templates/BasicTemplate";
import NotAccess from '../../organisms/not-access/NotAccess';
import Titles from "../../../Resources/Titles";

export const NotAccessPage = () => {
  return (
    <BasicTemplate
      main={
        <NotAccess />
      }
      title={Titles.notAccess}
    />
  );
};
