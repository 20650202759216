import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Divider,
  Grid,
  Button,
  DialogActions,
  NativeSelect,
} from "@material-ui/core/";
import {
  addDays,
  startOfDay,
  eachDayOfInterval,
  format,
  setHours,
  isAfter,
} from "date-fns";
import AccountImage from "../../../../images/account_circle-24px.svg";
import { ja } from "date-fns/locale";
import Radio from "../../../atoms/forms/Radio";
import { ArrowDropDown } from "@material-ui/icons/";
import { japaneseList } from "../../../../Resources/japaneseList";
import FontSpan from "../../../atoms/fonts/FontSpan";
import FontPError from "../../../atoms/fonts/FontPError";
import {
  getAge,
  getDayTextJaFormatShort,
} from "../../../../Utils/DisplayLogic";
import NumberFormat from "react-number-format";
import { prefectureCodes } from "../../../../Utils/PrefectureCode";
import {
  DELIVERY_OPTION,
  MEDICAL_METHOD,
  PAYMENT_METHOD,
} from "../../../../Utils/const";
import { isValidYamato } from "../../../../Utils/Validation";
import { deliveryFeeCalculator } from "../../../../Utils/DeliveryFeeCalculator";
import YamatoDeliveryFeeContent from "./YamatoDeliveryFeeContent";

const Resource = japaneseList.Components.Payment.YamatoDeliveryDialog;
const DeliveryProductName = japaneseList.models.appointment.deliveryProductName;

const styles = (theme) => ({
  dialogTitle: {
    fontSize: "20px",
    lineHeight: "0px",
  },

  dialogActions: {
    padding: "14px 24px 16px 0px",
    margin: "0px",
  },

  buttonOk: {
    color: theme.button.ok.color,
    width: "120px",
    height: "40px",
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    borderRadius: "6px",
    opacity: "1",
    margin: "0px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    fontWeight: "bold",
  },

  buttonCancel: {
    backgroundColor: "#808080",
    color: "white",
    width: "120px",
    height: "40px",
    background: "#808080 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 0px #505050",
    borderRadius: "6px",
    opacity: "1",
    margin: "0px 20px 0px 0px",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
    fontWeight: "bold",
  },

  buttonDisable: {
    background: "#DDDDDD 0% 0% no-repeat padding-box",
    marginRight: "0px",
    pointerEvents: "none",
    color: "white",
    width: "120px",
    height: "40px",
    borderRadius: "6px",
    opacity: "1",
    margin: "0px",
    "&:hover": {
      background: "#DDDDDD 0% 0% no-repeat padding-box",
    },
    fontWeight: "bold",
  },
  tableStyle: {
    borderCollapse: "collapse",
    width: "310px",
    tableLayout: "auto",
  },
  thBirthDay: {
    width: "98px",
  },
  thAge: {
    width: "26px",
  },
  thGender: {
    width: "48px",
  },
  thPhonenumber: {
    width: "120px",
  },
  thStyle: {
    textAlign: "center",
    borderTop: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    backgroundColor: "#eee",
    fontSize: "12px",
    color: "#808080",
  },
  tdStyle: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    color: "#393939",
    whiteSpace: "nowrap",
  },
  tdStyleGenderMale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  tdStyleGenderFemale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },

  containerTable: {
    display: "flex",
    boxSizing: "border-box",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "space-between",
    margin: "8px 0 0 0",
  },

  containerBorder: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
    marginTop: "20px",
  },

  container: {
    display: "grid",
    gridTemplateColumns: "80px auto",
    gridGap: "20px",
    borderTop: "#ccc solid 1px",
  },

  titleItem: {
    backgroundColor: "#eee",
    padding: "8px 10px 8px 10px",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    color: "#808080",
    fontWeight: "bold",
    textAlign: "left",
    whiteSpace: "pre-wrap",
  },

  description: {
    marginTop: "20px",
    whiteSpace: "pre-wrap",
  },

  contentItem: {
    padding: "10px 20px 10px 0px",
    margin: "0",
    fontSize: "14px",
    color: "#393939",
    textAlign: "left",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },

  contentItemDisplayName: {
    padding: "8px 20px 8px 0px",
    marginTop: "auto",
    marginBottom: "auto",
  },

  radioStyle: {
    verticalAlign: "sub",
    width: "20px",
    height: "20px",
    background: "#EEEEEE 0% 0% no-repeat padding-box",
    backdropFilter: "blur(10px)",
    opacity: 1,
    webkitBackdropFilter: "blur(10px)",
    boxShadow: "0px 0px 0px #CCC",
    "&:checked": {
      border: `2px solid ${theme.color.borderRadio}`,
    },
    border: "2px solid #CCCCCC",
  },

  radioStyleCheck: {
    verticalAlign: "sub",
    width: "20px",
    height: "20px",
    background: "#EEEEEE 0% 0% no-repeat padding-box",
    backdropFilter: "blur(10px)",
    opacity: 1,
    webkitBackdropFilter: "blur(10px)",
    //border: "2px solid #27cbcb",
    "&:checked": {
      border: `2px solid ${theme.color.borderRadio}`,
    },
  },

  dateSelectNote: {
    color: "#808080",
    fontSize: "11px",
    opacity: 1,
    letterSpacing: "0px",
    textAlign: "left",
    align: "center",
    marginLeft: "15px",
  },

  datePickUpNote: {
    marginTop: "auto",
    marginBottom: "auto",
  },

  maxHeightWidthDialog: {
    maxHeight: "96%",
    maxWidth: "810px",
  },
  nameStyle: {
    fontWeight: "bold",
    fontSize: "16px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  nameKanaStyle: {
    fontSize: "12px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },

  displayValidateError: {
    margin: "auto",
    color: "#EF5E5E",
    fontWeight: "bold",
  },

  hasError: {
    background: "#FFF1F2 0% 0% no-repeat padding-box",
  },
  datePickerStyle: {
    fontSize: "14px",
  },
});

const SpanCustom = styled.span`
  padding-right: 20px;
  margin-left: 8px;
  font-size: 14px;
  color: "#393939";
`;

const InputText = styled.input`
  width: 100%;
  height: 34px;
  padding: 5px 10px;
  font-family: inherit;
  border: 1px solid #dddddd;
  font-size: 14px;
  -webkit-appearance: none;
  background: 0% 0% no-repeat padding-box
    ${(p) => p.theme.color.primaryVariant3};
  border-radius: 3px;
  color: #393939;
`;

const NativeSelectPickupDateCustom = styled(NativeSelect)`
  select {
    padding: 0px 10px;
    width: 194px;
    height: 32px;
    border-radius: 3px;
    border: 1px solid #dddddd;
    background: 0% 0% no-repeat padding-box
      ${(p) => p.theme.color.primaryVariant3};
    color: #393939;
    font-size: 14px;
    &:focus {
      background: 0% 0% no-repeat padding-box
        ${(p) => p.theme.color.primaryVariant3};
    }
  }
  &:hover svg {
    background-color: ${(p) => p.theme.color.primaryVariant};
  }
`;

const NativeSelectTimeSpanCustom = styled(NativeSelect)`
  select {
    padding: 0px 10px;
    width: 300px;
    height: 32px;
    border-radius: 3px;
    border: 1px solid #dddddd;
    background: 0% 0% no-repeat padding-box
      ${(p) => p.theme.color.primaryVariant3};
    color: #393939;
    &:focus {
      background: 0% 0% no-repeat padding-box
        ${(p) => p.theme.color.primaryVariant3};
    }
  }
  &:hover svg {
    background-color: ${(p) => p.theme.color.primaryVariant};
  }
`;

const ArrowDropDownIcon = withStyles((theme) => ({
  root: {
    backgroundColor: theme.button.ok.backgroundColor,
    right: "0px",
    top: "0px",
    width: "32px",
    height: "32px",
    boxShadow: `0px 1px 0px ${theme.color.shadow}`,
    borderRadius: "0px 3px 3px 0px",
    color: "white",
    opacity: 1,
  },
}))(ArrowDropDown);

class YamatoDeliveryDialogInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      now: null,
      pickUpDate: "",
      status: null,
      pickUpDateCandidates: [],
      pickUpTimeSpanCandidates: [],
      enableSubmitButton: false,
      checkCommentInput: false,
      validateYamatoCommentInput: false,
      disabled: false,
    };
  }

  validateComment = (comment) => {
    if (comment && comment.trim().length > 100) {
      this.setState({ checkCommentInput: true });
      return false;
    }
    if (comment && !isValidYamato(comment)) {
      this.setState({ validateYamatoCommentInput: true, disabled: true });
      return false;
    } else {
      this.setState({ validateYamatoCommentInput: false, disabled: false });
    }
    return true;
  };

  _checkPickUpTimeSpanCandidates = (now, pickUpDateInit) => {
    const pickUpDate = this.props.requestDelivery.pickUpDate
      ? new Date(this.props.requestDelivery.pickUpDate)
      : pickUpDateInit;

    const pickUpTimeSpanCandidates = [
      {
        value: "06",
        description: Resource.onEnter.j005,
        deadline: setHours(pickUpDate, 17),
      },
      {
        value: "07",
        description: Resource.onEnter.j001,
        deadline: setHours(pickUpDate, 10),
      },
      {
        value: "08",
        description: Resource.onEnter.j002,
        deadline: setHours(pickUpDate, 14),
      },
      {
        value: "09",
        description: Resource.onEnter.j003,
        deadline: setHours(pickUpDate, 16),
      },
      {
        value: "10",
        description: Resource.onEnter.j004,
        deadline: setHours(pickUpDate, 17),
      },
    ];
    return pickUpTimeSpanCandidates.filter((t) => !isAfter(now, t.deadline));
  };

  onEnter = () => {
    const now = new Date();
    const start = startOfDay(now);
    const end = addDays(start, 2);
    const pickUpDateCandidates = eachDayOfInterval({ start, end });
    const checkPickUpDateCandidatesAfter17h = pickUpDateCandidates.filter(
      (d) => setHours(d, 17) > now
    );
    const pickUpDateInit = checkPickUpDateCandidatesAfter17h[0];
    const pickUpDate =
      this.props.requestDelivery.pickUpDate ||
      format(pickUpDateInit, Resource.Format.j002);

    this.props.handleChangeDeliveryState({ pickUpDate });

    const checkPickUpTimeSpanCandidatesWithDeadline =
      this._checkPickUpTimeSpanCandidates(now, pickUpDateInit);
    this.setState({
      now,
      pickUpDate,
      pickUpDateCandidates: checkPickUpDateCandidatesAfter17h,
      pickUpTimeSpanCandidates: checkPickUpTimeSpanCandidatesWithDeadline,
      checkCommentInput: false,
      validateYamatoCommentInput: false,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value }, () => {
      if (name === "pickUpDate") {
        const { now } = this.state;
        const pickUpDateInit = new Date(this.state.pickUpDate);
        const pickUpTimeSpanCandidates = this._checkPickUpTimeSpanCandidates(
          now,
          pickUpDateInit
        );
        this.props.handleChangeDeliveryState({ pickUpTimeSpan: "06" });
        this.setState({ pickUpTimeSpanCandidates });
      }
    });
  };

  handleChangeComment = (e) => {
    this.props.handleChangeDeliveryState({ comment: e.target.value });
    if (this.validateComment(e.target.value)) {
      this.setState({
        checkCommentInput: false,
        validateYamatoCommentInput: false,
      });
    }
  };

  handleChangePickUpDate = (e) => {
    this.handleChange("pickUpDate")(e);
    this.props.handleChangeDeliveryState({ pickUpDate: e.target.value });
  };

  handleChangePickUpTimeSpan = (e) => {
    this.handleChange("pickUpTimeSpan")(e);
    this.props.handleChangeDeliveryState({ pickUpTimeSpan: e.target.value });
  };

  handleContentSelection = (e) => {
    this.handleChange("product")(e);
    this.setState({ enableSubmitButton: true });
    this.props.handleChangeDeliveryState({ name: e.target.value });
  };

  isTodaySelected() {
    return (
      this.state.pickUpDate === format(this.state.now, Resource.Format.j002)
    );
  }

  handleClickConfirmButton = () => {
    if (this.validateComment()) {
      this.props.handleConfirm();
    }
  };

  render() {
    const { classes, appointment, open } = this.props;
    const { pickUpDate, pickUpTimeSpan, comment } = this.props.requestDelivery;
    const { now, pickUpDateCandidates, pickUpTimeSpanCandidates } = this.state;
    const { patient, from, doctor, menu } = appointment;
    const { address } = patient;
    const disabled = this.state.disabled || !pickUpDate || !pickUpTimeSpan;
    const prefecture = prefectureCodes.find(
      (c) => address.prefectureCode === c.code
    ).jpName;
    const postalCode =
      address.postalCode.slice(0, 3) + "-" + address.postalCode.slice(3);
    const deliveryDisplayFee = deliveryFeeCalculator(
      appointment,
      DELIVERY_OPTION.yamato
    );
    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;

    const dateSelect = (
      <NativeSelectPickupDateCustom
        disableUnderline
        className={classes.datePickerStyle}
        IconComponent={ArrowDropDownIcon}
        value={pickUpDate}
        onChange={this.handleChangePickUpDate}
      >
        {pickUpDateCandidates
          ? pickUpDateCandidates.map((date) => (
              <option
                value={format(date, Resource.Format.j002)}
                key={date.getTime()}
              >
                {format(
                  date,
                  `yyyy${Resource.DialogContent.j003}M${Resource.DialogContent.j001}d${Resource.DialogContent.j002} (iii)`,
                  { locale: ja }
                )}
              </option>
            ))
          : ""}
      </NativeSelectPickupDateCustom>
    );

    const timeSpanOptions = (
      <NativeSelectTimeSpanCustom
        disableUnderline
        className={classes.datePickerStyle}
        IconComponent={ArrowDropDownIcon}
        value={pickUpTimeSpan}
        onChange={this.handleChangePickUpTimeSpan}
        name="pickUpTimeSpan"
      >
        {pickUpTimeSpanCandidates
          ? pickUpTimeSpanCandidates.map((t) => (
              <option
                key={t.value}
                value={t.value}
                disabled={this.isTodaySelected() && isAfter(now, t.deadline)}
              >
                {t.description}
              </option>
            ))
          : ""}
      </NativeSelectTimeSpanCustom>
    );

    return (
      <Dialog
        disableBackdropClick
        maxWidth={false}
        fullWidth={true}
        PaperProps={{ classes: { root: classes.maxHeightWidthDialog } }}
        onEnter={this.onEnter}
        open={open}
      >
        <DialogTitle>
          <FontSpan weight="bold" className={classes.dialogTitle}>
            {Resource.DialogTitle.create}
          </FontSpan>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography className={classes.description}>
            {Resource.DialogContent.description}
          </Typography>
          {patient && (
            <Grid container wrap="nowrap" style={{ marginTop: "20px" }}>
              <Grid item style={{ padding: "0px 10px 0px 0px" }}>
                <img src={AccountImage} alt={""} />
              </Grid>
              <Grid item xs>
                <Typography className={classes.nameStyle}>
                  {patient.familyName} {patient.givenName}
                </Typography>
                <Typography className={classes.nameKanaStyle}>
                  {patient.familyNameKana} {patient.givenNameKana}
                </Typography>
              </Grid>
            </Grid>
          )}
          {patient && (
            <div className={classes.containerTable}>
              <table className={classes.tableStyle}>
                <thead>
                  <tr>
                    <th className={classes.thStyle}>
                      {Resource.DialogContent.table.thBirthDay}
                    </th>
                    <th className={classes.thStyle}>
                      {Resource.DialogContent.table.thAge}
                    </th>
                    <th className={classes.thStyle}>
                      {Resource.DialogContent.table.thGender}
                    </th>
                    <th className={classes.thStyle}>
                      {Resource.DialogContent.table.thPhonenumber}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={classes.tdStyle}>
                      {format(
                        new Date(patient.birthdate),
                        Resource.Format.j001
                      )}
                    </td>
                    <td className={classes.tdStyle}>
                      {patient.birthdate ? getAge(patient.birthdate) : " "}
                    </td>
                    <td
                      className={
                        patient.gender &&
                        patient.gender ===
                          Resource.DialogContent.table.genderMale
                          ? classes.tdStyleGenderMale
                          : classes.tdStyleGenderFemale
                      }
                    >
                      {patient.gender}
                    </td>
                    <td className={classes.tdStyle}>
                      <NumberFormat
                        value={patient.phoneNumber}
                        displayType={"text"}
                        format="###-####-####"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <div className={classes.containerBorder}>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.undertaker}
              </div>
              <div className={classes.contentItem}>
                {menu && menu.medicalMethod === MEDICAL_METHOD.OFFLINE
                  ? Resource.DialogContent.containerBorder.offline
                  : `${doctor.familyName} ${doctor.givenName}`}
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.examDate}
              </div>
              <div className={classes.contentItem}>
                {from ? getDayTextJaFormatShort(from) : ""}
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.deliveryAddress}
              </div>
              <div className={classes.contentItem}>
                {postalCode
                  ? `${Resource.DialogContent.containerBorder.prefixImage}${postalCode}`
                  : ""}
                <br />
                {`${prefecture} ${address.addressCity} ${
                  address.addressStreetNumber
                } ${address.addressBuilding || ""}`}
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.patientName}
              </div>
              <div
                className={`${classes.contentItem} ${classes.contentItemDisplayName}`}
              >
                {`${patient.familyName} ${patient.givenName} (${patient.familyNameKana} ${patient.givenNameKana})`}
              </div>
            </div>
            {!isBillingEnterprise &&
              deliveryDisplayFee &&
              deliveryDisplayFee.deliveryFee &&
              deliveryDisplayFee.deliveryArrangementFee && (
                <YamatoDeliveryFeeContent
                  deliveryFee={deliveryDisplayFee.deliveryFee}
                  deliveryArrangementFee={
                    deliveryDisplayFee.deliveryArrangementFee
                  }
                />
              )}

            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.collectionDate}
              </div>
              <div className={classes.contentItem}>
                <div style={{ display: "flex" }}>
                  <div>{dateSelect}</div>
                  <div className={classes.datePickUpNote}>
                    <SpanCustom className={classes.dateSelectNote}>
                      {Resource.DialogContent.containerBorder.dateSelectNote}
                    </SpanCustom>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.timeSpan}
              </div>
              <div className={classes.contentItem}>{timeSpanOptions}</div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.content}
              </div>
              <div className={classes.contentItem}>
                <Radio
                  className={
                    this.props.requestDelivery.name !== "medicine"
                      ? classes.radioStyle
                      : classes.radioStyleCheck
                  }
                  name="product"
                  value="medicine"
                  onChange={this.handleContentSelection}
                  checked={this.props.requestDelivery.name === "medicine"}
                />
                <SpanCustom>{DeliveryProductName.medicine}</SpanCustom>
                <Radio
                  className={
                    this.props.requestDelivery.name !== "prescription"
                      ? classes.radioStyle
                      : classes.radioStyleCheck
                  }
                  name="product"
                  value="prescription"
                  onChange={this.handleContentSelection}
                  checked={this.props.requestDelivery.name === "prescription"}
                />
                <SpanCustom>{DeliveryProductName.prescription}</SpanCustom>
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.comment}
              </div>
              <div className={classes.contentItem}>
                {this.state.validateYamatoCommentInput && (
                  <FontPError size="s" className={classes.displayValidateError}>
                    {Resource.DialogContent.containerBorder.commentYamatoError}
                  </FontPError>
                )}
                {this.state.checkCommentInput && (
                  <FontPError size="s" className={classes.displayValidateError}>
                    {Resource.DialogContent.containerBorder.commentError}
                  </FontPError>
                )}
                <InputText
                  width="100%"
                  value={comment}
                  placeholder={
                    Resource.DialogContent.containerBorder.commentPlaceholder
                  }
                  onChange={this.handleChangeComment}
                  className={
                    this.state.checkCommentInput ||
                    this.state.validateYamatoCommentInput
                      ? classes.hasError
                      : ""
                  }
                ></InputText>
              </div>
            </div>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.buttonCancel}
            onClick={this.props.handleCancel}
          >
            {Resource.DialogActions.Button.cancelButton}
          </Button>
          {this.props.requestDelivery.name &&
          this.state.enableSubmitButton &&
          !disabled ? (
            <Button
              className={classes.buttonOk}
              onClick={this.handleClickConfirmButton}
            >
              {Resource.DialogActions.Button.confirmButton}
            </Button>
          ) : (
            <Button className={classes.buttonDisable} onClick={() => {}}>
              {Resource.DialogActions.Button.confirmButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(YamatoDeliveryDialogInput);
