import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { japaneseList } from "../../../../Resources/japaneseList";

const FinishResource = japaneseList.Components.Appointment.Stepper.Finish;

const styles = (theme) => ({
  dialogContent: {
    width: "500px",
    height: "77px",
    color: "#393939",
    padding: "25px 30px 25px 25px",
    fontSize: "14px",
    borderBottom: "1px solid #cccccc",
  },
  dialogActions: {
    width: "500px",
    height: "70px",
    margin: "0",
    padding: "15px 27px 15px 353px",
    "& button": {
      height: "40px",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  button: {
    width: "120px",
    borderRadius: "5px",
    backgroundColor: "#808080",
    boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
});

class Finish extends Component {
  render() {
    const { classes, open, closeFinish } = this.props;

    return (
      <React.Fragment>
        <Dialog disableBackdropClick open={open}>
          <DialogContent className={classes.dialogContent}>
            {FinishResource.text.j001}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={closeFinish}
            >
              {FinishResource.render.div.Button.j001}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Finish);
