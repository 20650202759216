import styled from "styled-components";

const ContentItem = styled.div`
  padding: "10px 20px",
  fontSize: "14px",
  minHeight: "41px",
  lineHeight: "21px",
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
`;

interface DrugNameContentProps {
  drugsNameValue: string;
}

export const DrugNameContent = (props: DrugNameContentProps) => {
  return <ContentItem>{props.drugsNameValue}</ContentItem>;
};
