import React from "react";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
export const NewsContent = ({ content }) => {
  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    // nodeがrel属性を持ち、その値がnoopenerの場合に処理を行う
    if (node.hasAttribute("rel") && node.getAttribute("rel") === "noopener") {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener");
    }
  });
  return <React.Fragment>{parse(DOMPurify.sanitize(content))}</React.Fragment>;
};
