import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./index.css";

// Init Libraries
import "./lib/logger/init";
import "./lib/amplify/init";
import "./lib/sentry/init";

ReactDOM.render(<App />, document.getElementById("root"));
