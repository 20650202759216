// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
  aws_cognito_identity_pool_id:
    "ap-northeast-1:d7e55702-bc35-4384-b1db-26276b92abb4",
  aws_cognito_region: "ap-northeast-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "doctor-hosting-mobilehub-300089902",
  aws_content_delivery_bucket_region: "ap-northeast-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "daqk2h7ejkr0t.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_id: "bdf49814-9a8c-4069-8cee-c2a27ef05a83",
  aws_project_name: "doctor",
  aws_project_region: "ap-northeast-1",
  aws_resource_name_prefix: "doctor-mobilehub-300089902",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "ap-northeast-1_Wi6AOreU3",
  aws_user_pools_web_client_id: "2nibh78mf31i1mhle9egk2i2ec"
};

export default awsmobile;
