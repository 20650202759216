import { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import ja from "date-fns/locale/ja";
import AppointmentStatus from "./AppointmentStatus";
import IconInsurance from "../../atoms/icons/IconInsurance";
import IconNotInsurance from "../../atoms/icons/IconNotInsurance";

import { japaneseList } from "../../../Resources/japaneseList";
import { patientDetail } from "../../../lib/window/PatientDetail";
import DescriptionRoundedIcon from "@material-ui/icons/DescriptionRounded";
import FileuploadDialog from "./fileupload/FileuploadDialog";
import DialogConsentRegistration from "../../molecules/dialogs/DialogConsentRegistration";

import { format, endOfMonth } from "date-fns";
import { MEDICAL_METHOD, CONSENT_STATUS } from "../../../Utils/const";
import {
  client,
  GetAppointment,
  AttachmentPresignedUrlForGetObject,
  GetMultipleConsentStatus,
} from "../../../graphql";
import axios from "axios";

const fns = require("date-fns");
const NUMBER_MONTHS_ADD = 1;
const AppointmentUnapprovedListResource =
  japaneseList.Components.Appointment.AppointmentUnapprovedList;

const defaultFileList = [];

const styles = (theme) => ({
  root: {
    width: "95vw",
    marginTop: theme.spacing.unit * 3,
    margin: "auto",
    overflowX: "auto",
    fontSize: "12px",
    height: "calc(100vh - 224px)",
  },
  buttonMedial: {
    fontSize: "14px",
    color: theme.button.ok.color,
    backgroundColor: theme.button.ok.backgroundColor,
    minWidth: "38px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    fontWeight: "bold",
    boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
  },
  buttonIconOnly: {
    minWidth: "0px",
    minHeight: "0px",
    maxWidth: "35px !important",
    maxHeight: "35px !important",
    padding: "5px",
  },
  approvedButton: {
    width: "80%",
    minWidth: "30px",
    height: "40%",
    minHeight: "15px",
    margin: "0 0 5px 0",
    padding: "5px 0",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#ffffff",
    borderRadius: "3px",
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
  rejectButton: {
    width: "80%",
    minWidth: "30px",
    height: "40%",
    minHeight: "15px",
    margin: "0px",
    padding: "5px 0",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#ffffff",
    borderRadius: "3px",
    backgroundColor: "#808080",
    boxShadow: `0px 1px 0px ${theme.button.cancel.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
  tbl: {
    fontSize: "14px",
    width: "100%",
    tableLayout: "fixed",
    borderCollapse: "collapse",
    backgroundColor: "#FFF",
    borderBottom: "solid 1px #cccccc",
    "& tbody tr:nth-child(odd)": {
      backgroundColor: "#fff",
      height: "87px",
    },
    "& tbody tr:nth-child(even)": {
      backgroundColor: "#FAFAFA",
      height: "87px",
    },
  },
  head: {
    backgroundColor: "#999999",
    color: theme.palette.common.white,
    textAlign: "center",
    whiteSpace: "nowrap",
    paddingRight: 0,
    fontSize: "12px",
  },
  tblContent: {
    height: "60vh",
    marginTop: "0",
  },
  body: {
    textAlign: "center",
    paddingRight: 0,
  },
  td: {
    borderLeft: "solid 1px #E1E1E1",
  },
  row: {
    marginBottom: "50px",
  },
  doctor: {
    width: theme.spacing.unit * 14,
    wordBreak: "break-word",
  },
  dateTime: {
    width: theme.spacing.unit * 17,
  },
  date: {
    width: theme.spacing.unit * 17,
  },
  time: {
    width: theme.spacing.unit * 13,
  },
  name: {
    width: theme.spacing.unit * 19,
    wordBreak: "break-word",
  },
  menu: {
    width: theme.spacing.unit * 17,
  },
  status: {
    whiteSpace: "pre-wrap",
    width: theme.spacing.unit * 8,
  },
  consentRegistrations: {
    width: theme.spacing.unit * 11,
  },
  upload: {
    width: theme.spacing.unit * 10,
  },
  op: {
    width: "47px",
    borderRight: "solid 1px #E1E1E1",
    padding: "10px 0",
  },
  header: {
    height: "38px",
    position: "sticky",
  },
  clickArea: {
    display: "inline-block",
    cursor: "pointer",
    color: "#2563c0",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
  },
  font: {
    fontWeight: "bold",
  },
  left: {
    textAlign: "left",
    paddingLeft: "10px",
  },
  iconInsurance: {
    fontSize: "10px",
    border: `1px solid ${theme.color.primary1}`,
  },
  iconNotInsurance: {
    fontSize: "10px",
    border: `1px solid ${theme.color.notInsurance}`,
  },
  spanFileNameUpload: {
    paddingTop: "5px",
  },
  uploadAction: {
    display: "inline-grid",
    justifyItems: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: "10px",
  },
});

class AppointmentUnapprovedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointmentSelect: {},
      isOpenFileouploadDialog: false,
      thumbnailPatientFiles: [],
      patientFileExpiredDay: "",
      fileList: [],
      isLoading: false,
      isOpenConsentRegistrationDialog: false,
      consentRegistrations: [],
      selectedConsentRegistration: {},
    };
  }
  componentDidMount() {
    if (this.props.isSupportOnlineQualificationSystem)
      this.getPatientConsentRegistrations();
  }
  // オンライン資格確認の同意関連の処理
  getPatientConsentRegistrations = () => {
    client
      .query({
        query: GetMultipleConsentStatus,
      })
      .then((res) => {
        this.setState({
          consentRegistrations: res.data.multipleConsentStatus,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  canRegisterConsent = (appointment) => {
    const isOnline = appointment?.menu?.medicalMethod === MEDICAL_METHOD.ONLINE;
    return isOnline;
  };
  extractConsentRegistration = (appointment) => {
    if (!this.canRegisterConsent(appointment))
      return {
        consentStatus: CONSENT_STATUS.UNREGISTRABLE,
      };

    const consentRegistration = this.state.consentRegistrations.find(
      (consentRegistration) => {
        return (
          consentRegistration?.patientId === appointment?.patient?.patientId
        );
      }
    );
    if (!consentRegistration)
      return {
        consentStatus: CONSENT_STATUS.UNREGISTERED,
      };
    return consentRegistration;
  };

  openConsentDialog = (consentRegistration) => {
    this.setState({
      isOpenConsentRegistrationDialog: true,
      selectedConsentRegistration: consentRegistration,
    });
  };
  closeConsentDialog = () => {
    this.setState({
      isOpenConsentRegistrationDialog: false,
    });
  };
  renderConsentRegistration = (appointment) => {
    const { classes } = this.props;
    const consentResource =
      AppointmentUnapprovedListResource.render.div.div.table.tbody.tr.td
        .consentRegistration;
    const consentRegistration = this.extractConsentRegistration(appointment);
    switch (consentRegistration.consentStatus) {
      case CONSENT_STATUS.UNREGISTRABLE:
        return <div>{consentResource.unregistrable}</div>;
      case CONSENT_STATUS.UNREGISTERED:
      case CONSENT_STATUS.REGISTERING:
      case CONSENT_STATUS.REGISTERED:
      default:
        return (
          <div
            className={classes.clickArea}
            onClick={() => this.openConsentDialog(consentRegistration)}
          >
            {consentResource[consentRegistration.consentStatus]}
          </div>
        );
    }
  };

  handleClickUpload = async (appointment) => {
    try {
      this.setState({
        isLoading: true,
        appointmentSelect: appointment,
        isOpenFileouploadDialog: true,
      });
      let fileList = [];
      // Get list file uploaded
      const variables =
        appointment.menu &&
        appointment.menu.medicalMethod === MEDICAL_METHOD.OFFLINE
          ? {
              patientId: appointment.patient.patientId,
              createTime: appointment.createTime,
            }
          : {
              patientId: appointment.patient.patientId,
              createTime: appointment.createTime,
              medicalDoctorId: appointment.doctor.doctorId,
            };
      const { data } = await client.query({
        query: GetAppointment,
        variables: variables,
      });
      if (data) {
        const attachments = data.appointment.attachment || [];
        fileList = attachments.map((item) => {
          return { file: item };
        });

        const patientAttachments = data.appointment.patientAttachments;
        const thumbnailPatientFiles = await this.fetchPatientAttachments(
          patientAttachments
        );

        let reservationDay = new Date(data.appointment.from);
        const nexMonth = reservationDay.getMonth() + 1;
        reservationDay.setMonth(nexMonth);
        const lastDayofMonth = endOfMonth(reservationDay);
        const patientFileExpiredDay = format(lastDayofMonth, "yyy/MM/dd");

        this.setState({
          isOpenFileouploadDialog: true,
          appointmentSelect: appointment,
          fileList,
          newFileList: [],
          selectSomeFiles: false,
          thumbnailPatientFiles,
          patientFileExpiredDay,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
    return false;
  };

  fetchPatientAttachments = async (patientFiles) => {
    if (!patientFiles) return [];
    const preSignedURLs = await Promise.all(
      patientFiles.map((thumbnailFile) => {
        return client.query({
          query: AttachmentPresignedUrlForGetObject,
          variables: {
            filePath: thumbnailFile.thumbnailFilePath,
            fileName: thumbnailFile.fileName,
          },
        });
      })
    );

    const fileBuffers = await Promise.all(
      preSignedURLs.map((preSignUrl) => {
        return axios.get(
          preSignUrl.data.attachmentPresignedUrlForGetObject.url,
          {
            responseType: "arraybuffer",
          }
        );
      })
    );

    const files = patientFiles.map((thumbnailFile, index) => {
      const filePathDevides =
        thumbnailFile.thumbnailFilePath &&
        thumbnailFile.thumbnailFilePath.split("/");
      const fileIdName = filePathDevides[filePathDevides.length - 1];
      const fileId = fileIdName.split(".").slice(0, -1).join("");
      const extensionFile = fileIdName.split(".").slice(-1)[0];
      const newFile = {
        fileName: thumbnailFile.fileName,
        fileId,
        extensionFile,
        fileType: thumbnailFile.fileType,
        thumbnailFilePath: thumbnailFile.thumbnailFilePath,
        filePath: thumbnailFile.filePath,
        uploadedTime: thumbnailFile.uploadedTime,
        updatedTime: thumbnailFile.updatedTime,
      };

      return {
        ...newFile,
        thumbnailFile: new Blob([fileBuffers[index].data]),
      };
    });
    return files;
  };

  handleFileouploadDialog = (e) => {
    this.setState({
      errorCode: [],
      isOpenFileouploadDialog: false,
      selectSomeFiles: false,
    });
  };

  handleFileouploadDialogCancel = (e) => {
    this.setState({
      fileList: JSON.parse(JSON.stringify(defaultFileList)),
      errorCode: [],
      isOpenFileouploadDialog: false,
      selectSomeFiles: false,
    });
  };

  render() {
    const { classes, appointments = [] } = this.props;
    const theadResource =
      AppointmentUnapprovedListResource.render.div.table.thead.tr.td;
    const buttonResource =
      AppointmentUnapprovedListResource.render.div.div.table.tbody.tr.td.button;
    return (
      <div className={classes.root}>
        <table className={classes.tbl}>
          <thead className={classes.head}>
            <tr>
              <td
                className={`${classes.doctor} ${classes.td} ${classes.header} ${classes.font}`}
              >
                {theadResource.j010}
              </td>
              <td
                className={`${classes.td} ${classes.dateTime} ${classes.font}`}
              >
                {theadResource.j002}
              </td>
              <td className={`${classes.td} ${classes.date} ${classes.font}`}>
                {theadResource.j003}
              </td>
              <td className={`${classes.td} ${classes.time} ${classes.font}`}>
                {theadResource.j004}
              </td>
              <td className={`${classes.td} ${classes.name} ${classes.font}`}>
                {theadResource.j005}
              </td>
              <td className={`${classes.td} ${classes.menu} ${classes.font}`}>
                {theadResource.j007}
              </td>
              <td className={`${classes.td} ${classes.status} ${classes.font}`}>
                {theadResource.j008}
              </td>
              {this.props.isSupportOnlineQualificationSystem && (
                <td
                  className={`${classes.td} ${classes.consentRegistrations} ${classes.font}`}
                >
                  {theadResource.j012}
                </td>
              )}
              <td className={`${classes.td} ${classes.upload} ${classes.font}`}>
                {theadResource.j011}
              </td>
              <td className={`${classes.td} ${classes.op} ${classes.font}`}>
                {theadResource.j009}
              </td>
            </tr>
          </thead>
        </table>
        {appointments.length > 0 ? (
          <div className={classes.tblContent}>
            <table className={classes.tbl}>
              <tbody className={classes.body}>
                {appointments.length > 0 &&
                  appointments.map((appo, i) => {
                    // Check if exist patient attachments && it is still before attachments's expire date
                    const patientAttachmentDueDate = fns.endOfMonth(
                      fns.addMonths(fns.parseISO(appo.from), NUMBER_MONTHS_ADD)
                    );

                    const existPatientAttachments =
                      appo.patientAttachments &&
                      Date.now() <= patientAttachmentDueDate.getTime();
                    const disabledButtonUploadFile = !existPatientAttachments;
                    return (
                      <tr className={classes.row} key={`appointment-${i}`}>
                        <td
                          className={`${classes.td} ${classes.doctor} ${classes.left}`}
                        >
                          {`${appo.doctor.familyName} ${appo.doctor.givenName}`}
                        </td>
                        <td
                          className={`${classes.td} ${classes.dateTime} ${classes.left}`}
                        >
                          {fns.format(
                            parseInt(appo.createTime, 10),
                            "yyyy/MM/dd(iii)",
                            {
                              locale: ja,
                            }
                          )}
                          <br />
                          {fns.format(parseInt(appo.createTime, 10), "HH:mm")}
                        </td>
                        <td
                          className={`${classes.td} ${classes.date} ${classes.left}`}
                        >
                          {fns.format(
                            fns.parseISO(appo.from),
                            "yyyy/MM/dd(iii)",
                            {
                              locale: ja,
                            }
                          )}
                        </td>
                        <td className={`${classes.td} ${classes.time}`}>
                          {fns.format(fns.parseISO(appo.from), "HH:mm")}～
                          {fns.format(fns.parseISO(appo.to), "HH:mm")}
                        </td>
                        <td
                          className={`${classes.td} ${classes.name} ${classes.left}`}
                        >
                          <div
                            className={classes.clickArea}
                            onClick={() =>
                              patientDetail.openPatientDetailWindow(
                                appo.patient.patientId
                              )
                            }
                          >
                            {`${appo.patient.familyName} ${appo.patient.givenName}`}
                            <br />
                            {`${appo.patient.familyNameKana} ${appo.patient.givenNameKana}`}
                          </div>
                        </td>
                        <td
                          className={`${classes.td} ${classes.menu} ${classes.left}`}
                        >
                          {appo.menu.displayName}
                          <br />
                          {appo.menu.supportsInsuranceTreatment ? (
                            <IconInsurance className={classes.iconInsurance}>
                              {
                                AppointmentUnapprovedListResource.insuranceCard
                                  .yes
                              }
                            </IconInsurance>
                          ) : (
                            <IconNotInsurance
                              className={classes.iconNotInsurance}
                            >
                              {
                                AppointmentUnapprovedListResource.insuranceCard
                                  .no
                              }
                            </IconNotInsurance>
                          )}
                        </td>
                        <td className={`${classes.td} ${classes.status}`}>
                          <AppointmentStatus
                            appointment={appo}
                            menu={appo.menu}
                          />
                        </td>
                        {this.props.isSupportOnlineQualificationSystem && (
                          <td
                            className={`${classes.td} ${classes.consentRegistrations}`}
                          >
                            {this.renderConsentRegistration(appo, classes)}
                          </td>
                        )}
                        <td className={`${classes.td} ${classes.upload}`}>
                          <div
                            className={
                              existPatientAttachments && classes.uploadAction
                            }
                          >
                            <Button
                              id="upapproved-open-shared-file-button"
                              disabled={disabledButtonUploadFile}
                              key="btn-update-files"
                              className={`${classes.buttonMedial} ${classes.buttonIconOnly}`}
                              variant="raised"
                              color="primary"
                              onClick={(e) => this.handleClickUpload(appo)}
                            >
                              <DescriptionRoundedIcon color="secondary" />
                            </Button>
                            <span className={classes.spanFileNameUpload}>
                              {existPatientAttachments && buttonResource.j003}
                            </span>
                          </div>
                        </td>
                        <td className={`${classes.td} ${classes.op}`}>
                          <Button
                            className={classes.approvedButton}
                            variant="raised"
                            size="small"
                            color="primary"
                            disabled={appo.isUnderMaintenance}
                            onClick={() =>
                              this.props.handleOpenConfirmApprovedDialog(appo)
                            }
                          >
                            {buttonResource.j001}
                          </Button>
                          <Button
                            className={classes.rejectButton}
                            variant="raised"
                            size="small"
                            color="secondary"
                            onClick={(e) =>
                              this.props.handleClickRejectButton(appo)
                            }
                          >
                            {buttonResource.j002}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : (
          <Typography>
            {AppointmentUnapprovedListResource.render.div.Typography.j001}
          </Typography>
        )}
        <DialogConsentRegistration
          isOpen={this.state.isOpenConsentRegistrationDialog}
          handleClose={this.closeConsentDialog}
          consentRegistration={this.state.selectedConsentRegistration}
        />
        <FileuploadDialog
          appointmentSelected={this.state.appointmentSelect}
          thumbnailPatientFiles={this.state.thumbnailPatientFiles}
          patientFileExpiredDay={this.state.patientFileExpiredDay}
          fileList={this.state.fileList}
          selectSomeFiles={false}
          open={this.state.isOpenFileouploadDialog}
          onClose={this.handleFileouploadDialog}
          handleClickCancel={this.handleFileouploadDialogCancel}
          patient={this.state.appointmentSelect.patient}
          doctor={this.state.appointmentSelect.doctor}
        />
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(AppointmentUnapprovedList));
