import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";

const drugNamePlaceholder =
  japaneseList.Components.Appointment.StatusChangeDialog.DialogContent
    .containerBorder.drugNamePlaceholderEWP;

const InputText = styled.input<{ isValidateError: boolean }>`
  background-color: #eee;
  width: 234px;
  height: 34px;
  padding: 5px 10px;
  font-family: inherit;
  border: solid 1px #eee;
  font-size: 14px;
  -webkit-appearance: none;
  background: #eeeeee 0% 0% no-repeat padding-box;
  box-shadow: -1px -1px 0px #cccccc;
  border-radius: 3px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #999999;
  }
  &:enabled {
    background: ${(p) =>
      p.isValidateError ? "#FFF1F2" : p.theme.color.primaryVariant3};
  }
  color: #393939;
`;

interface DrugNameTextProps {
  drugsNameValue: string;
  handleChangeDrugName: () => void;
  disabled: boolean;
  isValidateError: boolean;
}

export const DrugNameText = (props: DrugNameTextProps) => {
  return (
    <InputText
      value={props.drugsNameValue}
      placeholder={drugNamePlaceholder}
      onChange={props.handleChangeDrugName}
      disabled={props.disabled}
      isValidateError={props.isValidateError}
    />
  );
};
