import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import locale from "date-fns/locale/ja";

const styles = _ => ({
    root: { 
        fontSize: "small"
    },
    label: {
        fontSize: "small"
    }
});


function EventMonth({ event, classes }) {
    const {start, end} = event;
    const label = format(start, 'HH:mm', locale) + ' - ' + format(end, 'HH:mm', locale);
    
    return (
      <div className={classes.root}>
        <div><span className={classes.label}>{label}</span> {event.title}</div>
        {
          event.desc &&
          <div>{event.desc}</div>
        }
      </div>
    );
  }
  
  export default withStyles(styles)(EventMonth);
