import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import FontSpan from "../../atoms/fonts/FontSpan";
import CurrencyText from "../../organisms/payment/CurrencyText";
import { japaneseList } from "../../../Resources/japaneseList";

import {
  APPOINTMENT_STATUS,
  DELIVERY_OPTION,
  PAYMENT_METHOD,
} from "../../../Utils/const";

const TblCellTreatmentFee = styled.td`
  text-align: center;
  padding: 5px 10px;
  border-left: 1px solid #cccccc;
  white-space: nowrap;
`;

const FontSpanCustom = styled(FontSpan)`
  color: #cccccc;
`;

const ButtonCustom = withStyles((theme) => ({
  root: {
    backgroundColor: theme.button.ok.backgroundColor,
    borderRadius: "3px",
    border: 0,
    color: "white",
    height: "24px",
    padding: "5px",
    fontWeight: "bold",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    boxShadow: "0px 1px 0px " + theme.button.ok.shadowColor,
    minWidth: "66px",
  },
}))(Button);

const HospitalPaymentRowTreatmentFee = (props) => {
  const { appo, deliverySelectedOption, handleOpenPaymentFeeDialog, isShowButton } = props;

  const Resource = japaneseList.Components.Payment.HospitalPaymentList;

  const treatmentFee = (appointment) => {
    const { payment, status } = appointment;
    const { medicalExpense } = payment || {};
    const appoDeliveryOption = parseInt(payment.deliverySelectedOption, 10);
    const propsDeliveryOption = parseInt(deliverySelectedOption, 10);
    // TODO: 健康経営のメニューかどうかの判定方法、判定箇所をリファクタリングする
    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;
    if (medicalExpense || medicalExpense === 0) {
      if (isBillingEnterprise) {
        return japaneseList.Components.Payment.CustomNumber.NumberFormat.j002;
      }
      return <CurrencyText value={medicalExpense} style={{ float: "right" }} />;
    }

    if (
      isShowButton ||
      (status !== APPOINTMENT_STATUS.PAYMENT_COMPLETED &&
      (!!appoDeliveryOption ||
        propsDeliveryOption === DELIVERY_OPTION.noDelivery))
    ) {
      return (
        <ButtonCustom
          variant="raised"
          size="small"
          color="primary"
          onClick={() => {
            handleOpenPaymentFeeDialog(appointment);
          }}
        >
          {isBillingEnterprise
            ? Resource.render.MedicalExpenseButton.j002
            : Resource.render.MedicalExpenseButton.j001}
        </ButtonCustom>
      );
    }

    return (
      <FontSpanCustom style={{ float: "right" }}>
        {japaneseList.Components.Payment.CustomNumber.NumberFormat.j001}
      </FontSpanCustom>
    );
  };
  return <TblCellTreatmentFee>{treatmentFee(appo)}</TblCellTreatmentFee>;
};

export default HospitalPaymentRowTreatmentFee;
