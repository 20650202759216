import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import PersonBlack from "../../../images/account_circle_b-24px.svg";
import PersonWhite from "../../../images/account_circle_w-24px.svg";
import { isBefore } from "date-fns";

const styles = (_) => ({
  root: {
    fontSize: "small",
    display: "flex",
    alignItems: "center",
  },
  text: {
    fontSize: "12px",
  },
  icon: {
    margin: "0 1px 2px 2px",
    fontSize: "14px",
  },
  img: {
    width: "12px",
    height: "12px",
  },
});

function EventWeek({ event, classes }) {
  return (
    <div className={classes.root}>
      <span className={classes.text}>{event.title}</span>
      <Icon className={classes.icon}>
        <img
          className={classes.img}
          src={isBefore(event.end, new Date()) ? PersonBlack : PersonWhite}
          alt=""
        />
      </Icon>
      <span className={classes.text}>{event.detail}</span>
    </div>
  );
}

export default withStyles(styles)(EventWeek);
