import React from 'react';
import styled from 'styled-components';

const StyledIconNew = styled.span`
  display:inline-block;
  background-color: #EF5E5E;
  height: 16px;
  width: 31px;
  text-align: center;
  vertical-align: middle;
  font-size: 10px;
  font-weight: bold;
  color: white;
  margin-left: 10px;
`;

export const IconNew = () => {
  return (
    <React.Fragment>
      <StyledIconNew>
        New
      </StyledIconNew>
    </React.Fragment>
  );
}