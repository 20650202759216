import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import PasswordPolicy from "../../molecules/others/PasswordPolicy";
import { japaneseList } from "../../../Resources/japaneseList";
import LinkA from "../../atoms/links/LinkA";

const ForgotPasswordResource = japaneseList.Authenticator.ForgotPassword;

const styles = (theme) => {
  return {
    changePasswordGrid: {
      maxWidth: "360px",
      paddingTop: "69px",
    },
    item: {
      display: "block",
      marginTop: theme.spacing.unit * 2,
    },
    passwordPolicy: {
      marginTop: "30px",
    },
    link: {
      display: "block",
      marginTop: "16px",
      fontSize: "12px",
    },
    input: {
      backgroundColor: theme.color.primaryVariant3,
      border: `1px solid #DDDDDD`,
      borderRadius: "3px",
      padding: "1px",
    },
    label: {
      paddingBottom: "5px",
      color: "#808080",
      fontWeight: "bold",
      fontFamily: "inherit",
      fontSize: "12px",
      transform: "scale(1)",
    },
    inputPropsStyle: {
      height: "14px",
      padding: "10px",
      WebkitBoxShadow: `0 0 0 30px ${theme.color.primaryVariant3} inset !important`,
      backgroundClip: "content-box",
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      fontFamily: "inherit",
      color: "#333333",
    },
    formControlLabel: {
      textAlign: "left",
      fontSize: "12px",
      letterSpacing: "0px",
      color: "#393939",
      opacity: 1,
      marginLeft: "8px",
      marginTop: "1px",
      fontFamily: "inherit",
    },
    formControlRoot: {
      marginLeft: "0px",
      marginTop: "20px",
    },
    button: {
      color: theme.button.ok.color,
      backgroundColor: theme.button.ok.backgroundColor,
      boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
      "&:hover": {
        backgroundColor: theme.button.ok.backgroundColorHover,
      },
      fontWeight: "bold",
      marginTop: "30px",
    },
    code: {
      marginTop: "2px",
    },
    checkboxRoot: {
      color: "#CCCCCC",
      width: "18px",
      height: "18px",
      background: "#EEEEEE 0% 0% no-repeat padding-box",
      opacity: 1,
      "&:hover": {
        background: "#EEEEEE 0% 0% no-repeat padding-box",
      },
    },
    checked: {
      color: theme.color.primary1 + "!important",
    },
  };
};

const LinkAStyle = styled(LinkA)`
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0px;
  color: #2563c0;
  opacity: 1;
  &:hover {
    text-decoration: none;
  }
`;

const EmailText = styled.p`
  text-align: center;
  font-size: 14px;
  font-family: inherit;
  color: #393939;
`;

/** Specify background color for set forgot password page only.  */
const GlobalStyle = createGlobalStyle`
  body {
    background-color: #ffffff !important;
  }
`;

const ForgotPasswordAfterSendAuthCode = (props) => {
  const {
    classes,
    email,
    setEmail,
    code, 
    setCode,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    isEnabledPasswordChangeSubmitButton,
    setIsEnabledPasswordChangeSubmitButton,
    requestChangePassword,
    returnToLoginPage,
  } = props;

  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    if (name === 'email') {
      setEmail(value);
      if (value && code && password && passwordConfirm) {
        setIsEnabledPasswordChangeSubmitButton(true);
      } else {
        setIsEnabledPasswordChangeSubmitButton(false);
      }
    } else if (name === 'code') {
      setCode(value);
      if (value && email && password && passwordConfirm) {
        setIsEnabledPasswordChangeSubmitButton(true);
      } else {
        setIsEnabledPasswordChangeSubmitButton(false);
      }
    } else if (name === 'password') {
      setPassword(value);
      if (value && email && code && passwordConfirm) {
        setIsEnabledPasswordChangeSubmitButton(true);
      } else {
        setIsEnabledPasswordChangeSubmitButton(false);
      }
    } else if (name === 'passwordConfirm') {
      setPasswordConfirm(value);
      if (value && email && code && password) {
        setIsEnabledPasswordChangeSubmitButton(true);
      } else {
        setIsEnabledPasswordChangeSubmitButton(false);
      }
    }
  };

  const handleTogglePassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  return (
    <React.Fragment>
      <Grid
        container
        className={classes.changePasswordGrid}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="title"
            align="center"
            className={classes.title}
          >
            {ForgotPasswordResource.render.Grid.Grid.Typography.j005}
          </Typography>
          <EmailText>
            { email }
          </EmailText>
          <TextField
            className={`${classes.item} ${classes.code}`}
            fullWidth
            label={ForgotPasswordResource.render.Grid.Grid.TextField.j002}
            name="code"
            onChange={(e) => handleInputChange(e)}
            // Properties applied to the Input element
            InputProps={{
              disableUnderline: true,
              className: classes.input,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.label,
              focused: false,
            }}
            // Attributes applied to the native input element
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ className: classes.inputPropsStyle }}
          />
          <TextField
            className={classes.item}
            fullWidth
            name="password"
            type={isShowPassword ? "text" : "password"}
            label={ForgotPasswordResource.render.Grid.Grid.TextField.j003}
            onChange={(e) => handleInputChange(e)}
            // Properties applied to the Input element
            InputProps={{
              disableUnderline: true,
              className: classes.input,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.label,
              focused: false,
            }}
            // Attributes applied to the native input element
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ className: classes.inputPropsStyle }}
          />
          <TextField
            className={classes.item}
            fullWidth
            name="passwordConfirm"
            type={isShowPassword ? "text" : "password"}
            label={ForgotPasswordResource.render.Grid.Grid.TextField.j004}
            onChange={(e) => handleInputChange(e)}
            // Properties applied to the Input element
            InputProps={{
              disableUnderline: true,
              className: classes.input,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.label,
              focused: false,
            }}
            // Attributes applied to the native input element
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ className: classes.inputPropsStyle }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isShowPassword"
                classes={{
                  root: classes.checkboxRoot,
                  checked: classes.checked,
                }}
              />
            }
            classes={{
              root: classes.formControlRoot,
              label: classes.formControlLabel,
            }}
            onChange={() => handleTogglePassword()}
            label={ForgotPasswordResource.render.Grid.Grid.CheckBox.j001}
          />
          <div className={classes.passwordPolicy}>
            <PasswordPolicy />
          </div>
          <Button
            className={classes.button}
            variant="raised"
            fullWidth
            onClick={() => requestChangePassword()}
            disabled={!isEnabledPasswordChangeSubmitButton}
          >
            {ForgotPasswordResource.render.Grid.Grid.Button.j002}
          </Button>
          <LinkAStyle
            className={classes.link}
            onClick={() => returnToLoginPage()}
          >
            {ForgotPasswordResource.render.Grid.Grid.Button.Typography.j001}
          </LinkAStyle>
        </Grid>
      </Grid>
      <GlobalStyle />
    </React.Fragment>
  )
}

export default withStyles(styles)(ForgotPasswordAfterSendAuthCode);
