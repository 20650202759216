import React from "react";
import styled from "styled-components";
import { formatDate } from "../../../Utils/DateUtil";
import { getAge } from "../../../Utils/DisplayLogic";
import { japaneseList } from "../../../Resources/japaneseList";
import FontP from "../../atoms/fonts/FontP";
import FontPBold from "../../atoms/fonts/FontPBold";
import PatientInfoButtonIcon from "../../../images/person_remove-24px.svg";
import { isLnln } from "../../../Utils/checkLnln";

const InfoBarResource = japaneseList.Pages.Patient.InfoBar;

const InfoBarBox = styled.div`
  width: 100%;
  height: 68.5px;
  border-bottom: solid 5px ${isLnln() ? "#171F55" : "#6CE1D5"};
  clear: both;
  font-family: "ヒラギノ角ゴ ProN W6", Hiragino Kaku Gothic ProN,
    HiraKakuProN-W6, Yu Gothic, sans-serif;
  font-weight: 600;
  background-color: white;
  position: relative;
`;

const Name = styled.div`
  display: inline-block;
`;

const NameText = styled(FontPBold)`
  font-size: 20px;
  font-weight: bold;
  color: #393939;
  padding: 0px;
  line-height: 20px;
  margin-top: 14px;
  margin-left: 30px;
`;

const NameKana = styled(FontP)`
  font-size: 14px;
  color: #393939;
  line-height: 14px;
  margin-top: 5px;
  margin-left: 30px;
`;

const TextBaseStyle = styled(FontP)`
  font-size: 12px;
  color: #808080;
  line-height: 12px;
  margin-top: 22px;
  margin-left: 10px;
`;

const ValueBaseStyle = styled(FontP)`
  font-size: 14px;
  color: #808080;
  line-height: 14px;
  margin-top: 5px;
  margin-left: 10px;
`;

const BirthDate = styled.div`
  display: inline-block;
  margin-left: 10vw;
`;

const BirthDateText = styled(TextBaseStyle)``;

const BirthDateValue = styled(ValueBaseStyle)``;

const Age = styled.div`
  display: inline-block;
  color: #808080;
  margin-left: 10px;
`;

const AgeText = styled(TextBaseStyle)``;

const AgeValue = styled(ValueBaseStyle)``;

const Gender = styled.div`
  display: inline-block;
  margin-left: 10px;
`;

const GenderText = styled(TextBaseStyle)``;

const GenderValue = styled(ValueBaseStyle)``;

const Img = styled.img`
  margin-right: 5px;
`;

const CustomButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${isLnln() ? "#8EAFED" : (p) => p.theme.color.primary};
  box-shadow: 0px 1px 0px ${isLnln() ? "#738EBF" : "#009896"};
  height: 28px;
  width: 92px;
  top: 20px;
  margin-left: 40px;
  padding-top: 1px;
  border-radius: 3px;
  opacity: 1;
  position: absolute;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background-color: ${isLnln() ? "#B5CDF8" : "#6ce1d5"};
  }
`;

const CustomButtonText = styled(FontPBold)`
  font-size: 14px;
  color: white;
`;

export const InfoBar = ({ patient, openDialogPatientInfo }) => {
  const {
    familyName,
    givenName,
    familyNameKana,
    givenNameKana,
    birthdate,
    gender,
  } = patient;
  const birthdateWithSlash = birthdate
    ? formatDate(birthdate, InfoBarResource.j004)
    : "";
  const age = birthdate ? getAge(birthdate) : "";

  return (
    <React.Fragment>
      <InfoBarBox>
        <Name>
          <NameText>
            {familyName} {givenName}
          </NameText>
          <NameKana>
            {familyNameKana} {givenNameKana}
          </NameKana>
        </Name>
        <BirthDate>
          <BirthDateText>{InfoBarResource.j001}</BirthDateText>
          <BirthDateValue>{birthdateWithSlash}</BirthDateValue>
        </BirthDate>
        <Age>
          <AgeText>{InfoBarResource.j002}</AgeText>
          <AgeValue>{age}</AgeValue>
        </Age>
        <Gender>
          <GenderText>{InfoBarResource.j003}</GenderText>
          <GenderValue>{gender}</GenderValue>
        </Gender>
        <CustomButton onClick={openDialogPatientInfo}>
          <Img src={PatientInfoButtonIcon}></Img>
          <CustomButtonText>{InfoBarResource.Button.j001}</CustomButtonText>
        </CustomButton>
      </InfoBarBox>
    </React.Fragment>
  );
};
