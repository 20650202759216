import React from "react";
import styled from "styled-components";

const IconStyled = styled.span`
  font-family: Hiragino Kaku Gothic ProN;
  margin-left: 6px;
  padding: 0px 4px 0px 4px;
  border: 1px solid #ef5e5e;
  color: #ef5e5e;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  width: 24px;
  height: 18px;
  left: 4px;
  gap: 0px;
  opacity: 0px;
`;

export const IconImportant = () => {
  return (
    <React.Fragment>
      <IconStyled>重要</IconStyled>
    </React.Fragment>
  );
};
