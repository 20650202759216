import React from "react";
import styled from "styled-components";
import { withStyles, Badge } from "@material-ui/core";

const styles = (theme) => ({
  badge: {
    backgroundColor: "#ff4d4d",
    width: "8px",
    height: "8px",
    marginTop: "10px",
    marginRight: "10px",
  },
  selected: {
    backgroundColor: theme.color.header.selected.bg,
    color: theme.color.header.selected.font,
  },
});

const TooltipWrapper = styled.div`
  width: 76px;
  height: 65px;
  margin: 0;
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.header.tooltip.hover};
  }
  border-left: 1px solid ${(p) => p.theme.header.border.left};
  color: ${(p) => p.theme.header.tooltip.color};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.div`
  margin-top: -4px;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px;
  height: 20px;
`;

const TooltipBase = (props) => {
  const { title, onClick, Icon, isBadge, isUnderline, classes } = props;

  return (
    <React.Fragment>
      <TooltipWrapper
        onClick={onClick}
        className={isUnderline ? classes.selected : ""}
      >
        {isBadge ? (
          <Badge
            color="error"
            classes={{
              badge: classes.badge,
            }}
            badgeContent=""
          >
            {title ? (
              <div>
                <Icon color="inherit" />
              </div>
            ) : (
              <Icon color="inherit" />
            )}
          </Badge>
        ) : title ? (
          <div>
            <Icon color="inherit" />
          </div>
        ) : (
          <Icon color="inherit" />
        )}
        {title && (
          <Title>
            {title.map((text) => (
              <React.Fragment key={text}>
                {text}
                <br />
              </React.Fragment>
            ))}
          </Title>
        )}
      </TooltipWrapper>
    </React.Fragment>
  );
};

export default withStyles(styles)(TooltipBase);
