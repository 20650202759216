import React from "react";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { NewsList } from "../../organisms/news/NewsList";
import Heading1 from "../../atoms/headings/Heading1";
import { japaneseList } from "../../../Resources/japaneseList";

const title = japaneseList.Pages.Home.render.Header.title;
const styles = (_) => {
  return {
    home: {
      width: "100%",
      maxWidth: "900px",
      marginTop: "24px",
      marginRight: "30px",
      marginLeft: "30px",
      paddingBottom: "30px",
    },
    heading: {
      height: "30px",
      marginBottom: "10px",
      fontFamily: "Hiragino Kaku Gothic ProN",
      fontWeight: 600,
      lineHeight: "30px",
      "&:before": {
        marginRight: "8px",
      },
    },
  };
};

const Home = ({ newsList, classes }) => {
  return (
    <React.Fragment>
      <div className={classes.home}>
        <Heading1 className={classes.heading}>{title}</Heading1>
        <Grid container direction="column" alignItems="center">
          <NewsList newsList={newsList} />
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(Home);
