import React from "react";
import NoHeaderTemplate from "../../templates/LogoOnlyHeaderTemplate";
import Titles from "../../../Resources/Titles";
import UnsupportedDevice from '../../organisms/unsupportedDevice/UnsupportedDevice';

export const UnsupportedDevicePage = () => {
  return (
    <NoHeaderTemplate
      main={
        <UnsupportedDevice />
      }
      title={Titles.home}
    />
  );
};
