import React from "react";
import styled from "styled-components";
import imagePath from "../../../images/signin-panel.svg";
import { japaneseList } from "../../../Resources/japaneseList";

const Img = styled.img`
    width: 524px;
    height: 188px;
`;

const Root = styled.div`
    display: flex;
    background-color: #EAFBF980;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const Hr = styled.div`
    margin: 28px 0 10px;
    border-top: 1px dashed #27cdcb;
    width: 90%;
    opacity: 1;
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
`;

const Title = styled.div`
    text-align: center;
    font-family: 'Hiragino Kaku Gothic ProN';
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0px;
    margin: 10px 0 20px 0;
    color: ${(p) => p.theme.color.primary};
`;

const Description = styled.div`
    text-align: center;
    font-family: 'Hiragino Kaku Gothic ProN';
    font-size: 14px;
    letter-spacing: 0px;
    color: #393939;
    margin-bottom: 20px;
    white-space: pre-wrap;
`;

const Button = styled.a`
    margin: 0 10px 0 0;
    padding: 6px 10px 0px;
    height: 35px;
    font-family: 'Hiragino Kaku Gothic ProN';
    font-size: 14px;
    text-decoration: none;
    border: solid 1px ${p => p.theme.button.border.link};
    border-radius: 5px;
    background-color: #ffffff;
    color: ${(p) => p.theme.color.primary};

    &:hover {
        background-color: #D9F6F3;
    }
`;

const Div1 = styled.div`
    margin-right: 10px;
    font-family: 'Hiragino Kaku Gothic ProN';
    font-size: 14px;
    color: ${(p) => p.theme.color.primary};
`;

const Res = japaneseList.Components.AnnouncementPanel;

const AnnouncementPanel = () => {
    const hospitalLpUrl = process.env.REACT_APP_LP_CARADA_URL + '/';
    const pharmacyLpUrl = process.env.REACT_APP_LP_CARADA_URL + '/pharmacy.html';

    return (
        <Root>
            <Title>{Res.Root.Title}</Title>
            <Description>{Res.Root.Description}</Description>
            <Img src={imagePath} />
            <Hr />
            <Footer>
                <Div1>{Res.Root.Footer.Div1}</Div1>
                <Button target="_blank" rel="noopener" href={hospitalLpUrl}>{Res.Root.Footer.Button1}</Button>
                <Button target="_blank" rel="noopener" href={pharmacyLpUrl}>{Res.Root.Footer.Button2}</Button>
            </Footer>
        </Root>
    );
}

export default AnnouncementPanel;