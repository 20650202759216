import { useState, useEffect, useContext } from 'react';
import SiteContext from '../contexts/SiteContext';
import { client, GetDoctor } from "../graphql";

export const useDoctor = () => {
  const [doctor, setDoctor] = useState(null);
  const siteContext = useContext(SiteContext);

  useEffect(() => {
    if (siteContext.doctor) {
      // console.log('use Doctor in Context!');
      setDoctor(siteContext.doctor);
    } else {
      client.query({
        query: GetDoctor
      })
      .then(res => {
        // console.log('use Doctor from API!');
        setDoctor(res.data.doctor);
      })
      .catch(err => {
        console.error(err);
      });
    }
  }, []);

  return doctor;
}