import React from "react";
import styled from "styled-components";
import { Launch } from "@material-ui/icons";
import { IconNew } from "../../atoms/icons/IconNew";

const StyledDocumentNameWithIcon = styled.span`
  text-decoration: underline;
`;

// TODO: 現行のLinkA のatom だとcolor が変わらなかったので、暫定処理
const LinkA = styled.a`
  display: inline-block;
  cursor: pointer;
  color: ${(p) => p.theme.color.font};
`;

const LinkAInner = styled.div`
  display: flex;
`;

export const DocumentNameWithIcon = ({ document }) => {
  const name = document.name;
  const id = document.id;
  const fileUrl = document.fileUrl;
  const isNew = document.isNew;

  return (
    <React.Fragment>
      <StyledDocumentNameWithIcon>
        <LinkA id={id} href={fileUrl} target="_blank">
          <LinkAInner>
            {name}
            <Launch style={{ marginLeft: "5px" }} />
          </LinkAInner>
        </LinkA>
        {isNew && <IconNew />}
      </StyledDocumentNameWithIcon>
    </React.Fragment>
  );
};
