import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Fade from "@material-ui/core/Fade";
import AppointmentUpdate from "../../organisms/appointment/AppointmentUpadate";
import AppointmentList from "../../organisms/appointment/AppointmentList";
import AppointConditionInput from "../../organisms/appointment/AppointConditionInput";
import AppointmentCancelDialog from "../../organisms/appointment/AppointmentCancelDialog";
import { startOfDay, endOfDay } from "date-fns";
import { Query } from "@apollo/react-components";
import {
  client,
  GetDoctor,
  GetHospital,
  ListAppointments,
} from "../../../graphql";
import { Dialog } from "@material-ui/core";
import Heading1 from "../../atoms/headings/Heading1";

import BasicTemplate from "../../templates/BasicTemplate";
import { japaneseList } from "../../../Resources/japaneseList";
import Titles from "../../../Resources/Titles";
import ImportantNoticeContext from "../../../contexts/ImportantNoticeContext";

const styles = {
  root: {
    minWidth: "100%",
    marginTop: "2px",
    paddingLeft: "31px",
    paddingRight: "31px",
  },
  heading: {
    fontSize: "20px",
    marginBottom: "12px",
  },
};
const AppointmentResource = japaneseList.Pages.Appointment.Appointment;
const mustFilterStatus = [
  "beforeExam",
  "examCompleted",
  "paymentCompleted",
  "canceledDoctor",
  "canceledPatientPreviousDay",
  "canceledPatientFromToday",
  "rejected",
  "canceledBeforeApproving",
];

class Appointment extends Component {
  state = {
    editting: null,
    range: {
      start: startOfDay(new Date()).toISOString(),
      end: endOfDay(new Date()).toISOString(),
      status: mustFilterStatus,
    },
    facilityType: null,
    isSupportOnlineQualificationSystem: false,
  };

  componentDidMount = () => {
    this.setNumberOfUnapprovedAppointment();
    this.getHospital();
  };

  setNumberOfUnapprovedAppointment = () => {
    client
      .query({
        query: GetDoctor,
      })
      .then((data) => {
        if (data) {
          this.setState({
            numberOfUnapprovedAppointment:
              (data.data.doctor &&
                data.data.doctor.numberOfUnapprovedAppointment) ||
              0,
          });
        }
      });
  };

  getHospital = () => {
    client
      .query({
        query: GetHospital,
      })
      .then((res) => {
        this.setState({
          facilityType: res.data.hospital.facilityType,
          isSupportOnlineQualificationSystem:
            res.data.hospital.isSupportOnlineQualificationSystem,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  onConditionStateChanged = async (e) => {
    const range = {
      start: startOfDay(e.start).toISOString(),
      end: endOfDay(e.end).toISOString(),
      // GraphQL query fetch is not fired if variables don't changed.
      // So, append extra parameter to force refetch data.
      timestamp: Date.now(),
      status: mustFilterStatus,
    };
    this.setState({ range });
  };

  onAppointmentEdit = async (appointment, type) => {
    this.setState({
      editting: { appointment, type },
    });
  };

  onAppointmentEditCanceled = () => {
    this.setState({
      editting: null,
    });
  };

  onAppointmentEditCompleted = () => {
    this.setState({
      editting: null,
      range: {
        ...this.state.range,
        // GraphQL query fetch is not fired if variables don't changed.
        // So, append extra parameter to force refetch data.
        timestamp: Date.now(),
        status: mustFilterStatus,
      },
    });
    this.context.refreshImportantNotice &&
      this.context.refreshImportantNotice();
  };

  render() {
    const { classes } = this.props;
    const { editting, range } = this.state;
    const canceling = editting && editting.type === "cancel";
    const editing = editting && editting.type === "edit";

    const main = (
      <React.Fragment>
        <div className={classes.root}>
          <Heading1 className={classes.heading}>
            {AppointmentResource.title[this.state.facilityType]}
          </Heading1>
          <AppointConditionInput
            onStateChanged={this.onConditionStateChanged}
            search={this.props.location.search}
          />
          {
            // polling every 7 seconds, because apply changing by doctor.
            <Query query={ListAppointments} variables={range}>
              {({ loading, error, data, refetch }) =>
                loading ? (
                  <Fade
                    in={loading}
                    style={{ transitionDelay: loading ? "800ms" : "0ms" }}
                    unmountOnExit
                  >
                    <LinearProgress />
                  </Fade>
                ) : error ? (
                  // TODO: 空配列を入れる方法は変更すべき、エラーが返却される原因によってはAPIの修正が必要
                  // no loading by passing an empty appointments to an existing component
                  <AppointmentList
                    appointments={[]}
                    onAppointmentEdit={this.onAppointmentEdit}
                    refetch={refetch}
                    isSupportOnlineQualificationSystem={
                      this.state.isSupportOnlineQualificationSystem
                    }
                  />
                ) : (
                  data.appointments && (
                    <AppointmentList
                      appointments={data.appointments.items}
                      onAppointmentEdit={this.onAppointmentEdit}
                      refetch={refetch}
                      isSupportOnlineQualificationSystem={
                        this.state.isSupportOnlineQualificationSystem
                      }
                    />
                  )
                )
              }
            </Query>
          }
          {canceling && (
            <AppointmentCancelDialog
              onFinished={this.onAppointmentEditCompleted}
              onDismissDialog={this.onAppointmentEditCanceled}
              appointment={editting.appointment}
            />
          )}
          {editing && (
            <Dialog
              disableBackdropClick
              onClose={this.onAppointmentEditCanceled}
              open={editing}
              maxWidth="md"
            >
              <AppointmentUpdate
                onFinished={this.onAppointmentEditCompleted}
                onClose={this.onAppointmentEditCanceled}
                handleResult={this.handleResult}
                appointment={editting.appointment}
              />
            </Dialog>
          )}
        </div>
      </React.Fragment>
    );
    return <BasicTemplate main={main} title={Titles.appointment} />;
  }
}

Appointment.contextType = ImportantNoticeContext;

export default withStyles(styles)(Appointment);
