import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import DefaultPage from "./DefaultPage";
import GTM from "../Utils/GoogleTagManager";

// Pages
import { HomePage } from "../comp/pages/home";
import AppointmentPage from "../comp/pages/appointment";
import AppointmentUnapprovedPage from "../comp/pages/appointment/unapproved";
import AppointmentCalendarPage from "../comp/pages/appointment/calendar";
import MedicalPage from "../comp/pages/medical";
import PaymentPage from "../comp/pages/payment";
import { DocumentsPage } from "../comp/pages/documents";
import { ContactPage } from "../comp/pages/contact";
import SettingsPage from "../comp/pages/settings";
import { NotAccessPage } from "../comp/pages/not-access";
import { UnsupportedDevicePage } from "../comp/pages/unsupported-device-page";
import NotFoundPage from "../comp/pages/404";
import { PatientPage } from "../comp/pages/patient";

export const Routes = ({ isLogin }) => {
  return (
    <Router>
      <GTM.RouteTracker />
      <Switch>
        <DefaultPage exact path="/" component={HomePage} isLogin={isLogin} />
        <DefaultPage
          exact
          path="/appointment"
          component={AppointmentPage}
          isLogin={isLogin}
        />
        <DefaultPage
          exact
          path="/appointment/unapproved"
          component={AppointmentUnapprovedPage}
          isLogin={isLogin}
        />
        <DefaultPage
          exact
          path="/appointment/calendar"
          component={AppointmentCalendarPage}
          isLogin={isLogin}
        />
        <DefaultPage
          exact
          path="/medical"
          component={MedicalPage}
          isLogin={isLogin}
        />
        <DefaultPage
          exact
          path="/payment"
          component={PaymentPage}
          isLogin={isLogin}
        />
        <DefaultPage
          path="/documents"
          component={DocumentsPage}
          isLogin={isLogin}
        />
        <DefaultPage
          path="/contact"
          component={ContactPage}
          isLogin={isLogin}
        />
        <DefaultPage
          path="/settings"
          component={SettingsPage}
          isLogin={isLogin}
        />
        <DefaultPage
          exact
          path="/not-access"
          component={NotAccessPage}
          isLogin={isLogin}
        />
        <DefaultPage
          exact
          path="/unsupported-device-page"
          component={UnsupportedDevicePage}
          isLogin={isLogin}
        />
        <DefaultPage
          exact
          path="/patient"
          component={PatientPage}
          isLogin={isLogin}
        />
        <DefaultPage component={NotFoundPage} />
      </Switch>
    </Router>
  );
};
