import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import FontP from "../../atoms/fonts/FontP";
import IconInsurance from "../../atoms/icons/IconInsurance";
import IconNotInsurance from "../../atoms/icons/IconNotInsurance";
import { HOSPITAL_TYPES, MEDICAL_METHOD } from "../../../Utils/const";
import { useDoctor, useHospital } from "../../../hooks";

const AppointmentInfoResource = japaneseList.Pages.Patient.AppointmentInfo;

const CustomIconInsurance = styled(IconInsurance)`
  border: solid 1px ${(p) => p.theme.color.primary};
  padding: 1px;
  font-size: 10px;
`;

const CustomIconNotInsurance = styled(IconNotInsurance)`
  border: solid 1px #ef5e5e;
  font-size: 10px;
`;

const AppointmentDate = styled(FontP)`
  font-size: 12px;
`;

const MenuName = styled(FontP)`
  display: inline;
  font-size: 14px;
`;

const InsuranceTreatment = styled.div`
  display: inline-block;
  margin-left: 5px;
`;

const DoctorName = styled.div`
  font-size: 12px;
`;

export const AppointmentInfo = ({ infoContents }) => {
  const isOnlineAppointment = infoContents?.medicalMethod === MEDICAL_METHOD.ONLINE;
  const doctor = useDoctor();
  const hospital = useHospital();
  const isCentralClinicWithDifferentDoctor =
    hospital?.hospitalType === HOSPITAL_TYPES.CENTRAL_CLINIC &&
    infoContents?.doctor?.doctorId !== doctor?.doctorId;

  return (
    <div>
      <AppointmentDate>{infoContents.date}</AppointmentDate>
      <MenuName>{infoContents.displayName}</MenuName>
      <InsuranceTreatment>
        {infoContents.supportsInsuranceTreatment ? (
          <CustomIconInsurance>
            {AppointmentInfoResource.j001}
          </CustomIconInsurance>
        ) : (
          <CustomIconNotInsurance>
            {AppointmentInfoResource.j002}
          </CustomIconNotInsurance>
        )}
      </InsuranceTreatment>
      <DoctorName>
        {isCentralClinicWithDifferentDoctor && isOnlineAppointment ? null : (
          <>
            {AppointmentInfoResource.j003}
            {isOnlineAppointment
              ? `${infoContents.doctor.familyName} ${infoContents.doctor.givenName}`
              : AppointmentInfoResource.j004}
          </>
        )}
      </DoctorName>
    </div>
  );
};
