import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { DELIVERY_METHOD } from "../../../Utils/const";

interface Props {
  appo: Appointment;
  handleOpenMedicineDeliveryDialog: (appointmentSelect: Appointment) => void;
}

interface Appointment {
  medicineDelivery: MedicineDelivery;
}

interface MedicineDelivery {
  deliveryMethod: string;
}

const Resource =
  japaneseList.Components.Payment.HospitalPaymentList.render
    .medicineDeliveryButton;

const TblCellMenu = styled.td`
  text-align: center;
  padding: 5px 10px;
  border-left: 1px solid #cccccc;
  min-width: 116px;
`;

const TblCellMenuText = styled.div`
  color: #2563c0;
  text-decoration: underline;
  cursor: pointer;
`;

const HospitalPaymentRowMedicineDelivery = (props: Props) => {
  const { appo, handleOpenMedicineDeliveryDialog } = props;
  return (
    <TblCellMenu>
      {appo.medicineDelivery ? (
        <TblCellMenuText
          id="prescription-desired-destination-link"
          onClick={(e) => handleOpenMedicineDeliveryDialog(appo)}
        >
          {appo.medicineDelivery.deliveryMethod === DELIVERY_METHOD.DELIVERY
            ? Resource.j001
            : appo.medicineDelivery.deliveryMethod ===
              DELIVERY_METHOD.GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME
            ? Resource.j002
            : Resource.j003}
        </TblCellMenuText>
      ) : (
        Resource.j004
      )}
    </TblCellMenu>
  );
};

export default HospitalPaymentRowMedicineDelivery;
