import { Component } from "react";
import styled from "styled-components";

import { withStyles, WithStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { japaneseList } from "../../../Resources/japaneseList";
import { IconImportant } from "../../atoms/icons/IconImportant";
import { IconCategory } from "../../atoms/icons/IconCategory";
import format from "date-fns/format";
import { ja } from "date-fns/locale";
import { NewsContent } from "../../organisms/news/NewsContent";

const Resources =
  japaneseList.Components.Appointment.AppointmentList.ConsentRegistrationDialog;
const styles = () => ({
  dialog: {
    maxWidth: "810px",
    width: "100%",
    wordBreak: "break-word" as const,
  },
  dialogTitle: {
    padding: "20px 24px 20px 24px",
    textAlign: "left" as const,
    fontWeight: 600 as const,
  },
  dialogContent: {
    padding: "21px 24px 20px 24px",
  },
  dialogActions: {
    margin: "14px 20px 15px 20px",
  },
  button: {
    width: "120px",
    height: "40px",
    color: "#ffffff",
    backgroundColor: "#808080",
    fontFamily: "Hiragino Kaku Gothic ProN !important",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "21px",
    opacity: "1",
    "&:hover": {
      backgroundColor: "#a6a6a6",
    },
  },
  hr: {
    margin: "0px 0px 0px",
    maxWidth: "100%",
    height: "0.8px",
    backgroundColor: "#cccccc",
    borderStyle: "solid",
    borderWidth: "0 0 1px 0",
    borderColor: "#CCCCCC",
  },
});
const CustomFont = styled.div`
  margin-top: 20px;
  font-family: Hiragino Kaku Gothic ProN;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  p {
    color: #393939;
    font-family: Hiragino Kaku Gothic ProN;
    font-size: 14px;
    text-align: left;
    line-height: 21px;
    margin: 0;
  }
  a {
    color: #2563c0;
    :hover {
      text-decoration: none !important;
    }
  }
`;
const TitleContainer = styled.span`
  font-family: Hiragino Kaku Gothic ProN;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
`;
const DisplayDatetime = styled.span`
  margin-right: 4px;
  font-family: Hiragino Kaku Gothic ProN;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
`;
interface News {
  id: string;
  title: string;
  activateDatetime: Date;
  isImportant: string;
  category: string;
  content: string;
}
interface Props extends WithStyles<typeof styles> {
  isOpen: boolean;
  news: News;
  handleClose: () => void;
}
class DialogNewsDetail extends Component<Props> {
  onClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { classes, isOpen, news, handleClose } = this.props;
    const Datetime = format(new Date(news.activateDatetime), "yyyy/MM/dd", {
      locale: ja,
    });
    return (
      <Dialog
        open={isOpen}
        maxWidth={"sm"}
        PaperProps={{ classes: { root: classes.dialog } }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <TitleContainer>{`${news.title}`}</TitleContainer>
        </DialogTitle>
        <hr className={classes.hr} />
        <DialogContent className={classes.dialogContent}>
          <DisplayDatetime>{Datetime}</DisplayDatetime>
          {news && news.isImportant && <IconImportant />}
          <IconCategory category={news.category} />
          <CustomFont>
            <NewsContent content={news.content}></NewsContent>
          </CustomFont>
        </DialogContent>
        <hr className={classes.hr} />
        <DialogActions className={classes.dialogActions}>
          <Button
            id="news-dialog-close-button"
            className={classes.button}
            onClick={handleClose}
          >
            {Resources.close}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogNewsDetail);
