import styled from "styled-components";

const LinkA = styled.a`
  color: ${(p) =>
    p.color && p.theme.font.color[p.color]
      ? p.theme.font.color[p.color]
      : p.theme.font.color.link};
  cursor: pointer;
`;

export default LinkA;
