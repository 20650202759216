import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import querystring from "querystring";
import {
  client,
  GetPatientByPatientId,
  GetMedicalHistory,
  GetHospital,
} from "../../../graphql";
import { APPOINTMENT_STATUS } from "../../../Utils/const";
import { InfoBar } from "../../organisms/patient/InfoBar";
import { DialogPatientInfo } from "../../molecules/dialogs/DialogPatientInfo";
import { InsuranceCardArea } from "../../organisms/patient/InsuranceCardArea";
import { MedicalHistory } from "../../organisms/patient/MedicalHistory";
import { MedicalDetailArea } from "../../organisms/patient/MedicalDetailArea";
import { ConsentStatusArea } from "../../organisms/patient/ConsentStatusArea";
import HeaderlessTemplete from "../../templates/HeaderlessTemplete";
import Titles from "../../../Resources/Titles";
import { Auth } from "aws-amplify";

const CONTENTS_STATUS_LIST = [
  APPOINTMENT_STATUS.BEFORE_EXAM,
  APPOINTMENT_STATUS.EXAM_COMPLETED,
  APPOINTMENT_STATUS.PAYMENT_COMPLETED,
  APPOINTMENT_STATUS.CANCELED_DOCTOR,
  APPOINTMENT_STATUS.CANCELED_PATIENT_PREVIOUS_DAY,
  APPOINTMENT_STATUS.CANCELED_PATIENT_FROM_DAY,
  APPOINTMENT_STATUS.UNAPPROVED,
  APPOINTMENT_STATUS.REJECTED,
  APPOINTMENT_STATUS.CANCELED_BEFORE_APPROVING,
];

const RootWrapper = styled.div`
  width: 100%;
`;

const MainArea = styled.div`
  display: flex;
  padding: 0px 30px;
`;

const InfoBarDiv = styled.div`
  width: 100%;
  height: 69px;
`;

const LeftArea = styled.div`
  margin-top: 30px;
  margin-right: 20px;
  width: 212px;
  & > div {
    margin-bottom: 16px;
  }
`;

const InsuranceCardDiv = styled.div`
  height: 236px;
`;

const ConsentStatusDiv = styled.div`
  width: 212px;
`;

const MedicalHistoryDiv = styled.div`
  height: 85vh;
  margin-right: 10px;
  width: calc(50vw - 280px);
  min-width: 200px;
  height: calc(100vh - 122px);
`;

const MedicalInterviewDiv = styled.div`
  margin-top: 30px;
  width: calc(50vw - 22px);
  height: calc(100vh - 152px);
`;

export const PatientPage = () => {
  const [isPharmacy, setIsPharmacy] = useState(undefined);
  const [patient, setPatient] = useState({});
  const [isOpenDialogPatientInfo, setIsOpenDialogPatientInfo] = useState(false);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [isExistMedicalHistory, setIsExistMedicalHistory] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [isHospitalLoading, setIsHospitalLoading] = useState(true);
  const [isPatientLoading, setIsPatientLoading] = useState(true);
  const [isInsuranceCardLoading, setIsInsuranceCardLoading] = useState(true);
  const [isMedicalHistoryLoading, setIsMedicalHistoryLoading] = useState(true);
  const [isInteviewSheetAnswerLoading, setIsInteviewSheetAnswerLoading] =
    useState(true);
  const [
    isSupportOnlineQualificationSystem,
    setIsSupportOnlineQualificationSystem,
  ] = useState(false);

  const location = useLocation();
  const CHECK_LOGIN_INTERVAL = 5000;
  const { patientId } = querystring.parse(location.search.substring(1));

  useEffect(() => {
    loadHospital();
    loadPatient(patientId);
    loadMedicalHistory(patientId);
    // ログアウトを検知してログインページに移動するためのインターバル処理
    const intervalID = setInterval(() => {
      handleSignout();
    }, CHECK_LOGIN_INTERVAL);
    return () => {
      clearInterval(intervalID);
    };
  }, [patientId]);

  const loadHospital = async () => {
    const { data, errors } = await client.query({
      query: GetHospital,
    });

    if (errors) {
      console.error("GetHospitalInfo errors:", JSON.stringify(errors));
    }
    if (data && data.hospital) {
      setIsSupportOnlineQualificationSystem(
        data.hospital.isSupportOnlineQualificationSystem
      );
      if (data.hospital.facilityType === "pharmacy") setIsPharmacy(true);
      else setIsPharmacy(false);
    }
    setIsHospitalLoading(false);
  };

  const loadPatient = async (patientId) => {
    const { data, errors } = await client.query({
      query: GetPatientByPatientId,
      variables: { patientId },
    });

    if (errors) {
      console.error("GetPatientInfo errors:", JSON.stringify(errors));
    }
    if (data && data.patient) {
      setPatient(data.patient);
    }
    setIsPatientLoading(false);
  };

  const loadMedicalHistory = async (patientId) => {
    const { data, errors } = await client.query({
      query: GetMedicalHistory,
      variables: {
        patientId,
        status: CONTENTS_STATUS_LIST,
      },
    });
    if (errors) {
      console.error("GetMedicalHistory errors:", JSON.stringify(errors));
    }

    if (data && data.appointments) {
      const medicalHistoryList = (data.appointments.items || [])
        .slice()
        .sort((a, b) => {
          return new Date(b.from) - new Date(a.from);
        });

      if (medicalHistoryList.length > 0) {
        setMedicalHistory(medicalHistoryList);
        setSelectedAppointment(medicalHistoryList[0]);
        setIsExistMedicalHistory(true);
      } else {
        setIsInteviewSheetAnswerLoading(false);
      }
    }
    setIsMedicalHistoryLoading(false);
  };

  const handleSignout = () => {
    Auth.currentUserPoolUser()
      .then((user) => {
        if (!user) {
          window.location.reload();
        }
      })
      .catch(() => {
        window.location.reload();
      });
  };

  const handleSelectAppointment = (createTime) => {
    const selectedAppo = medicalHistory.find(
      (element) => element.createTime === createTime
    );
    setSelectedAppointment(selectedAppo);
  };

  const openDialogPatientInfo = () => {
    setIsOpenDialogPatientInfo(true);
  };

  const closeDialogPatientInfo = () => {
    setIsOpenDialogPatientInfo(false);
  };

  const isLoading =
    isHospitalLoading ||
    isPatientLoading ||
    isInsuranceCardLoading ||
    isMedicalHistoryLoading ||
    isInteviewSheetAnswerLoading;

  const main = (
    <React.Fragment>
      <RootWrapper>
        <InfoBarDiv>
          <InfoBar
            patient={patient}
            openDialogPatientInfo={openDialogPatientInfo}
          />
        </InfoBarDiv>
        <MainArea>
          <LeftArea>
            <InsuranceCardDiv>
              {patient && (
                <InsuranceCardArea
                  patient={patient}
                  setIsInsuranceCardLoading={setIsInsuranceCardLoading}
                />
              )}
            </InsuranceCardDiv>
            {isSupportOnlineQualificationSystem && (
              <ConsentStatusDiv>
                <ConsentStatusArea patientId={patient.patientId} />
              </ConsentStatusDiv>
            )}
          </LeftArea>
          <MedicalHistoryDiv>
            <MedicalHistory
              medicalHistory={medicalHistory}
              handleSelectAppointment={handleSelectAppointment}
              isPharmacy={isPharmacy}
            />
          </MedicalHistoryDiv>
          <MedicalInterviewDiv>
            <MedicalDetailArea
              patient={patient}
              selectedAppointment={selectedAppointment}
              isExistMedicalHistory={isExistMedicalHistory}
              setIsInteviewSheetAnswerLoading={setIsInteviewSheetAnswerLoading}
            />
          </MedicalInterviewDiv>
        </MainArea>
      </RootWrapper>
      <DialogPatientInfo
        open={isOpenDialogPatientInfo}
        onClose={closeDialogPatientInfo}
        handleClickButton={closeDialogPatientInfo}
        patient={patient}
      />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <HeaderlessTemplete
        main={main}
        title={Titles.patient}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};
