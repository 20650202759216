import { getUnsupportedDeviceInfo } from "../../../Utils/UnsupportedDevice";
import {
  GetMediaDeviceStatus,
  MediaDeviceStatus,
} from "../../../Utils/MediaDevice";
import { APPOINTMENT_STATUS } from "../../../Utils/const";
import { isSameDay, parseISO } from "date-fns";

const VideoStandbyStatus = {
  start: "start",
  resume: "resume",
  end: "end",
  notTime: "notTime",
};

const isBeforeExam = (appointment) => {
  // TODO:今後APPOINTMENT_STATUSを使うように横並びで対応する
  return appointment.status === "beforeExam";
};

const isExamCompleted = (appointment) => {
  // TODO:今後APPOINTMENT_STATUSを使うように横並びで対応する
  return appointment.status === "examCompleted";
};

const isPaymentCompleted = (appointment) => {
  return appointment.status === APPOINTMENT_STATUS.PAYMENT_COMPLETED;
};

const handleDoctorReady = async () => {
  let isDoctorReady = false,
    isWarningBrowser = true,
    isShowMediaDevicePermissionAlert = true;
  let { alertArray } = await getUnsupportedDeviceInfo();
  const { mediaDeviceStatus, mediaDeviceError } = await GetMediaDeviceStatus(
    true
  );

  if (alertArray.length === 0) {
    isWarningBrowser = false;
  }

  if (mediaDeviceStatus === MediaDeviceStatus.accepted) {
    isShowMediaDevicePermissionAlert = false;
  }

  if (!isShowMediaDevicePermissionAlert) {
    isDoctorReady = true;
  }

  return {
    isDoctorReady,
    isWarningBrowser,
    isShowMediaDevicePermissionAlert,
    mediaDeviceError,
  };
};

const handlePatientReady = (
  appointment,
  patientStandbyStatus,
  videoStandbyStatus
) => {
  const { isLogin, videoStandby } = patientStandbyStatus;
  if (isLogin && !videoStandby.isMediaDeviceBlocked) {
    if (isResume(videoStandbyStatus, appointment)) {
      return true;
    } else if (
      videoStandby.isOpeningStandbyPage &&
      videoStandby.isMediaDeviceAccepted
    ) {
      return true;
    }
  }

  return false;
};

const isResume = (videoStandbyStatus, appointment) => {
  if (videoStandbyStatus === VideoStandbyStatus.resume) {
    return true;
  }
  // 該当予約の接続情報が残っている場合は再開として判断する
  const videoConnectingInfo = localStorage.getItem("videoConnectingInfo");
  if (videoConnectingInfo) {
    const [patientId, createTime] = videoConnectingInfo.split("|");
    if (
      patientId === appointment.patient.patientId &&
      createTime === appointment.createTime
    ) {
      return true;
    }
  }
  return false;
};

const handleStandbyStatus = (appointment, currentStatus) => {
  const today = new Date();
  const isTheSameDay = isSameDay(today, parseISO(appointment.to));
  let videoStandbyStatus;
  switch (currentStatus) {
    case "duringAppointmentTime":
      if (isBeforeExam(appointment)) {
        videoStandbyStatus = VideoStandbyStatus.start;
      } else if (isExamCompleted(appointment)) {
        videoStandbyStatus = VideoStandbyStatus.resume;
      } else {
        videoStandbyStatus = VideoStandbyStatus.end;
      }
      break;
    case "afterAppointmentTime":
      if (isExamCompleted(appointment) && isTheSameDay) {
        videoStandbyStatus = VideoStandbyStatus.resume;
      } else {
        videoStandbyStatus = VideoStandbyStatus.end;
      }
      break;
    case "beforeAppointmentTime":
      videoStandbyStatus = VideoStandbyStatus.notTime;
      break;
    case "examCompleted":
      if (isPaymentCompleted(appointment)) {
        videoStandbyStatus = VideoStandbyStatus.end;
      } else {
        videoStandbyStatus = VideoStandbyStatus.resume;
      }
      break;
    default:
      videoStandbyStatus = VideoStandbyStatus.notTime;
      break;
  }
  return videoStandbyStatus;
};

export {
  handleStandbyStatus,
  handleDoctorReady,
  handlePatientReady,
  VideoStandbyStatus,
};
