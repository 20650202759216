import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import FontP from "../../atoms/fonts/FontP";
import { japaneseList } from "../../../Resources/japaneseList";
import { VideoStandbyStatus } from "../../pages/medical/VideoStandbyStatus";
const Resources = japaneseList.molecules.dialogs.DialogCallingUnready;

const styles = () => ({
  dialogTitle: {
    padding: "24px",
    textAlign: "left",
    fontWeight: "bold",
  },
  dialogContent: {
    padding: "0 25px 30px 25px",
  },
  dialogActions: {
    margin: "15px 20px 20px 20px",
  },
  button: {
    width: "120px",
    color: "#ffffff",
    boxShadow: "0px 2px 0px #505050",
    backgroundColor: "#808080",
    opacity: "1",
    "&:hover": {
      backgroundColor: "#a6a6a6",
    },
  },
  hr: {
    margin: "0px 0px 0px",
    maxWidth: "100%",
    height: "0.8px",
    backgroundColor: "#cccccc",
    border: 0,
  },
});
const CustomFontP = styled(FontP)`
  margin-bottom: 20px;
`;

class DialogPatientUnready extends Component {
  render() {
    const {
      classes,
      open,
      onClose,
      handleClickButton,
      patientStandbyStatus,
      videoStandbyStatus,
    } = this.props;
    const { isLogin, videoStandby } = patientStandbyStatus;
    let isResume = false;

    if (videoStandbyStatus === VideoStandbyStatus.resume) {
      isResume = true;
    }

    return (
      <Dialog open={open} onClose={onClose} maxWidth={"sm"}>
        <DialogTitle className={classes.dialogTitle}>
          {Resources.title.patient}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <CustomFontP size="s">{Resources.content.patient.text}</CustomFontP>
          {!isLogin && (
            <FontP size="s">{Resources.content.patient.condition.j001}</FontP>
          )}
          {!videoStandby.isOpeningStandbyPage && !isResume && (
            <FontP size="s">{Resources.content.patient.condition.j002}</FontP>
          )}
          {(videoStandby.isMediaDeviceBlocked ||
            (!isResume && !videoStandby.isMediaDeviceAccepted)) && (
            <FontP size="s">{Resources.content.patient.condition.j003}</FontP>
          )}
        </DialogContent>
        <hr className={classes.hr} />
        <DialogActions className={classes.dialogActions}>
          <Button className={classes.button} onClick={handleClickButton}>
            {Resources.button}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogPatientUnready);
