import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import PasswordPolicy from "../../molecules/others/PasswordPolicy";
import LinkA from "../../atoms/links/LinkA";
import { japaneseList } from "../../../Resources/japaneseList";

const NewPasswordResource = japaneseList.Authenticator.NewPassword;
const styles = (theme) => {
  return {
    root: {
      maxWidth: "360px",
      marginTop: "69px",
    },
    item: {
      display: "block",
      marginTop: theme.spacing.unit * 2,
    },
    passwordPolicy: {
      marginTop: "22px",
    },
    buttonLink: {
      display: "block",
      marginTop: "16px",
      fontSize: "12px",
    },
    formControlLabel: {
      textAlign: "left",
      fontSize: "12px",
      letterSpacing: "0px",
      color: "#393939",
      opacity: 1,
      marginLeft: "8px",
      marginTop: "1px",
      fontFamily: "inherit",
    },
    formControlRoot: {
      marginLeft: "0px",
      marginTop: "20px",
    },
    input: {
      backgroundColor: theme.color.primaryVariant3,
      border: `1px solid #DDDDDD`,
      borderRadius: "3px",
      padding: "1px",
    },
    label: {
      paddingBottom: "5px",
      color: "#808080",
      fontSize: "12px",
      fontWeight: "bold",
      fontFamily: "inherit",
      transform: "scale(1)",
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      fontFamily: "inherit",
      marginBottom: "40px",
      color: "#333333",
    },
    inputPropsStyle: {
      WebkitBoxShadow: `0 0 0 30px ${theme.color.primaryVariant3} inset !important`,
      backgroundClip: "content-box",
      height: "14px",
      padding: "10px",
    },
    button: {
      color: theme.button.ok.color,
      marginRight: "0px",
      backgroundColor: theme.button.ok.backgroundColor,
      boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
      borderRadius: "6px",
      opacity: "1",
      margin: "0px",
      "&:hover": {
        backgroundColor: theme.button.ok.backgroundColorHover,
      },
      fontWeight: "bold",
      marginTop: "25px",
    },
    checkboxRoot: {
      color: "#CCCCCC",
      width: "18px",
      height: "18px",
      background: "#EEEEEE 0% 0% no-repeat padding-box",
      opacity: 1,
      "&:hover": {
        background: "#EEEEEE 0% 0% no-repeat padding-box",
      },
    },
    checked: {
      color: theme.color.primary1 + "!important",
    },
  };
};

const LinkAStyle = styled(LinkA)`
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0px;
  color: #2563c0;
  opacity: 1;
  &:hover {
    text-decoration: none;
  }
`;

/** Specify background color for set forgot password page only.  */
const GlobalStyle = createGlobalStyle`
  body {
    background-color: #ffffff !important;
  }
`;

const NewPassword = (props) => {
  const {
    classes, 
    returnToLoginPage,
    isEnabledSubmitButton,
    isShowPassword,
    handleInputChange,
    handleTogglePassword,
    handleSubmit,
  } = props;

  return (
    <React.Fragment>
      <Grid
        container
        className={classes.root}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="title"
            gutterBottom
            align="center"
            className={classes.title}
          >
            {NewPasswordResource.render.Grid.Grid.Typography.j001}
          </Typography>
          <TextField
            className={classes.item}
            fullWidth
            name="password"
            type={isShowPassword ? "text" : "password"}
            label={NewPasswordResource.render.Grid.Grid.TextField.j001}
            onChange={(e) => handleInputChange(e)}
            // Properties applied to the Input element
            InputProps={{
              disableUnderline: true,
              className: classes.input,
            }}
            InputLabelProps={{
              shrink: true,
              focused: false,
              className: classes.label,
            }}
            // Attributes applied to the native input element
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ className: classes.inputPropsStyle }}
          />
          <TextField
            className={classes.item}
            fullWidth
            name="passwordConfirm"
            type={isShowPassword ? "text" : "password"}
            label={NewPasswordResource.render.Grid.Grid.TextField.j002}
            onChange={(e) => handleInputChange(e)}
            // Properties applied to the Input element
            InputProps={{
              disableUnderline: true,
              className: classes.input,
            }}
            InputLabelProps={{
              shrink: true,
              focused: false,
              className: classes.label,
            }}
            // Attributes applied to the native input element
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ className: classes.inputPropsStyle }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isShowPassword"
                classes={{
                  root: classes.checkboxRoot,
                  checked: classes.checked,
                }}
              />
            }
            classes={{
              root: classes.formControlRoot,
              label: classes.formControlLabel,
            }}
            onChange={() => handleTogglePassword()}
            label={NewPasswordResource.render.Grid.Grid.CheckBox.j001}
          />
          <div className={classes.passwordPolicy}>
            <PasswordPolicy />
          </div>
          <Button
            className={`${classes.item} ${classes.button}`}
            variant="raised"
            color="primary"
            fullWidth
            onClick={() => handleSubmit()}
            disabled={!isEnabledSubmitButton}
          >
            {NewPasswordResource.render.Grid.Grid.Button.j001}
          </Button>
          <LinkAStyle
            className={classes.buttonLink}
            onClick={() => returnToLoginPage()}
          >
            {NewPasswordResource.render.Grid.Grid.Button.Typography.j001}
          </LinkAStyle>
        </Grid>
      </Grid>
      <GlobalStyle />
    </React.Fragment>
  );
}

export default withStyles(styles)(NewPassword);
