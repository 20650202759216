import React, { useState } from "react";
import {
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { japaneseList } from "../../../../Resources/japaneseList";
import { formatDate } from "../../../../Utils/DateUtil";
import PatientInfo from "../../dialog/PatientInfo";

import LinkA from "../../../atoms/links/LinkA";
import DialogCancelConfirm from "../../../molecules/dialogs/DialogCancelConfirm";
import IconInsurance from "../../../atoms/icons/IconInsurance";
import IconNotInsurance from "../../../atoms/icons/IconNotInsurance";
import { LoadingScreenForDialog } from "../../../molecules/others/LoadingScreenForDialog";

const AppointmentConfirmResource =
  japaneseList.Components.Appointment.Stepper.AppointmentConfirm;
const InsuranceResource = japaneseList.insurance;

const styles = (theme) => {
  return {
    dialogTitle: {
      width: "810px",
      height: "68px",
      margin: "0",
      padding: "24px 0 24px 24px",
      borderBottom: "1px solid #cccccc",
    },
    dialogContent: {
      width: "810px",
      margin: "0",
      padding: "20px 25px 20px 24px",
    },
    dialogActions: {
      width: "810px",
      height: "70px",
      margin: "0",
      padding: "14px 25px 16px 24px",
      borderTop: "1px solid #cccccc",
      "& button": {
        height: "40px",
      },
      "& span": {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#ffffff",
      },
    },
    buttonBack: {
      fontSize: "14px",
      position: "absolute",
      left: "24px",
      color: "#2563c0",
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none",
      },
    },
    buttonOk: {
      backgroundColor: theme.button.ok.backgroundColor,
      boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
      "&:hover": {
        backgroundColor: theme.button.ok.backgroundColorHover,
      },
    },
    dialogActionsButtonOk: {
      width: "120px",
      margin: "0",
      padding: "13px 46px",
      borderRadius: "5px",
    },
    buttonCancel: {
      backgroundColor: "#808080",
      boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
      "&:hover": {
        backgroundColor: theme.button.cancel.backgroundColorHover,
      },
    },
    dialogActionsButtonCancel: {
      width: "120px",
      margin: "0 20px 0 0",
      padding: "13px 25px",
      borderRadius: "5px",
    },
    error: {
      color: "red",
      margin: "0",
    },
    iconInsurance: {
      fontSize: "10px",
      border: `1px solid ${theme.color.primary1}`,
      float: "left",
      marginLeft: "5px",
    },
    iconNotInsurance: {
      fontSize: "10px",
      border: `1px solid ${theme.color.notInsurance}`,
      float: "left",
      marginLeft: "5px",
    },
  };
};

const DialogTitleText = styled.div`
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
`;

const DialogContentText = styled.div`
  height: 14px;
  line-height: 14px;
  font-size: 14px;
`;

const PatientArea = styled.div`
  margin: 25px 0 0 0;
`;

const AppointmentArea = styled.div`
  margin: 20px 0 0 0;
`;

const Container = styled.div`
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 64px auto;
  border-top: 1px solid #cccccc;
  height: 35px;
`;

const Title = styled.div`
  background-color: #eeeeee;
  padding-left: 6px;
  color: #808080;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`;

const Content = styled.div`
  padding-left: 20px;
  color: #393939;
  text-align: left;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-word;
`;

const DisplayName = styled.div`
  float: left;
  font-size: 14px;
  border: none;
  text-align: left;
  display: inline;
  padding: 3px 4px 3px 0;
  color: #000;
`;

const AppointmentConfirm = (props) => {
  const { classes, step, appointment, errors } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayDialogCancelConfirm, setDisplayDialogCancelConfirm] =
    useState(false);
  const isNotDisplayInsurance =
    appointment.selectedMenu.menuName.indexOf("/") === -1 ? true : false;
  const haveInsurance =
    !isNotDisplayInsurance &&
    appointment.selectedMenu.menuName.split("/")[1] === InsuranceResource.ok
      ? true
      : false;
  const date =
    formatDate(
      props.appointment.from.toISOString(),
      AppointmentConfirmResource.DialogContent.ListItem.Format.j001
    ) +
    formatDate(
      props.appointment.to.toISOString(),
      AppointmentConfirmResource.DialogContent.ListItem.Format.j002
    );

  const handleRegisterAppointment = async () => {
    if (isSubmitting) {
      return false;
    }
    setIsSubmitting(true);
    const { result } = await props.handleRegisterAppointment({
      patient: props.appointment.patient,
      from: props.appointment.from,
      to: props.appointment.to,
      hasCharge: props.appointment.hasCharge,
      menuId: props.appointment.selectedMenu.menuId,
    });
    if (result === "failed" || result === "confirming") {
      setIsSubmitting(false);
    }
  };

  const openDialogCancelConfirm = () => {
    setDisplayDialogCancelConfirm(true);
  };

  const closeDialogCancelConfirm = () => {
    setDisplayDialogCancelConfirm(false);
  };

  return (
    <React.Fragment>
      <DialogTitle className={classes.dialogTitle}>
        <DialogTitleText>
          {AppointmentConfirmResource.DialogTitle.j001}
        </DialogTitleText>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          {AppointmentConfirmResource.DialogContent.Text.j001}
        </DialogContentText>
        <PatientArea>
          <PatientInfo patient={appointment.patient} />
        </PatientArea>
        <AppointmentArea>
          <Container>
            <Item>
              <Title>
                {AppointmentConfirmResource.DialogContent.ListItem.Text.j001}
              </Title>
              <Content>{appointment.doctor.name}</Content>
            </Item>
            <Item>
              <Title>
                {AppointmentConfirmResource.DialogContent.ListItem.Text.j002}
              </Title>
              <Content>{date}</Content>
            </Item>
            <Item>
              <Title>
                {AppointmentConfirmResource.DialogContent.ListItem.Text.j003}
              </Title>
              {isNotDisplayInsurance ? (
                <Content>{appointment.selectedMenu.menuName}</Content>
              ) : (
                <Content>
                  {haveInsurance ? (
                    <div>
                      <DisplayName>
                        {appointment.selectedMenu.menuName.split("/")[0]}
                      </DisplayName>
                      <IconInsurance className={classes.iconInsurance}>
                        {InsuranceResource.ok}
                      </IconInsurance>
                    </div>
                  ) : (
                    <div>
                      <DisplayName>
                        {appointment.selectedMenu.menuName.split("/")[0]}
                      </DisplayName>
                      <IconNotInsurance className={classes.iconNotInsurance}>
                        {InsuranceResource.no}
                      </IconNotInsurance>
                    </div>
                  )}
                </Content>
              )}
            </Item>
          </Container>
        </AppointmentArea>
        {errors.map((error, index) => (
          <Typography
            variant="subheading"
            gutterBottom
            className={classes.error}
            key={index}
          >
            {error}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <LinkA className={classes.buttonBack} onClick={() => step(1)}>
          {AppointmentConfirmResource.DialogActions.Button.j001}
        </LinkA>
        <Button
          className={`${classes.buttonCancel} ${classes.dialogActionsButtonCancel}`}
          onClick={() => openDialogCancelConfirm()}
        >
          {AppointmentConfirmResource.DialogActions.Button.j002}
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={`${classes.buttonOk} ${classes.dialogActionsButtonOk}`}
          onClick={() => handleRegisterAppointment()}
          disabled={isSubmitting}
        >
          {AppointmentConfirmResource.DialogActions.Button.j003}
        </Button>
      </DialogActions>
      <DialogCancelConfirm
        open={displayDialogCancelConfirm}
        closeDialogCancelConfirm={closeDialogCancelConfirm}
        handleClose={props.closeScheduleSelectionPopup}
      />
      <LoadingScreenForDialog isLoading={props.isLoading} />
    </React.Fragment>
  );
};

export default withStyles(styles)(AppointmentConfirm);
