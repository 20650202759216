import React from "react";
import NumberFormat from "react-number-format";
import { japaneseList } from "../../../Resources/japaneseList";

export default function CustomNumber(props) {
  const { value, ...other } = props;

  return (
    <NumberFormat
      className="digit"
      {...other}
      value={value}
      displayType={"text"}
      thousandSeparator={true}
      suffix={japaneseList.Components.Payment.CustomNumber.NumberFormat.j001}
    />
  );
}
