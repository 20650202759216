import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { japaneseList } from "../../../Resources/japaneseList";

const DialogMidCancleResource =
  japaneseList.molecules.dialogs.DialogCancelConfirm;

const styles = (theme) => {
  return {
    dialogContent: {
      width: "500px",
      margin: "0",
      padding: "24px 0 24px 24px",
      borderBottom: "1px solid #cccccc",
    },
    dialogActions: {
      width: "500px",
      height: "70px",
      margin: "0",
      padding: "14px 25px 16px 24px",
      "& button": {
        height: "40px",
      },
      "& span": {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#ffffff",
      },
    },
    buttonEnd: {
      backgroundColor: theme.button.ok.backgroundColor,
      boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
      "&:hover": {
        backgroundColor: theme.button.ok.backgroundColorHover,
      },
    },
    dialogActionsButtonEnd: {
      width: "120px",
      margin: "0",
      padding: "13px 46px",
      borderRadius: "5px",
    },
    buttonCancel: {
      backgroundColor: "#808080",
      boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
      "&:hover": {
        backgroundColor: theme.button.cancel.backgroundColorHover,
      },
    },
    dialogActionsButtonCancel: {
      width: "120px",
      margin: "0 20px 0 0",
      padding: "13px 25px",
      borderRadius: "5px",
    },
  };
};

const Text = styled.div`
  font-size: 14px;
  color: #393939;
`;

const RemindText = styled.div`
  font-size: 12px;
  color: #f26666;
`;

const DialogCancelConfirm = (porps) => {
  const { classes, open, closeDialogCancelConfirm, handleClose } = porps;

  const onEndClick = () => {
    closeDialogCancelConfirm();
    handleClose();
  };

  return (
    <Dialog disableBackdropClick open={open}>
      <DialogContent className={classes.dialogContent}>
        <Text>{DialogMidCancleResource.Text.j001}</Text>
        <RemindText>{DialogMidCancleResource.Text.j002}</RemindText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={`${classes.buttonCancel} ${classes.dialogActionsButtonCancel}`}
          onClick={closeDialogCancelConfirm}
        >
          {DialogMidCancleResource.Button.j001}
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={`${classes.buttonEnd} ${classes.dialogActionsButtonEnd}`}
          onClick={onEndClick}
        >
          {DialogMidCancleResource.Button.j002}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DialogCancelConfirm);
