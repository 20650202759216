import * as _ from "lodash";

export class FileUploadUtility {
  // Check file size default maxFileSize = 2097152 //2Mb
  static isInvalidFileSize = (file, maxFileSize = 2097152) => {
    return file.size > maxFileSize;
  };

  // Check file type. Valid values are jpg, jpeg, png
  static isInvalidFileExtension = (
    file,
    validExtensions = ["image/png", "image/jpg", "image/jpeg"]
  ) => {
    return !validExtensions.includes(file.type);
  };

  // Check file name too long default 20 character
  static isFileNameTooLong = (file, maxFileNameLength = 20) => {
    const nameFile = FileUploadUtility.getFileName(file);
    if (nameFile.name.length > maxFileNameLength) {
      // Check file name too long
      if (!_.isEmpty(file.niceName.trim())) {
        return true;
      }
    }
    return false;
  };

  static isLocalFileNameTooLong = (file, maxFileNameLength = 20) => {
    const nameFile = FileUploadUtility.getFileName(file);
    if (nameFile.name.length > maxFileNameLength) {
      // Check local file name too long
      if (_.isEmpty(file.niceName.trim())) {
        return true;
      }
    }
    return false;
  };

  // Check file name Valid
  static isUnusableFileName = (file) => {
    const nameFile = FileUploadUtility.getFileName(file);
    // reg exp validate text input
    const regexp = /[¥/:*?"<>|.]/g;
    const checkingResult = regexp.test(nameFile.name);
    if (checkingResult) {
      //"unusableFileName"
      return true;
    }
    return false;
  };

  static getFileName = (item) => {
    let file = {
      name: "",
      extension: "",
    };
    if (!_.isUndefined(item.file.name)) {
      file.extension = _.last(item.file.name.split("."));
      file.name = item.file.name.slice(
        0,
        item.file.name.length - (file.extension.length + 1)
      );
      if (!_.isEmpty(item.niceName.trim())) {
        file.name = item.niceName.trim();
      }
    }
    return file;
  };

  static getLowerCaseFileName = (item) => {
    let name = undefined;
    if (item.file.name) {
      const fileName = FileUploadUtility.getFileName(item);
      name =
        fileName.name.toLowerCase() + "." + fileName.extension.toLowerCase();
    } else if (item.file.fileName) {
      name = item.file.fileName.toLowerCase();
    }
    return name;
  };

  static checkExistFile = async (file) => {
    try {
      await FileUploadUtility.readFile(file);
      return true;
    } catch (e) {
      return false;
    }
  };

  static readFile = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (res) => {
        resolve(res.target.result);
      };
      reader.onerror = (err) => reject(err);

      reader.readAsText(file);
    });
  };
}
