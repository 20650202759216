import React, { useState } from "react";
import { Auth, Hub } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import NewPassword from "../../organisms/authenticator/NewPassword";
import LogoOnlyHeaderTemplate from "../../templates/LogoOnlyHeaderTemplate";
import { japaneseList } from "../../../Resources/japaneseList";
import Titles from "../../../Resources/Titles";

const NewPasswordResource = japaneseList.Authenticator.NewPassword;

const NewPasswordPage = ({ user, authcb }) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isEnabledSubmitButton, setIsEnabledSubmitButton] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const returnToLoginPage = () => {
    authcb("SIGN_IN");
  };

  const handleTogglePassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    if (name === "password") {
      setPassword(value);
      if (value && passwordConfirm) {
        setIsEnabledSubmitButton(true);
      } else {
        setIsEnabledSubmitButton(false);
      }
    } else if (name === "passwordConfirm") {
      setPasswordConfirm(value);
      if (value && password) {
        setIsEnabledSubmitButton(true);
      } else {
        setIsEnabledSubmitButton(false);
      }
    }
  };

  const displayErrorAsSnackBar = (err) => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: err, level: "error" } },
      "NewPassword"
    );
    // ボタン連打対応
    setIsEnabledSubmitButton(false);
  };

  const handleSubmit = () => {
    Sentry.captureMessage("password-setnew", Sentry.Severity.Log);
    const { requiredAttributes } = user.challengeParam;
    const { email } = user.challengeParam.userAttributes;

    const passwordMinLength = 8;
    if (!password || password.length < passwordMinLength) {
      Sentry.captureMessage(
        "password-setnew-error-shortLength",
        Sentry.Severity.Log
      );
      displayErrorAsSnackBar(NewPasswordResource.change.j001);
      return;
    }
    // パスワードがEmailの @ より前の文字列と一緒 or パスワードがEmailと同じ
    if (
      password === email.substring(0, email.lastIndexOf("@")) ||
      password === email
    ) {
      Sentry.captureMessage(
        "password-setnew-error-sameAsEmail",
        Sentry.Severity.Log
      );
      displayErrorAsSnackBar(NewPasswordResource.change.j003);
      return;
    }
    // パスワードが「大文字小文字数字含む」の条件を満たしていない
    const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);
    const isOkPassword = regex.test(password);
    if (!isOkPassword) {
      Sentry.captureMessage(
        "password-setnew-error-fewCharacterTypes",
        Sentry.Severity.Log
      );
      displayErrorAsSnackBar(NewPasswordResource.change.j002);
      return;
    }
    // パスワードと確認用パスワードの入力が一致しない
    if (password !== passwordConfirm) {
      Sentry.captureMessage(
        "password-setnew-error-notSameAsConfirm",
        Sentry.Severity.Log
      );
      displayErrorAsSnackBar(NewPasswordResource.change.j004);
      return;
    }

    // ボタン連打対応
    setIsEnabledSubmitButton(false);

    Auth.completeNewPassword(user, password, requiredAttributes).catch(
      (err) => {
        Sentry.captureMessage(
          "password-setnew-error-" + err.code,
          Sentry.Severity.Log
        );
        // セッション切れ（3分）
        if (err.code === "NotAuthorizedException") {
          displayErrorAsSnackBar(NewPasswordResource.change.j005);
          return;
        }
        // パスワードがCognitoの設定条件を満たしていない
        if (err.code === "InvalidPasswordException") {
          displayErrorAsSnackBar(NewPasswordResource.change.j006);
          return;
        }
        // その他エラー
        displayErrorAsSnackBar(NewPasswordResource.change.j007);
        return;
      }
    );
  };

  const main = (
    <NewPassword
      isEnabledSubmitButton={isEnabledSubmitButton}
      isShowPassword={isShowPassword}
      handleInputChange={handleInputChange}
      handleTogglePassword={handleTogglePassword}
      handleSubmit={handleSubmit}
      returnToLoginPage={returnToLoginPage}
    />
  );

  return (
    <LogoOnlyHeaderTemplate main={main} title={Titles.newPasswordRequired} />
  );
};

export default NewPasswordPage;
