import { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  DialogContent,
  DialogActions,
  Dialog,
} from "@material-ui/core";
import { japaneseList } from "../../../Resources/japaneseList";
import { FACILITY_TYPE } from "../../../Utils/const";
import SiteContext from "../../../contexts/SiteContext";
import FontP from "../../atoms/fonts/FontP";

interface Props {
  hasError: boolean;
  closePopup: () => void;
}

const Resource =
  japaneseList.Pages.Medical.AppointmentStatusChangedWarningDialog.render
    .Dialog;

const CustomDialogContent = withStyles(() => ({
  root: {
    paddingBottom: "9px",
  },
}))(DialogContent);

const CustomDialogAction = withStyles(() => ({
  root: {
    paddingBottom: "5px",
    justifyContent: "center",
  },
}))(DialogActions);

export const AppointmentStatusChangedWarningDialog = (props: Props) => {
  const { hasError, closePopup } = props;

  // ToDo:SiteContextの型は初期値がNULLを指定しているので、型指定は一旦anyです。
  const siteContext: {
    hospital: { facilityType: string };
  } = useContext<any>(SiteContext);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={hasError}
    >
      <CustomDialogContent>
        {siteContext.hospital &&
        siteContext.hospital.facilityType === FACILITY_TYPE.HOSPITAL ? (
          <FontP>{Resource.DialogContent.div.j001}</FontP>
        ) : (
          <FontP>{Resource.DialogContent.div.j002}</FontP>
        )}
      </CustomDialogContent>
      <CustomDialogAction>
        <Button
          id="close-status-changed-warning-dialog"
          variant="contained"
          color="primary"
          onClick={closePopup}
        >
          {Resource.DialogActions.Button.j001}
        </Button>
      </CustomDialogAction>
    </Dialog>
  );
};
