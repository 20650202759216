import React from "react";
import { Typography } from "@material-ui/core";
import { japaneseList } from "../../../Resources/japaneseList";

export const Footer = () => {
  return (
    <React.Fragment>
      <footer>
        <Typography
          gutterBottom
          align="center"
          style={{ fontSize: "11px", marginBottom: "16px" }}
        >
          {japaneseList.Components.Footer.render.footer.Typography.j001}
        </Typography>
      </footer>
    </React.Fragment>
  );
};
