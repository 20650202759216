import React from "react";
import { japaneseList } from "../../../Resources/japaneseList";
import styled from "styled-components";
const SignInResource = japaneseList.Authenticator.SignIn;

const NotificationArea = styled.div`
  background-color: #fff6f6;
  border: 1px solid #f26666;
  border-radius: 3px;
  box-sizing: border-box;
  color: #f26666;
  font-size: 12px;
  line-height: 18px;
  margin: 0px auto 20px;
  width: 100%;
`;

const NotificationTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin: 0 auto 0px;
  padding-top: 22px;
  width: 100%;
`;

const NotificationBody = styled.div`
  font-weight: normal;
  margin: 0 auto;
  padding: 10px 25px 22px;
`;

const NotificationBodyOs = styled.div`
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
`;

const NotificationBodyBrowser = styled.div`
  display: inline-block;
`;

const BrowserInfoPanel = () => {
  return (
    <NotificationArea>
      <NotificationTitle>
        {SignInResource.render.Grid.Notification.Title.j001}
      </NotificationTitle>
      <NotificationBody>
        {SignInResource.render.Grid.Notification.Body.j001}
        <br />
        <NotificationBodyOs>
          {SignInResource.render.Grid.Notification.OS.j001}
        </NotificationBodyOs>
        <NotificationBodyBrowser>
          {SignInResource.render.Grid.Notification.Browser.j001}
          <br />
          {SignInResource.render.Grid.Notification.Browser.j003}
        </NotificationBodyBrowser>
        <br />
        <NotificationBodyOs>
          {SignInResource.render.Grid.Notification.OS.j002}
        </NotificationBodyOs>
        <NotificationBodyBrowser>
          {SignInResource.render.Grid.Notification.Browser.j001}
        </NotificationBodyBrowser>
        <br />
        <NotificationBodyOs>
          {SignInResource.render.Grid.Notification.OS.j003}
        </NotificationBodyOs>
        <NotificationBodyBrowser>
          {SignInResource.render.Grid.Notification.Browser.j002}
        </NotificationBodyBrowser>
      </NotificationBody>
    </NotificationArea>
  );
};

export default BrowserInfoPanel;
