import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { client, GetOperationHistorys } from "../../../graphql";
import { OPERATION_TYPE } from "../../../Utils/const";
import { Administrator } from "../../molecules/operationTypes/Administrator";
import { Approve } from "../../molecules/operationTypes/Approve";
import { Cancel } from "../../molecules/operationTypes/Cancel";
import { CancelPayment } from "../../molecules/operationTypes/CancelPayment";
import { ChageMedicalExpense } from "../../molecules/operationTypes/ChageMedicalExpense";
import { Create } from "../../molecules/operationTypes/Create";
import { EndVideoChat } from "../../molecules/operationTypes/EndVideoChat";
import { RemoveAttachment } from "../../molecules/operationTypes/RemoveAttachment";
import { RestartVideoChat } from "../../molecules/operationTypes/RestartVideoChat";
import { Settle } from "../../molecules/operationTypes/Settle";
import { StartVideoChat } from "../../molecules/operationTypes/StartVideoChat";
import { Unapprove } from "../../molecules/operationTypes/Unapprove";
import { UpdateStatus } from "../../molecules/operationTypes/UpdateStatus";
import { UpdateTime } from "../../molecules/operationTypes/UpdateTime";
import { UploadAttachment } from "../../molecules/operationTypes/UploadAttachment";
import { DoctorLowNetwork } from "../../molecules/operationTypes/DoctorLowNetwork";
import { PatientLowNetwork } from "../../molecules/operationTypes/PatientLowNetwork";
import { withStyles } from "@material-ui/core/styles";
import * as _ from "lodash";

const MedicalDetailAreaResource = japaneseList.Pages.Patient.MedicalDetailArea;

const START_RESTART_OPERATIONS = [
  OPERATION_TYPE.STARTVIDEOCHAT,
  OPERATION_TYPE.RESTARTVIDEOCHAT,
];

const LOW_NETWORK_OPERATIONS = [
  OPERATION_TYPE.DOCTOR_LOW_NETWORK,
  OPERATION_TYPE.PATIENT_LOW_NETWORK,
];

const styles = {
  tbl: {
    "& tbody tr:nth-child(odd)": {
      backgroundColor: "#ffffff",
    },
    "& tbody tr:nth-child(even)": {
      backgroundColor: "#fafafa",
    },
  },
};

const Table = styled.table`
  width: 100%;
  margin: 10px 0 0 0;
  border-collapse: collapse;
  border-bottom: 1px #cccccc solid;
`;

const Thead = styled.thead`
  background: #999999 0% 0% no-repeat padding-box;
  text-align: center;
`;

const Th = styled.th`
  width: 86px;
  background: #999999 0% 0% no-repeat padding-box;
  height: 22px;
  font-size: 12px;
  border-right: 1px #cccccc solid;
  color: #ffffff;
`;

const OperatorName = styled.th`
  width: 126px;
  background: #999999 0% 0% no-repeat padding-box;
  height: 22px;
  font-size: 12px;
  border-right: 1px #cccccc solid;
  color: #ffffff;
`;

const OperationContent = styled.th`
  background: #999999 0% 0% no-repeat padding-box;
  height: 22px;
  border-right: 1px #cccccc solid;
  font-size: 12px;
  color: #ffffff;
  padding-left: 10px;
  text-align: left;
`;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  background: #fafafa 0% 0% no-repeat padding-box;
`;

const DisplayArea = styled.div`
  height: 100%;
  background-color: white;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  box-shadow: 1px 1px #0000001a;
  font-size: 14px;
`;

const NoOperation = styled.div`
  margin: 20px 0 0 20px;
  position: absolute;
  text-align: left;
  font-size: 14px;
  color: #999999;
`;

const MedicalOperationHistory = (porps) => {
  const { classes, patient, selectedAppointment } = porps;
  const patientId = patient ? patient.patientId : "";
  const [operationHistorys, setOperationHistorys] = useState(null);

  useEffect(() => {
    if (selectedAppointment && patientId)
      loadOperationHistorys(selectedAppointment);
  }, [selectedAppointment]);

  const getOperationHistorys = async (patientId, createTime) => {
    const { data, errors } = await client.query({
      query: GetOperationHistorys,
      variables: {
        patientId,
        createTime,
      },
    });
    if (errors) {
      console.error("GetOperationHistorys errors:", JSON.stringify(errors));
    }
    return data ? data.operationHistory : [];
  };

  const removeDuplicateLowNetworkLevelLog = (operationHistory) => {
    const filteredOperationHistories = [];
    const existingLowNetwork = {};
    operationHistory &&
      Array.from(operationHistory)
        .reverse()
        .forEach((item) => {
          // Not start video call, existingLowNetwork = {}
          // When start video call, existingLowNetwork counts low network logs already be saved
          if (START_RESTART_OPERATIONS.includes(item.operationType)) {
            LOW_NETWORK_OPERATIONS.forEach((x) => {
              existingLowNetwork[x] = 0;
            });
          } else if (item.operationType === OPERATION_TYPE.ENDVIDEOCHAT) {
            LOW_NETWORK_OPERATIONS.forEach((x) => {
              delete existingLowNetwork[x];
            });
          } else if (
            LOW_NETWORK_OPERATIONS.includes(item.operationType) &&
            !_.isEmpty(existingLowNetwork)
          ) {
            existingLowNetwork[item.operationType]++;
          }

          // Bypass low network item already saved during video call
          if (
            LOW_NETWORK_OPERATIONS.includes(item.operationType) &&
            existingLowNetwork &&
            existingLowNetwork[item.operationType] &&
            existingLowNetwork[item.operationType] > 1
          ) {
            return;
          }

          filteredOperationHistories.unshift(item);
        });

    return filteredOperationHistories;
  };

  const loadOperationHistorys = async (selectedAppointment) => {
    const result = await getOperationHistorys(
      patientId,
      selectedAppointment.createTime
    );

    setOperationHistorys(removeDuplicateLowNetworkLevelLog(result));
  };

  return (
    <React.Fragment>
      <DisplayArea>
        <Table className={classes.tbl}>
          <Thead>
            <Tr>
              <Th>{MedicalDetailAreaResource.j010}</Th>
              <OperatorName>{MedicalDetailAreaResource.j011}</OperatorName>
              <OperationContent>
                {MedicalDetailAreaResource.j012}
              </OperationContent>
            </Tr>
          </Thead>
          {operationHistorys && operationHistorys.length > 0 && (
            <Tbody>
              {operationHistorys &&
                operationHistorys.map((operationHistory, index) => {
                  if (
                    operationHistory.operationType === OPERATION_TYPE.CREATE
                  ) {
                    return (
                      <Create key={index} operationHistory={operationHistory} />
                    );
                  } else if (
                    operationHistory.operationType === OPERATION_TYPE.APPROVE
                  ) {
                    return (
                      <Approve
                        key={index}
                        operationHistory={operationHistory}
                      />
                    );
                  } else if (
                    operationHistory.operationType === OPERATION_TYPE.UNAPPROVE
                  ) {
                    return (
                      <Unapprove
                        key={index}
                        operationHistory={operationHistory}
                      />
                    );
                  } else if (
                    operationHistory.operationType === OPERATION_TYPE.UPDATETIME
                  ) {
                    return (
                      <UpdateTime
                        key={index}
                        operationHistory={operationHistory}
                      />
                    );
                  } else if (
                    operationHistory.operationType === OPERATION_TYPE.CANCEL
                  ) {
                    return (
                      <Cancel key={index} operationHistory={operationHistory} />
                    );
                  } else if (
                    operationHistory.operationType ===
                    OPERATION_TYPE.UPDATESTATUS
                  ) {
                    return (
                      <UpdateStatus
                        key={index}
                        operationHistory={operationHistory}
                      />
                    );
                  } else if (
                    operationHistory.operationType ===
                    OPERATION_TYPE.STARTVIDEOCHAT
                  ) {
                    return (
                      <StartVideoChat
                        key={index}
                        operationHistory={operationHistory}
                      />
                    );
                  } else if (
                    operationHistory.operationType ===
                    OPERATION_TYPE.RESTARTVIDEOCHAT
                  ) {
                    return (
                      <RestartVideoChat
                        key={index}
                        operationHistory={operationHistory}
                      />
                    );
                  } else if (
                    operationHistory.operationType ===
                    OPERATION_TYPE.ENDVIDEOCHAT
                  ) {
                    return (
                      <EndVideoChat
                        key={index}
                        operationHistory={operationHistory}
                      />
                    );
                  } else if (
                    operationHistory.operationType ===
                    OPERATION_TYPE.UPLOADATTACHMENT
                  ) {
                    return (
                      <UploadAttachment
                        key={index}
                        operationHistory={operationHistory}
                      />
                    );
                  } else if (
                    operationHistory.operationType ===
                    OPERATION_TYPE.REMOVEATTACHMENT
                  ) {
                    return (
                      <RemoveAttachment
                        key={index}
                        operationHistory={operationHistory}
                      />
                    );
                  } else if (
                    operationHistory.operationType === OPERATION_TYPE.SETTLE
                  ) {
                    return (
                      <Settle key={index} operationHistory={operationHistory} />
                    );
                  } else if (
                    operationHistory.operationType ===
                    OPERATION_TYPE.CHAGEMEDICALEXPENSE
                  ) {
                    return (
                      <ChageMedicalExpense
                        key={index}
                        operationHistory={operationHistory}
                      />
                    );
                  } else if (
                    operationHistory.operationType ===
                    OPERATION_TYPE.ADMINISTRATOR
                  ) {
                    return (
                      <Administrator
                        key={index}
                        operationHistory={operationHistory}
                      />
                    );
                  } else if (
                    operationHistory.operationType ===
                    OPERATION_TYPE.CANCELPAYMENT
                  ) {
                    return (
                      <CancelPayment
                        key={index}
                        operationHistory={operationHistory}
                      />
                    );
                  } else if (
                    operationHistory.operationType ===
                    OPERATION_TYPE.DOCTOR_LOW_NETWORK
                  ) {
                    return (
                      <DoctorLowNetwork
                        key={index}
                        operationHistory={operationHistory}
                      />
                    );
                  } else if (
                    operationHistory.operationType ===
                    OPERATION_TYPE.PATIENT_LOW_NETWORK
                  ) {
                    return (
                      <PatientLowNetwork
                        key={index}
                        operationHistory={operationHistory}
                      />
                    );
                  } else {
                    return "";
                  }
                })}
            </Tbody>
          )}
          {operationHistorys && operationHistorys.length === 0 && (
            <NoOperation>{MedicalDetailAreaResource.j013}</NoOperation>
          )}
        </Table>
      </DisplayArea>
    </React.Fragment>
  );
};

export default withStyles(styles)(MedicalOperationHistory);
