import gql from "graphql-tag";

export const GetPatientLoginStatus = gql`
  query GetPatientLoginStatus($patientId: String!, $appointmentKey: String!) {
    loginStatus(patientId: $patientId, appointmentKey: $appointmentKey) {
      isLogin
      videoStandby {
        isOpeningStandbyPage
        isMediaDeviceBlocked
        isMediaDeviceAccepted
        isWarningBrowser
        lastMediaDeviceError
      }
    }
  }
`;