import gql from "graphql-tag";

export const InsertAttachment = gql`
  mutation InsertAttachment(
    $patientId: ID!
    $createTime: String!
    $s3files: [S3File]!
  ) {
    insertAttachment(
      patientId: $patientId
      createTime: $createTime
      s3files: $s3files
    )
  }
`;