import styled from "styled-components";
import FontBase from "./FontBase";

const FontSpan = styled(FontBase.withComponent('span'))`
  ${p => p.size && p.theme.font.size[p.size]? "font-size:"+p.theme.font.size[p.size]+";" : ""}
  ${p => p.align && p.theme.font.align[p.align]? "text-align:"+p.theme.font.align[p.align]+";" : ""}
  ${p => p.color && p.theme.font.color[p.color]? "color:"+p.theme.font.color[p.color]+";" : ""}
  ${p => p.weight && p.theme.font.weight[p.weight]? "font-weight:"+p.theme.font.weight[p.weight]+";" : ""}
`

export default FontSpan;
