import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { isLnln } from "../../../Utils/checkLnln";
import {
  APPOINTMENT_STATUS,
  PROGRAM_TYPE,
  SETTLEMENT_ERROR,
} from "../../../Utils/const";
import { formatPeriod } from "../../../Utils/DateUtil";
import FontP from "../../atoms/fonts/FontP";
import FontPBold from "../../atoms/fonts/FontPBold";
import { AppointmentInfo } from "./AppointmentInfo";

const MedicalHistoryResource = japaneseList.Pages.Patient.MedicalHistory;

const deliveryErrorSet = [
  SETTLEMENT_ERROR.DELIVERY_MAINTENANCE,
  SETTLEMENT_ERROR.DELIVERY_PATIENT,
  SETTLEMENT_ERROR.DELIVERY_SYSTEM,
];
const settlementErrorSet = [
  SETTLEMENT_ERROR.CREDIT_CARD,
  SETTLEMENT_ERROR.CARD_SYSTEM,
  SETTLEMENT_ERROR.UNEXPECTED_SYSTEM,
  SETTLEMENT_ERROR.INVALID_AMOUNT,
];

const OuterDiv = styled.div`
  height: 100%;
  background-color: white;
  padding-top: 30px;
`;

const Title = styled(FontPBold)`
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 14px;
  display: block;
`;

const PrimaryHr = styled.hr`
  width: 100%;
  background-color: ${isLnln() ? "#171F55" : (p) => p.theme.color.primary};
  border: 0;
  height: 2px;
  margin: 0px;
`;

const DisplayArea = styled.div`
  height: calc(100% - 34px);
  overflow-y: auto;
`;

const Appointment = styled.div`
  padding: 16px 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
  border-bottom: 1px solid #efefef;
`;

const StatusText = styled(FontPBold)`
  display: block;
  margin-top: 15px;
  font-size: 14px;
  color: ${(p) => p.theme.color.primary};
`;

const NoMedicalHistory = styled(FontP)`
  color: ${(p) => p.theme.font.color.gray};
  font-size: 14px;
  margin-left: 14px;
  margin-top: 16px;
`;

export const MedicalHistory = ({
  medicalHistory,
  handleSelectAppointment,
  isPharmacy,
}) => {
  const [selectedCreateTime, setSelectedCreateTime] = useState(null);
  const [displayList, setDisplayList] = useState([]);
  const selectedBgColor = isLnln() ? "#ebf0fc" : "#e1f8f8";

  useEffect(() => {
    loadDisplayList(medicalHistory);
  }, [medicalHistory]);

  const loadDisplayList = (medicalHistoryList) => {
    const inputList = medicalHistoryList || [];
    const outputList = [];
    inputList.map((element, index) => {
      const date = formatPeriod(element.from, element.to);
      const statusText = getStatusText(element);

      outputList.push({
        date: date,
        displayName: element.menu.displayName,
        supportsInsuranceTreatment: element.menu.supportsInsuranceTreatment,
        statusText: statusText,
        createTime: element.createTime,
        companyName: element.companyName || "",
        content: element.content || "",
        doctor: element.doctor,
        medicalMethod: element.menu.medicalMethod || "",
      });
      if (index === 0 && element.createTime)
        setSelectedCreateTime(element.createTime);
      return element;
    });
    setDisplayList(outputList);
  };

  const getStatusText = (appo) => {
    const { status, menu, payment, program } = appo;
    const isOnline = menu.medicalMethod === "online" ? true : false;
    const isWelfareProgram =
      program && program.type && program.type === PROGRAM_TYPE.WELFARE_PROGRAM;
    const settlementError =
      payment &&
      payment.errorCode &&
      settlementErrorSet.includes(payment.errorCode);
    const deliveryError =
      payment &&
      payment.errorCode &&
      deliveryErrorSet.includes(payment.errorCode);

    if (
      (isWelfareProgram && payment && payment.medicalExpense) ||
      status === APPOINTMENT_STATUS.PAYMENT_COMPLETED
    ) {
      return MedicalHistoryResource.Status.payment.j001;
    } else if (settlementError) {
      return MedicalHistoryResource.Status.settlementError.j001;
    } else if (deliveryError) {
      return MedicalHistoryResource.Status.deliveryError.j001;
    }

    switch (status) {
      case APPOINTMENT_STATUS.BEFORE_EXAM:
        return isOnline
          ? MedicalHistoryResource.Status.beforeExam.j001
          : MedicalHistoryResource.Status.beforeExam.j002;
      case APPOINTMENT_STATUS.UNAPPROVED:
        return MedicalHistoryResource.Status.unapproved.j001;
      case APPOINTMENT_STATUS.REJECTED:
        return MedicalHistoryResource.Status.rejected.j001;
      case APPOINTMENT_STATUS.EXAM_COMPLETED:
        return isOnline || isWelfareProgram
          ? MedicalHistoryResource.Status.payment.j002
          : MedicalHistoryResource.Status.examCompleted.j001;
      case APPOINTMENT_STATUS.CANCELED_DOCTOR:
      case APPOINTMENT_STATUS.CANCELED_PATIENT_PREVIOUS_DAY:
      case APPOINTMENT_STATUS.CANCELED_PATIENT_FROM_DAY:
      case APPOINTMENT_STATUS.CANCELED_BEFORE_APPROVING:
        return MedicalHistoryResource.Status.cancel.j001;
      default:
        return "";
    }
  };

  const handleSelected = (createTime) => {
    setSelectedCreateTime(createTime);
    handleSelectAppointment(createTime);
  };

  return (
    <React.Fragment>
      <OuterDiv>
        <Title>
          {isPharmacy === undefined
            ? ""
            : isPharmacy
            ? MedicalHistoryResource.Title.j002
            : MedicalHistoryResource.Title.j001}
        </Title>
        <PrimaryHr />
        <DisplayArea>
          {displayList.length > 0 ? (
            displayList.map((element) => (
              <Appointment
                style={{
                  backgroundColor:
                    element.createTime === selectedCreateTime
                      ? selectedBgColor
                      : "",
                }}
                value={element.createTime}
                key={element.createTime}
                onClick={() => handleSelected(element.createTime)}
              >
                <AppointmentInfo infoContents={element} />
                <StatusText>{element.statusText}</StatusText>
              </Appointment>
            ))
          ) : (
            <NoMedicalHistory>
              {isPharmacy === undefined
                ? ""
                : isPharmacy
                ? MedicalHistoryResource.j004
                : MedicalHistoryResource.j003}
            </NoMedicalHistory>
          )}
        </DisplayArea>
      </OuterDiv>
    </React.Fragment>
  );
};
