import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../../Resources/japaneseList";

const YearSelectorElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
`;

const SelectButton = styled.button`
  width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 0;
  position: relative;
  display: inline-block;
  background-color: transparent;
  border: none;
  outline: none;
  &:before {
    content: "";
    width: 8.5px;
    height: 8.5px;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -4.25px;
  }
  &:disabled {
    &:before {
      border-left: none !important;
      border-bottom: none !important;
      border-top: none !important;
      border-right: none !important;
    }
  }
`;

const SelectPrevButton = styled(SelectButton)`
  &:before {
    border-left: solid 2px #393939;
    border-bottom: solid 2px #393939;
    margin-left: -2px;
  }
  &:hover {
    &:before {
      border-left: solid 2px #a6a6a6;
      border-bottom: solid 2px #a6a6a6;
    }
  }
`;

const SelectNextButton = styled(SelectButton)`
  &:before {
    border-top: solid 2px #393939;
    border-right: solid 2px #393939;
    margin-left: -6px;
  }
  &:hover {
    &:before {
      border-top: solid 2px #a6a6a6;
      border-right: solid 2px #a6a6a6;
    }
  }
`;

const YearSelector = (props) => {
  const { date, minimumDate, maximumDate, handleChangeYear } = props;
  const selectedYear = date.getFullYear();
  const minimumYear = minimumDate.getFullYear();
  const maximumYear = maximumDate.getFullYear();

  const changeYear = (year) => () => {
    handleChangeYear(year);
  };

  return (
    <YearSelectorElement>
      <SelectPrevButton
        onClick={changeYear(selectedYear - 1)}
        disabled={selectedYear <= minimumYear}
      />
      {selectedYear}
      {japaneseList.general.datePostfix.year}
      <SelectNextButton
        onClick={changeYear(selectedYear + 1)}
        disabled={selectedYear >= maximumYear}
      />
    </YearSelectorElement>
  );
};

export default YearSelector;
