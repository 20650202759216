import styled from "styled-components";
import CameraIcon from "../../../images/icon/icon-camera-ng.svg";

const Img = styled.img`
    width: 30px;
    height: 30px;
`;

export const IconCameraNG = () => <Img src={CameraIcon} />;

