// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AccountImage from "../../../images/account_circle-24px.svg";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import CurrencyText from "./CurrencyText";
import { format } from "date-fns";

// personal modules
// --------------------------------------------------
import FontSpan from "../../atoms/fonts/FontSpan";
import { japaneseList } from "../../../Resources/japaneseList";
import { getAge } from "../../../Utils/DisplayLogic";
import NumberFormat from "react-number-format";
import LinkA from "../../atoms/links/LinkA";
import {
  SETTLEMENT_ERROR,
  APPOINTMENT_STATUS,
  PAYMENT_METHOD,
  MEDICAL_METHOD,
  MEDICAL_MENU,
  DELIVERY_OPTION,
} from "../../../Utils/const";
import { deliveryFeeCalculator } from "../../../Utils/DeliveryFeeCalculator";

const Resources = japaneseList.Components.Payment.PaymentConfirmDialog;
const BillingCategoryResource =
  japaneseList.Components.Appointment.StatusChangeDialog.DialogContent
    .containerBorder.billingCategoryOption;
const ResourcesAppointment = japaneseList.Components.Appointment;
const ErrorResources =
  japaneseList.Components.Payment.HospitalPaymentList.render.ErrorResources;
const maleGender = Resources.genderMale;

// style
// --------------------------------------------------
const styles = (theme) => ({
  dialogTitle: {
    padding: "24px",
    height: "68px",
  },
  dialogTitleContent: {
    fontSize: "20px",
    lineHeight: "0px",
    weight: "bold",
  },
  dialogActions: {
    padding: "14px 24px 14px 24px",
    height: "70px",
    margin: "0",
  },
  dialogContent: {
    padding: "0 24px 24px 24px",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  buttonOk: {
    backgroundColor: "gray",
    color: "white",
    width: "120px",
    height: "40px",
    boxShadow: "0px 2px 0px #505050",
    marginRight: "0px",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
    fontWeight: "bold",
  },
  tableStyle: {
    borderCollapse: "collapse",
    width: "310px",
    tableLayout: "auto",
  },
  thBirthDay: {
    width: "98px",
  },
  thAge: {
    width: "26px",
  },
  thGender: {
    width: "48px",
  },
  thPhonenumber: {
    width: "120px",
  },
  gridContentPatient: {
    marginTop: "20px",
  },
  gridAvatar: {
    marginRight: "12px",
  },
  textName: {
    width: "calc(100% - 102px);",
  },
  textGiveName: {
    fontSize: "16px",
    fontWeight: "bold",
    wordBreak: "break-all",
  },
  textGiveNameKana: {
    fontSize: "12px",
    wordBreak: "break-all",
  },
  thStyle: {
    textAlign: "center",
    borderTop: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    backgroundColor: "#eee",
    fontSize: "12px",
    height: "22px",
    color: "#808080",
  },
  trContent: {
    height: "33px",
  },
  tdStyle: {
    padding: "10px",
    textAlign: "center",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  tdStyleGenderMale: {
    padding: "10px",
    textAlign: "center",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  tdStyleGenderFemale: {
    padding: "10px",
    textAlign: "center",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    borderBottom: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  containerTable: {
    display: "flex",
    boxSizing: "border-box",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "8px",
  },
  containerBorder: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
    marginTop: "20px",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "80px auto",
    borderTop: "#ccc solid 1px",
  },
  titleItem: {
    backgroundColor: "#eee",
    padding: "0 10px",
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    color: "#808080",
  },
  titleDetailItem: {
    backgroundColor: "#eee",
    padding: "0 10px",
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "start",
    color: "#808080",
  },
  titleItemMemo: {
    backgroundColor: "#eee",
    padding: "10px",
    fontSize: "13px",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  },
  titleItemMemoNote: {
    fontWeight: "normal",
    color: "#aaaaaa",
  },
  contentItem: {
    padding: "10px 20px",
    fontSize: "14px",
    minHeight: "41px",
    lineHeight: "21px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  contentTotalFeeItem: {
    display: "flex",
    padding: "10px 30px",
    fontSize: "14px",
    minHeight: "41px",
    lineHeight: "21px",
    alignItems: "end",
    justifyContent: "flex-start",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  contentItemGroup: {
    display: "flex",
    padding: "0 10px 10px 20px",
    flexDirection: "column",
    alignItems: "start",
    gap: "10px",
  },
  contentItemSubGroup: {
    display: "flex",
    padding: "0px 10px",
    alignItems: "start",
    gap: "27px",
  },
  contentDetailItem: {
    display: "flex",
    minWidth: "102px",
    height: "21px",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: "12px",
  },
  contentFeeBlock: {
    display: "flex",
    minWidth: "42px",
    height: "21px",
    paddingRight: "0px",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    gap: "4px",
  },
  contentFeeItem: {
    justifyContent: "center",
    textAlign: "right",
    fontSize: "12px",
  },
  contentDescriptionItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#808080",
    fontSize: "11px",
    lineHeight: "normal",
    whiteSpace: "pre-wrap",
    alignSelf: "center",
  },
  maxHeightWidthDialog: {
    maxHeight: "96%",
    maxWidth: "810px",
  },
  dialogContentText: {
    padding: "0px 24px 14px 0px",
    color: "#393939",
    whiteSpace: "pre-line",
  },
  dialogContentTitleText: {
    padding: "14px 24px 0px 0px",
    color: "#EF5E5E",
    fontWeight: "bold",
  },
  linkAStyleNormal: {
    fontSize: "14px",
    color: "#2563C0",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
  },
  dialogContainer: {
    marginBottom: "14px",
  },
});

// class define
// --------------------------------------------------
class HospitalPaymentConfirm extends Component {
  // default state
  // --------------------------------------------------
  static defaultProps = {
    open: false,
    onClose: function () {},
    maxWidth: "md",
    buttonOkText: Resources.buttonOk,
    buttonCancelText: Resources.buttonCancel,
    handleCloseConfirmDialog: function () {},
  };

  // constructor
  // --------------------------------------------------
  constructor(props) {
    super(props);
    this.state = {};
  }

  getErrorResource = (appointment) => {
    const errorCode = appointment.payment.errorCode;
    return ErrorResources.Errors.find((item) => item.code === errorCode);
  };

  pathCheck = (errorResource) => {
    const documentPath = "/documents";
    const contactPath = "/contact";
    const documentErrorSet = [
      SETTLEMENT_ERROR.CREDIT_CARD,
      SETTLEMENT_ERROR.DELIVERY_SYSTEM,
      SETTLEMENT_ERROR.DELIVERY_PATIENT,
    ];
    const contactErrorSet = [
      SETTLEMENT_ERROR.CARD_SYSTEM,
      SETTLEMENT_ERROR.UNEXPECTED_SYSTEM,
      SETTLEMENT_ERROR.FAILED_CANCEL,
    ];
    if (Boolean(errorResource)) {
      const documentError = documentErrorSet.includes(errorResource.code);
      const contactError = contactErrorSet.includes(errorResource.code);
      if (documentError) {
        return documentPath;
      }
      if (contactError) {
        return contactPath;
      }
    }
  };

  // render
  // --------------------------------------------------
  render() {
    const { appointment, classes, open } = this.props;
    const { patient, requireDrugName, requireDrugQuantity, menu, doctor } =
      appointment;
    const medicalExpense =
      appointment.payment && Boolean(appointment.payment.medicalExpense)
        ? appointment.payment.medicalExpense
        : 0;
    const errorResource = this.getErrorResource(appointment);
    const approachPath = this.pathCheck(errorResource);
    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;
    const displayDeliveryFee = deliveryFeeCalculator(
      appointment,
      appointment.payment.deliverySelectedOption
    );
    const isMedicationInstructionOnline =
      menu?.medicalMethod === MEDICAL_METHOD.ONLINE &&
      menu?.medicalMenu === MEDICAL_MENU.MEDICATION_INSTRUCTION;

    return (
      <React.Fragment>
        <Dialog
          open={open}
          maxWidth={false}
          fullWidth={true}
          PaperProps={{ classes: { root: classes.maxHeightWidthDialog } }}
        >
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            <FontSpan weight="bold" className={classes.dialogTitleContent}>
              {isBillingEnterprise
                ? Resources.BillingEnterpriseDialogTitle
                : Resources.DialogTitle}
            </FontSpan>
          </DialogTitle>
          <Divider />
          <DialogContent className={classes.dialogContent}>
            {patient && (
              <Grid
                container
                wrap="nowrap"
                className={classes.gridContentPatient}
              >
                <Grid item className={classes.gridAvatar}>
                  <img src={AccountImage} alt={""} />
                </Grid>
                <Grid item xs className={classes.textName}>
                  <Typography className={classes.textGiveName}>
                    {patient.familyName} {patient.givenName}
                  </Typography>
                  <Typography className={classes.textGiveNameKana}>
                    {patient.familyNameKana} {patient.givenNameKana}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {patient && (
              <div className={classes.containerTable}>
                <table className={classes.tableStyle}>
                  <thead>
                    <tr>
                      <th
                        className={classes.thStyle + " " + classes.thBirthDay}
                      >
                        {Resources.DialogContent.table.thBirthDay}
                      </th>
                      <th className={classes.thStyle + " " + classes.thAge}>
                        {Resources.DialogContent.table.thAge}
                      </th>
                      <th className={classes.thStyle + " " + classes.thGender}>
                        {Resources.DialogContent.table.thGender}
                      </th>
                      <th
                        className={
                          classes.thStyle + " " + classes.thPhonenumber
                        }
                      >
                        {Resources.DialogContent.table.thPhonenumber}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={classes.trContent}>
                      <td className={classes.tdStyle}>
                        {format(new Date(patient.birthdate), "yyyy/MM/dd")}
                      </td>
                      <td className={classes.tdStyle}>
                        {patient.birthdate ? getAge(patient.birthdate) : " "}
                      </td>
                      <td
                        className={
                          patient.gender && patient.gender === maleGender
                            ? classes.tdStyleGenderMale
                            : classes.tdStyleGenderFemale
                        }
                      >
                        {patient.gender}
                      </td>
                      <td className={classes.tdStyle}>
                        <NumberFormat
                          value={patient.phoneNumber}
                          displayType={"text"}
                          format="###-####-####"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {appointment.status === APPOINTMENT_STATUS.PAYMENT_COMPLETED ? (
              <div className={classes.containerBorder}>
                <div className={classes.container}>
                  <div className={classes.titleItem}>
                    {
                      ResourcesAppointment.StatusChangeDialog.DialogContent
                        .containerBorder.undertaker
                    }
                  </div>
                  <div className={classes.contentItem}>
                    {menu && menu.medicalMethod === MEDICAL_METHOD.OFFLINE
                      ? ResourcesAppointment.StatusChangeDialog.DialogContent
                          .containerBorder.offline
                      : `${doctor.familyName} ${doctor.givenName}`}
                  </div>
                </div>
                {!isBillingEnterprise && displayDeliveryFee && (
                  <React.Fragment>
                    <div className={classes.container}>
                      <div className={classes.titleItem}>
                        {
                          Resources.DialogContent.containerBorder
                            .medicalTotalFee
                        }
                      </div>
                      <div className={classes.contentTotalFeeItem}>
                        <CurrencyText
                          value={
                            medicalExpense + displayDeliveryFee.deliveryTotalFee
                          }
                        />
                      </div>
                      <div className={classes.titleDetailItem}>
                        {
                          Resources.DialogContent.containerBorder
                            .medicalDetailFee
                        }
                      </div>

                      <div className={classes.contentItemGroup}>
                        <div className={classes.contentItemSubGroup}>
                          <div className={classes.contentDetailItem}>
                            {isMedicationInstructionOnline
                              ? Resources.DialogContent.containerBorder
                                  .medicalFee.medicationInstruction
                              : Resources.DialogContent.containerBorder
                                  .medicalFee.normalMenu}
                          </div>
                          <div className={classes.contentFeeBlock}>
                            <div className={classes.contentFeeItem}>
                              <CurrencyText value={medicalExpense} />
                            </div>
                          </div>

                          <div className={classes.contentDescriptionItem}>
                            {isMedicationInstructionOnline
                              ? Resources.DialogContent.containerBorder
                                  .medicalFeeDescription.medicationInstruction
                              : Resources.DialogContent.containerBorder
                                  .medicalFeeDescription.normalMenu}
                          </div>
                        </div>
                        <div className={classes.contentItemSubGroup}>
                          <div className={classes.contentDetailItem}>
                            {
                              Resources.DialogContent.containerBorder
                                .deliveryFee
                            }
                          </div>
                          <div className={classes.contentFeeBlock}>
                            <div className={classes.contentFeeItem}>
                              <CurrencyText
                                value={displayDeliveryFee.deliveryFee}
                              />
                            </div>
                          </div>
                          <div className={classes.contentDescriptionItem}>
                            {appointment.payment.deliverySelectedOption ===
                            DELIVERY_OPTION.yamato
                              ? Resources.DialogContent.containerBorder
                                  .deliveryFeeYamatoDescription
                              : Resources.DialogContent.containerBorder
                                  .deliveryFeeDescription}
                          </div>
                        </div>
                        <div className={classes.contentItemSubGroup}>
                          <div className={classes.contentDetailItem}>
                            {
                              Resources.DialogContent.containerBorder
                                .deliveryArrangementFee
                            }
                          </div>
                          <div className={classes.contentFeeBlock}>
                            <div className={classes.contentFeeItem}>
                              <CurrencyText
                                value={
                                  displayDeliveryFee.deliveryArrangementFee
                                }
                              />
                            </div>
                          </div>
                          <div className={classes.contentDescriptionItem}>
                            {
                              Resources.DialogContent.containerBorder
                                .deliveryArrangementFeeDescription
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    {requireDrugName && (
                      <div className={classes.container}>
                        <div className={classes.titleItem}>
                          {Resources.DialogContent.containerBorder.drugName}
                        </div>
                        <div className={classes.contentItem}>
                          {appointment.drugsName}
                        </div>
                      </div>
                    )}
                    {requireDrugQuantity && (
                      <div className={classes.container}>
                        <div className={classes.titleItem}>
                          {Resources.DialogContent.containerBorder.drugQuantity}
                        </div>
                        <div className={classes.contentItem}>
                          {appointment.drugsQuantity}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
                {isBillingEnterprise && (
                  <React.Fragment>
                    <div className={classes.container}>
                      <div className={classes.titleItem}>
                        {
                          Resources.DialogContent.containerBorder
                            .billingCategory
                        }
                      </div>
                      <div className={classes.contentItem}>
                        {BillingCategoryResource[appointment.billingCategory]}
                      </div>
                    </div>
                    <div className={classes.container}>
                      <div className={classes.titleItem}>
                        {Resources.DialogContent.containerBorder.drugNameEWP}
                      </div>
                      <div className={classes.contentItem}>
                        {appointment.drugsName}
                      </div>
                    </div>
                    <div className={classes.container}>
                      <div className={classes.titleItem}>
                        {Resources.DialogContent.containerBorder.drugQuantity}
                      </div>
                      <div className={classes.contentItem}>
                        {appointment.drugsQuantity}
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className={classes.container}>
                  <div className={classes.titleItem}>
                    {Resources.DialogContent.containerBorder.memo}
                  </div>
                  <div className={classes.contentItem}>{appointment.memo}</div>
                </div>
              </div>
            ) : (
              <React.Fragment>
                <div className={classes.dialogContainer}>
                  <DialogContentText className={classes.dialogContentTitleText}>
                    {errorResource.title}
                  </DialogContentText>
                  <DialogContentText className={classes.dialogContentText}>
                    {errorResource.text}
                  </DialogContentText>
                  {approachPath && Boolean(errorResource.approachText) && (
                    <LinkA
                      className={classes.linkAStyleNormal}
                      href={approachPath}
                      target="_blank"
                    >
                      {errorResource.approachText}
                    </LinkA>
                  )}
                </div>
              </React.Fragment>
            )}
          </DialogContent>
          <Divider />
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.buttonOk}
              onClick={this.props.handleCloseConfirmDialog}
            >
              {Resources.DialogActions.confirmButton}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(HospitalPaymentConfirm);
