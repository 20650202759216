import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../../Resources/japaneseList";
const InterviewSheetAnswerRowResource =
  japaneseList.Pages.Patient.PrintInterviewSheetAnswerRow;

const TableInterviewTextCell = styled.td`
  width: 250px;
  font-size: 12px;
  border: 1px solid;
  text-align: left;
  font-weight: 600;
  padding: 12px 12px 12px 0px;
`;

const TableAnswerCell = styled.td`
  width: 496px;
  padding: 12px;
  border: 1px solid;
  font-size: 14px;
`;

const InterviewNo = styled.div`
  width: 13%;
  text-align: right;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
`;

const InterviewText = styled.div`
  width: 87%;
  text-align: left;
  font-size: 12px;
  display: inline-block;
`;

const FreeText = styled.div`
  word-wrap: break-word;
  word-break: break-word;
`;

export const InterviewSheetAnswerRow = (props) => {
  const { item } = props;

  return (
    <React.Fragment>
      <tr>
        <TableInterviewTextCell>
          <InterviewNo>
            {item.idNotation + InterviewSheetAnswerRowResource.j001}
          </InterviewNo>
          <InterviewText>{item.interviewText}</InterviewText>
        </TableInterviewTextCell>
        <TableAnswerCell>
          {item.answerStr}
          {item.freeText && <FreeText>{item.freeText}</FreeText>}
        </TableAnswerCell>
      </tr>
    </React.Fragment>
  );
};
