import React from 'react';
import { Helmet } from 'react-helmet';
import { japaneseList } from '../../../Resources/japaneseList';

export const Favicon = ({ servicePath='carada' }) => {
  const serviceName = japaneseList.service.name;
  return (
    <Helmet>
      <link rel="manifest" href={`/${servicePath}/manifest.json`} />
      <link rel="shortcut icon" href={`/${servicePath}/favicon.ico`} />
      <meta property='og:site_name' content={`${serviceName}`} />
    </Helmet>
  )
};