import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import AccessTime from "@material-ui/icons/AccessTime";
import DateRange from "@material-ui/icons/DateRange";
import IconButton from "@material-ui/core/IconButton";
import { format, parseISO } from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import jaLocale from "date-fns/locale/ja";
import DateFnsUtils from "@date-io/date-fns";
import NativeSelect from "@material-ui/core/NativeSelect";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import FontPError from "../../../atoms/fonts/FontPError";

import {
  setYearMonthDate,
  setHoursAndMinutes,
} from "../../../../Utils/DateUtil";
import { getAvailableAppointmentMenuNames } from "../../../../Utils/Appointment";
import TimePicker from "./TimePicker";
import { endMonthOfNextYear } from "../../../../Utils/DateUtil";
import { japaneseList } from "../../../../Resources/japaneseList";
import PatientInfo from "../../dialog/PatientInfo";
import ja from "date-fns/locale/ja";

const AppointmentResource =
  japaneseList.Components.Appointment.Stepper.Appointment;
const InsuranceResource = japaneseList.insurance;

const styles = (theme) => ({
  input: {
    width: "194px",
    height: "34px",
    lineHeight: "34px",
    alignItems: "center",
    verticalAlign: "middle",
    padding: "10px 0px 10px 12px",
    fontSize: "14px",
    border: "1px solid #dddddd",
    background: `${theme.color.primaryVariant3} 0% 0% no-repeat padding-box`,
    borderRadius: "3px 0px 0px 3px",
    textAlign: "left",
    color: "#393939",
    cursor: "pointer",
    "&:focus": {
      outline: "none",
    },
  },
  iconButton: {
    padding: 0,
    backgroundColor: theme.button.ok.backgroundColor,
    maxWidth: "34px",
    maxHeight: "33px",
    minWidth: "34px",
    minHeight: "33px",
    marginLeft: "-34px",
    marginTop: "-1px",
    color: "#ffffff",
    borderRadius: "0 3px 3px 0",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
  },
  doctor: {
    height: "35px",
  },
  menuError: {
    height: "75px",
  },
});

const PatientArea = styled.div`
  margin: 25px 0 0 0;
`;

const AppointmentArea = styled.div`
  margin: 20px 0 0 0;
`;

const Container = styled.div`
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 64px auto;
  border-top: 1px solid #cccccc;
  height: 55px;
`;

const Title = styled.div`
  background-color: #eeeeee;
  padding-left: 6px;
  color: #808080;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`;

const Content = styled.div`
  padding-left: 20px;
  color: #393939;
  text-align: left;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-word;
`;

const CustomNativeSelect = styled(NativeSelect)`
  width: 656px;
  line-height: 33px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  background: 0% 0% no-repeat padding-box
    ${(p) => p.theme.color.primaryVariant3};
  select {
    width: calc(100% - 10px);
    padding: 0 0 0 10px;
    height: 34px;
    font-size: 14px;
    &:focus {
      background: 0% 0% no-repeat padding-box
        ${(p) => p.theme.color.primaryVariant3};
    }
  }
  &:hover svg {
    background-color: ${(p) => p.theme.color.primaryVariant};
  }
`;

const FontPValidateError = styled(FontPError)`
  margin: auto;
  color: #ef5e5e;
  font-weight: bold;
  font-size: ${(p) => p.theme.font.size.s};
`;

const SelectErrorContent = styled.div`
  display: inline;
`;

const CustomNativeSelectError = styled(NativeSelect)`
  width: 656px;
  line-height: 33px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  background: 0% 0% no-repeat padding-box
    ${(p) => p.theme.color.primaryVariant6};
  select {
    width: calc(100% - 10px);
    padding: 0 0 0 10px;
    height: 34px;
    font-size: 14px;
    &:focus {
      background: 0% 0% no-repeat padding-box
        ${(p) => p.theme.color.primaryVariant3};
    }
  }
  &:hover svg {
    background-color: ${(p) => p.theme.color.primaryVariant};
  }
`;

const ArrowDropDownIcon = withStyles((theme) => ({
  root: {
    backgroundColor: theme.button.ok.backgroundColor,
    width: "34px",
    height: "33px",
    color: "#ffffff",
    background: "#27cdcb 0% 0% no-repeat padding-box",
    boxShadow: `0px 1px 0px ${theme.color.shadow}`,
    borderRadius: "0px 3px 3px 0px",
    marginTop: "-5px",
    left: "620px",
  },
}))(ArrowDropDown);

class Appointment extends Component {
  constructor(props) {
    super(props);
    const endDateWithinStartDay = parseISO(
      `${format(props.slot.start, "yyyy-MM-dd")}T${format(
        props.slot.end,
        "HH:mm"
      )}`
    );
    this.state = {
      startDate: props.slot.start,
      endDate: endDateWithinStartDay,
      startTimePicker: false,
      endTimePicker: false,
      selectedMenuId: null,
      availableMenus: [],
      menus: [],
      menuId: props.slot.selectedMenu ? props.slot.selectedMenu.menuId : "",
      selectedMenu: props.slot.selectedMenu,
    };
  }

  componentDidMount() {
    getAvailableAppointmentMenuNames(
      this.props.hospital,
      this.props.slot.selectDoctor
    )
      .then((availableMenus) => {
        this.setState({ availableMenus });
      })
      .catch();
  }

  createMenuNameOption(menuData) {
    if (menuData.supportsInsuranceTreatment === true) {
      this.state.menus.push({
        menuId: menuData.menuId,
        menuName: `${menuData.displayName}/${InsuranceResource.ok}`,
      });
      return `${menuData.displayName}(${InsuranceResource.ok})`;
    } else if (menuData.supportsInsuranceTreatment === false) {
      this.state.menus.push({
        menuId: menuData.menuId,
        menuName: `${menuData.displayName}/${InsuranceResource.no}`,
      });
      return `${menuData.displayName}(${InsuranceResource.no})`;
    } else {
      this.state.menus.push({
        menuId: menuData.menuId,
        menuName: menuData,
      });
      return menuData;
    }
  }

  handleClickDateButton = () => {
    this.picker.open();
  };

  handleDateChange = (date) => {
    const startDate = setYearMonthDate(this.state.startDate, date);
    const endDate = setYearMonthDate(this.state.endDate, date);
    this.setState({
      startDate: startDate,
      endDate: endDate,
    });
    this.props.handleAppointmentChange({
      patient: this.props.patient,
      doctor: this.props.slot.selectDoctor,
      from: startDate,
      to: endDate,
      hasCharge: this.props.hasCharge,
      selectedMenu: this.state.selectedMenu,
    });
  };

  handleStartTimeChange = (start) => {
    const [hours, minutes] = start.split(":");
    const startDate = setHoursAndMinutes(this.state.startDate, hours, minutes);
    this.setState({
      startDate: startDate,
      startTimePicker: false,
    });
    this.props.handleAppointmentChange({
      patient: this.props.patient,
      doctor: this.props.slot.selectDoctor,
      from: startDate,
      to: this.state.endDate,
      hasCharge: this.props.hasCharge,
      selectedMenu: this.state.selectedMenu,
    });
  };

  handleEndTimeChange = (end) => {
    const [hours, minutes] = end.split(":");
    const endDate = setHoursAndMinutes(this.state.endDate, hours, minutes);
    this.setState({
      endDate: endDate,
      endTimePicker: false,
    });
    this.props.handleAppointmentChange({
      patient: this.props.patient,
      doctor: this.props.slot.selectDoctor,
      from: this.state.startDate,
      to: endDate,
      hasCharge: this.props.hasCharge,
      selectedMenu: this.state.selectedMenu,
    });
  };

  handleClickStartButton = () => {
    if (!this.state.startTimePicker) {
      this.setState({
        startTimePicker: true,
      });
    }
  };

  handleClickEndButton = () => {
    if (!this.state.endTimePicker) {
      this.setState({
        endTimePicker: true,
      });
    }
  };

  closeStartTimePicker = () => {
    this.setState({
      startTimePicker: false,
    });
  };

  closeEndTimePicker = () => {
    this.setState({
      endTimePicker: false,
    });
  };

  handleChangeMenuName = (e) => {
    if (isNaN(e.target.value)) {
      this.setState({
        menuId: "",
        selectedMenu: null,
      });
      this.props.handleAppointmentChange(null);
    } else {
      const selectedMenu = this.state.menus.find(
        (item) => item.menuId === e.target.value
      );
      this.setState({
        menuId: selectedMenu.menuId,
        selectedMenu: selectedMenu,
      });
      this.props.handleAppointmentChange({
        patient: this.props.patient,
        doctor: this.props.slot.selectDoctor,
        from: this.state.startDate,
        to: this.state.endDate,
        hasCharge: this.props.hasCharge,
        selectedMenu: selectedMenu,
      });
    }
  };

  render() {
    const { patient, classes, isUnselectedMenuError } = this.props;
    const { startDate, endDate } = this.state;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
        <PatientArea>
          <PatientInfo patient={patient} />
        </PatientArea>
        <AppointmentArea>
          <Container>
            <Item className={classes.doctor}>
              <Title>{AppointmentResource.ListItem.ListItemText.j001}</Title>
              {this.props.slot.selectDoctor && (
                <Content>{this.props.slot.selectDoctor.name}</Content>
              )}
            </Item>
            <Item>
              <Title>{AppointmentResource.ListItem.ListItemText.j002}</Title>
              <Content>
                <input
                  className={classes.input}
                  readOnly={true}
                  onClick={this.handleClickDateButton}
                  value={format(startDate, "yyyy/MM/dd(iii)", { locale: ja })}
                ></input>
                <IconButton
                  className={classes.iconButton}
                  aria-label="Edit"
                  onClick={this.handleClickDateButton}
                >
                  <DateRange />
                </IconButton>
                <DatePicker
                  style={{ display: "none" }}
                  format="yyyy MM dd"
                  ref={(node) => {
                    this.picker = node;
                  }}
                  openTo="day"
                  value={format(startDate, "yyyy-MM-dd")}
                  onChange={this.handleDateChange}
                  maxDate={endMonthOfNextYear()}
                />
              </Content>
            </Item>
            <Item>
              <Title>{AppointmentResource.ListItem.ListItemText.j003}</Title>
              <Content>
                <input
                  className={classes.input}
                  readOnly={true}
                  onClick={this.handleClickStartButton}
                  value={format(startDate, "HH:mm")}
                />
                <IconButton
                  className={classes.iconButton}
                  aria-label="Edit"
                  onClick={this.handleClickStartButton}
                >
                  <AccessTime />
                </IconButton>
                <TimePicker
                  time={format(startDate, "HH:mm")}
                  updateTime={this.handleStartTimeChange}
                  open={this.state.startTimePicker}
                  handleClose={this.closeStartTimePicker}
                />
              </Content>
            </Item>
            <Item>
              <Title>{AppointmentResource.ListItem.ListItemText.j004}</Title>
              <Content>
                <input
                  className={classes.input}
                  readOnly={true}
                  onClick={this.handleClickEndButton}
                  value={format(endDate, "HH:mm")}
                />
                <IconButton
                  className={classes.iconButton}
                  aria-label="Edit"
                  onClick={this.handleClickEndButton}
                >
                  <AccessTime />
                </IconButton>
                <TimePicker
                  time={format(endDate, "HH:mm")}
                  updateTime={this.handleEndTimeChange}
                  open={this.state.endTimePicker}
                  handleClose={this.closeEndTimePicker}
                />
              </Content>
            </Item>
            <Item className={isUnselectedMenuError ? classes.menuError : null}>
              <Title>{AppointmentResource.ListItem.ListItemText.j005}</Title>
              <Content>
                {isUnselectedMenuError ? (
                  <SelectErrorContent>
                    <FontPValidateError>
                      {AppointmentResource.ListItem.ValidateError.j001}
                    </FontPValidateError>
                    <CustomNativeSelectError
                      disableUnderline
                      IconComponent={ArrowDropDownIcon}
                      value={this.state.menuId}
                      onChange={this.handleChangeMenuName}
                    >
                      {this.state.availableMenus.map((item, index) => (
                        <option value={item.menuId} key={index}>
                          {this.createMenuNameOption(item)}
                        </option>
                      ))}
                    </CustomNativeSelectError>
                  </SelectErrorContent>
                ) : (
                  <CustomNativeSelect
                    disableUnderline
                    IconComponent={ArrowDropDownIcon}
                    value={this.state.menuId}
                    onChange={this.handleChangeMenuName}
                  >
                    {this.state.availableMenus.map((item, index) => (
                      <option value={item.menuId} key={index}>
                        {this.createMenuNameOption(item)}
                      </option>
                    ))}
                  </CustomNativeSelect>
                )}
              </Content>
            </Item>
          </Container>
        </AppointmentArea>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(Appointment);
