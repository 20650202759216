import { Auth } from "aws-amplify";

const cognitoSessionOut = async () => {
  try {
    //confirming cognito user info exists
    const user = await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    //confirming cognito user info deleted or disabled
    const refreshRequest = new Promise((res, rej) => {
      user.refreshSession(session.getRefreshToken(), (err, session) => {
        return err ? rej(err) : res(session);
      });
    });
    await refreshRequest;
  } catch (error) {
    //when authenticating with session has expired
    if (error.code === "not authenticated") await Auth.signOut();
  }
};

export { cognitoSessionOut };
