// --------------------------------------------------
//
// Util Dialog class
//
// --------------------------------------------------

// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import FontSpan from "../../atoms/fonts/FontSpan";

// personal modules
// --------------------------------------------------

// style
// --------------------------------------------------
const styles = theme => ({
  buttonOk: {
    color: theme.button.ok.color,
    fontSize: "13px",
    fontWeight: "bold",
    height: "40px",
    backgroundColor: theme.button.ok.backgroundColor,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColor
    }
  },
  buttonCancel: {
    fontSize: "13px",
    fontWeight: "bold",
    height: "40px",
    backgroundColor: theme.button.cancelApproveAppo.backgroundColor,
    "&:hover": {
      backgroundColor: theme.button.cancelApproveAppo.backgroundColor
    }
  },
  center: {
    margin: "auto",
    padding: "0 !important"
  },
  dialogContentStyle: {
    margin: "auto",
    padding: " 0px 0px 24px 0px"
  },
  noMaxHeight: {
    padding: "23px 30px 30px 30px"
  }
});

// class define
// --------------------------------------------------
class DialogWithStylesUtil extends Component {
  // default
  // --------------------------------------------------
  static defaultProps = {
    open: false,
    title: "",
    buttons: {
      ok: {
        display: false,
        text: "ok",
        handleClick: function() {}
      },
      cancel: {
        display: false,
        text: "キャンセル",
        handleClick: function() {}
      }
    },
    maxWidth: false
  };

  _handleClose = () => {};

  render() {
    const {
      classes,
      open,
      onClose,
      title,
      buttons,
      maxWidth,
      children,
      alignCenter
    } = this.props;
    const centerClass = alignCenter ? classes.center : "";
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={maxWidth}
        PaperProps={{ classes: { root: classes.noMaxHeight } }}
      >
        <DialogTitle className={centerClass}>
          <FontSpan size="ll" weight="bold">
            {title}
          </FontSpan>
        </DialogTitle>
        <DialogContent
          className={alignCenter ? classes.dialogContentStyle : ""}
        >
          {children}
        </DialogContent>
        <DialogActions className={centerClass}>
          {buttons.cancel.display && (
            <Button
              className={classes.buttonCancel}
              onClick={buttons.cancel.handleClick}
            >
              {buttons.cancel.text}
            </Button>
          )}
          {buttons.ok.display && (
            <Button
              className={classes.buttonOk}
              onClick={buttons.ok.handleClick}
            >
              {buttons.ok.text}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogWithStylesUtil);
