// --------------------------------------------------
//
// file upload confirm
//
// --------------------------------------------------

// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

// personal modules
// --------------------------------------------------
import FontP from "../../../atoms/fonts/FontP";
import { japaneseList } from "../../../../Resources/japaneseList";
const Resources = japaneseList.Components.Appointment.FileuploadCompleteDialog;

// style
// --------------------------------------------------
const styles = theme => ({
  dialogTitle: {
    padding: "24px"
  },
  dialogContent: {
    padding: "36px !important"
  },
  dialogActions: {
    margin: "8px 20px 20px 20px",
    justifyContent: "center"
  },
  buttonOk: {
    color: theme.button.ok.color,
    backgroundColor: theme.button.ok.backgroundColor,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColor
    }
  }
});

// class define
// --------------------------------------------------
class FileuploadCompleteDialog extends Component {
  // default state
  // --------------------------------------------------
  static defaultProps = {
    open: false,
    onClose: function() {},
    desc: Resources.desc,
    maxWidth: "sm",
    isChatPage: false,
    buttonOkText: Resources.buttonOk,
    handleClickOk: function() {},
    buttonNextText: Resources.buttonNext,
    handleClickNext: function() {},
    alertMessage: ""
  };

  // render
  // --------------------------------------------------
  render() {
    const {
      classes,
      open,
      onClose,
      alertMessage,
      maxWidth,
      isChatPage,
      buttonOkText,
      handleClickOk,
      buttonNextText,
      handleClickNext
    } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={maxWidth}
        fullWidth={true}
      >
        <DialogContent className={classes.dialogContent}>
          {alertMessage !== "" ? (
            <FontP weight="bold" align="center">
              {alertMessage}
            </FontP>
          ) : (
            <FontP weight="bold" align="center">
              {Resources.err001} <br />
              {Resources.err002}
            </FontP>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button className={classes.buttonOk} onClick={handleClickOk}>
            {buttonOkText}
          </Button>
          {isChatPage && (
            <Button className={classes.buttonNext} onClick={handleClickNext}>
              {buttonNextText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(FileuploadCompleteDialog);
