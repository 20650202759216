import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Menu from "./Menu";
import AppointmentDesignation from "./AppointmentDesignation";
import AppointmentConfirm from "./AppointmentConfirm";
import Slot from "./Slot";
import SlotConfirm from "./SlotConfirm";
import { FLOW } from "./Constants";
import { Query } from "@apollo/react-components";
import { GetHospital } from "../../../../graphql";
import { LinearProgress, Typography } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { japaneseList } from "../../../../Resources/japaneseList";

const CalendarStepperResource =
  japaneseList.Components.Appointment.Stepper.CalendarStepper;
const styles = (_) => ({
  noMenu: {
    color: "red",
  },
  stepper: {
    minHeight: "336px",
    minWidth: "700px",
  },
  fristStep: {
    width: "500px",
    height: "141px",
  },
});

class CalendarStepper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      flow: props.flow,
      activeStep: props.activeStep,
      patient: null,
      appointment: null,
      slot: null,
      phoneNumber: "",
    };

    this.user = {};

    Auth.currentUserPoolUser().then((user) => {
      this.user = user;
    });
  }

  setPatient = (data) => {
    if (data) {
      this.setState({
        patient: data.patient,
      });
    } else {
      this.setState({
        patient: null,
      });
    }
  };

  setStep = (step, flow, isSlotChanged = false) => {
    if (!Number.isFinite(flow)) {
      flow = this.state.flow;
    }
    this.setState({
      activeStep: step,
      flow,
    });
    if (step === 2 && flow === FLOW.APPOINTMENT) {
      this.props.setSelectSlot(
        flow,
        this.state.appointment.from,
        this.state.appointment.to,
        this.state.appointment.selectedMenu
      );
    }

    if (isSlotChanged && flow === FLOW.SLOT) {
      const from = new Date(
        this.state.slot.selectedDate + "T" + this.state.slot.start
      );
      const to = new Date(
        this.state.slot.selectedDate + "T" + this.state.slot.end
      );
      this.props.setSelectSlot(
        flow,
        from,
        to,
        null,
        this.state.slot.menuMethod,
        this.state.slot.capacity ? this.state.slot.capacity : 1,
        this.state.slot.repetition,
        this.state.slot.repetitionEndValue,
        this.state.slot.repetitionEndDate
      );
    }

    if (step === 0 && flow === FLOW.APPOINTMENT) {
      this.setState({
        phoneNumber: "",
      });
    }
    this.props.onSetStep();
  };

  handleAppointmentChange = (data) => {
    this.setState({
      appointment: data,
    });
  };

  handleSlotChange = (data) => {
    this.setState({
      slot: data,
    });
  };

  handlePhoneNumberChange = (phoneNumber) => {
    this.setState({
      phoneNumber: phoneNumber,
    });
  };

  step = (num, props, medicalTypes) => {
    switch (num) {
      case 0:
        return (
          <Menu
            step={this.setStep}
            medicalTypes={medicalTypes}
            closeScheduleSelectionPopup={this.props.closeScheduleSelectionPopup}
          />
        );
      case 1:
        if (this.state.flow === FLOW.SLOT) {
          return (
            <Slot
              step={this.setStep}
              slot={this.props.slot}
              handleSlotChange={this.handleSlotChange}
              slotFrameLengthes={this.props.slotFrameLengthes}
              closeScheduleSelectionPopup={
                this.props.closeScheduleSelectionPopup
              }
              {...props}
              setSelectSlotWithBackupSelectSlot={
                this.props.setSelectSlotWithBackupSelectSlot
              }
            />
          );
        } else {
          return (
            <AppointmentDesignation
              step={this.setStep}
              patient={this.state.patient}
              setPatient={this.setPatient}
              hasCharge={this.state.flow === FLOW.APPOINTMENT}
              slotFrameLengthes={this.props.slotFrameLengthes}
              slot={this.props.slot}
              hospital={props.hospital}
              handleAppointmentChange={this.handleAppointmentChange}
              closeScheduleSelectionPopup={
                this.props.closeScheduleSelectionPopup
              }
              phoneNumber={this.state.phoneNumber}
              handlePhoneNumberChange={this.handlePhoneNumberChange}
              selectDoctor={this.props.selectDoctor}
              setSelectSlotWithBackupSelectSlot={
                this.props.setSelectSlotWithBackupSelectSlot
              }
              appointment={this.state.appointment}
            />
          );
        }
      case 2:
        return (
          <AppointmentConfirm
            step={this.setStep}
            appointment={this.state.appointment}
            handleRegisterAppointment={this.props.createAppointment}
            errors={this.props.errors}
            closeScheduleSelectionPopup={this.props.closeScheduleSelectionPopup}
            isLoading={this.props.isLoading}
          />
        );
      case 3:
        return (
          <SlotConfirm
            step={this.setStep}
            slot={this.state.slot}
            handleRegisterSlot={this.props.createSlot}
            errors={this.props.errors}
            closeScheduleSelectionPopup={this.props.closeScheduleSelectionPopup}
            isLoading={this.props.isLoading}
          />
        );
      default:
        return "Unknown step";
    }
  };

  handleNext = () => {
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  getMedicalTypes = (menus) =>
    menus.filter(
      (item) =>
        ((item.createSlotByDoctor && item.createAppointmentByPatient) ||
          item.createAppointmentByDoctor) &&
        (!item.assignToDoctor ||
          item.doctorId === this.props.selectDoctor.id ||
          !item.doctorId)
    );

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;

    return (
      <Query query={GetHospital}>
        {({ loading, data }) => {
          if (loading) {
            return <LinearProgress />;
          }

          const medicalTypes = this.getMedicalTypes(data.hospital.menus);

          if (medicalTypes.length === 0) {
            return (
              <Stepper orientation="vertical">
                <Typography className={classes.noMenu}>
                  {CalendarStepperResource.render.Query.Stepper.Typography.j001}
                </Typography>
              </Stepper>
            );
          }

          return (
            <div>
              {activeStep === 0 ? (
                <div className={classes.fristStep}>
                  {this.step(activeStep, data, medicalTypes)}
                </div>
              ) : (
                <div>{this.step(activeStep, data, medicalTypes)}</div>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(CalendarStepper);
