import gql from "graphql-tag";

export const FindDocuments = gql`
  query FindDocuments($input: InputDocuments!) {
    documents(input: $input) {
      id
      name
      fileUrl
      canDownload
      category
      activateDatetime
      isNew
    }
  }
`;