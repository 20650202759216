import React from 'react';
import styled from 'styled-components';
import { japaneseList } from "../../../Resources/japaneseList";

const StyledUnapprovedWarning = styled.div`
  position: absolute;
  display: inline-block;
  top: 77px;
  right: 260px;
  width: 135px;
  height: 20px;
  font-size: 10px;
  background: #636363;
  color: #fff;
  :before {
    content: "";
    position: absolute;
    top: -13px;
    left: 48px;
    margin-left: -15px;
    border: 5px solid transparent;
    border-bottom: 8px solid #636363;
  }
`;

const UnapprovedWarningText = styled.p`
  margin-top: 3px;
  padding: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
`;

const UnapprovedWarning = () => {
  const Resource = japaneseList.Components.Header;

  return (
    <React.Fragment>
      <StyledUnapprovedWarning>
        <UnapprovedWarningText>
          {Resource.render.AppBar.Toolbar.div.AlertContent}
        </UnapprovedWarningText>
      </StyledUnapprovedWarning>
    </React.Fragment>
  );
};

export default UnapprovedWarning;