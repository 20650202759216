import React from "react";
import StyledHeader from "../../atoms/others/Header";
import HeaderLogo from '../../molecules/header/HeaderLogo';

export const LogoOnlyHeader = () => {
  return (
    <React.Fragment>
      <StyledHeader>
        <HeaderLogo />
      </StyledHeader>
    </React.Fragment>
  );
};