import gql from "graphql-tag";

export const UpdateAppointment = gql`
  mutation UpdateAppointment(
    $patientId: String
    $createTime: String
    $status: String
    $roomName: String
    $from: String
    $to: String
    $medicalDoctorId: String
  ) {
    updateAppointment(
      patientId: $patientId
      createTime: $createTime
      status: $status
      roomName: $roomName
      from: $from
      to: $to
      doctorId: $medicalDoctorId
    ) {
      succeed
    }
  }
`;
