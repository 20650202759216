// --------------------------------------------------
//
// file upload content
//
// --------------------------------------------------

// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

// personal modules
// --------------------------------------------------
import DialogDeleteConfirm from "../../../molecules/dialogs/DialogDeleteConfirm";
import FontP from "../../../atoms/fonts/FontP";
import FontSpan from "../../../atoms/fonts/FontSpan";
import Panel from "../../../atoms/panels/Panel";
import FileuploadDialogFile from "./FileuploadDialogFile";
import FileuploadDialogInput from "./FileuploadDialogInput";
import { japaneseList } from "../../../../Resources/japaneseList";
import { APPOINTMENT_STATUS, MEDICAL_METHOD } from "../../../../Utils/const";

const Resources = japaneseList.Components.Appointment.FileuploadDialog;
const DeleteConfirmResources =
  japaneseList.Components.Appointment.FileUploadDeleteConfirmDialog;

let _ = require("lodash");
const fns = require("date-fns");

// style
// --------------------------------------------------
const styles = (theme) => ({
  dialogSubTitleText: {
    fontSize: "14px",
    lineHeight: "14px",
    padding: "8px 0 0 0",
  },
  dialogSubTitleNote: { fontSize: "12px", lineHeight: "12px" },
  // Must use !important to overide style of material-ui Grid
  gridItemPadding: { "& > div": { padding: "5px 8px !important" } },
  cautionText: { fontSize: "11px" },
});

const DeleteConfirmTitleContainer = styled.div`
  & > div {
    font-size: 14px;
  }
  & > div:first-child {
    padding-bottom: 5px;
  }
`;

const DeleteConfirmTitleItem = styled.div``;

// class define
// --------------------------------------------------
class FileuploadDialogContent extends Component {
  // default state
  // --------------------------------------------------
  static defaultProps = {
    cautionText: Resources.cautionText,
    fileSelectText: Resources.fileSelectText,
    fileSelectNoteText: Resources.fileSelectNoteText,
    fileList: [],
    handleNiceNameChange: function () {},
    handleChooseFileChange: function () {},
    handleDeleteFile: function () {},
  };

  // constructor
  // --------------------------------------------------
  constructor(props) {
    super(props);
    this.state = {
      existFileList: [],
      remainFileSlots: [],
      isOpenDeleteConfirmDialog: false,
    };
  }

  componentDidMount() {
    this.filterFilesList();
  }

  checkAvailableS3File = (file) => {
    let isAvailable = false;
    if (
      !_.isUndefined(file.file.expiredTime) &&
      fns.isAfter(fns.parseISO(file.file.expiredTime), new Date()) &&
      file.file.status === "available"
    ) {
      isAvailable = true;
    }
    return isAvailable;
  };

  handleDeleteFile = async (deletedFile, existFileList) => {
    this.handleDeselectDeleteFile();
    await this.props.handleDeleteFile(deletedFile, existFileList);
    this.filterFilesList();
  };

  handleSelectDeleteFile = (deletedFile) => {
    this.setState({
      deletedFile,
      isOpenDeleteConfirmDialog: true,
    });
  };

  handleDeselectDeleteFile = () => {
    this.setState({
      deletedFile: undefined,
      isOpenDeleteConfirmDialog: false,
    });
  };

  filterFilesList = () => {
    let existFileList = this.props.fileList
      .filter((file) => this.checkAvailableS3File(file))
      .sort((a, b) => fns.compareAsc(a.updatedTime, b.updatedTime))
      .slice(0, 5)
      .map((item) => {
        item.time = fns.parseISO(item.file.updatedTime).getTime();
        return item;
      });
    let numRemain =
      5 -
      existFileList.filter((item) => item.justUpdatedStatus !== "deleted")
        .length;
    let remainFileSlots = new Array(numRemain).fill({ file: {} });
    this.setState({ existFileList, remainFileSlots });
  };

  // event
  // --------------------------------------------------

  // render
  // --------------------------------------------------
  render() {
    const {
      appointmentSelected,
      newFileList,
      fileSelectText,
      fileSelectNoteText,
      cautionText,
      classes,
      handleNotExistS3File,
    } = this.props;

    const { medicalMethod } = appointmentSelected.menu;
    const showDoctorFileUploadConditions =
      medicalMethod === MEDICAL_METHOD.ONLINE &&
      (appointmentSelected.status === APPOINTMENT_STATUS.EXAM_COMPLETED ||
        appointmentSelected.status === APPOINTMENT_STATUS.PAYMENT_COMPLETED);

    return (
      <React.Fragment>
        <Grid container spacing={16} className={classes.gridItemPadding}>
          <FileuploadDialogFile
            appointmentSelected={appointmentSelected}
            fileList={this.state.existFileList}
            handleDeleteFile={this.handleSelectDeleteFile}
            handleNotExistS3File={handleNotExistS3File}
          />
          {this.state.remainFileSlots.length > 0 &&
            showDoctorFileUploadConditions && (
              <React.Fragment>
                <Grid container item xs={12}>
                  <FontP weight="bold" className={classes.dialogSubTitleText}>
                    {fileSelectText}
                    <FontSpan
                      weight="default"
                      size="sss"
                      className={classes.dialogSubTitleNote}
                    >
                      {fileSelectNoteText}
                    </FontSpan>
                  </FontP>
                </Grid>
                {this.state.remainFileSlots.map((file, index) => {
                  return (
                    <Grid container item xs={12}>
                      <FileuploadDialogInput
                        file={newFileList[index] || file}
                        key={index}
                        handleNiceNameChange={(name) =>
                          this.props.handleNiceNameChange(name, index)
                        }
                        handleChooseFileChange={(file, fileData) =>
                          this.props.handleChooseFileChange(
                            file,
                            fileData,
                            index
                          )
                        }
                      />
                    </Grid>
                  );
                })}
              </React.Fragment>
            )}
        </Grid>
        {showDoctorFileUploadConditions &&
          this.state.remainFileSlots.length > 0 && (
            <Panel padding={"vmh0"}>
              <FontP
                className={classes.cautionText}
                size="ss"
                color="gray"
                whiteSpace={"preWrap"}
              >
                {cautionText}
              </FontP>
            </Panel>
          )}
        <DialogDeleteConfirm
          isOpen={this.state.isOpenDeleteConfirmDialog}
          title={
            <DeleteConfirmTitleContainer className={classes.deleteConfirmTitle}>
              <DeleteConfirmTitleItem>
                {this.state.deletedFile && this.state.deletedFile.fileName}
              </DeleteConfirmTitleItem>
              <DeleteConfirmTitleItem>
                {DeleteConfirmResources.title}
              </DeleteConfirmTitleItem>
            </DeleteConfirmTitleContainer>
          }
          confirmText={DeleteConfirmResources.buttonOk}
          cancelText={DeleteConfirmResources.buttonCancel}
          handleConfirm={() => {
            this.handleDeleteFile(
              this.state.deletedFile,
              this.state.existFileList
            );
          }}
          handleCancel={this.handleDeselectDeleteFile}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FileuploadDialogContent);
