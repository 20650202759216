import React, { Component } from "react";
import { Hub } from "aws-amplify";
import { withRouter, Prompt } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Video from "twilio-video";
import TwilioHandler from "./TwilioHandler";
import {
  isBefore,
  isSameDay,
  isWithinInterval,
  parseISO,
  addMinutes,
} from "date-fns";
import {
  Button,
  Card,
  CardContent,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@material-ui/core";
import {
  client,
  GetAppointment,
  ConnectVideoChat,
  DisconnectVideoChat,
  GetPatientLoginStatus,
  RegisterServiceLog,
  ListAppointmentsInMedical,
  AddLowNetworkHistoryLog,
} from "../../../graphql";
import querystring from "querystring";
import classNames from "classnames";
import MedicalSideArea from "../../organisms/medical/MedicalSideArea";
import PatientCallingWarningDialog from "../../organisms/medical/PatientCallingWarningDialog";
import { AppointmentStatusChangedWarningDialog } from "../../organisms/medical/AppointmentStatusChangedWarningDialog";
import { MedicalDetailArea } from "../../organisms/medical/MedicalDetailArea";
import { MainAreaVideoClose } from "../../organisms/medical/MainAreaVideoClose";
import DialogDoctorUnready from "../../molecules/dialogs/DialogDoctorUnready";
import DialogPatientUnready from "../../molecules/dialogs/DialogPatientUnready";
import LoadingScreen from "../../molecules/others/LoadingScreen";
import { ChattingPromptInformationArea } from "../../organisms/medical/ChattingPromptInformationArea";
import StartButton from "../../organisms/medical/StartButton";

import BasicTemplate from "../../templates/BasicTemplate";
import { japaneseList } from "../../../Resources/japaneseList";
import Titles from "../../../Resources/Titles";
import { serviceLogLib } from "../../../Resources/serviceLogLib";
import * as Sentry from "@sentry/browser";
import {
  handleDoctorReady,
  handleStandbyStatus,
  handlePatientReady,
  VideoStandbyStatus,
} from "./VideoStandbyStatus";
import { APPOINTMENT_STATUS, OPERATION_TYPE } from "../../../Utils/const";
const MedicalResource = japaneseList.Pages.Medical.Medical;
const styles = (theme) => {
  return {
    root: {
      width: "100%",
      display: "flex",
    },
    card: {
      margin: "10px",
    },
    contentArea: {
      flex: "10 1 auto",
      margin: "0px 20px",
      height: "calc(100vh - 150px)",
    },
    diagnostic: {
      height: "100%",
      backgroundColor: "#333333",
      margin: "0px",
    },
    contentMedia: {
      height: "100%",
      position: "relative",
      "&:last-child": {
        padding: "0px",
      },
    },
    localMedia: {
      width: "200px",
      height: "200px",
      position: "absolute",
      bottom: "15px",
      right: "15px",
    },
    remoteMedia: {
      height: "100%",
      width: "100%",
    },
    "@global": {
      "#local-media video": {
        height: "100%",
        width: "100%",
      },
      "#remote-media video": {
        height: "100%",
        width: "100%",
      },
    },
    bigIndicator: {
      height: "4px",
    },
    sideArea: {
      height: "calc(100vh - 150px)",
      width: "290px",
      marginLeft: "30px",
    },
    interviewArea: {
      marginTop: "10px !important",
    },
    videoArea: {
      position: "relative",
      width: "100%",
    },
    videoButtonArea: {
      display: "flex",
      marginTop: "-60px",
    },
  };
};

const LOW_NETWORK_LEVELS = [0, 1, 2];

const NOT_ZERO_NETWORK_LEVELS = [1, 2, 3, 4, 5];

const MAX_DECIBELS_OF_LOW_AUDIO = 5;
const INTERVAL_COUNTS_CHECK_LOW_AUDIO = 2;

const APPOINTMENTTIMECURRENTSTATUS_INTERVAL = 10000; //msec
const LOGINSTATUS_INTERVAL = 10000; //msec
const DEVICEPERMISSION_INTERVAL = 10000; //msec
const APPOINTMENTANDVIDEOSTANDBYSTATUS_INTERVAL = 10000; //msec
const CONTENTS_STATUS_LIST = [
  APPOINTMENT_STATUS.BEFORE_EXAM,
  APPOINTMENT_STATUS.EXAM_COMPLETED,
  APPOINTMENT_STATUS.PAYMENT_COMPLETED,
  APPOINTMENT_STATUS.CANCELED_DOCTOR,
  APPOINTMENT_STATUS.CANCELED_PATIENT_PREVIOUS_DAY,
  APPOINTMENT_STATUS.CANCELED_PATIENT_FROM_DAY,
  APPOINTMENT_STATUS.UNAPPROVED,
  APPOINTMENT_STATUS.REJECTED,
  APPOINTMENT_STATUS.CANCELED_BEFORE_APPROVING,
];

class Medical extends Component {
  state = {
    appointment: null,
    patient: null,
    isMediaOpen: false,
    currentStatus: null,
    open: false,
    isPatientCallingWarning: false,
    isAppointmentStatusChangedWarning: false,
    hasAddedLocalLowNetworkLog: false,
    hasAddedRemoteLowNetworkLog: false,
    patientStandbyStatus: {
      isLogin: false,
      videoStandby: {
        isOpeningStandbyPage: false,
        isMediaDeviceBlocked: true,
        isMediaDeviceAccepted: false,
        lastMediaDeviceError: "",
      },
    },
    doctorStandbyStatus: {
      isDoctorReady: false,
      isShowMediaDevicePermissionAlert: true,
    },
    isOpenDoctorUnreadyDialog: false,
    isOpenPatientUnreadyDialog: false,
    videoStandbyStatus: VideoStandbyStatus.notTime,
    doctorDeviceError: "",
    isDisplayPatientNotExistedRoom: null,
  };

  TwilioHandler;
  patientIntervalID = null;
  doctorIntervalID = null;
  appointmentAndVideoStandbyStatusIntervalID = null;
  isChangedMediaOpenStatus = false;

  constructor(props) {
    super(props);

    this.TwilioHandler = new TwilioHandler(this);
    this.leaveButtonAreaRef = React.createRef();

    if (!this.props.location.search) {
      alert(MedicalResource.constructor.j001);
      return;
    }

    const { patientId, createTime } = querystring.parse(
      this.props.location.search.substring(1)
    );

    this.params = { patientId, createTime: parseInt(createTime, 10) };

    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    this.handlePageHide = this.handlePageHide.bind(this);
  }

  addLocalLowNetworkHistoryLog = async () => {
    const { data, errors } = await client.mutate({
      mutation: AddLowNetworkHistoryLog,
      variables: {
        appointmentId: `${this.state.appointment.patient.patientId}/${this.state.appointment.createTime}`,
        operationType: OPERATION_TYPE.DOCTOR_LOW_NETWORK,
      },
    });

    if ((data && data.addLowNetworkHistoryLog) || errors) {
      this.setState({ ...this.state, hasAddedLocalLowNetworkLog: true });
    }
  };

  addRemoteLowNetworkHistoryLog = async () => {
    const { data, errors } = await client.mutate({
      mutation: AddLowNetworkHistoryLog,
      variables: {
        appointmentId: `${this.state.appointment.patient.patientId}/${this.state.appointment.createTime}`,
        operationType: OPERATION_TYPE.PATIENT_LOW_NETWORK,
      },
    });

    if ((data && data.addLowNetworkHistoryLog) || errors) {
      this.setState({ ...this.state, hasAddedRemoteLowNetworkLog: true });
    }
  };

  handleLocalNetworkLevelChanged = (level) => {
    if (
      LOW_NETWORK_LEVELS.includes(level) &&
      !this.state.hasAddedLocalLowNetworkLog
    ) {
      this.addLocalLowNetworkHistoryLog();
    }

    this.setState({
      isDisplayDoctorLowNetwork: LOW_NETWORK_LEVELS.includes(level),
      isDisplayPatientLowNetwork:
        NOT_ZERO_NETWORK_LEVELS.includes(level) &&
        this.state.isDisplayPatientLowNetwork,
      localNetWorkLevel: level,
    });
  };

  handleRemoteNetworkLevelChanged = (level) => {
    if (
      LOW_NETWORK_LEVELS.includes(level) &&
      NOT_ZERO_NETWORK_LEVELS.includes(this.state.localNetWorkLevel) &&
      !this.state.hasAddedRemoteLowNetworkLog
    ) {
      this.addRemoteLowNetworkHistoryLog();
    }

    this.setState({
      isDisplayPatientLowNetwork:
        NOT_ZERO_NETWORK_LEVELS.includes(this.state.localNetWorkLevel) &&
        LOW_NETWORK_LEVELS.includes(level),
    });
  };

  handleLocalAudioVolumeLow = (decibel) => {
    if (decibel < MAX_DECIBELS_OF_LOW_AUDIO) {
      this.setState({
        localVolumeLowCount: this.state.localVolumeLowCount + 1,
      });
      if (this.state.localVolumeLowCount >= INTERVAL_COUNTS_CHECK_LOW_AUDIO) {
        this.setState({
          isDisplayDoctorLowAudio: true,
        });
      } else {
        this.setState({
          isDisplayDoctorLowAudio: false,
        });
      }
    } else {
      this.setState({
        isDisplayDoctorLowAudio: false,
        localVolumeLowCount: 0,
      });
    }
  };

  handlePatientleaveRoom = (isLeave) => {
    this.setState({ isDisplayPatientLeaveRoom: isLeave });
  };

  handlePatientExisted = (isExisted) => {
    this.setState({
      isDisplayPatientNotExistedRoom: isExisted === false,
    });
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  async handleOk() {
    Sentry.captureMessage("video-chat-disconnect", Sentry.Severity.Log);

    this.setState({ open: false });
    await this.leaveRoom();
  }

  leave = () => {
    this.setState({ open: true });
  };

  handleStartTelemedicine = async (isRestart) => {
    Sentry.captureMessage("video-chat-connect", Sentry.Severity.Log);

    let self = this;
    this.stopInterval();
    const { from, to, menu } = this.state.appointment;
    const now = new Date();
    const isTheSameDay = isSameDay(now, parseISO(to));

    const callStartTime = addMinutes(
      parseISO(from),
      -menu.canStartCallBeforeMinutes
    );
    if (
      isWithinInterval(now, {
        start: callStartTime,
        end: parseISO(to),
      }) ||
      isTheSameDay
    ) {
      const { patientId, createTime } = this.params;

      const {
        data: { connectVideoChat },
        errors,
      } = await client.mutate({
        mutation: ConnectVideoChat,
        variables: {
          patientId: patientId,
          createTime: createTime,
          isRestart: isRestart,
        },
      });

      if (errors && errors.length) {
        console.log(
          errors
            .map((item) => {
              return item.errorType + ":" + item.message;
            })
            .join("/")
        );
      }

      if (
        errors &&
        errors.filter((error) => error.errorType === "E01").length > 0
      ) {
        this.setState({
          isPatientCallingWarning: true,
        });
        return;
      }

      if (
        errors &&
        errors.filter((error) => error.errorType === "E02").length > 0
      ) {
        this.setState({
          isAppointmentStatusChangedWarning: true,
        });
        return;
      }

      if (
        errors &&
        errors.filter((error) => error.errorType === "E04").length > 0
      ) {
        this.showConnectError();
        return;
      }

      localStorage.setItem(
        "videoConnectingInfo",
        patientId + "|" + createTime + "|" + Date.now()
      );

      this.setState({
        isMediaOpen: true,
        isDisplayPatientLeaveRoom: false,
        isDisplayDoctorLowAudio: false,
        localVolumeLowCount: 0,
      });
      this.isChangedMediaOpenStatus = true;

      const connectOptions = {
        preferredVideoCodecs: ["H264"],
        networkQuality: {
          // 取得できるネットワーク品質の情報量となります、設定できる値は下記です。
          // none       0: 全く情報を取得できない。
          // minimal    1：参加者のネットワーク品質のレベル（NetworkQualityLevel）が取得できる。
          // moderate   2：ネットワーク品質のレベル（NetworkQualityLevel）とステータス（NetworkQualityStats）が取得できる。
          // detailed   3：2の設定よりもさらに詳細なネットワーク品質の情報が取得できる。
          // localの設定範囲は1～3です、remoteの設定範囲は0～3です。
          local: 3,
          remote: 3,
        },
      };
      Video.connect(connectVideoChat.token, connectOptions)
        .then(self.TwilioHandler.roomJoined)
        .then(() => {
          const params = {
            eventType: serviceLogLib.eventType.startVideoChat,
            executorType: serviceLogLib.executorType.doctor,
            isSucceeded: true,
            resourceType: serviceLogLib.resourceType.none,
          };

          client.mutate({
            mutation: RegisterServiceLog,
            variables: params,
          });
        })
        .catch((error) => {
          console.log(error);
          Sentry.captureException(error);

          const params = {
            eventType: serviceLogLib.eventType.startVideoChat,
            executorType: serviceLogLib.executorType.doctor,
            isSucceeded: false,
            contents: [
              {
                name: "Error",
                value: error.name + "/" + error.message,
              },
              {
                name: "Raw Data",
                value: error,
              },
            ],
            resourceType: serviceLogLib.resourceType.none,
          };

          client.mutate({
            mutation: RegisterServiceLog,
            variables: params,
          });

          this.startInterval();
        });
    } else {
      this.startInterval();
    }
  };

  outputLogLeaveButtonDisplayMount = () => {
    console.log(
      "video-chat-leaveButtonDisplay: isMediaOpen=" +
        this.state.isMediaOpen +
        (this.leaveButtonAreaRef && this.leaveButtonAreaRef.current
          ? ", hidden=" + this.leaveButtonAreaRef.current.hidden
          : "")
    );
    Sentry.addBreadcrumb({
      message: "video-chat-leaveButtonDisplay-mount",
      level: Sentry.Severity.Log,
    });
    this.isChangedMediaOpenStatus = false;
  };

  outputLogLeaveButtonDisplayUpdate = () => {
    if (this.isChangedMediaOpenStatus) {
      console.log(
        "video-chat-leaveButtonDisplay: isMediaOpen=" +
          this.state.isMediaOpen +
          (this.leaveButtonAreaRef && this.leaveButtonAreaRef.current
            ? ", hidden=" + this.leaveButtonAreaRef.current.hidden
            : "")
      );
      Sentry.addBreadcrumb({
        message: "video-chat-leaveButtonDisplay-update",
        level: Sentry.Severity.Log,
      });
      this.isChangedMediaOpenStatus = false;
    }
  };

  outputLogLeaveButtonDisplayUnmount = () => {
    console.log(
      "video-chat-leaveButtonDisplay: isMediaOpen=" +
        this.state.isMediaOpen +
        (this.leaveButtonAreaRef && this.leaveButtonAreaRef.current
          ? ", hidden=" + this.leaveButtonAreaRef.current.hidden
          : "")
    );
    Sentry.captureMessage(
      "video-chat-leaveButtonDisplay-unmount",
      Sentry.Severity.Log
    );
    this.isChangedMediaOpenStatus = false;
  };

  showDisconnectError = () => {
    Hub.dispatch(
      "msg",
      {
        event: "open",
        data: { message: MedicalResource.disconnect.j001, level: "error" },
      },
      "OnlineChat"
    );
  };

  showConnectError = () => {
    Hub.dispatch(
      "msg",
      {
        event: "open",
        data: { message: MedicalResource.connect.j001, level: "error" },
      },
      "OnlineChat"
    );
  };

  async leaveRoom() {
    // Init PatientStatus
    this.setState({
      patientStandbyStatus: {
        isLogin: false,
        videoStandby: {
          isOpeningStandbyPage: false,
          isMediaDeviceBlocked: true,
          isMediaDeviceAccepted: false,
          lastMediaDeviceError: "",
        },
      },
    });
    // First Invoke Interval Function
    await this.getDoctorStatus();
    await this.getAppointment();
    this.getvideoStandbyStatus();
    await this.getPatientLoginStatus();
    this.startInterval();
    const { patientId, createTime } = this.params;

    try {
      const { errors } = await client.mutate({
        mutation: DisconnectVideoChat,
        variables: {
          patientId: patientId,
          createTime: createTime,
        },
      });
      if (errors) {
        console.log(errors);
        this.showDisconnectError();
        return;
      }
    } catch (error) {
      this.showDisconnectError();
      return;
    }

    this.TwilioHandler.leaveRoomIfJoined();

    this.setState({
      isMediaOpen: false,
      currentStatus: "examCompleted",
      hasAddedLocalLowNetworkLog: false,
      hasAddedRemoteLowNetworkLog: false,
    });
    this.isChangedMediaOpenStatus = true;
  }

  handleBeforeUnload(e) {
    if (this.state.isMediaOpen) {
      e.preventDefault();

      e.returnValue = MedicalResource.render.div.Prompt.j001;
    }
    console.log("leave the current page");
    Sentry.captureMessage(
      "video-chat-leaveButtonDisplay-close",
      Sentry.Severity.Log
    );
  }

  handlePageHide() {
    if (this.state.isMediaOpen) {
      this.TwilioHandler.leaveRoomIfJoined();
    }
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("pagehide", this.handlePageHide);
  }

  async componentDidMount() {
    const { patientId, createTime } = this.params;
    try {
      const { data, errors } = await client.query({
        query: GetAppointment,
        variables: { patientId, createTime },
      });
      if (errors) {
        this.props.history.push("/");
        return;
      }

      const { loginStatus } = await this.getPatientLoginStatus();
      const appointment = data.appointment;
      this.setState({
        appointment: appointment,
        patient: appointment.patient,
        name: `${appointment.patient.familyName} ${appointment.patient.givenName}`,
        furigana: `${appointment.patient.familyNameKana} ${appointment.patient.givenNameKana}`,
        patientStandbyStatus: loginStatus || this.state.patientStandbyStatus,
      });

      const now = Date.now();
      const callStartTime = addMinutes(
        parseISO(appointment.from),
        -appointment.menu.canStartCallBeforeMinutes
      );
      if (isBefore(now, callStartTime)) {
        this.setState({ currentStatus: "beforeAppointmentTime" });
      } else if (isBefore(now, parseISO(appointment.to))) {
        this.setState({ currentStatus: "duringAppointmentTime" });
      } else {
        this.setState({ currentStatus: "afterAppointmentTime" });
      }

      setInterval(() => {
        const now = Date.now();
        const { appointment, currentStatus } = this.state;

        if (
          currentStatus === "beforeAppointmentTime" &&
          !isBefore(now, callStartTime)
        ) {
          this.setState({
            currentStatus: "duringAppointmentTime",
          });
        } else if (
          currentStatus === "duringAppointmentTime" &&
          !isBefore(now, parseISO(appointment.to))
        ) {
          this.setState({
            currentStatus: "afterAppointmentTime",
          });
        }
      }, APPOINTMENTTIMECURRENTSTATUS_INTERVAL);
      this.getDoctorStatus();
      this.getvideoStandbyStatus();
      this.loadContentsDateList({
        from: appointment.from,
        createTime: appointment.createTime,
      });
      this.startInterval();
    } catch (err) {
      alert(MedicalResource.componentDidMount.j001);
    }
    this.outputLogLeaveButtonDisplayMount();
  }

  startInterval() {
    if (!this.patientIntervalID) {
      this.intervalPatientLoginStatus();
    }
    if (!this.doctorIntervalID) {
      this.intervalDoctorDevicePermisson();
    }
    if (!this.appointmentAndVideoStandbyStatusIntervalID) {
      this.intervalAppointmentAndVideoStandbyStatus();
    }
  }

  stopInterval() {
    if (this.patientIntervalID) {
      clearInterval(this.patientIntervalID);
      this.patientIntervalID = null;
    }
    if (this.doctorIntervalID) {
      clearInterval(this.doctorIntervalID);
      this.doctorIntervalID = null;
    }
    if (this.appointmentAndVideoStandbyStatusIntervalID) {
      clearInterval(this.appointmentAndVideoStandbyStatusIntervalID);
      this.appointmentAndVideoStandbyStatusIntervalID = null;
    }
  }

  intervalPatientLoginStatus() {
    this.patientIntervalID = setInterval(async () => {
      await this.getPatientLoginStatus();
    }, LOGINSTATUS_INTERVAL);
  }

  intervalDoctorDevicePermisson() {
    this.doctorIntervalID = setInterval(async () => {
      this.getDoctorStatus();
    }, DEVICEPERMISSION_INTERVAL);
  }

  intervalAppointmentAndVideoStandbyStatus() {
    this.appointmentAndVideoStandbyStatusIntervalID = setInterval(async () => {
      await this.getAppointment();
      this.getvideoStandbyStatus();
    }, APPOINTMENTANDVIDEOSTANDBYSTATUS_INTERVAL);
  }

  async getAppointment() {
    const { patientId, createTime } = this.params;
    try {
      const { data, errors } = await client.query({
        query: GetAppointment,
        variables: { patientId, createTime },
      });
      if (errors) return false;
      if (data && data.appointment) {
        this.setState({
          appointment: data.appointment,
        });
      }
      return data;
    } catch (error) {
      console.error("getAppointment error:", JSON.stringify(error));
    }
  }

  async getPatientLoginStatus() {
    const { patientId, createTime } = this.params;
    const appointmentKey = createTime.toString();
    try {
      const { data, errors } = await client.query({
        query: GetPatientLoginStatus,
        variables: { patientId, appointmentKey },
      });
      if (errors) return false;
      if (data && data.loginStatus) {
        this.setState({
          patientStandbyStatus: data.loginStatus,
        });
      }
      return data;
    } catch (error) {
      console.error("getPatientLoginStatus error:", JSON.stringify(error));
    }
  }

  cancelPatientCallingWarning = () => {
    this.setState({ isPatientCallingWarning: false });
    this.startInterval();
  };

  closeAppointmentStatusChangedWarningDialog = () => {
    this.setState({ isAppointmentStatusChangedWarning: false });
    window.location.reload();
  };

  componentDidUpdate() {
    // #5643 Added investigation log about hidden attribute status after DOM update.
    this.outputLogLeaveButtonDisplayUpdate();
  }

  componentWillUnmount() {
    this.TwilioHandler.leaveRoomIfJoined();
    this.stopInterval();
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("pagehide", this.handlePageHide);
    this.outputLogLeaveButtonDisplayUnmount();
  }

  async getDoctorStatus() {
    const doctorStandbyStatus = await handleDoctorReady();
    this.setState({
      doctorStandbyStatus: doctorStandbyStatus,
      doctorDeviceError: doctorStandbyStatus.mediaDeviceError,
    });
  }

  getvideoStandbyStatus() {
    const videoStandbyStatus = handleStandbyStatus(
      this.state.appointment,
      this.state.currentStatus
    );
    this.setState({
      videoStandbyStatus: videoStandbyStatus,
    });
  }

  handleClickDoctorUnready = () => {
    this.setState({
      isOpenDoctorUnreadyDialog: true,
    });
    // When Doctor Call Not Possible Click, Output To Sentry Log.
    Sentry.captureMessage(
      "video-chat-callNotPossible-doctor",
      Sentry.Severity.Log
    );
  };

  closeDoctorUnreadyDialog = () => {
    this.setState({
      isOpenDoctorUnreadyDialog: false,
    });
  };

  handleClickPatientUnready = () => {
    this.setState({
      isOpenPatientUnreadyDialog: true,
    });
    // When Patient Call Not Possible Click, Output To Sentry Log.
    Sentry.captureMessage(
      "video-chat-callNotPossible-patient",
      Sentry.Severity.Log
    );
  };

  closePatientUnreadyDialog = () => {
    this.setState({
      isOpenPatientUnreadyDialog: false,
    });
  };

  loadContentsDateList = async (currentContents) => {
    const { patientId, createTime } = this.params;
    const { data, errors } = await client.query({
      query: ListAppointmentsInMedical,
      variables: {
        patientId,
        isDispalyInMedical: true,
        status: CONTENTS_STATUS_LIST,
      },
    });
    if (errors) {
      console.error(
        "ListAppointmentsInMedical errors:",
        JSON.stringify(errors)
      );
    }

    const appointmentsItems =
      data && data.appointments && data.appointments.items
        ? data.appointments.items
        : [];
    const contentsDateList = [...appointmentsItems];
    if (
      !contentsDateList.some(
        (item) => item.createTime === createTime.toString()
      )
    ) {
      contentsDateList.push(currentContents);
    }

    this.setState({
      contentsDateList: contentsDateList.slice().sort((a, b) => {
        return new Date(b.from) - new Date(a.from);
      }),
    });
  };

  render() {
    const { classes } = this.props;
    const {
      appointment,
      contentsDateList,
      name,
      furigana,
      patientStandbyStatus,
      doctorStandbyStatus,
      isMediaOpen,
      isOpenPatientUnreadyDialog,
      isOpenDoctorUnreadyDialog,
      videoStandbyStatus,
      doctorDeviceError,
      patient,
    } = this.state;

    const { isDoctorReady } = doctorStandbyStatus;
    const isPatientReady = handlePatientReady(
      appointment,
      patientStandbyStatus,
      videoStandbyStatus
    );

    const main = (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.sideArea}>
            <MedicalSideArea
              appointment={appointment}
              name={name}
              furigana={furigana}
            />
            {appointment && (
              <div className={classes.interviewArea}>
                <MedicalDetailArea
                  patient={patient}
                  appointment={appointment}
                  contentsDateList={contentsDateList}
                />
              </div>
            )}
          </div>
          <div className={classes.videoArea}>
            <div
              className={classes.contentArea}
              hidden={this.state.isMediaOpen}
            >
              <MainAreaVideoClose
                isDoctorReady={isDoctorReady}
                isPatientReady={isPatientReady}
                videoStandbyStatus={videoStandbyStatus}
                handleClickDoctorUnready={this.handleClickDoctorUnready}
                handleClickPatientUnready={this.handleClickPatientUnready}
                doctorDeviceError={doctorDeviceError}
                patientStandbyStatus={patientStandbyStatus}
                patient={patient}
              />
            </div>
            <div
              ref={this.leaveButtonAreaRef}
              className={classes.contentArea}
              hidden={!this.state.isMediaOpen}
            >
              <Card className={classNames(classes.card, classes.diagnostic)}>
                <CardContent className={classes.contentMedia}>
                  <div className={classes.localMedia} id="local-media" />
                  <div className={classes.remoteMedia} id="remote-media" />
                  <ChattingPromptInformationArea
                    isDisplayDoctorLowNetwork={
                      this.state.isDisplayDoctorLowNetwork
                    }
                    isDisplayPatientLowNetwork={
                      this.state.isDisplayPatientLowNetwork
                    }
                    isDisplayPatientLeaveRoom={
                      this.state.isDisplayPatientLeaveRoom
                    }
                    isDisplayDoctorLowAudio={this.state.isDisplayDoctorLowAudio}
                    isDisplayPatientNotExistedRoom={
                      this.state.isDisplayPatientNotExistedRoom
                    }
                  />
                </CardContent>
              </Card>
            </div>
            <div className={classes.videoButtonArea}>
              <StartButton
                appointment={appointment}
                isMediaOpen={isMediaOpen}
                isPatientReady={isPatientReady}
                isDoctorReady={isDoctorReady}
                videoStandbyStatus={videoStandbyStatus}
                leave={this.leave}
                handleStartTelemedicine={this.handleStartTelemedicine}
              />
            </div>
          </div>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={this.state.open}
          >
            <DialogTitle id="confirmation-dialog-title" />
            <DialogContent>
              {MedicalResource.render.div.Dialog.DialogContent.j001}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCancel} variant="outlined">
                {MedicalResource.render.div.Dialog.DialogActions.Button.j001}
              </Button>
              <Button
                onClick={this.handleOk.bind(this)}
                variant="raised"
                color="primary"
              >
                {MedicalResource.render.div.Dialog.DialogActions.Button.j002}
              </Button>
            </DialogActions>
          </Dialog>
          <Prompt
            when={this.state.isMediaOpen}
            message={(location) => MedicalResource.render.div.Prompt.j001}
          />
          <PatientCallingWarningDialog
            hasError={this.state.isPatientCallingWarning}
            closePopup={this.cancelPatientCallingWarning}
          />
          <AppointmentStatusChangedWarningDialog
            hasError={this.state.isAppointmentStatusChangedWarning}
            closePopup={this.closeAppointmentStatusChangedWarningDialog}
          />
          <DialogDoctorUnready
            open={isOpenDoctorUnreadyDialog}
            onClose={this.closeDoctorUnreadyDialog}
            handleClickButton={this.closeDoctorUnreadyDialog}
            doctorStandbyStatus={doctorStandbyStatus}
          />
          <DialogPatientUnready
            open={isOpenPatientUnreadyDialog}
            onClose={this.closePatientUnreadyDialog}
            handleClickButton={this.closePatientUnreadyDialog}
            patientStandbyStatus={patientStandbyStatus}
            videoStandbyStatus={videoStandbyStatus}
          />
        </div>
        <LoadingScreen isLoading={false} />
      </React.Fragment>
    );
    return <BasicTemplate main={main} title={Titles.medical} />;
  }
}

export default withStyles(styles)(withRouter(Medical));
