import { japaneseList } from "../Resources/japaneseList";
export const prefectureCodes = [
  {
    code: "JP-01",
    jpName: japaneseList.Utils.PrefectureCode.j001,
    enName: "Hokkaido"
  },
  {
    code: "JP-02",
    jpName: japaneseList.Utils.PrefectureCode.j002,
    enName: "Aomori"
  },
  {
    code: "JP-03",
    jpName: japaneseList.Utils.PrefectureCode.j003,
    enName: "Iwate"
  },
  {
    code: "JP-04",
    jpName: japaneseList.Utils.PrefectureCode.j004,
    enName: "Miyagi"
  },
  {
    code: "JP-05",
    jpName: japaneseList.Utils.PrefectureCode.j005,
    enName: "Akita"
  },
  {
    code: "JP-06",
    jpName: japaneseList.Utils.PrefectureCode.j006,
    enName: "Yamagata"
  },
  {
    code: "JP-07",
    jpName: japaneseList.Utils.PrefectureCode.j007,
    enName: "Fukushima"
  },
  {
    code: "JP-08",
    jpName: japaneseList.Utils.PrefectureCode.j008,
    enName: "Ibaraki"
  },
  {
    code: "JP-09",
    jpName: japaneseList.Utils.PrefectureCode.j009,
    enName: "Tochigi"
  },
  {
    code: "JP-10",
    jpName: japaneseList.Utils.PrefectureCode.j010,
    enName: "Gunma"
  },
  {
    code: "JP-11",
    jpName: japaneseList.Utils.PrefectureCode.j011,
    enName: "Saitama"
  },
  {
    code: "JP-12",
    jpName: japaneseList.Utils.PrefectureCode.j012,
    enName: "Chiba"
  },
  {
    code: "JP-13",
    jpName: japaneseList.Utils.PrefectureCode.j013,
    enName: "Tokyo"
  },
  {
    code: "JP-14",
    jpName: japaneseList.Utils.PrefectureCode.j014,
    enName: "Kanagawa"
  },
  {
    code: "JP-15",
    jpName: japaneseList.Utils.PrefectureCode.j015,
    enName: "Niigata"
  },
  {
    code: "JP-16",
    jpName: japaneseList.Utils.PrefectureCode.j016,
    enName: "Toyama"
  },
  {
    code: "JP-17",
    jpName: japaneseList.Utils.PrefectureCode.j017,
    enName: "Ishikawa"
  },
  {
    code: "JP-18",
    jpName: japaneseList.Utils.PrefectureCode.j018,
    enName: "Fukui"
  },
  {
    code: "JP-19",
    jpName: japaneseList.Utils.PrefectureCode.j019,
    enName: "Yamanashi"
  },
  {
    code: "JP-20",
    jpName: japaneseList.Utils.PrefectureCode.j020,
    enName: "Nagano"
  },
  {
    code: "JP-21",
    jpName: japaneseList.Utils.PrefectureCode.j021,
    enName: "Gifu"
  },
  {
    code: "JP-22",
    jpName: japaneseList.Utils.PrefectureCode.j022,
    enName: "Shizuoka"
  },
  {
    code: "JP-23",
    jpName: japaneseList.Utils.PrefectureCode.j023,
    enName: "Aichi"
  },
  {
    code: "JP-24",
    jpName: japaneseList.Utils.PrefectureCode.j024,
    enName: "Mie"
  },
  {
    code: "JP-25",
    jpName: japaneseList.Utils.PrefectureCode.j025,
    enName: "Shiga"
  },
  {
    code: "JP-26",
    jpName: japaneseList.Utils.PrefectureCode.j026,
    enName: "Kyoto"
  },
  {
    code: "JP-27",
    jpName: japaneseList.Utils.PrefectureCode.j027,
    enName: "Osaka"
  },
  {
    code: "JP-28",
    jpName: japaneseList.Utils.PrefectureCode.j028,
    enName: "Hyogo"
  },
  {
    code: "JP-29",
    jpName: japaneseList.Utils.PrefectureCode.j029,
    enName: "Nara"
  },
  {
    code: "JP-30",
    jpName: japaneseList.Utils.PrefectureCode.j030,
    enName: "Wakayama"
  },
  {
    code: "JP-31",
    jpName: japaneseList.Utils.PrefectureCode.j031,
    enName: "Tottori"
  },
  {
    code: "JP-32",
    jpName: japaneseList.Utils.PrefectureCode.j032,
    enName: "Shimane"
  },
  {
    code: "JP-33",
    jpName: japaneseList.Utils.PrefectureCode.j033,
    enName: "Okayama"
  },
  {
    code: "JP-34",
    jpName: japaneseList.Utils.PrefectureCode.j034,
    enName: "Hiroshima"
  },
  {
    code: "JP-35",
    jpName: japaneseList.Utils.PrefectureCode.j035,
    enName: "Yamaguchi"
  },
  {
    code: "JP-36",
    jpName: japaneseList.Utils.PrefectureCode.j036,
    enName: "Tokushima"
  },
  {
    code: "JP-37",
    jpName: japaneseList.Utils.PrefectureCode.j037,
    enName: "Kagawa"
  },
  {
    code: "JP-38",
    jpName: japaneseList.Utils.PrefectureCode.j038,
    enName: "Ehime"
  },
  {
    code: "JP-39",
    jpName: japaneseList.Utils.PrefectureCode.j039,
    enName: "Kochi"
  },
  {
    code: "JP-40",
    jpName: japaneseList.Utils.PrefectureCode.j040,
    enName: "Fukuoka"
  },
  {
    code: "JP-41",
    jpName: japaneseList.Utils.PrefectureCode.j041,
    enName: "Saga"
  },
  {
    code: "JP-42",
    jpName: japaneseList.Utils.PrefectureCode.j042,
    enName: "Nagasaki"
  },
  {
    code: "JP-43",
    jpName: japaneseList.Utils.PrefectureCode.j043,
    enName: "Kumamoto"
  },
  {
    code: "JP-44",
    jpName: japaneseList.Utils.PrefectureCode.j044,
    enName: "Oita"
  },
  {
    code: "JP-45",
    jpName: japaneseList.Utils.PrefectureCode.j045,
    enName: "Miyazaki"
  },
  {
    code: "JP-46",
    jpName: japaneseList.Utils.PrefectureCode.j046,
    enName: "Kagoshima"
  },
  {
    code: "JP-47",
    jpName: japaneseList.Utils.PrefectureCode.j047,
    enName: "Okinawa"
  }
];
