import {
  PRESCRIPTION_DRUG_INPUT_METHOD,
  BILLING_CATEGORY_OPTION,
  BILLING_DRUG_QUANTITY_FIRST_VISIT_OPTION,
  BILLING_DRUG_QUANTITY_REVISIT_OPTION,
} from "../Utils/const";
import { japaneseList } from "../Resources/japaneseList";

function getBillingCategorySettings(
  billingCategory,
  { inputMethod, fixedDrugsName } = {
    inputMethod: "",
    fixedDrugsName: "",
  }
) {
  switch (billingCategory) {
    case BILLING_CATEGORY_OPTION.FIRST_VISIT:
      return {
        isShowDrug: true,
        isShowBillingCategoryNote: false,
        drugQuantityOptionList: BILLING_DRUG_QUANTITY_FIRST_VISIT_OPTION,
      };
    case BILLING_CATEGORY_OPTION.REVISIT:
      return {
        isShowDrug: true,
        isShowBillingCategoryNote: false,
        drugQuantityOptionList: BILLING_DRUG_QUANTITY_REVISIT_OPTION,
      };
    case BILLING_CATEGORY_OPTION.CONSULTATION:
    case BILLING_CATEGORY_OPTION.NO_VISIT:
      const Resources = japaneseList.Components.Appointment;
      const drugNameNone =
        Resources.StatusChangeDialog.DialogContent.containerBorder.drugNameNone;
      return {
        isShowDrug: false,
        isShowBillingCategoryNote: true,
        drugQuantityOptionList: [],
        drugName: drugNameNone,
        drugsName: drugNameNone,
        drugQuantity: "0",
        drugsQuantity: "0",
      };
    case BILLING_CATEGORY_OPTION.OTHER: // 2024-11-28よりnoVisitに移行、過去の診療歴用に残している（実績の変更初期用）
      return {
        isShowDrug: true,
        isShowBillingCategoryNote: true,
        drugQuantityOptionList: [],
        drugsName:
          inputMethod === PRESCRIPTION_DRUG_INPUT_METHOD.FIXED
            ? fixedDrugsName
            : "",
        drugsQuantity: "",
      };
    default:
      return {
        isShowDrug: true,
        isShowBillingCategoryNote: true,
        drugQuantityOptionList: [],
      };
  }
}

function getBillingCategorySettingsForChangeInit(
  billingCategory,
  drugsQuantity,
  { inputMethod, fixedDrugsName } = {
    inputMethod: "",
    fixedDrugsName: "",
  }
) {
  switch (billingCategory) {
    case BILLING_CATEGORY_OPTION.FIRST_VISIT:
      if (
        !BILLING_DRUG_QUANTITY_FIRST_VISIT_OPTION.map(String).includes(
          drugsQuantity
        )
      ) {
        return {
          drugsQuantity: "",
        };
      }
      return null;
    case BILLING_CATEGORY_OPTION.REVISIT:
      if (
        !BILLING_DRUG_QUANTITY_REVISIT_OPTION.map(String).includes(
          drugsQuantity
        )
      ) {
        return {
          drugsQuantity: "",
        };
      }
      return null;
    case BILLING_CATEGORY_OPTION.CONSULTATION:
    case BILLING_CATEGORY_OPTION.NO_VISIT:
      const Resources = japaneseList.Components.Appointment;
      const drugNameNone =
        Resources.StatusChangeDialog.DialogContent.containerBorder.drugNameNone;
      return {
        drugsName: drugNameNone,
        drugsQuantity: "0",
      };
    case BILLING_CATEGORY_OPTION.OTHER:
      return {
        drugsName:
          inputMethod === PRESCRIPTION_DRUG_INPUT_METHOD.FIXED
            ? fixedDrugsName
            : "",
        drugsQuantity: "",
      };
    default:
      return null;
  }
}
export { getBillingCategorySettings, getBillingCategorySettingsForChangeInit };
