import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { client, GetInterviewSheetAnswer } from "../../../graphql";
import { japaneseList } from "../../../Resources/japaneseList";
import { MedicalInterviewSheetAnswer } from "./MedicalInterviewSheetAnswer";
import MedicalOperationHistory from "./MedicalOperationHistory";
import garyFilesImg from "../../../images/gray_files.svg";
import IconInsurance from "../../atoms/icons/IconInsurance";
import IconNotInsurance from "../../atoms/icons/IconNotInsurance";
import { isLnln } from "../../../Utils/checkLnln";
import { formatPeriod } from "../../../Utils/DateUtil";
import {
  INTERVIEW_SHEET_ANSWER_STATUS,
  HOSPITAL_TYPES,
  MEDICAL_METHOD
} from "../../../Utils/const";
import { useDoctor, useHospital } from "../../../hooks";

const MedicalDetailAreaResource = japaneseList.Pages.Patient.MedicalDetailArea;

const RootWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
`;

const Title = styled.div`
  font-size: 14px;
  color: #3c3c3c;
  margin-left: 15px;
`;

const NoMedicalHistoryImg = styled.div`
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleName = styled.div`
  display: inline;
  font-size: 14px;
`;

const InsuranceTreatment = styled.div`
  display: inline-block;
  margin-left: 5px;
`;

const CustomIconInsurance = styled(IconInsurance)`
  border: solid 1px ${(p) => p.theme.color.primary};
  font-size: 10px;
`;

const CustomIconNotInsurance = styled(IconNotInsurance)`
  border: solid 1px #ef5e5e;
  font-size: 10px;
`;

const ContentsButton = styled.div`
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  margin-right: 5px;
`;

const CustomButtonNormal = styled(ContentsButton)`
  background-color: #eeeeee;
  color: #808080;
`;

const CustomButtonSelected = styled(ContentsButton)`
  background-color: ${isLnln() ? "#ebf0fc" : "#e1f8f8"};
  border-bottom: 2px solid ${(p) => p.theme.color.primary};
  color: ${(p) => p.theme.font.color.tab};
`;

const TabArea = styled.div`
  margin: 0;
`;

export const MedicalDetailArea = ({
  patient,
  selectedAppointment,
  isExistMedicalHistory,
  setIsInteviewSheetAnswerLoading,
}) => {
  const patientId = patient ? patient.patientId : "";
  //contents includes appointment date,displayName,supportsInsuranceTreatment shown in Header of this component,
  //and companyName,content(consultation content), interviewSheetAnswer shown in InterviewSheetAnswerDetail
  const [contents, setContents] = useState({});
  const [selectTabIndex, setSelectTabIndex] = useState(0);
  const doctor = useDoctor();
  const hospital = useHospital();
  const isOnlineAppointment = selectedAppointment?.menu?.medicalMethod === MEDICAL_METHOD.ONLINE;
  const isCentralClinicWithDifferentDoctor =
    hospital?.hospitalType === HOSPITAL_TYPES.CENTRAL_CLINIC &&
    selectedAppointment?.doctor?.doctorId !== doctor?.doctorId;

  useEffect(() => {
    if (selectedAppointment && patientId) loadContents(selectedAppointment);
    if (isCentralClinicWithDifferentDoctor) setSelectTabIndex(0);
  }, [selectedAppointment]);

  const getInterviewSheetAnswer = async (createTime) => {
    const medicalDoctorId = selectedAppointment.doctor
      ? selectedAppointment.doctor.doctorId
      : null;
    const { data, errors } = await client.query({
      query: GetInterviewSheetAnswer,
      variables: {
        patientId,
        createTime,
        medicalDoctorId,
      },
    });
    if (errors) {
      console.error("GetInterviewSheetAnswer errors:", JSON.stringify(errors));
    }
    return data ? data.interviewSheetAnswer : null;
  };

  const loadContents = async (selectedAppointment) => {
    const interviewSheetAnswer =
      selectedAppointment.interviewSheetAnswerStatus ===
        INTERVIEW_SHEET_ANSWER_STATUS.UNANSWERED ||
      selectedAppointment.interviewSheetAnswerStatus ===
        INTERVIEW_SHEET_ANSWER_STATUS.ANSWERED
        ? await getInterviewSheetAnswer(selectedAppointment.createTime)
        : null;
    const companyName = selectedAppointment.companyName;
    const content = selectedAppointment.content;

    const date = formatPeriod(selectedAppointment.from, selectedAppointment.to);
    const appointmentDate = formatPeriod(
      selectedAppointment.from,
      selectedAppointment.to
    );
    const displayName = selectedAppointment.menu.displayName;
    const supportsInsuranceTreatment =
      selectedAppointment.menu.supportsInsuranceTreatment;

    setContents({
      date,
      appointmentDate,
      displayName,
      supportsInsuranceTreatment,
      companyName,
      content,
      interviewSheetAnswer,
    });
    setIsInteviewSheetAnswerLoading(false);
  };

  const handleChangeMedicalTab = (index) => {
    setSelectTabIndex(index);
  };

  const renderMedicalTabButtons = (
    isCentralClinicWithDifferentDoctor,
    selectTabIndex
  ) => {
    if (isCentralClinicWithDifferentDoctor && isOnlineAppointment) {
      return (
        <React.Fragment>
          <CustomButtonSelected>
            {MedicalDetailAreaResource.j001}
          </CustomButtonSelected>
        </React.Fragment>
      );
    }

    if (selectTabIndex) {
      return (
        <React.Fragment>
          <CustomButtonNormal onClick={() => handleChangeMedicalTab(0)}>
            {MedicalDetailAreaResource.j001}
          </CustomButtonNormal>
          <CustomButtonSelected>
            {MedicalDetailAreaResource.j007}
          </CustomButtonSelected>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <CustomButtonSelected>
            {MedicalDetailAreaResource.j001}
          </CustomButtonSelected>
          <CustomButtonNormal onClick={() => handleChangeMedicalTab(1)}>
            {MedicalDetailAreaResource.j007}
          </CustomButtonNormal>
        </React.Fragment>
      );
    }
  };

  return isExistMedicalHistory ? (
    <React.Fragment>
      <RootWrapper>
        <Header>
          <Title>
            <TitleName>
              {contents.date}
              {MedicalDetailAreaResource.j005}
              {contents.displayName}
            </TitleName>
            <InsuranceTreatment>
              {contents.supportsInsuranceTreatment ? (
                <CustomIconInsurance>
                  {MedicalDetailAreaResource.j008}
                </CustomIconInsurance>
              ) : (
                <CustomIconNotInsurance>
                  {MedicalDetailAreaResource.j009}
                </CustomIconNotInsurance>
              )}
            </InsuranceTreatment>
          </Title>
        </Header>
        <TabArea>
          {renderMedicalTabButtons(isCentralClinicWithDifferentDoctor, selectTabIndex)}
        </TabArea>
        {selectTabIndex ? (
          <MedicalOperationHistory
            patient={patient}
            selectedAppointment={selectedAppointment}
          />
        ) : (
          <MedicalInterviewSheetAnswer patient={patient} contents={contents} />
        )}
      </RootWrapper>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <NoMedicalHistoryImg>
        <img src={garyFilesImg} alt="" />
      </NoMedicalHistoryImg>
    </React.Fragment>
  );
};
