import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { VideoStandbyStatus } from "../../pages/medical/VideoStandbyStatus";
import { japaneseList } from "../../../Resources/japaneseList";
import startVideoIcon from "../../../images/icon/icon-video-start.svg";
import endVideoIcon from "../../../images/icon/icon-video-end.svg";

const StartButtonResource = japaneseList.Pages.Medical.StartButton;
const styles = (theme) => {
  return {
    startButton: {
      margin: "0 auto",
      display: "block",
      fontSize: "14px",
      fontWeight: "700",
      color: "#ffffff",
      background: theme.button.ok.backgroundColor,
      boxShadow: "0px 2px 0px " + theme.button.ok.shadowColor,
      "&:hover": {
        background: theme.button.ok.backgroundColorHover,
      },
    },
    leaveButton: {
      margin: "0 auto",
      display: "block",
      fontSize: "14px",
      fontWeight: "700",
      background: "#FF4D4D",
      color: "#FFFFFF",
      boxShadow: "0px 2px 0px #BF3939",
      "&:hover": {
        background: "#FA7D7D",
      },
    },
    startButtonIcon: {
      verticalAlign: "middle",
    },
    startButtonText: {
      display: "inline-block",
      verticalAlign: "middle",
    },
    buttonArea: {
      height: "40px",
    },
    disabledButton: {
      margin: "0 auto",
      display: "block",
      fontSize: "14px",
      fontWeight: "700",
      background: "#dddddd !important",
      color: "#ffffff !important",
    },
  };
};

class StartButton extends Component {
  handleStandbyLabel = (videoStandbyStatus) => {
    switch (videoStandbyStatus) {
      case VideoStandbyStatus.start:
        return StartButtonResource.getStartLabel.j001;
      case VideoStandbyStatus.resume:
        return StartButtonResource.getResumeLabel.j001;
      case VideoStandbyStatus.end:
        return StartButtonResource.getStartLabel.j001;
      case VideoStandbyStatus.notTime:
        return StartButtonResource.getStartLabel.j001;
      default:
        throw new Error("unexpected videoStandbyStatus");
    }
  };

  handleStartBtnDisabled = (
    videoStandbyStatus,
    isDoctorReady,
    isPatientReady
  ) => {
    if (
      videoStandbyStatus === VideoStandbyStatus.notTime ||
      videoStandbyStatus === VideoStandbyStatus.end
    ) {
      return true;
    }
    if (!(isDoctorReady && isPatientReady)) {
      return true;
    }
  };

  render() {
    const {
      classes,
      appointment,
      isMediaOpen,
      isPatientReady,
      isDoctorReady,
      videoStandbyStatus,
      leave,
      handleStartTelemedicine,
    } = this.props;
    if (!appointment) {
      return null;
    }
    const disabled = this.handleStartBtnDisabled(
      videoStandbyStatus,
      isDoctorReady,
      isPatientReady
    );

    const label = this.handleStandbyLabel(videoStandbyStatus);
    const isRestart = videoStandbyStatus === VideoStandbyStatus.resume;
    return isMediaOpen ? (
      <Button
        onClick={leave}
        className={classes.leaveButton}
        variant="raised"
        size="large"
      >
        <img src={endVideoIcon} className={classes.startButtonIcon} />
        <label className={classes.startButtonText}>
          {StartButtonResource.endVideo.j001}
        </label>
      </Button>
    ) : (
      <Button
        onClick={() => handleStartTelemedicine(isRestart)}
        className={disabled ? classes.disabledButton : classes.startButton}
        variant="raised"
        size="large"
        disabled={disabled}
      >
        <img src={startVideoIcon} className={classes.startButtonIcon} />
        <label className={classes.startButtonText}>{label}</label>
      </Button>
    );
  }
}

export default withStyles(styles)(StartButton);
