import React, { useState } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { ArrowDropDown, AccountCircle, ChevronRight } from "@material-ui/icons";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";
import { japaneseList } from "../../../Resources/japaneseList";
import TooltipBase from "./TooltipBase";
import OtherDocumentsIconCarada from "../../../images/other_documents_24px.svg";
import OtherDocumentsIconLnln from "../../../images/other_documents_24px_lnln.svg";
import OtherMailOutlineIconCarada from "../../../images/other_mail_outline-24px.svg";
import OtherMailOutlineIconLnln from "../../../images/other_mail_outline-24px_lnln.svg";
import OtherSettingsIconCarada from "../../../images/other_settings-24px.svg";
import OtherSettingsIconLnln from "../../../images/other_settings-24px_lnln.svg";
import LogoutIconCarada from "../../../images/logout.svg";
import LogoutIconLnln from "../../../images/logout_lnln.svg";
import { isLnln } from "../../../Utils/checkLnln";
import { Auth } from "aws-amplify";
import { serviceLogLib } from "../../../Resources/serviceLogLib";
import { client, RegisterServiceLog } from "../../../graphql";

const OtherDocumentsIcon = isLnln()
  ? OtherDocumentsIconLnln
  : OtherDocumentsIconCarada;

const OtherMailOutlineIcon = isLnln()
  ? OtherMailOutlineIconLnln
  : OtherMailOutlineIconCarada;

const OtherSettingsIcon = isLnln()
  ? OtherSettingsIconLnln
  : OtherSettingsIconCarada;

const LogoutIcon = isLnln() ? LogoutIconLnln : LogoutIconCarada;

const Resource = japaneseList.Components.Header.render.AppBar.Toolbar.div;

const styles = (theme) => {
  return {
    buttonOk: {
      backgroundColor: theme.button.ok.backgroundColor,
      boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
      "&:hover": {
        backgroundColor: theme.button.ok.backgroundColorHover,
      },
    },
  };
};

const LinksWrapper = styled.div`
  position: absolute;
  top: 70px;
  right: 0;
  width: 257px;
  background-color: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #cccccc;
  color: ${(p) => p.theme.header.other.color};
`;

const LoginUserInfoArea = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-top: 14px;
  padding-left: 10px;
  font-weight: bold;
`;

const Hospital = styled.div`
  line-height: 20px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

const DoctorItem = styled.div`
  display: flex;
`;

const DoctorIcon = styled.div`
  margin: 5px 5px 5px 0;
`;

const DoctorName = styled.div`
  margin-top: 10px;
  height: 16px;
  line-height: 16px;
  font-size: 16px;
`;

const LinkItem = styled.div`
  height: 36px;
  padding: 2px 5px;
  display: flex;
  font-size: 14px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #cccccc;
  :hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.header.other.hover};
  }
`;

const Borderline = styled.hr`
  height: 1px;
  margin: 0;
  padding: 0;
  border: 1px solid ${(p) => p.theme.header.other.border};
`;

const LinkIcon = styled.div`
  margin: 4px;
`;

const StyledIcon = styled.img`
  fill: ${(p) => p.theme.header.other.color};
`;

const LinkTitle = styled.div`
  font-size: 14px;
  margin-top: 6px;
  width: 100%;
`;

const RightIcon = styled.div`
  margin: 4px 0 4px 10px;
  color: #999999;
`;

const OtherTooltip = (props) => {
  const { classes } = props;
  const [isShowLinks, setIsShowLinks] = useState(false);
  const [isOpenLogoutDialog, setIsOpenLogoutDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();

  const openLogoutDialog = () => {
    setIsOpenLogoutDialog(true);
  };

  const closeLogoutDialog = () => {
    setIsOpenLogoutDialog(false);
  };

  const logout = async () => {
    if (isSubmitting) {
      return false;
    }
    setIsSubmitting(true);
    const params = {
      eventType: serviceLogLib.eventType.logout,
      executorType: serviceLogLib.executorType.doctor,
      isSucceeded: true,
      resourceType: serviceLogLib.resourceType.none,
    };
    await client.mutate({
      mutation: RegisterServiceLog,
      variables: params,
    });
    setIsSubmitting(false);
    await client.clearStore();
    await Auth.signOut();
    history.push("/");
  };

  return (
    <React.Fragment>
      <TooltipBase
        title={null}
        onClick={() => {
          setIsShowLinks(!isShowLinks);
        }}
        Icon={ArrowDropDown}
        isUnderline={
          history.location.pathname === "/documents" ||
          history.location.pathname === "/contact" ||
          history.location.pathname === "/settings"
        }
      />
      {isShowLinks && (
        <LinksWrapper>
          <LoginUserInfoArea>
            {props.hospital && <Hospital>{props.hospital.name}</Hospital>}
            {props.loginDoctorName && (
              <DoctorItem>
                <DoctorIcon>
                  <AccountCircle />
                </DoctorIcon>
                <DoctorName>{props.loginDoctorName}</DoctorName>
              </DoctorItem>
            )}
          </LoginUserInfoArea>
          <Borderline />
          <LinkItem
            onClick={() => {
              history.push("/documents");
            }}
          >
            <LinkIcon>
              <StyledIcon src={OtherDocumentsIcon} alt="" />
            </LinkIcon>
            <LinkTitle>{Resource.Tooltip.documents}</LinkTitle>
            <RightIcon>
              <ChevronRight />
            </RightIcon>
          </LinkItem>
          <LinkItem
            onClick={() => {
              history.push("/contact");
            }}
          >
            <LinkIcon>
              <StyledIcon src={OtherMailOutlineIcon} alt="" />
            </LinkIcon>
            <LinkTitle>{Resource.Tooltip.contact}</LinkTitle>
            <RightIcon>
              <ChevronRight />
            </RightIcon>
          </LinkItem>
          <LinkItem
            onClick={() => {
              history.push("/settings");
            }}
          >
            <LinkIcon>
              <StyledIcon src={OtherSettingsIcon} alt="" />
            </LinkIcon>
            <LinkTitle>{Resource.Tooltip.settings}</LinkTitle>
            <RightIcon>
              <ChevronRight />
            </RightIcon>
          </LinkItem>
          <LinkItem onClick={openLogoutDialog}>
            <LinkIcon>
              <StyledIcon src={LogoutIcon} alt="" />
            </LinkIcon>
            <LinkTitle>{Resource.Tooltip.logout}</LinkTitle>
            <RightIcon>
              <ChevronRight />
            </RightIcon>
          </LinkItem>
        </LinksWrapper>
      )}
      <Dialog
        id="menu-appbar"
        open={isOpenLogoutDialog}
        onClose={closeLogoutDialog}
      >
        <DialogTitle>{Resource.Dialog.DialogTitle.j001}</DialogTitle>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            onClick={closeLogoutDialog}
            color="default"
          >
            {Resource.Dialog.DialogActions.Button.j001}
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={logout}
            color="primary"
            autoFocus
            className={classes.buttonOk}
            disabled={isSubmitting}
          >
            {Resource.Dialog.DialogActions.Button.j002}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withStyles(styles)(OtherTooltip);
