// --------------------------------------------------
//
// insurance card Dialog class
//
// --------------------------------------------------

// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import KaitenImageCarada from "../../../images/kaiten.svg";
import KaitenImageLnln from "../../../images/kaiten_lnln.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

// personal modules
// --------------------------------------------------
import FontPError from "../../atoms/fonts/FontPError";
import { japaneseList } from "../../../Resources/japaneseList";
import { isLnln } from "../../../Utils/checkLnln";
const Resources = japaneseList.molecules.dialogs.DialogInsuranceCardConfirm;

// style
// --------------------------------------------------
const CustomImage = styled.img`
  width: 400px;
  height: 400px;
  object-fit: contain;
`;

const styles = (theme) => ({
  dialogTitle: {
    padding: "24px",
  },
  dialogContent: {
    padding: "0 36px 24px 36px",
    textAlign: "center",
    overflow: "hidden",
    margin: "0 auto",
  },
  dialogContentProgress: {
    padding: "24px 36px 24px 36px",
    textAlign: "center",
  },
  dialogActions: {
    margin: "8px 20px 20px 20px",
  },
  button: {
    color: theme.button.cancel.color,
    backgroundColor: theme.button.cancel.backgroundColor,
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColor,
    },
  },
  rotateButton: {
    float: "right",
    border: `2px solid ${theme.button.rotate.backgroundColor}`,
    padding: "6px 10px 6px 32px",
    borderRadius: "8px",
    color: theme.button.rotate.color,
    fontSize: "13px",
    fontWeight: 500,
    backgroundImage: `url(
      ${KaitenImage}
    )`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "14%",
    backgroundPositionX: "7px",
    backgroundPositionY: "7px",
  },
  classDialog: {
    width: "70%",
    margin: "0 auto",
  },
});

const KaitenImage = isLnln() ? KaitenImageLnln : KaitenImageCarada;

// class define
// --------------------------------------------------
class DialogInsuranceCardConfirm extends Component {
  // default
  // --------------------------------------------------
  static defaultProps = {
    open: false,
    onClose: function () {},
    handleClickButton: function () {},
    maxWidth: "md",
    file: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      rotation: 0,
      fileName: "",
    };
  }
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    let fileName = nextProps.fileName;
    if (this.state.fileName !== fileName)
      this.setState({
        rotation: 0,
        fileName,
      });
  };

  handleClickRotate = () => {
    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    this.setState({
      rotation: newRotation,
    });
  };

  render() {
    const { classes, open, onClose, handleClickButton, maxWidth, filePath } =
      this.props;
    const { rotation } = this.state;
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth={maxWidth}
          className={classes.classDialog}
          fullWidth={true}
        >
          <DialogTitle className={classes.dialogTitle}>
            {Resources.title}
            <Button
              id="video-insurance-card-confirm-rotate-button"
              className={classes.rotateButton}
              onClick={() => this.handleClickRotate()}
            >
              {Resources.rotateButton}
            </Button>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <CustomImage
              style={{ transform: `rotate(${rotation}deg)` }}
              src={filePath}
            />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <FontPError size="s" style={{ margin: "auto" }}>
              {Resources.caution}
            </FontPError>
            <Button
              id="video-insurance-card-confirm-close-button"
              size="small"
              className={classes.button}
              onClick={handleClickButton}
            >
              {Resources.button}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DialogInsuranceCardConfirm);
