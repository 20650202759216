import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { japaneseList } from "../../../Resources/japaneseList";
import { TitleTag } from "../../atoms/others/TitleTag";
import { isLnln } from "../../../Utils/checkLnln";
import { Favicon } from "../../atoms/others/Favicon";

const NotFoundResource = japaneseList.Pages.NotFound;

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 20
  }
});

const NotFoundPage = (props) => {
  const { classes } = props;
  const _title = japaneseList.service.name;
  const servicePath = isLnln() ? "lnln" : "carada";
  return (
    <section className={classes.root}>
      <Typography variant="display4" gutterBottom align="center">
        {NotFoundResource.render.section.Typography.j001}
      </Typography>
      <Typography variant="title" gutterBottom align="center">
        {NotFoundResource.render.section.Typography.j002}
      </Typography>
      <Favicon servicePath={servicePath} />
      <TitleTag title={_title} />
    </section>
  );
};

export default withStyles(styles)(NotFoundPage);
