import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { japaneseList } from "../../../Resources/japaneseList";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-normal",
    height: "34px",
    lineHeight: "34px",
  },
  shiftPageButton: {
    padding: 0,
    width: "34px",
    minWidth: "auto",
    minHeight: "auto",
    lineHeight: "34px",
    borderRadius: "5px",
    backgroundColor: theme.button.ok.backgroundColor,
    fontSize: "14px",
    fontWeight: "bold",
    boxShadow: `0px 1px 0px ${theme.color.shadow}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    "&:disabled": {
      backgroundColor: "#dddddd",
      color: "#fff",
    },
  },
  changePageButton: {
    padding: 0,
    minWidth: "34px",
    minHeight: "auto",
    lineHeight: "34px",
    border: `solid 1px ${theme.button.pager.borderColor}`,
    borderRadius: "5px",
    backgroundColor: "#fff",
    color: theme.button.pager.colorSelected,
    fontSize: "14px",
    boxShadow: "none",
    marginRight: "7px",
    "&:hover": {
      backgroundColor: theme.button.pager.backgroundColorSelected,
      color: theme.button.pager.colorSelected,
    },
    "&:disabled": {
      backgroundColor: theme.button.pager.backgroundColorSelected,
      color: theme.button.pager.colorSelected,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  pages: {
    display: "flex",
    margin: "0 13px",
  },
  more: {
    marginRight: "7px",
    "&:last-child": {
      marginRight: 0,
    },
    color: theme.button.pager.colorMore,
  },
});

class Pager extends Component {
  getPagesInfo = () => {
    const { limit, offset, totalItemCount, displayPageLength } = this.props;
    const nowPage = Math.ceil(offset / limit) + 1;
    const lastPage = Math.ceil(totalItemCount / limit);
    const prevPage = nowPage > 1 && nowPage - 1;
    const nextPage = nowPage < lastPage && nowPage + 1;

    let minDisplayPage = nowPage - Math.floor(displayPageLength / 2);
    if (minDisplayPage < 1) minDisplayPage = 1;
    let maxDisplayPage = minDisplayPage + displayPageLength;
    if (maxDisplayPage > lastPage) {
      maxDisplayPage = lastPage;
      if (maxDisplayPage - minDisplayPage < displayPageLength) {
        minDisplayPage =
          maxDisplayPage - displayPageLength > 0
            ? maxDisplayPage - displayPageLength
            : 1;
      }
    }

    const pagesInfo = {
      nowPage,
      pages: [],
      prev: prevPage && { page: prevPage, offset: limit * (prevPage - 1) },
      next: nextPage && { page: nextPage, offset: limit * (nextPage - 1) },
      hasPrevMore: minDisplayPage > 1,
      hasNextMore: maxDisplayPage < lastPage,
    };

    for (let page = minDisplayPage; page <= maxDisplayPage; page++) {
      pagesInfo.pages.push({ page, offset: limit * (page - 1) });
    }

    return pagesInfo;
  };

  render() {
    const { handleChangeOffset, classes } = this.props;
    const pagesInfo = this.getPagesInfo();

    return (
      <div className={classes.root}>
        <Button
          variant="raised"
          color="primary"
          className={classes.shiftPageButton}
          disabled={!pagesInfo.prev}
          onClick={() => handleChangeOffset(pagesInfo.prev.offset)}
        >
          {japaneseList.molecules.pager.prev}
        </Button>
        <div className={classes.pages}>
          {pagesInfo.hasPrevMore && (
            <div className={classes.more}>
              {japaneseList.molecules.pager.more}
            </div>
          )}
          {pagesInfo.pages.map((pageInfo) => (
            <Button
              variant="raised"
              onClick={() => handleChangeOffset(pageInfo.offset)}
              color="primary"
              className={classes.changePageButton}
              key={pageInfo.page}
              disabled={pageInfo.page === pagesInfo.nowPage}
            >
              {pageInfo.page}
            </Button>
          ))}
          {pagesInfo.hasNextMore && (
            <div className={classes.more}>
              {japaneseList.molecules.pager.more}
            </div>
          )}
        </div>
        <Button
          variant="raised"
          color="primary"
          className={classes.shiftPageButton}
          disabled={!pagesInfo.next}
          onClick={() => handleChangeOffset(pagesInfo.next.offset)}
        >
          {japaneseList.molecules.pager.next}
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(Pager);
