import React, { Component } from "react";
import YamatoDeliveryDialogInput from "./YamatoDeliveryDialogInput";
import OtherDeliveryDialogInput from "./OtherDeliveryDialogInput";
import CSVPostDeliveryDialog from "./CSVPostDeliveryDialog";

import { DELIVERY_OPTION } from "../../../../Utils/const";

class DeliveryProcessInputDialog extends Component {
  render() {
    if (!this.props.appointment) return null;

    switch (this.props.deliveryOption) {
      case DELIVERY_OPTION.yamato:
        return <YamatoDeliveryDialogInput {...this.props} />;
      case DELIVERY_OPTION.csvPost:
        return <CSVPostDeliveryDialog {...this.props} />;
      case DELIVERY_OPTION.other:
        return <OtherDeliveryDialogInput {...this.props} />;
      default:
        return null;
    }
  }
}

export default DeliveryProcessInputDialog;
