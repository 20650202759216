import { convert, stringToCode } from "encoding-japanese";
import { prefectureCodes } from "./PrefectureCode";
import { japaneseList } from "../Resources/japaneseList";

const Resource = japaneseList.Components.Payment.CSVPostDeliveryDialog;

const blobDownload = (Filename, Bytes, Mimetype) => {
  const filData = new Blob(Bytes, { type: Mimetype });
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = URL.createObjectURL(filData);
  a.download = Filename;
  a.click();
  a.remove();
};

const downloadPaymentCSV = (appointment, deliveryParams = {}) => {
  const patient = appointment.patient || {};
  const {
    postalCode: originalPostalCode,
    prefectureCode,
    addressCity,
    addressStreetNumber,
    addressBuilding,
  } = patient.address || {};

  const postalCode = (originalPostalCode || "").replace(/^(...)/, `$1-`);
  const userName = `${patient.familyName || ""} ${patient.givenName || ""}`;
  const prefecture = prefectureCodes.find((c) => c.code === prefectureCode)
    .jpName;
  const deliveryProductName =
    deliveryParams.deliveryProductName || appointment.deliveryProductName || "";

  const csv =
    Object.values(Resource.CSVHeader).join(",") +
    "\n" +
    [
      postalCode,
      userName,
      Resource.CSVContent.j001,
      prefecture,
      addressCity,
      addressStreetNumber,
      addressBuilding,
      deliveryProductName,
    ]
      .map((x) => (x && x.trim()) || "")
      .join(",");

  const bytes = new Uint8Array(convert(stringToCode(csv), "SJIS"));
  blobDownload(`宛名ラベルデータ_${userName}.csv`, [bytes], "text/csv");
};

export { downloadPaymentCSV };
