import { useState, useEffect, useContext } from 'react';
import SiteContext from '../contexts/SiteContext';
import { client, GetHospital } from "../graphql";

export const useHospital = () => {
  const [hospital, setHospital] = useState(null);
  const siteContext = useContext(SiteContext);

  useEffect(() => {
    if (siteContext.hospital) {
      // console.log('use Hospital in Context!');
      setHospital(siteContext.hospital);
    } else {
      client.query({
        query: GetHospital
      })
      .then(res => {
        // console.log('use Hospital from API!');
        setHospital(res.data.hospital);
      })
      .catch(err => {
        console.error(err);
      });
    }
  }, []);

  return hospital;
}