import gql from "graphql-tag";

export const UpdateMedicalTreatment = gql`
  mutation UpdateMedicalTreatment(
    $patientId: String!
    $createTime: String!
    $treatmentCompletedSelectedOption: Int
    $memo: String
    $medicalExpense: Int
    $billingCategory: BillingCategory
    $drugsName: String
    $drugsQuantity: String
  ) {
    updateMedicalTreatment(
      patientId: $patientId
      createTime: $createTime
      treatmentCompletedSelectedOption: $treatmentCompletedSelectedOption
      memo: $memo
      medicalExpense: $medicalExpense
      billingCategory: $billingCategory
      drugsName: $drugsName
      drugsQuantity: $drugsQuantity
    ) {
      succeed
    }
  }
`;
