import React from "react";

import { Favicon } from "../atoms/others/Favicon";
import { TitleTag } from "../atoms/others/TitleTag";   
import { LogoOnlyHeader } from "../organisms/headers/LogoOnlyHeader";
import { Main } from "../atoms/others/Main";
import { Footer } from "../organisms/footers/Footer";
import LoadingScreen from "../molecules/others/LoadingScreen";

import { japaneseList } from "../../Resources/japaneseList";
import { isLnln } from "../../Utils/checkLnln";

export default ({ main, title, isLoading }) => {
  const serviceName = japaneseList.service.name;
  const _title = title ? title +  " | " + serviceName : serviceName;
  const servicePath = isLnln() ? "lnln" : "carada";

  return (
    <React.Fragment>
      <Favicon servicePath={servicePath} />
      <TitleTag title={_title} />
      <LogoOnlyHeader />
      <Main>{main}</Main>
      <Footer />
      <LoadingScreen isLoading={isLoading} />
    </React.Fragment>
  )
}

