import React from "react";
import Pager from "../../molecules/pager/Pager";
import { japaneseList } from "../../../Resources/japaneseList";
import styled from "styled-components";

const DisplayPageLength = 2;

const PaginateOperatorElement = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
`;

const Counter = styled.div`
  margin-right: 15px;
  line-height: 34px;
`;

const PaginateOperator = (props) => {
  const { offset, limit, totalItemCount, handleChangeOffset } = props;
  const itemCount =
    offset + limit < totalItemCount ? offset + limit : totalItemCount;

  return (
    <PaginateOperatorElement>
      <Counter>
        {totalItemCount
          ? japaneseList.molecules.pager.displayItemCount
              .replace("#totalItemCount", totalItemCount)
              .replace("#start", offset + 1)
              .replace("#end", itemCount)
          : japaneseList.molecules.pager.displayNoItemCount}
      </Counter>
      {totalItemCount > 0 && (
        <Pager
          limit={limit}
          offset={offset}
          totalItemCount={totalItemCount}
          displayPageLength={DisplayPageLength}
          handleChangeOffset={handleChangeOffset}
        />
      )}
    </PaginateOperatorElement>
  );
};

export default PaginateOperator;
