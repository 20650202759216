import React from "react";
import styled from "styled-components";
import FontSpan from "../../atoms/fonts/FontSpan";
import CurrencyText from "../../organisms/payment/CurrencyText";
import { japaneseList } from "../../../Resources/japaneseList";
import { PAYMENT_METHOD } from "../../../Utils/const";

const TblCellTotalFee = styled.td`
  text-align: center;
  padding: 5px 10px;
  border-left: 1px solid #cccccc;
  white-space: nowrap;
`;

const FontSpanCustom = styled(FontSpan)`
  color: #cccccc;
`;

const HospitalPaymentRowTotalFee = (props) => {
  const { appo, deliveryDisplayFee } = props;

  const feeText = (fee) => {
    // TODO: 健康経営のメニューかどうかの判定方法、判定箇所をリファクタリングする
    const isBillingEnterprise =
      appo.menu &&
      appo.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;
    return fee === null ? (
      <FontSpanCustom style={{ float: "right" }}>
        {japaneseList.Components.Payment.CustomNumber.NumberFormat.j001}
      </FontSpanCustom>
    ) : isBillingEnterprise ? (
      japaneseList.Components.Payment.CustomNumber.NumberFormat.j002
    ) : (
      <CurrencyText value={fee} style={{ float: "right" }} />
    );
  };
  const getFee = () => {
    if (!deliveryDisplayFee) {
      return null;
    }
    if (
      deliveryDisplayFee.deliveryTotalFee ||
      (appo.payment && appo.payment.medicalExpense)
    ) {
      return (
        (deliveryDisplayFee.deliveryTotalFee || 0) +
        ((appo.payment && appo.payment.medicalExpense) || 0)
      );
    } else if (
      deliveryDisplayFee.deliveryTotalFee === null &&
      appo.payment &&
      appo.payment.medicalExpense === null
    ) {
      return null;
    }
    return 0;
  };

  return <TblCellTotalFee>{feeText(getFee())}</TblCellTotalFee>;
};

export default HospitalPaymentRowTotalFee;
