import { MediaDeviceError } from "../enums";

const MediaDeviceStatus = {
  accepted: "accepted",
  blocked: "blocked",
  confirm: "confirm",
  notfound: "notfound",
  error: "",
};
const MediaDeviceKind = {
  videoinput: "videoinput",
  audioinput: "audioinput",
  audiooutput: "audiooutput",
};
const MediaDeviceMessage = {
  notSupport: "not support media device",
  deviceError: "error catch from get media device Info",
};

/**
 * If you specify true for executeGetUserMedia, media device permission dialog will be displayed.
 * If you specify false for executeGetUserMedia, media device blooked cannot be determined.
 * Once blooked dialog, afeter not open dialog.
 * The behavior does not change significantly for each browser.
 * @param {*} executeGetUserMedia open media device permission dialog
 */
const GetMediaDeviceStatus = async (executeGetUserMedia) => {
  let mediaDeviceStatus = MediaDeviceStatus.confirm;
  let mediaDeviceError = MediaDeviceStatus.error;
  if (executeGetUserMedia) {
    await navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then(function (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      })
      .catch(function (error) {
        console.error("GetMediaDeviceStatus: getUserMedia error:", error);
        mediaDeviceError = error.name;
        mediaDeviceStatus = MediaDeviceStatus.blocked;
      });
    if (mediaDeviceStatus !== MediaDeviceStatus.confirm) {
      return { mediaDeviceStatus: mediaDeviceStatus, mediaDeviceError };
    }
  }
  await navigator.mediaDevices
    .enumerateDevices()
    .then(function (devices) {
      if (devices.length === 0) {
        mediaDeviceStatus = MediaDeviceStatus.notfound;
        console.log("GetMediaDeviceStatus: enumerateDevices is notfound");
      }
      const acceptedMediaArray = devices
        .filter((device) => device.deviceId)
        .map((device) => device.kind);
      if (
        acceptedMediaArray.includes(MediaDeviceKind.audioinput) &&
        acceptedMediaArray.includes(MediaDeviceKind.videoinput)
      ) {
        mediaDeviceStatus = MediaDeviceStatus.accepted;
      }
    })
    .catch(function (error) {
      console.error("GetMediaDeviceStatus: enumerateDevices error:", error);
    });
  if (mediaDeviceStatus !== MediaDeviceStatus.accepted) {
    mediaDeviceError = MediaDeviceError.OtherError;
    console.log("GetMediaDeviceStatus: mediaDevice is not accepted");
  }
  return { mediaDeviceStatus: mediaDeviceStatus, mediaDeviceError };
};

/**
 * Get Media Device Info.
 * @returns {Promise} media device info
 */
const GetMediaDeviceInfo = async () => {
  return new Promise((resolve, reject) => {
    if (
      navigator &&
      navigator.mediaDevices &&
      navigator.mediaDevices.enumerateDevices
    ) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          resolve(
            devices.reduce((devicesInfo, device) => {
              devicesInfo = devicesInfo.concat(device.kind);
              devicesInfo = devicesInfo.concat(":");
              devicesInfo = devicesInfo.concat("deviceId=");
              devicesInfo = devicesInfo.concat(device.deviceId);
              devicesInfo = devicesInfo.concat(",");
              devicesInfo = devicesInfo.concat("groupId=");
              devicesInfo = devicesInfo.concat(device.groupId);
              devicesInfo = devicesInfo.concat(",");
              devicesInfo = devicesInfo.concat("label=");
              devicesInfo = devicesInfo.concat(device.label);
              devicesInfo = devicesInfo.concat("\n");
              return devicesInfo;
            }, "")
          );
        })
        .catch((error) => {
          console.error(error);
          reject(MediaDeviceMessage.deviceError);
        });
    } else {
      reject(MediaDeviceMessage.notSupport);
    }
  });
};

export {
  GetMediaDeviceStatus,
  GetMediaDeviceInfo,
  MediaDeviceStatus,
  MediaDeviceKind,
};
