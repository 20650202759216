import gql from "graphql-tag";

export const GetAppointmentRequiredInputFields = gql`
  query GetAppointmentRequiredInputFields($patientId: ID!, $createTime: ID!) {
    appointment(patientId: $patientId, createTime: $createTime) {
      requireMedicalExpense
      requireDrugName
      requireDrugQuantity
    }
  }
`;