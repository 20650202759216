import gql from "graphql-tag";

export const GetInterviewSheetAnswer = gql`
  query GetInterviewSheetAnswer(
    $patientId: String!
    $createTime: String!
    $medicalDoctorId: String
  ) {
    interviewSheetAnswer(
      patientId: $patientId
      createTime: $createTime
      doctorId: $medicalDoctorId
    ) {
      patientId
      createTime
      hospitalId
      interviewSheetId
      interviewSheetVersion
      interviewAnswerAt
      index_doctorId
      interviewAnswers {
        order
        idNotation
        interviewSheetItemId
        interviewSheetItemVersion
        requiredAnswer
        interviewChoiceNos
        freeText
        interviewText
        interviewType
        interviewChoices {
          no
          text
        }
        freeTextPlaceholder
      }
    }
  }
`;
