import gql from "graphql-tag";

export const RequestDeliveryProcess = gql`
  mutation RequestDeliveryProcess(
    $patientId: String!
    $createTime: String!
    $deliverySelectedOption: Int!
    $deliveryFee: Int
    $deliveryProductName: String
    $pickUpDate: String
    $pickUpTimeSpan: String
    $comment: String
    $medicalDoctorId: String!
  ) {
    requestDeliveryProcess(
      patientId: $patientId
      createTime: $createTime
      deliverySelectedOption: $deliverySelectedOption
      deliveryFee: $deliveryFee
      deliveryProductName: $deliveryProductName
      pickUpDate: $pickUpDate
      pickUpTimeSpan: $pickUpTimeSpan
      comment: $comment
      doctorId: $medicalDoctorId
    ) {
      succeed
    }
  }
`;
