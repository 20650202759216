import React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { Subtitle } from '../../molecules/title/Subtitle';
import { DocumentItem } from './DocumentItem';

const Wrapper = styled.div`
  margin-top: 31px;
  :first-child {
    margin-top: 0;
  }
`;

const OverflowX = styled.div`
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    scrollbar-arrow-color: #dce0e0;
    background: #ffffff;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #999999;
    height: 50px;
  }
`;

const TableFullWidth = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.thead`
`;

const TableRow = styled.tr`
  border-bottom: 2px solid ${p => p.theme.color.secondary};
`;

const TableHeadRow = styled.th`
  text-align: left;
  font-size: 11px;
  color: #808080;
  padding: 0 10px;
`;

const TableHeadRowName = styled(TableHeadRow)`
`;

const TableHeadRowUpdateDatetime = styled(TableHeadRow)`
  width: 140px;
`;

const TableHeadRowDownload = styled(TableHeadRow)`
  width: 140px;
`;

const TableBody = styled.tbody`
`;

export const DocumentListInner = ({
  documents,
  title,
  category,
}) => {
  const listDocuments = (category) => documents
    .filter((document) => document.category === category)
    .map((document) =>
      <DocumentItem document={document} key={document.id} />
  );

  return (
    <React.Fragment>
      <Wrapper>
        <Subtitle title={title} />
        {/* リリース時の横幅最大値で調整 */}
        <MediaQuery query="(max-width: 1400px)">
          <OverflowX>
            <TableFullWidth>
              <TableHead>
                <TableRow>
                  <TableHeadRowName>名前</TableHeadRowName>
                  <TableHeadRowUpdateDatetime>更新日</TableHeadRowUpdateDatetime>
                  <TableHeadRowDownload>ダウンロード</TableHeadRowDownload>
                </TableRow>
              </TableHead>
              <TableBody>
                {listDocuments(category)}
              </TableBody>
            </TableFullWidth>
          </OverflowX>
        </MediaQuery>
        <MediaQuery query="(min-width: 1401px)">
          <TableFullWidth>
            <TableHead>
              <TableRow>
                <TableHeadRowName>名前</TableHeadRowName>
                <TableHeadRowUpdateDatetime>更新日</TableHeadRowUpdateDatetime>
                <TableHeadRowDownload>ダウンロード</TableHeadRowDownload>
              </TableRow>
            </TableHead>
            <TableBody>
              {listDocuments(category)}
            </TableBody>
          </TableFullWidth>
        </MediaQuery>
      </Wrapper>
    </React.Fragment>
  );
};