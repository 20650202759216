import React from 'react';
import { Helmet } from 'react-helmet';

export const TitleTag = ({ title='' }) => {
  return (
    <Helmet
      title={title}
    />
  )
};
