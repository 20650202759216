import styled from "styled-components";

export default styled.div`
  display: inline;
  padding: 3px 4px;
  text-align: center;
  font-size: 11px;
  color: #000;
  border: solid 1px #27cdcb;
  border-radius: 1px;
`;
