import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";

const InterviewAreaResource = japaneseList.Pages.Medical.InterviewArea;

const FontAllNull = styled.div`
  color: ${(p) => p.theme.font.color.gray};
`;

const Title = styled.div`
  font-weight: bold;
`;

const ItemHr = styled.hr`
  width: 100%;
  background-color: ${(p) => p.theme.color.secondary};
  border: none;
  height: 1px;
  margin: 10px 0px;
`;

const getInterviewSheetAnswerDetail = (contents) => {
  const { companyName, content, interviewSheetAnswer } = contents;
  const QAs = [];
  let isAllNull = false;

  if (!companyName && !content && !interviewSheetAnswer) {
    isAllNull = true;
    return { interviewAnswers: QAs, isAllNull };
  }

  if (companyName) {
    QAs.push({
      interviewText: InterviewAreaResource.Typography.j003,
      answerStr: companyName,
    });
  }
  if (content) {
    QAs.push({
      interviewText: InterviewAreaResource.Typography.j002,
      answerStr: content,
    });
  }
  if (interviewSheetAnswer) {
    const interviewAnswers = (interviewSheetAnswer.interviewAnswers || [])
      .slice()
      .sort((a, b) => {
        return a.order - b.order;
      });
    interviewAnswers.forEach((element) => {
      let answerStr = "";
      let freeText = "";

      if (element.interviewChoiceNos.length > 0) {
        element.interviewChoiceNos.map((choiceNo, index) => {
          const choiceItem = element.interviewChoices.find(
            (choice) => choice.no === choiceNo
          );
          answerStr += choiceItem.text;
          if (index !== element.interviewChoiceNos.length - 1)
            answerStr += "、";
          return choiceNo;
        });
        if (element.freeText) {
          answerStr += "/";
          freeText = element.freeText;
        }
      } else {
        freeText = element.freeText
          ? element.freeText
          : InterviewAreaResource.Typography.j006;
      }

      QAs.push({
        interviewText: element.interviewText,
        answerStr: answerStr,
        freeText: freeText,
      });
    });
  }
  return { interviewAnswers: QAs, isAllNull };
};

export const InterviewSheetAnswerDetail = ({ contents }) => {
  const { interviewAnswers, isAllNull } = getInterviewSheetAnswerDetail(
    contents
  );

  return (
    <React.Fragment>
      {isAllNull ? (
        <FontAllNull>{InterviewAreaResource.Typography.j004}</FontAllNull>
      ) : (
        interviewAnswers.map((item, index) => (
          <div key={index}>
            <Title>{item.interviewText}</Title>
            {item.answerStr}
            {item.freeText && <div>{item.freeText}</div>}
            {index < interviewAnswers.length - 1 && <ItemHr />}
          </div>
        ))
      )}
    </React.Fragment>
  );
};
