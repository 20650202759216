import { Component } from "react";
import styled from "styled-components";

import { withStyles, WithStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import FontP from "../../atoms/fonts/FontP";
import { japaneseList } from "../../../Resources/japaneseList";
import { CONSENT_STATUS } from "../../../Utils/const";
import { formatDateTime } from "../../../Utils/DateUtil";

const Resources =
  japaneseList.Components.Appointment.AppointmentList.ConsentRegistrationDialog;
const styles = () => ({
  dialogTitle: {
    padding: "24px",
    textAlign: "left" as const,
    fontWeight: "bold" as const,
  },
  dialogContent: {
    padding: "0 25px 30px 25px",
  },
  dialogActions: {
    margin: "15px 20px 20px 20px",
  },
  button: {
    width: "120px",
    color: "#ffffff",
    boxShadow: "0px 2px 0px #505050",
    backgroundColor: "#808080",
    opacity: "1",
    "&:hover": {
      backgroundColor: "#a6a6a6",
    },
  },
  hr: {
    margin: "0px 0px 0px",
    maxWidth: "100%",
    height: "0.8px",
    backgroundColor: "#cccccc",
    border: 0,
  },
});
const CustomFontP: any = styled(FontP)`
  margin-bottom: 20px;
`;

interface ConsentRegistration {
  consentStatus: "unregistered" | "registering" | "registered";
  consentModificationAttemptedAt?: string;
}
interface Props extends WithStyles<typeof styles> {
  isOpen: boolean;
  handleClose: () => void;
  consentRegistration: ConsentRegistration;
}

class DialogConsentRegistration extends Component<Props> {
  render() {
    const { classes, isOpen, handleClose } = this.props;
    const { consentStatus, consentModificationAttemptedAt } =
      this.props.consentRegistration;
    const showConsentModificationAttemptedAt =
      consentStatus === CONSENT_STATUS.REGISTERED &&
      Boolean(consentModificationAttemptedAt);

    return (
      <Dialog open={isOpen} maxWidth={"sm"}>
        <DialogTitle className={classes.dialogTitle}>
          {`${Resources.title.text}${Resources.title[consentStatus]}`}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <CustomFontP size="s">
            {consentStatus === CONSENT_STATUS.UNREGISTERED &&
              Resources.description.unregistered}
            {consentStatus === CONSENT_STATUS.REGISTERING &&
              Resources.description.registering}
            {consentStatus === CONSENT_STATUS.REGISTERED &&
              Resources.description.registered.common}
          </CustomFontP>
          {showConsentModificationAttemptedAt && (
            <CustomFontP size="s">
              {`${formatDateTime(
                new Date(consentModificationAttemptedAt ?? "")
              )}${
                Resources.description.registered.consentModificationAttempted
              }`}
            </CustomFontP>
          )}
        </DialogContent>
        <hr className={classes.hr} />
        <DialogActions className={classes.dialogActions}>
          <Button
            id="consent-dialog-close-button"
            className={classes.button}
            onClick={handleClose}
          >
            {Resources.close}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogConsentRegistration);
