import React, { Component } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import { Hub } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";
import { japaneseList } from "../../../Resources/japaneseList";

const Resource = japaneseList.Components.MessageBox;
const styles = theme => ({
  snackbar: {
    position: "fixed"
  },
  snackbarContent: {
    width: 360
  }
});

class MessageBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      message: null
    };

    Hub.listen("msg", capsule => {
      const { payload } = capsule;
      const { event, data } = payload;
      switch (event) {
        case "open":
          this.setState({
            open: true,
            message: data.message
          });
          break;
        case "close":
          this.setState({
            open: false,
            message: null
          });
          break;
        default:
          break;
      }}, "MessageBox");
  }

  componentWillUnmount() {
    Hub.remove("msg");
  }

  close = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <Snackbar
        open={this.state.open}
        autoHideDuration={15000}
        onClose={this.close}
        ContentProps={{
          "aria-describedby": "snackbar-fab-message-id",
          className: classes.snackbarContent
        }}
        message={this.state.message}
        action={
          <Button color="inherit" size="small" onClick={this.close}>
            {Resource.render.Snackbar.Button.j001}
          </Button>
        }
        className={classes.snackbar}
      />
    );
  }
}

export default withStyles(styles)(MessageBox);
