// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles, DialogContent } from "@material-ui/core";

// personal modules
// --------------------------------------------------
import { japaneseList } from "../../../../Resources/japaneseList";

const Resources = japaneseList.Components.Payment.NoDeliveryDialog;

// style
// --------------------------------------------------
const styles = {
  noteContainer: {
    gridTemplateColumns: "15px auto",
  },
  noteTittle: {
    fontSize: "16px",
    fontWeight: "bold",
    gridColumn: "1 / span 2",
  },
  noteContent: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#808080",
  },
};

class NoDeliveryDetail extends Component {
  render() {
    const { classes } = this.props;
    return (
      <DialogContent>
        <div className={classes.noteContainer}>
          <div className={classes.noteTittle}>{Resources.title}</div>
          <div className={classes.noteContent}>{Resources.note.content}</div>
        </div>
      </DialogContent>
    );
  }
}

export default withStyles(styles)(NoDeliveryDetail);
