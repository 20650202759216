import {
  areIntervalsOverlapping,
  compareAsc,
  differenceInMinutes,
  getMinutes,
  format,
  isBefore,
  isAfter,
  parseISO,
} from "date-fns";
import jaLocale from "date-fns/locale/ja";

import * as Sentry from "@sentry/browser";

import {
  client,
  CreateAppointment,
  GetAppointmentTimeSetting,
  GetCapacitySetting,
  GetDoctor,
  IsFinishRepeatedlySlots,
  RegisterSlot,
  RegisterRepeatedlyAppointmentSlots,
} from "../graphql";

import { japaneseList } from "../Resources/japaneseList";

const SlotResource = japaneseList.Components.Appointment.Stepper.Slot;
const AppointmentResource =
  japaneseList.Components.Appointment.Stepper.Appointment;
const ConfirmResource =
  japaneseList.Components.Appointment.DialogConfirmRegistration.confirm;

export const slotValidationErrorMessages = {
  invalidTime: SlotResource.handleEndTimeChange.j004,
  onlineTime: SlotResource.handleEndTimeChange.j002,
  offlineTime: SlotResource.handleEndTimeChange.j003,
  offlineCapacity: SlotResource.create.j011,
  isPastTime: SlotResource.create.j001,
  notMultipleOf15: SlotResource.create.j005,
  invalidRepetitionStartTime: SlotResource.create.j012,
  invalidRepetitionByDay: SlotResource.create.j013,
  overLappingOtherCount: SlotResource.create.j014,
  overlappingSlot: SlotResource.create.j007,
  overlappingAppointment: SlotResource.create.j008,
  default: SlotResource.create.j010,
};

export const appointmentValidationErrorMessages = {
  notRegistered: AppointmentResource.create.j001,
  notSetAddress: AppointmentResource.create.j002,
  invalidDate: AppointmentResource.create.j003,
  overlappingSlot: AppointmentResource.create.j005,
  overlappingAppointment: AppointmentResource.create.j006,
  invalidStartEndDate: AppointmentResource.create.j016,
  default: AppointmentResource.create.j009,
};

export const confirmMessages = {
  overlappingSlot: ConfirmResource.j001,
  overlappingAppointment: ConfirmResource.j002,
};

export const registrationSlotApiErrorMessages = {
  E01: SlotResource.create.j003,
  E02: SlotResource.create.j004,
  E03: SlotResource.create.j005,
  E04: SlotResource.create.j006,
  E05: SlotResource.create.j007,
  E06: SlotResource.create.j008,
  E11: SlotResource.create.j011,
  E15: SlotResource.create.j012,
  E17: SlotResource.create.j013,
  E18: SlotResource.create.j015,
  E19: SlotResource.create.j016,
  default: SlotResource.create.j009,
};

export const registrationSlotApiConfirmMessages = {
  E05: ConfirmResource.j001,
  E06: ConfirmResource.j002,
};

export const registrationAppointmentApiErrorMessages = {
  E01: AppointmentResource.create.j004,
  E02: AppointmentResource.create.j005,
  E03: AppointmentResource.create.j006,
  E04: AppointmentResource.create.j007,
  E05: AppointmentResource.create.j010,
  E06: AppointmentResource.create.j011,
  E07: AppointmentResource.create.j012,
  E08: AppointmentResource.create.j013,
  E09: AppointmentResource.create.j014,
  E10: AppointmentResource.create.j011,
  E102: AppointmentResource.create.j015,
  E103: AppointmentResource.create.j015,
  default: AppointmentResource.create.j008,
};

const getErrorTimeMessage = (menuMethod) =>
  menuMethod === "offline"
    ? slotValidationErrorMessages.offlineTime
    : slotValidationErrorMessages.onlineTime;

const getOverlappingMessage = (from, to, overlappingCount = 0) => {
  const parsedFrom = from instanceof Date ? from : parseISO(from);
  const parsedTo = to instanceof Date ? to : parseISO(to);
  const countMessage = overlappingCount
    ? slotValidationErrorMessages.overLappingOtherCount.replace(
        /#overlappingCount/,
        overlappingCount
      )
    : "";
  const formatedFrom = format(parsedFrom, "yyyy/MM/dd (iii)", {
    locale: jaLocale,
  });
  const formatedFromTime = format(parsedFrom, "HH:mm");
  const formatedToTime = format(parsedTo, "HH:mm");

  return `${formatedFrom} ${formatedFromTime} ～ ${formatedToTime}${countMessage}`;
};

/**
 * get MaxCapacity data
 * @return { maxCapacity?: number, errors?: [string] }
 */
export const getSlotMaxCapacity = async () => {
  const responseValue = { maxCapacity: null, errors: null };

  try {
    const { data, errors } = await client.query({
      query: GetCapacitySetting,
    });

    return errors
      ? { ...responseValue, errors: [errors] }
      : { ...responseValue, maxCapacity: data.appointmentSetting.maxCapacity };
  } catch (error) {
    console.log("Errors Get Capacity", error);
    return { ...responseValue, errors: [slotValidationErrorMessages.default] };
  }
};

/**
 * get TimeFrameSetting data
 * @return { frameLengthes?: { [string]: number }, errors?: [string] }
 */
export const getSlotTimeFrameLength = async () => {
  const responseValue = { frameLengthes: null, errors: null };

  try {
    const { data, errors } = await client.query({
      query: GetAppointmentTimeSetting,
    });
    return errors
      ? { ...responseValue, errors: [errors] }
      : {
          ...responseValue,
          frameLengthes: {
            onlineMaximumTimeframe:
              data.appointmentSetting.onlineMaximumTimeframe,
            onlineMinimumTimeframe:
              data.appointmentSetting.onlineMinimumTimeframe,
            offlineMaximumTimeframe:
              data.appointmentSetting.offlineMaximumTimeframe,
            offlineMinimumTimeframe:
              data.appointmentSetting.offlineMinimumTimeframe,
          },
        };
  } catch (error) {
    console.log("Errors Get Time Length", error);
    return { ...responseValue, errors: [slotValidationErrorMessages.default] };
  }
};

/**
 * validation
 * @return { errors: [string], confirms: [string] }
 */
export const getSlotValidationResult = ({
  menuMethod,
  startDateTime,
  endDateTime,
  maximumTimeframe,
  minimumTimeframe,
  capacity,
  maxCapacity,
  repetition,
  otherEvents = [],
}) => {
  const errors = [];
  const confirms = [];
  const diffTimeInMinute = differenceInMinutes(endDateTime, startDateTime);
  if (getMinutes(startDateTime) % 15 !== 0) {
    errors.push(slotValidationErrorMessages.notMultipleOf15);
  }
  if (diffTimeInMinute < minimumTimeframe && diffTimeInMinute > 0) {
    errors.push(getErrorTimeMessage(menuMethod));
  }
  if (diffTimeInMinute > maximumTimeframe) {
    errors.push(getErrorTimeMessage(menuMethod));
  }
  if (isBefore(startDateTime, new Date())) {
    errors.push(slotValidationErrorMessages.isPastTime);
  }
  if (repetition && repetition.isCustom && repetition.byDay.length === 0) {
    errors.push(slotValidationErrorMessages.invalidRepetitionByDay);
  }
  if (
    repetition &&
    repetition.repetitionEndDate &&
    isBefore(parseISO(repetition.repetitionEndDate), startDateTime)
  ) {
    errors.push(slotValidationErrorMessages.invalidRepetitionStartTime);
  }
  if (capacity > maxCapacity) {
    errors.push(slotValidationErrorMessages.offlineCapacity);
  }
  if (!isAfter(endDateTime, startDateTime)) {
    errors.push(slotValidationErrorMessages.invalidTime);
  } else {
    otherEvents.forEach((event) => {
      if (
        areIntervalsOverlapping(
          { start: startDateTime, end: endDateTime },
          { start: event.start, end: event.end }
        )
      ) {
        if (event.appointment && menuMethod !== "offline") {
          if (
            differenceInMinutes(startDateTime, event.start) < 0 ||
            differenceInMinutes(endDateTime, event.end) > 0
          ) {
            // case of slot repetition registration, needed avoid double confirm
            if (!repetition) {
              if (confirms.length === 0) {
                const { appointment, menu } = event;
                const { patient } = appointment;
                confirms.push({
                  message: confirmMessages.overlappingAppointment,
                  info: getOverlappingMessage(event.start, event.end),
                  existing: { appointment, patient, menu },
                  overlappingCount: 0,
                });
              } else {
                confirms[0].overlappingCount++;
              }
            }
          } else {
            errors.push(slotValidationErrorMessages.overlappingAppointment);
            errors.push(getOverlappingMessage(event.start, event.end));
          }
        }
        if (event.slot) {
          if (
            menuMethod === event.slot.menu.medicalMethod &&
            (menuMethod !== "offline" ||
              differenceInMinutes(startDateTime, event.start) !== 0 ||
              differenceInMinutes(endDateTime, event.end) !== 0)
          ) {
            errors.push(slotValidationErrorMessages.overlappingSlot);
            errors.push(getOverlappingMessage(event.start, event.end));
          }
        }
      }
    });
  }

  return { errors, confirms };
};

/**
 * registration Slot
 * @param stotParam
 * @return { result: "succeed" | "failed", errors: [string] }
 */
export const registerSlot = async ({
  from,
  to,
  capacity,
  menuMethod,
  repetition,
  suspendsOnConflicts,
  medicalDoctorId,
}) => {
  const payload = {
    from,
    to,
    capacity,
    menuMethod,
    repetition,
    medicalDoctorId,
  };

  const { data, errors: resultErrors } = await client.mutate({
    mutation: repetition ? RegisterRepeatedlyAppointmentSlots : RegisterSlot,
    variables: repetition ? { ...payload, suspendsOnConflicts } : payload,
  });

  const [errors, confirms] = [[], []];

  if (resultErrors && resultErrors.length > 0) {
    resultErrors.forEach((e) => {
      if (e.errorType in registrationSlotApiErrorMessages) {
        errors.push(registrationSlotApiErrorMessages[e.errorType]);
      }
      if (["E05", "E06"].includes(e.errorType) && e.errorInfo) {
        const {
          existing: { from, to },
          overlappingCount,
        } = e.errorInfo;
        errors.push(getOverlappingMessage(from, to, overlappingCount - 1));
      }
    });

    return { result: "failed", errors, confirms };
  }

  if (repetition) {
    if (data && data.registerRepeatedlyAppointmentSlots) {
      const slotRepetitionId = data.registerRepeatedlyAppointmentSlots;
      const pollingResult = await pollingRegistrationSlotRepetition(
        slotRepetitionId
      );
      pollingResult.errors.forEach((e) => errors.push(e));
      pollingResult.confirms.forEach((c) => confirms.push(c));

      if (errors.length === 0 && confirms.length === 0) {
        return { result: "succeed", errors, confirms };
      }
    }
  } else {
    if (data && data.registerSlot) {
      return { result: "succeed", errors, confirms };
    }
  }

  if (errors.length > 0) {
    return { result: "failed", errors, confirms };
  } else if (confirms.length > 0) {
    return { result: "confirming", errors, confirms };
  }

  errors.push(registrationSlotApiErrorMessages.default);

  return { result: "failed", errors, confirms };
};

const pollingRegistrationSlotRepetition = async (slotRepetitionId) => {
  const sleep = (n) => new Promise((resolve) => setTimeout(resolve, n));

  while (true) {
    try {
      await sleep(1000);
      const [isFinish, result] = await isProcessingRegistrationSlotRepetition(
        slotRepetitionId
      );
      if (isFinish) return result;
    } catch (e) {
      throw e;
    }
  }
};

const isProcessingRegistrationSlotRepetition = async (slotRepetitionId) => {
  const [errors, confirms] = [[], []];

  const { data, errors: processingErrors } = await client.query({
    query: IsFinishRepeatedlySlots,
    variables: { slotRepetitionId },
  });

  if (processingErrors) {
    const { errorInfo, message } = processingErrors[0];
    if (errorInfo.batchStatus === "CREATE_ERROR") {
      if (["E05", "E06", "E18"].includes(errorInfo.code)) {
        const message =
          errorInfo.errorLevel === "confirm"
            ? registrationSlotApiConfirmMessages[errorInfo.code]
            : registrationSlotApiErrorMessages[errorInfo.code];
        const info = ["E05", "E06"].includes(errorInfo.code)
          ? getOverlappingMessage(
              errorInfo.existing.from,
              errorInfo.existing.to,
              errorInfo.overlappingCount - 1
            )
          : "";

        if (errorInfo.errorLevel === "confirm") {
          const confirm = { message, info, existing: errorInfo.existing };
          return [true, { errors, confirms: [confirm] }];
        } else {
          return [true, { errors: [message, info], confirms }];
        }
      }
    }
    throw new Error(message);
  } else if (data && data.isFinishRepeatedlySlots) {
    switch (data.isFinishRepeatedlySlots) {
      case "DONE":
        return [true, { errors, confirms }];
      case "RUNNING":
      case "CREATE_WAITING":
        return [false, { errors, confirms }];
      default:
        return [true, { errors, confirms }];
    }
  }
  throw new Error();
};

/**
 * get available appointment menus
 * @param hospital
 * @return [String]
 */
export const getAvailableAppointmentMenuNames = async (
  hospital,
  selectDoctor
) => {
  const availableMenus = hospital.menus.filter(
    (menu) => menu.createAppointmentByDoctor === true
  );
  const nullDoctorIdMenuList = availableMenus.filter(
    (menu) => menu.doctorId === null
  );
  const matchDoctorIdMenuList = availableMenus.filter(
    (menu) => menu.doctorId === selectDoctor.id
  );

  return [
    AppointmentResource.ListItem.ListItemPlaceholder.j001,
    ...nullDoctorIdMenuList,
    ...matchDoctorIdMenuList,
  ];
};

/**
 * validation
 * @return { errors: [string], confirms: [{ message: string, info: string }] }
 */
export const getAppointmentValidationResult = ({
  patient,
  from,
  to,
  hasCharge,
  organizationId,
  otherEvents = [],
}) => {
  const [errors, confirms] = [[], []];

  if (hasCharge) {
    const isRegistered = patient.cardStatus === "registered";

    // patient cregit card is not registered
    if (!isRegistered) {
      errors.push(appointmentValidationErrorMessages.notRegistered);
    }
    // patient address is not registered
    if (!organizationId && !patient.address) {
      errors.push(appointmentValidationErrorMessages.notSetAddress);
    }
  }
  // check date
  if (compareAsc(from, new Date()) <= 0) {
    errors.push(appointmentValidationErrorMessages.invalidDate);
  }
  if (!isAfter(to, from)) {
    errors.push(appointmentValidationErrorMessages.invalidStartEndDate);
  } else {
    otherEvents.forEach((event) => {
      if (
        areIntervalsOverlapping(
          { start: from, end: to },
          { start: event.start, end: event.end }
        )
      ) {
        if (event.slot) {
          if (event.slot.menu.medicalMethod !== "offline") {
            confirms.push({
              message: confirmMessages.overlappingSlot,
              info: getOverlappingMessage(event.start, event.end),
            });
          }
        } else {
          errors.push(
            appointmentValidationErrorMessages.overlappingAppointment
          );
          errors.push(getOverlappingMessage(event.start, event.end));
        }
      }
    });
  }

  return { errors, confirms };
};

/**
 * registration Appointment
 * @param stotParam
 * @return { result: "succeed" | "failed", errors: [string] }
 */
export const registerAppointment = async ({
  patientId,
  from,
  to,
  menu,
  medicalDoctorId,
}) => {
  const payload = {
    patientId,
    from,
    to,
    menu,
    medicalDoctorId,
  };

  try {
    const { errors } = await client.mutate({
      mutation: CreateAppointment,
      variables: payload,
    });

    if (errors) {
      const { errorType } = errors[0];
      const error = registrationAppointmentApiErrorMessages[errorType]
        ? registrationAppointmentApiErrorMessages[errorType]
        : registrationAppointmentApiErrorMessages.default;
      return { result: "failed", errors: [error] };
    }

    Sentry.captureMessage("appointment-create", Sentry.Severity.Log);

    return { result: "succeed", errors: [] };
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);

    return {
      result: "failed",
      errors: [
        `${appointmentValidationErrorMessages.default}(${error.message})`,
      ],
    };
  }
};
