import gql from "graphql-tag";

export const ListItem = gql`
  query ListItem(
    $start: String!
    $end: String!
    $status: [String!]
    $medicalDoctorId: String!
  ) {
    appointmentSlots(start: $start, end: $end, doctorId: $medicalDoctorId) {
      items {
        from
        to
        hospitalId
        capacity
        remaining
        reservedNumber
        menu {
          doctorId
          menuId
          medicalMethod
          medicalMenu
          displayName
          assignToDoctor
        }
        slotRepetition {
          slotRepetitionId
          isCustom
          frequency
          byDay
          interval
          endDate
          lastCreatedDate
        }
        doctor {
          doctorId
          familyName
          givenName
        }
      }
    }

    appointments(
      start: $start
      end: $end
      status: $status
      medicalDoctorId: $medicalDoctorId
    ) {
      items {
        from
        to
        hospitalId
        hasCharge
        fromOfSlot
        status
        patient {
          patientId
          familyName
          givenName
          familyNameKana
          givenNameKana
          phoneNumber
          birthdate
          gender
        }
        menu {
          menuId
          doctorId
          medicalMethod
          medicalMenu
          displayName
          description
          assignToDoctor
          supportsInsuranceTreatment
        }
        doctor {
          doctorId
          familyName
          givenName
        }
      }
    }
  }
`;
