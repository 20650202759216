export const UnsupportedDeviceList = {
  version: "1.0",
  lists: [
    {
      list: {
        name: "terminal",
        priority: 1,
        type: "black",
        isBlock: true,
        items: [],
      },
    },
    {
      list: {
        name: "browser",
        priority: 2,
        type: "white",
        isBlock: true,
        items: [
          {
            item: {
              os: { name: "Windows", version: null, condition: null },
              browser: { name: "Chrome", version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: null, condition: null },
              browser: { name: "Chrome", version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: null, condition: null },
              browser: {
                name: "Mobile Safari",
                version: null,
                condition: null,
              },
              device: { model: "iPad" },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: null, condition: null },
              browser: { name: "Safari", version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Windows", version: null, condition: null },
              browser: { name: "Edge", version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: null, condition: null },
              browser: {
                name: "Mobile Safari",
                version: null,
                condition: null,
              },
              device: { model: null },
              ua: null,
            },
          },
        ],
      },
    },
    {
      list: {
        name: "osVersion",
        priority: 3,
        type: "black",
        isBlock: true,
        items: [
          {
            item: {
              os: { name: "Windows", version: "9", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Windows", version: "me", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Windows", version: "2000", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Windows", version: "XP", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Windows", version: "Vista", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Windows", version: "7", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Windows", version: "8", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.0", condition: "all" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.0.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.1", condition: "all" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.1.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.2", condition: "all" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.2.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.3", condition: "all" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.3.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.4", condition: "all" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.4.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.5", condition: "all" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.5.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.6", condition: "all" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.6.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.7", condition: "all" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.7.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.8", condition: "all" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.8.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.9", condition: "all" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.9.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.10", condition: "all" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "Mac OS", version: "10.10.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: "1.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: "2.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: "3.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: "4.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: "5.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: "6.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: "7.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: "8.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: "9.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: "10.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: "11.", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: "12.0", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: "12.1", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: "iOS", version: "13.0", condition: "before" },
              browser: { name: null, version: null, condition: null },
              device: { model: "iPad" },
              ua: null,
            },
          },
        ],
      },
    },
    {
      list: {
        name: "browserVersion",
        priority: 4,
        type: "black",
        isBlock: false,
        items: [
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "1.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "2.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "3.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "4.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "5.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "6.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "7.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "8.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "9.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "10.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "11.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "12.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "13.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "14.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "15.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "16.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "17.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "18.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "19.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "20.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "21.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "22.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "23.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "24.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "25.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "26.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "27.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "28.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "29.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "30.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "31.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "32.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "33.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "34.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "35.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "36.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "37.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "38.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "39.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "40.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "41.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "42.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "43.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "44.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "45.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "46.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "47.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "48.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "49.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "50.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "51.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "52.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "53.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "54.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "55.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "56.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "57.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "58.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "59.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "60.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "61.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "62.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "63.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "64.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "65.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "66.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "67.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "68.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "69.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "70.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "71.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "72.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "73.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "74.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "75.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "76.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "77.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "78.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "79.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "80.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "81.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Chrome", version: "82.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: {
                name: "Mobile Safari",
                version: "1.",
                condition: "before",
              },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: {
                name: "Mobile Safari",
                version: "2.",
                condition: "before",
              },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: {
                name: "Mobile Safari",
                version: "3.",
                condition: "before",
              },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: {
                name: "Mobile Safari",
                version: "4.",
                condition: "before",
              },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: {
                name: "Mobile Safari",
                version: "5.",
                condition: "before",
              },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: {
                name: "Mobile Safari",
                version: "6.",
                condition: "before",
              },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: {
                name: "Mobile Safari",
                version: "7.",
                condition: "before",
              },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: {
                name: "Mobile Safari",
                version: "8.",
                condition: "before",
              },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: {
                name: "Mobile Safari",
                version: "9.",
                condition: "before",
              },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: {
                name: "Mobile Safari",
                version: "10.",
                condition: "before",
              },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: {
                name: "Mobile Safari",
                version: "11.",
                condition: "before",
              },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: {
                name: "Mobile Safari",
                version: "12.0",
                condition: "before",
              },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "79.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "80.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "81.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "82.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "83.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
        ],
      },
    },
    {
      list: {
        name: "browserVersionBlock",
        priority: 5,
        type: "black",
        isBlock: true,
        items: [
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "1.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "2.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "3.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "4.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "5.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "6.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "7.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "8.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "9.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "10.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "11.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "12.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "13.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "14.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "15.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "16.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "17.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "18.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "19.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "20.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "21.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "22.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "23.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "24.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "25.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "26.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "27.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "28.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "29.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "30.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "31.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "32.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "33.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "34.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "35.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "36.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "37.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "38.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "39.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "40.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "41.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "42.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "43.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "44.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "45.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "46.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "47.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "48.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "49.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "50.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "51.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "52.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "53.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "54.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "55.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "56.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "57.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "58.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "59.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "60.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "61.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "62.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "63.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "64.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "65.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "66.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "67.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "68.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "69.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "70.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "71.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "72.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "73.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "74.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "75.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "76.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "77.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
          {
            item: {
              os: { name: null, version: null, condition: null },
              browser: { name: "Edge", version: "78.", condition: "before" },
              device: { model: null },
              ua: null,
            },
          },
        ],
      },
    },
  ],
};
