import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IconError from "../../atoms/icons/IconError";

const styles = (theme) => ({
  dialogTitle: {
    padding: "25px 25px 30px 25px",
    color: theme.color.font,
    boxShadow: `0px 1px 0px ${theme.color.secondary}`,
    display: "flex",
    fontSize: "14px",
    width: "500px",
    height: "93px",
    alignItems: "center",
  },
  dialogActions: {
    padding: "15px 25px",
    margin: "0px",
  },
  buttonClose: {
    color: "white",
    width: "120px",
    height: "40px",
    background: `${theme.button.cancelUploadFile.backgroundColor} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
    borderRadius: "5px",
    opacity: "1",
    margin: 0,
    "&:hover": {
      backgroundColor: theme.button.cancelUploadFile.hoverColor,
    },
    fontWeight: "bold",
  },
});

const Space = styled.div`
  width: 13px;
`;

const DialogAccessFileError = (props) => {
  const { isOpen, title, cancelText, handleClose, classes } = props;

  return (
    <Dialog
      open={isOpen}
      maxWidth={"sm"}
      onClose={handleClose}
      disableBackdropClick={true}
    >
      <DialogTitle disableTypography={true} className={classes.dialogTitle}>
        <IconError />
        <Space />
        {title}
      </DialogTitle>
      <DialogActions className={classes.dialogActions}>
        <Button className={classes.buttonClose} onClick={handleClose}>
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DialogAccessFileError);
