import React from "react";
import styled from "styled-components";

const TextStyle = styled.a`
  text-align: center;
  font-weight: bold;
  margin-top: -5px;
  display: flex;
  justify-content: center;
  padding: 0px 5px;
  font-size: 14px;
  color: ${(p) => p.theme.color.link};
`;

const FileNameStyle = styled.span`
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExtensionStyle = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const ThumbnailItemText = (props) => {
  const { file, onClick } = props;
  return (
    <TextStyle onClick={onClick}>
      <FileNameStyle>{`${file.fileName}`}</FileNameStyle>
      <ExtensionStyle>{`.${file.extensionFile}`}</ExtensionStyle>
    </TextStyle>
  );
};

export default ThumbnailItemText;
