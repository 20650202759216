import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Divider,
  Grid,
  Button,
  DialogActions,
} from "@material-ui/core/";
import AccountImage from "../../../../images/account_circle-24px.svg";
import { format } from "date-fns";
import { japaneseList } from "../../../../Resources/japaneseList";
import FontSpan from "../../../atoms/fonts/FontSpan";
import FontPError from "../../../atoms/fonts/FontPError";
import {
  getAge,
  toHalfWidthNumbers,
  removeInvalidZeros,
} from "../../../../Utils/DisplayLogic";
import NumberFormat from "react-number-format";
import { prefectureCodes } from "../../../../Utils/PrefectureCode";
import { MEDICAL_METHOD, PAYMENT_METHOD } from "../../../../Utils/const";

const Resource = japaneseList.Components.Payment.FeeDeliveryDialog;
const deliveryFeeMax = 9999999;

const styles = (theme) => ({
  dialogTitle: {
    fontSize: "20px",
    lineHeight: "0px",
  },

  dialogActions: {
    padding: "14px 24px 16px 0px",
    margin: "0px",
  },

  buttonOk: {
    color: theme.button.ok.color,
    width: "120px",
    height: "40px",
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    borderRadius: "6px",
    opacity: "1",
    margin: "0px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    fontWeight: "bold",
  },

  buttonCancel: {
    backgroundColor: "#808080",
    color: "white",
    width: "120px",
    height: "40px",
    background: "#808080 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 0px #505050",
    borderRadius: "6px",
    opacity: "1",
    margin: "0px 20px 0px 0px",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
    fontWeight: "bold",
  },

  buttonDisable: {
    background: "#DDDDDD 0% 0% no-repeat padding-box",
    marginRight: "0px",
    pointerEvents: "none",
    color: "white",
    width: "120px",
    height: "40px",
    borderRadius: "6px",
    opacity: "1",
    margin: "0px",
    "&:hover": {
      background: "#DDDDDD 0% 0% no-repeat padding-box",
    },
    fontWeight: "bold",
  },

  tableStyle: {
    borderCollapse: "collapse",
    width: "310px",
    tableLayout: "auto",
  },
  thBirthDay: {
    width: "98px",
  },
  thAge: {
    width: "26px",
  },
  thGender: {
    width: "48px",
  },
  thPhonenumber: {
    width: "120px",
  },

  thStyle: {
    textAlign: "center",
    borderTop: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    backgroundColor: "#eee",
    fontSize: "12px",
    color: "#808080",
  },

  tdStyle: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    color: "#393939",
    whiteSpace: "nowrap",
  },

  tdStyleGenderMale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },

  tdStyleGenderFemale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },

  containerTable: {
    display: "flex",
    boxSizing: "border-box",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "space-between",
    margin: "8px 0 0 0",
  },

  containerBorder: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
    marginTop: "20px",
  },

  container: {
    display: "grid",
    gridTemplateColumns: "80px auto",
    gridGap: "20px",
    borderTop: "#ccc solid 1px",
  },

  titleItem: {
    backgroundColor: "#eee",
    padding: "8px 10px 8px 10px",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    color: "#808080",
    fontWeight: "bold",
    textAlign: "left",
    whiteSpace: "pre-wrap",
  },

  contentItem: {
    padding: "10px 20px 10px 0px",
    fontSize: "14px",
    color: "#393939",
    textAlign: "left",
    marginTop: "auto",
    marginBottom: "auto",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },

  contentDeliveryFeeGroup: {
    display: "flex",
    padding: "15px 0",
    alignItems: "center",
    gap: "10px",
  },

  contentDeliveryFeeSubGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  contentDeriveryFeeItem: {
    color: "#393939",
    textAlign: "left",
    marginTop: "auto",
    marginBottom: "auto",
  },

  contentDescriptionBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "2px",
  },

  contentDeliveryFeeDescriptionItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#808080",
    fontSize: "11px",
    whiteSpace: "pre-wrap",
    paddingTop: "15px",
  },
  contentDeliveryArrangementFeeDescriptionItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#808080",
    fontSize: "11px",
    whiteSpace: "pre-wrap",
  },

  contentDeliveryArrangementFeeGroup: {
    display: "flex",
    padding: "15px 0",
    gap: "10px",
  },

  contentDeriveryArrangementFeeItem: {
    display: "flex",
    width: "58px",
    flexDirection: "column",
    fontSize: "14px",
  },

  maxHeightWidthDialog: {
    maxHeight: "96%",
    maxWidth: "810px",
  },
  nameStyle: {
    fontWeight: "bold",
    fontSize: "16px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  nameKanaStyle: {
    fontSize: "12px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  displayValidateError: {
    margin: "auto",
    color: "#EF5E5E",
    fontWeight: "bold",
  },
  inputFee: {
    background: theme.color.primaryVariant3 + " 0% 0% no-repeat padding-box",
  },
});

const InputFee = styled.input`
  background-color: #eee;
  width: 194px;
  height: 34px;
  padding: 5px 10px;
  font-family: inherit;
  border: 1px solid #dddddd;
  font-size: 14px;
  -webkit-appearance: none;
  border-radius: 3px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #999999;
  }
  color: #393939;
  margin-right: 7px;
  text-align: right;
`;

class OtherDeliveryDialogInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      now: null,
      status: null,
      pickUpDateCandidates: [],
      pickUpTimeSpanCandidates: [],
      enableSubmitButton: false,
      checkCommentInput: false,
      checkDeliveryFeeLength: true,
      isFocusInput: false,
    };
  }

  handleEnableSubmitButton = () => {
    const { deliveryFee } = this.props.requestDelivery;
    const deliveryFeeNumbers = toHalfWidthNumbers(deliveryFee);
    this.setState({ enableSubmitButton: !!deliveryFeeNumbers });
  };

  handleChangeOtherDelivery = (e) => {
    this.props.handleChangeDeliveryState(
      { deliveryFee: e.target.value },
      this.handleEnableSubmitButton
    );
  };

  formatDeliveryFee = (value) => {
    return value && value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  handleFocusDeliveryFee = () => {
    this.setState({
      isFocusInput: true,
    });
  };

  handleBlurDeliveryFee = (e) => {
    const deliveryFeeNumbers = toHalfWidthNumbers(e.target.value);
    const formatValue = removeInvalidZeros(deliveryFeeNumbers);
    this.setState({
      isFocusInput: false,
    });
    this.props.handleChangeDeliveryState({ deliveryFee: formatValue });
  };

  handleClickConfirmButton = () => {
    if (this.validateDeliveryFee()) {
      this.props.handleConfirm();
    }
  };

  validateDeliveryFee = () => {
    if (this.props.requestDelivery.deliveryFee > deliveryFeeMax) {
      this.setState({
        checkDeliveryFeeLength: false,
        enableSubmitButton: false,
      });
      return false;
    }
    this.setState({
      checkDeliveryFeeLength: true,
    });
    return true;
  };

  render() {
    const { classes, appointment, open, requestDelivery } = this.props;
    const { patient, doctor, menu } = appointment;
    const { address } = patient;
    const { deliveryFee } = requestDelivery;
    const prefecture = prefectureCodes.find(
      (c) => address.prefectureCode === c.code
    ).jpName;
    const postalCode =
      address.postalCode.slice(0, 3) + "-" + address.postalCode.slice(3);

    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;
    return (
      <Dialog
        disableBackdropClick
        maxWidth={false}
        fullWidth={true}
        PaperProps={{ classes: { root: classes.maxHeightWidthDialog } }}
        onEnter={this.onEnter}
        open={open}
      >
        <DialogTitle>
          <FontSpan weight="bold" className={classes.dialogTitle}>
            {Resource.DialogTitle.create}
          </FontSpan>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography
            style={{
              margin: "20px 0px",
              whiteSpace: "pre-wrap",
              lineHeight: "24px",
            }}
          >
            {Resource.DialogContent.description}
          </Typography>
          {patient && (
            <Grid
              container
              wrap="nowrap"
              style={{ padding: "0px", margin: "0px" }}
            >
              <Grid item style={{ padding: "0px 10px 0px 0px" }}>
                <img src={AccountImage} alt={""} />
              </Grid>
              <Grid item style={{ padding: "0px" }}>
                <Typography className={classes.nameStyle}>
                  {patient.familyName} {patient.givenName}
                </Typography>
                <Typography className={classes.nameKanaStyle}>
                  {patient.familyNameKana} {patient.givenNameKana}
                </Typography>
              </Grid>
            </Grid>
          )}
          {patient && (
            <div className={classes.containerTable}>
              <table className={classes.tableStyle}>
                <thead>
                  <tr>
                    <th className={classes.thStyle + " " + classes.thBirthDay}>
                      {Resource.DialogContent.table.thBirthDay}
                    </th>
                    <th className={classes.thStyle + " " + classes.thAge}>
                      {Resource.DialogContent.table.thAge}
                    </th>
                    <th className={classes.thStyle + " " + classes.thGender}>
                      {Resource.DialogContent.table.thGender}
                    </th>
                    <th
                      className={classes.thStyle + " " + classes.thPhonenumber}
                    >
                      {Resource.DialogContent.table.thPhonenumber}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={classes.tdStyle}>
                      {format(
                        new Date(patient.birthdate),
                        Resource.Format.j001
                      )}
                    </td>
                    <td className={classes.tdStyle}>
                      {patient.birthdate ? getAge(patient.birthdate) : " "}
                    </td>
                    <td
                      className={
                        patient.gender &&
                        patient.gender ===
                          Resource.DialogContent.table.genderMale
                          ? classes.tdStyleGenderMale
                          : classes.tdStyleGenderFemale
                      }
                    >
                      {patient.gender}
                    </td>
                    <td className={classes.tdStyle}>
                      <NumberFormat
                        value={patient.phoneNumber}
                        displayType={"text"}
                        format="###-####-####"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <div className={classes.containerBorder}>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.undertaker}
              </div>
              <div className={classes.contentItem}>
                {menu && menu.medicalMethod === MEDICAL_METHOD.OFFLINE
                  ? Resource.DialogContent.containerBorder.offline
                  : `${doctor.familyName} ${doctor.givenName}`}
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.deliveryAddress}
              </div>
              <div className={classes.contentItem}>
                {postalCode
                  ? `${Resource.DialogContent.containerBorder.prefixImage}${postalCode}`
                  : ""}
                <br />
                {`${prefecture} ${address.addressCity} ${
                  address.addressStreetNumber
                } ${address.addressBuilding || ""}`}
              </div>
            </div>
            <React.Fragment>
              <div className={classes.container}>
                <div className={classes.titleItem}>
                  {Resource.DialogContent.containerBorder.deliveryFee}
                </div>
                <div className={classes.contentDeliveryFeeGroup}>
                  <div className={classes.contentDeliveryFeeSubGroup}>
                    <div className={classes.contentDeriveryFeeItem}>
                      {/* TODO: replace "true" by condition after PO design error type */}
                      {!true && (
                        <FontPError
                          size="s"
                          className={classes.displayValidateError}
                        >
                          {Resource.DialogContent.containerBorder.inputFeeError}
                        </FontPError>
                      )}
                      {!this.state.checkDeliveryFeeLength && (
                        <FontPError
                          size="s"
                          className={classes.displayValidateError}
                        >
                          {
                            Resource.DialogContent.containerBorder
                              .deliveryFeeLengthErrorMessage
                          }
                        </FontPError>
                      )}
                      <InputFee
                        type="tel"
                        className={classes.inputFee}
                        onChange={this.handleChangeOtherDelivery}
                        value={
                          this.state.isFocusInput
                            ? deliveryFee
                            : this.formatDeliveryFee(deliveryFee)
                        }
                        onBlur={this.handleBlurDeliveryFee}
                        onFocus={this.handleFocusDeliveryFee}
                      />
                      <span>
                        {Resource.DialogContent.containerBorder.deliveryFeeUnit}
                      </span>
                      {isBillingEnterprise && (
                        <span
                          style={{
                            marginLeft: "15px",
                            fontFamily: "Hiragino Kaku Gothic ProN",
                            fontSize: "11px",
                            textAlign: "left",
                            letterSpacing: "0px",
                            color: "#808080",
                            opacity: "1",
                          }}
                        >
                          {
                            Resource.DialogContent.containerBorder
                              .deliveryFeeNote
                          }
                        </span>
                      )}
                    </div>
                    {!isBillingEnterprise && (
                      <div className={classes.contentDescriptionBlock}>
                        <div
                          className={classes.contentDeliveryFeeDescriptionItem}
                        >
                          {
                            Resource.DialogContent.containerBorder
                              .deliveryFeeDescription
                          }
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {!isBillingEnterprise && (
                <div className={classes.container}>
                  <div className={classes.titleItem}>
                    {
                      Resource.DialogContent.containerBorder
                        .deliveryArrangementFee
                    }
                  </div>
                  <div className={classes.contentDeliveryArrangementFeeGroup}>
                    <div className={classes.contentDeriveryArrangementFeeItem}>
                      {
                        Resource.DialogContent.containerBorder
                          .deliveryArrangementFeeBefore
                      }
                    </div>
                    <div className={classes.contentDescriptionBlock}>
                      <div
                        className={
                          classes.contentDeliveryArrangementFeeDescriptionItem
                        }
                      >
                        {
                          Resource.DialogContent.containerBorder
                            .deliveryArrangementFeeDescription
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.buttonCancel}
            onClick={this.props.handleCancel}
          >
            {Resource.DialogActions.Button.cancelButton}
          </Button>
          {deliveryFee && this.state.enableSubmitButton ? (
            <Button
              className={classes.buttonOk}
              onClick={this.handleClickConfirmButton}
            >
              {Resource.DialogActions.Button.confirmButton}
            </Button>
          ) : (
            <Button className={classes.buttonDisable} onClick={() => {}}>
              {Resource.DialogActions.Button.confirmButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(OtherDeliveryDialogInput);
