/*
  beforeExam: 診察前
  examCompleted: 診察完了
  paymentCompleted: 会計完了
  canceledDoctor: キャンセル済み（医師側からのキャンセル）
  canceledPatientPreviousDay: キャンセル済み（患者側からの前日までのキャンセル）
  canceledPatientFromToday: キャンセル済み（患者側からの当日以降のキャンセル）
*/
module.exports.APPOINTMENT_STATUS = {
  BEFORE_EXAM: "beforeExam",
  EXAM_COMPLETED: "examCompleted",
  PAYMENT_COMPLETED: "paymentCompleted",
  CANCELED_DOCTOR: "canceledDoctor",
  CANCELED_PATIENT_PREVIOUS_DAY: "canceledPatientPreviousDay",
  CANCELED_PATIENT_FROM_DAY: "canceledPatientFromToday",
  UNAPPROVED: "unapproved",
  TENTATIVE_RESERVATION: "tentativeReservation",
  REJECTED: "rejected",
  CANCELED_BEFORE_APPROVING: "canceledBeforeApproving",
};

module.exports.MEDICAL_METHOD = { ONLINE: "online", OFFLINE: "offline" };

/*
1 - Industrial Physical Interview
2 - Welfare Program
*/
module.exports.PROGRAM_TYPE = {
  WELFARE_PROGRAM: "welfareProgram",
  INDUCSTRIAL_PHYSICAL_INTERVIEW: "industrialPhysicalInterview",
};

module.exports.DELIVERY_OPTION = {
  empty: -2,
  noDelivery: -1,
  yamato: 1,
  csvPost: 2,
  other: 3,
};

module.exports.DELIVERY_METHOD = {
  NONE: "none",
  DELIVERY: "delivery",
  GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME: "goGetWithReceivePrescriptionAtHome",
};

module.exports.FACILITY_TYPE = {
  HOSPITAL: "hospital",
  PHARMACY: "pharmacy",
};

/**
 *  Settlement Errorcode
 */
module.exports.SETTLEMENT_ERROR = {
  DELIVERY_MAINTENANCE: "E04",
  DELIVERY_PAST: "E12",
  DELIVERY_PATIENT: "E13",
  DELIVERY_OLD: "E14",
  CREDIT_CARD: "E15",
  CARD_SYSTEM: "E16",
  UNEXPECTED_SYSTEM: "E17",
  DELIVERY_SYSTEM: "E18",
  INVALID_AMOUNT: "E19",
  FAILED_CANCEL: "E20",
};

module.exports.INTERVIEW_SHEET_ANSWER_STATUS = {
  NONE: "none",
  UNANSWERED: "unanswered",
  ANSWERED: "answered",
};

module.exports.MEDICAL_MENU = {
  EXAMINATIONWELFARE: "examinationWelfare",
  EXAMINATION: "examination",
  MEDICATION_INSTRUCTION: "medicationInstruction",
};

module.exports.OPERATION_TYPE = {
  CREATE: "create",
  APPROVE: "approve",
  UNAPPROVE: "unapprove",
  UPDATETIME: "updateTime",
  CANCEL: "cancel",
  UPDATESTATUS: "updateStatus",
  STARTVIDEOCHAT: "startVideoChat",
  RESTARTVIDEOCHAT: "restartVideoChat",
  ENDVIDEOCHAT: "endVideoChat",
  DOCTOR_LOW_NETWORK: "doctorLowNetwork",
  PATIENT_LOW_NETWORK: "patientLowNetwork",
  UPLOADATTACHMENT: "uploadAttachment",
  REMOVEATTACHMENT: "removeAttachment",
  SETTLE: "settle",
  CHAGEMEDICALEXPENSE: "chageMedicalExpense",
  ADMINISTRATOR: "administrator",
  CANCELPAYMENT: "cancelPayment",
};

module.exports.OPERATOR_TYPE = {
  PATIENT: "patient",
  DOCTOR: "doctor",
  SYSTEM: "system",
  ADMINISTRATOR: "administrator",
};

/*
1 - paid by patient
2 - paid by enterprise
3 - paid by old examinationWelfareProgram
*/
module.exports.PAYMENT_METHOD = {
  BILLING_PATIENT: "billingPatient",
  BILLING_ENTERPRISE: "billingEnterprise",
  ORGANIZATION_WELFARE_PROGRAM: "organizationWelfareProgram",
};

/*
-1: not visit
1: treatment complete
*/
module.exports.TREATMENT_COMPLETED_SELECTED_OPTION = {
  VISITED: 1,
  NOT_VISITED: -1,
};

/**
 *  the exceptions of MediaDevices.getUserMedia() method
 */
module.exports.MEDIA_DEVICE_ERROR = {
  NOT_FOUND_ERROR: "NotFoundError",
  NOT_ALLOWED_ERROR: "NotAllowedError",
  NOT_READABLE_ERROR: "NotReadableError",
  EMPTY: "",
};

module.exports.BILLING_CATEGORY_OPTION = {
  FIRST_VISIT: "firstVisit",
  REVISIT: "revisit",
  CONSULTATION: "consultation",
  OTHER: "other", // 2024-11-28よりnoVisitに移行、過去の診療歴用に残している
  NO_VISIT: "noVisit",
};

module.exports.BILLING_DRUG_QUANTITY_FIRST_VISIT_OPTION = [0, 1];
module.exports.BILLING_DRUG_QUANTITY_REVISIT_OPTION = [0, 1, 2, 3];

module.exports.PRESCRIPTION_DRUG_INPUT_METHOD = {
  FREE: "free",
  FIXED: "fixed",
  SELECT: "select",
  NONE: "none",
};

module.exports.HOSPITAL_TYPES = {
  CENTRAL_CLINIC: "centralClinic",
};

module.exports.CENTRAL_CLINIC_PLANS = {
  PLAN_IDS: ["001", "002"],
};
// オンライン資格確認の登録ステータス
module.exports.CONSENT_STATUS = {
  UNREGISTRABLE: "unregistrable",
  UNREGISTERED: "unregistered",
  REGISTERING: "registering",
  REGISTERED: "registered",
};
