import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core/";
import YearSelector from "./YearSelector";
import MonthColumn from "./MonthColumn";

import {
  setYear,
  setMonth,
  startOfMonth,
  endOfMonth,
  isBefore,
  isAfter,
} from "date-fns";

import { japaneseList } from "../../../../Resources/japaneseList";

const styles = (theme) => ({
  content: {
    width: "310px",
    padding: 0,
    "&:first-child": {
      paddingTop: 0,
    },
  },
  yearSelecter: {
    margin: "30px 30px 14px",
  },
  calendar: {
    margin: "0 auto 30px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignContent: "space-between",
    width: "214px",
    height: "146px",
  },
  buttonWrapper: {
    justifyContent: "space-between",
    margin: 0,
    padding: "15px 25px",
  },
  button: {
    margin: 0,
    width: "120px",
    height: "40px",
    borderRadius: "5px",
    background: `${theme.button.ok.backgroundColor} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    opacity: "1",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },

  buttonCancel: {
    color: "white",
    background: "#808080 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 0px #505050",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
});

const Months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

class MonthSelectDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedDate: new Date(),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = { open: nextProps.open };
    if (nextProps.open && !prevState.open) {
      nextState.selectedDate = nextProps.date;
    }
    return nextState;
  }

  handleChangeYear = (year) => {
    const { minimumDate, maximumDate } = this.props;
    const selectedDate = setYear(this.state.selectedDate, year);

    if (
      isBefore(selectedDate, minimumDate) &&
      minimumDate.getFullYear() === year
    ) {
      this.setState({ selectedDate: minimumDate });
    } else if (
      isAfter(selectedDate, maximumDate) &&
      maximumDate.getFullYear() === year
    ) {
      this.setState({ selectedDate: maximumDate });
    } else {
      this.setState({ selectedDate });
    }
  };

  handleChangeMonth = (month) => {
    const selectedDate = setMonth(this.state.selectedDate, month);
    this.setState({ selectedDate });
  };

  handleChangeDate = async () => {
    await this.props.handleChangeDate(this.state.selectedDate);
  };

  render() {
    const { handleClose, minimumDate, maximumDate, classes } = this.props;
    const { open, selectedDate } = this.state;
    const selectedMonth = selectedDate.getMonth();
    const selectedYear = selectedDate.getFullYear();
    const nowDate = new Date();
    const nowYear = nowDate.getFullYear();
    const nowMonth = nowDate.getMonth();

    const isDisabledMonth = (month) => {
      const selectedMonthDate = setMonth(selectedDate, month);

      return (
        isBefore(endOfMonth(selectedMonthDate), minimumDate) ||
        isAfter(startOfMonth(selectedMonthDate), maximumDate)
      );
    };

    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent className={classes.content}>
          <div className={classes.yearSelecter}>
            <YearSelector
              date={selectedDate}
              minimumDate={minimumDate}
              maximumDate={maximumDate}
              handleChangeYear={this.handleChangeYear}
            />
          </div>
          <div className={classes.calendar}>
            {Months.map((month) => (
              <MonthColumn
                key={month}
                month={month}
                handleChangeMonth={this.handleChangeMonth}
                isSelected={selectedMonth === month}
                isNow={selectedYear === nowYear && month === nowMonth}
                disabled={isDisabledMonth(month)}
              />
            ))}
          </div>
        </DialogContent>
        <Divider />
        <DialogActions className={classes.buttonWrapper}>
          <Button
            className={`${classes.button} ${classes.buttonCancel}`}
            variant="raised"
            onClick={handleClose}
            color="inherit"
          >
            {japaneseList.general.submitTexts.cancel}
          </Button>
          <Button
            className={classes.button}
            variant="raised"
            onClick={this.handleChangeDate}
            color="primary"
          >
            {japaneseList.general.submitTexts.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(MonthSelectDialog);
