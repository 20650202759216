import React from "react";
import styled from "styled-components";
import { isLnln } from "../../../Utils/checkLnln";
const iconColor = isLnln() ? "#171F55" : "#009896";
const IconStyled = styled.span`
  font-family: Hiragino Kaku Gothic ProN;
  color: ${iconColor};
  margin-left: 6px;
  padding: 0px 3px 0px 3px;
  border: 1px solid ${iconColor};
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  height: 18px;
`;

export const IconCategory = (props) => {
  const category = props.category;
  return (
    <React.Fragment>
      <IconStyled>{category}</IconStyled>
    </React.Fragment>
  );
};
