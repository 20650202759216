import React, { Component } from "react";
import { ApolloProvider } from "@apollo/react-components";
import { Auth, Hub, Logger } from "aws-amplify";
import { getMessaging, getToken } from "firebase/messaging";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "styled-components";
import { MuiThemeProvider } from "@material-ui/core/styles";

import { isLnln } from "./Utils/checkLnln";
import { muiThemeCarada, muiThemeLnln } from "./themes/material-ui";
import { styledThemeCarada, styledThemeLnln } from "./themes/styled-components";

import { Routes } from "./router";
import { initFCM } from "./Utils/WebPush";
import { client, Subscribe, GetDoctor, GetHospital } from "./graphql";

import MessageBox from "./comp/organisms/common/MessageBox";

import * as Sentry from "@sentry/browser";
import SiteContext from "./contexts/SiteContext";
import ImportantNoticeContext from "./contexts/ImportantNoticeContext";

const muiTheme = isLnln() ? muiThemeLnln : muiThemeCarada;
const styledTheme = isLnln() ? styledThemeLnln : styledThemeCarada;

const hubAuthListener = (data) => {
  // Apply user id to Sentry configuration.
  switch (data.payload.event) {
    case "signIn":
      break;
    case "configured":
      Auth.currentUserPoolUser()
        .then((user) => {
          Sentry.setUser({
            id: user.username,
          });
        })
        .catch((err) => {});
      break;
    case "signOut":
      Sentry.captureMessage("signout", Sentry.Severity.Log);
      Sentry.setUser({
        id: null,
      });
      break;
    case "signIn_failure":
      break;
    default:
      break;
  }
};
Hub.listen("auth", hubAuthListener);

class App extends Component {
  constructor(props) {
    super(props);

    this.logger = new Logger("App");
    this.state = {
      isLogin: undefined,
      numberOfUnapprovedAppointment: null,
      isAccess: true,
      doctor: null,
      hospital: null,
      // 重要なお知らせのコンポーネント側で監視して更新トリガを検出するための変数
      // 値をインクリメントして重要なお知らせのコンポーネント側にイベントを通知する。
      signalToRefreshImportantNotice: 0,
    };

    Auth.currentUserPoolUser()
      .then((user) => {
        this.setLoginState();
      })
      .catch((err) => {
        this.setState({ isLogin: false });
      });
    Hub.listen("auth", this.hubAuthListener);
  }

  componentDidMount() {
    if (this.state.isLogin) {
      this.setNumberOfUnapprovedAppointment();
    }
  }

  setNumberOfUnapprovedAppointment = () => {
    client
      .query({
        query: GetDoctor,
      })
      .then((res) => {
        this.setState({ doctor: res.data.doctor });
      });
  };

  setLoginState = async () => {
    if (window.location.pathname.startsWith("/not-access")) {
      this.setState({
        isLogin: true,
        isAccess: false,
      });
    } else {
      const { data } = await client.query({
        query: GetHospital,
      });

      this.setState({
        hospital: data.hospital,
      });

      this.setState({
        isLogin: true,
      });

      initFCM();

      this.updateMessageToken();
    }
  };

  /**
   * 重要なお知らせを更新する。
   * signalToRefreshImportantNoticeの値をインクリメントして
   * 重要なお知らせのコンポーネント側にイベントを通知する。
   */
  refreshImportantNotice = () => {
    this.setState({
      signalToRefreshImportantNotice:
        this.state.signalToRefreshImportantNotice + 1,
    });
  };

  updateMessageToken = async () => {
    if (window.location.pathname.startsWith("/not-access")) {
      this.setState({
        isAccess: false,
      });
    } else {
      const { data, errors } = await client.query({
        query: GetDoctor,
      });
      if (!errors) {
        if (data.doctor && data.doctor.token) {
          if (Notification.permission !== "granted") {
            if (Notification.permission === "denied") {
              const permissionError = new Error(`
FirebaseError: Messaging: Notifications have been blocked. (messaging/notifications-blocked).
通知 ON かつブラウザの通知権限がブロックされています。別のブラウザでログインした可能性があります。`);
              Sentry.captureException(permissionError);
            }
            return;
          }
          const messaging = getMessaging();
          const currentToken = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_WEB_PUSH_PUBLIC_KEY,
          });
          if (currentToken) {
            if (currentToken && currentToken !== data.doctor.token) {
              let params = {
                token: currentToken,
              };
              await client.mutate({
                mutation: Subscribe,
                variables: params,
              });
            }
          }
        }
      }
    }
  };

  hubAuthListener = (data) => {
    switch (data.payload.event) {
      case "signIn":
        this.setLoginState();
        this.setNumberOfUnapprovedAppointment();
        break;
      case "configured":
        // クラスインスタス前に呼ばれるため処理されない。
        // configuredを使いたい場合はAppクラス外のイベント処理にすること。
        break;
      case "signOut":
        this.setState({
          doctor: null,
          hospital: null,
          isLogin: false,
        });
        break;
      case "signIn_failure":
        this.logger.error("user sign in failed");
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <React.Fragment>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={styledTheme}>
            <React.Fragment>
              <CssBaseline />
              <ApolloProvider client={client}>
                <SiteContext.Provider
                  value={{
                    doctor: this.state.doctor,
                    hospital: this.state.hospital,
                  }}
                >
                  <ImportantNoticeContext.Provider
                    value={{
                      signalToRefreshImportantNotice:
                        this.state.signalToRefreshImportantNotice,
                      refreshImportantNotice: this.refreshImportantNotice,
                    }}
                  >
                    <Routes isLogin={this.state.isLogin} />
                    <MessageBox />
                  </ImportantNoticeContext.Provider>
                </SiteContext.Provider>
              </ApolloProvider>
            </React.Fragment>
          </ThemeProvider>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default App;
