import React from "react";
import { useHistory } from "react-router-dom";
import EventNote from "@material-ui/icons/EventNote";
import { japaneseList } from "../../../Resources/japaneseList";
import TooltipBase from "./TooltipBase";

const Resource = japaneseList.Components.Header;

const CalendarTooltip = () => {
  const history = useHistory();

  const title = Resource.render.AppBar.Toolbar.div.Tooltip.calendar;
  const pagePath = "/appointment/calendar";

  return (
    <React.Fragment>
      <TooltipBase
        title={title}
        onClick={() => {
          history.push(pagePath);
        }}
        Icon={EventNote}
        isUnderline={history.location.pathname === pagePath}
      />
    </React.Fragment>
  );
};

export default CalendarTooltip;
