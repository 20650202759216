import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import InputText from "../../../atoms/forms/InputText";
import FontPError from "../../../atoms/fonts/FontPError";

import { japaneseList } from "../../../../Resources/japaneseList";
import { withStyles } from "@material-ui/core";

const Resources = japaneseList.Components.Appointment.FileuploadDialog;

const _ = require("lodash");

const styles = (theme) => ({
  button: {
    padding: "5px",
    backgroundColor: theme.button.ok.backgroundColor,
    maxWidth: "38px",
    maxHeight: "24px",
    minWidth: "38px",
    minHeight: "24px",
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    boxShadow: "0px 1px 0px " + theme.button.ok.shadowColor,
    fontSize: "14px",
    fontWeight: "bold",
  },
  errors: {
    marginLeft: "50px",
  },
  errorText: {
    color: "#ef5e5e",
    fontSize: "11px",
    fontWeight: "bold",
  },
  inputError: {
    backgroundColor: "#FFF1F2",
  },
  input: {
    "&::placeholder": {
      fontSize: "14px",
      color: "#CCC",
      lineHeight: "13px",
    },
  },
});

const CustomInputText = styled(InputText)`
  border-radius: 3px;
  background: 0% 0% no-repeat padding-box
    ${(p) => p.theme.color.primaryVariant3};
  font-size: 12px;
  margin-left: 10px;
  border-color: #dddddd;
  padding: 8px 10px 5px 10px;
`;

const Input = styled.input.attrs({ type: "file" })`
  display: none;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const FileuploadDialogInput = (props) => {
  const { classes, file, handleNiceNameChange } = props;
  const inputEl = useRef(null);
  const niceName = (file && file.niceName) || "";

  const handleOnChangeInputFile = useCallback((e) => {
    const file = e.target.files.item(0);
    if (!_.isNull(file)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        props.handleChooseFileChange(file, fileData);
      };
      reader.readAsDataURL(file);
    }
  });

  const handleSelectFile = useCallback((e) => {
    e.preventDefault();
    inputEl.current.click();
  });

  const error = Resources.Errors.filter((item) => {
    // check error type
    return file.errors && _.includes(file.errors, item.code);
  }).map((item, key) => {
    return (
      <Container className={classes.errors}>
        <FontPError className={classes.errorText} size="ss" key={key}>
          {item.text}
        </FontPError>
      </Container>
    );
  });

  return (
    <React.Fragment>
      {error.length > 0 && error}
      <Container>
        <Button
          variant="raised"
          color="primary"
          className={classes.button}
          onClick={handleSelectFile}
        >
          {Resources.buttonSelectFile}
        </Button>
        <CustomInputText
          width={"full"}
          value={niceName}
          placeholder={
            file.file
              ? _.isUndefined(file.file.size) &&
                Resources.placeHolderInputFileName
              : Resources.placeHolderInputFileName
          }
          disabled={file.file && file.file.size ? false : true}
          className={error.length > 0 ? classes.inputError : classes.input}
          onChange={(e) => {
            handleNiceNameChange(e.target.value);
          }}
        />
        <Input ref={inputEl} onChange={handleOnChangeInputFile} />
      </Container>
    </React.Fragment>
  );
};
export default withStyles(styles)(FileuploadDialogInput);
