import React from "react";
import styled from 'styled-components';
import format from 'date-fns/format';
import { ja } from 'date-fns/locale';
import { DownloadButton } from '../../molecules/buttons/DownloadButton';
import { DocumentNameWithIcon } from './DocumentNameWithIcon';

const TableRow = styled.tr`
  border-bottom: 1px solid ${p => p.theme.color.secondary};
  height: 52px;
`;

const TableData = styled.td`
  min-width: 750px;
  text-align: left;
  font-size: 14px;
  color: ${p => p.theme.color.font};
  padding: 8px 10px;
`;

const TableDataName = styled(TableData)`
`;

const TableDataUpdateDatetime = styled(TableData)`
  min-width: 140px;
  width: 140px;
`;

const TableDataDownload = styled(TableData)`
  min-width: 140px;
  width: 140px;
`;

export const DocumentItem = ({ document }) => {
  const updateDatetime = format(new Date(document.activateDatetime), 'yyyy/MM/dd', { locale: ja });
  const fileUrl = document.fileUrl;
  
  return (
    <TableRow>
      <TableDataName>
        <DocumentNameWithIcon document={document} />
      </TableDataName>
      <TableDataUpdateDatetime>{updateDatetime}</TableDataUpdateDatetime>
      <TableDataDownload>
        { document.canDownload && <DownloadButton fileUrl={fileUrl} /> }
      </TableDataDownload>
    </TableRow>
  );
}