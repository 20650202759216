import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LinkA from "../../atoms/links/LinkA";
import {
  APPOINTMENT_STATUS,
  PROGRAM_TYPE,
  SETTLEMENT_ERROR,
  PAYMENT_METHOD,
} from "../../../Utils/const";
import { japaneseList } from "../../../Resources/japaneseList";

const PAYMENT_STATUS = {
  examCompleted: -1,
  offlineWelfareProgramAlreadySetFee: 1,
  paymentCompleted: 2,
  deliveryError: 3,
  paymentError: 4,
};

const TblCellStatus = styled.td`
  text-align: center;
  padding: 5px 10px;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
`;

const ButtonCustom = withStyles((theme) => ({
  root: {
    backgroundColor: theme.button.ok.backgroundColor,
    borderRadius: "3px",
    border: 0,
    color: "white",
    height: "24px",
    padding: "5px",
    fontWeight: "bold",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    boxShadow: "0px 1px 0px " + theme.button.ok.shadowColor,
    minWidth: "38px",
  },
}))(Button);

const StyledLinkA = styled(LinkA)`
  color: #2563c0;
  text-decoration: underline;
`;

const StyledErrorLinkA = styled(LinkA)`
  font-weight: bold;
  color: #ef5e5e;
  text-decoration: underline;
`;

const HighlightSpan = styled.span`
  font-weight: bold;
  color: #ef5e5e;
`;

const HospitalPaymentRowStatus = (props) => {
  const { appo, handleOpenMedicalFeeDialog, handleOpenConfirmDialog } = props;

  const Resource = japaneseList.Components.Payment.HospitalPaymentList;
  const deliveryErrorSet = [
    SETTLEMENT_ERROR.DELIVERY_MAINTENANCE,
    SETTLEMENT_ERROR.DELIVERY_PATIENT,
    SETTLEMENT_ERROR.DELIVERY_SYSTEM,
  ];
  const settlementErrorSet = [
    SETTLEMENT_ERROR.CREDIT_CARD,
    SETTLEMENT_ERROR.CARD_SYSTEM,
    SETTLEMENT_ERROR.UNEXPECTED_SYSTEM,
    SETTLEMENT_ERROR.INVALID_AMOUNT,
  ];
  const settlementError = settlementErrorSet.includes(appo.payment.errorCode);
  const deliveryError = deliveryErrorSet.includes(appo.payment.errorCode);

  const paymentStatus = (appo) => {
    const paymentStatusCheck = () => {
      return appo.status === APPOINTMENT_STATUS.PAYMENT_COMPLETED
        ? PAYMENT_STATUS.paymentCompleted
        : settlementError
        ? PAYMENT_STATUS.paymentError
        : deliveryError
        ? PAYMENT_STATUS.deliveryError
        : PAYMENT_STATUS.examCompleted;
    };
    const getStatus = () => {
      // TODO: 健康経営のメニューかどうかの判定方法、判定箇所をリファクタリングする
      const isBillingEnterprise =
        appo.menu &&
        appo.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;
      if (
        appo.menu &&
        ((appo.program && appo.program.type === PROGRAM_TYPE.WELFARE_PROGRAM) ||
          isBillingEnterprise) &&
        appo.status === APPOINTMENT_STATUS.PAYMENT_COMPLETED
      ) {
        return PAYMENT_STATUS.offlineWelfareProgramAlreadySetFee;
      } else {
        return paymentStatusCheck();
      }
    };

    const status = getStatus();

    const confirmLink = () => {
      return (
        <StyledLinkA
          onClick={() => {
            handleOpenConfirmDialog(appo);
          }}
        >
          {Resource.render.paymentStatus.j001}
        </StyledLinkA>
      );
    };

    switch (status) {
      case PAYMENT_STATUS.paymentCompleted:
        return confirmLink();
      case PAYMENT_STATUS.examCompleted:
        return (
          <HighlightSpan>{Resource.render.paymentStatus.j002}</HighlightSpan>
        );
      case PAYMENT_STATUS.offlineWelfareProgramAlreadySetFee:
        return Boolean(appo.enableChangePaymentInformation) ? (
          <ButtonCustom
            disabled={!appo.enableChangePaymentInformation}
            variant="raised"
            size="small"
            color="primary"
            onClick={() => {
              handleOpenMedicalFeeDialog(appo);
            }}
          >
            {Resource.render.paymentStatus.j003}
          </ButtonCustom>
        ) : (
          confirmLink()
        );
      case PAYMENT_STATUS.paymentError:
        return (
          <StyledErrorLinkA
            onClick={() => {
              handleOpenConfirmDialog(appo);
            }}
          >
            {Resource.render.paymentStatus.j004}
          </StyledErrorLinkA>
        );
      case PAYMENT_STATUS.deliveryError:
        return (
          <StyledErrorLinkA
            onClick={() => {
              handleOpenConfirmDialog(appo);
            }}
          >
            {Resource.render.paymentStatus.j005}
          </StyledErrorLinkA>
        );
      default:
        return "-";
    }
  };

  return <TblCellStatus>{paymentStatus(appo)}</TblCellStatus>;
};

export default HospitalPaymentRowStatus;
