import React, { Component } from "react";
import { Button, DialogContent, DialogActions } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FLOW } from "./Constants";
import { japaneseList } from "../../../../Resources/japaneseList";

const MenuResource = japaneseList.Components.Appointment.Stepper.Menu;

const styles = (theme) => ({
  dialogContent: {
    width: "500px",
    height: "71px",
    margin: "0",
    padding: "25px 26px 0px 31px",
    borderBottom: "1px solid #cccccc",
    fontSize: "14px",
    color: "#393939",
    background: "#ffffff",
  },
  dialogActions: {
    width: "500px",
    height: "70px",
    margin: "0",
    padding: "14px 25px 16px 24px",
    "& button": {
      height: "40px",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonAppointment: {
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
  dialogActionsButtonAppointment: {
    width: "120px",
    margin: "0 20px 0 0",
    padding: "13px 17px",
    borderRadius: "5px",
  },
  buttonSolt: {
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
  dialogActionsButtonSolt: {
    width: "120px",
    margin: "0",
    padding: "13px 25px",
    borderRadius: "5px",
  },
  buttonCancel: {
    backgroundColor: "#808080",
    boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
  dialogActionsButtonCancel: {
    width: "120px",
    margin: "0 20px 0 0",
    padding: "13px 25px",
    borderRadius: "5px",
  },
});

class Menu extends Component {
  constructor(props) {
    super(props);
    this.medicalTypes = this.props.medicalTypes;
    this.menuCreateAppointmentByDoctor = this.medicalTypes.filter(
      (item) => item.createAppointmentByDoctor
    );
    this.menuCreateSlotByDoctor = this.medicalTypes.filter(
      (item) => item.createSlotByDoctor
    );
  }

  onClickAppointmentButton = (flow) => {
    this.props.step(1, flow);
  };

  onClickSlotButton = (flow) => {
    this.props.step(1, flow);
  };

  render() {
    const { classes, closeScheduleSelectionPopup } = this.props;
    return (
      <React.Fragment>
        <DialogContent className={classes.dialogContent}>
          {MenuResource.render.div.DialogContent.j001}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={`${classes.buttonCancel} ${classes.dialogActionsButtonCancel}`}
            onClick={closeScheduleSelectionPopup}
          >
            {MenuResource.render.div.DialogActions.Button.j004}
          </Button>
          {this.menuCreateAppointmentByDoctor.length ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.onClickAppointmentButton(FLOW.APPOINTMENT)}
              className={`${classes.buttonAppointment} ${classes.dialogActionsButtonAppointment}`}
            >
              {MenuResource.render.div.DialogActions.Button.j002}
            </Button>
          ) : (
            ""
          )}
          {this.menuCreateSlotByDoctor.length ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.onClickSlotButton(FLOW.SLOT)}
              className={`${classes.buttonSolt} ${classes.dialogActionsButtonSolt}`}
            >
              {MenuResource.render.div.DialogActions.Button.j003}
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Menu);
