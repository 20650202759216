import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import jaLocale from "date-fns/locale/ja";
import { japaneseList } from "../../../Resources/japaneseList";
import { withRouter } from "react-router";

const AppointConditionInputResource =
  japaneseList.Components.Appointment.AppointConditionInput;

const styles = (theme) => ({
  root: {
    marginBottom: "17px",
  },
  datePicker: {
    margin: theme.spacing.unit * 1,
    maxWidth: "200px",
    "& label": { fontSize: "17px" },
  },
  dateIcon: {
    border: "none !important",
  },
  button: {
    color: theme.button.ok.color,
    backgroundColor: theme.button.ok.backgroundColor,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColor,
    },
    boxShadow: "none",
  },
  calendarIcon: {
    color: "white",
    fontSize: 24,
  },
  searchText: { color: "#FFFFFF;", fontSize: "14px" },
  calendarIconButton: {
    padding: 0,
    backgroundColor: theme.button.ok.backgroundColor,
    maxWidth: "34px",
    maxHeight: "34px",
    minWidth: "34px",
    minHeight: "34px",
    margin: "-3px 0 0 -35px",
    borderRadius: "0 3px 3px 0",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    boxShadow: "0px 1px 0px " + theme.button.ok.shadowColor,
  },
  searchIconButtonNext: {
    padding: 0,
    alignItems: "center",
    backgroundColor: theme.button.ok.backgroundColor,
    margin: "0 0 1px 26px",
    background: "#27CDCB 0% 0% no-repeat padding-box",
    borderRadius: "3px",
    fontSize: "14px",
    width: "62px",
    height: "34px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    boxShadow: `0px 1px 0px ${theme.color.shadow}`,
  },

  searchIconButtonPrevious: {
    padding: 0,
    alignItems: "center",
    backgroundColor: theme.button.ok.backgroundColor,
    margin: "0 0 1px 10px",
    boxShadow: `0px 1px 0px ${theme.color.shadow}`,
    borderRadius: "3px",
    fontSize: "14px",
    width: "62px",
    height: "34px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
  labelDate: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#808080",
  },
  inputDateAndButtons: {
    marginLeft: "-8px",
    marginTop: "-9px",
  },
});

const DatePickerCustom = styled(DatePicker)`
  height: 18px;
  line-height: 18px;
  input {
    height: 34px;
    line-height: 34px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    padding: 0 0 0 7px;
    font-size: 14px;
    background: ${(p) => p.theme.color.primaryVariant3} 0% 0% no-repeat
      padding-box;
    border: 1px solid #dddddd;
    border-radius: 3px;
    color: #393939;
  }
`;

class AppointmentConditionInput extends Component {
  constructor(props) {
    super(props);
    this.datePickerRef = React.createRef();
  }
  state = {
    selectedDate: new Date(),
    errorMessage: null,
  };

  handleDateChange = async (date, key) => {
    await this.setState({ [key]: date });
    await this.props.onStateChanged({
      start: this.state.selectedDate,
      end: this.state.selectedDate,
    });
  };

  handleSearch = () => {};

  openDatePicker = (e) => {
    this.datePickerRef.current && this.datePickerRef.current.open();
  };

  setSelectedDate(search) {
    if (search) {
      const params = new URLSearchParams(search);
      const currentDay = params.get("currentDay");
      if (currentDay) {
        this.handleDateChange(new Date(currentDay), "selectedDate");
      } else {
        this.handleDateChange(new Date(), "selectedDate");
      }
    } else {
      this.handleDateChange(new Date(), "selectedDate");
    }
  }

  componentDidMount() {
    this.unregisterHistoryCallback = this.props.history.listen((router) => {
      this.setSelectedDate(router.search);
    });
  }

  componentWillMount() {
    this.setSelectedDate(this.props.search);
  }

  render() {
    const { classes } = this.props;
    const { errorMessage } = this.state;
    const locale = jaLocale;
    return (
      <div className={classes.root}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <label className={classes.labelDate}>
            {
              AppointConditionInputResource.render.div.MuiPickersUtilsProvider
                .div.DatePicker.j001
            }
          </label>
          <div className={classes.inputDateAndButtons}>
            <DatePickerCustom
              ref={this.datePickerRef}
              InputProps={{
                disableUnderline: true,
              }}
              className={classes.datePicker}
              autoFocus
              format="yyyy/MM/dd (iii)"
              value={this.state.selectedDate}
              onChange={(d) => this.handleDateChange(d, "selectedDate")}
            />
            <Button
              disabled={errorMessage !== null}
              onClick={this.openDatePicker}
              variant="raised"
              color="primary"
              className={classes.calendarIconButton}
            >
              <DateRangeIcon className={classes.calendarIcon}></DateRangeIcon>
            </Button>
            <Button
              disabled={errorMessage !== null}
              onClick={() => {
                const selectedDate = this.state.selectedDate;
                selectedDate.setDate(selectedDate.getDate() - 1);
                this.datePickerRef.current &&
                  this.datePickerRef.current.props.onChange(selectedDate);
              }}
              variant="raised"
              size="small"
              color="primary"
              className={classes.searchIconButtonNext}
            >
              <span className={classes.searchText}>
                {
                  AppointConditionInputResource.render.div
                    .MuiPickersUtilsProvider.div.Button.j003
                }
              </span>
            </Button>
            <Button
              disabled={errorMessage !== null}
              onClick={() => {
                const selectedDate = this.state.selectedDate;
                selectedDate.setDate(selectedDate.getDate() + 1);
                this.datePickerRef.current &&
                  this.datePickerRef.current.props.onChange(selectedDate);
              }}
              variant="raised"
              size="small"
              color="primary"
              className={classes.searchIconButtonPrevious}
            >
              <span className={classes.searchText}>
                {
                  AppointConditionInputResource.render.div
                    .MuiPickersUtilsProvider.div.Button.j002
                }
              </span>
            </Button>
            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}
          </div>
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(AppointmentConditionInput));
