import gql from "graphql-tag";

export const GetDeliveryFee = gql`
  query GetDeliveryFee($patientId: String!) {
    deliveryFee(patientId: $patientId) {
      deliveryFee
      deliveryFeeTax
      deliveryMaterialFee
    }
  }
`;