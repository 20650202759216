import styled from "styled-components";

const ButtonBase = styled.button`
  display:inline-block;
  width: ${p => p.width? p.width:p.theme.button.width.default };
  padding: ${p => p.padding? p.padding:p.theme.button.padding.default };
  box-sizing: border-box;
  font-size: ${p => p.theme.font.size.default};
  font-weight: bold;
  font-family: 'ヒラギノ角ゴ ProN W3', Hiragino Kaku Gothic ProN, HiraKakuProN-W3, Yu Gothic, sans-serif;
  color: ${p => p.theme.button.color.default};
  background-color: ${p => p.theme.button.bg.default};
  border-radius: 5px;
  border: solid 1px ${p => p.theme.button.border.default};
  cursor:pointer;
  transition:all 0.3s ease;
  outline:0;
  &:hover{
    filter:brightness(1.2);
  }
  &:disabled{
    background-color: ${p => p.theme.button.bg.disable};
    border: solid 1px ${p => p.theme.button.bg.disable};
    &:hover{
      filter:brightness(1);
    }
  }
`;

export default ButtonBase;
