import { integer } from "aws-sdk/clients/cloudfront";
import styled from "styled-components";

interface NewsTitleProps {
  news: News;
  titleMaxlength: integer;
}

interface News {
  id: string;
  title: string;
  content: string;
  activateDatetime: Date;
  isImportant: string;
  category: string;
}
const NewsTitleContainer = styled.div`
  margin-right: 57px;
`;

export const NewsTitle = (props: NewsTitleProps) => {
  const { news, titleMaxlength } = props;
  const title = news.title;
  const shortTitle =
    title.length > titleMaxlength
      ? title.substring(0, titleMaxlength) + "..."
      : title;
  return <NewsTitleContainer id="news-title">{shortTitle}</NewsTitleContainer>;
};
