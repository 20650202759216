import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import VideoCallNgIcon from "../../../images/icon/icon-video-call-ng.svg";
import VideoCallOkIcon from "../../../images/icon/icon-video-call-ok.svg";
import { CautionOK } from "../../molecules/panels/CautionOK";
import { CautionNG } from "../../molecules/panels/CautionNG";

const MainAreaVideoCloseResource =
  japaneseList.Pages.Medical.MainAreaVideoClose;

const Img = styled.img`
  display: block;
  margin: 0px auto 10px;
`;

const ParticipantContainer = styled.div`
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 47px;
`;

const ParticipantStatusContent = styled.div`
  width: 230px;
  height: 375px;
  border-radius: 4px;
  background-color: #ffffff;
`;

const ParticipantTitle = styled.div`
  padding: 20px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #393939;
`;

const VideoConnectionIcon = styled.div`
  margin: 16px;
`;

interface ParticipantStatusProps {
  isPatientReady: boolean;
  patient: {
    familyName: string;
    givenName: string;
    phoneNumber: string;
  };
  patientStandbyStatus: {
    isLogin: boolean;
    videoStandby: {
      isOpeningStandbyPage: boolean;
      lastMediaDeviceError: string;
    };
  };
}

export const ParticipantStatus = (props: ParticipantStatusProps) => {
  const { isPatientReady, patient, patientStandbyStatus } = props;
  return (
    <ParticipantContainer>
      <ParticipantStatusContent>
        <ParticipantTitle>
          {MainAreaVideoCloseResource.title.j001}
        </ParticipantTitle>
        <CautionOK />
      </ParticipantStatusContent>
      <VideoConnectionIcon>
        <Img
          src={isPatientReady ? VideoCallOkIcon : VideoCallNgIcon}
          alt={""}
        />
      </VideoConnectionIcon>
      <ParticipantStatusContent>
        <ParticipantTitle>
          {MainAreaVideoCloseResource.title.j002}
        </ParticipantTitle>
        {isPatientReady ? (
          <CautionOK />
        ) : (
          <CautionNG
            patient={patient}
            patientStandbyStatus={patientStandbyStatus}
          />
        )}
      </ParticipantStatusContent>
    </ParticipantContainer>
  );
};
