import styled from "styled-components";

const FontBase = styled.p`
  padding:0;
  margin:0;
  color: ${p => p.theme.font.color.default};
  font-size: ${p => p.theme.font.size.default};
  line-height:1.6;
  font-family: 'ヒラギノ角ゴ ProN W3', Hiragino Kaku Gothic ProN, HiraKakuProN-W3, Yu Gothic, sans-serif;
`;

export default FontBase;
