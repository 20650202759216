import React from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { formatDate } from "../../../Utils/DateUtil";
import {
  getCombinedAddress,
  getPhoneNumberWithHyphen,
  getAge,
} from "../../../Utils/DisplayLogic";
import FontP from "../../atoms/fonts/FontP";
import FontPBold from "../../atoms/fonts/FontPBold";
import ButtonBase from "../../atoms/buttons/ButtonBase";
import AccountCircle from "../../../images/account_circle-24px.svg";
import { japaneseList } from "../../../Resources/japaneseList";

const DialogPatientInfoResource =
  japaneseList.molecules.dialogs.DialogPatientInfo;
const cardRegistered = "registered";

const DialogTitleText = styled(FontPBold)`
  font-size: 20px;
`;

const CustomHr = styled.hr`
  margin: 0px;
  width: 100%;
  height: 1px;
  background-color: #cccccc;
  border: 0;
`;

const CenterArea = styled.div`
  padding-top: 20px;
`;

const InfoTitle = styled(FontPBold)`
  color: #808080;
  font-size: 12px;
`;

const InfoContent = styled(FontP)`
  font-size: 14px;
`;

const NameInfo = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const CircleIcon = styled.img`
  width: 42px;
`;

const Name = styled.div`
  margin-left: 10px;
`;

const BasicInfo = styled.table`
  text-align: center;
  border-collapse: collapse;
  border: 1px solid #cccccc;
`;

const BasicInfoHead = styled.thead`
  padding: 5px auto;
  border-bottom: none;
`;

const BasicInfoTitleCell = styled.td`
  min-width: 44px;
  border-right: 1px solid #cccccc;
  background-color: #eeeeee;
`;

const BasicInfoContentCell = styled.td`
  border-right: 1px solid #cccccc;
  padding: 10px 10px;
`;

const MoreInfo = styled.table`
  margin-top: 20px;
  border-collapse: collapse;
  border: 1px solid #cccccc;
`;

const MoreInfoTitleCell = styled.td`
  min-height: 36px;
  padding-left: 10px;
  padding-right: 20px;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
`;

const MoreInfoContentCell = styled.td`
  width: 610px;
  border-bottom: 1px solid #cccccc;
  padding: 10px 18px;
`;

const CustomFontP = styled(FontP)`
  font-size: 12px;
`;

const CustomButton = styled(ButtonBase)`
  width: 120px;
  height: 40px;
  background-color: #808080;
  border: 0;
  box-shadow: 0px 2px 0px #505050;
  margin: 14px 22px 16px;
  float: right;
`;

const ButtonText = styled(FontP)`
  margin: auto;
  font-size: 14px;
  color: white;
`;

export const DialogPatientInfo = ({
  patient,
  open,
  onClose,
  handleClickButton,
}) => {
  const {
    familyName,
    givenName,
    familyNameKana,
    givenNameKana,
    gender,
    birthdate,
    cardStatus,
    address,
    phoneNumber,
    email,
  } = patient;

  const getAddr = (addr) => {
    const { postalCode, address } = getCombinedAddress(addr);

    return (
      <React.Fragment>
        <InfoContent>{postalCode}</InfoContent>
        <InfoContent>{address}</InfoContent>
      </React.Fragment>
    );
  };

  const isCardRegistered = cardStatus === cardRegistered ? true : false;
  const birthdateWithSlash = birthdate
    ? formatDate(birthdate, DialogPatientInfoResource.dateFormat)
    : "";
  const age = birthdate ? getAge(birthdate) : "";

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"}>
      <DialogTitle>
        <DialogTitleText>{DialogPatientInfoResource.title}</DialogTitleText>
      </DialogTitle>
      <CustomHr />
      <DialogContent>
        <CenterArea>
          <NameInfo>
            <CircleIcon src={AccountCircle} />
            <Name>
              <FontPBold>{`${familyName} ${givenName}`}</FontPBold>
              <CustomFontP>{`${familyNameKana} ${givenNameKana}`}</CustomFontP>
            </Name>
          </NameInfo>
          <BasicInfo>
            <BasicInfoHead>
              <tr>
                <BasicInfoTitleCell>
                  <InfoTitle>
                    {DialogPatientInfoResource.infoTitle.j001}
                  </InfoTitle>
                </BasicInfoTitleCell>
                <BasicInfoTitleCell>
                  <InfoTitle>
                    {DialogPatientInfoResource.infoTitle.j002}
                  </InfoTitle>
                </BasicInfoTitleCell>
                <BasicInfoTitleCell>
                  <InfoTitle>
                    {DialogPatientInfoResource.infoTitle.j003}
                  </InfoTitle>
                </BasicInfoTitleCell>
              </tr>
            </BasicInfoHead>
            <tbody>
              <tr>
                <BasicInfoContentCell>
                  <InfoContent>{birthdateWithSlash}</InfoContent>
                </BasicInfoContentCell>
                <BasicInfoContentCell>
                  <InfoContent>{age}</InfoContent>
                </BasicInfoContentCell>
                <BasicInfoContentCell>
                  <InfoContent>{gender}</InfoContent>
                </BasicInfoContentCell>
              </tr>
            </tbody>
          </BasicInfo>
          <MoreInfo>
            <tbody>
              <tr>
                <MoreInfoTitleCell>
                  <InfoTitle>
                    {DialogPatientInfoResource.infoTitle.j004}
                  </InfoTitle>
                </MoreInfoTitleCell>
                <MoreInfoContentCell>
                  <InfoContent>
                    {phoneNumber && getPhoneNumberWithHyphen(phoneNumber)}
                  </InfoContent>
                </MoreInfoContentCell>
              </tr>
              <tr>
                <MoreInfoTitleCell>
                  <InfoTitle>
                    {DialogPatientInfoResource.infoTitle.j005}
                  </InfoTitle>
                </MoreInfoTitleCell>
                <MoreInfoContentCell>
                  {address ? (
                    getAddr(address)
                  ) : (
                    <InfoContent>
                      {DialogPatientInfoResource.unregistered}
                    </InfoContent>
                  )}
                </MoreInfoContentCell>
              </tr>
              <tr>
                <MoreInfoTitleCell>
                  <InfoTitle>
                    {DialogPatientInfoResource.infoTitle.j006}
                  </InfoTitle>
                </MoreInfoTitleCell>
                <MoreInfoContentCell>
                  <InfoContent>
                    {email ? email : DialogPatientInfoResource.unregistered}
                  </InfoContent>
                </MoreInfoContentCell>
              </tr>
              <tr>
                <MoreInfoTitleCell>
                  <InfoTitle>
                    {DialogPatientInfoResource.infoTitle.j007}
                  </InfoTitle>
                </MoreInfoTitleCell>
                <MoreInfoContentCell>
                  <InfoContent>
                    {isCardRegistered
                      ? DialogPatientInfoResource.cardRegistered
                      : DialogPatientInfoResource.unregistered}
                  </InfoContent>
                </MoreInfoContentCell>
              </tr>
            </tbody>
          </MoreInfo>
        </CenterArea>
      </DialogContent>
      <CustomHr />
      <div>
        <CustomButton onClick={handleClickButton}>
          <ButtonText>{DialogPatientInfoResource.button}</ButtonText>
        </CustomButton>
      </div>
    </Dialog>
  );
};
