import React, { Component } from "react";
import styled, { keyframes } from "styled-components";

import LoadingText from "../../atoms/others/LoadingText";
import LoadingCapsule from "../../atoms/others/LoadingCapsule";

const hideAnime = keyframes`
  0% {
    opacity:1;
    height:100vh;
  }
  90% {
    opacity:0;
    height:100vh;
  }
  100% {
    opacity:0;
    height:0;
  }
`;
const LoadgingWapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(102, 102, 102, 0.7);
  z-index: 90;
  &.is-hide {
    animation: ${hideAnime} 0.8s ease 0.6s 1 forwards;
  }
`;
class LoadingScreen extends Component {
  static defaultProps = {
    isLoading: false,
  };

  constructor(props) {
    super(props);
    this.timerID = null;
    this.timeOut = 5000;
    this.disabelEvent();
  }

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.enableEvent();
  }

  //
  checkEvent(isLoading) {
    if (isLoading) this.disabelEvent();
  }

  disabelEvent() {
    //PC用
    var scroll_event =
      "onwheel" in document
        ? "wheel"
        : "onmousewheel" in document
        ? "mousewheel"
        : "DOMMouseScroll";
    document.addEventListener(scroll_event, this.handleTouchMove, {
      passive: false,
    });
    //SP用
    document.addEventListener("touchmove", this.handleTouchMove, {
      passive: false,
    });
    // keyframes end event
    document.addEventListener("animationend", this.handleAnimationEnd);
    // Forced release
    this.setForceEnable();
  }

  enableEvent() {
    //PC用
    var scroll_event =
      "onwheel" in document
        ? "wheel"
        : "onmousewheel" in document
        ? "mousewheel"
        : "DOMMouseScroll";
    document.removeEventListener(scroll_event, this.handleTouchMove, {
      passive: false,
    });
    //スクロール復帰
    document.removeEventListener("touchmove", this.handleTouchMove, {
      passive: false,
    });
    // keyframes end event
    document.removeEventListener("animationend", this.handleAnimationEnd);
  }

  handleTouchMove = (event) => {
    event.preventDefault();
  };

  handleAnimationEnd = (event) => {
    this.enableEvent();
  };

  setForceEnable() {
    clearTimeout(this.timerID);
    this.timerID = setTimeout(
      function () {
        this.enableEvent();
      }.bind(this),
      this.timeOut
    );
  }

  render() {
    const { isLoading } = this.props;
    this.checkEvent(isLoading);
    return (
      <LoadgingWapper
        className={`${this.props.className}${isLoading ? "" : " is-hide"}`}
      >
        <LoadingCapsule />
        <LoadingText />
      </LoadgingWapper>
    );
  }
}

export default LoadingScreen;
