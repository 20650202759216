import React, { useState, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { blockingUnsupportedDevice } from "../Utils/UnsupportedDevice";
import UnsupportedDeviceAlert from "../comp/organisms/unsupportedDevice/UnsupportedDeviceAlert";
import ConnectingAlert from "../comp/organisms/common/ConnectingAlert";
import AuthenticatorPage from "../comp/pages/authenticator";
import { cognitoSessionOut } from "../Utils/SignOut";
import { isVideoConnecting } from "../Utils/isVideoConnecting";
// import { GetMediaDeviceStatus, MediaDeviceStatus } from "./MediaDevice";

const DefaultPage = ({ exact, path, component, render, isLogin }) => {
  const history = useHistory();

  // const [isShowMediaDevicePermissionAlert, setIsShowMediaDevicePermissionAlert] = useState(false);
  const [isShowUnsupportedDeviceAlert, setIsShowUnsupportedDeviceAlert] =
    useState(false);
  const [blockStartMessage, setBlockStartMessage] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  useEffect(() => {
    // const isMediaDeviceAccepted = async () => {
    //   const { mediaDeviceStatus } = await GetMediaDeviceStatus(false);
    //   if (mediaDeviceStatus !== MediaDeviceStatus.accepted)
    //   setIsShowMediaDevicePermissionAlert(true);
    // };
    // isMediaDeviceAccepted();
    (async () => {
      let { isShowUnsupportedDeviceAlert, blockStartMessage } =
        await blockingUnsupportedDevice(path, history);
      setIsShowUnsupportedDeviceAlert(isShowUnsupportedDeviceAlert);
      setBlockStartMessage(blockStartMessage);
    })();
    (async () => {
      await cognitoSessionOut();
      const videoConnect = await isVideoConnecting();
      setIsConnecting(videoConnect);
    })();
    return () => {
      setIsConnecting(false);
    };
  }, [path]);

  return (
    <React.Fragment>
      <Route
        exact={exact}
        path={path}
        component={isLogin === false ? AuthenticatorPage : component}
        render={render}
      />
      {isShowUnsupportedDeviceAlert && (
        <UnsupportedDeviceAlert
          blockStartMessage={blockStartMessage}
          props={{ history }}
        />
      )}
      {isConnecting && <ConnectingAlert />}
      {/* ToDo #3112 MediaDevicePermissionAlert Popup. Rediscuss along with the initial settings*/}
      {/*{isShowMediaDevicePermissionAlert && <MediaDevicePermissionAlert />}*/}
    </React.Fragment>
  );
};

export default DefaultPage;
