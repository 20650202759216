import gql from "graphql-tag";

export const GetMedicalHistory = gql`
  query GetMedicalHistory($patientId: String!, $status: [String!]) {
    appointments(patientId: $patientId, status: $status) {
      items {
        createTime
        status
        from
        to
        content
        contentType
        companyName
        interviewSheetAnswerStatus
        menu {
          medicalMethod
          displayName
          supportsInsuranceTreatment
        }
        payment {
          errorCode
          medicalExpense
        }
        program {
          type
        }
        doctor {
          doctorId
          familyName
          givenName
        }
      }
    }
  }
`;
