import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  DialogContent,
  DialogActions,
  Dialog,
} from "@material-ui/core";
import { japaneseList } from "../../../Resources/japaneseList";

const Resource = japaneseList.Pages.Medical.PatientCallingWarningDialog;
const styles = (theme) => {
  return {
    dialogInner: {
      marginBottom: "-15px",
    },
    footer: {
      paddingBottom: "5px",
      textAlign: "center",
      width: "100%",
    },
  };
};

class PatientCallingWarningDialog extends Component {
  render() {
    const { hasError, classes } = this.props;
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={hasError}
      >
        <DialogContent>
          <div className={classes.dialogInner}>
            <div>{Resource.render.Dialog.DialogContent.div.div.j001}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.footer}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.props.closePopup}
            >
              {Resource.render.Dialog.DialogActions.div.Button.j001}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(PatientCallingWarningDialog);
