import React, { Component } from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";

const Container = styled.div`
  padding: 0 2px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 21px 1fr;
  grid-row-gap: 3px;
`;

const TextCell = styled.label`
  font-size: 12px;
  line-height: 20px;
  color: #808080;
`;

class CancelPolicy extends Component {
  render() {
    const renderResource = japaneseList.molecules.PasswordPolicy.render;
    return (
      <Container>
        <TextCell>{renderResource.j000}</TextCell>
        <TextCell>{renderResource.j001}</TextCell>
        <TextCell>{renderResource.j000}</TextCell>
        <TextCell>{renderResource.j002}</TextCell>
      </Container>
    );
  }
}
export default CancelPolicy;
