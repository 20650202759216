const Titles = {
  home: "",
  signIn: "ログイン",
  forgotPassword: "パスワード再設定",
  newPasswordRequired: "パスワード再設定",

  appointment: "診療一覧",
  unapprovedAppointment: "未承認予約一覧",
  calendar: "スケジュール管理",
  payment: "会計処理",
  contact: "お問い合わせ",
  settings: "通知設定",

  medical: "ビデオ通話",
  patient: "患者詳細",

  notFound: "404 Not found",
  notSupport: "",
  notAccess: "",
  siteTypeName: "【医療機関】",
};

export default Titles;
