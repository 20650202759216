import gql from "graphql-tag";

export const RegisterSlot = gql`
  mutation RegisterSlot(
    $from: String!
    $to: String!
    $capacity: Int!
    $menuMethod: String!
    $medicalDoctorId: String!
  ) {
    registerSlot(
      from: $from
      to: $to
      capacity: $capacity
      menuMethod: $menuMethod
      doctorId: $medicalDoctorId
    ) {
      from
      to
      hospitalId
    }
  }
`;
