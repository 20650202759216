import gql from "graphql-tag";

export const GenerateUploadPresignedUrl = gql`
  mutation GenerateUploadPresignedUrl(
    $patientId: ID!
    $fileName: String!
    $filetype: String!
    $medicalDoctorId: String!
  ) {
    generateUploadPresignedUrl(
      patientId: $patientId
      fileName: $fileName
      filetype: $filetype
      doctorId: $medicalDoctorId
    ) {
      putSignedRequest
      filePath
    }
  }
`;
