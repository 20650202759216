import React, { useState } from "react";
import styled from "styled-components";
import Heading1 from "../../atoms/headings/Heading1";
import { DocumentList } from "./DocumentList";
import { ScrollToAnchor } from "./ScrollToAnchor";

const DocumentListWrapper = styled.div`
  display: inline-block;
  width: 100%;
  margin: 0 48px;
`;

export const Documents = ({ documents }) => {
  // 現在のURLのアンカー値を取得
  const [documentHash] = useState(window.location.hash);
  // アンカーを除去する前の（#）
  const [documentId] = useState(documentHash.substring(1));
  return (
    <React.Fragment>
      {documents && (
        <DocumentListWrapper>
          <Heading1>資料ダウンロード</Heading1>
          <DocumentList documents={documents} />
          <ScrollToAnchor id={documentId}></ScrollToAnchor>
        </DocumentListWrapper>
      )}
    </React.Fragment>
  );
};
