import red from "@material-ui/core/colors/red";
import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#fafafa",
        },
      },
    },
  },
  // used headline
  title: {
    color: "#27cdcb",
    fontSize: "1.8rem",
  },
  // used default font
  font: {
    color: "#393939",
    fontSize: "1.2rem",
    accent: "#27cdcb",
    info: "#ff4d4d",
    sub: "#999999",
  },
  palette: {
    primary: {
      main: "#27cdcb",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ffffff",
    },
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif"',
      lineHeight: "1.375em",
      color: "rgba(102, 102, 102, 1)",
    },
  },
  icon: {
    headar: "#ffffff",
  },
  button: {
    ok: {
      color: "#ffff",
      backgroundColor: "#27cdcb",
      shadowColor: "#009896",
      backgroundColorHover: "#6CE1D5",
    },
    cancel: {
      backgroundColor: "#e0e0e0",
      shadowColor: "#505050",
      backgroundColorHover: "#A6A6A6",
    },
    disable: {
      backgroundColor: "#dddddd",
      hoverColor: "#dddddd",
    },
    cancelApproveAppo: {
      backgroundColor: "#f1f3f3",
    },
    cancelUploadFile: {
      backgroundColor: "#808080",
      hoverColor: "#A6A6A6",
    },
    rotate: {
      color: "#27cdcb",
      backgroundColor: "#27cdcb",
    },
    remove: {
      backgroundColor: "#4164b5",
    },
    pager: {
      borderColor: "#27CDCB",
      backgroundColorSelected: "#D9F6F3",
      colorSelected: "#6CE1D5",
      colorMore: "#6CE1D5",
    },
  },
  color: {
    primary: "#27cdcb",
    primary1: "#27cdcb",
    notInsurance: "#EF5E5E",
    primaryVariant: "#6ce1d5",
    primaryVariant2: "#d3f6f2",
    primaryVariant3: "#eafbf9",
    primaryVariant4: "#f2feff",
    primaryVariant5: "#FFF1F2",
    secondary: "#cccccc",
    secondaryVariant: "#f1f3f3",
    secondaryVariant2: "#f7f7f7",
    warning: "#ff4d4d",
    warningBackground: "#fff0f0",
    font: "#393939",
    fontVariant: "#999999",
    fontInvert: "#ffffff",
    examIcon1: "#faae1c",
    examIcon2: "#85b7de",
    borderRadio: "#27cdcb",
    shadow: "#009896",
    tableHead: "#999999",
    header: {
      selected: {
        bg: "#27cdcb",
        font: "#ffffff",
      },
    },
  },
});
