import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import styled from "styled-components";

import { IconCameraNG } from "../../atoms/icons/IconCameraNG";
import { IconMicNG } from "../../atoms/icons/IconMicNG";

import WarningIcon from "../../../images/icon/error.svg";
import DoctorDeviceErrorWinChrome from "../../../images/doctor_device_error_win_chrome.png";
import DoctorDeviceErrorWinEdge from "../../../images/doctor_device_error_win_edge.png";
import DoctorDeviceErrorMacChrome from "../../../images/doctor_device_error_mac_chrome.png";
import DoctorDeviceErrorIpadSafari from "../../../images/doctor_device_error_ipad-safari.png";

import { ErrorDevice, MediaDeviceError } from "../../../enums";
import { japaneseList } from "../../../Resources/japaneseList";
const Resource = japaneseList.Pages.Medical.DoctorDeviceStatusDialog;

const styles = () => ({
  paper: { width: "500px", maxHeight: "600px" },
  dialogTitle: {
    padding: "20px 24px 20px 24px",
    borderBottom: "1px solid #CCCCCC",
    display: "flex",
    alignItems: "center",
  },
  dialogContent: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    overflow: "hidden",
    padding: "20px 24px 20px 24px",
  },
  mediaIcon: {
    margin: "0 5px",
  },
});

const Img = styled.img``;

const FlexWrapIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const WrapMessageError = styled.div`
  margin-bottom: 20px;
`;

const WrapMessageNotFoundError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
`;

const WrapMessageOtherError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
`;

const Link = styled.a`
  white-space: nowrap;
  color: #2563c0;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

const TextError = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #393939;
`;

const TextTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-left: 10px;
  color: #393939;
`;

const deviceErrorImageUrl: Record<ErrorDevice, string> = {
  [ErrorDevice.WinChrome]: DoctorDeviceErrorWinChrome,
  [ErrorDevice.WinEdge]: DoctorDeviceErrorWinEdge,
  [ErrorDevice.MacChrome]: DoctorDeviceErrorMacChrome,
  [ErrorDevice.IpadSafari]: DoctorDeviceErrorIpadSafari,
};

interface DoctorDeviceStatusDialogProps {
  classes: any;
  errorDevice: ErrorDevice;
  mediaDeviceError: MediaDeviceError;
}

const DoctorDeviceStatusDialog = (props: DoctorDeviceStatusDialogProps) => {
  const { classes, errorDevice, mediaDeviceError } = props;

  const renderErrorMessageNotAllow = () => {
    const ResourceMsgNotAllowError = Resource.NotAllowError[errorDevice];
    return (
      <WrapMessageError>
        <TextError>{ResourceMsgNotAllowError.j001}</TextError>
        <TextError>
          {ResourceMsgNotAllowError.j002}
          <Link onClick={() => window.location.reload()}>
            {ResourceMsgNotAllowError.link}
          </Link>
          {ResourceMsgNotAllowError.j003}
        </TextError>
      </WrapMessageError>
    );
  };

  return (
    <Dialog open classes={{ paper: classes.paper }}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Img src={WarningIcon} />
        <TextTitle>{Resource.title}</TextTitle>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FlexWrapIcons>
          <IconCameraNG />
          <IconMicNG />
        </FlexWrapIcons>
        {MediaDeviceError.NotAllowedError === mediaDeviceError && (
          <>
            {renderErrorMessageNotAllow()}
            {deviceErrorImageUrl[errorDevice] && (
              <Img src={deviceErrorImageUrl[errorDevice]} />
            )}
          </>
        )}
        {MediaDeviceError.NotFoundError === mediaDeviceError && (
          <WrapMessageNotFoundError>
            <TextError>{Resource.NotFoundError.j001}</TextError>
            <TextError>{Resource.NotFoundError.j002}</TextError>
          </WrapMessageNotFoundError>
        )}
        {MediaDeviceError.OtherError === mediaDeviceError && (
          <WrapMessageOtherError>
            <TextError>{Resource.OtherError.j001}</TextError>
            <TextError>{Resource.OtherError.j002}</TextError>
          </WrapMessageOtherError>
        )}
        {MediaDeviceError.NotReadableError === mediaDeviceError && (
          <>
            <TextError>{Resource.NotReadableError.j001}</TextError>
            <TextError>{Resource.NotReadableError.j002}</TextError>
            <TextError>
              {Resource.NotReadableError.j003}
              <Link onClick={() => window.location.reload()}>
                {Resource.NotReadableError.link}
              </Link>
              {Resource.NotReadableError.j004}
            </TextError>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(DoctorDeviceStatusDialog);
