import React, { useEffect } from "react";
import styled from "styled-components";

const ImageStyle = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
`;

const ThumbnailItem = React.memo((props) => {
  const { src, alt, onClick } = props;
  const objectURL = URL.createObjectURL(src);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(objectURL);
    };
  });

  return <ImageStyle onClick={onClick} src={objectURL} alt={alt} />;
});

export default ThumbnailItem;
