import React, { useState } from "react";
import styled from "styled-components";
import FontPError from "../../atoms/fonts/FontPError";
import {
  toHalfWidthNumbers,
  removeInvalidZeros,
} from "../../../Utils/DisplayLogic";

const Container = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  grid-gap: 20px;
  border-top: #ccc solid 1px;
`;

const TitleItem = styled.div`
  background-color: #eee;
  padding: 8px 10px 8px 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #808080;
  font-weight: bold;
  text-align: left;
  white-space: pre-wrap;
`;
const ContentItem = styled.div`
  padding: 8px 20px 8px 0px;
  font-size: 14px;
  color: #393939;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  word-break: break-word;
  white-space: pre-wrap;
`;

const FontPValidateError = styled(FontPError)`
  margin: auto;
  color: #ef5e5e;
  font-weight: bold;
`;

const MedicalFeeNote = styled.span`
  margin-left: 15px;
  font-family: Hiragino Kaku Gothic ProN;
  font-size: 11px;
  text-align: left;
  letter-spacing: 0px;
  color: #808080;
  opacity: 1;
`;

const InputFee = styled.input`
  background-color: #eee;
  width: 194px;
  height: 34px;
  padding: 5px 10px;
  font-family: inherit;
  border: solid 1px #eee;
  font-size: 14px;
  -webkit-appearance: none;
  background: #eeeeee 0% 0% no-repeat padding-box;
  box-shadow: -1px -1px 0px #cccccc;
  border-radius: 3px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #999999;
  }
  color: #393939;
  margin-right: 7px;
  text-align: right;
  &:enabled {
    background: ${(p) => p.theme.color.primaryVariant3};
  }
`;
export const MedicalFeeInput = (props) => {
  const [isFocusInput, setIsFocusInput] = useState(false);

  const formatMedicalExpense = (value) => {
    return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleBlurMedicalFeeValue = (e) => {
    const medicalFeeNumbers = toHalfWidthNumbers(e.target.value);
    const formatValue = removeInvalidZeros(medicalFeeNumbers);

    setIsFocusInput(false);
    props.handleBlurMedicalFee(formatValue);
  };

  const handleFocusMedicalFee = () => {
    setIsFocusInput(true);
  };

  return (
    <Container>
      <TitleItem>{props.title}</TitleItem>
      <ContentItem>
        {props.isValidateError && (
          <FontPValidateError size="s">{props.errMessage}</FontPValidateError>
        )}
        <InputFee
          type="tel"
          placeholder={props.inputFeePlaceholder}
          onChange={props.handleChangeMedicalFee}
          onBlur={handleBlurMedicalFeeValue}
          onFocus={handleFocusMedicalFee}
          value={
            isFocusInput
              ? props.medicalFeeValue
              : formatMedicalExpense(props.medicalFeeValue)
          }
          disabled={props.disabled}
          className={props.hasErrorClass}
        />
        <span>{props.medicalFeeUnit}</span>
        <MedicalFeeNote>{props.medicalFeeNote}</MedicalFeeNote>
      </ContentItem>
    </Container>
  );
};
