import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import StyledHeader from "../../atoms/others/Header";
import HeaderLogo from "../../molecules/header/HeaderLogo";
import UnapprovedTooltip from "../../molecules/header/UnapprovedTooltip";
import HomeTooltip from "../../molecules/header/HomeTooltip";
import CalendarTooltip from "../../molecules/header/CalendarTooltip";
import AppointmentTooltip from "../../molecules/header/AppointmentTooltip";
import PaymentTooltip from "../../molecules/header/PaymentTooltip";
import OtherTooltip from "../../molecules/header/OtherTooltip";
import SiteContext from "../../../contexts/SiteContext";
import { client, GetDoctorAndChooseDoctors } from "../../../graphql";

const HeaderArea = styled.div`
  height: 69px;
`;

export const Header = () => {
  const siteContext = useContext(SiteContext);
  const hospital = siteContext.hospital;
  const [loginDoctorName, setLoginDoctorName] = useState(null);
  const [numberOfUnapprovedAppointment, setNumberOfUnapprovedAppointment] =
    useState(null);

  useEffect(() => {
    //hospitalでログインしているかどうか判定
    if (hospital) getDoctorAndChooseDoctors();
  }, [location.pathname, hospital]);

  const getDoctorAndChooseDoctors = async () => {
    try {
      const { data } = await client.query({
        query: GetDoctorAndChooseDoctors,
      });
      if (data) {
        if (data.doctor) {
          setLoginDoctorName(
            `${data.doctor.familyName} ${data.doctor.givenName}`
          );
        }
        if (data.doctors) {
          const numberOfUnapprovedAppointment = data.doctors.reduce(
            (sum, doctor) =>
              doctor.numberOfUnapprovedAppointment > 0
                ? sum + doctor.numberOfUnapprovedAppointment
                : sum,
            0
          );
          setNumberOfUnapprovedAppointment(numberOfUnapprovedAppointment);
        }
      }
    } catch (error) {
      console.error("getDoctorAndChooseDoctors error:", JSON.stringify(error));
    }
  };

  return (
    <React.Fragment>
      <HeaderArea>
        <StyledHeader>
          <HeaderLogo />
          <HomeTooltip />
          <UnapprovedTooltip
            numberOfUnapprovedAppointment={numberOfUnapprovedAppointment}
          />
          <CalendarTooltip />
          <AppointmentTooltip
            facilityType={
              hospital && hospital.facilityType ? hospital.facilityType : ""
            }
          />
          <PaymentTooltip />
          <OtherTooltip hospital={hospital} loginDoctorName={loginDoctorName} />
        </StyledHeader>
      </HeaderArea>
    </React.Fragment>
  );
};
