import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import HospitalPaymentList from "../../organisms/payment/HospitalPaymentList";
import { Fade, LinearProgress } from "@material-ui/core";
import Heading1 from "../../atoms/headings/Heading1";
import BasicTemplate from "../../templates/BasicTemplate";
import { japaneseList } from "../../../Resources/japaneseList";
import Titles from "../../../Resources/Titles";
import SiteContext from "../../../contexts/SiteContext";
import { HOSPITAL_TYPES } from "../../../Utils/const";

const Resource = japaneseList.Pages.Payment.Payment;

const RootWrapper = styled.div`
  min-width: 100%;
  margin-top: 2px;
  padding-left: 31px;
  padding-right: 31px;
`;

const CustomHeading1 = styled(Heading1)`
  font-size: 20px;
  margin-bottom: 16px;
`;

const PaymentPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const siteContext = useContext(SiteContext);
  const hospital = siteContext.hospital;
  const [appointments, setAppointments] = useState(null);

  useEffect(() => {
    if (appointments) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [appointments]);

  const isCentralClinic = hospital? hospital.hospitalType === HOSPITAL_TYPES.CENTRAL_CLINIC : false ;

  // TODO: move to organisms
  const main = (
    <React.Fragment>
      <RootWrapper>
        <CustomHeading1>{Resource.render.div.h1.j001}</CustomHeading1>
        <HospitalPaymentList
          facilityType={hospital ? hospital.facilityType : ""}
          isCentralClinic = {isCentralClinic}
          setAppointments={setAppointments}
          setIsLoading={setIsLoading}
        />
        <Fade
          in={isLoading}
          style={{ transitionDelay: isLoading ? "800ms" : "0ms" }}
          unmountOnExit
        >
          <LinearProgress />
        </Fade>
      </RootWrapper>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <BasicTemplate main={main} title={Titles.payment} isLoading={isLoading} />
    </React.Fragment>
  );
};

export default PaymentPage;
