import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  dialogTitle: {
    padding: "25px 25px 28px 25px",
    color: theme.color.font,
    boxShadow: `0px 1px 0px ${theme.color.secondary}`,
  },
  dialogActions: {
    padding: "15px 25px",
    margin: "0px",
  },
  buttonOk: {
    color: theme.button.ok.color,
    marginRight: "0px",
    width: "120px",
    height: "40px",
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    borderRadius: "6px",
    opacity: "1",
    margin: "0px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    fontWeight: "bold",
  },
  buttonCancel: {
    color: "white",
    width: "120px",
    height: "40px",
    background: `${theme.button.cancelUploadFile.backgroundColor} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
    borderRadius: "5px",
    opacity: "1",
    margin: "0px 20px 0px 0px",
    "&:hover": {
      backgroundColor: theme.button.cancelUploadFile.hoverColor,
    },
    fontWeight: "bold",
  },
});

const DialogDeleteConfirm = (props) => {
  const {
    isOpen,
    title,
    confirmText,
    cancelText,
    handleConfirm,
    handleCancel,
    classes,
  } = props;

  return (
    <Dialog
      open={isOpen}
      maxWidth={"sm"}
      fullWidth={true}
      onClose={handleCancel}
    >
      <DialogTitle disableTypography={true} className={classes.dialogTitle}>
        {title}
      </DialogTitle>
      <DialogActions className={classes.dialogActions}>
        <Button className={classes.buttonCancel} onClick={handleCancel}>
          {cancelText}
        </Button>
        <Button className={classes.buttonOk} onClick={handleConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DialogDeleteConfirm);
