import gql from "graphql-tag";

export const RegisterRepeatedlyAppointmentSlots = gql`
  mutation registerRepeatedlyAppointmentSlots(
    $from: String!
    $to: String!
    $capacity: Int
    $menuMethod: String!
    $repetition: InputSlotRepetition!
    $suspendsOnConflicts: Boolean
    $medicalDoctorId: String!
  ) {
    registerRepeatedlyAppointmentSlots(
      from: $from
      to: $to
      capacity: $capacity
      menuMethod: $menuMethod
      repetition: $repetition
      suspendsOnConflicts: $suspendsOnConflicts
      doctorId: $medicalDoctorId
    )
  }
`;
