import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import ListAlt from "@material-ui/icons/ListAlt";
import { japaneseList } from "../../../Resources/japaneseList";
import TooltipBase from "./TooltipBase";
import ImportantNoticeContext from "../../../contexts/ImportantNoticeContext";

const Resource = japaneseList.Components.Header;

const AppointmentTooltip = (props) => {
  const { facilityType } = props;

  const history = useHistory();
  const importantNoticeContext = useContext(ImportantNoticeContext);

  const title = facilityType
    ? Resource.render.AppBar.Toolbar.div.Tooltip.appointment[facilityType]
    : [""];
  const pagePath = "/appointment";

  return (
    <React.Fragment>
      <TooltipBase
        title={title}
        onClick={() => {
          history.push(pagePath);
          importantNoticeContext.refreshImportantNotice &&
            importantNoticeContext.refreshImportantNotice();
        }}
        Icon={ListAlt}
        isUnderline={history.location.pathname === pagePath}
      />
    </React.Fragment>
  );
};

export default AppointmentTooltip;
