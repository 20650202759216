import gql from "graphql-tag";

export const ConnectVideoChat = gql`
  mutation ConnectVideoChat(
    $patientId: String!
    $createTime: String!
    $isRestart: Boolean!
  ) {
    connectVideoChat(
      patientId: $patientId
      createTime: $createTime
      isRestart: $isRestart
    ) {
      identity
      room
      token
    }
  }
`;
