import React, { Component } from "react";
import SignIn from "./SignIn";
import NewPassword from "./NewPassword";
import ForgotPassword from "./ForgotPassword";

class Authenticator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      action: "SIGN_IN",
      user: null
    };
  }

  updateState = (action, user) => {
    this.setState({
      action: action,
      user: user ? user : null
    });
  };

  render() {
    const action = this.state.action;
    switch (action) {
      case "SIGN_IN":
        return <SignIn authcb={this.updateState} />;
      case "NEW_PASSWORD_REQUIRED":
        return <NewPassword user={this.state.user} authcb={this.updateState} />;
      case "FORGOT_PASSWORD":
        return <ForgotPassword authcb={this.updateState} />;
      default:
        return <SignIn authcb={this.updateState} />;
    }
  }
}

export default Authenticator;
