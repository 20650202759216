import React from "react";
import styled from "styled-components";
import format from "date-fns/format";
import { ja } from "date-fns/locale";
import { IconImportant } from "../../atoms/icons/IconImportant";
import { IconCategory } from "../../atoms/icons/IconCategory";
import { NewsTitle } from "./NewsTitle";

interface NewsItemProps {
  news: News;
  openNewsDetailDialog: (news: News) => void;
}
interface News {
  id: string;
  title: string;
  activateDatetime: Date;
  isImportant: string;
  category: string;
  content: string;
}
const NewsItemContainer = styled.div`
  margin-left: 10px;
`;

const DisplayDatetime = styled.span`
  margin-right: 4px;
  font-family: Hiragino Kaku Gothic ProN;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
`;
const NewsItemSubContainer = styled.div`
  padding-bottom: 5px;
`;
const LinkA = styled.a`
  color: #393939;
  cursor: pointer;
  font-family: Hiragino Kaku Gothic ProN;
  font-size: 14px;
  font-weight: 250;
  line-height: 21px;
  text-align: left;
  white-space: pre-wrap;
  text-decoration: none;
  [id="news-title"] {
    text-decoration: underline;
  }
  :hover [id="news-title"] {
    text-decoration: none;
  }
`;
export const NewsItem = (props: NewsItemProps) => {
  const { news } = props;
  const Datetime = format(new Date(news.activateDatetime), "yyyy/MM/dd", {
    locale: ja,
  });
  const titleMaxlength = 50;
  return (
    <React.Fragment>
      <LinkA href="#" onClick={(e) => props.openNewsDetailDialog(news)}>
        <NewsItemContainer id="news">
          <NewsItemSubContainer>
            <DisplayDatetime>{Datetime}</DisplayDatetime>
            {news && news.isImportant && <IconImportant />}
            <IconCategory category={news.category} />
          </NewsItemSubContainer>
          <NewsTitle news={news} titleMaxlength={titleMaxlength} />
        </NewsItemContainer>
      </LinkA>
    </React.Fragment>
  );
};
