import React, { Component } from "react";
import { Hub } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core/";

import { client, CancelAppointment } from "../../../graphql";
import { japaneseList } from "../../../Resources/japaneseList";
import * as Sentry from "@sentry/browser";
import { format, parseISO } from "date-fns";
import ja from "date-fns/locale/ja";
import styled from "styled-components";
import PatientInfo from "../dialog/PatientInfo";
import { LoadingScreenForDialog } from "../../molecules/others/LoadingScreenForDialog";

const AppointmentCancelDialogResource =
  japaneseList.Components.Appointment.AppointmentCancelDialog;

const styles = (theme) => ({
  dialogTitle: {
    width: "810px",
    height: "68px",
    margin: "0",
    padding: "24px 0 24px 24px",
    borderBottom: "1px solid #cccccc",
  },
  dialogContent: {
    width: "810px",
    margin: "0",
    padding: "22px 25px 20px 24px",
  },
  dialogActions: {
    width: "810px",
    height: "70px",
    margin: "0",
    padding: "14px 25px 16px 24px",
    borderTop: "1px solid #cccccc",
    "& button": {
      height: "40px",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonOk: {
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
  dialogActionsButtonOk: {
    width: "142px",
    margin: "0",
    padding: "13px 22px",
    borderRadius: "5px",
  },
  buttonCancel: {
    backgroundColor: "#808080",
    boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
  dialogActionsButtonCancel: {
    width: "120px",
    margin: "0 20px 0 0",
    padding: "13px 25px",
    borderRadius: "5px",
  },
});

const DialogTitleText = styled.div`
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
`;

const DialogContentText = styled.div`
  height: 14px;
  line-height: 14px;
  font-size: 14px;
`;

const PatientArea = styled.div`
  margin: 0;
`;

const AppointmentArea = styled.div`
  margin: 21px 0 0 0;
`;

const Container = styled.div`
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  border-top: 1px solid #cccccc;
`;

const Title = styled.div`
  background-color: #eeeeee;
  height: 35px;
  padding-left: 10px;
  color: #808080;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`;

const Content = styled.div`
  height: 35px;
  padding-left: 20px;
  color: #393939;
  text-align: left;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-word;
`;

class AppointmentCancelDialog extends Component {
  state = {
    isSubmitting: false,
    isCompleted: false,
    isLoading: false,
  };

  handleCancelAppointment = async () => {
    this.setState({ isSubmitting: true, isLoading: true });
    try {
      const {
        patient: { patientId },
        createTime,
      } = this.props.appointment;

      const payload = {
        patientId,
        createTime,
      };
      await client.mutate({
        mutation: CancelAppointment,
        variables: payload,
      });
      this.setState({ isCompleted: true });

      Sentry.captureMessage("appointment-delete", Sentry.Severity.Log);
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
      Hub.dispatch(
        "msg",
        {
          event: "open",
          data: {
            message:
              AppointmentCancelDialogResource.handleCancelAppointment.j001,
            level: "error",
          },
        },
        "Appointment"
      );
    }
    this.setState({ isSubmitting: false, isLoading: false });
  };

  handleClose = () => {};

  render() {
    const { classes, appointment } = this.props;
    const { isSubmitting, isCompleted } = this.state;
    const patient = appointment.patient;
    const doctor = appointment.doctor;

    return (
      <div>
        <Dialog
          disableBackdropClick
          open={patient !== null}
          onClose={this.handleClose}
          maxWidth="md"
        >
          <DialogTitle className={classes.dialogTitle}>
            <DialogTitleText>
              {
                AppointmentCancelDialogResource.render.div.Dialog.DialogTitle
                  .j001
              }
            </DialogTitleText>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              {
                AppointmentCancelDialogResource.render.div.Dialog.DialogContent
                  .j001
              }
            </DialogContentText>
            <PatientArea>
              <PatientInfo patient={patient} />
            </PatientArea>
            <AppointmentArea>
              <Container>
                <Item>
                  <Title>
                    {
                      AppointmentCancelDialogResource.render.div.Dialog
                        .Appointment.Container.Item.Title.j001
                    }
                  </Title>
                  <Content>
                    {doctor
                      ? `${doctor.familyName} ${doctor.givenName}`
                      : AppointmentCancelDialogResource.render.div.Dialog
                          .Appointment.Container.Item.Content.j001}
                  </Content>
                </Item>
                <Item>
                  <Title>
                    {
                      AppointmentCancelDialogResource.render.div.Dialog
                        .Appointment.Container.Item.Title.j002
                    }
                  </Title>
                  <Content>
                    {appointment.from
                      ? format(
                          parseISO(appointment.from),
                          "yyyy年MM月dd日(iii) HH:mm",
                          {
                            locale: ja,
                          }
                        )
                      : ""}
                    ～
                    {appointment.to
                      ? format(parseISO(appointment.to), "HH:mm")
                      : ""}
                  </Content>
                </Item>
              </Container>
            </AppointmentArea>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={`${classes.buttonCancel} ${classes.dialogActionsButtonCancel}`}
              onClick={this.props.onDismissDialog}
            >
              {
                AppointmentCancelDialogResource.render.div.Dialog.DialogActions
                  .Button.j001
              }
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={`${classes.buttonOk} ${classes.dialogActionsButtonOk}`}
              disabled={isSubmitting}
              onClick={this.handleCancelAppointment}
            >
              {
                AppointmentCancelDialogResource.render.div.Dialog.DialogActions
                  .Button.j002
              }
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={isCompleted} onClose={this.handleClose}>
          <DialogTitle>
            {AppointmentCancelDialogResource.render.div.Dialog.DialogTitle.j002}
          </DialogTitle>
          <DialogContent>
            {
              AppointmentCancelDialogResource.render.div.Dialog.DialogContent
                .j002
            }
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="raised"
              onClick={this.props.onFinished}
            >
              {
                AppointmentCancelDialogResource.render.div.Dialog.DialogContent
                  .Button.DisplayText.j001
              }
            </Button>
          </DialogActions>
        </Dialog>
        <LoadingScreenForDialog isLoading={this.state.isLoading} />
      </div>
    );
  }
}

export default withStyles(styles)(AppointmentCancelDialog);
