import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { NativeSelect, Button } from "@material-ui/core";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { APPOINTMENT_STATUS, DELIVERY_OPTION } from "../../../Utils/const";
import { japaneseList } from "../../../Resources/japaneseList";

const TblCellDeliveryOptionContent = styled.td(({ isShowDeliveryMethod }) => ({
  textAlign: isShowDeliveryMethod ? "center" : "left",
  padding: "5px 10px",
  borderLeft: " 1px solid #cccccc",
}));

const ButtonCustom = withStyles((theme) => ({
  root: {
    backgroundColor: theme.button.ok.backgroundColor,
    borderRadius: "3px",
    border: 0,
    color: "white",
    height: "24px",
    padding: "5px",
    fontWeight: "bold",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    boxShadow: "0px 1px 0px " + theme.button.ok.shadowColor,
    minWidth: "38px",
    "&:disabled": {
      backgroundColor: "#ddd",
      color: "#fff",
    },
  },
}))(Button);

const NativeSelectCustom = styled(NativeSelect)`
  width: 100%;
  border-radius: 3px;
  border: 1px solid #DDDDDD;
  background: 0% 0% no-repeat padding-box ${(p) =>
    p.theme.color.primaryVariant3};
  select {
    padding: 3px 0 3px 5px;
    width: calc(100% - 5px);
    height: 20px;
    font-size: 14px;
    &:focus {
      background: 0% 0% no-repeat padding-box ${(p) =>
        p.theme.color.primaryVariant3};
    }
  }
  &:hover svg {
      background-color: ${(p) => p.theme.color.primaryVariant};
    }
  }
`;

const ArrowDropDownIcon = withStyles((theme) => ({
  root: {
    backgroundColor: theme.button.ok.backgroundColor,
    width: "26px",
    height: "26px",
    color: "white",
    background: "#27CDCB 0% 0% no-repeat padding-box",
    boxShadow: `0px 1px 0px ${theme.color.shadow}`,
    borderRadius: "0px 3px 3px 0px",
    marginTop: "-2px",
  },
}))(ArrowDropDown);

const DivDeliverySelectOptionDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HospitalPaymentRowDeliveryOptionContent = (props) => {
  const {
    appo,
    handleSelectDeliveryOptions,
    deliverySelectedOption,
    handleOpenDeliveryResetDialog,
    isCentralClinicSpecification,
  } = props;

  const Resource = japaneseList.Components.Payment.HospitalPaymentList;
  const DeliveryResource =
    japaneseList.models.appointment.payment.deliverySelectedOption;

  const existDeliveryOption =
    appo &&
    appo.payment &&
    Number.isInteger(appo.payment.deliverySelectedOption) &&
    appo.payment.deliverySelectedOption;

  const renderDeliveryOpt = () => {
    if (isCentralClinicSpecification) return "-";
    return (
      <>
        {!existDeliveryOption ? (
          <NativeSelectCustom
            disableUnderline
            IconComponent={ArrowDropDownIcon}
            onChange={handleSelectDeliveryOptions}
          >
            <option value={DELIVERY_OPTION.empty}>
              {DeliveryResource[DELIVERY_OPTION.empty]}
            </option>
            <option value={DELIVERY_OPTION.yamato}>
              {DeliveryResource[DELIVERY_OPTION.yamato]}
            </option>
            <option value={DELIVERY_OPTION.csvPost}>
              {DeliveryResource[DELIVERY_OPTION.csvPost]}
            </option>
            <option value={DELIVERY_OPTION.other}>
              {DeliveryResource[DELIVERY_OPTION.other]}
            </option>
            <option value={DELIVERY_OPTION.noDelivery}>
              {DeliveryResource[DELIVERY_OPTION.noDelivery]}
            </option>
          </NativeSelectCustom>
        ) : (
          <DivDeliverySelectOptionDetail>
            {DeliveryResource[deliverySelectedOption] || "-"}
            {deliverySelectedOption !== DELIVERY_OPTION.empty && (
              <ButtonCustom
                variant="raised"
                size="small"
                color="primary"
                disabled={
                  appo.status === APPOINTMENT_STATUS.PAYMENT_COMPLETED ||
                  (appo.delivery && appo.delivery.tradingId)
                }
                onClick={handleOpenDeliveryResetDialog}
              >
                {Resource.render.ResetDeliveryProcessButton.j001}
              </ButtonCustom>
            )}
          </DivDeliverySelectOptionDetail>
        )}
      </>
    );
  };

  return (
    <TblCellDeliveryOptionContent
      isShowDeliveryMethod={isCentralClinicSpecification}
    >
      {renderDeliveryOpt()}
    </TblCellDeliveryOptionContent>
  );
};

export default HospitalPaymentRowDeliveryOptionContent;
