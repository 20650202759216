import React from "react";

const ImportantNoticeContext = React.createContext({
  // 重要なお知らせコンポーネント側で監視して更新トリガを検出するための変数
  signalToRefreshImportantNotice: null,
  // 重要なお知らせを更新するための関数
  refreshImportantNotice: null,
});

export default ImportantNoticeContext;
