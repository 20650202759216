import { useState, useEffect } from 'react';
import { client, FindDocuments } from "../graphql";
import { isLnln } from '../Utils/checkLnln';

export const useDocuments = (hospital) => {
  const [documents, setDocuments] = useState(null);

  const getDocuments = () => {
    const siteType = isLnln() ? 'LNLN' : 'CARADA';
    client.query({
      query: FindDocuments,
      variables: {
        input: {
          siteType: siteType,
        },
      },
    })
    .then(res => {
      setDocuments(res.data.documents);
    })
    .catch(err => {
      console.error(err);
    });
  }

  useEffect(() => {
    if (hospital) {
      getDocuments();
    }
  }, [hospital]);

  return documents;
}