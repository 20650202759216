import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import NumberFormat from "react-number-format";
import { getAge } from "../../../Utils/DisplayLogic";
import { japaneseList } from "../../../Resources/japaneseList";
import Person from "../../../images/account_circle-24px.svg";

const PatientInfoResources = japaneseList.Components.Dialog.PatientInfo;
const fns = require("date-fns");

const styles = (_) => {
  return {
    title: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#393939",
      wordBreak: "break-word",
      whiteSpace: "pre-wrap",
    },
    content: {
      fontSize: "12px",
      color: "#393939",
      wordBreak: "break-word",
      whiteSpace: "pre-wrap",
    },
  };
};

const CustumContainer = styled(Grid)`
  margin: 20px 0;
  height: 42px;
`;

const CustumIcon = styled(Grid)`
  padding: 0px 10px 0px 0px;
`;

const CustumContent = styled(Grid)`
  width: calc(100% - 102px);
  color: #393939;
`;

const Table = styled.table`
  width: 333px;
  border-collapse: collapse;
`;

const Thead = styled.thead`
  background-color: #eeeeee;
  color: #808080;
  text-align: center;
  font-weight: bold;
  border-top: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
`;

const Th = styled.td`
  height: 22px;
  font-size: 12px;
  border-right: 1px solid #cccccc;
`;

const Tbody = styled.tbody`
  text-align: center;
  font-size: 14px;
  color: #393939;
`;

const Tr = styled.tr``;

const Td = styled.td`
  height: 34px;
  padding: 5px 10px;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
`;

const PatientInfo = (props) => {
  const { classes, patient } = props;

  return (
    <div>
      <CustumContainer container wrap="nowrap">
        <CustumIcon item>
          <img src={Person} alt="" />
        </CustumIcon>
        <CustumContent item xs>
          <Typography className={classes.title}>
            {patient.familyName} {patient.givenName}
          </Typography>
          <Typography className={classes.content}>
            {patient.familyNameKana} {patient.givenNameKana}
          </Typography>
        </CustumContent>
      </CustumContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>{PatientInfoResources.Table.Thead.Tr.Th.j001}</Th>
            <Th>{PatientInfoResources.Table.Thead.Tr.Th.j002}</Th>
            <Th>{PatientInfoResources.Table.Thead.Tr.Th.j003}</Th>
            <Th>{PatientInfoResources.Table.Thead.Tr.Th.j004}</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              {patient.birthdate
                ? fns.format(new Date(patient.birthdate), "yyyy/MM/dd")
                : ""}
            </Td>
            <Td>{patient.birthdate ? getAge(patient.birthdate) : " "}</Td>
            <Td>{patient.gender}</Td>
            <Td>
              <NumberFormat
                value={patient.phoneNumber}
                displayType={"text"}
                format="###-####-####"
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(PatientInfo);
