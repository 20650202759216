import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { Operator } from "../../atoms/others/Operator";
import { OperationTime } from "../../../Utils/OperationTime";

const Resource = japaneseList.molecules.operationTypes.UpdateStatus;
const AppointmentStatusResource =
  japaneseList.molecules.operationTypeRemarks.AppointmentStatus;

const Tr = styled.tr`
  background: #fafafa 0% 0% no-repeat padding-box;
`;

const Td = styled.td`
  border-right: 1px #cccccc solid;
  font-size: 12px;
  white-space: pre-wrap;
  padding: 8px 10px;
`;

const OperationContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 160px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

export const UpdateStatus = ({ key, operationHistory }) => {
  const operationHistoryContent =
    Resource.j002 +
    Resource.j003 +
    Resource.j001 +
    AppointmentStatusResource[operationHistory.operationDetail.before.status] +
    Resource.j004 +
    AppointmentStatusResource[operationHistory.operationDetail.after.status];
  return (
    <React.Fragment>
      <Tr key={key}>
        <Td>
          <OperationTime operationHistory={operationHistory} />
        </Td>
        <Td>
          <Operator operationHistory={operationHistory} />
        </Td>
        <Td>
          <OperationContent>{operationHistoryContent}</OperationContent>
        </Td>
      </Tr>
    </React.Fragment>
  );
};
