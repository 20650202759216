import gql from "graphql-tag";

export const RegisterServiceLog = gql`
  mutation RegisterServiceLog(
    $eventType: Int!
    $executorType: Int!
    $isSucceeded: Boolean!
    $contents: [Contents]
    $resourceType: String!
  ) {
    registerServiceLog(
      eventType: $eventType
      executorType: $executorType
      isSucceeded: $isSucceeded
      contents: $contents
      resourceType: $resourceType
    )
  }
`;
