import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { japaneseList } from "../../../Resources/japaneseList";
import * as Sentry from "@sentry/browser";

const SystemErrorResource = japaneseList.Pages.SystemError;
const styles = (theme) => {
  return {
    title: {
      color: theme.font.color,
      fontSize: "20px",
      marginTop: "100px",
      fontWeight: "bold",
      marginBottom: theme.spacing.unit * 5,
    },
    subtitle: {
      color: theme.font.color,
      fontSize: "14px",
      fontWeight: "bold",
    },
  };
};

const NotAccess = ({ classes }) => {
  Sentry.captureMessage("display-not-access-page", Sentry.Severity.Log);
  return (
    <section>
      <Typography variant="display1" align="center" className={classes.title}>
        {SystemErrorResource.render.section.Typography.j001}
      </Typography>
      <Typography align="center">
        {SystemErrorResource.render.section.Typography.j002}
      </Typography>
      <Typography align="center">
        {SystemErrorResource.render.section.Typography.j003}
      </Typography>
      <Typography align="center">
        {SystemErrorResource.render.section.Typography.j004}
      </Typography>
      <br />
      <Typography align="center" className={classes.subtitle}>
        {SystemErrorResource.render.section.Typography.j005}
      </Typography>
      <Typography align="center">
        {SystemErrorResource.render.section.Typography.j006}
      </Typography>
    </section>
  );
};
export default withStyles(styles)(NotAccess);
