import { useEffect } from "react";

interface ScrollToAnchorProps {
  id: string;
}
export const ScrollToAnchor = (props: ScrollToAnchorProps) => {
  const { id } = props;
  const headerOffset = 78;
  useEffect(() => {
    const element = document.getElementById(id);

    if (element) {
      const rect = element.getBoundingClientRect();
      const offsetPosition = rect.top - headerOffset;
      window.scrollTo({ top: offsetPosition, behavior: "smooth" })
    }
  }, [id]);

  return null;
};