import React from "react";
import styled from "styled-components";
import { formatDate } from "../../../../Utils/DateUtil";
import {
  getAge,
  getPhoneNumberWithHyphen,
} from "../../../../Utils/DisplayLogic";
import { japaneseList } from "../../../../Resources/japaneseList";
const InterviewSheetAnswerHeaderResource =
  japaneseList.Pages.Patient.PrintInterviewSheetAnswerHeader;

const TitleRight = styled.div`
  margin-top: 40px;
  font-size: 14px;
  text-align: right;
`;

const Title = styled.div`
  margin-top: 10px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 27px;
  font-weight: 600;
`;

const AppointmentInfo = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
`;

const UserInfo = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

const Table = styled.table`
  width: 746px;
  margin: 0 -1px 30px -1px;
  border-collapse: collapse;
  font-family: ヒラギノ角ゴ ProN;
`;

const TableTitleNameCell = styled.td`
  width: 300px;
  height: 28px;
  font-size: 12px;
  border: 1px solid;
  text-align: center;
  font-weight: 600;
`;

const TableTitleBirthCell = styled.td`
  width: 150px;
  font-size: 12px;
  border: 1px solid;
  border-left: 0;
  text-align: center;
  font-weight: 600;
`;

const TableTitleAgeSexCell = styled.td`
  width: 50px;
  font-size: 12px;
  border: 1px solid;
  border-left: 0;
  text-align: center;
  font-weight: 600;
`;

const TableTitleTelCell = styled.td`
  width: 190px;
  font-size: 12px;
  border: 1px solid;
  border-left: 0;
  text-align: center;
  font-weight: 600;
`;

const TableLeftContentCell = styled.td`
  border: 1px solid;
  border-top: 0;
  text-align: left;
  font-size: 16px;
`;

const TableContentCell = styled.td`
  border: 1px solid;
  border-left: 0;
  border-top: 0;
  text-align: center;
  font-size: 16px;
`;

const KanaName = styled.div`
  margin-left: 20px;
  margin-top: 2px;
  font-size: 11px;
`;

const KanjiName = styled.div`
  margin-bottom: 5px;
  margin-left: 20px;
  font-size: 16px;
`;

export const InterviewSheetAnswerHeader = (props) => {
  const { patient, contents } = props;
  const {
    familyNameKana,
    givenNameKana,
    familyName,
    givenName,
    birthdate,
    gender,
    phoneNumber,
  } = patient;
  const birthdateWithSlash = birthdate
    ? formatDate(birthdate, InterviewSheetAnswerHeaderResource.j009)
    : "";
  const age = birthdate ? getAge(birthdate) : "";
  const phoneNumberHyphen = getPhoneNumberWithHyphen(phoneNumber);

  return (
    <React.Fragment>
      <tr>
        <td colSpan="2">
          <TitleRight>{InterviewSheetAnswerHeaderResource.j001}</TitleRight>
          <Title>{InterviewSheetAnswerHeaderResource.j002}</Title>
          <AppointmentInfo>
            <UserInfo>{contents.displayName}</UserInfo>
            <div>
              {InterviewSheetAnswerHeaderResource.j003}
              {contents.appointmentDate}
            </div>
          </AppointmentInfo>
          <Table cellSpacing="0">
            <tbody>
              <tr>
                <TableTitleNameCell>
                  {InterviewSheetAnswerHeaderResource.j004}
                </TableTitleNameCell>
                <TableTitleBirthCell>
                  {InterviewSheetAnswerHeaderResource.j005}
                </TableTitleBirthCell>
                <TableTitleAgeSexCell>
                  {InterviewSheetAnswerHeaderResource.j006}
                </TableTitleAgeSexCell>
                <TableTitleAgeSexCell>
                  {InterviewSheetAnswerHeaderResource.j007}
                </TableTitleAgeSexCell>
                <TableTitleTelCell>
                  {InterviewSheetAnswerHeaderResource.j008}
                </TableTitleTelCell>
              </tr>
              <tr>
                <TableLeftContentCell>
                  <KanaName>
                    {familyNameKana}
                    {givenNameKana}
                  </KanaName>
                  <KanjiName>
                    {familyName} {givenName}
                  </KanjiName>
                </TableLeftContentCell>
                <TableContentCell>{birthdateWithSlash}</TableContentCell>
                <TableContentCell>{age}</TableContentCell>
                <TableContentCell>{gender}</TableContentCell>
                <TableContentCell>{phoneNumberHyphen}</TableContentCell>
              </tr>
            </tbody>
          </Table>
        </td>
      </tr>
    </React.Fragment>
  );
};
