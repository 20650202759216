import gql from "graphql-tag";

export const GetConsentStatus = gql`
  query GetConsentStatus($patientId: String!) {
    consentStatus(patientId: $patientId) {
      consentStatus
      consentExpirationDate
    }
  }
`;
