// --------------------------------------------------
//
// file upload content
//
// --------------------------------------------------

// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

// personal modules
// --------------------------------------------------
import LinkA from "../../../atoms/links/LinkA";
import { isLnln } from "../../../../Utils/checkLnln";
import IconFile from "../../../../images/carada/icon/icon-file-blue.svg";
import IconFileLnln from "../../../../images/lnln/icon/icon-file-blue.svg";
import DialogAccessFileError from "../../../molecules/dialogs/DialogFileAccessError";
import { FileUploadUtility } from "../../../../Utils/FileUpload";
import { japaneseList } from "../../../../Resources/japaneseList";

const Resources =
  japaneseList.Components.Appointment.FileuploadConfirmDialogFile;

const icon = isLnln() ? IconFileLnln : IconFile;

let _ = require("lodash");

const CustomLinkA = styled(LinkA)`
  display: flex;
  color: #2563c0;
  text-decoration: underline;
`;
const Img = styled.img`
  width: 20px;
  margin-right: 5px;
`;
// style
// --------------------------------------------------
const styles = theme => ({});

// class define
// --------------------------------------------------
class FileuploadConfirmDialogFile extends Component {
  // default state
  // --------------------------------------------------
  static defaultProps = {
    fileList: []
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpenErrorDialog: false,
    };
  }

  // handle functions
  // --------------------------------------------------

  getFileName = item => {
    let file = {
      string: "",
      extension: ""
    };
    if (!_.isUndefined(item.file.name)) {
      // item.file.name.split(".");
      file.extension = _.last(item.file.name.split("."));
      file.string = item.file.name.slice(
        0,
        item.file.name.length - (file.extension.length + 1)
      );
      if (!_.isEmpty(item.niceName.trim())) {
        file.string = item.niceName.trim();
      }
    }
    return file;
  };

  openErrorPopUp = () => {
    this.setState({
      isOpenErrorDialog: true,
    });
  };

  openLocalFileOnNewTab = (item, url, newWindow) => {
    let html = `<html><body style='text-align: center;'><img src='${url}' alt='${item.string}'/></body></html>`;
    if (item.file.type === "application/pdf") {
      html = `<html><body style='margin:0!important'><object type="application/x-pdf" data ="${url}" id="pdfViewer"><embed width="100%" height="100%" src="${url}" type="application/pdf" /></object></body></html>`;
    }
    newWindow.document.write(html);
  };

  openFile = (item, url, fileName) => {
    const dateTime = Date.now();
    let newWindow = window.open(
      "",
      `Preview Window - ${fileName.string} - ${dateTime}}`,
      ""
    );
    FileUploadUtility.checkExistFile(item.file).then((isExistFile) => {
      if (isExistFile) {
        this.openLocalFileOnNewTab(item, url, newWindow);
      } else {
        this.openErrorPopUp();
        newWindow.close();
      }
    });
  };

  closeErrorPopup = () => {
    this.setState({
      isOpenErrorDialog: false,
    });
  }

  // render
  // --------------------------------------------------
  //href={item.fileData}
  render() {
    const { fileList } = this.props;
    return fileList.map((item, index) => {
      if (
        !_.isUndefined(item) &&
        !_.isUndefined(item.file) &&
        !_.isUndefined(item.file.name)
      ) {
        let fileName = this.getFileName(item);
        let url = item.file && URL.createObjectURL(item.file);
        return (
          <React.Fragment>
            <Grid container item xs={12} key={index}>
              <CustomLinkA onClick={() => this.openFile(item, url, fileName)}>
                <Img src={icon} />
                {fileName.string + "." + fileName.extension}
              </CustomLinkA>
            </Grid>
            <DialogAccessFileError
              isOpen={this.state.isOpenErrorDialog}
              title={Resources.FileError.j001}
              handleClose={this.closeErrorPopup}
              cancelText={Resources.FileError.j002}
            />
          </React.Fragment>
        );
      }
      return '';
    });
  }
}

export default withStyles(styles)(FileuploadConfirmDialogFile);
