import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Card,
  CardActions,
  CardContent
} from "@material-ui/core";
import LoadingScreen from "../../molecules/others/LoadingScreen";
import RecommendTerminal from "../../organisms/common/RecommendTerminal";
import { japaneseList } from "../../../Resources/japaneseList";
import Heading1 from "../../atoms/headings/Heading1";
import IconDecorative from "../../atoms/icons/IconDecorative";

const ContactResource = japaneseList.Pages.Contact;

const styles = theme => {
  return {
    root: {
      width: "810px",
      margin: theme.spacing.unit * 5,
      marginTop: "00px",
      fontFamily:
        "'Avenir','Helvetica Neue','Helvetica','Arial','Hiragino Sans','ヒラギノ角ゴシック',YuGothic,'Yu Gothic','メイリオ', Meiryo,'ＭＳ Ｐゴシック','MS PGothic'",
    },
    title: {
      margin: "34px 26px 40px",
      fontWeight: "bold",
      color: "#202020",
    },
    subtitle: {
      margin: "50px 26px 0px",
      textAlign: "left",
      fontWeight: "bold",
    },
    info: {
      margin: "40px 26px",
      textAlign: "left",
    },
    extra: {
      marginBottom: "25px",
      color: theme.font.accent,
    },
    action: {
      margin: "auto",
      minWidth: theme.spacing.unit * 15,
    },
    contactInfo: {
      margin: "0px 0px",
      color: "#393939",
      maxWidth: "500px",
      textAlign: "left",
      paddingTop: "15px",
      paddingLeft: "15px",
      paddingRight: "15px",
    },
    contactborder: {
      margin: "0px 50px 26px",
      color: "#393939",
      maxWidth: "708px",
      minHeight: "370px",
      border: "1px solid #cccccc",
      paddingTop: "15px",
      paddingLeft: "15px",
    },
    contactInfoTitle: {
      fontWeight: "bold",
    },
    contactInfoContent: {
      color: "#393939",
      marginTop: "1px",
      marginBottom: "1px",
    },
    setBottomPadding: {
      paddingBottom: "5px",
    }
  };
};

const generateMessage = (doctor, hospital) => {
  const generateMessageResource =
    ContactResource.generateMessageResource;
  const newLineCode = "%0D%0A";
  const userAgent = window.navigator.userAgent;
  let hospitalId = ""
  let doctorId = ""
  if (hospital !== null) {
    hospitalId = hospital.hospitalId;
  }
  if (doctor !== null) {
    doctorId = doctor.doctorId;
  }
  const msg =
    "mailto:telemedicine_doctor@caradamedica.co.jp?subject=" +
    generateMessageResource.j001 +
    "&body=" +
    generateMessageResource.j002 + hospitalId +
    newLineCode +
    generateMessageResource.j003 + doctorId +
    newLineCode +
    userAgent +
    newLineCode +
    newLineCode +
    generateMessageResource.j004 +
    newLineCode +
    "--------------------------" +
    newLineCode +
    generateMessageResource.j005 +
    newLineCode +
    "--------------------------" +
    newLineCode +
    generateMessageResource.j006 +
    newLineCode +
    newLineCode +
    newLineCode +
    generateMessageResource.j007 +
    newLineCode +
    newLineCode +
    newLineCode +
    generateMessageResource.j008 +
    newLineCode +
    newLineCode +
    newLineCode +
    generateMessageResource.j009 +
    newLineCode +
    newLineCode +
    newLineCode +
    generateMessageResource.j010 +
    newLineCode +
    newLineCode;

  return msg;
};

export const Contact = ({ doctor, hospital, classes }) => {
  const msg = generateMessage(doctor, hospital);

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <CardContent className={classes.setBottomPadding}>
          <Heading1 className={classes.title}>
            {ContactResource.render.Card.CardContent.Typography.j001}
          </Heading1>
          <Typography variant="body2" className={classes.info}>
            {ContactResource.render.Card.CardContent.Typography.j002}
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            <IconDecorative>▼</IconDecorative>
            {japaneseList.Components.RecommendTerminal.j001}
          </Typography>
        </CardContent>
        <RecommendTerminal />
        <CardContent className={classes.contactborder}>
          <CardContent className={classes.contactInfo}>
            <Typography className={classes.contactInfoTitle}>
              {ContactResource.render.Card.CardContent.Typography.j005}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j006}
            </Typography>
            <br />
            <Typography className={classes.contactInfoTitle}>
              {ContactResource.render.Card.CardContent.Typography.j007}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j008}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j009}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j010}
            </Typography>
            <br />
            <Typography className={classes.contactInfoTitle}>
              {ContactResource.render.Card.CardContent.Typography.j011}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j012}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j013}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j014}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j015}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j016}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j017}
            </Typography>
          </CardContent>
        </CardContent>
        <CardActions>
          <Button
            className={classes.action}
            size="large"
            disableRipple
            color="primary"
            variant="raised"
            href={msg}
          >
            {ContactResource.render.Card.CardActions.Button.j001}
          </Button>
        </CardActions>
        <CardContent>
          <Typography
            variant="caption"
            className={classes.extra}
            gutterBottom
            align="center"
          >
            {ContactResource.render.Card.CardContent.Typography.j004}
          </Typography>
        </CardContent>
      </Card>
      <LoadingScreen isLoading={false} />
    </React.Fragment>
  );
};

export default withStyles(styles)(Contact);