import gql from "graphql-tag";

export const GetDoctor = gql`
  query GetDoctor {
    doctor {
      doctorId
      token
      familyName
      givenName
      familyNameKana
      givenNameKana
      numberOfUnapprovedAppointment
    }
  }
`;