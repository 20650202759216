import gql from "graphql-tag";

export const ListAppointmentsInMedical = gql`
  query ListAppointmentsInMedical(
    $patientId: String!
    $isDispalyInMedical: Boolean
    $status: [String!]
  ) {
    appointments(
      patientId: $patientId
      isDispalyInMedical: $isDispalyInMedical
      status: $status
    ) {
      items {
        patient {
          patientId
        }
        createTime
        status
        from
        content
        contentType
        companyName
        interviewSheetAnswerStatus
        menu {
          medicalMethod
          medicalMenu
        }
        medicineDelivery {
          accountStatus
          deliveryMethod
          deliveryHospitalId
          name
          tel
          fax
          postalCode
          prefectureCode
          addressCity
          addressStreetNumber
          addressBuilding
        }
      }
    }
  }
`;
