import { initializeApp, getApps } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

function initFCM() {
  if (getApps().length !== 0) return;

  const firebaseApp = initializeApp({
    messagingSenderId: process.env.REACT_APP_FCM_SENDER_ID,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  });
  const messaging = getMessaging(firebaseApp);
  onMessage(messaging, function (payload) {
    console.log("Message received. ", payload);
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((regs) => {
        regs[0].showNotification(payload.data.title, {
          body: payload.data.body,
          icon: payload.data.icon,
          data: payload.data.url,
        });
      });
    }
  });
}

export { initFCM };
