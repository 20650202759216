import React from "react";
import styled from "styled-components";
import IconInsurance from "../../atoms/icons/IconInsurance";
import IconNotInsurance from "../../atoms/icons/IconNotInsurance";
import { japaneseList } from "../../../Resources/japaneseList";
import { PROGRAM_TYPE } from "../../../Utils/const";

const TblCellMenu = styled.td`
  text-align: left;
  padding: 5px 10px;
  border-left: 1px solid #cccccc;
  min-width: 260px;
`;

const Insurance = styled.div`
  white-space: nowrap;
`;

const StyledIconInsurance = styled(IconInsurance)`
  font-size: 10px;
  border: 1px solid ${(p) => p.theme.color.primary};
`;

const StyledIconNotInsurance = styled(IconNotInsurance)`
  font-size: 10px;
  border: 1px solid ${(p) => p.theme.color.notInsurance};
`;

const HospitalPaymentRowMenu = (props) => {
  const { appo } = props;

  const Resource = japaneseList.Components.Payment.HospitalPaymentList;
  const InsuranceResource = japaneseList.insurance;

  return (
    <TblCellMenu>
      {appo.menu && appo.menu.medicalMenu === PROGRAM_TYPE.WELFARE_PROGRAM ? (
        <React.Fragment>
          {Resource.render.examinationWelfare.j001}
          <br />
          {Resource.render.examinationWelfare.j002}
        </React.Fragment>
      ) : (
        appo.menu.displayName
      )}
      <Insurance>
        {Boolean(appo.menu.supportsInsuranceTreatment) ? (
          <StyledIconInsurance>{InsuranceResource.ok}</StyledIconInsurance>
        ) : (
          <StyledIconNotInsurance>
            {InsuranceResource.no}
          </StyledIconNotInsurance>
        )}
      </Insurance>
    </TblCellMenu>
  );
};

export default HospitalPaymentRowMenu;
