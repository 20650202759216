import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { format, parseISO } from "date-fns";
import classNames from "classnames";
import { Card, CardContent } from "@material-ui/core";

import DialogInsuranceCardCaution from "../../molecules/dialogs/DialogInsuranceCardCaution";
import DialogInsuranceCardConfirm from "../../molecules/dialogs/DialogInsuranceCardConfirm";
import { japaneseList } from "../../../Resources/japaneseList";
import {
  client,
  GenerateGetInsuranceCardPresignedUrl,
  GetAppointmentPatientInsurance,
} from "../../../graphql";
import { ja } from "date-fns/locale";
import { patientDetail } from "../../../lib/window/PatientDetail";

const MedicalSideAreaResource = japaneseList.Pages.Medical.MedicalSideArea;
const styles = (theme) => {
  return {
    title: {
      margin: 1,
      fontWeight: "bold",
      marginTop: "15px",
    },
    cardContent: {
      height: "186px",
      "&:last-child": {
        padding: "20px",
      },
    },
    medicalInfoCardArea: {
      fontSize: 14,
      flex: "0 1 50px",
    },
    nameFurigana: {
      fontSize: 10,
    },
    nameDiv: {
      cursor: "pointer",
      display: "inline-block",
      color: "#2563c0",
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none",
      },
    },
    insuranceCardDiv: {
      display: "inline-block",
      float: "right",
      color: "#2563c0",
      cursor: "pointer",
      textDecoration: "underline",
    },
    noInsuranceCardDiv: {
      display: "inline-block",
      float: "right",
      color: "#ef5e5e",
      cursor: "pointer",
      textDecoration: "underline",
    },
    insuranceButton: {
      backgroundColor: theme.button.ok.backgroundColor,
      minWidth: "38px",
      "&:hover": {
        backgroundColor: theme.button.ok.backgroundColorHover,
      },
      boxShadow: "0px 1px 0px " + theme.button.ok.shadowColor,
    },
    noInsuranceButton: {
      backgroundColor: theme.color.notInsurance,
      minWidth: "38px",
      "&:hover": {
        backgroundColor: "#FA7D7D",
      },
      boxShadow: "0px 1px 0px #BF3939",
    },
    buttonIconOnlyInsurance: {
      minWidth: "0px",
      minHeight: "0px",
      maxWidth: "35px !important",
      maxHeight: "35px !important",
      padding: "2px",
    },
    patientName: {
      fontSize: "14px",
      marginTop: "0px",
      display: "flex",
    },
    titleName: {
      fontSize: "14px",
      marginTop: "14px",
      display: "flex",
    },
    titleContext: {
      fontWeight: "bold",
      width: "70px",
    },
    dataTitle: {
      marginTop: "0px",
      fontWeight: "bold",
      width: "70px",
    },
  };
};

class MedicalSideArea extends Component {
  static defaultProps = {
    appointment: {},
    name: "",
    furigana: "",
    classes: {},
    currentStatus: "",
    patientStandbyStatus: {},
    doctorStandbyStatus: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpenInsuranceCardConfirmDialog: false,
      isOpenInsuranceCardCautionDialog: false,
      filePath: "",
      fileName: "",
    };
  }

  // insurance card
  // --------------------------------------------------
  handleClickInsuranceCard = async (appo) => {
    this.setState({
      isOpenInsuranceCardConfirmDialog: true,
      filePath: "",
    });
    const { appointment } = this.props;
    const { data } = await client.query({
      query: GetAppointmentPatientInsurance,
      variables: {
        patientId: appointment.patient.patientId,
        createTime: appointment.createTime,
      },
    });

    let filePath =
      (data &&
        data.appointment.patient.insuranceCard &&
        data.appointment.patient.insuranceCard.filePath) ||
      "";
    if (!filePath) {
      console.log("no have file path");
      return;
    }
    const response = await client.mutate({
      mutation: GenerateGetInsuranceCardPresignedUrl,
      variables: { filePath },
    });

    Promise.all([data, response]).then((value) => {
      this.setState({
        fileName: filePath,
        filePath:
          value[1].data.generateGetInsuranceCardPresignedUrl.getSignedUrl,
      });
    });
  };
  handleInsuranceCardConfirmDialog = (e) => {
    this.setState({
      isOpenInsuranceCardConfirmDialog: false,
    });
  };

  handleInsuranceCardConfirmDialogButton = (e) => {
    this.setState({
      isOpenInsuranceCardConfirmDialog: false,
    });
  };

  // insurance card caution
  // --------------------------------------------------
  handleClickInsuranceCardCaution = () => {
    this.setState({
      isOpenInsuranceCardCautionDialog: true,
    });
  };
  handleInsuranceCardCautionDialog = (e) => {
    this.setState({
      isOpenInsuranceCardCautionDialog: false,
    });
  };

  handleInsuranceCardCautionDialogButton = (e) => {
    this.setState({
      isOpenInsuranceCardCautionDialog: false,
    });
  };

  render() {
    const { appointment, name, furigana, insuranceCardFile, classes } =
      this.props;
    const {
      isOpenInsuranceCardConfirmDialog,
      isOpenInsuranceCardCautionDialog,
    } = this.state;

    if (!this.props.appointment) {
      return null;
    }

    return (
      <div>
        <Card className={classNames(classes.card, classes.medicalInfoCardArea)}>
          {appointment && (
            <CardContent className={classes.cardContent}>
              <div className={classes.patientName}>
                <div className={classes.titleContext}>
                  {
                    MedicalSideAreaResource.render.div.Card.CardContent
                      .Typography.j002
                  }
                </div>
                <div
                  className={classes.nameDiv}
                  onClick={() =>
                    patientDetail.openPatientDetailWindow(
                      appointment.patient.patientId
                    )
                  }
                >
                  {name}
                  <div className={classes.nameFurigana}>{furigana}</div>
                </div>
              </div>
              <div className={classes.titleName}>
                <div className={classes.dataTitle}>
                  {
                    MedicalSideAreaResource.render.div.Card.CardContent
                      .Typography.j001
                  }
                </div>
                {format(parseISO(appointment.from), "yyyy年M月d日 (EE)", {
                  locale: ja,
                })}
                <br />
                {format(parseISO(appointment.from), "HH:mm")}～
                {format(parseISO(appointment.to), "HH:mm")}
              </div>
              <div className={classes.titleName}>
                <div className={classes.titleContext}>
                  {
                    MedicalSideAreaResource.render.div.Card.CardContent
                      .Typography.j003
                  }
                </div>

                {appointment.menu.supportsInsuranceTreatment ? (
                  <React.Fragment>
                    {appointment.patient.insuranceCard &&
                    appointment.patient.insuranceCard.filePath !== null ? (
                      <div
                        onClick={this.handleClickInsuranceCard}
                        className={classes.insuranceCardDiv}
                      >
                        {
                          MedicalSideAreaResource.render.div.Card.CardContent
                            .Typography.j004
                        }
                      </div>
                    ) : (
                      <div
                        onClick={this.handleClickInsuranceCardCaution}
                        className={classes.noInsuranceCardDiv}
                      >
                        {
                          MedicalSideAreaResource.render.div.Card.CardContent
                            .Typography.j005
                        }
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  MedicalSideAreaResource.render.div.Card.CardContent.Typography
                    .j006
                )}
              </div>
            </CardContent>
          )}
        </Card>
        <DialogInsuranceCardConfirm
          open={isOpenInsuranceCardConfirmDialog}
          file={insuranceCardFile}
          filePath={this.state.filePath}
          fileName={this.state.fileName}
          onClose={this.handleInsuranceCardConfirmDialog}
          handleClickButton={this.handleInsuranceCardConfirmDialogButton}
        />
        <DialogInsuranceCardCaution
          open={isOpenInsuranceCardCautionDialog}
          onClose={this.handleInsuranceCardCautionDialog}
          handleClickButton={this.handleInsuranceCardCautionDialogButton}
        />
      </div>
    );
  }
}

export default withStyles(styles)(MedicalSideArea);
