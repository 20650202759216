// --------------------------------------------------
//
// file upload confirm
//
// --------------------------------------------------

// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

// personal modules
// --------------------------------------------------
import FileuploadConfirmDialogContent from "./FileuploadConfirmDialogContent";
import FontSpan from "../../../atoms/fonts/FontSpan";
import LinkA from "../../../atoms/links/LinkA";
import { japaneseList } from "../../../../Resources/japaneseList";
const Resources = japaneseList.Components.Appointment.FileuploadConfirmDialog;

// style
// --------------------------------------------------
const styles = (theme) => ({
  dialogTitle: {
    width: "810px",
    margin: "0",
    padding: "24px 0 24px 24px",
    borderBottom: "1px solid #cccccc",
  },

  dialogContent: {
    width: "810px",
    margin: "0",
    padding: "20px 25px 20px 24px",
  },

  dialogActions: {
    width: "810px",
    margin: "0",
    padding: "14px 25px 16px 24px",
    borderTop: "1px solid #cccccc",
    "& button": {
      height: "40px",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "bold",
    },
  },

  buttonOk: {
    color: theme.button.ok.color,
    margin: "0 0 0 20px",
    width: "120px",
    height: "40px",
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    borderRadius: "6px",
    opacity: "1",
    fontWeight: "bold",
    backgroundColor: theme.button.ok.backgroundColor,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },

  buttonCancel: {
    color: "#FFFFFF",
    width: "120px",
    height: "40px",
    boxShadow: "0px 2px 0px #505050",
    borderRadius: "6px",
    opacity: "1",
    margin: "0 0 0 20px",
    backgroundColor: "#808080",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
});

const StyledFontSpan = styled(FontSpan)`
  font-size: 20px;
  font-weight: bold;
  line-height: 0;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledLinkA = styled(LinkA)`
  color: #2563c0;
  font-size: 14px;
  align-self: center;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

const ButtonContainer = styled.div`
  font-size: 14px;
`;

// class define
// --------------------------------------------------
class FileuploadConfirmDialog extends Component {
  // default state
  // --------------------------------------------------
  static defaultProps = {
    open: false,
    onClose: function () {},
    title: Resources.title,
    maxWidth: "md",
    buttonOkText: Resources.buttonOk,
    handleClickOk: function () {},
    buttonCancelText: Resources.buttonCancel,
    handleClickCancel: function () {},
  };

  // render
  // --------------------------------------------------
  render() {
    const {
      newFileList,
      classes,
      open,
      onClose,
      title,
      maxWidth,
      buttonOkText,
      handleClickOk,
      buttonCancelText,
      handleClickCancel,
      alreadyConfirm,
      handleBack,
    } = this.props;
    return (
      <Dialog
        disableBackdropClick={true}
        open={open}
        onClose={onClose}
        maxWidth={maxWidth}
      >
        <DialogTitle className={classes.dialogTitle}>
          <StyledFontSpan>{title}</StyledFontSpan>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FileuploadConfirmDialogContent fileList={newFileList} />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <ActionContainer>
            <StyledLinkA onClick={handleBack}>
              {Resources.backButton}
            </StyledLinkA>
            <ButtonContainer>
              {alreadyConfirm ? (
                <Button className={classes.buttonCancel}>
                  {buttonCancelText}
                </Button>
              ) : (
                <Button
                  className={classes.buttonCancel}
                  onClick={handleClickCancel}
                >
                  {buttonCancelText}
                </Button>
              )}
              {alreadyConfirm ? (
                <Button className={classes.buttonCancel}>{buttonOkText}</Button>
              ) : (
                <Button className={classes.buttonOk} onClick={handleClickOk}>
                  {buttonOkText}
                </Button>
              )}
            </ButtonContainer>
          </ActionContainer>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(FileuploadConfirmDialog);
