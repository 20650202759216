import gql from "graphql-tag";

export const GetUnderMaintenanceAppointments = gql`
  query GetUnderMaintenanceAppointments(
    $start: String
    $end: String
    $status: [String!]
    $limit: Int
    $offset: Int
    $orderBy: AppointmentOrderBy
    $medicalMethodFilters: [String!]
    $isFilterUnderMaintenance: Boolean
    $requestPage: String
    $requireFromRange: Boolean
  ) {
    appointments(
      start: $start
      end: $end
      status: $status
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      medicalMethodFilters: $medicalMethodFilters
      isFilterUnderMaintenance: $isFilterUnderMaintenance
      requestPage: $requestPage
      requireFromRange: $requireFromRange
    ) {
      items {
        from
        to
        hospitalId
        createTime
        status
        patient {
          patientId
        }
        isUnderMaintenance
      }
    }
  }
`;
