import gql from "graphql-tag";

export const GetChooseDoctors = gql`
  query GetChooseDoctors {
    doctors {
      doctorId
      familyName
      givenName
      numberOfUnapprovedAppointment
    }
  }
`;
