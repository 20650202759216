import React, { Component } from "react";

import HospitalPaymentRowMenu from "../../molecules/payment/HospitalPaymentRowMenu";
import HospitalPaymentRowDeliveryOptionContent from "../../molecules/payment/HospitalPaymentRowDeliveryOptionContent";
import HospitalPaymentRowDeliveryProcess from "../../molecules/payment/HospitalPaymentRowDeliveryProcess";
import HospitalPaymentRowDeliveryFee from "../../molecules/payment/HospitalPaymentRowDeliveryFee";
import HospitalPaymentRowTreatmentFee from "../../molecules/payment/HospitalPaymentRowTreatmentFee";
import HospitalPaymentRowTotalFee from "../../molecules/payment/HospitalPaymentRowTotalFee";
import HospitalPaymentRowStatus from "../../molecules/payment/HospitalPaymentRowStatus";
import HospitalPaymentRowMedicineDelivery from "../../molecules/payment/HospitalPaymentRowMedicineDelivery";
import HospitalPaymentRowDeliveryArrangementFee from "../../molecules/payment/HospitalPaymentRowDeliveryArrangementFee";

import {
  PROGRAM_TYPE,
  MEDICAL_METHOD,
  DELIVERY_OPTION,
  FACILITY_TYPE,
  CENTRAL_CLINIC_PLANS,
} from "../../../Utils/const";
import { deliveryFeeCalculator } from "../../../Utils/DeliveryFeeCalculator";

class HospitalPaymentRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryFee: "",
      pickUpDateCandidates: [],
      deliveryServiceError: null,
      errorMessage: null,
    };
  }

  handleOpenDeliveryInputDialog = (deliveryOption) => {
    this.props.handleOpenDeliveryInputDialog(this.props.appo, deliveryOption);
  };

  handleOpenDeliveryResetDialog = () => {
    this.setState({ deliverySelectedOption: null });
    this.props.handleOpenDeliveryResetDialog(this.props.appo);
  };

  calcDeliveryFee = (appo, deliverySelectedOption, isNotIncludeDeliveryFee) => {
    if (deliverySelectedOption === DELIVERY_OPTION.empty) return null;
    if (
      isNotIncludeDeliveryFee ||
      (appo.menu && appo.menu.supportPaymentByContractCompany)
    )
      return {
        deliveryFee: 0,
        deliveryArrangementFee: 0,
        deliveryTotalFee: 0,
      };

    switch (parseInt(deliverySelectedOption, 10)) {
      case DELIVERY_OPTION.noDelivery:
        return deliveryFeeCalculator(appo, DELIVERY_OPTION.noDelivery);
      case DELIVERY_OPTION.yamato:
        return deliveryFeeCalculator(appo, DELIVERY_OPTION.yamato);
      case DELIVERY_OPTION.csvPost:
        return deliveryFeeCalculator(appo, DELIVERY_OPTION.csvPost);
      case DELIVERY_OPTION.other:
        return deliveryFeeCalculator(appo, DELIVERY_OPTION.other);
      default:
        return null;
    }
  };

  handleSelectDeliveryOptions = (e) => {
    const { appo } = this.props;
    const isOnlineWelfareProgram =
      appo.menu &&
      appo.menu.medicalMethod === MEDICAL_METHOD.ONLINE &&
      appo.program &&
      appo.program.type === PROGRAM_TYPE.WELFARE_PROGRAM;

    if (
      Boolean(e.target.value) &&
      isOnlineWelfareProgram &&
      !appo.deliveryFeeCalculateType
    ) {
      this.props.setErrorMessage("E08");
    } else {
      this.setState({ deliverySelectedOption: e.target.value });
    }
  };

  render() {
    const { appo, facilityType, isCentralClinic } = this.props;
    const existDeliveryOption =
      appo &&
      appo.payment &&
      Number.isInteger(appo.payment.deliverySelectedOption) &&
      appo.payment.deliverySelectedOption;
    let deliverySelectedOption =
      existDeliveryOption || this.state.deliverySelectedOption;
    let isNotIncludeDeliveryFee = false;
    if (
      appo.menu &&
      appo.menu.medicalMethod === MEDICAL_METHOD.OFFLINE &&
      appo.program &&
      appo.program.type === PROGRAM_TYPE.WELFARE_PROGRAM
    ) {
      deliverySelectedOption = DELIVERY_OPTION.noDelivery;
      isNotIncludeDeliveryFee = true;
    } else {
      isNotIncludeDeliveryFee = Boolean(appo.deliveryFeeCalculateType === 1);
    }

    const deliveryDisplayFee = this.calcDeliveryFee(
      appo,
      deliverySelectedOption,
      isNotIncludeDeliveryFee
    );

    const isCentralClinicSpecification =
      isCentralClinic &&
      (appo.contractPlan
        ? appo.contractPlan.plan &&
          CENTRAL_CLINIC_PLANS.PLAN_IDS.includes(appo.contractPlan.plan.planId)
        : false);

    return (
      <tr>
        <HospitalPaymentRowMenu appo={appo} />
        {facilityType === FACILITY_TYPE.HOSPITAL && (
          <HospitalPaymentRowMedicineDelivery
            appo={appo}
            handleOpenMedicineDeliveryDialog={
              this.props.handleOpenMedicineDeliveryDialog
            }
          />
        )}
        <HospitalPaymentRowDeliveryOptionContent
          isCentralClinicSpecification={isCentralClinicSpecification}
          appo={appo}
          handleSelectDeliveryOptions={this.handleSelectDeliveryOptions}
          deliverySelectedOption={deliverySelectedOption}
          handleOpenDeliveryResetDialog={this.handleOpenDeliveryResetDialog}
        />
        <HospitalPaymentRowDeliveryProcess
          appo={appo}
          deliverySelectedOption={deliverySelectedOption}
          propsDeliverySelectedOption={this.state.deliverySelectedOption}
          handleOpenDeliveryResultDialog={
            this.props.handleOpenDeliveryResultDialog
          }
          handleOpenDeliveryInputDialog={this.handleOpenDeliveryInputDialog}
        />
        <HospitalPaymentRowDeliveryFee
          isShowFee={!isCentralClinicSpecification} //jobanHopital default hide fee
          appo={appo}
          deliverySelectedOption={deliverySelectedOption}
          deliveryDisplayFee={deliveryDisplayFee}
        />
        <HospitalPaymentRowDeliveryArrangementFee
          isShowFee={!isCentralClinicSpecification} //jobanHopital default hide fee
          appo={appo}
          deliveryDisplayFee={deliveryDisplayFee}
        />
        <HospitalPaymentRowTreatmentFee
          isShowButton={isCentralClinicSpecification} //jobanHopital default show button
          appo={appo}
          deliverySelectedOption={this.state.deliverySelectedOption}
          handleOpenPaymentFeeDialog={this.props.handleOpenPaymentFeeDialog}
        />
        <HospitalPaymentRowTotalFee
          appo={appo}
          deliveryDisplayFee={deliveryDisplayFee}
          deliverySelectedOption={this.state.deliverySelectedOption}
        />
        <HospitalPaymentRowStatus
          appo={appo}
          handleOpenMedicalFeeDialog={this.props.handleOpenMedicalFeeDialog}
          handleOpenConfirmDialog={this.props.handleOpenConfirmDialog}
        />
      </tr>
    );
  }
}

export default HospitalPaymentRow;
