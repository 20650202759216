import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FontP from "../../../atoms/fonts/FontP";
import PatientInfo from "../../dialog/PatientInfo";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core/";
import Grid from "@material-ui/core/Grid";
import DialogWithStylesUtil from "../../../molecules/dialogs/DialogWithStylesUtil";
import DialogCancelConfirm from "../../../molecules/dialogs/DialogCancelConfirm";
import LoadingScreen from "../../../molecules/others/LoadingScreen";
import { client, RejectAppointment } from "../../../../graphql";
import { japaneseList } from "../../../../Resources/japaneseList";
import { formatPeriod } from "../../../../Utils/DateUtil";
import IconNotInsurance from "../../../atoms/icons/IconNotInsurance";
import Panel from "../../../atoms/panels/Panel";
import LinkA from "../../../atoms/links/LinkA";

const AppointmentRejectStatusResource =
  japaneseList.Components.Appointment.AppointmentRejectStatusDialog;
const AppointmentListResource =
  japaneseList.Components.Appointment.AppointmentList;
const AppointmentRejectConfirmDialogResource =
  japaneseList.Components.Appointment.AppointmentRejectConfirmDialog.render.div
    .Dialog;

const FACILTY_TYPE = { hospital: "hospital", pharmacy: "pharmacy" };

const styles = (theme) => ({
  dialogPaper: {
    width: "810px",
    margin: "82px 107px",
  },
  dialogTitle: {
    width: "810px",
    height: "68px",
    margin: "0",
    padding: "24px 0 22px 24px",
    borderBottom: "1px solid #cccccc",
  },
  dialogContent: {
    width: "810px",
    margin: "0",
    padding: "22px 25px 20px 24px",
  },
  dialogActions: {
    width: "810px",
    height: "70px",
    margin: "0",
    padding: "14px 25px 16px 24px",
    borderTop: "1px solid #cccccc",
    "& button": {
      height: "40px",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonOk: {
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
  dialogActionsButtonOk: {
    width: "120px",
    margin: "0",
    padding: "13px 46px",
    borderRadius: "5px",
  },
  buttonCancel: {
    backgroundColor: "#808080",
    boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
  dialogActionsButtonCancel: {
    width: "120px",
    margin: "0 20px 0 0",
    padding: "13px 25px",
    borderRadius: "5px",
  },
  buttonBack: {
    fontSize: "14px",
    position: "absolute",
    left: "24px",
    color: "#2563c0",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
  },
  iconNotInsurance: {
    border: `1px solid ${theme.color.notInsurance}`,
    fontSize: "10px",
    padding: "0",
    marginLeft: "10px",
  },
  iconInsurance: {
    fontSize: "10px",
    border: `1px solid ${theme.color.primary1}`,
    padding: "0",
    marginLeft: "10px",
  },
  panel: {
    paddingTop: "15px",
    paddingBottom: "0px",
    color: "#808080",
  },
  font: {
    fontSize: "12px",
    lineHeight: "1.2",
  },
});

const DialogTitleText = styled.div`
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
`;

const DialogContentText = styled.div`
  height: 14px;
  line-height: 14px;
  font-size: 14px;
`;

const PatientArea = styled.div`
  margin: 0;
`;

const AppointmentArea = styled.div`
  margin: 21px 0 0 0;
`;

const Container = styled.div`
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 68px auto;
  border-top: 1px solid #cccccc;
`;

const Title = styled.div`
  background-color: #eeeeee;
  height: 35px;
  padding-left: 10px;
  color: #808080;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`;

const Content = styled.div`
  height: 35px;
  padding-left: 20px;
  color: #393939;
  text-align: left;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-word;
`;

const ReasonTitle = styled.div`
  background-color: #eeeeee;
  min-height: 55px;
  padding-left: 10px;
  color: #808080;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`;

const ReasonContent = styled.div`
  min-height: 55px;
  padding: 10px 168px 10px 20px;
  color: #393939;
  text-align: left;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-word;
`;

const Text = styled.div``;

class AppointmentRejectConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: "other",
      isSubmitting: false,
      isCompleted: false,
      succeed: false,
    };
  }
  handleRejectAppointment = async () => {
    this.setState({
      isSubmitting: true,
      isCompleted: false,
      displayDialogCancelConfirm: false,
    });
    var succeed = true;
    var errorCode = "";
    try {
      const {
        patient: { patientId },
        createTime,
      } = this.props.appointment;

      const payload = {
        patientId,
        createTime,
        rejectSelectedOption: this.props.selectDefault ? 1 : -1,
        rejectComment: this.props.selectDefault
          ? AppointmentRejectConfirmDialogResource.DialogContent.Reason.j002
          : this.props.reasonText,
        medicalDoctorId: this.props.doctor.doctorId,
      };

      let response = await client.mutate({
        mutation: RejectAppointment,
        variables: payload,
      });

      if (
        !response.data.rejectAppointment ||
        !response.data.rejectAppointment.succeed
      ) {
        succeed = false;
        errorCode = response.errors ? response.errors[0].errorType : "";
      }
    } catch (err) {
      succeed = false;
    }

    this.setState({
      isSubmitting: false,
      isCompleted: true,
      succeed,
      errorCode,
    });
  };

  openDialogCancelConfirm = () => {
    this.setState({ displayDialogCancelConfirm: true });
  };

  closeDialogCancelConfirm = () => {
    this.setState({ displayDialogCancelConfirm: false });
  };

  render() {
    const { classes, appointment, doctor, facilityType } = this.props;
    const patient = appointment.patient;
    return (
      <React.Fragment>
        <Dialog
          disableBackdropClick
          open={
            this.props.open && (!this.state.isCompleted || !this.state.succeed)
          }
          maxWidth="md"
          onClose={this.props.onClose}
        >
          <DialogTitle className={classes.dialogTitle}>
            <DialogTitleText>
              {AppointmentRejectConfirmDialogResource.DialogTitle.j001}
            </DialogTitleText>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              {AppointmentRejectConfirmDialogResource.DialogTitle.j002}
            </DialogContentText>
            <PatientArea>
              <PatientInfo patient={patient} />
            </PatientArea>
            <AppointmentArea>
              <Container>
                <Item>
                  <Title>
                    {
                      AppointmentRejectConfirmDialogResource.DialogContent.Item
                        .j001
                    }
                  </Title>
                  <Content>
                    {doctor.familyName} {doctor.givenName}
                  </Content>
                </Item>
                <Item>
                  <Title>
                    {
                      AppointmentRejectConfirmDialogResource.DialogContent.Item
                        .j002
                    }
                  </Title>
                  <Content>
                    {appointment.menu.displayName}
                    <IconNotInsurance
                      className={
                        appointment.menu.supportsInsuranceTreatment
                          ? classes.iconInsurance
                          : classes.iconNotInsurance
                      }
                    >
                      {appointment.menu.supportsInsuranceTreatment
                        ? AppointmentListResource.insuranceCard.ok
                        : AppointmentListResource.insuranceCard.ng}
                    </IconNotInsurance>
                  </Content>
                </Item>
                <Item>
                  <Title>
                    {
                      AppointmentRejectConfirmDialogResource.DialogContent.Item
                        .j003
                    }
                  </Title>
                  <Content>
                    {appointment.from && appointment.to
                      ? formatPeriod(appointment.from, appointment.to)
                      : ""}
                  </Content>
                </Item>
                <Item>
                  <ReasonTitle>
                    {
                      AppointmentRejectConfirmDialogResource.DialogContent.Item
                        .j004
                    }
                  </ReasonTitle>
                  <ReasonContent>
                    <Grid container item>
                      <Grid item>
                        <Text>
                          {this.props.selectDefault
                            ? AppointmentRejectConfirmDialogResource
                                .DialogContent.Reason.j002
                            : AppointmentRejectConfirmDialogResource
                                .DialogContent.Reason.j003}
                        </Text>
                        <Text>{this.props.reasonText}</Text>
                      </Grid>
                    </Grid>
                  </ReasonContent>
                </Item>
              </Container>
            </AppointmentArea>
            <Panel padding={"vmh0"} className={classes.panel}>
              <FontP
                className={classes.font}
                color="gray"
                whiteSpace={"preWrap"}
              >
                {AppointmentRejectConfirmDialogResource.DialogContent.Note.j001}
              </FontP>
            </Panel>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <LinkA className={classes.buttonBack} onClick={this.props.onClose}>
              {
                AppointmentRejectConfirmDialogResource.DialogContent.Button
                  .DisplayText.j001
              }
            </LinkA>
            <Button
              variant="raised"
              onClick={this.openDialogCancelConfirm}
              className={`${classes.buttonCancel} ${classes.dialogActionsButtonCancel}`}
            >
              {
                AppointmentRejectConfirmDialogResource.DialogContent.Button
                  .DisplayText.j002
              }
            </Button>
            <Button
              variant="raised"
              onClick={this.handleRejectAppointment}
              className={`${classes.buttonOk} ${classes.dialogActionsButtonOk}`}
            >
              {
                AppointmentRejectConfirmDialogResource.DialogContent.Button
                  .DisplayText.j003
              }
            </Button>
          </DialogActions>
        </Dialog>
        <DialogWithStylesUtil
          open={this.state.isCompleted}
          onClose={() => {
            window.location.reload();
          }}
          title={
            this.state.succeed ? AppointmentRejectStatusResource.title : ""
          }
          children={(this.state.succeed
            ? facilityType === FACILTY_TYPE.hospital
              ? AppointmentRejectStatusResource.successHospital
              : AppointmentRejectStatusResource.successPharmacy
            : this.state.errorCode === "E01"
            ? AppointmentRejectStatusResource.error.j001
            : this.state.errorCode === "E02"
            ? AppointmentRejectStatusResource.error.j002
            : this.state.errorCode === "E04"
            ? AppointmentRejectStatusResource.error.j004
            : AppointmentRejectStatusResource.error.j003
          )
            .split("\n")
            .map((item, index) => {
              return (
                <span key={index}>
                  {index > 0 ? <br /> : ""}
                  {item}
                </span>
              );
            })}
          buttons={{
            ok: {
              display: true,
              text: this.state.succeed
                ? facilityType === FACILTY_TYPE.hospital
                  ? AppointmentRejectStatusResource.buttonOkHospital
                  : AppointmentRejectStatusResource.buttonOkPharmacy
                : AppointmentRejectStatusResource.buttonCancel,
              handleClick: (e) => {
                if (this.state.succeed) {
                  this.props.history.push("/appointment");
                } else {
                  window.location.reload();
                }
              },
            },
            cancel: {
              display: this.state.succeed,
              text: AppointmentRejectStatusResource.buttonCancel,
              handleClick: () => {
                window.location.reload();
              },
            },
          }}
          alignCenter={true}
        />
        <Dialog
          open={Boolean(this.state.isSubmitting)}
          fullWidth={true}
          PaperProps={{
            style: {
              willChange: "initial !important",
            },
          }}
        >
          <LoadingScreen isLoading={Boolean(this.state.isSubmitting)} />
        </Dialog>
        <DialogCancelConfirm
          open={this.state.displayDialogCancelConfirm}
          closeDialogCancelConfirm={this.closeDialogCancelConfirm}
          handleClose={this.props.handleClose}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AppointmentRejectConfirmDialog);
