import styled from "styled-components";
import { DialogSelect } from "./DialogSelect";
import { japaneseList } from "../../../Resources/japaneseList";

const defaultOption =
  japaneseList.Components.Appointment.StatusChangeDialog.DialogContent
    .containerBorder.drugNameEWPDefaultOption;

const Container = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  grid-gap: 20px;
`;

const ContentItem = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.color.font};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  word-break: break-word;
  white-space: pre-wrap;
`;

interface DrugNameSelectProps {
  drugsNameValue: string;
  handleChangeDrugName: () => void;
  disabled: boolean;
  isValidateError: boolean;
  selectableDrugNames: string[];
}

export const DrugNameSelect = (props: DrugNameSelectProps) => {
  const optionList = props.selectableDrugNames.map((drugName) => {
    return {
      optionName: drugName,
      optionValue: drugName,
    };
  });
  return (
    <Container>
      <ContentItem>
        <DialogSelect
          selectedValue={props.drugsNameValue}
          onChange={props.handleChangeDrugName}
          disabled={props.disabled}
          defaultOptionName={defaultOption}
          optionList={optionList}
        />
      </ContentItem>
    </Container>
  );
};
