import React from "react";
import BasicTemplate from "../../templates/BasicTemplate";
import Home from "../../organisms/home/Home";
import Titles from "../../../Resources/Titles";
import { useHospital, useNewsList } from "../../../hooks";

export const HomePage = () => {
  const hospital = useHospital();
  const newsList = useNewsList(hospital);

  return (
    <BasicTemplate
      main={<Home newsList={newsList} />}
      title={Titles.home}
      isLoading={!newsList}
    />
  );
};
