import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  Divider,
  Button,
  DialogActions,
} from "@material-ui/core/";
import LinkA from "../../../atoms/links/LinkA";
import { japaneseList } from "../../../../Resources/japaneseList";
import FontSpan from "../../../atoms/fonts/FontSpan";
import { DELIVERY_OPTION } from "../../../../Utils/const";
import YamatoDeliveryDetail from "./YamatoDeliveryDetail";
import OtherDeliveryDetail from "./OtherDeliveryDetail";
import CSVPostDeliveryDetail from "./CSVPostDeliveryDetail";
import NoDeliveryDetail from "./NoDeliveryDetail";

const Resource = japaneseList.Components.Payment.DeliveryProcessDialog;

const styles = (theme) => ({
  dialogTitle: {
    fontSize: "20px",
    lineHeight: "0px",
  },

  dialogActions: {
    padding: "14px 24px 16px 0px",
    margin: "0px",
  },

  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },

  linkAStyle: {
    color: "#2563C0",
    textDecoration: "underline",
    paddingLeft: "24px",
    fontSize: "14px",
    "&:hover": {
      textDecoration: "none",
    },
  },

  buttonOk: {
    color: theme.button.ok.color,
    margin: "0 0 0 20px",
    width: "120px",
    height: "40px",
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    borderRadius: "6px",
    opacity: "1",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    fontWeight: "bold",
  },

  buttonCancel: {
    backgroundColor: "gray",
    color: "white",
    width: "120px",
    height: "40px",
    background: "#808080 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 0px #505050",
    borderRadius: "6px",
    opacity: "1",
    margin: "0 0 0 20px",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
    fontWeight: "bold",
  },

  buttonClose: {
    backgroundColor: "gray",
    color: "white",
    width: "120px",
    height: "40px",
    background: "#808080 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 0px #505050",
    borderRadius: "6px",
    opacity: "1",
    margin: "0 0 0 20px",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
    fontWeight: "bold",
  },

  container: {
    display: "grid",
    gridTemplateColumns: "80px auto",
    gridGap: "20px",
    borderTop: "#ccc solid 1px",
  },

  maxHeightWidthDialog: {
    maxHeight: "96%",
    maxWidth: "810px",
  },
});

class DeliveryProcessDialog extends Component {
  dialogTitle = () => {
    const {
      classes,
      deliveryOption: propDeliveryOption,
      ...deliveryParams
    } = this.props;
    const { mode } = deliveryParams;
    let deliveryOption = propDeliveryOption;
    return (
      <React.Fragment>
        <DialogTitle>
          <FontSpan weight="bold" className={classes.dialogTitle}>
            {mode === "confirm"
              ? Resource.ConfirmTitle[deliveryOption]
              : mode === "result"
              ? Resource.ResultTitle[deliveryOption]
              : mode === "reset"
              ? Resource.ResetTitle[deliveryOption]
              : ""}
          </FontSpan>
        </DialogTitle>
        <Divider />
      </React.Fragment>
    );
  };

  render() {
    const {
      classes,
      open,
      deliveryOption: propDeliveryOption,
      handleBack,
      handleClose,
      handleCancel,
      handleConfirm,
      handleReset,
      ...deliveryParams
    } = this.props;
    const { appointment, mode } = deliveryParams;
    if (!appointment) return null;

    let deliveryOption = propDeliveryOption;

    if (["result", "reset"].includes(mode)) {
      const { name, pickUpDate, pickUpTimeSpan, comment } =
        appointment.delivery || {};
      deliveryParams.requestDelivery = {
        name,
        pickUpDate,
        pickUpTimeSpan,
        comment,
        deliveryFee: appointment.payment.deliveryTotalFee,
      };
      deliveryOption = appointment.payment.deliverySelectedOption;
    }

    return (
      <Dialog
        open={open}
        disableBackdropClick
        maxWidth={false}
        fullWidth={true}
        PaperProps={{ classes: { root: classes.maxHeightWidthDialog } }}
      >
        {deliveryOption === DELIVERY_OPTION.noDelivery ? (
          <NoDeliveryDetail {...deliveryParams} />
        ) : deliveryOption === DELIVERY_OPTION.yamato ? (
          <YamatoDeliveryDetail {...deliveryParams} />
        ) : deliveryOption === DELIVERY_OPTION.csvPost ? (
          <CSVPostDeliveryDetail {...deliveryParams} />
        ) : deliveryOption === DELIVERY_OPTION.other ? (
          <OtherDeliveryDetail {...deliveryParams} />
        ) : (
          ""
        )}
        <Divider />
        <DialogActions className={classes.dialogActions}>
          {deliveryParams.mode === "confirm" ? (
            <div className={classes.flexContainer}>
              <div>
                <LinkA className={classes.linkAStyle} onClick={handleBack}>
                  {Resource.DialogActions.back}
                </LinkA>
              </div>
              <div>
                <Button className={classes.buttonCancel} onClick={handleCancel}>
                  {Resource.DialogActions.cancel}
                </Button>
                <Button
                  variant="raised"
                  onClick={handleConfirm}
                  className={classes.buttonOk}
                >
                  {Resource.DialogActions.complete[deliveryOption]}
                </Button>
              </div>
            </div>
          ) : deliveryParams.mode === "reset" ? (
            <React.Fragment>
              <Button className={classes.buttonCancel} onClick={handleCancel}>
                {Resource.DialogActions.cancel}
              </Button>
              <Button
                variant="raised"
                onClick={handleReset}
                className={classes.buttonOk}
              >
                {Resource.DialogActions.reset}
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button className={classes.buttonClose} onClick={handleClose}>
                {Resource.DialogActions.close}
              </Button>
              {deliveryParams.mode === "result" &&
                deliveryOption === DELIVERY_OPTION.csvPost && (
                  <Button
                    variant="raised"
                    onClick={this.props.handleConfirm}
                    className={classes.buttonOk}
                  >
                    {Resource.DialogActions.complete[deliveryOption]}
                  </Button>
                )}
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DeliveryProcessDialog);
