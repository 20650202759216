// --------------------------------------------------
//
// file upload content file list
//
// --------------------------------------------------

// required　modules
// --------------------------------------------------
import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

// personal modules
// --------------------------------------------------
import { client, GenerateGetPresignedUrl } from "../../../../graphql";
import LinkA from "../../../atoms/links/LinkA";
import FontP from "../../../atoms/fonts/FontP";
import { isLnln } from "../../../../Utils/checkLnln";
import IconFile from "../../../../images/carada/icon/icon-file-blue.svg";
import IconFileLnln from "../../../../images/lnln/icon/icon-file-blue.svg";
import { japaneseList } from "../../../../Resources/japaneseList";
import Panel from "../../../atoms/panels/Panel";
import { APPOINTMENT_STATUS, MEDICAL_METHOD } from "../../../../Utils/const";
const Resources = japaneseList.Components.Appointment.FileuploadDialog;
const icon = isLnln() ? IconFileLnln : IconFile;

let _ = require("lodash");

const CustomLinkA = styled(LinkA)`
  display: flex;
  align-items: center;
  margin-right: 20px;
  color: ${(p) => p.theme.color.link};
`;

const Img = styled.img`
  width: 20px;
  margin-right: 5px;
`;

// style
// --------------------------------------------------
const styles = (theme) => ({
  buttonAttachment: {
    fontSize: "14px",
    fontWeight: "bold",
    backgroundColor: "#eb6565",
    boxShadow: "0px 1px 0px #bf3939",
    "&:hover": {
      backgroundColor: "#f26666",
    },
    maxWidth: "38px",
    maxHeight: "24px",
    minWidth: "38px",
    minHeight: "24px",
    padding: "1px 2px",
  },
  text: { fontSize: "14px", lineHeight: "14px" },
  textNoFile: {
    padding: "29px 0px 29px 0px",
    fontSize: "14px",
    lineHeight: "14px",
  },
  textNotCompleteYet: { fontSize: "14px", margin: "27px 0 0px 0" },
  textNotCompleteYetCaution: { margin: "0px 0 15px 0", fontSize: "11px" },
});

// class define
// --------------------------------------------------
const FileuploadDialogFile = (props) => {
  const { appointmentSelected, fileList, classes, handleNotExistS3File } =
    props;
  const { medicalMethod } = appointmentSelected.menu;
  const fileListAvailable = fileList.filter(
    (file) => file.justUpdatedStatus !== "deleted"
  );

  const getSignedUrl = (filePath, newWindow) => {
    client
      .mutate({
        mutation: GenerateGetPresignedUrl,
        variables: {
          filePath: filePath,
        },
      })
      .then((res) => {
        if (!res.errors) {
          newWindow.location = res.data.generateGetPresignedUrl.getSignedUrl;
        } else {
          handleNotExistS3File();
          newWindow.close();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <React.Fragment>
      <Grid container item xs={6}>
        <FontP weight={"bold"} className={classes.text}>
          {Resources.existFileTitle}
        </FontP>
      </Grid>
      <Grid container item xs={6}></Grid>
      {fileListAvailable.length > 0 ? (
        _.orderBy(fileListAvailable, "time").map((files) => (
          <React.Fragment>
            <Grid container item xs={12} alignItems="center">
              <CustomLinkA
                href="#"
                target={"_blank"}
                onClick={(event) => {
                  event.preventDefault();
                  getSignedUrl(files.file.filePath, window.open());
                }}
                className={classes.text}
              >
                <Img src={icon} />
                {files.file.fileName}
              </CustomLinkA>
              <Button
                variant="raised"
                color="primary"
                className={classes.buttonAttachment}
                onClick={(event) => {
                  props.handleDeleteFile(files.file, fileList);
                }}
              >
                {Resources.buttonDelete}
              </Button>
            </Grid>
          </React.Fragment>
        ))
      ) : medicalMethod === MEDICAL_METHOD.OFFLINE ? (
        <Grid container item xs={12}>
          <FontP className={classes.textNoFile}>
            {Resources.notUploadFile}
          </FontP>
        </Grid>
      ) : appointmentSelected.status === APPOINTMENT_STATUS.EXAM_COMPLETED ||
        appointmentSelected.status === APPOINTMENT_STATUS.PAYMENT_COMPLETED ? (
        <Grid container item xs={12}>
          <FontP className={classes.textNoFile}>
            {Resources.noHaveFileUpload}
          </FontP>
        </Grid>
      ) : (
        <Panel padding={"vmh0"}>
          <FontP className={classes.textNotCompleteYet}>
            {Resources.notCompleteYet}
          </FontP>
          <FontP
            size="ss"
            color="gray"
            className={classes.textNotCompleteYetCaution}
            whiteSpace={"preWrap"}
          >
            {Resources.notCompleteYetcaution}
          </FontP>
        </Panel>
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(FileuploadDialogFile);
