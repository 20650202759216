import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  DialogContent,
  Typography,
  Grid,
  DialogTitle,
  Divider,
} from "@material-ui/core/";
import AccountImage from "../../../../images/account_circle-24px.svg";
import { format } from "date-fns";
import { japaneseList } from "../../../../Resources/japaneseList";
import FontPError from "../../../atoms/fonts/FontPError";
import CurrencyText from "../CurrencyText";
import { getAge } from "../../../../Utils/DisplayLogic";
import NumberFormat from "react-number-format";
import { prefectureCodes } from "../../../../Utils/PrefectureCode";
import { PAYMENT_METHOD, MEDICAL_METHOD } from "../../../../Utils/const";
import { deliveryFeeCalculatorForRequestDelivery } from "../../../../Utils/DeliveryFeeCalculator";
import FontSpan from "../../../atoms/fonts/FontSpan";

const Resource = japaneseList.Components.Payment.FeeDeliveryDialog;
const DeliveryProcessResources =
  japaneseList.Components.Payment.DeliveryProcessDialog;

const styles = {
  dialogTitle: {
    fontSize: "20px",
    lineHeight: "0px",
  },

  tableStyle: {
    borderCollapse: "collapse",
    width: "310px",
    tableLayout: "auto",
  },
  thBirthDay: {
    width: "98px",
  },
  thAge: {
    width: "26px",
  },
  thGender: {
    width: "48px",
  },
  thPhonenumber: {
    width: "120px",
  },

  thStyle: {
    textAlign: "center",
    borderTop: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    backgroundColor: "#eee",
    fontSize: "12px",
    color: "#808080",
  },

  tdStyle: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    color: "#393939",
    whiteSpace: "nowrap",
  },

  tdStyleGenderMale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },

  tdStyleGenderFemale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },

  containerTable: {
    display: "flex",
    boxSizing: "border-box",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "space-between",
    margin: "8px 0 26px 0",
  },

  containerBorder: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
  },

  container: {
    display: "grid",
    gridTemplateColumns: "80px auto",
    gridGap: "20px",
    borderTop: "#ccc solid 1px",
  },

  titleItem: {
    backgroundColor: "#eee",
    padding: "6px 10px 6px 10px",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    color: "#808080",
    fontWeight: "bold",
    textAlign: "left",
    whiteSpace: "pre-wrap",
  },

  contentItem: {
    padding: "6px 20px 6px 0px",
    fontSize: "14px",
    color: "#393939",
    textAlign: "left",
    marginTop: "auto",
    marginBottom: "auto",
  },

  contentItemSubGroup: {
    display: "flex",
    padding: "15px 0",
    alignItems: "center",
    gap: "10px",
  },
  contentFeeBlock: {
    display: "flex",
    minWidth: "42px",
    height: "21px",
    paddingRight: "0px",
    justifyContent: "left",
    alignItems: "center",
    gap: "4px",
  },
  contentFeeItem: {
    textAlign: "right",
    fontSize: "14px",
  },
  contentDescriptionItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#808080",
    fontSize: "11px",
    lineHeight: "normal",
    whiteSpace: "pre-wrap",
    alignSelf: "center",
  },

  maxHeightWidthDialog: {
    maxHeight: "96%",
    maxWidth: "810px",
  },
  nameStyle: {
    fontWeight: "bold",
    fontSize: "16px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  nameKanaStyle: {
    fontSize: "12px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  textQuestion: {
    fontSize: "14px",
    marginTop: "25px",
    marginBottom: "15px",
  },
  noteContent: {
    fontSize: "11px",
    lineHeight: "20px",
    color: "#aaaaaa",
  },
};

class OtherDeliveryDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      now: null,
      pickUpDate: "",
      pickUpTimeSpan: "",
      product: "",
      comment: "",
      status: null,
      pickUpDateCandidates: [],
      pickUpTimeSpanCandidates: [],
      enableSubmitButton: false,
      checkCommentInput: false,
    };
  }

  render() {
    const { classes, appointment, requestDelivery, mode } = this.props;

    const { patient, doctor, menu } = appointment;
    const { address } = patient;

    const prefecture = prefectureCodes.find(
      (c) => address.prefectureCode === c.code
    ).jpName;
    const postalCode =
      address.postalCode.slice(0, 3) + "-" + address.postalCode.slice(3);

    const displayDeliveryFee =
      deliveryFeeCalculatorForRequestDelivery(requestDelivery);
    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;

    return (
      <React.Fragment>
        <DialogTitle>
          <FontSpan weight="bold" className={classes.dialogTitle}>
            {mode === "confirm" && Resource.DialogTitle.confirm}
            {mode === "reset" && Resource.DialogTitle.reset}
          </FontSpan>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography style={{ margin: "20px 0px" }}>
            {Resource.DialogContent.description}
          </Typography>
          {patient && (
            <Grid
              container
              wrap="nowrap"
              style={{ padding: "0px", margin: "0px" }}
            >
              <Grid item style={{ padding: "0px 10px 0px 0px" }}>
                <img src={AccountImage} alt="" />
              </Grid>
              <Grid item style={{ padding: "0px" }}>
                <Typography className={classes.nameStyle}>
                  {patient.familyName} {patient.givenName}
                </Typography>
                <Typography className={classes.nameKanaStyle}>
                  {patient.familyNameKana} {patient.givenNameKana}
                </Typography>
              </Grid>
            </Grid>
          )}
          {patient && (
            <div className={classes.containerTable}>
              <table className={classes.tableStyle}>
                <thead>
                  <tr>
                    <th className={`${classes.thStyle} ${classes.thBirthDay}`}>
                      {Resource.DialogContent.table.thBirthDay}
                    </th>
                    <th className={`${classes.thStyle} ${classes.thAge}`}>
                      {Resource.DialogContent.table.thAge}
                    </th>
                    <th className={`${classes.thStyle} ${classes.thGender}`}>
                      {Resource.DialogContent.table.thGender}
                    </th>
                    <th
                      className={`${classes.thStyle} ${classes.thPhonenumber}`}
                    >
                      {Resource.DialogContent.table.thPhonenumber}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={classes.tdStyle}>
                      {format(
                        new Date(patient.birthdate),
                        Resource.Format.j001
                      )}
                    </td>
                    <td className={classes.tdStyle}>
                      {patient.birthdate ? getAge(patient.birthdate) : " "}
                    </td>
                    <td
                      className={
                        patient.gender &&
                        patient.gender ===
                          Resource.DialogContent.table.genderMale
                          ? classes.tdStyleGenderMale
                          : classes.tdStyleGenderFemale
                      }
                    >
                      {patient.gender}
                    </td>
                    <td className={classes.tdStyle}>
                      <NumberFormat
                        value={patient.phoneNumber}
                        displayType={"text"}
                        format="###-####-####"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {mode === "confirm" && (
            <p className={classes.textQuestion}>
              {Resource.DialogContent.messageConfirm}
            </p>
          )}
          {mode === "reset" && (
            <div className={classes.textQuestion}>
              {DeliveryProcessResources.ResetQuestion}
              <div className={classes.noteContent}>
                {DeliveryProcessResources.ResetQuestionNotice}
              </div>
            </div>
          )}
          <div className={classes.containerBorder}>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.undertaker}
              </div>
              <div className={classes.contentItem}>
                {menu && menu.medicalMethod === MEDICAL_METHOD.OFFLINE
                  ? Resource.DialogContent.containerBorder.offline
                  : `${doctor.familyName} ${doctor.givenName}`}
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.deliveryAddress}
              </div>
              <div className={classes.contentItem}>
                {postalCode
                  ? `${Resource.DialogContent.containerBorder.prefixImage}${postalCode}`
                  : ""}
                <br />
                {`${prefecture} ${address.addressCity} ${
                  address.addressStreetNumber
                } ${address.addressBuilding || ""}`}
              </div>
            </div>
            {isBillingEnterprise && displayDeliveryFee && (
              <div className={classes.container}>
                <div className={classes.titleItem}>
                  {Resource.DialogContent.containerBorder.deliveryFee}
                </div>
                <div className={classes.contentItem}>
                  {!true && (
                    <FontPError size="s" style={{ margin: "auto" }}>
                      {"error message input"}
                    </FontPError>
                  )}
                  <CurrencyText value={displayDeliveryFee.deliveryFee} />
                  <span
                    style={{
                      marginLeft: "15px",
                      fontFamily: "Hiragino Kaku Gothic ProN",
                      fontSize: "11px",
                      textAlign: "left",
                      letterSpacing: "0px",
                      color: "#808080",
                      opacity: "1",
                    }}
                  >
                    {
                      Resource.DialogContent.containerBorder
                        .deliveryFeeNoteConfirm
                    }
                  </span>
                </div>
              </div>
            )}
            {!isBillingEnterprise && displayDeliveryFee && (
              <React.Fragment>
                <div className={classes.container}>
                  <div className={classes.titleItem}>
                    {Resource.DialogContent.containerBorder.deliveryFee}
                  </div>
                  <div className={classes.contentItemSubGroup}>
                    <div className={classes.contentFeeBlock}>
                      <div className={classes.contentFeeItem}>
                        <CurrencyText
                          value={displayDeliveryFee.deliveryFee}
                        ></CurrencyText>
                      </div>
                    </div>
                    <div className={classes.contentDescriptionItem}>
                      {
                        Resource.DialogContent.containerBorder
                          .deliveryFeeDetailDescription
                      }
                    </div>
                  </div>
                </div>
                <div className={classes.container}>
                  <div className={classes.titleItem}>
                    {
                      Resource.DialogContent.containerBorder
                        .deliveryArrangementFee
                    }
                  </div>
                  <div className={classes.contentItemSubGroup}>
                    <div className={classes.contentFeeBlock}>
                      <div className={classes.contentFeeItem}>
                        <CurrencyText
                          value={displayDeliveryFee.deliveryArrangementFee}
                        ></CurrencyText>
                      </div>
                    </div>
                    <div className={classes.contentDescriptionItem}>
                      {
                        Resource.DialogContent.containerBorder
                          .deliveryArrangementFeeDetailDescription
                      }
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </DialogContent>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OtherDeliveryDetail);
