import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core/";

import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { japaneseList } from "../../../../Resources/japaneseList";
import {  formatDay } from "../../../../Utils/DateUtil";
import { parseISO, endOfDay } from "date-fns";
import {
  showCustomWeek,
  byDayFromNumberToString,
} from "../../../../Utils/DateUtil";

import LinkA from "../../../atoms/links/LinkA";
import DialogCancelConfirm from "../../../molecules/dialogs/DialogCancelConfirm";
import { FLOW } from "./Constants";

import { MEDICAL_METHOD } from "../../../../Utils/const";
import { LoadingScreenForDialog } from "../../../molecules/others/LoadingScreenForDialog";

const SlotConfirmResource =
  japaneseList.Components.Appointment.Stepper.SlotConfirm;
const OnlineMethod = "online";

const styles = (theme) => {
  return {
    dialogTitle: {
      width: "810px",
      height: "68px",
      margin: "0",
      padding: "24px 0 24px 24px",
      borderBottom: "1px solid #cccccc",
    },
    dialogContent: {
      width: "810px",
      margin: "0",
      padding: "21px 25px 20px 24px",
    },
    dialogActions: {
      width: "810px",
      height: "70px",
      margin: "0",
      padding: "14px 25px 16px 24px",
      borderTop: "1px solid #cccccc",
      "& button": {
        height: "40px",
      },
      "& span": {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#ffffff",
      },
    },
    buttonBack: {
      fontSize: "14px",
      position: "absolute",
      left: "24px",
      color: "#2563c0",
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none",
      },
    },
    buttonCancel: {
      backgroundColor: "#808080",
      boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
      "&:hover": {
        backgroundColor: theme.button.cancel.backgroundColorHover,
      },
    },
    dialogActionsButtonCancel: {
      width: "120px",
      margin: "0 20px 0 0",
      padding: "13px 25px",
      borderRadius: "5px",
    },
    buttonOk: {
      backgroundColor: theme.button.ok.backgroundColor,
      boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
      "&:hover": {
        backgroundColor: theme.button.ok.backgroundColorHover,
      },
    },
    dialogActionsButtonOk: {
      width: "120px",
      margin: "0",
      padding: "13px 46px",
      borderRadius: "5px",
    },
    error: {
      color: "red",
      margin: "0",
    },
  };
};

const DialogTitleText = styled.div`
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
`;

const DialogContentText = styled.div`
  height: 14px;
  line-height: 14px;
  font-size: 14px;
`;

const AppointmentArea = styled.div`
  margin: 21px 0 0 0;
`;

const Container = styled.div`
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 88px auto;
  border-top: 1px solid #cccccc;
  height: 35px;
`;

const Title = styled.div`
  width: 88px;
  background-color: #eeeeee;
  padding-left: 10px;
  color: #808080;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`;

const Content = styled.div`
  padding-left: 20px;
  color: #393939;
  text-align: left;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-word;
`;

const SlotConfirm = (props) => {
  const { classes, step, slot, errors } = props;
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayDialogCancelConfirm, setDisplayDialogCancelConfirm] =
    useState(false);

  const startDate = formatDay(slot.selectedDate);

  const endDate = formatDay(slot.repetitionEndDate);

  const type =
    slot.menuMethod === MEDICAL_METHOD.OFFLINE
      ? SlotConfirmResource.DialogContent.AppointmentArea.Type.j001
      : SlotConfirmResource.DialogContent.AppointmentArea.Type.j002;

  const undertaker =
    slot.menuMethod === MEDICAL_METHOD.OFFLINE
      ? SlotConfirmResource.DialogContent.AppointmentArea.Type.j003
      : slot.selectDoctor.name;

  const handleRegisterSlot = async () => {
    const {
      menuMethod,
      selectedDate,
      capacity,
      end,
      start,
      repetition,
      repetitionEndValue,
      repetitionEndDate,
    } = props.slot;
    if (isSubmitting) {
      return false;
    }
    setIsSubmitting(true);
    const endDateTime = parseISO(`${selectedDate}T${end}:00`);
    const startDateTime = parseISO(`${selectedDate}T${start}:00`);
    const slotRepetition = repetition.value !== 1 && {
      interval: repetition.interval,
      frequency: repetition.frequency,
      isCustom: repetition.isCustom,
      startDate: startDateTime,
      endDate:
        repetitionEndValue === "0"
          ? endOfDay(parseISO(`${repetitionEndDate}T00:00:00`)).toISOString()
          : null,
      byDay: byDayFromNumberToString(repetition.byDay),
    };
    try {
      const { result } = await props.handleRegisterSlot({
        slotParam: {
          menuMethod,
          startDateTime,
          endDateTime,
          capacity: menuMethod === OnlineMethod ? 0 : capacity,
          repetition: slotRepetition,
        },
      });

      if (result === "failed" || result === "confirming") {
        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
      setError(SlotConfirmResource.Errors.j001);
    }
  };

  const openDialogCancelConfirm = () => {
    setDisplayDialogCancelConfirm(true);
  };

  const closeDialogCancelConfirm = () => {
    setDisplayDialogCancelConfirm(false);
  };

  return (
    <React.Fragment>
      <DialogTitle className={classes.dialogTitle}>
        <DialogTitleText>
          {SlotConfirmResource.DialogTitle.DialogTitleText.j001}
        </DialogTitleText>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          {SlotConfirmResource.DialogContent.DialogContentText.j001}
        </DialogContentText>
        <AppointmentArea>
          <Container>
            <Item>
              <Title>
                {
                  SlotConfirmResource.DialogContent.AppointmentArea.Container
                    .Item.Title.j001
                }
              </Title>
              <Content>{undertaker}</Content>
            </Item>
            <Item>
              <Title>
                {
                  SlotConfirmResource.DialogContent.AppointmentArea.Container
                    .Item.Title.j002
                }
              </Title>
              <Content>
                {`${startDate} ${slot.start}${SlotConfirmResource.DialogContent.AppointmentArea.Format.j001}${slot.end}`}
              </Content>
            </Item>
            <Item>
              <Title>
                {
                  SlotConfirmResource.DialogContent.AppointmentArea.Container
                    .Item.Title.j003
                }
              </Title>
              <Content>{type}</Content>
            </Item>
            {slot && slot.menuMethod === MEDICAL_METHOD.OFFLINE && (
              <Item>
                <Title>
                  {
                    SlotConfirmResource.DialogContent.AppointmentArea.Container
                      .Item.Title.j004
                  }
                </Title>
                <Content>{slot.capacity}</Content>
              </Item>
            )}
            <Item>
              <Title>
                {
                  SlotConfirmResource.DialogContent.AppointmentArea.Container
                    .Item.Title.j005
                }
              </Title>
              <Content>
                {slot.repetition.value === 6
                  ? `${slot.repetition.text}${showCustomWeek(
                      slot.repetition.byDay
                    )}`
                  : slot.repetition.text}
              </Content>
            </Item>
            <Item>
              <Title>
                {
                  SlotConfirmResource.DialogContent.AppointmentArea.Container
                    .Item.Title.j006
                }
              </Title>
              <Content>
                {slot.repetitionEndValue === "0"
                  ? endDate
                  : SlotConfirmResource.DialogContent.AppointmentArea.Slot.j001}
              </Content>
            </Item>
          </Container>
        </AppointmentArea>
        {[...errors, error].map((error, index) => (
          <Typography
            variant="subheading"
            gutterBottom
            className={classes.error}
            key={index}
          >
            {error}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <LinkA
          className={classes.buttonBack}
          onClick={() => step(1, FLOW.SLOT, true)}
        >
          {SlotConfirmResource.DialogActions.Button.j001}
        </LinkA>
        <Button
          onClick={() => openDialogCancelConfirm()}
          className={`${classes.buttonCancel} ${classes.dialogActionsButtonCancel}`}
        >
          {SlotConfirmResource.DialogActions.Button.j002}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleRegisterSlot()}
          disabled={isSubmitting}
          className={`${classes.buttonOk} ${classes.dialogActionsButtonOk}`}
        >
          {SlotConfirmResource.DialogActions.Button.j003}
        </Button>
      </DialogActions>
      <DialogCancelConfirm
        open={displayDialogCancelConfirm}
        closeDialogCancelConfirm={closeDialogCancelConfirm}
        handleClose={props.closeScheduleSelectionPopup}
      />
      <LoadingScreenForDialog isLoading={props.isLoading} />
    </React.Fragment>
  );
};

export default withStyles(styles)(SlotConfirm);
