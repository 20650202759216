import React, { useState } from "react";
import axios from "axios";
import { Hub } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import LogoOnlyHeaderTemplate from "../../templates/LogoOnlyHeaderTemplate";
import Titles from "../../../Resources/Titles";
import ForgotPasswordBeforeSendAuthCode from "../../organisms/authenticator/ForgotPasswordBeforeSendAuthCode";
import ForgotPasswordAfterSendAuthCode from "../../organisms/authenticator/ForgotPasswordAfterSendAuthCode";
import { japaneseList } from "../../../Resources/japaneseList";

const ForgotPasswordResource = japaneseList.Authenticator.ForgotPassword;

const ForgotPasswordPage = ({ authcb }) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [didSendAuthCode, setDidSendAuthCode] = useState(false);
  const [
    isEnabledAuthCodeSubmitButton,
    setIsEnabledAuthCodeSubmitButton,
  ] = useState(false);
  const [
    isEnabledPasswordChangeSubmitButton,
    setIsEnabledPasswordChangeSubmitButton,
  ] = useState(false);

  const doctorApiGatewayUrl = process.env.REACT_APP_DOCTOR_AWS_API_GATEWAY;
  const authCodeEndpoint = `${doctorApiGatewayUrl}/auth-code`;
  const passwordChangeEndpoint = `${doctorApiGatewayUrl}/change-password`;

  const returnToLoginPage = () => {
    authcb("SIGN_IN");
  };

  const displayErrorAsSnackBar = (err) => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: err, level: "error" } },
      "ForgotPassword"
    );
    // ボタン連打対応
    setIsEnabledAuthCodeSubmitButton(false);
    setIsEnabledPasswordChangeSubmitButton(false);
  };

  // 1. 認可コード取得
  const requestAuthCode = () => {
    // ボタン連打対応
    setIsEnabledAuthCodeSubmitButton(false);
    Sentry.captureMessage("password-send-authcode", Sentry.Severity.Log);
    axios
      .post(
        authCodeEndpoint,
        {
          email: email,
        },
        {
          headers: {
            "Client-Version": process.env.REACT_APP_CLIENT_VERSION,
          },
        }
      )
      .then((_) => {
        // 認可コードの送信が完了したら、ボタンの状態をリセットして、次の画面を表示するようにする。
        setIsEnabledAuthCodeSubmitButton(false);
        setDidSendAuthCode(true);
      })
      .catch((err) => {
        const resourceNotFoundError = "ResourceNotFoundError";
        const errorCode = err.response.data.errorCode;
        Sentry.captureMessage(
          "password-send-authcode-error-" + errorCode,
          Sentry.Severity.Log
        );
        if (errorCode === resourceNotFoundError) {
          displayErrorAsSnackBar(ForgotPasswordResource.requestAuthCode.j001);
        } else {
          // その他エラー
          displayErrorAsSnackBar(ForgotPasswordResource.requestAuthCode.j002);
        }
      });
  };

  // 2. パスワード変更
  const requestChangePassword = () => {
    Sentry.captureMessage("password-reset", Sentry.Severity.Log);
    // パスワードが「8文字以上」の条件を満たしていない
    const passwordMinLength = 8;
    if (!password || password.length < passwordMinLength) {
      Sentry.captureMessage(
        "password-reset-error-shortLength",
        Sentry.Severity.Log
      );
      displayErrorAsSnackBar(ForgotPasswordResource.submit.j001);
      return;
    }
    // パスワードがEmailの @ より前の文字列と一緒 or パスワードがEmailと同じ
    if (
      password === email.substring(0, email.lastIndexOf("@")) ||
      password === email
    ) {
      Sentry.captureMessage(
        "password-reset-error-sameAsEmail",
        Sentry.Severity.Log
      );
      displayErrorAsSnackBar(ForgotPasswordResource.submit.j003);
      return;
    }
    // パスワードが「大文字小文字数字含む」の条件を満たしていない
    const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);
    const isOkPassword = regex.test(password);
    if (!isOkPassword) {
      console.log(ForgotPasswordResource.submit.j002);
      Sentry.captureMessage(
        "password-reset-error-fewCharacterTypes",
        Sentry.Severity.Log
      );
      displayErrorAsSnackBar(ForgotPasswordResource.submit.j002);
      return;
    }
    // パスワードと確認用パスワードの入力が一致しない
    if (password !== passwordConfirm) {
      Sentry.captureMessage(
        "password-reset-error-notSameAsConfirm",
        Sentry.Severity.Log
      );
      displayErrorAsSnackBar(ForgotPasswordResource.submit.j004);
      return;
    }

    // ボタン連打対応
    setIsEnabledPasswordChangeSubmitButton(false);

    axios
      .post(
        passwordChangeEndpoint,
        {
          email: email,
          code: code,
          password: password,
        },
        {
          headers: {
            "Client-Version": process.env.REACT_APP_CLIENT_VERSION,
          },
        }
      )
      .then((_) => {
        // パスワード変更が完了したら、ボタンの状態をリセットして、ログインページに遷移
        setIsEnabledPasswordChangeSubmitButton(false);
        returnToLoginPage();
      })
      .catch((err) => {
        const invalidAuthCodeError = "InvalidAuthCodeError";
        const passwordValidationError = "PasswordValidationError";
        const errorCode = err.response.data.errorCode;
        Sentry.captureMessage(
          "password-reset-error-" + errorCode,
          Sentry.Severity.Log
        );
        // 認証コードが間違っている​（認証コードの有効期限が切れている場合も含む）
        if (errorCode === invalidAuthCodeError) {
          displayErrorAsSnackBar(ForgotPasswordResource.submit.j005);
          return;
        }
        // パスワードが「大文字小文字数字含む」の条件を満たしていない
        if (errorCode === passwordValidationError) {
          displayErrorAsSnackBar(ForgotPasswordResource.submit.j006);
          return;
        }
        // その他エラー
        displayErrorAsSnackBar(ForgotPasswordResource.submit.j007);
        return;
      });
  };

  // 認可コードを送る前と後でView を分けている
  const main = didSendAuthCode ? (
    <ForgotPasswordAfterSendAuthCode
      email={email}
      setEmail={setEmail}
      code={code}
      setCode={setCode}
      password={password}
      setPassword={setPassword}
      passwordConfirm={passwordConfirm}
      setPasswordConfirm={setPasswordConfirm}
      isEnabledPasswordChangeSubmitButton={isEnabledPasswordChangeSubmitButton}
      setIsEnabledPasswordChangeSubmitButton={
        setIsEnabledPasswordChangeSubmitButton
      }
      requestChangePassword={requestChangePassword}
      returnToLoginPage={returnToLoginPage}
    />
  ) : (
    <ForgotPasswordBeforeSendAuthCode
      setEmail={setEmail}
      isEnabledAuthCodeSubmitButton={isEnabledAuthCodeSubmitButton}
      setIsEnabledAuthCodeSubmitButton={setIsEnabledAuthCodeSubmitButton}
      requestAuthCode={requestAuthCode}
      returnToLoginPage={returnToLoginPage}
    />
  );

  return <LogoOnlyHeaderTemplate main={main} title={Titles.forgotPassword} />;
};

export default ForgotPasswordPage;
