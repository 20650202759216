// --------------------------------------------------
//
// file upload content
//
// --------------------------------------------------

// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";


// personal modules
// --------------------------------------------------
import FontP from "../../../atoms/fonts/FontP";
import FileuploadConfirmDialogFile from "./FileuploadConfirmDialogFile";
import {japaneseList} from "../../../../Resources/japaneseList";
const Resources = japaneseList.Components.Appointment.FileuploadConfirmDialog;

// style
// --------------------------------------------------
const styles = (theme) => ({
  customFontP: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: 1,
    paddingBottom: "10px",
  },
});

// class define
// --------------------------------------------------
class FileuploadConfirmDialogContent extends Component {

  // default state
  // --------------------------------------------------
  static defaultProps={
    desc:Resources.desc
  }

  // render
  // --------------------------------------------------
  render() {
    const { classes, desc, fileList } = this.props;
    return (
      <Grid container>
        <Grid container item xs={12}>
          <FontP className={classes.customFontP}>{desc}</FontP>
        </Grid>
        <FileuploadConfirmDialogFile fileList={fileList} />
      </Grid>
    );
  }
}

export default withStyles(styles)(FileuploadConfirmDialogContent);
