import React, { Component } from "react";
import { format } from "date-fns";
import { withStyles } from "@material-ui/core/styles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Button from "@material-ui/core/Button";
import { japaneseList } from "../../../../Resources/japaneseList";

const { datePostfix } = japaneseList.general;

const styles = (theme) => ({
  root: {
    display: "flex",
    margin: "0 0 17px",
    padding: "1px 0 0 1px",
    maxWidth: "200px",
    "& label": { fontSize: "17px" },
  },
  dateIcon: {
    border: "none !important",
  },
  calendarIcon: {
    color: "white",
    fontSize: 24,
  },
  calendarIconButton: {
    padding: 0,
    backgroundColor: theme.button.ok.backgroundColor,
    maxWidth: "32px",
    maxHeight: "32px",
    minWidth: "32px",
    minHeight: "32px",
    marginLeft: "-32px",
    borderRadius: "0 3px 3px 0",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
  },
  input: {
    width: "170px",
    height: "34px",
    lineHeight: "34px",
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    padding: "0 0 0 7px",
    fontSize: "14px",
    background: `${theme.color.primaryVariant3} 0% 0% no-repeat padding-box`,
    border: "1px solid #DDDDDD",
    borderRadius: "3px",
    color: "#393939",
    "&:focus": {
      outline: "none",
    },
  },
});

class MonthInput extends Component {
  render() {
    const { date, handleOpenMonthSelectDialog, classes } = this.props;
    return (
      <div className={classes.root} autoFocus>
        <input
          name="date"
          className={classes.input}
          value={format(date, `yyyy${datePostfix.year}M${datePostfix.month}`)}
          readOnly={true}
          onClick={handleOpenMonthSelectDialog}
        />
        <Button
          onClick={handleOpenMonthSelectDialog}
          variant="raised"
          color="primary"
          className={classes.calendarIconButton}
        >
          <DateRangeIcon className={classes.calendarIcon} />
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(MonthInput);
