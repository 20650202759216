import gql from "graphql-tag";

export const AttachmentPresignedUrlForGetObject = gql`
  query AttachmentPresignedUrlForGetObject(
    $filePath: String!
    $fileName: String!
  ) {
    attachmentPresignedUrlForGetObject(
      filePath: $filePath
      fileName: $fileName
    ) {
      url
    }
  }
`;
