// --------------------------------------------------
//
// Util Dialog class
//
// --------------------------------------------------

// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

// personal modules
// --------------------------------------------------
import { japaneseList } from "../../../Resources/japaneseList";

// style
// --------------------------------------------------
const styles = (theme) => ({
  styleDialogTitle: {
    padding: "25px 24px 0px 25px",
    fontSize: "14px",
    color: "#393939",
  },
  styleDialogContent: {
    color: "#F26666",
    fontSize: "12px",
  },
  styleCompleteDialogContent: {
    color: "#393939",
    fontSize: "14px",
    marginTop: "10px",
  },
  dialogActions: {
    padding: "14px 24px 16px 0px",
    margin: "0px",
  },
  buttonOk: {
    color: theme.button.ok.color,
    marginRight: "0px",
    width: "120px",
    height: "40px",
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    borderRadius: "6px",
    opacity: "1",
    margin: "0px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    fontWeight: "bold",
  },

  buttonComplete: {
    color: "white",
    marginRight: "0px",
    width: "120px",
    height: "40px",
    backgroundColor: "#808080",
    boxShadow: "0px 2px 0px #505050",
    borderRadius: "6px",
    opacity: "1",
    margin: "0px",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
    fontWeight: "bold",
  },

  buttonCancel: {
    backgroundColor: "gray",
    color: "white",
    width: "120px",
    height: "40px",
    background: "#808080 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 0px #505050",
    borderRadius: "6px",
    opacity: "1",
    margin: "0px 20px 0px 0px",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
    fontWeight: "bold",
  },
});

const Resource = japaneseList.molecules.dialogs.DialogConfirm;

// class define
// --------------------------------------------------
class DialogConfirm extends Component {
  // default
  // --------------------------------------------------
  static defaultProps = {
    onClose: function () {},
    open: false,
    title: "",
    buttons: {
      ok: {
        display: false,
        text: Resource.button.j001,
        handleClick: function () {},
      },
      cancel: {
        display: false,
        text: Resource.button.j002,
        handleClick: function () {},
      },
    },
    maxWidth: false,
  };

  _handleClose = () => {};

  render() {
    const { classes, open, title, children } = this.props;
    return (
      <Dialog open={open} maxWidth={"sm"} fullWidth={true}>
        <DialogTitle
          disableTypography={!this.props.isYamatoComplete}
          className={classes.styleDialogTitle}
        >
          {title}
        </DialogTitle>
        <DialogContent
          className={
            this.props.isYamatoComplete
              ? classes.styleCompleteDialogContent
              : classes.styleDialogContent
          }
        >
          {children}
        </DialogContent>
        <Divider />
        <DialogActions className={classes.dialogActions}>
          {this.props.isYamatoComplete ? (
            ""
          ) : (
            <Button
              className={classes.buttonCancel}
              onClick={this.props.handleCancelDialogConfirm}
            >
              {this.props.buttons.cancel.text}
            </Button>
          )}
          <Button
            className={
              this.props.isYamatoComplete
                ? classes.buttonComplete
                : classes.buttonOk
            }
            onClick={this.props.handleCloseDialogConfirm}
          >
            {this.props.isYamatoComplete
              ? Resource.button.j003
              : this.props.buttons.ok.text}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogConfirm);
