import jconv from "jconv";

/**
 * available characters definition for delivery comment
 * This definition is taken into the yamato-api's validation.
 * @type {RegExp}
 */
const availableCommentCharactersBase =
  /[0-9a-zA-Z０-９ａ-ｚＡ-Ｚあ-んゔぁ-ょア-ンヴァ-ョヶヵー]/g;
const availableCommentCharactersException =
  /[贒琇氿增倞濵桒瀨硺德栁彅昮羽埈淸曻福侚暲悊褜俉哿冝棈伃﨑昤賴埇髙鉎精杦祥ⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹ㈱㈲々ゝゞヽヾ〃\-.・ 　‐－─━]/g;

/**
 * Check if inputted word consists of only available characters by doctor delivery comment
 *   According to yamato-api's validation.
 * available characters definitions:
 * - Alphanumeric, Kana
 * - JIS(ISO-2022-JP) code Kanji 1~2 level,
 * - Some exception symbol and Kanji.
 * @param {String} input inputted word
 * @return {Boolean}
 */
const isValidYamato = (input) => {
  const convertedInput = input
    .replace(availableCommentCharactersBase, "")
    .replace(availableCommentCharactersException, "");

  // check JIS code Kanji level
  const hasTarget = Array.from(convertedInput).some((str) => {
    const buffer = jconv.encode(str, "ISO-2022-JP");

    return buffer.length !== 8 || buffer[3] < 48 || buffer[3] > 116;
  });

  return !hasTarget;
};

export { isValidYamato };
