import React from "react";

import { Heading4 } from '../../atoms/headings/Heading4';

export const Subtitle = ({ title }) => {
  return (
    <React.Fragment>
      <Heading4>{title}</Heading4>
    </React.Fragment>
  );
}