import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { japaneseList } from "../../../Resources/japaneseList";

const styles = theme => ({
  root: {
    padding: "0px 50px 50px"
  },
  outerBoarder:{
    padding: "0px 0px",
    border: "1px solid #cccccc",
    maxWidth: "708px",
  },
  contentborder: {
    padding: "0px 0px",
    maxWidth: "708px",
    minHeight: "65px"
  },
  title: {
    textAlign: "left",
    fontWeight: "bold",
    padding: "10px 0px 10px"
  },
  subtitleBlock: {
    width: "100px",
    minHeight: "65px",
    padding: "0px 0px 0px",
    backgroundColor: theme.color.secondaryVariant2
  },
  subtitleContent:{
    textAlign:"left",
    fontWeight: "bold",
    margin:"24px 0px 20px 10px"
  },
  content: {
    textAlign:"left",
    margin: "14px 10px "
  },
  hr: {
    margin: "0px 0px 0px",
    maxWidth: "100%",
    height: "0.8px",
    backgroundColor: "#cccccc",
    border: 0
  },
  commend:{
    marginTop: "5px",
    fontSize: "0.7rem",
    color: "#999999",
  }
});

const RecommendTerminal = props => {
  const { classes } = props;
  const langList = japaneseList.Components.RecommendTerminal;

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        {langList.j002}
      </Typography>
      <div className={classes.outerBoarder}>
        <Grid container className={classes.contentborder}>
          <Grid container className={classes.subtitleBlock}>
            <Typography className={classes.subtitleContent}>
              {langList.j003}
            </Typography>
          </Grid>
          <Grid className={classes.content}>
            <Typography variant="body1">{langList.j004}{langList.j005}</Typography>
            <Typography variant="body1">
              {langList.j006}
              {langList.j007} / {langList.j015}
            </Typography>
          </Grid>
        </Grid>
        <hr className={classes.hr} />
        <Grid container className={classes.contentborder}>
          <Grid className={classes.subtitleBlock}>
            <Typography className={classes.subtitleContent}>
              {langList.j008}
            </Typography>
          </Grid>
          <Grid className={classes.content}>
            <Typography variant="body1">{langList.j004}{langList.j009}</Typography>
            <Typography variant="body1">{langList.j006}{langList.j007}</Typography>
          </Grid>
        </Grid>
      </div>
      <Typography className={classes.title}>
        {langList.j010}
      </Typography>
      <div className={classes.outerBoarder}>
        <Grid container className={classes.contentborder}>
          <Grid className={classes.subtitleBlock}>
            <Typography className={classes.subtitleContent}>
              {langList.j011}
            </Typography>
          </Grid>
          <Grid className={classes.content}>
            <Typography variant="body1">{langList.j004}{langList.j012}</Typography>
            <Typography variant="body1">{langList.j006}{langList.j013}</Typography>
          </Grid>
        </Grid>
      </div>
      <Typography variant="body2" className={classes.commend}>{langList.j014}</Typography>
    </div>
  );
};

export default withStyles(styles)(RecommendTerminal);
