import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import FontSpan from "../../atoms/fonts/FontSpan";

const styles = () => ({
  dialogTitle: {
    padding: "24px",
    height: "68px",
  },
  dialogTitleContent: {
    fontSize: "20px",
    lineHeight: "0px",
    weight: "bold",
  },
  dialogActions: {
    padding: "14px 24px 14px 24px",
    height: "70px",
    margin: "0",
  },
  maxHeightWidthDialog: {
    maxHeight: "96%",
    maxWidth: "810px",
  },
});

const DialogMedical = (props) => {
  const { classes, open, children, dialogActions, title, handleClose } = props;

  return (
    <Dialog
      open={open}
      maxWidth={false}
      fullWidth={true}
      PaperProps={{ classes: { root: classes.maxHeightWidthDialog } }}
      onClose={handleClose}
      onExit={() => {}}
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        <FontSpan weight="bold" className={classes.dialogTitleContent}>
          {title}
        </FontSpan>
      </DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        {dialogActions}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DialogMedical);
