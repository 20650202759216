import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LoadingScreen from "../../molecules/others/LoadingScreen";
import RecommendTerminal from "../common/RecommendTerminal";
import { japaneseList } from "../../../Resources/japaneseList";
import Heading1 from "../../atoms/headings/Heading1";
import iconBlockPath from "../../../images/error_outline-24px_red.png";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";

const styles = (theme) => {
  return {
    root: {
      width: "810px",
      margin: theme.spacing.unit * 5,
      marginTop: "85px",
      fontFamily:
        "'Avenir','Helvetica Neue','Helvetica','Arial','Hiragino Sans','ヒラギノ角ゴシック',YuGothic,'Yu Gothic','メイリオ', Meiryo,'ＭＳ Ｐゴシック','MS PGothic'",
    },
    title: {
      fontWeight: "bold",
      color: "#202020",
      margin: "0px 50px 0px",
      fontSize: "20px",
    },
    subtitle: {
      margin: "50px 50px 10px",
      textAlign: "left",
      fontWeight: "bold",
    },
    info: {
      margin: "10px 50px 10px",
      textAlign: "left",
      color: "#393939",
    },
    extra: {
      marginBottom: "25px",
      color: theme.font.accent,
    },
    action: {
      margin: "auto",
      minWidth: theme.spacing.unit * 15,
    },
    browserInfo: {
      marginTop: theme.spacing.unit * 10,
    },
    contactInfo: {
      margin: "0px 0px",
      color: "#393939",
      maxWidth: "500px",
      textAlign: "left",
      paddingTop: "10px",
      paddingLeft: "10px",
      paddingRight: "15px",
    },
    contactborder: {
      margin: "0px 50px 26px",
      color: "#393939",
      maxWidth: "708px",
      minheight: "340px",
      border: "1px solid #cccccc",
      paddingTop: "10px",
      paddingLeft: "10px",
    },
    contactInfoTitle: {
      fontWeight: "bold",
    },
    contactInfoContent: {
      color: "#393939",
      marginTop: "1px",
      marginBottom: "1px",
    },
    warningborder: {
      margin: "50px 50px 50px",
      paddingTop: "0px",
      paddingBottom: "15px",
      top: "144px",
      left: "157px",
      maxWidth: "710px",
      minHeight: "117px",
      background: "#FFF1F2 0% 0% no-repeat padding-box",
      opacity: "1",
    },
    alertIcon: {
      top: "165px",
      left: "177px",
      marginTop: "21px",
      marginRight: "6px",
      width: "24px",
      height: "24px",
      opacity: "1",
      float: "left",
    },
    warningInfoTitle: {
      top: "164px",
      left: "207px",
      marginTop: "20px",
      maxWidth: "398px",
      minHeight: "26px",
      textAlign: "left",
      fontSize: "20px",
      letterSpacing: "0px",
      color: "#EF5E5E",
      fontWeight: "bold",
      opacity: "1",
      float: "left",
    },
    warningInfoContent: {
      paddingTop: "13px",
      top: "202px",
      left: "177px",
      maxWidth: "670px",
      minHeight: "39px",
      textAlign: "left",
      fontSize: "14px",
      letterSpacing: "0px",
      color: "#EF5E5E",
      opacity: "1",
      clear: "left",
    },
  };
};

const UnsupportedDevice = (props) => {
  const { classes } = props;

  const ContactResource = japaneseList.Pages.UnsupportedDevice;

  const generateMessage = () => {
    const generateMessageResource = ContactResource.generateMessageResource;
    const newLineCode = "%0D%0A";
    const userAgent = window.navigator.userAgent;
    const msg =
      "mailto:telemedicine_doctor@caradamedica.co.jp?subject=" +
      generateMessageResource.j001 +
      "&body=" +
      userAgent +
      newLineCode +
      newLineCode +
      generateMessageResource.j002 +
      newLineCode +
      "--------------------------" +
      newLineCode +
      generateMessageResource.j003 +
      newLineCode +
      "--------------------------" +
      newLineCode +
      generateMessageResource.j004 +
      newLineCode +
      newLineCode +
      newLineCode +
      generateMessageResource.j005 +
      newLineCode +
      newLineCode +
      newLineCode +
      generateMessageResource.j006 +
      newLineCode +
      newLineCode +
      newLineCode +
      generateMessageResource.j007 +
      newLineCode +
      newLineCode;

    return msg;
  };
  
  const msg = generateMessage();

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <CardContent className={classes.warningborder}>
          <Typography className={classes.alertIcon}>
            <img src={iconBlockPath} alt={''}></img>
          </Typography>
          <Typography className={classes.warningInfoTitle}>
            {ContactResource.render.Card.CardContent.Warning.j001}
          </Typography>
          <Typography className={classes.warningInfoContent}>
            {ContactResource.render.Card.CardContent.Warning.j002}
          </Typography>
        </CardContent>
        <Heading1 className={classes.title}>
          {ContactResource.render.Card.CardContent.Heading.j001}
        </Heading1>
        <RecommendTerminal />
        <Heading1 className={classes.title}>
          {ContactResource.render.Card.CardContent.Heading.j002}
        </Heading1>
        <Typography className={classes.info}>
          {ContactResource.render.Card.CardContent.Typography.j001}
        </Typography>
        <CardContent className={classes.contactborder}>
          <CardContent className={classes.contactInfo}>
            <Typography className={classes.contactInfoTitle}>
              {ContactResource.render.Card.CardContent.Typography.j003}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j004}
            </Typography>
            <br />
            <Typography className={classes.contactInfoTitle}>
              {ContactResource.render.Card.CardContent.Typography.j005}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j006}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j007}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j008}
            </Typography>
            <br />
            <Typography className={classes.contactInfoTitle}>
              {ContactResource.render.Card.CardContent.Typography.j009}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j010}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j011}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j012}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j013}
            </Typography>
            <Typography className={classes.contactInfoContent}>
              {ContactResource.render.Card.CardContent.Typography.j014}
            </Typography>
          </CardContent>
        </CardContent>
        <CardActions>
          <Button
            className={classes.action}
            size="large"
            disableRipple
            color="primary"
            variant="raised"
            href={msg}
          >
            {ContactResource.render.Card.CardActions.Button.j001}
          </Button>
        </CardActions>
        <CardContent>
          <Typography
            variant="caption"
            className={classes.extra}
            gutterBottom
            align="center"
          >
            {ContactResource.render.Card.CardContent.Typography.j002}
          </Typography>
        </CardContent>
      </Card>
      <LoadingScreen isLoading={false} />
    </React.Fragment>
  );
};

export default withStyles(styles)(UnsupportedDevice);
