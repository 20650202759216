import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  DialogContent,
  Typography,
  Grid,
  DialogTitle,
  Divider,
} from "@material-ui/core/";
import FontSpan from "../../../atoms/fonts/FontSpan";
import AccountImage from "../../../../images/account_circle-24px.svg";
import { parseISO, format } from "date-fns";
import { ja } from "date-fns/locale";
import { japaneseList } from "../../../../Resources/japaneseList";
import {
  getAge,
  getDayTextJaFormatShort,
} from "../../../../Utils/DisplayLogic";
import NumberFormat from "react-number-format";
import { prefectureCodes } from "../../../../Utils/PrefectureCode";
import {
  DELIVERY_OPTION,
  MEDICAL_METHOD,
  PAYMENT_METHOD,
} from "../../../../Utils/const";
import { deliveryFeeCalculator } from "../../../../Utils/DeliveryFeeCalculator";
import YamatoDeliveryFeeContent from "./YamatoDeliveryFeeContent";

const Resource = japaneseList.Components.Payment.YamatoDeliveryDialog;
const DeliveryProductName = japaneseList.models.appointment.deliveryProductName;
const DeliveryProcessResources =
  japaneseList.Components.Payment.DeliveryProcessDialog;
const pickUpTimeSpanCandidates = {
  "07": Resource.onEnter.j001,
  "08": Resource.onEnter.j002,
  "09": Resource.onEnter.j003,
  10: Resource.onEnter.j004,
  "06": Resource.onEnter.j005,
};

const styles = {
  dialogTitle: {
    fontSize: "20px",
    lineHeight: "0px",
  },

  tableStyle: {
    borderCollapse: "collapse",
    width: "310px",
    tableLayout: "auto",
  },
  thBirthDay: {
    width: "98px",
  },
  thAge: {
    width: "26px",
  },
  thGender: {
    width: "48px",
  },
  thPhonenumber: {
    width: "120px",
  },

  thStyle: {
    padding: "0.25rem",
    textAlign: "center",
    borderTop: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    backgroundColor: "#eee",
    fontSize: "12px",
    color: "#808080",
  },

  tdStyle: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    color: "#393939",
    whiteSpace: "nowrap",
  },

  tdStyleGenderMale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },

  tdStyleGenderFemale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },

  containerTable: {
    display: "flex",
    boxSizing: "border-box",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "space-between",
    margin: "8px 0 25px 0",
  },

  containerBorder: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
  },

  container: {
    display: "grid",
    gridTemplateColumns: "80px auto",
    gridGap: "20px",
    borderTop: "#ccc solid 1px",
  },

  titleItem: {
    backgroundColor: "#eee",
    padding: "8px 10px 8px 10px",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    color: "#808080",
    fontWeight: "bold",
    textAlign: "left",
    whiteSpace: "pre-wrap",
  },

  contentItem: {
    padding: "6px 20px 6px 0px",
    fontSize: "14px",
    color: "#393939",
    textAlign: "left",
    marginTop: "auto",
    marginBottom: "auto",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },

  contentItemComment: {
    padding: "6px 20px 6px 0px",
    fontSize: "14px",
    color: "#393939",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    textAlign: "left",
    marginTop: "auto",
    marginBottom: "auto",
  },

  maxHeightWidthDialog: {
    maxHeight: "96%",
    maxWidth: "810px",
  },
  nameStyle: {
    fontWeight: "bold",
    fontSize: "16px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  nameKanaStyle: {
    fontSize: "12px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  messageConfirm: {
    fontSize: "14px",
    marginTop: "25px",
    marginBottom: "15px",
  },
  noteContent: {
    fontSize: "11px",
    lineHeight: "20px",
    color: "#aaaaaa",
  },
};

class YamatoDeliveryDetail extends Component {
  render() {
    const { classes, appointment, mode } = this.props;
    const { name, pickUpDate, pickUpTimeSpan, comment } =
      this.props.requestDelivery;

    const { patient, from, doctor, menu } = appointment;
    const { address } = patient;

    const prefecture = prefectureCodes.find(
      (c) => address.prefectureCode === c.code
    ).jpName;
    const postalCode = `${address.postalCode.slice(
      0,
      3
    )}-${address.postalCode.slice(3)}`;

    const displayDeliveryFee = deliveryFeeCalculator(
      appointment,
      DELIVERY_OPTION.yamato
    );

    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;

    return (
      <React.Fragment>
        <DialogTitle>
          <FontSpan weight="bold" className={classes.dialogTitle}>
            {mode === "confirm" && Resource.DialogTitle.confirm}
            {mode === "reset" && Resource.DialogTitle.reset}
            {mode === "result" && Resource.DialogTitle.result}
          </FontSpan>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography style={{ marginTop: "20px" }}>
            {Resource.DialogContent.description}
          </Typography>
          {patient && (
            <Grid container wrap="nowrap" style={{ marginTop: "10px" }}>
              <Grid item style={{ padding: "0px 10px 0px 0px" }}>
                <img src={AccountImage} alt="" />
              </Grid>
              <Grid item xs>
                <Typography className={classes.nameStyle}>
                  {patient.familyName} {patient.givenName}
                </Typography>
                <Typography className={classes.nameKanaStyle}>
                  {patient.familyNameKana} {patient.givenNameKana}
                </Typography>
              </Grid>
            </Grid>
          )}
          {patient && (
            <div className={classes.containerTable}>
              <table className={classes.tableStyle}>
                <thead>
                  <tr>
                    <th className={`${classes.thStyle} ${classes.thBirthDay}`}>
                      {Resource.DialogContent.table.thBirthDay}
                    </th>
                    <th className={`${classes.thStyle} ${classes.thAge}`}>
                      {Resource.DialogContent.table.thAge}
                    </th>
                    <th className={`${classes.thStyle} ${classes.thGender}`}>
                      {Resource.DialogContent.table.thGender}
                    </th>
                    <th
                      className={`${classes.thStyle} ${classes.thPhonenumber}`}
                    >
                      {Resource.DialogContent.table.thPhonenumber}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={classes.tdStyle}>
                      {format(
                        new Date(patient.birthdate),
                        Resource.Format.j001
                      )}
                    </td>
                    <td className={classes.tdStyle}>
                      {patient.birthdate ? getAge(patient.birthdate) : " "}
                    </td>
                    <td
                      className={
                        patient.gender &&
                        patient.gender ===
                          Resource.DialogContent.table.genderMale
                          ? classes.tdStyleGenderMale
                          : classes.tdStyleGenderFemale
                      }
                    >
                      {patient.gender}
                    </td>
                    <td className={classes.tdStyle}>
                      <NumberFormat
                        value={patient.phoneNumber}
                        displayType={"text"}
                        format="###-####-####"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {mode === "confirm" && (
            <p className={classes.messageConfirm}>
              {Resource.DialogContent.messageConfirm}
            </p>
          )}
          {mode === "reset" && (
            <div className={classes.messageConfirm}>
              {DeliveryProcessResources.ResetQuestion}
            </div>
          )}
          <div className={classes.containerBorder}>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.undertaker}
              </div>
              <div className={classes.contentItem}>
                {menu && menu.medicalMethod === MEDICAL_METHOD.OFFLINE
                  ? Resource.DialogContent.containerBorder.offline
                  : `${doctor.familyName} ${doctor.givenName}`}
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.examDate}
              </div>
              <div className={classes.contentItem}>
                {from ? getDayTextJaFormatShort(from) : ""}
              </div>
            </div>
            {appointment.delivery ? (
              <div className={classes.container}>
                <div className={classes.titleItem}>
                  {Resource.DialogContent.containerBorder.slipNumber}
                </div>
                <div className={classes.contentItem}>
                  <NumberFormat
                    value={appointment.delivery.voucherId}
                    displayType={"text"}
                    format="####-####-####"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.deliveryAddress}
              </div>
              <div className={classes.contentItem}>
                {postalCode
                  ? `${Resource.DialogContent.containerBorder.prefixImage}${postalCode}`
                  : ""}
                <br />
                {`${prefecture} ${address.addressCity} ${
                  address.addressStreetNumber
                } ${address.addressBuilding || ""}`}
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.patientName}
              </div>
              <div className={classes.contentItem}>
                {`${patient.familyName} ${patient.givenName} (${patient.familyNameKana} ${patient.givenNameKana})`}
              </div>
            </div>
            {!isBillingEnterprise &&
              displayDeliveryFee &&
              displayDeliveryFee.deliveryFee &&
              displayDeliveryFee.deliveryArrangementFee && (
                <YamatoDeliveryFeeContent
                  deliveryFee={displayDeliveryFee.deliveryFee}
                  deliveryArrangementFee={
                    displayDeliveryFee.deliveryArrangementFee
                  }
                />
              )}
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.collectionDate}
              </div>
              <div className={classes.contentItem}>
                {appointment.delivery
                  ? format(
                      parseISO(appointment.delivery.pickUpDate),
                      `yyyy${Resource.DialogContent.j003}M${Resource.DialogContent.j001}d${Resource.DialogContent.j002} (iii)`,
                      { locale: ja }
                    )
                  : pickUpDate
                  ? format(
                      parseISO(pickUpDate),
                      `yyyy${Resource.DialogContent.j003}M${Resource.DialogContent.j001}d${Resource.DialogContent.j002} (iii)`,
                      { locale: ja }
                    )
                  : ""}
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.timeSpan}
              </div>
              <div className={classes.contentItem}>
                {appointment.delivery
                  ? pickUpTimeSpanCandidates[
                      appointment.delivery.pickUpTimeSpan
                    ]
                  : pickUpTimeSpanCandidates[pickUpTimeSpan]}
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.content}
              </div>
              <div className={classes.contentItem}>
                {appointment.delivery
                  ? DeliveryProductName[appointment.delivery.name]
                  : DeliveryProductName[name]}
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resource.DialogContent.containerBorder.comment}
              </div>
              <div className={classes.contentItemComment}>
                {appointment.delivery
                  ? appointment.delivery.comment &&
                    appointment.delivery.comment.trim()
                  : comment && comment.trim()}
              </div>
            </div>
          </div>
        </DialogContent>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(YamatoDeliveryDetail);
