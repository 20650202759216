import gql from "graphql-tag";

export const UpdateAppointmentOnline = gql`
  mutation settle(
    $patientId: String!
    $createTime: String!
    $medicalExpense: Int!
    $billingCategory: BillingCategory
    $drugsName: String
    $drugsQuantity: String
    $memo: String
    $medicalDoctorId: String
  ) {
    settle(
      patientId: $patientId
      createTime: $createTime
      medicalExpense: $medicalExpense
      billingCategory: $billingCategory
      drugsName: $drugsName
      drugsQuantity: $drugsQuantity
      memo: $memo
      doctorId: $medicalDoctorId
    ) {
      succeed
    }
  }
`;
