import React from "react";
import styled from "styled-components";

const MemoContainer = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  grid-gap: 20px;
  border-top: #ccc solid 1px;
`;

const TitleItem = styled.div`
  background-color: #eee;
  padding: 8px 10px 8px 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #808080;
  font-weight: bold;
  text-align: left;
  white-space: pre-wrap;
`;
const ContentItem = styled.div`
  padding: 8px 20px 8px 0px;
  font-size: 14px;
  color: #393939;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  word-break: break-word;
  white-space: pre-wrap;
`;

const FontPValidateError = styled.div`
  margin: auto;
  color: #ef5e5e;
  font-weight: bold;
`;

const TextareaCustom = styled.textarea`
  background-color: #eee;
  display: flex;
  width: 100%;
  height: 64px;
  resize: none;
  padding: 5px 10px;
  font-family: inherit;
  border: solid 1px #eee;
  font-size: 14px;
  -webkit-appearance: none;
  background: #eeeeee 0% 0% no-repeat padding-box;
  box-shadow: -1px -1px 0px #cccccc;
  border-radius: 3px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #999999;
  }
  color: #393939;
  &:enabled {
    background: ${(p) =>
      p.isValidateError ? "#FFF1F2" : p.theme.color.primaryVariant3};
  }
`;

export const MemoTextarea = (props) => {
  return (
    <MemoContainer>
      <TitleItem>
        <div>{props.title}</div>
      </TitleItem>
      <ContentItem>
        {!props.checkMemoInput && (
          <FontPValidateError size="s">
            {props.memoErrMessage}
          </FontPValidateError>
        )}
        <TextareaCustom
          value={props.memoValue}
          placeholder={props.placeholder}
          onChange={props.handleChangeMemo}
          disabled={props.disabled}
          className={props.hasErrorClass}
          isValidateError={!props.checkMemoInput}
        ></TextareaCustom>
      </ContentItem>
    </MemoContainer>
  );
};
