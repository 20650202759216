import React, { Component } from "react";
import {
  addMonths,
  startOfMonth,
  endOfMonth,
  isBefore,
  isAfter,
} from "date-fns";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MonthInput from "./MonthInput";
import MonthSelectDialog from "./MonthSelectDialog";
import { japaneseList } from "../../../../Resources/japaneseList";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
  },
  changeDateButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "26px",
    width: "134px",
  },
  changeDateButton: {
    padding: 0,
    width: "62px",
    height: "34px",
    minWidth: "auto",
    minHeight: "auto",
    lineHeight: "34px",
    borderRadius: "3px",
    backgroundColor: theme.button.ok.backgroundColor,
    fontSize: "14px",
    alignItems: "center",
    fontWeight: "bold",
    boxShadow: `0px 1px 0px ${theme.color.shadow}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    "&:disabled": {
      backgroundColor: "#dddddd",
      color: "#fff",
    },
  },
});

class MonthSelectOperator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMonthSelectDialog: false,
    };
  }

  handleOpenMonthSelectDialog = () => {
    this.setState({ isOpenMonthSelectDialog: true });
  };

  handleChangeDate = async (date) => {
    this.setState({ isOpenMonthSelectDialog: false });
    await this.props.handleChangeDate(date);
  };

  handleCloseMonthSelectDialog = () => {
    this.setState({ isOpenMonthSelectDialog: false });
  };

  handleChangePrevMonth = async () => {
    const changedDate = addMonths(this.props.selectedDate, -1);
    await this.props.handleChangeDate(changedDate);
  };

  handleChangeNextMonth = async () => {
    const changedDate = addMonths(this.props.selectedDate, 1);
    await this.props.handleChangeDate(changedDate);
  };

  render() {
    const { selectedDate, minimumDate, maximumDate, classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <MonthInput
            date={selectedDate}
            handleChangeDate={this.handleChangeDate}
            handleOpenMonthSelectDialog={this.handleOpenMonthSelectDialog}
            className={classes.monthInput}
          />
          <div className={classes.changeDateButtonWrapper}>
            <Button
              variant="raised"
              onClick={this.handleChangePrevMonth}
              disabled={!isAfter(startOfMonth(selectedDate), minimumDate)}
              color="primary"
              className={classes.changeDateButton}
            >
              {japaneseList.atoms.forms.DateSelect.prevMonth}
            </Button>
            <Button
              variant="raised"
              onClick={this.handleChangeNextMonth}
              disabled={!isBefore(endOfMonth(selectedDate), maximumDate)}
              color="primary"
              className={classes.changeDateButton}
            >
              {japaneseList.atoms.forms.DateSelect.nextMonth}
            </Button>
          </div>
        </div>
        <MonthSelectDialog
          open={this.state.isOpenMonthSelectDialog}
          date={selectedDate}
          minimumDate={minimumDate}
          maximumDate={maximumDate}
          handleChangeDate={this.handleChangeDate}
          handleClose={this.handleCloseMonthSelectDialog}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MonthSelectOperator);
