import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { client, GetConsentStatus } from "../../../graphql";
import { CONSENT_STATUS } from "../../../Utils/const";
import { japaneseList } from "../../../Resources/japaneseList";
import { formatDay } from "../../../Utils/DateUtil";

import FontPBold from "../../atoms/fonts/FontPBold";
import FontBase from "../../atoms/fonts/FontP";

const ConsentStatusAreaResource = japaneseList.Pages.Patient.ConsentStatusArea;

const Title = styled(FontPBold)`
  font-size: 14px;
  margin-bottom: 10px;
`;
const MessageBox = styled.div`
  background-color: white;
  padding: 14px;
`;
const Message = styled(FontBase)`
  color: #393939;
  font-size: 14px;
`;
const MessageSub = styled(FontBase)`
  color: #808080;
  font-size: 12px;
`;

interface Props {
  patientId: string;
}
type Status = "unregistered" | "registering" | "registered";
interface ConsentStatus {
  consentStatus: Status;
  consentExpirationDate?: string;
}

export const ConsentStatusArea: React.FC<Props> = (props) => {
  const [consentStatus, setConsentStatus] = useState<ConsentStatus | null>(
    null
  );
  useEffect(() => {
    if (props.patientId) loadConsentStatus(props.patientId);
  }, [props.patientId]);

  const loadConsentStatus = async (patientId?: string): Promise<void> => {
    const { data, errors } = await client.query({
      query: GetConsentStatus,
      variables: { patientId },
    });

    if (errors) {
      console.error("GetConsentStatus errors:", JSON.stringify(errors));
    }
    if (data && data.consentStatus) {
      setConsentStatus(data.consentStatus);
    }
  };

  const shouldShowExpirationDate = (): boolean => {
    return (
      consentStatus?.consentStatus === CONSENT_STATUS.REGISTERED ||
      consentStatus?.consentStatus === CONSENT_STATUS.REGISTERING
    );
  };
  const formatDate = (consentExpirationDate?: string): string => {
    if (!consentExpirationDate) return "";
    return formatDay(parseInt(consentExpirationDate));
  };

  return (
    <>
      <Title>{ConsentStatusAreaResource.j001}</Title>
      <MessageBox>
        <Message>
          {ConsentStatusAreaResource.j002 +
            ConsentStatusAreaResource.consentStatus[
              consentStatus?.consentStatus ??
                (CONSENT_STATUS.UNREGISTERED as Status)
            ]}
        </Message>
        {shouldShowExpirationDate() && (
          <MessageSub>
            {formatDate(consentStatus?.consentExpirationDate) +
              ConsentStatusAreaResource.j003}
          </MessageSub>
        )}
      </MessageBox>
    </>
  );
};
