import * as Sentry from "@sentry/browser";

export function reload() {
  let forceReloadCount = localStorage.getItem("forceReloadCount");
  if (!forceReloadCount) {
    localStorage.setItem("forceReloadCount", "1");

    window.location.reload();
  } else if (4 <= forceReloadCount) {
    Sentry.captureMessage("call-not-access-page", Sentry.Severity.Log);
    localStorage.removeItem("forceReloadCount");
    window.location.href = "/not-access";
  } else {
    forceReloadCount = parseInt(forceReloadCount, 10) + 1;
    localStorage.setItem("forceReloadCount", forceReloadCount);

    window.location.reload();
  }

  console.log("forceReloadCount", localStorage.getItem("forceReloadCount"));
}
