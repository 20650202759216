import Amplify from "aws-amplify";
import aws_exports from "../../aws-exports";

Amplify.configure(aws_exports);

// AppSync Configuration
Amplify.configure({
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: "ap-northeast-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
});