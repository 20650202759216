import gql from "graphql-tag";

export const GetPatientByPatientId = gql`
  query GetPatientByPatientId($patientId: String!) {
    patient(patientId: $patientId) {
      familyName
      givenName
      familyNameKana
      givenNameKana
      gender
      birthdate
      phoneNumber
      email
      cardStatus
      patientId
      organizationId
      insuranceCard {
        fileName
        fileType
        filePath
        updatedTime
      }
      address {
        postalCode
        prefectureCode
        addressCity
        addressStreetNumber
        addressBuilding
      }
      videoChatAppointmentKey
    }
  }
`;
