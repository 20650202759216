import React from "react";
import { Helmet } from "react-helmet";

// TODO: なんとかして削除する
function ReactBigCalendarCustomCss(props) {
  const calendar = props.theme.calendar;
  return (
    <Helmet>
      <style type="text/css">{`
/* Calender background */
.rbc-time-view {
  background-color: #ffffff;
}

.rbc-month-view {
  background-color: #ffffff;
}

/* Today's style */
.rbc-today {
  background-color: ${calendar.bg.color.today};
}

/* set dashed style to horizontal line of half hour */

.rbc-timeslot-group:nth-child(odd) {
  border-bottom: 1px dashed ${calendar.border.color.timeslotgroup};
}

/** Toolbar */

.rbc-label {
  display: inline-block;
  position: relative;
  top: -12px;
  font-size: small;
}

/* Between header and calender margin */
.custom-toolbar {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 22px;
  font-size: 16px;
  justify-content: space-between;
}

.rbc-toolbar {
  margin-bottom: 0;
}

.rbc-toolbar-label {
  font-weight: bolder;
}

.rbc-toolbarLnln {
  margin-bottom: 0;
}

.rbc-toolbarLnln-label {
  font-weight: bolder;
}

/** horizontal indicator of 'now' */
.rbc-time-content > .rbc-current-time-indicator {
  background-color: transparent;
  border: 1px dashed ${calendar.border.color.currenttime};
  /* set higher priority than element style */
  left: 48px !important;
  right: 0px !important;
  width: calc(100% - 48px) !important;
}

/** calender's header */

.rbc-events-container > .rbc-event {
  font-size: smaller;
  border-style: none;
  border: 1px solid white;
}

.rbc-header > a > span {
  vertical-align: middle;
  line-height: 30px;
  color: #ffffff;
}

.rbc-month-header > .rbc-header > span {
  vertical-align: middle;
  line-height: 30px;
  color: #ffffff;
}

.rbc-time-content {
  top: -1px;
  border-top: 1px solid #dddddd;
}

.rbc-row.rbc-month-header {
  background-color: #999999;
  height: 30px;
}

.rbc-row.rbc-time-header-cell {
  height: 30px;
  background-color:#999999;
}

.rbc-time-header-content {
  background-color: #f6f6f6;
}

.rbc-header.rbc-today {
  background-color: ${calendar.bg.color.header};
}

/* hide all-day place */
.rbc-allday-cell {
  display: none;
}

/* Events */
.rbc-event-content {
  font-size: smaller;
}

.rbc-event-label {
  font-size: smaller;
}

.rbc-btn-group {
  background-color: #ffffff;
}

.is-slot {
  background-color: ${calendar.bg.color.slot};
}

.status-before-exam {
  background-color: ${calendar.bg.color.before};
}

.status-exam-completed {
  background-color: #dddddd;
  color: black;
}

.is-past {
  background-color: #dddddd;
  color: black;
}

/* Outer border style */
.rbc-time-view {
  border: 2px solid #c1c1c1;
}

.rbc-month-view {
  border: 2px solid #c1c1c1;
}

.rbc-day-bg.rbc-off-range-bg {
  background-color: #ffffff;
}

.rbc-date-cell.rbc-off-range {
  color: #cccccc;
}

/* left side time label */
.rbc-time-gutter > .rbc-timeslot-group {
  border-bottom-style: none;
}

.rbc-show-more {
  text-align: right;
  color: #999999;
  font-weight: normal;
  font-size: small;
  text-decoration: none;
}

.rbc-show-more:hover {
  text-decoration: underline;
}

.rbc-createbtn {
  cursor: pointer;
  border: 0 !important;
  margin-left: 10px !important;
}
    `}</style>
    </Helmet>
  );
}
export default ReactBigCalendarCustomCss;
