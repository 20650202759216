import React from "react";
import styled from "styled-components";
import LoadingScreen from "./LoadingScreen";

const LoadingScreenCustom = styled(LoadingScreen)`
  z-index: 2000;
`;

export const LoadingScreenForDialog = (props) => {
  const { isLoading } = props;
  return <LoadingScreenCustom isLoading={isLoading} />;
};
