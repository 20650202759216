import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import NativeSelect from "@material-ui/core/NativeSelect";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Fade from "@material-ui/core/Fade";
import EventDetail from "../../../organisms/calendar/EventDetail";
import * as Sentry from "@sentry/browser";
import {
  client,
  ListItem,
  GetDoctorAndChooseDoctors,
  GetHospital,
} from "../../../../graphql";

import locale from "date-fns/locale/ja";
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  setHours,
  format,
  isBefore,
  differenceInCalendarDays,
  subMilliseconds,
  parseISO,
  addYears,
  isAfter,
} from "date-fns";

import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "./react-big-calendar.css";
import ReactBigCalendarCustomCss from "./ReactBigCalendarCustomCss";

import "moment/locale/ja";
import Dialog from "@material-ui/core/Dialog";
import { isLnln } from "../../../../Utils/checkLnln";
import { oneYearLimit } from "../../../../Utils/DateUtil";
import {
  getAppointmentValidationResult,
  getSlotMaxCapacity,
  getSlotTimeFrameLength,
  getSlotValidationResult,
  registerAppointment,
  registerSlot,
  registrationSlotApiErrorMessages,
} from "../../../../Utils/Appointment";
import CalendarStepper from "../../../organisms/appointment/Stepper/CalendarStepper";
import DialogConfirmRegistration from "../../../organisms/appointment/DialogConfirmRegistration";

import Toolbar from "../../../organisms/calendar/Toolbar";
import EventMonth from "../../../organisms/calendar/EventMonth";
import EventWeek from "../../../organisms/calendar/EventWeek";
import Finish from "../../../organisms/appointment/Stepper/Finish";

import Heading1 from "../../../atoms/headings/Heading1";
import LoadingScreen from "../../../molecules/others/LoadingScreen";
import BasicTemplate from "../../../templates/BasicTemplate";
import { japaneseList } from "../../../../Resources/japaneseList";
import Titles from "../../../../Resources/Titles";

import * as theme from "../../../../themes/styled-components/themeCarada";
import * as themeLnln from "../../../../themes/styled-components/themeLnln";
import jaLocale from "date-fns/locale/ja";

const theme_ = isLnln() ? themeLnln : theme;
const FLOW = {
  APPOINTMENT: 1,
  SLOT: 2,
};

moment.locale("ja-JP");
const localizer = momentLocalizer(moment);

const CalendarResource = japaneseList.Pages.Appointment.Calendar;
const textFormats = {
  eventTimeRangeFormat: ({ start, end }) =>
    format(start, "HH:mm", { locale }) +
    " - " +
    format(end, "HH:mm", { locale }),
  dayRangeHeaderFormat: ({ start, end }) =>
    format(
      start,
      `M${CalendarResource.textFormat.dayRangeHeaderFormat.j001} d${CalendarResource.textFormat.dayRangeHeaderFormat.j002}`,
      { locale }
    ) +
    " - " +
    format(
      end,
      `M${CalendarResource.textFormat.dayRangeHeaderFormat.j001} d${CalendarResource.textFormat.dayRangeHeaderFormat.j002}`,
      { locale }
    ),
  dayHeaderFormat: (date) =>
    format(
      date,
      `M${CalendarResource.textFormat.dayHeaderFormat.j001} d${CalendarResource.textFormat.dayHeaderFormat.j002} (iii)`,
      { locale }
    ),
  monthHeaderFormat: (date) =>
    format(
      date,
      `yyyy${CalendarResource.textFormat.monthHeaderFormat.j001} M${CalendarResource.textFormat.monthHeaderFormat.j002}`,
      { locale }
    ),
  dayFormat: (date) => format(date, "d (iii)", { locale }),
};

const getMessages = function (currentView) {
  return {
    week: CalendarResource.messages.j001,
    month: CalendarResource.messages.j002,
    today: CalendarResource.messages.j003,
    createAppo: CalendarResource.messages.j006,
    createSlot: CalendarResource.messages.j007,
    previous: CalendarResource.messages[currentView].previous,
    next: CalendarResource.messages[currentView].next,
    showMore: (count) =>
      `${CalendarResource.messages.j004}${count}${CalendarResource.messages.j005}`,
  };
};

const styles = (theme) => ({
  root: {
    minWidth: "calc(100vw - 100px)",
    marginTop: theme.spacing.unit * 2,
  },
  legendContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "16px",
    marginTop: "10px",
  },
  legendItem: {
    height: "15px",
    fontSize: "14px",
    marginLeft: theme.spacing.unit * 2,
  },
  legendColor: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    verticalAlign: "middle",
    marginRight: theme.spacing.unit,
    borderRadius: "3px",
  },
  nativeSelectBox: {
    float: "right",
    marginTop: "-67px",
    width: "280px",
  },
  nativeSelectTitle: {
    fontSize: "12px",
    fontWeight: "bold",
  },
});

const CustomNativeSelect = styled(NativeSelect)`
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dddddd;
  background: 0% 0% no-repeat padding-box
    ${(p) => p.theme.color.primaryVariant3};
  select {
    padding: 3px 0 3px 13px;
    width: 265px;
    height: 28px;
    font-size: 14px;
    &:focus {
      background: 0% 0% no-repeat padding-box
        ${(p) => p.theme.color.primaryVariant3};
    }
  }
  &:hover svg {
    background-color: ${(p) => p.theme.color.primaryVariant};
  }
`;
const ArrowDropDownIcon = withStyles((theme) => ({
  root: {
    backgroundColor: theme.button.ok.backgroundColor,
    width: "34px",
    height: "35px",
    color: "white",
    boxShadow: `0px 1px 0px ${theme.color.shadow}`,
    borderRadius: "0px 3px 3px 0px",
    marginTop: "-6px",
  },
}))(ArrowDropDown);

const Legend = ({ target, children, classes }) => (
  <div className={classes.legendItem}>
    <span className={`${target} ${classes.legendColor}`}> </span>
    <span>{children}</span>
  </div>
);

class Calendar extends Component {
  state = {
    selectedEvent: null,
    selectedSlot: null,
    openScheduleSelection: false,
    events: [],
    loading: false,
    activeStep: 0,
    flow: 1,
    creatingCondition: "none",
    creatingRequestParams: null,
    errors: [],
    confirms: [],
    slotMaxCapacity: undefined,
    slotFrameLengthes: undefined,
    showSuccessCommit: false,
    doctors: [],
    selectDoctor: null,
    displayFinish: false,
    backupSelectedSlot: null,
    facilityType: null,
    isLoading: false,
  };

  currentDate = new Date();
  currentView = "week";

  onSelectEvent = (event, e) => {
    this.setState({
      selectedEvent: event,
    });
  };

  onSelectSlot = (event) => {
    if (!oneYearLimit({ date: event.start, viewType: "day" })) return;

    if (event.start === event.end) {
      //Create a new object. Because the object is a reference
      event.end = new Date(
        event.end.getFullYear(),
        event.end.getMonth(),
        event.end.getDate(),
        event.end.getHours(),
        event.end.getMinutes() + 15,
        event.end.getSeconds()
      );
    }
    event.selectDoctor = this.state.selectDoctor;
    event.menuMethod = "online";
    event.capacity = 1;
    event.repetition = {
      text: "なし",
      value: 1,
      interval: 1, // 毎週、隔週等の繰り返し頻度。現在は毎週のみなので固定で1
      byDay: [],
      frequency: null,
      isCustom: false,
    };
    event.repetitionEndValue = "1";
    event.repetitionEndDate = format(new Date(), "yyyy-MM-dd", {
      locale: jaLocale,
    });
    this.setState({
      openScheduleSelection: true,
      selectedSlot: event,
      backupSelectedSlot: event,
    });
  };

  onNavigate = (e) => {
    if (!oneYearLimit({ date: e, viewType: this.currentView })) return;
    this.currentDate = e;
    this.setState({ loading: true });
    this.loadEvents();
  };

  onView = (e) => {
    this.currentView = e;
    this.setState({ loading: true });
    this.loadEvents();
  };

  eventPropGetter = (event) => {
    let className;
    if (event.appointment) {
      className = `status-before-exam`;
    } else if (
      (event.slot.menu.medicalMethod === "offline" &&
        event.slot.capacity === event.slot.remaining) ||
      (event.slot.menu.medicalMethod === "online" &&
        event.slot.reservedNumber === 0)
    ) {
      className = `is-slot`;
    } else {
      className = "status-before-exam";
    }

    if (isBefore(event.end, Date.now())) {
      className += " is-past";
    }

    return {
      className,
    };
  };

  closeEventDetailPopup = () => {
    this.setState({
      selectedEvent: null,
    });
  };

  closeScheduleSelectionPopup = () => {
    this.setState({
      openScheduleSelection: false,
      activeStep: 0,
      flow: 1,
      errors: [],
      confirms: [],
    });
  };

  handleSetStep = () => {
    this.setState({ errors: [], confirms: [] });
  };

  _getStartAndEndTimePoints = () => {
    const lastDate = endOfMonth(addYears(new Date(), 1));
    let endDate;

    switch (this.currentView) {
      case "day":
        endDate = isAfter(lastDate, endOfDay(this.currentDate))
          ? endOfDay(this.currentDate).toISOString()
          : lastDate.toISOString();
        return [startOfDay(this.currentDate).toISOString(), endDate];
      case "month":
        endDate = isAfter(lastDate, endOfMonth(this.currentDate))
          ? endOfMonth(this.currentDate).toISOString()
          : lastDate.toISOString();
        return [startOfMonth(this.currentDate).toISOString(), endDate];
      default:
        return [
          startOfWeek(this.currentDate).toISOString(),
          endOfWeek(this.currentDate).toISOString(),
        ];
    }
  };

  FILTER_STATUS = [
    "beforeExam",
    "examCompleted",
    "paymentCompleted",
    "unapproved",
  ];
  loadEvents = async () => {
    this.setState({ loading: true });
    const [start, end] = this._getStartAndEndTimePoints();
    try {
      const { data } = await client.query({
        query: ListItem,
        variables: {
          start,
          end,
          status: this.FILTER_STATUS,
          medicalDoctorId: this.state.selectDoctor.id,
        },
      });

      const appointmentEvents = data.appointments.items
        .filter((appo) => {
          return !data.appointmentSlots.items.find(
            (slot) =>
              slot.from === appo.fromOfSlot &&
              slot.menu.medicalMethod === appo.menu.medicalMethod
          );
        })
        .map((appo) => ({
          appointment: appo,
          hospitalId: appo.hospitalId,
          menu: appo.menu,
          start: parseISO(appo.from),
          end: parseISO(appo.to),
          title: "",
          detail: 1,
        }));

      const slotEvents = data.appointmentSlots.items.map((slot) => {
        const appointments = data.appointments.items.filter(
          (appo) =>
            slot.from === appo.fromOfSlot &&
            slot.menu.medicalMethod === appo.menu.medicalMethod
        );
        const reservedNumber = slot.reservedNumber ? slot.reservedNumber : 0;
        const detail =
          slot.menu.medicalMethod === "offline"
            ? `${slot.capacity - slot.remaining}/${slot.capacity}`
            : reservedNumber;
        return {
          slot,
          hospitalId: slot.hospitalId,
          menu: slot.menu,
          appointments,
          start: parseISO(slot.from),
          end: parseISO(slot.to),
          title: "",
          detail: detail,
          slotRepetition: slot.slotRepetition,
        };
      });

      const events = [...appointmentEvents, ...slotEvents].map((event) => {
        if (differenceInCalendarDays(event.start, event.end) !== 0)
          event.end = subMilliseconds(event.end, 1);
        return event;
      });

      this.setState({
        events,
        loading: false,
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      this.setState({
        loading: false,
      });
    }
    this.setState({ loading: false });
  };

  removeEvent = (start, hospitalId, menuMethod) => {
    this.setState({
      events: this.state.events.filter(
        (item) =>
          !(
            item.start.getTime() === start.getTime() &&
            item.hospitalId === hospitalId &&
            item.menu.medicalMethod === menuMethod
          )
      ),
    });
  };

  setActiveStepAndFlow = (flow) => {
    this.setState({ activeStep: 1, flow });
  };

  createSlot = async ({ slotParam, acceptConfirm = false }) => {
    this.setState({ errors: [], confirmes: [] });

    const { slotMaxCapacity, slotFrameLengthes } = this.state;
    if (!slotMaxCapacity || !slotFrameLengthes) {
      this.setState({ errors: [registrationSlotApiErrorMessages.default] });
      return { result: "failed" };
    }

    const validationResult = getSlotValidationResult({
      ...slotParam,
      maximumTimeframe:
        slotParam.menuMethod === "offline"
          ? slotFrameLengthes.offlineMaximumTimeframe
          : slotFrameLengthes.onlineMaximumTimeframe,
      minimumTimeframe:
        slotParam.menuMethod === "offline"
          ? slotFrameLengthes.offlineMinimumTimeframe
          : slotFrameLengthes.onlineMinimumTimeframe,
      maxCapacity: slotMaxCapacity,
      otherEvents: this.state.events,
    });
    if (validationResult.errors.length) {
      this.setState({ creatingCondition: "none" });
      this.setState({ creatingRequestParam: null });
      this.setState({ errors: validationResult.errors });
      return { result: "failed" };
    } else if (validationResult.confirms.length && !acceptConfirm) {
      this.setState({ creatingCondition: "slot:confirm" });
      this.setState({ creatingRequestParam: slotParam });
      this.setState({ confirms: validationResult.confirms });
      return { result: "confirming" };
    }

    this.setState({ isLoading: true });
    const registrationResult = await registerSlot({
      ...slotParam,
      from: slotParam.startDateTime.toISOString(),
      to: slotParam.endDateTime.toISOString(),
      suspendsOnConflicts: !acceptConfirm,
      medicalDoctorId: this.state.selectDoctor.id,
    });
    if (registrationResult.result === "succeed") {
      this.setState({ confirms: [], errors: [] });
      this.loadEvents();
      this.closeScheduleSelectionPopup();
      this.openFinish();
    }
    if (registrationResult.result === "confirming") {
      this.setState({ creatingCondition: "slot:confirm" });
      this.setState({ creatingRequestParam: slotParam });
    } else {
      this.setState({ creatingCondition: "none" });
      this.setState({ creatingRequestParam: null });
    }
    this.setState({ confirms: registrationResult.confirms });
    this.setState({ errors: registrationResult.errors });
    this.setState({ isLoading: false });

    return registrationResult;
  };

  acceptCreatingSlot = async () => {
    return await this.createSlot({
      slotParam: this.state.creatingRequestParam,
      acceptConfirm: true,
    });
  };

  createAppointment = async ({
    patient,
    from,
    to,
    hasCharge,
    menuId,
    acceptConfirm = false,
  }) => {
    this.setState({ errors: [], confirmes: [] });

    const payload = {
      patientId: patient.patientId,
      from,
      to,
      menu: { menuId, hasCharge },
      medicalDoctorId: this.state.selectDoctor.id,
    };

    const validationResult = getAppointmentValidationResult({
      patient,
      from,
      to,
      hasCharge,
      organizationId: patient.organizationId,
      otherEvents: this.state.events,
    });
    if (validationResult.errors.length) {
      this.setState({
        creatingCondition: "none",
        creatingRequestParam: null,
        errors: validationResult.errors,
      });
      return { result: "failed" };
    } else if (validationResult.confirms.length && !acceptConfirm) {
      this.setState({
        creatingCondition: "appointment:confirm",
        creatingRequestParam: { patient, from, to, hasCharge, menuId },
        confirms: validationResult.confirms,
      });
      return { result: "confirming" };
    }

    this.setState({ isLoading: true });
    const registrationResult = await registerAppointment(payload);

    if (registrationResult.result === "succeed") {
      this.loadEvents();
      this.closeScheduleSelectionPopup();
      this.openFinish();
    }
    this.setState({
      creatingCondition: "none",
      creatingRequestParam: null,
      errors: registrationResult.errors,
      isLoading: false,
    });

    return registrationResult;
  };

  acceptCreatingAppointment = async () => {
    return await this.createAppointment({
      ...this.state.creatingRequestParam,
      acceptConfirm: true,
    });
  };

  handleCloseConfirm = () => {
    this.setState({
      creatingCondition: "none",
      creatingRequestParam: null,
      errors: [],
      confirms: [],
    });
  };

  handleChangeDoctors = (e) => {
    const doctor = this.state.doctors.find((doctor) => {
      return doctor.doctorId === e.target.value;
    });
    this.setState(
      {
        selectDoctor: {
          id: doctor.doctorId,
          name: `${doctor.familyName} ${doctor.givenName}`,
        },
      },
      () => {
        this.loadEvents();
      }
    );
  };

  initializeChooseDoctors = async () => {
    try {
      const { data } = await client.query({
        query: GetDoctorAndChooseDoctors,
      });
      if (data) {
        if (data.doctors) {
          this.setState({
            doctors: data.doctors,
          });
        }
        if (data.doctor) {
          this.setState({
            selectDoctor: {
              id: data.doctor.doctorId,
              name: `${data.doctor.familyName} ${data.doctor.givenName}`,
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  async componentDidMount() {
    await this.initializeChooseDoctors();
    this.loadEvents();
    this.initializeHospital();

    getSlotMaxCapacity()
      .then(({ maxCapacity }) => {
        maxCapacity && this.setState({ slotMaxCapacity: maxCapacity });
      })
      .catch();

    getSlotTimeFrameLength()
      .then(({ frameLengthes }) => {
        frameLengthes && this.setState({ slotFrameLengthes: frameLengthes });
      })
      .catch();
  }

  openFinish = () => {
    this.setState({ displayFinish: true });
  };

  closeFinish = () => {
    this.setState({ displayFinish: false });
  };

  setSelectSlot = (
    flow,
    start,
    end,
    selectedMenu,
    menuMethod,
    capacity,
    repetition,
    repetitionEndValue,
    repetitionEndDate
  ) => {
    if (flow === FLOW.APPOINTMENT) {
      this.setState({
        selectedSlot: {
          ...this.state.selectedSlot,
          start,
          end,
          selectedMenu,
          isSubmitting: true,
        },
      });
    } else if (flow === FLOW.SLOT) {
      this.setState({
        selectedSlot: {
          ...this.state.selectedSlot,
          start,
          end,
          menuMethod,
          capacity,
          repetition,
          repetitionEndValue,
          repetitionEndDate,
        },
      });
    }
  };

  setSelectSlotWithBackupSelectSlot = () => {
    this.setState({
      selectedSlot: {
        ...this.state.backupSelectedSlot,
      },
    });
  };

  initializeHospital = () => {
    client
      .query({
        query: GetHospital,
      })
      .then((res) => {
        this.setState({ facilityType: res.data.hospital.facilityType });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const { classes } = this.props;
    const { loading, creatingCondition, selectDoctor, isLoading } = this.state;

    const scrollToTime = setHours(startOfDay(Date.now()), 9);
    const messages = getMessages(this.currentView);
    const getDrilldownView = (targetDate, currentViewName) =>
      oneYearLimit({ date: targetDate, viewType: this.currentView })
        ? currentViewName
        : undefined;

    const main = (
      <div className={classes.root}>
        <ReactBigCalendarCustomCss theme={theme_} />
        <Heading1>{CalendarResource.render.div.h1.j001}</Heading1>
        {this.state.doctors.length > 0 && this.state.selectDoctor && (
          <div className={classes.nativeSelectBox}>
            <div className={classes.nativeSelectTitle}>
              {CalendarResource.render.div.nativeSelectBox.j001}
            </div>
            <CustomNativeSelect
              disableUnderline
              IconComponent={ArrowDropDownIcon}
              value={this.state.selectDoctor.id}
              onChange={(e) => this.handleChangeDoctors(e)}
            >
              {this.state.doctors.map((doctor, index) => {
                return (
                  <option value={doctor.doctorId} key={index}>
                    {`${doctor.familyName} ${doctor.givenName}`}
                  </option>
                );
              })}
            </CustomNativeSelect>
          </div>
        )}

        <BigCalendar
          localizer={localizer}
          defaultDate={new Date()}
          defaultView={this.currentView}
          scrollToTime={scrollToTime}
          step={15}
          selectable={true}
          onSelectEvent={this.onSelectEvent}
          onSelectSlot={this.onSelectSlot}
          onNavigate={this.onNavigate}
          onView={this.onView}
          getDrilldownView={getDrilldownView}
          eventPropGetter={this.eventPropGetter}
          events={this.state.events}
          components={{
            toolbar: (props) => (
              <Toolbar
                {...props}
                onSelectSlot={this.onSelectSlot}
                setActiveStepAndFlow={this.setActiveStepAndFlow}
              />
            ),
            month: {
              event: EventMonth,
            },
            week: {
              event: EventWeek,
            },
          }}
          popup={true}
          views={["week", "month"]}
          messages={messages}
          formats={textFormats}
        />
        <Fade
          in={loading}
          style={{ transitionDelay: loading ? "50ms" : "0ms" }}
        >
          <LinearProgress />
        </Fade>

        <div className={classes.legendContainer}>
          <Legend target={"is-slot"} classes={classes}>
            {CalendarResource.render.div.div.Legend.j001}
          </Legend>
          <Legend target={"status-before-exam"} classes={classes}>
            {CalendarResource.render.div.div.Legend.j002}
          </Legend>
        </div>

        <EventDetail
          event={this.state.selectedEvent}
          closeEventDetailPopup={this.closeEventDetailPopup}
          removeEvent={this.removeEvent}
          removeEvents={this.loadEvents}
          history={this.props.history}
          handleTableOverflow={(isOverflowed) => {
            this.setState({ isEventDetailTableOverflow: isOverflowed });
          }}
          selectDoctor={selectDoctor}
          isTableOverflow={this.state.isEventDetailTableOverflow}
          facilityType={this.state.facilityType}
        />
        <Dialog
          disableBackdropClick
          onClose={this.closeScheduleSelectionPopup}
          open={this.state.openScheduleSelection}
          maxWidth="md"
        >
          <CalendarStepper
            createSlot={this.createSlot}
            createAppointment={this.createAppointment}
            slotFrameLengthes={this.state.slotFrameLengthes}
            onSetStep={this.handleSetStep}
            slot={this.state.selectedSlot}
            activeStep={this.state.activeStep}
            flow={this.state.flow}
            errors={this.state.errors}
            closeScheduleSelectionPopup={this.closeScheduleSelectionPopup}
            selectDoctor={selectDoctor}
            setSelectSlot={this.setSelectSlot}
            setSelectSlotWithBackupSelectSlot={
              this.setSelectSlotWithBackupSelectSlot
            }
            isLoading={isLoading}
          />
        </Dialog>
        <Finish
          open={this.state.displayFinish}
          closeFinish={this.closeFinish}
        />

        {creatingCondition === "slot:confirm" && (
          <DialogConfirmRegistration
            type="slot"
            confirms={this.state.confirms}
            accept={this.acceptCreatingSlot}
            close={this.handleCloseConfirm}
            isLoading={isLoading}
          />
        )}
        {creatingCondition === "appointment:confirm" && (
          <DialogConfirmRegistration
            type="appointment"
            confirms={this.state.confirms}
            accept={this.acceptCreatingAppointment}
            close={this.handleCloseConfirm}
            isLoading={isLoading}
          />
        )}
      </div>
    );
    return <BasicTemplate main={main} title={Titles.calendar} />;
  }
}

export default withStyles(styles)(Calendar);
