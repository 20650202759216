import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Switch from "@material-ui/core/Switch";
import { client, Subscribe, UnSubscribe, GetDoctor } from "../../../graphql";
import { getMessaging, getToken } from "firebase/messaging";
import * as Sentry from "@sentry/browser";
import LoadingScreen from "../../molecules/others/LoadingScreen";
import BasicTemplate from "../../templates/BasicTemplate";
import { japaneseList } from "../../../Resources/japaneseList";
import Titles from "../../../Resources/Titles";
import { cognitoSessionOut } from "../../../Utils/SignOut";

const SettingResource = japaneseList.Pages.Setting;
const styles = (theme) => {
  return {
    root: {
      height: "700px",
      width: "850px",
      margin: theme.spacing.unit * 5,
      fontFamily:
        "'Avenir','Helvetica Neue','Helvetica','Arial','Hiragino Sans','ヒラギノ角ゴシック',YuGothic,'Yu Gothic','メイリオ', Meiryo,'ＭＳ Ｐゴシック','MS PGothic'",
    },
    title: {
      color: theme.font.color,
      marginTop: "70px",
      fontWeight: "bold",
      marginBottom: theme.spacing.unit * 6,
    },
    info: {
      color: theme.font.color,
      textAlign: "center",
    },
    box: {
      color: theme.font.color,
      margin: "40px auto",
      backgroundColor: "#f7f7f7",
      maxWidth: "400px",
      padding: "20px 40px",
      borderRadius: "10px",
    },
    list: {
      color: theme.font.color,
      margin: "20px auto",
    },
    extra: {
      marginTop: "100px",
      color: "#27cbcb",
    },
    action: {
      color: theme.font.color,
      margin: "auto",
      textAlign: "center",
    },
    browserInfo: {
      marginTop: theme.spacing.unit * 10,
    },
    notice: {
      marginBottom: "30px",
    },
    noticeInfo: {
      color: theme.font.info,
      fontSize: "0.8rem",
      textAlign: "center",
    },
  };
};

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      push: false,
      disable: true,
    };
    client
      .query({
        query: GetDoctor,
      })
      .then((data, error) => {
        if (!error) {
          if (data.data.doctor && data.data.doctor.token) {
            this.setState({
              push: true,
              disable: false,
            });
          }
        } else {
          if ("serviceWorker" in navigator || "PushManager" in window) {
            this.setState({
              disable: false,
            });
          }
        }
      });
  }

  subscribePush = () => {
    const messaging = getMessaging();
    getToken(messaging).then((currentToken) => {
      if (currentToken) {
        let params = {
          token: currentToken,
        };
        client
          .mutate({
            mutation: Subscribe,
            variables: params,
          })
          .then(() => {
            this.setState({
              push: true,
            });
          });
      }
    });
  };

  unsubscribePush = () => {
    client
      .mutate({
        mutation: UnSubscribe,
      })
      .then((data, error) => {
        if (!error) {
          this.setState({
            push: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handlePushChange = async (event, checked) => {
    await cognitoSessionOut();
    if (checked) {
      if (Notification.permission !== "granted") {
        Notification.requestPermission().then((permission) => {
          if (permission === "denied") {
            const permissionError = new Error(`
FirebaseError: Messaging: The required permissions were not granted and blocked instead. (messaging/permission-blocked).
お知らせ通知設定画面の「通知を ON にする」押下時にブラウザからの通知権限の要求がブロックされました。`);
            Sentry.captureException(permissionError);
            return;
          }
          if (permission === "granted") {
            console.log("Notification permission granted.");
            this.subscribePush();
          }
        });
      } else {
        this.subscribePush();
      }
    } else {
      this.unsubscribePush();
    }
  };

  render() {
    const { classes } = this.props;
    const main = (
      <React.Fragment>
        <Card className={classes.root}>
          <CardContent>
            <Typography
              variant="display1"
              align="center"
              className={classes.title}
            >
              {SettingResource.render.Card.CardContent.Typography.j001}
            </Typography>
            <Typography variant="subheading" className={classes.info}>
              {SettingResource.render.Card.CardContent.Typography.j002}
            </Typography>
            <Typography variant="subheading" className={classes.info}>
              {SettingResource.render.Card.CardContent.Typography.j003}
            </Typography>
            <Paper className={classes.box} elevation={0}>
              <Typography variant="subheading" className={classes.list}>
                {SettingResource.render.Card.CardContent.Paper.Typography.j001}
              </Typography>
              <Typography variant="subheading" className={classes.list}>
                {SettingResource.render.Card.CardContent.Paper.Typography.j002}
              </Typography>
              <Typography variant="subheading" className={classes.list}>
                {SettingResource.render.Card.CardContent.Paper.Typography.j003}
              </Typography>
            </Paper>
            <div className={classes.notice}>
              <Typography variant="subheading" className={classes.noticeInfo}>
                {SettingResource.render.Card.CardContent.div.Typography.j001}
              </Typography>
              <Typography variant="subheading" className={classes.noticeInfo}>
                {SettingResource.render.Card.CardContent.div.Typography.j002}
              </Typography>
              <Typography variant="subheading" className={classes.noticeInfo}>
                {SettingResource.render.Card.CardContent.div.Typography.j003}
              </Typography>
            </div>
            <Typography
              variant="headline"
              gutterBottom
              className={classes.action}
            >
              {SettingResource.render.Card.CardContent.Typography.j004}
              <Switch
                checked={this.state.push}
                onChange={this.handlePushChange}
                color="primary"
              />
            </Typography>
          </CardContent>
        </Card>
        <LoadingScreen isLoading={false} />
      </React.Fragment>
    );
    return <BasicTemplate main={main} title={Titles.settings} />;
  }
}

export default withStyles(styles)(SettingsPage);
