import gql from "graphql-tag";

export const ListAppointments = gql`
  query ListAppointments(
    $start: String
    $end: String
    $status: [String!]
    $limit: Int
    $offset: Int
    $orderBy: AppointmentOrderBy
    $medicalMethodFilters: [String!]
    $isFilterUnderMaintenance: Boolean
    $requestPage: String
    $requireFromRange: Boolean
  ) {
    appointments(
      start: $start
      end: $end
      status: $status
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      medicalMethodFilters: $medicalMethodFilters
      isFilterUnderMaintenance: $isFilterUnderMaintenance
      requestPage: $requestPage
      requireFromRange: $requireFromRange
    ) {
      items {
        from
        to
        hospitalId
        createTime
        status
        content
        hasCharge
        attachment {
          status
          expiredTime
        }
        patientAttachments {
          filePath
        }
        patient {
          patientId
          familyName
          givenName
          familyNameKana
          givenNameKana
          gender
          birthdate
          phoneNumber
          cardStatus
          organizationId
          address {
            postalCode
            prefectureCode
            addressCity
            addressStreetNumber
            addressBuilding
          }
        }
        existedInsuranceCard
        payment {
          orderId
          paymentTime
          status
          usageFee
          usageFeeTax
          usageFeeTaxIncluded
          deliveryTotalFee
          deliveryFee
          deliveryFeeTax
          deliveryStatus
          medicalExpense
          deliverySelectedOption
          gmoCode
          gmoInfo
          errorCode
        }
        facilityType
        delivery {
          tradingId
          name
          voucherId
          pickUpDate
          pickUpTimeSpan
          comment
          reserveAt
        }
        deliveryProductName
        deliveryRelatedFee {
          deliveryMaterialFee
          deliveryFee
          deliveryFeeTax
          deliveryTotalFee
        }
        postDeliveryRelatedFee {
          deliveryTotalFee
        }
        menu {
          menuId
          doctorId
          medicalMethod
          medicalMenu
          paymentMethod
          displayName
          supportsInsuranceTreatment
          supportPaymentByContractCompany
        }
        memo
        enableChangePaymentInformation
        deliveryFeeCalculateType
        program {
          programId
          type
          name
        }
        isUnderMaintenance
        doctor {
          doctorId
          familyName
          givenName
        }
        medicineDelivery {
          accountStatus
          deliveryMethod
          deliveryHospitalId
          name
          prefectureCode
          addressBuilding
          addressCity
          addressStreetNumber
          tel
          fax
          postalCode
        }
        contractPlan {
          contractPlanId
          contractPlanName
          plan {
            planId
            planName
            displayTagName
            prescriptionDrug {
              inputMethod
              fixedDrugsName
              selectableDrugNames
            }
          }
        }
      }
      totalItemCount
      offset
      minimumFrom
      maximumFrom
    }
  }
`;
