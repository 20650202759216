// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  DialogContent,
  Typography,
  Grid,
  DialogTitle,
  Divider,
} from "@material-ui/core/";
import { prefectureCodes } from "../../../../Utils/PrefectureCode";
import AccountImage from "../../../../images/account_circle-24px.svg";
import {
  getAge,
  getDayTextJaFormatShort,
} from "../../../../Utils/DisplayLogic";
import { format } from "date-fns";
import NumberFormat from "react-number-format";

// personal modules
// --------------------------------------------------
import { japaneseList } from "../../../../Resources/japaneseList";
import {
  DELIVERY_OPTION,
  MEDICAL_METHOD,
  PAYMENT_METHOD,
} from "../../../../Utils/const";
import { deliveryFeeCalculator } from "../../../../Utils/DeliveryFeeCalculator";
import FontSpan from "../../../atoms/fonts/FontSpan";
import CSVPostDeliveryFeeContent from "./CSVPostDeliveryFeeContent";

const Resources = japaneseList.Components.Payment.CSVPostDeliveryDialog;
const DeliveryProcessResources =
  japaneseList.Components.Payment.DeliveryProcessDialog;
const DeliveryProductName = japaneseList.models.appointment.deliveryProductName;
const maleGender = Resources.patientInfo.genderMale;

// style
// --------------------------------------------------
const styles = {
  dialogTitle: {
    fontSize: "20px",
    lineHeight: "0px",
  },

  tableStyle: {
    borderCollapse: "collapse",
    width: "310px",
    tableLayout: "auto",
  },
  thBirthDay: {
    width: "98px",
  },
  thAge: {
    width: "26px",
  },
  thGender: {
    width: "48px",
  },
  thPhonenumber: {
    width: "120px",
  },
  thStyle: {
    textAlign: "center",
    borderTop: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    backgroundColor: "#eee",
    fontSize: "12px",
    color: "#808080",
  },
  tdStyle: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    color: "#393939",
    whiteSpace: "nowrap",
  },
  tdStyleGenderMale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  tdStyleGenderFemale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  containerTable: {
    display: "flex",
    boxSizing: "border-box",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "8px",
  },
  containerBorder: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
    marginTop: "20px",
  },
  containerBorderConfirm: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
    marginTop: "15px",
  },
  containerBorderView: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
    marginTop: "20px",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "80px auto",
    gridGap: "10px",
    borderTop: "#ccc solid 1px",
  },
  titleItem: {
    backgroundColor: "#eee",
    padding: "10px",
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    color: "#808080",
  },
  contentItem: {
    padding: "10px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  contentItemSubGroup: {
    display: "flex",
    padding: "15px 10px",
    alignItems: "flex-start",
    gap: "10px",
  },
  contentFeeBlock: {
    display: "flex",
    minWidth: "42px",
    height: "21px",
    paddingRight: "0px",
    justifyContent: "left",
    alignItems: "flex-end",
    gap: "4px",
  },
  contentFeeItem: {
    textAlign: "right",
    fontSize: "12px",
  },
  contentDescriptionItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#808080",
    fontSize: "11px",
    lineHeight: "normal",
    whiteSpace: "pre-wrap",
    alignSelf: "center",
  },
  noteContainer: {
    paddingTop: "20px",
    display: "grid",
    gridTemplateColumns: "15px auto",
  },
  noteTittle: {
    fontSize: "14px",
    gridColumn: "1 / span 2",
  },
  noteContent: {
    fontSize: "11px",
    lineHeight: "20px",
    color: "#aaaaaa",
  },
  confirmQuestion: {
    marginTop: "25px",
    fontSize: "14px",
  },
  wrapText: {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  resetQuestion: {
    marginTop: "25px",
    fontSize: "14px",
  },
};

class CSVPostDeliveryDetail extends Component {
  render() {
    const { appointment, classes, requestDelivery, mode } = this.props;
    const { patient, doctor, menu } = appointment;
    const address = patient && (patient.address || {});
    const prefecture = address.prefectureCode
      ? prefectureCodes.find((c) => address.prefectureCode === c.code).jpName ||
        ""
      : "";
    const postalCode =
      address && address.postalCode
        ? address.postalCode.slice(0, 3) + "-" + address.postalCode.slice(3)
        : "";

    const displayDeliveryFee = deliveryFeeCalculator(
      appointment,
      DELIVERY_OPTION.csvPost
    );
    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;

    return (
      <React.Fragment>
        <DialogTitle>
          <FontSpan weight="bold" className={classes.dialogTitle}>
            {mode === "confirm" && Resources.DialogTitle.confirm}
            {mode === "reset" && Resources.DialogTitle.reset}
            {mode === "result" && Resources.DialogTitle.result}
          </FontSpan>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div className={classes.noteContainer}>
            <div className={classes.noteTittle}>{Resources.note.title}</div>
            <div className={classes.noteContent}>{Resources.note.symbol}</div>
            <div className={classes.noteContent}>
              {Resources.note.content.j001}
              {Resources.note.content.j002}
            </div>
            <div></div>
            <div className={classes.noteContent}>
              {Resources.note.content.j003}
            </div>
          </div>
          {patient && (
            <React.Fragment>
              <Grid container wrap="nowrap" style={{ marginTop: "20px" }}>
                <Grid item style={{ padding: "0px 10px 0px 0px" }}>
                  <img src={AccountImage} alt="" />
                </Grid>
                <Grid item xs style={{ width: "calc(100% - 102px)" }}>
                  <Typography
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                    className={classes.wrapText}
                  >
                    {patient.familyName} {patient.givenName}
                  </Typography>
                  <Typography
                    style={{ fontSize: "12px" }}
                    className={classes.wrapText}
                  >
                    {patient.familyNameKana} {patient.givenNameKana}
                  </Typography>
                </Grid>
              </Grid>
              <div className={classes.containerTable}>
                <table className={classes.tableStyle}>
                  <thead>
                    <tr>
                      <th
                        className={`${classes.thStyle} ${classes.thBirthDay}`}
                      >
                        {Resources.patientInfo.thBirthDay}
                      </th>
                      <th className={`${classes.thStyle} ${classes.thAge}`}>
                        {Resources.patientInfo.thAge}
                      </th>
                      <th className={`${classes.thStyle} ${classes.thGender}`}>
                        {Resources.patientInfo.thGender}
                      </th>
                      <th
                        className={`${classes.thStyle} ${classes.thPhonenumber}
                      `}
                      >
                        {Resources.patientInfo.thPhonenumber}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={classes.tdStyle}>
                        {format(
                          new Date(patient.birthdate),
                          Resources.Format.j001
                        )}
                      </td>
                      <td className={classes.tdStyle}>
                        {patient.birthdate ? getAge(patient.birthdate) : " "}
                      </td>
                      <td
                        className={
                          patient.gender && patient.gender === maleGender
                            ? classes.tdStyleGenderMale
                            : classes.tdStyleGenderFemale
                        }
                      >
                        {patient.gender}
                      </td>
                      <td className={classes.tdStyle}>
                        <NumberFormat
                          value={patient.phoneNumber}
                          displayType={"text"}
                          format="###-####-####"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          )}
          {mode === "confirm" && (
            <div className={classes.confirmQuestion}>
              {Resources.confirmQuestion}
            </div>
          )}
          {mode === "reset" && (
            <div className={classes.resetQuestion}>
              {DeliveryProcessResources.ResetQuestion}
              <div className={classes.noteContent}>
                {DeliveryProcessResources.ResetQuestionNotice}
              </div>
            </div>
          )}
          <div
            className={
              mode === "confirm"
                ? classes.containerBorderConfirm
                : classes.containerBorderView
            }
          >
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resources.information.undertaker}
              </div>
              <div className={classes.contentItem}>
                {menu && menu.medicalMethod === MEDICAL_METHOD.OFFLINE
                  ? Resources.information.offline
                  : `${doctor.familyName} ${doctor.givenName}`}
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resources.information.treatmentDay}
              </div>
              <div className={classes.contentItem}>
                {appointment.from
                  ? getDayTextJaFormatShort(appointment.from)
                  : ""}
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resources.information.address}
              </div>
              {address.prefectureCode ? (
                <div className={classes.contentItem}>
                  {Resources.information.addressSymbol}
                  {postalCode}
                  <br />
                  {`${prefecture} ${address.addressCity} ${
                    address.addressStreetNumber
                  } ${address.addressBuilding || ""}`}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resources.information.patientName}
              </div>
              <div className={classes.contentItem}>
                {patient.familyName} {patient.givenName}
              </div>
            </div>
            {!isBillingEnterprise &&
              displayDeliveryFee &&
              displayDeliveryFee.deliveryFee &&
              displayDeliveryFee.deliveryArrangementFee && (
                <CSVPostDeliveryFeeContent
                  deliveryFee={displayDeliveryFee.deliveryFee}
                  deliveryArrangementFee={
                    displayDeliveryFee.deliveryArrangementFee
                  }
                />
              )}
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resources.information.contentSelect}
              </div>
              <div className={classes.contentItem}>
                {mode === "confirm"
                  ? DeliveryProductName[requestDelivery.name] || ""
                  : appointment.deliveryProductName || ""}
              </div>
            </div>
          </div>
        </DialogContent>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CSVPostDeliveryDetail);
