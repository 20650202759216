import { useState, useEffect } from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { japaneseList } from "../../../Resources/japaneseList";
import iconBlockPath from "../../../images/error_outline-24px.svg";
import clearPath from "../../../images/clear-24px.svg";

interface Props extends RouteComponentProps {
  handleClose?: () => void;
}

const ConnectingAlertEl = document.getElementById("Alerts");
const ConnectingAlertMessage = japaneseList.Utils.ConnectingAlertMessage;

const Alert = styled.div`
  width: 320px;
  background: #393939 0% 0% no-repeat padding-box;
  opacity: 0.8;
  padding-bottom: 10px;
  margin-top: 20px;
`;

const Head = styled.div`
  margin-top: 14px;
  margin-left: 14px;
  height: 20px;
  width: 250 px;
  opacity: 1;
  float: left;
`;

const Info = styled.div`
  margin-right: 4px;
  width: 20px;
  height: 20px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  float: left;
`;

const Heading = styled.div`
  margin-top: 2px;
  left: 712px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font: W6 12px/21px Hiragino Kaku Gothic ProN;
  font-size: 12px;
  float: left;
  vertical-align: middle;
`;

const Clear = styled.div`
  margin-top: 8px;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  float: right;
  cursor: pointer;
`;
const Content = styled.div`
  margin: 10px 14px 0px;
  line-height: 18px;
  text-align: left;
  letter-spacing: 0px;
  font-size: 12px;
  color: #ffffff;
  opacity: 1;
  display: inline-block;
`;
const ContentLow = styled.div`
  margin: 0px 14px 0px;
  line-height: 18px;
  text-align: left;
  letter-spacing: 0px;
  font-size: 12px;
  color: #ffffff;
  opacity: 1;
  display: inline-block;
`;

const ConnectingAlertContent = (props: Props) => {
  return (
    <Alert>
      <Head>
        <Info>
          <img src={iconBlockPath} alt={""}></img>
        </Info>
        <Heading>{ConnectingAlertMessage.j001}</Heading>
      </Head>
      <Clear onClick={props.handleClose}>
        <img src={clearPath} alt={""}></img>
      </Clear>
      <Content>{ConnectingAlertMessage.j002}</Content>
      <ContentLow>{ConnectingAlertMessage.j003}</ContentLow>
    </Alert>
  );
};

const ConnectingAlert = (props: Props) => {
  const [isShowConnectingAlert, setIsShowConnectingAlert] = useState(true);
  const container = document.createElement("div");

  useEffect(() => {
    ConnectingAlertEl && ConnectingAlertEl.appendChild(container);
    props.history.listen(() => {
      setIsShowConnectingAlert(true);
    });
    setIsShowConnectingAlert(true);
    return () => {
      ConnectingAlertEl && ConnectingAlertEl.removeChild(container);
    };
  }, [props]);

  const close = () => {
    setIsShowConnectingAlert(false);
  };

  return ReactDOM.createPortal(
    isShowConnectingAlert && (
      <ConnectingAlertContent {...props} handleClose={close} />
    ),
    container
  );
};

export default withRouter(ConnectingAlert);
