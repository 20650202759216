import React from "react";
import { prefectureCodes } from "../../../Utils/PrefectureCode";

interface Props {
  addressInfo: AddressInfo;
}

interface AddressInfo {
  prefectureCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
}

export const AddressContent = (props: Props) => {
  const { addressInfo } = props;
  const prefectureName = addressInfo
    ? prefectureCodes.find((c) => addressInfo.prefectureCode === c.code)
    : null;
  return (
    <React.Fragment>
      {`${(prefectureName && prefectureName.jpName) || ""}${
        (addressInfo && addressInfo.addressCity) || ""
      }${(addressInfo && addressInfo.addressStreetNumber) || ""}${
        addressInfo && addressInfo.addressBuilding
          ? ` ${addressInfo.addressBuilding}`
          : ""
      }`}
    </React.Fragment>
  );
};
