import React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import CurrencyText from "../CurrencyText";

import { japaneseList } from "../../../../Resources/japaneseList";

interface Props extends WithStyles<typeof styles> {
  deliveryFee: number;
  deliveryArrangementFee: number;
}
const styles = (theme: any) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "80px auto",
      gridGap: "20px",
      borderTop: "#ccc solid 1px",
    },

    titleItem: {
      backgroundColor: "#eee",
      padding: "8px 10px 8px 10px",
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
      color: "#808080",
      fontWeight: "bold",
      textAlign: "left",
      whiteSpace: "pre-wrap",
    },

    description: {
      marginTop: "20px",
      whiteSpace: "pre-wrap",
    },

    contentItem: {
      padding: "10px 20px 10px 0px",
      margin: "0",
      fontSize: "14px",
      color: "#393939",
      textAlign: "left",
      wordBreak: "break-word",
      whiteSpace: "pre-wrap",
    },

    contentItemSubGroup: {
      display: "flex",
      padding: "15px 0px",
      alignItems: "flex-start",
      gap: "10px",
    },
    contentFeeBlock: {
      display: "flex",
      minWidth: "42px",
      height: "21px",
      paddingRight: "0px",
      justifyContent: "left",
      alignItems: "flex-end",
      gap: "4px",
    },
    contentFeeItem: {
      textAlign: "right",
      fontSize: "12px",
    },
    contentDescriptionItem: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      color: "#808080",
      fontSize: "11px",
      lineHeight: "normal",
      whiteSpace: "pre-wrap",
      alignSelf: "center",
    },

    contentItemDisplayName: {
      padding: "8px 20px 8px 0px",
      marginTop: "auto",
      marginBottom: "auto",
    },
  });
const YamatoDeliveryFeeContent = (props: Props) => {
  const { classes, deliveryFee, deliveryArrangementFee } = props;
  const Resource = japaneseList.Components.Payment.YamatoDeliveryDialog;
  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.titleItem}>
          {Resource.DialogContent.containerBorder.deliveryFee}
        </div>
        <div className={classes.contentItemSubGroup}>
          <div className={classes.contentFeeBlock}>
            <div className={classes.contentFeeItem}>
              <CurrencyText value={deliveryFee}></CurrencyText>
            </div>
          </div>
          <div className={classes.contentDescriptionItem}>
            {Resource.DialogContent.containerBorder.deliveryFeeDescription}
          </div>
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.titleItem}>
          {Resource.DialogContent.containerBorder.deliveryArrangementFee}
        </div>
        <div className={classes.contentItemSubGroup}>
          <div className={classes.contentFeeBlock}>
            <div className={classes.contentFeeItem}>
              <CurrencyText
                value={deliveryArrangementFee}
                style={{ float: "right" }}
              ></CurrencyText>
            </div>
          </div>
          <div className={classes.contentDescriptionItem}>
            {
              Resource.DialogContent.containerBorder
                .deliveryArrangementFeeDescription
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(YamatoDeliveryFeeContent);
