import React from "react";
import styled from "styled-components";
import { GetApp } from "@material-ui/icons";
import ButtonBase from "../../atoms/buttons/ButtonBase";

const StyledDownloadButton = styled(ButtonBase)`
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background-color: ${(p) => p.theme.color.primary};
  box-shadow: 0px 1px 0px ${(p) => p.theme.button.color.shadow};
  display: flex;
  padding: 0;
  border: none;
  border-radius: 3px;
  height: 28px;
`;

const DownloadIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin: 2px;
`;

const DownloadText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  margin-right: 5px;
`;

export const DownloadButton = ({ fileUrl }) => {
  const downloadFile = (fileUrl) => {
    //ランダムなパラメータを付与してリンクと異なるURLを設定する。（Chromeでリンクから資料表示してからダウンロードするとヘッダがキャッシュされてCorsエラーになる。）
    fetch(fileUrl + "?s=" + Math.random().toString())
      .then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          const filename = new URL(fileUrl).pathname.split("/").pop();
          a.download = decodeURI(filename);
          a.click();
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <React.Fragment>
      <StyledDownloadButton onClick={() => downloadFile(fileUrl)}>
        <DownloadIcon>
          <GetApp color="secondary" />
        </DownloadIcon>
        <DownloadText>ダウンロード</DownloadText>
      </StyledDownloadButton>
    </React.Fragment>
  );
};
