import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { SETTLEMENT_ERROR } from "../../../Utils/const";
import { Operator } from "../../atoms/others/Operator";
import { OperationTime } from "../../../Utils/OperationTime";

const Resource = japaneseList.molecules.operationTypes.Settle;

const PAYMENT_STATUS = {
  paymentError: 1,
  deliveryError: 2,
};
const deliveryErrorSet = [
  SETTLEMENT_ERROR.DELIVERY_MAINTENANCE,
  SETTLEMENT_ERROR.DELIVERY_PATIENT,
  SETTLEMENT_ERROR.DELIVERY_SYSTEM,
];
const settlementErrorSet = [
  SETTLEMENT_ERROR.CREDIT_CARD,
  SETTLEMENT_ERROR.CARD_SYSTEM,
  SETTLEMENT_ERROR.UNEXPECTED_SYSTEM,
  SETTLEMENT_ERROR.INVALID_AMOUNT,
];

const Tr = styled.tr`
  background: #fafafa 0% 0% no-repeat padding-box;
`;

const Td = styled.td`
  border-right: 1px #cccccc solid;
  font-size: 12px;
  white-space: pre-wrap;
  padding: 8px 10px;
`;

const OperationContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 160px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;
export const Settle = ({ key, operationHistory }) => {
  const editOperationHistoryContent = (operationHistory) => {
    if (operationHistory.operationDetail.payment) {
      const settlementError = settlementErrorSet.includes(
        operationHistory.operationDetail.payment.errorCode
      );
      const deliveryError = deliveryErrorSet.includes(
        operationHistory.operationDetail.payment.errorCode
      );
      const status = settlementError
        ? PAYMENT_STATUS.paymentError
        : deliveryError
        ? PAYMENT_STATUS.deliveryError
        : 0;
      return (
        Resource.j002 +
        Resource.j003 +
        Resource.j001 +
        (status === PAYMENT_STATUS.paymentError
          ? Resource.j004
          : status === PAYMENT_STATUS.deliveryError
          ? Resource.j005
          : "")
      );
    } else {
      return Resource.j002 + Resource.j003;
    }
  };
  const operationHistoryContent = editOperationHistoryContent(operationHistory);

  return (
    <React.Fragment>
      <Tr key={key}>
        <Td>
          <OperationTime operationHistory={operationHistory} />
        </Td>
        <Td>
          <Operator operationHistory={operationHistory} />
        </Td>
        <Td>
          <OperationContent>{operationHistoryContent}</OperationContent>
        </Td>
      </Tr>
    </React.Fragment>
  );
};
