import React from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { isLnln } from "../../../Utils/checkLnln";
import Logo from "../../../images/carada/logo/logo.svg";
import LogoLnln from "../../../images/lnln/logo/logo.svg";
import { japaneseList } from "../../../Resources/japaneseList";

const FlexWrapper = styled.div`
  flex-grow: 1;
`;

const HeaderLogo = () => {
  const logo = isLnln() ? LogoLnln : Logo;
  const Resource = japaneseList.Components.Header;

  return (
    <React.Fragment>
      <FlexWrapper>
        <Link to="/">
          {/* iPad横未満 */}
          <MediaQuery query="(max-width: 1023px)">
            <img
              src={logo}
              alt={Resource.render.AppBar.Toolbar.div.Link.img.j001}
              style={{ width: "150px" }}
            />
          </MediaQuery>
          {/* PC */}
          <MediaQuery query="(min-width: 1024px)">
            <img
              src={logo}
              alt={Resource.render.AppBar.Toolbar.div.Link.img.j001}
              style={{
                width: "260px",
                verticalAlign: "middle",
              }}
            />
          </MediaQuery>
        </Link>
      </FlexWrapper>
    </React.Fragment>
  );
};

export default HeaderLogo;
