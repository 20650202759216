import React from "react";
import styled from "styled-components";
import { IconCameraNG } from "../../atoms/icons/IconCameraNG";
import { IconMicNG } from "../../atoms/icons/IconMicNG";
import { japaneseList } from "../../../Resources/japaneseList";

const DeviceErrorDetailResource: DeviceErrorDetailResourceInterface =
  japaneseList.Pages.Medical.DeviceErrorDetail;

const PatientSiteStatusResource: PatientSiteStatusResourceInterface =
  japaneseList.Pages.Medical.PatientSiteStatus;

const Container = styled.div``;

const DeviceErrorText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #393939;
  text-align: left;
  padding: 10px 0;
`;

const DeviceErrorIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 10px 0 10px;
`;

interface DeviceErrorDetailProps {
  patientStandbyStatus: {
    isLogin: boolean;
    videoStandby: {
      isOpeningStandbyPage: boolean;
      lastMediaDeviceError: string;
    };
  };
}

interface DeviceErrorDetailResourceInterface {
  [key: string]: { [key: string]: string };
}
interface PatientSiteStatusResourceInterface {
  [key: string]: Record<string, string>;
}

export const DeviceErrorDetail = (props: DeviceErrorDetailProps) => {
  const { isLogin, videoStandby } = props.patientStandbyStatus;

  const renderErrorMessage = () => {
    if (!isLogin) {
      return (
        <React.Fragment>
          {PatientSiteStatusResource.NotLoginError.j001}
          <br />
          {PatientSiteStatusResource.NotLoginError.j002}
        </React.Fragment>
      );
    } else if (!videoStandby.isOpeningStandbyPage) {
      return (
        <React.Fragment>
          {PatientSiteStatusResource.NotOpenVideoStandbyPage.j001}
          <br />
          {PatientSiteStatusResource.NotOpenVideoStandbyPage.j002}
        </React.Fragment>
      );
    } else if (videoStandby.lastMediaDeviceError) {
      return DeviceErrorDetailResource[videoStandby.lastMediaDeviceError].j001;
    } else {
      return "";
    }
  };

  return (
    <Container>
      {isLogin && videoStandby.isOpeningStandbyPage && (
        <DeviceErrorIcons>
          <IconCameraNG />
          <IconMicNG />
        </DeviceErrorIcons>
      )}
      <DeviceErrorText>{renderErrorMessage()}</DeviceErrorText>
    </Container>
  );
};
