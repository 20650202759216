import React from "react";
import styled from 'styled-components';
import { DocumentListInner } from './DocumentListInner';

const WhiteBack = styled.div`
  background-color: ${p => p.theme.color.fontInvert};
  padding: 30px 20px;
`;

export const DocumentList = ({documents}) => {  
  return (
    <React.Fragment>
      <WhiteBack>
        <DocumentListInner
          documents={documents}
          title={'操作方法'}
          category={'OPERATION_MANUAL'}
        />
        <DocumentListInner
          documents={documents}
          title={'販促物'}
          category={'PROMOTIONAL_MATERIAL'}
        />
        <DocumentListInner
          documents={documents}
          title={'困った時は'}
          category={'IN_TROUBLE'}
        />
        <DocumentListInner
          documents={documents}
          title={'その他'}
          category={'OTHER'}
        />
      </WhiteBack>
    </React.Fragment>
  );
};