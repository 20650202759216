import styled from "styled-components";
import FontPError from "../../atoms/fonts/FontPError";
import { DrugNameText } from "../../molecules/appointment/DrugNameText";
import { DrugNameContent } from "../../molecules/appointment/DrugNameContent";
import { DrugNameSelect } from "../../molecules/appointment/DrugNameSelect";
import { PRESCRIPTION_DRUG_INPUT_METHOD } from "../../../Utils/const";

const Container = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  grid-gap: 20px;
  border-top: #ccc solid 1px;
`;

const TitleItem = styled.div`
  background-color: #eee;
  padding: 8px 10px 8px 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #808080;
  font-weight: bold;
  text-align: left;
  white-space: pre-wrap;
`;

const ContentItem = styled.div`
  padding: 8px 20px 8px 0px;
  font-size: 14px;
  color: #393939;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  word-break: break-word;
  white-space: pre-wrap;
`;

const FontPValidateError = styled(FontPError)`
  margin: auto;
  color: #ef5e5e;
  font-weight: bold;
  font-size: ${(p) => p.theme.font.size.s};
`;

interface DrugNameInputProps {
  title: string;
  drugsNameValue: string;
  handleChangeDrugName: () => void;
  disabled: boolean;
  isValidateError: boolean;
  errMessage?: string;
  inputMethod: string;
  selectableDrugNames?: string[];
}

export const DrugNameInput = (props: DrugNameInputProps) => {
  return (
    <Container>
      <TitleItem>{props.title}</TitleItem>
      <ContentItem>
        {props.isValidateError && (
          <FontPValidateError>{props.errMessage}</FontPValidateError>
        )}
        {(props.inputMethod === PRESCRIPTION_DRUG_INPUT_METHOD.FREE ||
          props.inputMethod === PRESCRIPTION_DRUG_INPUT_METHOD.NONE) && (
          <DrugNameText
            drugsNameValue={props.drugsNameValue}
            handleChangeDrugName={props.handleChangeDrugName}
            disabled={props.disabled}
            isValidateError={props.isValidateError}
          />
        )}
        {props.inputMethod === PRESCRIPTION_DRUG_INPUT_METHOD.FIXED && (
          <DrugNameContent drugsNameValue={props.drugsNameValue} />
        )}
        {props.inputMethod === PRESCRIPTION_DRUG_INPUT_METHOD.SELECT && (
          <DrugNameSelect
            drugsNameValue={props.drugsNameValue}
            handleChangeDrugName={props.handleChangeDrugName}
            disabled={props.disabled}
            isValidateError={props.isValidateError}
            selectableDrugNames={props.selectableDrugNames || [""]}
          />
        )}
      </ContentItem>
    </Container>
  );
};
