import React from "react";
import { createGlobalStyle } from "styled-components";
import Titles from "../../../Resources/Titles";
import SignInForm from "../../organisms/signin/SignInForm";
import SignInCaradaTemplate from '../../templates/SignInCaradaTemplate';
import SignInLnlnTemplate from '../../templates/SignInLnlnTemplate';
import { isLnln } from "../../../Utils/checkLnln";

/** Specify background color for set sign in page only.  */
const GlobalStyle = createGlobalStyle`
  body {
    background-color: #ffffff !important;
  }
`;

const SignIn = (props) => {
  const SignInTemplate = isLnln() ? SignInLnlnTemplate : SignInCaradaTemplate;

  return (
    <React.Fragment>
      <SignInTemplate
        title={Titles.signIn}
        signInForm={<SignInForm authcb={props.authcb} />}
      />
      <GlobalStyle />
    </React.Fragment>
  );
}

export default SignIn;
