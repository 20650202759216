// library
import React, { Component } from "react";
import { japaneseList } from "../../../Resources/japaneseList";

// material ui
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";

import { format, differenceInYears } from "date-fns";
import { LoadingScreenForDialog } from "../../molecules/others/LoadingScreenForDialog";

const styles = {
  wrapper: {
    color: "#393939",
    fontSize: "14px",
    lineHeight: "1",
  },
  confirmContentWrapper: {
    margin: "30px",
  },
  confirmContent: {
    marginBottom: "20px",
  },
  confirmMessage: {
    lineHeight: "1.5",
    margin: "-3.5px 0",
    color: "#ef5e5e",
  },
  slotConfirmInfo: {
    margin: "30px 0 0",
    fontWeight: "bold",
  },
  appointmentConfirmInfo: {
    margin: "20px 0 0",
    fontWeight: "bold",
  },
  appDetail: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto auto auto",
    gridTemplateRows: "auto auto",
    border: "solid 1px #ccc",
    marginTop: "10px",
  },
  appRow: {
    display: "contents",
  },
  appHeader: {
    backgroundColor: "#eee",
    textAlign: "center",
    color: "#808080",
    padding: "5px",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "1",
    borderRight: "solid 1px #ccc",
    "&:last-child": {
      borderRight: "none",
    },
  },
  appContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    padding: "10px",
    textAlign: "center",
    fontSize: "14px",
    lineHeight: "1",
    borderRight: "solid 1px #ccc",
    "&:last-child": {
      borderRight: "none",
    },
  },
  patientName: {
    textAlign: "left",
  },
  patientNameKana: {
    margin: "5px 0 0",
    lineHeight: "1",
    fontSize: "10px",
  },
  menuDetail: {
    textAlign: "left",
  },
  menuName: {
    marginBottom: "3px",
  },
  supportsInsuranceTreatment: {
    border: "solid 1px #27cdcb",
    padding: "3px",
    fontSize: "10px",
    lineHeight: "1",
  },
  overlappingOtherCount: {
    marginTop: "10px",
    textAlign: "right",
    color: "#808080",
    fontSize: "12px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0 20px 20px",
  },
};

const ConfirmResource =
  japaneseList.Components.Appointment.DialogConfirmRegistration;
const ConfirmRenderResource = ConfirmResource.render.Dialog.div.div;

class DialogConfirmRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
    };
  }

  create = async () => {
    if (this.state.isSubmitting) {
      return false;
    }
    this.setState({ isSubmitting: true });

    const { result } = await this.props.accept();
    if (result === "failed" || result === "confirming") {
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const { classes, confirms, type } = this.props;
    if (!confirms || confirms.length === 0) return "";
    // FIXME: (todo refactering) Effectively, there can be only one confirm.
    const confirm = confirms[0];
    const { patient, menu } = confirm.existing || {};
    const patientBirthdate =
      patient && format(new Date(patient.birthdate), "yyyy/MM/dd");
    const patientAge =
      patient && differenceInYears(Date.now(), new Date(patient.birthdate));

    return (
      <div>
        <Dialog open={true}>
          <div className={classes.wrapper}>
            {type === "slot" ? (
              <div className={classes.confirmContentWrapper}>
                <p className={classes.confirmMessage}>
                  {confirm.message}
                  <br />
                  {ConfirmRenderResource.ConfirmText.j001}
                </p>
                <p className={classes.slotConfirmInfo}>{confirm.info}</p>
                <div className={classes.appDetail}>
                  <div className={classes.appRow}>
                    <div className={classes.appHeader}>
                      {ConfirmRenderResource.AppDetail.appRow.j001}
                    </div>
                    <div className={classes.appHeader}>
                      {ConfirmRenderResource.AppDetail.appRow.j002}
                    </div>
                    <div className={classes.appHeader}>
                      {ConfirmRenderResource.AppDetail.appRow.j003}
                    </div>
                    <div className={classes.appHeader}>
                      {ConfirmRenderResource.AppDetail.appRow.j004}
                    </div>
                    <div className={classes.appHeader}>
                      {ConfirmRenderResource.AppDetail.appRow.j005}
                    </div>
                  </div>
                  <div className={classes.appRow}>
                    <div className={classes.appContent}>
                      <div className={classes.patientName}>
                        {`${patient.familyName} ${patient.givenName}`}
                        <p className={classes.patientNameKana}>
                          {`${patient.familyNameKana} ${patient.givenNameKana}`}
                        </p>
                      </div>
                    </div>
                    <div className={classes.appContent}>
                      <div className={classes.menuDetail}>
                        <div className={classes.menuName}>
                          {menu.displayName}
                        </div>
                        <span className={classes.supportsInsuranceTreatment}>
                          {menu.supportsInsuranceTreatment
                            ? ConfirmRenderResource.AppDetail.insuranceCard.yes
                            : ConfirmRenderResource.AppDetail.insuranceCard.no}
                        </span>
                      </div>
                    </div>
                    <div className={classes.appContent}>{patientBirthdate}</div>
                    <div className={classes.appContent}>{patientAge}</div>
                    <div className={classes.appContent}>{patient.gender}</div>
                  </div>
                </div>
                {confirm.overlappingCount ? (
                  <div className={classes.overlappingOtherCount}>
                    {ConfirmResource.confirm.j003.replace(
                      /#overlappingCount/,
                      confirm.overlappingCount
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className={classes.confirmContentWrapper}>
                <div className={classes.confirmContent}>
                  <p className={classes.confirmMessage}>{confirm.message}</p>
                  {confirm.info ? (
                    <p className={classes.appointmentConfirmInfo}>
                      {confirm.info}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <p>{ConfirmRenderResource.ConfirmText.j002}</p>
              </div>
            )}
            <div className={classes.buttonWrapper}>
              <Button
                onClick={this.props.close}
                disabled={this.state.isSubmitting}
              >
                {ConfirmRenderResource.Button.j001}
              </Button>
              <Button
                onClick={this.create}
                color="primary"
                variant="contained"
                disabled={this.state.isSubmitting}
              >
                {ConfirmRenderResource.Button.j002}
              </Button>
            </div>
          </div>
        </Dialog>
        <LoadingScreenForDialog isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default withStyles(styles)(DialogConfirmRegistration);
