import { useState, useEffect } from 'react';
import { client, GetNewsList } from "../graphql";
import { isLnln } from '../Utils/checkLnln';

export const useNewsList = (hospital) => {
  const [newsList, setNewsList] = useState(null);

  const getNewsList = () => {
    const siteType = isLnln() ? 'LNLN' : 'CARADA';
    client.query({
      query: GetNewsList,
      variables: {
        input: {
          siteType: siteType,
        },
      },
    })
    .then(res => {
      setNewsList(res.data.newsList);
    })
    .catch(err => {
      console.error(err);
    });
  }

  useEffect(() => {
    if (hospital) {
      getNewsList();
    }
  }, [hospital]);

  return newsList;
}