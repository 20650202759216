import styled from "styled-components";
import WarningIcon from "../../../images/icon/error.svg";
import { DeviceErrorDetail } from "./DeviceErrorDetail";
import { PatientContact } from "./PatientContact";
import { getPhoneNumberWithoutPhoneCode } from "../../../Utils/DisplayLogic";
import { japaneseList } from "../../../Resources/japaneseList";

const CautionNgResource = japaneseList.Pages.Medical.CautionNG;

const Container = styled.div`
  margin: 0 16px;
  width: 198px;
  height: 285px;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const CautionWarning = styled.div`
  padding-top: 16px;
  width: 198px;
  height: 106px;
  background-color: #fff0f0;
  text-align: center;
`;

const CautionWarningIcon = styled.img`
  display: block;
  margin: 0px auto 10px;
`;

const CautionWarningText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #393939;
`;

interface CautionNGProps {
  patient: {
    familyName: string;
    givenName: string;
    phoneNumber: string;
  };
  patientStandbyStatus: {
    isLogin: boolean;
    videoStandby: {
      isOpeningStandbyPage: boolean;
      lastMediaDeviceError: string;
    };
  };
}

export const CautionNG = (props: CautionNGProps) => {
  const { patient, patientStandbyStatus } = props;
  const patientName = `${patient.familyName} ${patient.givenName}`;
  const patientPhone = getPhoneNumberWithoutPhoneCode(patient.phoneNumber);
  return (
    <Container>
      <CautionWarning>
        <CautionWarningIcon src={WarningIcon} alt={""} />
        <CautionWarningText>
          {CautionNgResource.text.j001}
          <br />
          {CautionNgResource.text.j002}
        </CautionWarningText>
      </CautionWarning>
      <DeviceErrorDetail patientStandbyStatus={patientStandbyStatus} />
      <PatientContact patientName={patientName} patientPhone={patientPhone} />
    </Container>
  );
};
