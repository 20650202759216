import styled from "styled-components";
import { DialogSelect } from "./DialogSelect";
import { japaneseList } from "../../../Resources/japaneseList";
import { BILLING_CATEGORY_OPTION } from "../../../Utils/const";

const billingCategoryOption =
  japaneseList.Components.Appointment.StatusChangeDialog.DialogContent
    .containerBorder.billingCategoryOption;
const billingCategoryNote =
  japaneseList.Components.Appointment.StatusChangeDialog.DialogContent
    .containerBorder.billingCategoryNote;

const optionList = Object.values(BILLING_CATEGORY_OPTION).map((option) => {
  return {
    optionName: billingCategoryOption[option],
    optionValue: option,
  };
});
const optionListNovisit = optionList.filter(
  (item) => item.optionValue != BILLING_CATEGORY_OPTION.OTHER
);
const Container = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  grid-gap: 20px;
  border-top: #ccc solid 1px;
`;

const TitleItem = styled.div`
  background-color: #eee;
  padding: 8px 10px 8px 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #808080;
  font-weight: bold;
  text-align: left;
  white-space: pre-wrap;
`;

const ContentItem = styled.div`
  padding: 8px 20px 8px 0px;
  font-size: 14px;
  color: ${(p) => p.theme.color.font};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  word-break: break-word;
  white-space: pre-wrap;
`;
const ContentItemNote = styled.div`
  padding: 8px 20px 8px 0px;
  font-size: 14px;
  color: ${(p) => p.theme.color.font};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  word-break: break-word;
  white-space: pre-wrap;
`;

interface BillingCategorySelectProps {
  title: string;
  isShowNote: boolean;
  billingCategoryValue: string;
  handleChangeBillingCategory: () => void;
  disabled: boolean;
}

export const BillingCategorySelect = (props: BillingCategorySelectProps) => {
  return (
    <Container>
      <TitleItem>{props.title}</TitleItem>
      <ContentItem>
        <DialogSelect
          selectedValue={props.billingCategoryValue}
          onChange={props.handleChangeBillingCategory}
          disabled={props.disabled}
          defaultOptionName={billingCategoryOption.j001}
          optionList={optionListNovisit}
        />
        {props.isShowNote && (
          <ContentItemNote>{billingCategoryNote}</ContentItemNote>
        )}
      </ContentItem>
    </Container>
  );
};
