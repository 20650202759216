class PatientDetail {
  constructor() {
    this.window = null;
  }

  /**
   * 患者詳細ページを新しいタブに表示します。
   * 既に患者詳細ページのタブを開いている場合は内容を更新します。
   * @param {String} patientId
   */
  openPatientDetailWindow = (patientId) => {
    if (this.window && !this.window.closed) {
      this.window.close();
    }
    this.window = window.open(
      `/patient?patientId=${patientId}`,
      "CaradaPatientDetailPage"
    );
  };
}

export const patientDetail = new PatientDetail();
