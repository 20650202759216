import styled from "styled-components";
import FontPError from "../../atoms/fonts/FontPError";
import { DialogSelect } from "./DialogSelect";
import { japaneseList } from "../../../Resources/japaneseList";
const Resources = japaneseList.Components.Payment.MedicalFeeDialog;

const Container = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  grid-gap: 20px;
  border-top: #ccc solid 1px;
`;

const TitleItem = styled.div`
  background-color: #eee;
  padding: 8px 10px 8px 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #808080;
  font-weight: bold;
  text-align: left;
  white-space: pre-wrap;
`;

const ContentItem = styled.div`
  padding: 8px 20px 8px 0px;
  font-size: 14px;
  color: ${(p) => p.theme.color.font};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  word-break: break-word;
  white-space: pre-wrap;
`;

const NoteItem = styled.div`
  padding: 8px 20px 8px 0px;
  font-size: 14px;
  color: ${(p) => p.theme.color.font};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  word-break: break-word;
  white-space: pre-wrap;
`;

const FontPValidateError = styled(FontPError)`
  margin: auto;
  color: #ef5e5e;
  font-weight: bold;
  font-size: ${(p) => p.theme.font.size.s};
`;

interface DrugQuantitySelectProps {
  title: string;
  note: string;
  drugsQuantityValue: string;
  handleChangeDrugQuantity: () => void;
  disabled: boolean;
  isValidateError: boolean;
  errMessage: string;
  drugQuantityOptionList: Int32Array[];
}

export const DrugQuantitySelect = (props: DrugQuantitySelectProps) => {
  const optionList = props.drugQuantityOptionList
    ? props.drugQuantityOptionList.map((value) => {
        return {
          optionName: value.toString(),
          optionValue: value.toString(),
        };
      })
    : [].map(() => {
        return {
          optionName: "",
          optionValue: "",
        };
      });

  return (
    <Container>
      <TitleItem>{props.title}</TitleItem>
      <ContentItem>
        {props.isValidateError && (
          <FontPValidateError>{props.errMessage}</FontPValidateError>
        )}
        <DialogSelect
          selectedValue={props.drugsQuantityValue}
          onChange={props.handleChangeDrugQuantity}
          disabled={props.disabled}
          isValidateError={props.isValidateError}
          defaultOptionName={Resources.PlaceHolderDrugsQuantityEWP}
          optionList={optionList}
        />
        <NoteItem>{props.note}</NoteItem>
      </ContentItem>
    </Container>
  );
};
