import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  tbl: {
    "table-layout": "auto",
    width: "100%",
    borderCollapse: "collapse",
    backgroundColor: "ffffff",
  },
  td: {
    whiteSpace: "nowrap",
    padding: "0",
  },
  title: {
    height: "35px",
    lineHeight: "35px",
    paddingLeft: "6px",
    borderTop: "1px #cccccc solid",
    borderLeft: "1px #cccccc solid",
    backgroundColor: "#eeeeee",
  },
  content: {
    height: "35px",
    lineHeight: "35px",
    paddingLeft: "20px",
    borderTop: "1px #cccccc solid",
    borderRight: "1px #cccccc solid",
  },
};

const EventBaseItem = (props) => {
  const { items, classes } = props;

  return (
    <table className={classes.tbl}>
      <tbody>
        <tr>
          {items.map((x, i) => {
            return (
              <td
                width={x.width || ""}
                className={classes.td}
                align={x.align || "left"}
                valign={x.valign || "middle"}
                key={i}
              >
                <div className={i === 0 ? classes.title : classes.content}>
                  {x.content}
                </div>
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};

export default withStyles(styles)(EventBaseItem);
