import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import InputTime from "../../../atoms/forms/InputTime";

import { japaneseList } from "../../../../Resources/japaneseList";

const Resource = japaneseList.Components.Appointment.Stepper.DatePicker;
const styles = (theme) => {
  return {
    title: {
      textAlign: "center",
    },
    content: {
      minWidth: "300px",
      textAlign: "center",
    },
    button: {
      backgroundColor: theme.button.ok.backgroundColor,
      boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
      "&:hover": {
        backgroundColor: theme.button.ok.backgroundColorHover,
      },
    },
  };
};
class TimePicker extends Component {
  static defaultProps = {
    step: 60 * 15, //seconds
  };

  constructor(props) {
    super(props);
    this.state = {
      time: this.props.time,
    };
  }

  handleClose = () => {};
  handleChangeTime = (e) => {
    this.setState({ time: e.target.value });
  };

  handleClickUpdate = () => {
    this.props.updateTime(this.state.time);
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog open={this.props.open} onClose={this.handleClose}>
        <DialogTitle className={classes.title}>
          {Resource.render.Dialog.DialogTitle.j001}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <InputTime
            defaultValue={this.props.time}
            onChange={this.handleChangeTime}
            step={this.props.step}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose}>
            {Resource.render.Dialog.DialogActions.Button.j001}
          </Button>
          <Button
            onClick={this.handleClickUpdate}
            color="primary"
            variant="contained"
            autoFocus
            disabled={!this.state.time}
            className={classes.button}
          >
            {Resource.render.Dialog.DialogActions.Button.j002}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(TimePicker);
