import styled from "styled-components";
import ErrorIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";
import { japaneseList } from "../../../Resources/japaneseList";

const Resource = japaneseList.Pages.Medical;

interface ChattingPromptInformationAreaProps {
  isDisplayDoctorLowNetwork: boolean;
  isDisplayPatientLowNetwork: boolean;
  isDisplayPatientLeaveRoom: boolean;
  isDisplayDoctorLowAudio: boolean;
  isDisplayPatientNotExistedRoom: boolean;
}

const WarningMessageContainer = styled.div`
  width: 100%;
  margin: 6px;
  padding: 14px 17px;
  background-color: rgba(57, 57, 57, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.8);
  display: flex;
  & svg {
    font-size: 18px;
    color: white;
  }
  & p {
    font-size: 14px;
    color: white;
    margin-left: 8px;
    margin-top: -1px;
  }
`;

const Container = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  width: calc(100% - 32px);
`;

export const ChattingPromptInformationArea = (
  props: ChattingPromptInformationAreaProps
) => {
  const {
    isDisplayDoctorLowNetwork,
    isDisplayPatientLowNetwork,
    isDisplayPatientLeaveRoom,
    isDisplayDoctorLowAudio,
    isDisplayPatientNotExistedRoom,
  } = props;
  return (
    <Container>
      {isDisplayDoctorLowNetwork && (
        <WarningMessageContainer>
          <ErrorIcon />
          <Typography>{Resource.NetworkQualityWarning.Doctor.j001}</Typography>
        </WarningMessageContainer>
      )}
      {isDisplayPatientLowNetwork && (
        <WarningMessageContainer>
          <ErrorIcon />
          <Typography>{Resource.NetworkQualityWarning.Patient.j001}</Typography>
        </WarningMessageContainer>
      )}
      {isDisplayPatientLeaveRoom && (
        <WarningMessageContainer>
          <ErrorIcon />
          <Typography>{Resource.NetworkQualityWarning.Patient.j002}</Typography>
        </WarningMessageContainer>
      )}
      {isDisplayDoctorLowAudio && (
        <WarningMessageContainer>
          <ErrorIcon />
          <Typography>{Resource.AudioLowWarning.Doctor.j001}</Typography>
        </WarningMessageContainer>
      )}
      {isDisplayPatientNotExistedRoom && (
        <WarningMessageContainer>
          <ErrorIcon />
          <Typography>{Resource.NetworkQualityWarning.Patient.j003}</Typography>
        </WarningMessageContainer>
      )}
    </Container>
  );
};
