// --------------------------------------------------
//
// Dialog ccontent
//
// --------------------------------------------------

// required　modules
// --------------------------------------------------
import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  tbl: {
    "table-layout": "auto",
    width: "100%",
    borderCollapse: "collapse",
    backgroundColor: "#FFF"
  },
  td: {
    whiteSpace: "nowrap"
  },
  content: {
    display: "flex"
  }
};

// class define
// --------------------------------------------------
const DialogTableItem = props => {
  const { items, classes, margin } = props;
  return (
    <table className={classes.tbl} style={{ margin }}>
      <tbody>
        <tr>
          {items.map((x, i) => {
            return (
              <td
                width={x.width || ""}
                className={classes.td}
                align={x.align || "left"}
                valign={x.valign || "middle"}
                key={i}
                style={{ paddingTop: x.paddingTop }}
              >
                <div className={classes.content}>{x.content}</div>
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};

export default withStyles(styles)(DialogTableItem);
