import { client, GetPatientByPatientId } from "../graphql";

const showAlertPages = [
  "/",
  "/appointment/unapproved",
  "/appointment/calendar",
  "/appointment",
  "/payment",
];
const MINUTES_MAX_VIDEO_CHAT_TIME = 60;

const isVideoConnecting = async () => {
  try {
    const videoConnectingInfo = localStorage.getItem("videoConnectingInfo");
    if (
      !(
        showAlertPages.includes(window.location.pathname) && videoConnectingInfo
      )
    ) {
      return false;
    }

    const [patientId, createTime, videoStartTimestamp] =
      videoConnectingInfo.split("|");
    if (
      Date.now() - parseInt(videoStartTimestamp, 10) >
      MINUTES_MAX_VIDEO_CHAT_TIME * 60 * 1000
    ) {
      return false;
    }

    const videoChatAppointmentKey = await getVideoChatAppointmentKey(patientId);
    if (videoChatAppointmentKey !== createTime) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

const getVideoChatAppointmentKey = async (patientId: string) => {
  try {
    const { data } = await client.query({
      query: GetPatientByPatientId,
      variables: { patientId },
    });
    if (data && data.patient && data.patient.videoChatAppointmentKey) {
      return data.patient.videoChatAppointmentKey;
    }
  } catch (error) {
    console.error(error);
  }
};

export { isVideoConnecting };
