import gql from "graphql-tag";

export const DeleteAttachment = gql`
  mutation DeleteAttachment(
    $patientId: ID!
    $createTime: String!
    $filePath: String!
  ) {
    deleteAttachment(
      patientId: $patientId
      createTime: $createTime
      filePath: $filePath
    )
  }
`;
