import gql from "graphql-tag";

export const GetOperationHistorys = gql`
  query GetOperationHistorys($patientId: String!, $createTime: String!) {
    operationHistory(patientId: $patientId, createTime: $createTime) {
      appointmentHistoryId
      appointmentId
      operationTime
      patientId
      createTime
      hospitalId
      doctorId
      operationType
      operationDetail {
        before {
          from
          to
          status
          deliveryTotalFee
          medicalExpense
        }
        after {
          from
          to
          status
          deliveryTotalFee
          medicalExpense
        }
        payment {
          errorCode
          gmoCode
          gmoInfo
        }
        attachment {
          fileNames {
            fileName
          }
          expiredTime
          isBeforeExpiredTime
        }
        administrator {
          message
        }
      }
      operatorType
      operatorDoctorId
      operatorDoctorName
    }
  }
`;
