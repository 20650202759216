import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import LinkA from "../../../atoms/links/LinkA";
import { japaneseList } from "../../../../Resources/japaneseList";

const Resource = japaneseList.Components.Payment.YamatoInquiryAnnounce;

const YAMATO_SERVICE_LINK =
  "http://www.kuronekoyamato.co.jp/ytc/center/servicecenter_list.html";

const styles = {
  container: {
    marginTop: "20px",
  },
};

const LinkAStyle = styled(LinkA)`
  text-decoration: underline;
  letter-spacing: 0px;
  color: #2563c0;
  opacity: 1;
`;

class YamatoInquiryAnnounce extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <LinkAStyle href={YAMATO_SERVICE_LINK} target="_blank">
          {Resource.render.LinkText.j001}
        </LinkAStyle>
        {Resource.render.LinkText.j002}
      </div>
    );
  }
}

export default withStyles(styles)(YamatoInquiryAnnounce);
