import { DELIVERY_OPTION } from "../Utils/const";

interface Appointment {
  deliveryRelatedFee: DeliveryRelatedFee;
  postDeliveryRelatedFee: PostDeliveryRelatedFee;
  payment: Payment;
}

interface DeliveryRelatedFee {
  deliveryFee: number;
  deliveryFeeTax: number;
  deliveryMaterialFee: number;
  deliveryTotalFee: number;
}

interface PostDeliveryRelatedFee {
  deliveryTotalFee: number;
}

interface Payment {
  deliveryTotalFee: number;
}

interface DeliveryDisplayFee {
  deliveryFee: number;
  deliveryArrangementFee: number;
  deliveryTotalFee: number;
}

interface RequestDelivery {
  deliveryFee: string;
}

const DELIVERY_ARRANGEMENT_FEE_RATE = 0.97;

const deliveryFeeCalculator = (
  appointment: Appointment,
  deliverySelectedOption: number
): DeliveryDisplayFee | null => {
  const { deliveryRelatedFee, postDeliveryRelatedFee, payment } = appointment;
  switch (deliverySelectedOption) {
    case DELIVERY_OPTION.yamato: {
      const deliveryTotalFee = Math.round(
        payment.deliveryTotalFee
          ? payment.deliveryTotalFee
          : deliveryRelatedFee.deliveryTotalFee
      );
      const deliveryFee = Math.round(
        deliveryTotalFee * DELIVERY_ARRANGEMENT_FEE_RATE
      );
      const deliveryArrangementFee = Math.round(deliveryTotalFee - deliveryFee);

      return {
        deliveryFee,
        deliveryArrangementFee,
        deliveryTotalFee,
      };
    }
    case DELIVERY_OPTION.csvPost: {
      const deliveryFee = Math.round(
        payment.deliveryTotalFee
          ? payment.deliveryTotalFee
          : postDeliveryRelatedFee.deliveryTotalFee
      );
      const deliveryArrangementFee = Math.round(
        deliveryFee / DELIVERY_ARRANGEMENT_FEE_RATE - deliveryFee
      );
      const deliveryTotalFee = Math.round(deliveryFee + deliveryArrangementFee);
      return {
        deliveryFee,
        deliveryArrangementFee,
        deliveryTotalFee,
      };
    }
    case DELIVERY_OPTION.other: {
      const deliveryFee = Math.round(
        payment.deliveryTotalFee ? payment.deliveryTotalFee : 0
      );
      const deliveryArrangementFee = Math.round(
        payment.deliveryTotalFee
          ? payment.deliveryTotalFee / DELIVERY_ARRANGEMENT_FEE_RATE -
              payment.deliveryTotalFee
          : 0
      );
      const deliveryTotalFee = Math.round(deliveryFee + deliveryArrangementFee);
      return {
        deliveryFee,
        deliveryArrangementFee,
        deliveryTotalFee,
      };
    }
    case DELIVERY_OPTION.noDelivery: {
      return {
        deliveryFee: 0,
        deliveryArrangementFee: 0,
        deliveryTotalFee: 0,
      };
    }
    default: {
      return null;
    }
  }
};

const deliveryFeeCalculatorForRequestDelivery = (
  requestDelivery: RequestDelivery
): DeliveryDisplayFee | null => {
  if (!requestDelivery.deliveryFee) {
    return null;
  }
  const deliveryFee = Math.round(Number.parseInt(requestDelivery.deliveryFee));
  const deliveryTotalFee = Math.round(
    deliveryFee / DELIVERY_ARRANGEMENT_FEE_RATE
  );

  const deliveryArrangementFee = deliveryTotalFee - deliveryFee;

  return {
    deliveryFee,
    deliveryArrangementFee,
    deliveryTotalFee,
  };
};

export { deliveryFeeCalculator, deliveryFeeCalculatorForRequestDelivery };
