// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Divider,
  Typography,
} from "@material-ui/core";
import AccountImage from "../../../../images/account_circle-24px.svg";
import Radio from "../../../atoms/forms/Radio";
import styled from "styled-components";
import ja from "date-fns/locale/ja";
import { format } from "date-fns";
// personal modules
// --------------------------------------------------
import FontSpan from "../../../atoms/fonts/FontSpan";
import { MemoTextarea } from "../../../molecules/appointment/MemoTextarea";
import { DrugInput } from "../../../molecules/appointment/DrugInput";
import { BillingCategorySelect } from "../../../molecules/appointment/BillingCategorySelect";
import { DrugNameInput } from "../../../organisms/appointment/DrugNameInput";
import { DrugQuantitySelect } from "../../../molecules/appointment/DrugQuantitySelect";
import { MedicalFeeInput } from "../../../molecules/appointment/MedicalFeeInput";
import { japaneseList } from "../../../../Resources/japaneseList";
import {
  getAge,
  getDayTextJaFormatShort,
  toHalfWidthNumbers,
} from "../../../../Utils/DisplayLogic";
import NumberFormat from "react-number-format";
import {
  PAYMENT_METHOD,
  TREATMENT_COMPLETED_SELECTED_OPTION,
  BILLING_CATEGORY_OPTION,
} from "../../../../Utils/const";
const Resources = japaneseList.Components.Appointment.StatusChangeDialog;

const memoMaxlength = 500;
const drugNameMaxLengthEWP = 15;
const drugNameMaxLength = 30;
const drugQuantityMaxLength = 20;
const medicalFeeMax = 9999999;
const maleGender = Resources.genderMale;

// style
// --------------------------------------------------
const styles = (theme) => ({
  dialogTitleText: {
    fontSize: "20px",
    lineHeight: "0px",
  },

  dialogTitle: {
    padding: "24px",
  },

  dialogContent: {
    padding: "20px 25px 24px 25px",
  },

  dialogActions: {
    padding: "16px 24px 14px 0px",
    margin: "0px",
  },

  buttonOk: {
    color: theme.button.ok.color,
    width: "120px",
    height: "40px",
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    borderRadius: "6px",
    opacity: "1",
    margin: "0px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    fontWeight: "bold",
  },

  buttonCancel: {
    backgroundColor: "#808080",
    color: "white",
    width: "120px",
    height: "40px",
    background: "#808080 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 0px #505050",
    borderRadius: "6px",
    opacity: "1",
    margin: "0px 20px 0px 0px",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
    fontWeight: "bold",
  },

  buttonDisable: {
    background: "#DDDDDD 0% 0% no-repeat padding-box",
    pointerEvents: "none",
    color: "white",
    width: "120px",
    height: "40px",
    borderRadius: "6px",
    opacity: "1",
    margin: "0px",
    "&:hover": {
      background: "#DDDDDD 0% 0% no-repeat padding-box",
    },
    fontWeight: "bold",
  },

  containerTable: {
    display: "flex",
    boxSizing: "border-box",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "space-between",
    margin: "8px 0 0 0",
  },

  tableStyle: {
    borderCollapse: "collapse",
    width: "310px",
    tableLayout: "auto",
  },
  thBirthDay: {
    width: "98px",
  },
  thAge: {
    width: "26px",
  },
  thGender: {
    width: "48px",
  },
  thPhonenumber: {
    width: "120px",
  },

  thStyle: {
    textAlign: "center",
    borderTop: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    backgroundColor: "#eee",
    fontSize: "12px",
    color: "#808080",
  },

  tdStyle: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    color: "#393939",
    whiteSpace: "nowrap",
  },

  tdStyleGenderMale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },

  tdStyleGenderFemale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },

  containerBorder: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
    marginTop: "20px",
  },

  container: {
    display: "grid",
    gridTemplateColumns: "80px auto",
    gridGap: "20px",
    borderTop: "#ccc solid 1px",
  },

  titleItem: {
    backgroundColor: "#eee",
    padding: "8px 10px 8px 10px",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    color: "#808080",
    fontWeight: "bold",
    textAlign: "left",
    whiteSpace: "pre-wrap",
  },

  contentItem: {
    padding: "8px 20px 8px 0px",
    fontSize: "14px",
    color: "#393939",
    textAlign: "left",
    marginTop: "auto",
    marginBottom: "auto",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },

  radioStyle: {
    verticalAlign: "sub",
    width: "20px",
    height: "20px",
    background: "#EEEEEE 0% 0% no-repeat padding-box",
    backdropFilter: "blur(10px)",
    opacity: 1,
    webkitBackdropFilter: "blur(10px)",
    boxShadow: "0px 0px 0px #CCC",
    "&:checked": {
      border: `2px solid ${theme.color.borderRadio}`,
    },
    border: "2px solid #CCCCCC",
  },

  radioStyleCheck: {
    verticalAlign: "sub",
    width: "20px",
    height: "20px",
    background: "#EEEEEE 0% 0% no-repeat padding-box",
    backdropFilter: "blur(10px)",
    opacity: 1,
    webkitBackdropFilter: "blur(10px)",
    "&:checked": {
      border: `2px solid ${theme.color.borderRadio}`,
    },
  },

  maxHeightWidthDialog: {
    maxHeight: "96%",
    maxWidth: "810px",
  },

  nameStyle: {
    fontWeight: "bold",
    fontSize: "16px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },

  nameKanaStyle: {
    fontSize: "12px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },

  medicalFeeNote: {
    marginLeft: "15px",
    fontFamily: "Hiragino Kaku Gothic ProN",
    fontSize: "11px",
    textAlign: "left",
    letterSpacing: "0px",
    color: "#808080",
    opacity: "1",
  },

  displayValidateError: {
    margin: "auto",
    color: "#EF5E5E",
    fontWeight: "bold",
  },

  hasError: {
    background: "#FFF1F2 0% 0% no-repeat padding-box",
  },
});

const SpanCustom = styled.span`
  padding-right: 20px;
  margin-left: 8px;
  font-size: 14px;
  color: "#393939";
`;

// class define
// --------------------------------------------------
class ChangeStatusDialogInput extends Component {
  // default state
  // --------------------------------------------------
  static defaultProps = {
    open: false,
    onClose: function () {},
    maxWidth: "md",
    buttonOkText: Resources.buttonOk,
    handleClickOk: function () {},
    buttonCancelText: Resources.buttonCancel,
    handleClickCancel: function () {},
  };

  // constructor
  // --------------------------------------------------
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      checkMemoInput: true,
      checkMedicalFeeZero: true,
      checkMedicalFeeLength: true,
      checkDrugNameEmpty: true,
      checkDrugNameLength: true,
      checkDrugQuantityEmpty: true,
      checkDrugQuantityLength: true,
      checkDrugQuantityValid: true,
      enable: true,
      selectOption: false,
      treatmentInfor: "",
      enableSubmitButton: true,
    };
  }

  handleDateChange = (date) => {
    if (
      this.state.selectedDate ===
      format(date, "yyyy年MM月dd日(iii)", { locale: ja })
    )
      return false;
    this.setState({
      selectedDate: format(date, "yyyy年MM月dd日(iii)", { locale: ja }),
    });
    this.afterChangedSchedule();
  };

  afterChangedSchedule = () => {
    this.setState({
      disable: true,
    });
  };

  handleEnableSubmitButton = (e) => {
    const isBillingEnterprise =
      this.props.appointment.menu &&
      this.props.appointment.menu.paymentMethod ===
        PAYMENT_METHOD.BILLING_ENTERPRISE;
    isBillingEnterprise
      ? this.validateBillingEnterpriseFormWhenKeyUp()
      : this.validateFormWhenKeyUp();
  };

  handleSelect = (e) => {
    const isBillingEnterprise =
      this.props.appointment.menu &&
      this.props.appointment.menu.paymentMethod ===
        PAYMENT_METHOD.BILLING_ENTERPRISE;
    if (Number(e.target.value) === -1) {
      this.setState({
        checkMemoInput: true,
        checkMedicalFeeZero: true,
        checkMedicalFeeLength: true,
        checkDrugNameEmpty: true,
        checkDrugNameLength: true,
        checkDrugQuantityEmpty: true,
        checkDrugQuantityLength: true,
        checkDrugQuantityValid: true,
        enableSubmitButton: true,
      });
    } else {
      isBillingEnterprise
        ? this.validateBillingEnterpriseFormWhenKeyUp()
        : this.validateFormWhenKeyUp();
    }
    this.props.handleSelectCompleteOption(e);
  };

  onExit = () => {
    this.setState({
      checkMemoInput: true,
      checkMedicalFeeZero: true,
      checkMedicalFeeLength: true,
      checkDrugNameEmpty: true,
      checkDrugNameLength: true,
      checkDrugQuantityEmpty: true,
      checkDrugQuantityLength: true,
      checkDrugQuantityValid: true,
      enableSubmitButton: true,
    });
  };

  validateFormWhenKeyUp = () => {
    const validationResult = {
      checkMedicalFeeZero:
        !this.props.treatmentInfor.requireMedicalExpense ||
        (toHalfWidthNumbers(this.props.treatmentInfor.medicalFee) || 0) !== 0,
      checkDrugNameEmpty:
        !this.props.treatmentInfor.requireDrugName ||
        (this.props.treatmentInfor.drugName &&
          this.props.treatmentInfor.drugName.trim().length > 0),
      checkDrugQuantityEmpty:
        !this.props.treatmentInfor.requireDrugQuantity ||
        (this.props.treatmentInfor.drugQuantity &&
          this.props.treatmentInfor.drugQuantity.trim().length > 0),
    };
    const enableSubmitButton = !Object.values(validationResult).some(
      (x) => x === false
    );
    this.setState({
      enableSubmitButton,
    });
    return enableSubmitButton;
  };

  validateBillingEnterpriseFormWhenKeyUp = () => {
    const validationResult = {
      checkDrugQuantityEmpty: this.props.treatmentInfor.drugQuantity,
      checkbillingCategoryEmpty: this.props.treatmentInfor.billingCategory,
    };
    const enableSubmitButton = !Object.values(validationResult).some((x) => !x);
    this.setState({
      enableSubmitButton,
    });
    return enableSubmitButton;
  };

  validateBillingEnterpriseForm = () => {
    const validationResult = {
      checkDrugQuantityValid:
        this.props.treatmentInfor.billingCategory ===
        BILLING_CATEGORY_OPTION.CONSULTATION
          ? this.props.treatmentInfor.drugQuantity === "0"
          : true,
      checkDrugNameEmpty:
        this.props.treatmentInfor.drugQuantity > "0"
          ? this.props.treatmentInfor.drugName
          : true,
      checkDrugNameLength:
        this.props.treatmentInfor.drugName.trim().length <=
        drugNameMaxLengthEWP,
      checkMemoInput:
        this.props.treatmentInfor.memo.trim().length <= memoMaxlength,
    };
    const enableSubmitButton = !Object.values(validationResult).some((x) => !x);
    this.setState({
      ...validationResult,
      enableSubmitButton,
    });
    return enableSubmitButton;
  };

  validateForm = () => {
    const validationResult = {
      checkMemoInput:
        this.props.treatmentInfor.memo.trim().length <= memoMaxlength,
      checkMedicalFeeZero:
        !this.props.treatmentInfor.requireMedicalExpense ||
        (this.props.treatmentInfor.medicalFee || 0) !== 0,
      checkMedicalFeeLength:
        !this.props.treatmentInfor.requireMedicalExpense ||
        this.props.treatmentInfor.medicalFee <= medicalFeeMax,
      checkDrugNameLength:
        !this.props.treatmentInfor.requireDrugName ||
        (this.props.treatmentInfor.drugName &&
          this.props.treatmentInfor.drugName.trim().length <=
            drugNameMaxLength),
      checkDrugNameEmpty:
        !this.props.treatmentInfor.requireDrugName ||
        (this.props.treatmentInfor.drugName &&
          this.props.treatmentInfor.drugName.trim().length > 0),
      checkDrugQuantityLength:
        !this.props.treatmentInfor.requireDrugQuantity ||
        (this.props.treatmentInfor.drugQuantity &&
          this.props.treatmentInfor.drugQuantity.trim().length <=
            drugQuantityMaxLength),
      checkDrugQuantityEmpty:
        !this.props.treatmentInfor.requireDrugQuantity ||
        (this.props.treatmentInfor.drugQuantity &&
          this.props.treatmentInfor.drugQuantity.trim().length > 0),
    };
    const enableSubmitButton = !Object.values(validationResult).some(
      (x) => x === false
    );
    this.setState({
      ...validationResult,
      enableSubmitButton,
    });
    return enableSubmitButton;
  };

  validateAndSubmitForm = (appointment) => (e) => {
    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;
    if (
      Number(this.props.treatmentInfor.completeSelectOption) ===
        TREATMENT_COMPLETED_SELECTED_OPTION.NOT_VISITED ||
      (isBillingEnterprise
        ? this.validateBillingEnterpriseForm()
        : this.validateForm())
    ) {
      this.props.handleOpenStatusChangeDialogConfirm(
        appointment,
        this.props.treatmentInfor
      );
    }
  };

  componentDidMount = () => {
    this.setState({
      treatmentInfor: this.props.treatmentInfor,
    });
  };

  handleClickCancelButtonDialogInput = () => {
    this.props.handleOpenCancelStatusChangeDialogConfirm();
  };

  // render
  // --------------------------------------------------
  render() {
    const { appointment, classes, open } = this.props;
    const patient = appointment.patient;
    let disabled =
      !this.props.treatmentInfor.completeSelectOption ||
      Number(this.props.treatmentInfor.completeSelectOption) ===
        TREATMENT_COMPLETED_SELECTED_OPTION.NOT_VISITED;
    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;
    return (
      <Dialog
        open={open}
        maxWidth={false}
        fullWidth={true}
        PaperProps={{ classes: { root: classes.maxHeightWidthDialog } }}
        onExit={this.onExit}
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          <FontSpan weight="bold" className={classes.dialogTitleText}>
            {Resources.DialogTitle}
          </FontSpan>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          {patient && (
            <Grid
              container
              wrap="nowrap"
              style={{ padding: "0px", margin: "0px" }}
            >
              <Grid item style={{ padding: "0px 10px 0px 0px" }}>
                <img src={AccountImage} alt={""} />
              </Grid>
              <Grid item style={{ padding: "0px" }}>
                <Typography className={classes.nameStyle}>
                  {patient.familyName} {patient.givenName}
                </Typography>
                <Typography className={classes.nameKanaStyle}>
                  {patient.familyNameKana} {patient.givenNameKana}
                </Typography>
              </Grid>
            </Grid>
          )}
          {patient && (
            <div className={classes.containerTable}>
              <table className={classes.tableStyle}>
                <thead>
                  <tr>
                    <th className={classes.thStyle + " " + classes.thBirthDay}>
                      {Resources.DialogContent.table.thBirthDay}
                    </th>
                    <th className={classes.thStyle + " " + classes.thAge}>
                      {Resources.DialogContent.table.thAge}
                    </th>
                    <th className={classes.thStyle + " " + classes.thGender}>
                      {Resources.DialogContent.table.thGender}
                    </th>
                    <th
                      className={classes.thStyle + " " + classes.thPhonenumber}
                    >
                      {Resources.DialogContent.table.thPhonenumber}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={classes.tdStyle}>
                      {format(new Date(patient.birthdate), "yyyy/MM/dd")}
                    </td>
                    <td className={classes.tdStyle}>
                      {patient.birthdate ? getAge(patient.birthdate) : " "}
                    </td>
                    <td
                      className={
                        patient.gender && patient.gender === maleGender
                          ? classes.tdStyleGenderMale
                          : classes.tdStyleGenderFemale
                      }
                    >
                      {patient.gender}
                    </td>
                    <td className={classes.tdStyle}>
                      <NumberFormat
                        value={patient.phoneNumber}
                        displayType={"text"}
                        format="###-####-####"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <div className={classes.containerBorder}>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resources.DialogContent.containerBorder.status}
              </div>
              <div className={classes.contentItem}>
                <Radio
                  className={
                    Number(this.props.treatmentInfor.completeSelectOption) !==
                    TREATMENT_COMPLETED_SELECTED_OPTION.VISITED
                      ? classes.radioStyle
                      : classes.radioStyleCheck
                  }
                  onClick={this.handleSelect}
                  name="status"
                  value={1}
                  checked={
                    this.props.treatmentInfor.completeSelectOption &&
                    Number(this.props.treatmentInfor.completeSelectOption) ===
                      TREATMENT_COMPLETED_SELECTED_OPTION.VISITED
                  }
                />
                <SpanCustom>
                  {Resources.DialogContent.containerBorder.radioComplete}
                </SpanCustom>
                <Radio
                  className={
                    Number(this.props.treatmentInfor.completeSelectOption) !==
                    TREATMENT_COMPLETED_SELECTED_OPTION.NOT_VISITED
                      ? classes.radioStyle
                      : classes.radioStyleCheck
                  }
                  onClick={this.handleSelect}
                  name="status"
                  value={-1}
                  checked={
                    this.props.treatmentInfor.completeSelectOption &&
                    Number(this.props.treatmentInfor.completeSelectOption) ===
                      TREATMENT_COMPLETED_SELECTED_OPTION.NOT_VISITED
                  }
                />
                <SpanCustom>
                  {Resources.DialogContent.containerBorder.radioNoVisit}
                </SpanCustom>
              </div>
            </div>
            <div className={classes.container}>
              <div className={classes.titleItem}>
                {Resources.DialogContent.containerBorder.treatmentDay}
              </div>
              <div className={classes.contentItem}>
                {appointment.from
                  ? getDayTextJaFormatShort(appointment.from)
                  : ""}
              </div>
            </div>
            {!isBillingEnterprise && (
              <React.Fragment>
                {patient && patient.organizationId && (
                  <MedicalFeeInput
                    title={Resources.DialogContent.containerBorder.medicalFee}
                    isValidateError={
                      !(
                        this.state.checkMedicalFeeZero &&
                        this.state.checkMedicalFeeLength
                      )
                    }
                    errMessage={
                      !this.state.checkMedicalFeeLength
                        ? Resources.DialogContent.containerBorder
                            .medicalFeeLengthErrorMessage
                        : Resources.DialogContent.containerBorder
                            .medicalFeeZeroErrorMessage
                    }
                    handleChangeMedicalFee={(e) =>
                      this.props.handleChangeMedicalFee(
                        e,
                        this.handleEnableSubmitButton
                      )
                    }
                    handleBlurMedicalFee={this.props.handleBlurMedicalFee}
                    medicalFeeValue={this.props.treatmentInfor.medicalFee}
                    inputFeePlaceholder={0}
                    disabled={disabled}
                    hasErrorClass={
                      !(
                        this.state.checkMedicalFeeZero &&
                        this.state.checkMedicalFeeLength
                      )
                        ? classes.hasError
                        : ""
                    }
                    medicalFeeUnit={
                      Resources.DialogContent.containerBorder.medicalFeeUnit
                    }
                    medicalFeeNote={
                      Resources.DialogContent.containerBorder.medicalFeeNote
                    }
                  />
                )}
                {this.props.treatmentInfor.requireDrugName && (
                  <DrugInput
                    title={Resources.DialogContent.containerBorder.drugName}
                    isValidateError={
                      !(
                        this.state.checkDrugNameLength &&
                        this.state.checkDrugNameEmpty
                      )
                    }
                    errMessage={
                      !this.state.checkDrugNameLength
                        ? Resources.DialogContent.containerBorder
                            .drugNameMaxLengthErrorMessage
                        : Resources.DialogContent.containerBorder
                            .drugNameEmptyErrorMessage
                    }
                    drugInputValue={this.props.treatmentInfor.drugName}
                    disabled={disabled}
                    drugInputPlaceholder={
                      Resources.DialogContent.containerBorder
                        .drugNamePlaceholder
                    }
                    handleChangeDrugInput={(e) =>
                      this.props.handleChangeDrugName(
                        e,
                        this.handleEnableSubmitButton
                      )
                    }
                    hasErrorClass={
                      !(
                        this.state.checkDrugNameLength &&
                        this.state.checkDrugNameEmpty
                      )
                        ? classes.hasError
                        : ""
                    }
                  />
                )}
                {this.props.treatmentInfor.requireDrugQuantity && (
                  <DrugInput
                    title={Resources.DialogContent.containerBorder.drugQuantity}
                    isValidateError={
                      !(
                        this.state.checkDrugQuantityLength &&
                        this.state.checkDrugQuantityEmpty
                      )
                    }
                    errMessage={
                      !this.state.checkDrugQuantityLength
                        ? Resources.DialogContent.containerBorder
                            .drugQuantityMaxLengthErrorMessage
                        : Resources.DialogContent.containerBorder
                            .drugQuantityEmptyErrorMessage
                    }
                    drugInputValue={this.props.treatmentInfor.drugQuantity}
                    drugInputPlaceholder={
                      Resources.DialogContent.containerBorder
                        .drugQuantityPlaceholder
                    }
                    handleChangeDrugInput={(e) =>
                      this.props.handleChangeDrugQuantity(
                        e,
                        this.handleEnableSubmitButton
                      )
                    }
                    disabled={disabled}
                    hasErrorClass={
                      !(
                        this.state.checkDrugQuantityLength &&
                        this.state.checkDrugQuantityEmpty
                      )
                        ? classes.hasError
                        : ""
                    }
                  />
                )}
                {patient && patient.organizationId && (
                  <MemoTextarea
                    title={Resources.DialogContent.containerBorder.memo}
                    checkMemoInput={this.state.checkMemoInput}
                    memoErrMessage={
                      Resources.DialogContent.containerBorder.memoErrMessage
                    }
                    memoValue={this.props.treatmentInfor.memo}
                    placeholder={
                      Resources.DialogContent.containerBorder.memoPlaceholder
                    }
                    handleChangeMemo={(e) =>
                      this.props.handleChangeMemo(
                        e,
                        this.handleEnableSubmitButton
                      )
                    }
                    disabled={disabled}
                    hasErrorClass={
                      !this.state.checkMemoInput ? classes.hasError : ""
                    }
                  />
                )}
              </React.Fragment>
            )}
            {isBillingEnterprise && (
              <React.Fragment>
                <BillingCategorySelect
                  title={
                    Resources.DialogContent.containerBorder.billingCategory
                  }
                  billingCategoryValue={
                    this.props.treatmentInfor.billingCategory
                  }
                  handleChangeBillingCategory={(e) =>
                    this.props.handleChangeBillingCategory(
                      e,
                      this.handleEnableSubmitButton
                    )
                  }
                  disabled={disabled}
                  isShowNote={
                    this.props.treatmentInfor.isShowBillingCategoryNote
                  }
                />
                {this.props.treatmentInfor.isShowDrug && (
                  <DrugNameInput
                    title={Resources.DialogContent.containerBorder.drugNameEWP}
                    isValidateError={
                      !(
                        this.state.checkDrugNameLength &&
                        this.state.checkDrugNameEmpty
                      )
                    }
                    errMessage={
                      !this.state.checkDrugNameEmpty
                        ? Resources.DialogContent.containerBorder
                            .drugNameEmptyErrorMessageEWP
                        : Resources.DialogContent.containerBorder
                            .drugNameMaxLengthErrorMessageEWP
                    }
                    drugsNameValue={this.props.treatmentInfor.drugName}
                    inputMethod={
                      appointment.contractPlan.plan.prescriptionDrug.inputMethod
                    }
                    selectableDrugNames={
                      appointment.contractPlan.plan.prescriptionDrug
                        .selectableDrugNames
                    }
                    handleChangeDrugName={(e) =>
                      this.props.handleChangeDrugName(
                        e,
                        this.handleEnableSubmitButton
                      )
                    }
                    disabled={
                      disabled ||
                      this.props.treatmentInfor.isDrugNameInputDisabled
                    }
                  />
                )}
                {this.props.treatmentInfor.isShowDrug && (
                  <DrugQuantitySelect
                    title={Resources.DialogContent.containerBorder.drugQuantity}
                    drugsQuantityValue={this.props.treatmentInfor.drugQuantity}
                    handleChangeDrugQuantity={(e) =>
                      this.props.handleChangeDrugQuantity(
                        e,
                        this.handleEnableSubmitButton
                      )
                    }
                    disabled={
                      disabled ||
                      this.props.treatmentInfor.isDrugQuantitySelectDisabled
                    }
                    isValidateError={!this.state.checkDrugQuantityValid}
                    errMessage={
                      Resources.DialogContent.containerBorder
                        .drugQuantityValidErrorMessageEWP
                    }
                    note={
                      Resources.DialogContent.containerBorder.drugQuantityNote
                    }
                    drugQuantityOptionList={
                      this.props.treatmentInfor.drugQuantityOptionList
                    }
                  />
                )}
                <MemoTextarea
                  title={Resources.DialogContent.containerBorder.memo}
                  checkMemoInput={this.state.checkMemoInput}
                  memoErrMessage={
                    Resources.DialogContent.containerBorder.memoErrMessage
                  }
                  memoValue={this.props.treatmentInfor.memo}
                  placeholder={
                    Resources.DialogContent.containerBorder
                      .enterpriseMemoPlaceholder
                  }
                  handleChangeMemo={(e) =>
                    this.props.handleChangeMemo(
                      e,
                      this.handleEnableSubmitButton
                    )
                  }
                  disabled={disabled}
                  hasErrorClass={
                    !this.state.checkMemoInput ? classes.hasError : ""
                  }
                />
              </React.Fragment>
            )}
          </div>
        </DialogContent>
        <Divider />
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.buttonCancel}
            onClick={this.handleClickCancelButtonDialogInput}
          >
            {Resources.DialogActions.cancelButton}
          </Button>
          {this.props.treatmentInfor.completeSelectOption &&
          this.state.enableSubmitButton ? (
            <Button
              className={classes.buttonOk}
              onClick={this.validateAndSubmitForm(appointment)}
            >
              {Resources.DialogActions.confirmButton}
            </Button>
          ) : (
            <Button className={classes.buttonDisable} onClick={() => {}}>
              {Resources.DialogActions.confirmButton}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ChangeStatusDialogInput);
