import gql from "graphql-tag";

export const deleteRepeatedlyAppointmentSlots = gql`
  mutation deleteRepeatedlyAppointmentSlots(
    $slotRepetitionId: String!
    $deleteFromDate: String!
  ) {
    deleteRepeatedlyAppointmentSlots(
      slotRepetitionId: $slotRepetitionId
      deleteFromDate: $deleteFromDate
    )
  }
`;