import gql from "graphql-tag";

export const DeleteAppointmentSlot = gql`
  mutation DeleteAppointmentSlot(
    $from: ID!
    $menuMethod: String!
    $medicalDoctorId: String
  ) {
    deleteAppointmentSlot(
      from: $from
      menuMethod: $menuMethod
      doctorId: $medicalDoctorId
    ) {
      from
      to
      hospitalId
    }
  }
`;