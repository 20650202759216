import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../../Resources/japaneseList";

const MonthColumnElement = styled.button`
  width: 46px;
  height: 46px;
  line-height: 46px;
  padding: 0;
  text-align: center;
  font-size: 14px;
  border: none;
  outline: none;
  background-color: ${(p) =>
    p.isSelected ? `${p.theme.color.primary} !important` : "transparent"};
  border-radius: ${(p) => (p.isSelected ? "23px" : "0")};
  color: ${(p) =>
    p.isSelected
      ? `${p.theme.color.fontInvert} !important`
      : p.isNow
      ? `${p.theme.color.primary} !important`
      : p.theme.font.color};
  &:hover {
    background-color: #eeeeee;
    border-radius: 23px;
    color: ${(p) => p.theme.font.color};
  }
  &:disabled {
    color: ${(p) => p.theme.button.bg.disable};
    background-color: transparent;
  }
`;

const MonthColumn = (props) => {
  const { month, isSelected, isNow, disabled, handleChangeMonth } = props;

  const onClick = () => {
    handleChangeMonth(month);
  };

  return (
    <MonthColumnElement
      onClick={onClick}
      isSelected={isSelected}
      isNow={isNow}
      disabled={disabled}
    >
      {`${month + 1}${japaneseList.general.datePostfix.month}`}
    </MonthColumnElement>
  );
};

export default MonthColumn;
