// Graphql
import { Auth } from "aws-amplify";
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  Observable,
} from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { reload } from "./reload";

const GraphQlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
const RELOAD_INTERVAL_TIME = 6000;
const httpLink = createHttpLink({
  uri: GraphQlEndpoint,
});

const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation, forward }) => {
    try {
      if (graphQLErrors) {
        for (const error of graphQLErrors) {
          if (error.errorType === "E22") {
            // Doctor is deleted or disabled
            return new Observable(async (observer) => {
              await Auth.signOut();
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };
              return forward(operation).subscribe(subscriber);
            });
          }
        }
      }
      if (networkError.statusCode === 403 || networkError.statusCode === 503) {
        if (window.location.pathname.startsWith("/not-access")) return;

        let reloadTimestamp = localStorage.getItem("reloadTimestamp");
        if (
          reloadTimestamp &&
          Date.now() - reloadTimestamp <= RELOAD_INTERVAL_TIME
        )
          return;

        localStorage.setItem("reloadTimestamp", Date.now());
        reload();
      }
    } catch (e) {
      console.log(e);
    }
  }
);

const authLink = setContext(async (_, { headers }) => {
  const authHeaders = await Auth.currentUserPoolUser()
    .then((user) => {
      return {
        Authorization: user.signInUserSession.idToken.jwtToken,
      };
    })
    .catch((error) => {
      console.error(
        "graphQLClient: currentUserPoolUser error:",
        JSON.stringify(error)
      );
      return {};
    });
  return {
    headers: {
      ...headers,
      ...authHeaders,
      "Client-Version": process.env.REACT_APP_CLIENT_VERSION,
    },
  };
});

const removeReloadCountLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    localStorage.removeItem("forceReloadCount");
    return response;
  });
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, removeReloadCountLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export default client;
