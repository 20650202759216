import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import LinkA from "../../atoms/links/LinkA";
import { DELIVERY_OPTION } from "../../../Utils/const";
import { japaneseList } from "../../../Resources/japaneseList";

const TblCellDeliveryProcess = styled.td`
  text-align: center;
  padding: 5px 10px;
  border-left: 1px solid #cccccc;
  white-space: pre-wrap;
`;

const LinkAStyle = styled(LinkA)`
  width: 70px;
  height: 14px;
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0px;
  color: #2563c0;
  opacity: 1;
`;

const ButtonCustom = withStyles((theme) => ({
  root: {
    backgroundColor: theme.button.ok.backgroundColor,
    borderRadius: "3px",
    border: 0,
    color: "white",
    height: "24px",
    padding: "5px",
    fontWeight: "bold",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    boxShadow: "0px 1px 0px " + theme.button.ok.shadowColor,
  },
}))(Button);

const ButtonCustomDeliveryYamoto = withStyles((theme) => ({
  root: {
    minWidth: "66px",
  }
}))(ButtonCustom);

const ButtonCustomDeliveryPost = withStyles((theme) => ({
  root: {
    minWidth: "70px",
  }
}))(ButtonCustom);

const ButtonCustomDeliveryOther = withStyles((theme) => ({
  root: {
    minWidth: "66px",
  }
}))(ButtonCustom);

const HospitalPaymentRowDeliveryProcess = (props) => {
  const { 
    appo, 
    deliverySelectedOption, 
    propsDeliverySelectedOption,
    handleOpenDeliveryResultDialog,
    handleOpenDeliveryInputDialog
  } = props;

  const Resource = japaneseList.Components.Payment.HospitalPaymentList;

  const existDeliveryOption =
    appo &&
    appo.payment &&
    Number.isInteger(appo.payment.deliverySelectedOption) &&
    appo.payment.deliverySelectedOption;
  
  const deliveryLinkResult = (deliverySelectedOption) => {
    switch (deliverySelectedOption) {
      case DELIVERY_OPTION.yamato:
        return (
          <LinkAStyle onClick={() => { handleOpenDeliveryResultDialog(appo); }}>
            {Resource.render.deliveryLinkResult.yamato}
          </LinkAStyle>
        );
      case DELIVERY_OPTION.csvPost:
        return (
          <LinkAStyle onClick={() => { handleOpenDeliveryResultDialog(appo); }}>
            {Resource.render.deliveryLinkResult.csv}
          </LinkAStyle>
        );
      // other
      default:
        return "-";
    }
  };

  const deliveryProcessingButton = (deliveryMethod) => {
    switch (parseInt(deliveryMethod, 10)) {
      case DELIVERY_OPTION.yamato:
        return (
          <ButtonCustomDeliveryYamoto
            variant="raised"
            size="small"
            color="primary"
            onClick={() => { handleOpenDeliveryInputDialog(DELIVERY_OPTION.yamato); }}
          >
            {Resource.render.deliveryProcButton.j001}
          </ButtonCustomDeliveryYamoto>
        );
      case DELIVERY_OPTION.csvPost:
        return (
          <ButtonCustomDeliveryPost
            variant="raised"
            size="small"
            color="primary"
            onClick={() => { handleOpenDeliveryInputDialog(DELIVERY_OPTION.csvPost); }}
          >
            {Resource.render.deliveryProcButton.j002}
          </ButtonCustomDeliveryPost>
        );
      case DELIVERY_OPTION.other:
        return (
          <ButtonCustomDeliveryOther
            variant="raised"
            size="small"
            color="primary"
            onClick={() => { handleOpenDeliveryInputDialog(DELIVERY_OPTION.other); }}
          >
            {Resource.render.deliveryProcButton.j003}
          </ButtonCustomDeliveryOther>
        );
      default:
        return "-";
    }
  }

  return (
    <TblCellDeliveryProcess>
      {
        existDeliveryOption
        ? deliveryLinkResult(deliverySelectedOption)
        : deliveryProcessingButton(propsDeliverySelectedOption)
      }
    </TblCellDeliveryProcess>
  );
}

export default HospitalPaymentRowDeliveryProcess;
