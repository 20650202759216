import styled from "styled-components";

export default styled.input.attrs({type: 'text'})`
  padding: 5px 10px;
  font-size: ${p => p.theme.font.size.default};
  color: ${p => p.theme.color.font};
  border: 1px solid ${p => p.theme.color.fontVariant};
  -webkit-appearance: none;
  ::placeholder{
    color: ${p => p.theme.font.color.placeholder};
  }
  &:focus {
    border: 1px solid ${p => p.theme.color.primary};
    outline:0;
  }
  ${p => p.width && p.theme.input.width[p.width]? "width:"+p.theme.input.width[p.width]+";" : ""}
`;