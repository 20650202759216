import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfWeek,
  endOfMonth,
  isAfter,
  setYear,
  setMonth,
  setDate,
  setHours,
  setMinutes,
  format,
  parseISO,
} from "date-fns";
import jaLocale from "date-fns/locale/ja";
import { japaneseList } from "../Resources/japaneseList";

const [MO, TU, WE, TH, FI, SA, SU] = ["0", "1", "2", "3", "4", "5", "6"];

// year month date change
function setYearMonthDate(date, yearMonthDate) {
  const year = yearMonthDate.getFullYear(),
    month = yearMonthDate.getMonth(),
    day = yearMonthDate.getDate();
  date = setYear(date, year);
  date = setMonth(date, month);
  date = setDate(date, day);
  return date;
}

// time : string 00:00
function setHoursAndMinutes(date, hour, minutes) {
  date = setHours(date, hour);
  date = setMinutes(date, minutes);
  return date;
}
// time : string 00:00
function setHoursAndMinutesAndDays(date, hour, minutes) {
  date = setHours(date, hour);
  date = setMinutes(date, minutes);
  // 00:00 → bofore day
  if (hour === 0 && minutes === 0) date = addDays(date, 1);
  return date;
}

const showCustomWeek = (byDay) => {
  const langList = japaneseList.Utils.DateUtil.showCustomWeek;
  if (byDay.length === 0) return "";

  if (byDay.length === 7) return langList.j001;

  if (
    byDay.length === 5 &&
    byDay.indexOf(SA) === -1 &&
    byDay.indexOf(SU) === -1
  ) {
    return langList.j002;
  }

  const dayList = [];
  if (byDay.indexOf(MO) !== -1) dayList.push(langList.j003);
  if (byDay.indexOf(TU) !== -1) dayList.push(langList.j004);
  if (byDay.indexOf(WE) !== -1) dayList.push(langList.j005);
  if (byDay.indexOf(TH) !== -1) dayList.push(langList.j006);
  if (byDay.indexOf(FI) !== -1) dayList.push(langList.j007);
  if (byDay.indexOf(SA) !== -1) dayList.push(langList.j008);
  if (byDay.indexOf(SU) !== -1) dayList.push(langList.j009);
  return `${langList.j010}${dayList.join(langList.j012)}${langList.j011}`;
};

const oneYearLimit = ({ date, viewType }) => {
  const endDate = endOfMonth(addYears(new Date(), 1));
  if (viewType === "day") {
    return isAfter(endDate, endOfDay(addDays(date, -1)));
  }
  if (viewType === "week") {
    return isAfter(endDate, endOfWeek(addMonths(date, -1)));
  }
  if (viewType === "month") {
    return isAfter(endDate, endOfMonth(addMonths(date, -1)));
  }
  return true;
};

const endMonthOfNextYear = () => endOfMonth(addYears(new Date(), 1));

const byDayFromNumberToString = (byDay) => {
  if (!byDay) return byDay;
  return byDay.map((day) => {
    if (day === MO) return "MO";
    if (day === TU) return "TU";
    if (day === WE) return "WE";
    if (day === TH) return "TH";
    if (day === FI) return "FI";
    if (day === SA) return "SA";
    if (day === SU) return "SU";
    throw new Error("unexpected day");
  });
};
const byDayFromStringToNumber = (byDay) => {
  if (!byDay || byDay.length === 0) return byDay;
  return byDay.map((day) => {
    if (day === "MO") return MO;
    if (day === "TU") return TU;
    if (day === "WE") return WE;
    if (day === "TH") return TH;
    if (day === "FI") return FI;
    if (day === "SA") return SA;
    if (day === "SU") return SU;
    throw new Error("unexpected day");
  });
};
const dayList = {
  MONDAY: "0",
  TUESDAY: "1",
  WEDNESDAY: "2",
  THURSDAY: "3",
  FRIDAY: "4",
  SATURDAY: "5",
  SUNDAY: "6",
};

const formatDate = (date, formatString) => {
  const parsedDate = typeof date === "string" ? parseISO(date) : date;
  return format(parsedDate, formatString, {
    locale: jaLocale,
  });
};

/**
 * 「<年>/<月>/<日>(<曜日>)」の形式にフォーマットする
 * @param {*} data フォーマット対象の日付
 * @returns
 */
const formatDay = (day) => {
  const formatString = japaneseList.Utils.DateUtil.dateFormat.day;
  return formatDate(day, formatString);
};
/**
 * 「<時刻>」の形式にフォーマットする
 * @param {*} data フォーマット対象の日時
 * @returns
 */
const formatTime = (data) => {
  const formatString = japaneseList.Utils.DateUtil.dateFormat.time;
  return formatDate(data, formatString);
};
/**
 * 「<年>/<月>/<日>(<曜日>) <時刻>」の形式にフォーマットする
 * @param {*} data フォーマット対象の日時
 * @returns
 */
const formatDateTime = (data) => {
  return `${formatDay(data)} ${formatTime(data)}`;
};

/**
 * 「<年>/<月>/<日>(<曜日>) <時刻>~<時刻>」の形式にフォーマットする
 * @param {*} start フォーマットしたい開始日時
 * @param {*} end 終了日時 startと同じ日付であることが前提
 * @returns
 */
const formatPeriod = (start, end) => {
  return `${formatDay(start)} ${formatTime(start)}~${formatTime(end)}`;
};

export {
  setYearMonthDate,
  setHoursAndMinutes,
  setHoursAndMinutesAndDays,
  showCustomWeek,
  oneYearLimit,
  endMonthOfNextYear,
  byDayFromNumberToString,
  byDayFromStringToNumber,
  formatDate,
  formatDay,
  formatTime,
  formatDateTime,
  formatPeriod,
  dayList,
};
