// --------------------------------------------------
//
// Util Dialog class
//
// --------------------------------------------------

// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

// personal modules
// --------------------------------------------------
import { japaneseList } from "../../../Resources/japaneseList";

// style
// --------------------------------------------------
const styles = (theme) => ({
  styleDialogContent: {
    color: "#393939",
    fontSize: "14px",
    letterSpacing: "0px",
    opacity: "1",
  },
  dialogActions: {
    padding: "15px 25px 15px 0px",
    margin: "0px",
  },
  buttonOk: {
    color: "#FFFFFF",
    marginRight: "0px",
    width: "120px",
    height: "40px",
    background: "#808080 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 0px #505050",
    borderRadius: "6px",
    opacity: "1",
    margin: "0px",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
    fontWeight: "bold",
  },
  divContent: {
    padding: "1px",
  },
});

const Resource = japaneseList.molecules.dialogs.DialogError;

// class define
// --------------------------------------------------
class DialogError extends Component {
  // default
  // --------------------------------------------------
  static defaultProps = {
    onClose: function () {},
    open: false,
    title: "",
    buttons: {
      ok: {
        display: false,
        text: Resource.button.j001,
        handleClick: function () {},
      },
    },
    maxWidth: false,
  };

  _handleClose = () => {};

  render() {
    const { classes, open, children } = this.props;
    return (
      <Dialog open={open}>
        <DialogContent className={classes.styleDialogContent}>
          <div className={classes.divContent}>{children}</div>
        </DialogContent>
        <Divider />
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.buttonOk}
            onClick={this.props.handleCloseDialogError}
          >
            {this.props.buttons.ok.text}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogError);
