import gql from "graphql-tag";

export const GetNewsList = gql`
  query GetNewsList($input: InputNewsList!) {
    newsList(input: $input) {
      id
      title
      content
      category
      isImportant
      activateDatetime
      expireDatetime
      targetHospitalTypes
    }
  }
`;