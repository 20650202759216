import React from 'react';
import { Documents } from '../../organisms/documents/Documents';
import BasicTemplate from '../../templates/BasicTemplate';
import { useHospital } from '../../../hooks/useHospital';
import { useDocuments } from '../../../hooks/useDocuments';

export const DocumentsPage = () => {
  const hospital = useHospital();
  const documents = useDocuments(hospital);

  return (
    <BasicTemplate
      main={
        <Documents
          documents={documents}
        />
      }
      title={'資料ダウンロード'}
      isLoading={!documents}
    />
  );
};