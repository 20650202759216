import React, { useState } from "react";
import styled from "styled-components";
import { NewsItem } from "./NewsItem";
import DialogNewsDetail from "../../molecules/dialogs/DialogNewsDetail";

const NewsListContainer = styled.div`
  width: 100%;
  max-width: 900px;
  min-height: 576px;
  height: calc(100vh - 231px);
  background-color: #ffff;
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 6px;
  opacity: 1;
  border: 1px solid #dddddd;
  &::-webkit-scrollbar {
    scrollbar-arrow-color: #dce0e0;
    background: #ffffff;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #999999;
    height: 50px;
  }
`;

const NoNewsText = styled.div`
  margin-top: 3%;
  margin-left: 2%;
  color: #999999;
`;

const NewsListUl = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const NewsListLi = styled.li`
  position: relative;
  padding-top: 18px;
  padding-bottom: 17px;
  margin-left: 30px;
  margin-right: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  border-bottom: 1px solid #dddddd;
  ::before {
    content: "";
    position: absolute;
    margin-right: 10px;
    right: 0;
    top: 50%;
    width: 7px;
    height: 7px;
    border-style: solid;
    border-width: 2px 0 0 2px;
    border-color: black;
    transform: translateY(-100%) rotate(135deg);
  }
`;

const NoNews = () => {
  return <NoNewsText>お知らせはありません。</NoNewsText>;
};

export const NewsList = (props) => {
  const newsList = props.newsList;
  const [showDetailDialog, setShowDetailDialog] = useState(false);
  const [newsDetail, setNewsDetail] = useState(false);

  const openNewsDetailDialog = (news) => {
    setShowDetailDialog(true);
    setNewsDetail(news);
  };
  const closeNewsDetailDialog = () => {
    setShowDetailDialog(false);
  };

  return (
    <React.Fragment>
      <NewsListContainer>
        {!newsList ? (
          ""
        ) : newsList.length > 0 ? (
          <NewsListUl>
            {newsList?.map((news) => (
              <NewsListLi key={news.id}>
                <NewsItem
                  openNewsDetailDialog={openNewsDetailDialog}
                  news={news}
                  key={news.id}
                />
              </NewsListLi>
            ))}
          </NewsListUl>
        ) : (
          <NoNews />
        )}
      </NewsListContainer>

      {showDetailDialog && (
        <DialogNewsDetail
          news={newsDetail}
          isOpen={showDetailDialog}
          handleClose={closeNewsDetailDialog}
        />
      )}
    </React.Fragment>
  );
};
