import React from "react";
import styled from "styled-components";
import ThumbnailItem from "../../atoms/thumbnail/ThumbnailItem";
import ThumbnailItemText from "../../atoms/thumbnail/ThumbnailItemText";
import { client, AttachmentPresignedUrlForGetObject } from "../../../graphql";

const ThumbnailListContainer = styled.div`
  padding-bottom: 25px;
`;

const ThumbnailListStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 150px);
  div:first-child {
    border-left: 1px solid ${(p) => p.theme.thumbnailList.borderColor};
  }
`;

const ThumbnailItemContainer = styled.div`
  width: 150px;
  height: 150px;
  text-align: center;
  padding: 15px 0px;
  border-right: 1px solid ${(p) => p.theme.thumbnailList.borderColor};
  border-top: 1px solid ${(p) => p.theme.thumbnailList.borderColor};
  border-bottom: 1px solid ${(p) => p.theme.thumbnailList.borderColor};
  background-color: ${(p) => p.theme.thumbnailList.backgroundColor};
`;

const FontSpanTitle = styled.div`
  font-size: 14px;
  padding-bottom: 10px;
  font-weight: bold;
`;

const FileDateTime = styled.div`
  padding-top: 10px;
  color: ${(p) => p.theme.thumbnailList.dateTimeColor};
  font-size: 10px;
`;

const ContentNoFile = styled.div`
  padding-top: 25px;
  padding-bottom: 15px;
  font-size: 14px;
`;

const ThumbnailList = React.memo((props) => {
  const {
    title,
    thumbnailList,
    expiredDay,
    contentNoFile,
    handleNotExistS3File,
  } = props;

  const getOriginPatientFile = (filePath, fileName, newWindow) => {
    client
      .query({
        query: AttachmentPresignedUrlForGetObject,
        variables: {
          filePath,
          fileName,
        },
      })
      .then((res) => {
        if (!res.errors) {
          newWindow.location = res.data.attachmentPresignedUrlForGetObject.url;
        } else {
          handleNotExistS3File();
          newWindow.close();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <ThumbnailListContainer>
      <FontSpanTitle>{title}</FontSpanTitle>
      {thumbnailList && thumbnailList.length === 0 ? (
        <ContentNoFile>{contentNoFile}</ContentNoFile>
      ) : (
        <React.Fragment>
          <ThumbnailListStyle>
            {thumbnailList
              .sort((a, b) => (a.uploadedTime > b.uploadedTime ? 1 : -1))
              .map((file) => {
                return (
                  <ThumbnailItemContainer key={file.fileId}>
                    <ThumbnailItem
                      onClick={() => {
                        getOriginPatientFile(
                          file.filePath,
                          file.fileName,
                          window.open()
                        );
                      }}
                      src={
                        file.thumbnailFile
                          ? file.thumbnailFile
                          : file.originalFile
                      }
                      alt={
                        file.fileName ? file.fileName : file.originalFile.name
                      }
                    />
                    <ThumbnailItemText
                      onClick={() => {
                        getOriginPatientFile(
                          file.filePath,
                          file.fileName,
                          window.open()
                        );
                      }}
                      file={file}
                    />
                  </ThumbnailItemContainer>
                );
              })}
          </ThumbnailListStyle>
          <FileDateTime>{expiredDay}</FileDateTime>
        </React.Fragment>
      )}
    </ThumbnailListContainer>
  );
});

export default ThumbnailList;
