import React from "react";
import styled from "styled-components";
import FontSpan from "../../atoms/fonts/FontSpan";
import CurrencyText from "../../organisms/payment/CurrencyText";
import { japaneseList } from "../../../Resources/japaneseList";
import { DELIVERY_OPTION, PAYMENT_METHOD } from "../../../Utils/const";

const TblCellDeliveryFee = styled.td`
  text-align: center;
  padding: 5px 10px;
  border-left: 1px solid #cccccc;
  white-space: nowrap;
`;

const FontSpanCustom = styled(FontSpan)`
  color: #cccccc;
`;

const HospitalPaymentRowDeliveryFee = (props) => {
  const { appo, deliverySelectedOption, deliveryDisplayFee, isShowFee } = props;

  const feeText = () => {
    // TODO: 健康経営のメニューかどうかの判定方法、判定箇所をリファクタリングする
    const isBillingEnterprise =
      appo.menu &&
      appo.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;
    const propsDeliveryOption = parseInt(deliverySelectedOption, 10);
    if (!deliveryDisplayFee) {
      return (
        <FontSpanCustom style={{ float: "right" }}>
          {japaneseList.Components.Payment.CustomNumber.NumberFormat.j001}
        </FontSpanCustom>
      );
    }
    if (isBillingEnterprise && propsDeliveryOption !== DELIVERY_OPTION.other) {
      return japaneseList.Components.Payment.CustomNumber.NumberFormat.j002;
    } else {
      return (
        <CurrencyText
          value={deliveryDisplayFee.deliveryFee}
          style={{ float: "right" }}
        />
      );
    }
  };

  return (
    <TblCellDeliveryFee>
      {!isShowFee
        ? japaneseList.Components.Payment.CustomNumber.NumberFormat.j002
        : feeText()}
    </TblCellDeliveryFee>
  );
};

export default HospitalPaymentRowDeliveryFee;
