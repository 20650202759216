import gql from "graphql-tag";

export const GetMultipleConsentStatus = gql`
  {
    multipleConsentStatus {
      patientId
      consentStatus
      consentModificationAttemptedAt
    }
  }
`;
