import React, { useContext } from "react";
import BasicTemplate from "../../templates/BasicTemplate";
import Titles from "../../../Resources/Titles";
import SiteContext from '../../../contexts/SiteContext';
import Contact from '../../organisms/contact/Contact';

export const ContactPage = () => {
  const siteContext = useContext(SiteContext);
  const doctor = siteContext.doctor;
  const hospital = siteContext.hospital;
  
  return (
    <BasicTemplate
      main={
        <Contact
          doctor={doctor}
          hospital={hospital}
        />
      }
      title={Titles.contact}
    />
  );
};