// library
import React, { Component } from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import {
  format,
  differenceInMinutes,
  addMinutes,
  differenceInCalendarDays,
  parseISO,
} from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import jaLocale from "date-fns/locale/ja";

// material ui
import {
  Button,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import AccessTime from "@material-ui/icons/AccessTime";
import DateRange from "@material-ui/icons/DateRange";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import NativeSelect from "@material-ui/core/NativeSelect";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "../../../atoms/forms/Radio";

// util, resource
import { japaneseList } from "../../../../Resources/japaneseList";
import { endMonthOfNextYear, dayList } from "../../../../Utils/DateUtil";
import TimePicker from "./TimePicker";

// components
import LinkA from "../../../atoms/links/LinkA";
import DialogCancelConfirm from "../../../molecules/dialogs/DialogCancelConfirm";

const styles = (theme) => ({
  error: {
    color: "red",
    fontSize: 12,
    marginTop: "30",
  },
  dialogTitle: {
    width: "810px",
    height: "68px",
    margin: "0",
    padding: "24px 0 0 24px",
    borderBottom: "1px solid #cccccc",
  },
  dialogContent: {
    width: "810px",
    padding: "19px 25px 20px 24px",
  },
  input: {
    width: "194px",
    height: "34px",
    lineHeight: "34px",
    alignItems: "center",
    verticalAlign: "middle",
    padding: "10px 0px 10px 12px",
    fontSize: "14px",
    border: "1px solid #dddddd",
    background: `${theme.color.primaryVariant3} 0% 0% no-repeat padding-box`,
    borderRadius: "3px 0px 0px 3px",
    textAlign: "left",
    color: "#393939",
    "&:focus": {
      outline: "none",
    },
  },
  iconButton: {
    padding: 0,
    backgroundColor: theme.button.ok.backgroundColor,
    maxWidth: "34px",
    maxHeight: "33px",
    minWidth: "34px",
    minHeight: "33px",
    marginLeft: "-34px",
    marginTop: "-1px",
    borderRadius: "0 3px 3px 0",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
  },
  removeIconButton: {
    padding: 0,
    backgroundColor: theme.button.ok.backgroundColor,
    maxWidth: "34px",
    maxHeight: "33px",
    minWidth: "34px",
    minHeight: "33px",
    marginTop: "-1px",
    borderRadius: "3px 0 0 3px",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
  },
  addIconButton: {
    padding: 0,
    backgroundColor: theme.button.ok.backgroundColor,
    maxWidth: "34px",
    maxHeight: "33px",
    minWidth: "34px",
    minHeight: "33px",
    marginTop: "-1px",
    borderRadius: "0 3px 3px 0",
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
  },
  numberInput: {
    width: "57px",
    height: "34px",
    lineHeight: "34px",
    alignItems: "center",
    verticalAlign: "middle",
    padding: "10px 0",
    fontSize: "14px",
    border: "1px solid #dddddd",
    background: `${theme.color.primaryVariant3} 0% 0% no-repeat padding-box`,
    textAlign: "center",
    color: "#393939",
    "&:focus": {
      outline: "none",
    },
  },
  radio: {
    width: "18px",
    height: "18px",
    position: "relative",
  },
  radioRight: {
    width: "18px",
    height: "18px",
    position: "relative",
    marginLeft: "30px",
  },
  checkbox: {
    width: "17px",
    height: "17px",
    margin: "-3px 5px 0 10px",
    border: "1px solid #cccccc",
    borderRadius: "2px",
  },
  dialogActions: {
    width: "810px",
    height: "70px",
    margin: "0",
    padding: "14px 25px 16px 24px",
    borderTop: "1px solid #cccccc",
    "& button": {
      height: "40px",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonBack: {
    fontSize: "14px",
    position: "absolute",
    left: "24px",
    color: "#2563c0",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
  },
  buttonOk: {
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
  dialogActionsButtonOk: {
    width: "120px",
    margin: "0",
    padding: "13px 46px",
    borderRadius: "5px",
  },
  buttonCancel: {
    backgroundColor: "#808080",
    boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
  dialogActionsButtonCancel: {
    width: "120px",
    margin: "0 20px 0 0",
    padding: "13px 25px",
    borderRadius: "5px",
  },
  week: {
    marginTop: "-2px",
    fontSize: "14px",
    color: "#393939",
    fontFamily:
      "'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', HiraKakuProN-W3, 'Yu Gothic', sans-serif",
  },
});

const DialogTitleText = styled.div`
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
`;

const Container = styled.div`
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 88px auto;
  border-top: 1px solid #cccccc;
  height: 55px;
`;

const Title = styled.div`
  background-color: #eeeeee;
  padding-left: 10px;
  color: #808080;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`;

const Content = styled.div`
  padding-left: 20px;
  color: #393939;
  text-align: left;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-word;
`;

const CustomWeeklySpan = styled.span`
  margin-right: 20px;
  position: relative;
  top: -5px;
`;

const CustomSpan = styled.span`
  padding-right: 20px;
  margin-left: 8px;
  position: relative;
`;

const CustomDiv = styled.div`
  display: inline-block;
  position: relative;
  top: 6px;
  margin-left: 50px;
`;

const CustomNativeSelect = styled(NativeSelect)`
  width: 194px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  background: 0% 0% no-repeat padding-box
    ${(p) => p.theme.color.primaryVariant3};
  select {
    width: calc(100% - 10px);
    padding: 0 0 0 10px;
    height: 34px;
    font-size: 14px;
    &:focus {
      background: 0% 0% no-repeat padding-box
        ${(p) => p.theme.color.primaryVariant3};
    }
  }
  &:hover svg {
    background-color: ${(p) => p.theme.color.primaryVariant};
  }
`;

const ArrowDropDownIcon = withStyles((theme) => ({
  root: {
    backgroundColor: theme.button.ok.backgroundColor,
    width: "34px",
    height: "33px",
    color: "#ffffff",
    background: "#27cdcb 0% 0% no-repeat padding-box",
    boxShadow: `0px 1px 0px ${theme.color.shadow}`,
    borderRadius: "0px 3px 3px 0px",
    marginTop: "-5px",
    left: "159px",
  },
}))(ArrowDropDown);

const SlotResource = japaneseList.Components.Appointment.Stepper.Slot;
const OnlineMethod = "online";
const OfflineMethod = "offline";
const [NONE, DAILY, WEEKLY, MONTHLY, YEARLY, CUSTOM] = [1, 2, 3, 4, 5, 6];

const RepetitionParams = [
  { type: NONE, text: SlotResource.handleIsRepetition.j001, frequency: null },
  {
    type: DAILY,
    text: SlotResource.handleIsRepetition.j002,
    frequency: "daily",
  },
  {
    type: WEEKLY,
    text: SlotResource.handleIsRepetition.j003,
    frequency: "weekly",
  },
  {
    type: MONTHLY,
    text: SlotResource.handleIsRepetition.j004,
    frequency: "monthly",
  },
  {
    type: YEARLY,
    text: SlotResource.handleIsRepetition.j005,
    frequency: "yearly",
  },
  {
    type: CUSTOM,
    text: SlotResource.handleIsRepetition.j006,
    frequency: "weekly",
  },
];

const WeekCheckButtonParams = [
  { day: dayList.MONDAY, buttonText: SlotResource.render.weekCheckButton.j002 },
  {
    day: dayList.TUESDAY,
    buttonText: SlotResource.render.weekCheckButton.j003,
  },
  {
    day: dayList.WEDNESDAY,
    buttonText: SlotResource.render.weekCheckButton.j004,
  },
  {
    day: dayList.THURSDAY,
    buttonText: SlotResource.render.weekCheckButton.j005,
  },
  { day: dayList.FRIDAY, buttonText: SlotResource.render.weekCheckButton.j006 },
  {
    day: dayList.SATURDAY,
    buttonText: SlotResource.render.weekCheckButton.j007,
  },
  { day: dayList.SUNDAY, buttonText: SlotResource.render.weekCheckButton.j001 },
];

const MenuMethodNames = {
  online: SlotResource.menuMethodNames.j001,
  offline: SlotResource.menuMethodNames.j002,
};

class Slot extends Component {
  menuMethods = [];

  constructor(props) {
    super(props);
    this.state = {
      selectedDate: format(props.slot.start, "yyyy-MM-dd", {
        locale: jaLocale,
      }),
      start: format(props.slot.start, "HH:mm"),
      end: format(props.slot.end, "HH:mm"),
      time: null,
      num: Math.ceil(
        differenceInMinutes(props.slot.end, props.slot.start) / 15
      ),
      menuMethod: props.slot.menuMethod,
      capacity: props.slot.capacity,
      isSubmitting: false,
      errors: [],
      hospitalId: null,
      startTimePicker: false,
      endTimePicker: false,
      doctorId: null,
      repetition: props.slot.repetition,
      repetitionEndValue: props.slot.repetitionEndValue,
      repetitionEndDate: props.slot.repetitionEndDate,
      displayDialogCancelConfirm: false,
    };
  }

  initData = () => {
    const { hospital, slot } = this.props;

    hospital.menus.forEach((m) => {
      if (m.createAppointmentByPatient && m.createSlotByDoctor) {
        if (!m.assignToDoctor || m.doctorId === slot.selectDoctor.id) {
          if (this.menuMethods.indexOf(m.medicalMethod) === -1)
            this.menuMethods.push(m.medicalMethod);
        }
      }
    });

    if (
      this.menuMethods.length > 0 &&
      (!this.state.menuMethod ||
        this.menuMethods.indexOf(this.state.menuMethod) === -1)
    ) {
      this.setState({
        menuMethod: this.menuMethods[0],
      });
    }
  };

  async componentDidMount() {
    Auth.currentUserPoolUser().then((user) => {
      this.setState(
        {
          hospitalId:
            user.signInUserSession.idToken.payload["custom:hospital_id"],
          doctorId: user.username,
        },
        this.initData()
      );
    });
  }

  openPicker = () => {
    this.picker.open();
  };

  openEndPicker = () => {
    this.endPicker.open();
  };

  handleDateChange = (date) => {
    const inputDate = format(date, "yyyy-MM-dd", { locale: jaLocale });
    this.setState({ selectedDate: inputDate });
  };

  handleStartTimeChange = (start) => {
    this.calculateEndTime(start, this.state.menuMethod);
  };

  calculateEndTime = (start = this.state.start, menuMethod) => {
    const { selectedDate } = this.state;
    const startTime = parseISO(`${selectedDate}T${start}:00`);
    const { slotFrameLengthes } = this.props;
    const endTime =
      menuMethod === OnlineMethod
        ? addMinutes(startTime, slotFrameLengthes.onlineMinimumTimeframe)
        : addMinutes(startTime, slotFrameLengthes.offlineMinimumTimeframe);
    if (differenceInCalendarDays(startTime, endTime) !== 0) {
      this.setState({
        errors: [SlotResource.calculateEndTime.j001],
        end: format(endTime, "HH:mm"),
        start: start,
        startTimePicker: false,
      });
    } else {
      this.setState({
        errors: [],
        end: format(endTime, "HH:mm"),
        start: start,
        startTimePicker: false,
      });
    }
  };

  handleEndTimeChange = (end) => {
    const { menuMethod } = this.state;

    switch (menuMethod) {
      case OnlineMethod:
      case OfflineMethod:
        this.setState({
          endTimePicker: false,
          end,
        });
        break;

      default:
        break;
    }
  };

  openStartTimePicker = () => {
    if (!this.state.startTimePicker) {
      this.setState({
        startTimePicker: true,
      });
    }
  };

  openEndTimePicker = () => {
    if (!this.state.endTimePicker) {
      this.setState({
        endTimePicker: true,
      });
    }
  };

  closeStartTimePicker = () => {
    this.setState({
      startTimePicker: false,
    });
  };

  closeEndTimePicker = () => {
    this.setState({
      endTimePicker: false,
    });
  };

  handleConfirm = () => {
    this.props.handleSlotChange({
      selectDoctor: this.props.slot.selectDoctor,
      menuMethod: this.state.menuMethod,
      selectedDate: this.state.selectedDate,
      capacity: this.state.capacity,
      end: this.state.end,
      start: this.state.start,
      repetition: this.state.repetition,
      repetitionEndValue: this.state.repetitionEndValue,
      repetitionEndDate: this.state.repetitionEndDate,
    });
    this.props.step(3);
  };

  addPerson = () => {
    const { capacity: previousCapacity } = this.state;
    const capacity = previousCapacity + 1;

    this.setState({ capacity });
  };

  removePerson = () => {
    const { capacity: previousCapacity } = this.state;
    const capacity = Math.max(1, previousCapacity - 1);

    this.setState({ capacity });
  };

  handleMenuMethod = (e) => {
    if (e.target.value === OnlineMethod || e.target.value === OfflineMethod) {
      const capacity = e.target.value === OfflineMethod ? 1 : 0;

      this.setState({
        menuMethod: e.target.value,
        capacity,
        errors: [],
      });
    }
  };

  handleIsRepetition = (e) => {
    const selectedValue = Number(e.target.value);
    const repetitionParam = RepetitionParams.find(
      (r) => r.type === selectedValue
    );
    if (repetitionParam) {
      this.setState({
        repetition: {
          value: selectedValue,
          text: repetitionParam.text,
          frequency: repetitionParam.frequency,
          interval: 1,
          byDay: [],
          isCustom: selectedValue === CUSTOM,
        },
      });
    }
  };

  handleRepetitionEnd = (e) => {
    const repetitionEndValue = e.target.value;
    this.setState({ repetitionEndValue });
  };

  handleRepetitionEndDate = (e) => {
    const repetitionEndDate = format(e, "yyyy-MM-dd", { locale: jaLocale });
    this.setState({ repetitionEndDate });
  };

  handleCustomWeekCheck = (e) => {
    const checkValue = e.target.value;
    const newRepetition = Object.assign({}, this.state.repetition);

    if (newRepetition.byDay.indexOf(checkValue) > -1) {
      const byDayTemp = newRepetition.byDay.filter(
        (byday) => byday !== checkValue
      );
      newRepetition.byDay = byDayTemp;
    } else {
      newRepetition.byDay.push(checkValue);
    }

    this.setState({ repetition: newRepetition });
  };

  showSelectedDate = (selectedDate) => {
    if (!selectedDate) return;
    if (typeof selectedDate === "object") {
      return format(selectedDate, "yyyy/MM/dd (iii)", { locale: jaLocale });
    }
    const d = parseISO(`${selectedDate}T00:00:00Z`);
    return format(d, "yyyy/MM/dd (iii)", { locale: jaLocale });
  };

  openDialogCancelConfirm = () => {
    this.setState({ displayDialogCancelConfirm: true });
  };

  closeDialogCancelConfirm = () => {
    this.setState({ displayDialogCancelConfirm: false });
  };

  handleBack = () => {
    this.props.step(0);
    this.props.setSelectSlotWithBackupSelectSlot();
  };

  render() {
    const { classes } = this.props;
    const { selectedDate, start, end, capacity, repetition } = this.state;
    const errors = [...this.state.errors];

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
        <DialogTitle className={classes.dialogTitle}>
          <DialogTitleText>
            {SlotResource.DialogTitle.DialogTitleText.j001}
          </DialogTitleText>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Container>
            <Item>
              <Title>
                {
                  SlotResource.render.MuiPickersUtilsProvider.DialogContent
                    .Container.Item.Title.j002
                }
              </Title>
              <Content>
                <input
                  className={classes.input}
                  readOnly={true}
                  value={this.showSelectedDate(selectedDate)}
                  onClick={this.openPicker}
                />
                <Button
                  variant="raised"
                  color="primary"
                  className={classes.iconButton}
                  onClick={this.openPicker}
                >
                  <DateRange />
                </Button>
                <DatePicker
                  style={{ display: "none" }}
                  format="yyyy-MM-dd"
                  ref={(node) => {
                    this.picker = node;
                  }}
                  value={selectedDate}
                  maxDate={endMonthOfNextYear()}
                  onChange={this.handleDateChange}
                />
              </Content>
            </Item>
            <Item>
              <Title>
                {
                  SlotResource.render.MuiPickersUtilsProvider.DialogContent
                    .Container.Item.Title.j003
                }
              </Title>
              <Content>
                <input
                  className={classes.input}
                  readOnly={true}
                  value={start}
                  onClick={this.openStartTimePicker}
                />
                <Button
                  variant="raised"
                  color="primary"
                  className={classes.iconButton}
                  onClick={this.openStartTimePicker}
                >
                  <AccessTime />
                </Button>
                <TimePicker
                  time={this.state.start}
                  updateTime={this.handleStartTimeChange}
                  open={this.state.startTimePicker}
                  handleClose={this.closeStartTimePicker}
                />
              </Content>
            </Item>
            <Item>
              <Title>
                {
                  SlotResource.render.MuiPickersUtilsProvider.DialogContent
                    .Container.Item.Title.j004
                }
              </Title>
              <Content>
                <input
                  className={classes.input}
                  readOnly={true}
                  value={end}
                  onClick={this.openEndTimePicker}
                />
                <Button
                  variant="raised"
                  color="primary"
                  className={classes.iconButton}
                  onClick={this.openEndTimePicker}
                >
                  <AccessTime />
                </Button>
                <TimePicker
                  time={this.state.end}
                  updateTime={this.handleEndTimeChange}
                  open={this.state.endTimePicker}
                  handleClose={this.closeEndTimePicker}
                />
              </Content>
            </Item>
            <Item>
              <Title>
                {
                  SlotResource.render.MuiPickersUtilsProvider.DialogContent
                    .Container.Item.Title.j005
                }
              </Title>
              <Content>
                <CustomNativeSelect
                  disableUnderline
                  IconComponent={ArrowDropDownIcon}
                  value={this.state.menuMethod}
                  onChange={this.handleMenuMethod}
                >
                  {this.menuMethods.map((method, index) => (
                    <option value={method} key={index}>
                      {MenuMethodNames[method]}
                    </option>
                  ))}
                </CustomNativeSelect>
              </Content>
            </Item>
            {this.state.menuMethod === "offline" && (
              <Item>
                <Title>
                  {
                    SlotResource.render.MuiPickersUtilsProvider.DialogContent
                      .Container.Item.Title.j006
                  }
                </Title>
                <Content>
                  <Button
                    className={classes.removeIconButton}
                    aria-label="Remove"
                    variant="raised"
                    color="primary"
                    onClick={this.removePerson}
                  >
                    <Remove />
                  </Button>
                  <input className={classes.numberInput} value={capacity} />
                  <Button
                    className={classes.addIconButton}
                    aria-label="Add"
                    variant="raised"
                    color="primary"
                    onClick={this.addPerson}
                  >
                    <Add />
                  </Button>
                </Content>
              </Item>
            )}
            <Item>
              <Title>
                {
                  SlotResource.render.MuiPickersUtilsProvider.DialogContent
                    .Container.Item.Title.j007
                }
              </Title>
              <Content>
                <CustomNativeSelect
                  disableUnderline
                  IconComponent={ArrowDropDownIcon}
                  value={this.state.repetition.value}
                  onChange={this.handleIsRepetition}
                >
                  {RepetitionParams.map((repetitionParam, index) => (
                    <option value={repetitionParam.type} key={index}>
                      {repetitionParam.text}
                    </option>
                  ))}
                </CustomNativeSelect>
                {this.state.repetition && this.state.repetition.isCustom && (
                  <CustomDiv>
                    <CustomWeeklySpan>
                      {
                        SlotResource.render.MuiPickersUtilsProvider
                          .DialogContent.Container.Item.Title.j009
                      }
                    </CustomWeeklySpan>
                    <FormControl component="fieldset">
                      <FormGroup
                        aria-label="position"
                        row
                        name="weekCheckButton"
                        onChange={this.handleCustomWeekCheck}
                      >
                        {WeekCheckButtonParams.map((dayParam, index) => (
                          <FormControlLabel
                            value={dayParam.day}
                            control={
                              <Checkbox
                                className={classes.checkbox}
                                color="primary"
                                key={index}
                                value={dayParam.day}
                                checked={
                                  repetition.byDay.indexOf(dayParam.day) > -1
                                }
                              />
                            }
                            label={
                              <Typography
                                variant="caption"
                                className={classes.week}
                              >
                                {dayParam.buttonText}
                              </Typography>
                            }
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </CustomDiv>
                )}
              </Content>
            </Item>
            {this.state.repetition && this.state.repetition.frequency && (
              <Item>
                <Title>
                  {
                    SlotResource.render.MuiPickersUtilsProvider.DialogContent
                      .Container.Item.Title.j008
                  }
                </Title>
                <Content>
                  <Radio
                    className={classes.radio}
                    name="product"
                    value="0"
                    onChange={this.handleRepetitionEnd}
                    checked={this.state.repetitionEndValue === "0"}
                  />
                  <CustomSpan>
                    {SlotResource.render.endDateSelect.j001}
                  </CustomSpan>
                  <input
                    className={classes.input}
                    readOnly={true}
                    value={this.showSelectedDate(this.state.repetitionEndDate)}
                    onClick={this.openEndPicker}
                  />
                  <Button
                    className={classes.iconButton}
                    variant="raised"
                    color="primary"
                    onClick={this.openEndPicker}
                  >
                    <DateRange />
                  </Button>
                  <DatePicker
                    style={{ display: "none" }}
                    format="yyyy-MM-dd"
                    ref={(node) => {
                      this.endPicker = node;
                    }}
                    onChange={this.handleRepetitionEndDate}
                    maxDate={endMonthOfNextYear()}
                    value={this.showSelectedDate(this.state.repetitionEndDate)}
                  />
                  <Radio
                    className={classes.radioRight}
                    name="product"
                    value="1"
                    onChange={this.handleRepetitionEnd}
                    checked={this.state.repetitionEndValue === "1"}
                  />
                  <CustomSpan>
                    {SlotResource.render.endDateSelect.j002}
                  </CustomSpan>
                </Content>
              </Item>
            )}
          </Container>
          {errors.length !== 0 &&
            errors.map((error, index) => (
              <React.Fragment key={index}>
                <Typography
                  variant="subheading"
                  gutterBottom
                  className={classes.error}
                >
                  {error}
                </Typography>
              </React.Fragment>
            ))}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <LinkA
            className={classes.buttonBack}
            onClick={() => this.handleBack()}
          >
            {SlotResource.DialogActions.button.j001}
          </LinkA>
          <Button
            onClick={() => this.openDialogCancelConfirm()}
            className={`${classes.buttonCancel} ${classes.dialogActionsButtonCancel}`}
          >
            {SlotResource.DialogActions.button.j002}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleConfirm}
            className={`${classes.buttonOk} ${classes.dialogActionsButtonOk}`}
            disabled={this.state.isSubmitting}
          >
            {SlotResource.DialogActions.button.j003}
          </Button>
        </DialogActions>
        <DialogCancelConfirm
          open={this.state.displayDialogCancelConfirm}
          closeDialogCancelConfirm={this.closeDialogCancelConfirm}
          handleClose={this.props.closeScheduleSelectionPopup}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(Slot);
