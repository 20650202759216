import { format, differenceInYears, parseISO } from "date-fns";
import jaLocale from "date-fns/locale/ja";
import { japaneseList } from "../Resources/japaneseList";
import { prefectureCodes } from "./PrefectureCode";
/**
 * calculate and returns display age.
 * @param {Date | String} birthdate The birthdate
 */
export function getAge(birthdate) {
  return differenceInYears(Date.now(), new Date(birthdate));
}

export function getAgeText(birthdate) {
  return getAge(birthdate) + japaneseList.Utils.DisplayLogic.getAgeText.j001;
}

export function getDayText(day) {
  const dayText = format(new Date(day), "yyyy/MM/dd(iii)", {
    locale: jaLocale,
  });
  return dayText;
}

export function getDayTextJa(day) {
  const dayText = format(parseISO(day), "yyyy年MM月dd日(iii)", {
    locale: jaLocale,
  });
  return dayText;
}

export function getDayTextJaFormatShort(day) {
  const dayText = format(parseISO(day), "yyyy年M月d日(iii)", {
    locale: jaLocale,
  });
  return dayText;
}

export function getTimeRangeText(from, to) {
  const text =
    format(parseISO(from), "HH:mm", { locale: jaLocale }) +
    " ～ " +
    format(parseISO(to), "HH:mm", { locale: jaLocale });
  return text;
}

export function getPhoneTextWithBrackets(phoneNumber) {
  return `（${
    japaneseList.Utils.DisplayLogic.getPhoneText.j001
  }：${getPhoneNumberWithoutPhoneCode(phoneNumber)}）`;
}

export function getPhoneNumberWithoutPhoneCode(phoneNumber) {
  phoneNumber = phoneNumber.replace(/\s/g, "");
  if (phoneNumber.startsWith("+")) {
    let phoneWithoutPhoneCode = phoneNumber.substring(3, phoneNumber.length);
    return `0${phoneWithoutPhoneCode}`;
  } else {
    return phoneNumber;
  }
}

export function getPhoneNumberWithHyphen(phoneNumber) {
  return phoneNumber && phoneNumber.length === 11
    ? phoneNumber.substring(0, 3) +
        "-" +
        phoneNumber.substring(3, 7) +
        "-" +
        phoneNumber.substring(7, 11)
    : phoneNumber;
}

export function getCombinedAddress(address) {
  const postalCode =
    japaneseList.Utils.DisplayLogic.getCombinedAddress.j001 +
    address.postalCode.slice(0, 3) +
    "-" +
    address.postalCode.slice(3);

  const prefecture = address.prefectureCode
    ? prefectureCodes.find((c) => address.prefectureCode === c.code).jpName ||
      ""
    : "";

  const addr =
    prefecture +
    (address.addressCity ? address.addressCity : "") +
    (address.addressStreetNumber ? address.addressStreetNumber : "") +
    " " +
    (address.addressBuilding ? address.addressBuilding : "");

  return { postalCode: postalCode, address: addr };
}

/**
 * convert numbers to half-width.
 * remove all except the numbers, including the number signs.
 * @param {string} text target data
 */
export function toHalfWidthNumbers(text) {
  return (
    text &&
    text.replace(/[^0-9]/g, (s) => {
      if (/[０-９]/.test(s)) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      }
      return "";
    })
  );
}

/**
 * detect half-width space and full-width space.
 * @param {*} text target data
 */
export function hasSpace(text) {
  const space = / |　/;
  return text && text.match(space);
}

/**
 * detect full-width character.
 * @param {*} text target data
 */
export function hasFullWidthCharacter(text) {
  const fullWidthCharacter = /[^\x01-\x7E\xA1-\xDF]/;
  return text && text.match(fullWidthCharacter);
}

/**
 * remove invalid zeros before numbers.
 * @param {string} text target data
 */
export function removeInvalidZeros(text) {
  return text && text.replace(/([0]*)([0-9]*[0-9]+|0)/g, "$2");
}
