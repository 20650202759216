import React from "react";
import styled from "styled-components";
import { Card } from "@material-ui/core";
import { japaneseList } from "../../../Resources/japaneseList";
import notDiagnosticTimeImage from "../../../images/videocam_off-24px.svg";
import { VideoStandbyStatus } from "../../pages/medical/VideoStandbyStatus";
import DoctorDeviceStatusDialog from "../../organisms/medical/DoctorDeviceStatusDialog";
import { ParticipantStatus } from "./ParticipantStatus";
import { MEDIA_DEVICE_ERROR } from "../../../Utils/const";
import { ErrorDevice, MediaDeviceError } from "../../../enums";
import * as browserDeviceDetection from "../../../Utils/browserDeviceDetection";

const MedicalResource =
  japaneseList.Pages.Medical.Medical.render.div.div.Card.CardContent.Typography;

const DiagnosticAreaCard = styled(Card)`
  height: 100%;
  display: flex;
`;

const Diagnostic = styled.div`
  height: 100%;
  width: 100%;
  background-color: #333333;
`;

const NotDiagnosticTime = styled.div`
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

const NotDiagnosticTimeText = styled.div`
  font-size: 16px;
  color: ${(p) => p.theme.color.fontVariant};
`;

const Img = styled.img`
  display: block;
  margin: 0px auto 10px;
`;

const NotDiagnosticTimeArea = styled.div`
  width: 100%;
  position: relative;
  background: #393939;
`;

const isAbleToClickStartButton = (videoStandbyStatus) => {
  if (
    videoStandbyStatus === VideoStandbyStatus.start ||
    videoStandbyStatus === VideoStandbyStatus.resume
  ) {
    return true;
  }
  return false;
};

const getSupportDeviceOsBrowser = () => {
  if (browserDeviceDetection.isWindow && browserDeviceDetection.isChrome) {
    return ErrorDevice.WinChrome;
  } else if (
    browserDeviceDetection.isWindow &&
    browserDeviceDetection.isMSEdge
  ) {
    return ErrorDevice.WinEdge;
  } else if (
    browserDeviceDetection.isMacOS &&
    browserDeviceDetection.isChrome
  ) {
    return ErrorDevice.MacChrome;
  } else if (
    browserDeviceDetection.isTablet &&
    browserDeviceDetection.isSafari
  ) {
    return ErrorDevice.IpadSafari;
  } else {
    console.log("getSupportDeviceOsBrowser: DeviceOsBrowser is unsupported");
    return "";
  }
};

const getSupportDeviceError = (doctorDeviceError) => {
  switch (doctorDeviceError) {
    case MEDIA_DEVICE_ERROR.EMPTY:
      return "";
    case MEDIA_DEVICE_ERROR.NOT_FOUND_ERROR:
      return MediaDeviceError.NotFoundError;
    case MEDIA_DEVICE_ERROR.NOT_READABLE_ERROR:
      return MediaDeviceError.NotReadableError;
    case MEDIA_DEVICE_ERROR.NOT_ALLOWED_ERROR:
      return MediaDeviceError.NotAllowedError;
    default:
      return MediaDeviceError.OtherError;
  }
};

export const MainAreaVideoClose = ({
  videoStandbyStatus,
  isPatientReady,
  doctorDeviceError,
  patientStandbyStatus,
  patient,
}) => {
  const isOpenDeviceStatusDialog =
    getSupportDeviceOsBrowser() && getSupportDeviceError(doctorDeviceError);

  return (
    <React.Fragment>
      <DiagnosticAreaCard>
        {isAbleToClickStartButton(videoStandbyStatus) ? (
          <Diagnostic>
            {isOpenDeviceStatusDialog !== "" ? (
              <DoctorDeviceStatusDialog
                errorDevice={getSupportDeviceOsBrowser()}
                mediaDeviceError={getSupportDeviceError(doctorDeviceError)}
              />
            ) : (
              <ParticipantStatus
                isPatientReady={isPatientReady}
                patient={patient}
                patientStandbyStatus={patientStandbyStatus}
              />
            )}
          </Diagnostic>
        ) : (
          <NotDiagnosticTimeArea>
            <NotDiagnosticTime>
              <Img src={notDiagnosticTimeImage} alt={""} />
              <NotDiagnosticTimeText>
                {MedicalResource.j005}
              </NotDiagnosticTimeText>
            </NotDiagnosticTime>
          </NotDiagnosticTimeArea>
        )}
      </DiagnosticAreaCard>
    </React.Fragment>
  );
};
