// --------------------------------------------------
//
// insurance card Dialog class
//
// --------------------------------------------------

// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

// personal modules
// --------------------------------------------------
import FontP from "../../atoms/fonts/FontP";
import { japaneseList } from "../../../Resources/japaneseList";
const Resources = japaneseList.molecules.dialogs.DialogInsuranceCardCaution;

// style
// --------------------------------------------------
const styles = (theme) => ({
  dialogTitle: {
    padding: "24px",
    textAlign: "center",
  },
  dialogContent: {
    padding: "0 36px 24px 36px",
  },
  dialogActions: {
    margin: "8px 20px 20px 20px",
  },
  button: {
    color: theme.button.cancel.color,
    backgroundColor: theme.button.cancel.backgroundColor,
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColor,
    },
  },
});
const CustomFontP = styled(FontP)`
  margin-bottom: 20px;
`;
// class define
// --------------------------------------------------
class DialogInsuranceCardCaution extends Component {
  // default
  // --------------------------------------------------
  static defaultProps = {
    open: false,
    onClose: function () {},
    handleClickButton: function () {},
    maxWidth: "sm",
  };

  render() {
    const { classes, open, onClose, handleClickButton, maxWidth } = this.props;

    return (
      <Dialog open={open} onClose={onClose} maxWidth={maxWidth}>
        <DialogTitle className={classes.dialogTitle}>
          {Resources.title}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <CustomFontP size="s">{Resources.text}</CustomFontP>
          <FontP size="s">{Resources.condition}</FontP>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            id="video-insurance-card-caution-close-button"
            className={classes.button}
            onClick={handleClickButton}
          >
            {Resources.button}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogInsuranceCardCaution);
