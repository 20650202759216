// required　modules
// --------------------------------------------------
import React from "react";
import { Dialog, Button } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { DELIVERY_METHOD } from "../../../Utils/const";
import { getPhoneNumberWithHyphen } from "../../../Utils/DisplayLogic";
import { AddressContent } from "../../molecules/address/addressContent";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  appointmentSelect: Appointment;
  handleCloseMedicineDeliveryDialog: () => void;
}

interface Appointment {
  patient: Patient;
  medicineDelivery: MedicineDelivery;
}

interface Patient {
  familyName: string;
  givenName: string;
  address: Address;
  phoneNumber: string;
}

interface Address {
  prefectureCode: string;
  postalCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
}

interface MedicineDelivery {
  accountStatus: string;
  deliveryMethod: string;
  prefectureCode: string;
  postalCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
  name: string;
  tel: string;
  fax: string;
}

const Resources = japaneseList.Components.Payment.MedicineDeliveryDialog;

const ACCOUNTSTATUS = {
  ENABLED: "enabled",
};

const DialogTitle = styled.div`
  margin: 22px 25px 16px 24px;
`;

const DialogContent = styled.div`
  margin: 22px 25px 16px 24px;
`;

const DialogActions = styled.div`
  text-align: right;
  margin: 22px 25px 16px 24px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);
`;

const PrescriptionTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0 10px 0;
  font-family: Hiragino Kaku Gothic ProN;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-family: Hiragino Kaku Gothic ProN;
`;

const TextSmall = styled.div`
  font-size: 12px;
`;

const ContainerBorder = styled.div`
  border-bottom: #ccc solid 1px;
  border-right: #ccc solid 1px;
  border-left: #ccc solid 1px;
  width: 100%;
  margin-top: 20px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  grid-gap: 10px;
  border-top: #ccc solid 1px;
`;

const TitleItem = styled.div`
  background-color: #eee;
  padding: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #808080;
  font-weight: 600;
  font-family: Hiragino Kaku Gothic ProN;
`;

const ContentItem = styled.div`
  padding: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  word-break: break-word;
  white-space: pre-wrap;
`;

const Text = styled.div`
  word-break: break-word;
  white-space: pre-wrap;
  font-size: 14px;
`;

const ButtonCancel = styled(Button)`
  font-weight: 600 !important;
  font-family: Hiragino Kaku Gothic ProN !important;
`;

const PharmacyWarning = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: #ef5e5e;
`;

// ToDo: Migrate custom theme to d.ts file
const styles = (theme: any) => ({
  maxHeightWidthDialog: {
    maxHeight: "100%",
    maxWidth: "810px",
  },
  buttonCancel: {
    backgroundColor: "gray",
    color: "white",
    width: "120px",
    height: "40px",
    background: "#808080 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 0px #505050",
    borderRadius: "6px",
    opacity: "1",
    margin: "0 0 0 20px",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
});

const formatPostalCode = (postalCode: string) => {
  return postalCode && `${postalCode.slice(0, 3)}-${postalCode.slice(3, 7)}`;
};

const DialogMedicineDelivery = (props: Props) => {
  const {
    classes,
    open,
    appointmentSelect,
    handleCloseMedicineDeliveryDialog,
  } = props;
  const medicineDelivery = appointmentSelect.medicineDelivery;
  const patient = appointmentSelect.patient;

  return (
    <Dialog
      open={open}
      maxWidth={false}
      fullWidth={true}
      PaperProps={{ classes: { root: classes.maxHeightWidthDialog } }}
    >
      <React.Fragment>
        <DialogTitle>
          <Title>{Resources.DialogTitle.j001}</Title>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Text>{Resources.DialogContent.j001}</Text>
          <PrescriptionTitle>{Resources.DialogContent.j002}</PrescriptionTitle>
          <TextSmall>
            {appointmentSelect.medicineDelivery
              ? appointmentSelect.medicineDelivery.deliveryMethod ===
                DELIVERY_METHOD.DELIVERY
                ? Resources.DialogContent.j004
                : appointmentSelect.medicineDelivery.deliveryMethod ===
                  DELIVERY_METHOD.GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME
                ? Resources.DialogContent.j005
                : Resources.DialogContent.j006
              : Resources.DialogContent.j006}
          </TextSmall>
          <PrescriptionTitle>{Resources.DialogContent.j003}</PrescriptionTitle>
          <React.Fragment>
            {medicineDelivery &&
              medicineDelivery.deliveryMethod === DELIVERY_METHOD.DELIVERY && (
                <React.Fragment>
                  {medicineDelivery.accountStatus !== ACCOUNTSTATUS.ENABLED && (
                    <PharmacyWarning>
                      {Resources.DialogContent.j013}
                    </PharmacyWarning>
                  )}
                  <ContainerBorder>
                    <Container>
                      <TitleItem>{Resources.DialogContent.j007}</TitleItem>
                      <ContentItem>{medicineDelivery.name}</ContentItem>
                    </Container>
                    <Container>
                      <TitleItem>{Resources.DialogContent.j008}</TitleItem>
                      <ContentItem>
                        {formatPostalCode(
                          patient.address && patient.address.postalCode
                        )}
                      </ContentItem>
                    </Container>
                    <Container>
                      <TitleItem>{Resources.DialogContent.j009}</TitleItem>
                      <ContentItem>
                        <AddressContent addressInfo={medicineDelivery} />
                      </ContentItem>
                    </Container>
                    <Container>
                      <TitleItem>{Resources.DialogContent.j010}</TitleItem>
                      <ContentItem>{medicineDelivery.tel}</ContentItem>
                    </Container>
                    <Container>
                      <TitleItem>{Resources.DialogContent.j011}</TitleItem>
                      <ContentItem>{medicineDelivery.fax}</ContentItem>
                    </Container>
                  </ContainerBorder>
                </React.Fragment>
              )}
            {medicineDelivery &&
              medicineDelivery.deliveryMethod ===
                DELIVERY_METHOD.GO_GET_WITH_RECEIVE_PRESCRIPTION_ATHOME && (
                <ContainerBorder>
                  <Container>
                    <TitleItem>{Resources.DialogContent.j012}</TitleItem>
                    <ContentItem>{`${patient.familyName} ${patient.givenName}`}</ContentItem>
                  </Container>
                  <Container>
                    <TitleItem>{Resources.DialogContent.j008}</TitleItem>
                    <ContentItem>
                      {formatPostalCode(patient.address.postalCode)}
                    </ContentItem>
                  </Container>
                  <Container>
                    <TitleItem>{Resources.DialogContent.j009}</TitleItem>
                    <ContentItem>
                      <AddressContent addressInfo={patient.address} />
                    </ContentItem>
                  </Container>
                  <Container>
                    <TitleItem>{Resources.DialogContent.j010}</TitleItem>
                    <ContentItem>
                      {getPhoneNumberWithHyphen(patient.phoneNumber)}
                    </ContentItem>
                  </Container>
                </ContainerBorder>
              )}
          </React.Fragment>
          {(!medicineDelivery ||
            medicineDelivery.deliveryMethod === DELIVERY_METHOD.NONE) && (
            <TextSmall>{Resources.DialogContent.j006}</TextSmall>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <ButtonCancel
            id="prescription-display-dialog-close-button"
            className={classes.buttonCancel}
            onClick={handleCloseMedicineDeliveryDialog}
          >
            {Resources.DialogActions.j001}
          </ButtonCancel>
        </DialogActions>
      </React.Fragment>
    </Dialog>
  );
};
export default withStyles(styles)(DialogMedicineDelivery);
