import gql from "graphql-tag";

export const GetHospital = gql`
  {
    hospital {
      address
      addressBuilding
      addressCity
      addressStreetNumber
      defaultAppointmentSlotExpiration
      description
      doctors
      hospitalId
      menus {
        name
        menuId
        description
        doctorId
        medicalMenu
        medicalMethod
        usageFee
        examFeeDescription
        assignToDoctor
        createAppointmentByPatient
        createAppointmentByDoctor
        displayName
        createSlotByDoctor
        supportsInsuranceTreatment
      }
      name
      postalCode
      prefectureCode
      startDate
      tel
      url
      facilityType
      hospitalType
      isSupportOnlineQualificationSystem
    }
  }
`;
