import styled from "styled-components";

const Header = styled.header`
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 69px;
  padding: 0 0 0 24px;
  box-sizing: border-box;
  color: ${(p) => p.theme.header.color.default};
  background-color: ${(p) => p.theme.header.bg.default};
  box-shadow: 0px 2px 3px #0000001a;
  z-index: 1100;
  border-bottom: 4px solid ${(p) => p.theme.header.border.bottom};
`;

export default Header;
