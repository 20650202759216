import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { InterviewSheetAnswerDetail } from "./InterviewSheetAnswerDetail";
import { InterviewSheetAnswerPrint } from "./print/InterviewSheetAnswerPrint";
import PrintButtonIcon from "../../../images/print-24px.svg";
import { isLnln } from "../../../Utils/checkLnln";

const MedicalDetailAreaResource = japaneseList.Pages.Patient.MedicalDetailArea;

const DisplayArea = styled.div`
  height: 100%;
  background-color: white;
  padding: 60px 20px 20px 20px;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  box-shadow: 1px 1px #0000001a;
  font-size: 14px;
`;

const PrintButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${isLnln() ? "#8EAFED" : (p) => p.theme.color.primary};
  box-shadow: 0px 1px 0px ${isLnln() ? "#738EBF" : "#009896"};
  width: 106px;
  height: 28px;
  padding-top: 1px;
  padding-right: 5px;
  border-radius: 3px;
  opacity: 1;
  position: relative;
  float: right;
  top: -45px;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background-color: ${isLnln() ? "#B5CDF8" : "#6ce1d5"};
  }
`;

const PrintButtonInactive = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #dddddd 0% 0% no-repeat padding-box;
  width: 106px;
  height: 28px;
  padding-top: 1px;
  padding-right: 5px;
  border-radius: 3px;
  opacity: 1;
  position: relative;
  float: right;
  top: -45px;
  cursor: not-allowed;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin: 2px 5px 2px 2px;
`;

const ButtonText = styled.div`
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  font-weight: bold;
`;

const NotDisplayArea = styled.div`
  display: none;
`;

export const MedicalInterviewSheetAnswer = ({ patient, contents }) => {
  const componentRef = useRef();

  const handleClickDownloadButton = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "@page{ margin: 0mm 10mm }",
  });

  return (
    <React.Fragment>
      <DisplayArea>
        {contents.interviewSheetAnswer ? (
          <PrintButton onClick={handleClickDownloadButton}>
            <Icon src={PrintButtonIcon}></Icon>
            <ButtonText>{MedicalDetailAreaResource.j006}</ButtonText>
          </PrintButton>
        ) : (
          <PrintButtonInactive>
            <Icon src={PrintButtonIcon}></Icon>
            <ButtonText>{MedicalDetailAreaResource.j006}</ButtonText>
          </PrintButtonInactive>
        )}
        {contents && <InterviewSheetAnswerDetail contents={contents} />}
      </DisplayArea>
      <NotDisplayArea>
        <InterviewSheetAnswerPrint
          patient={patient}
          contents={contents}
          ref={componentRef}
        />
      </NotDisplayArea>
    </React.Fragment>
  );
};
