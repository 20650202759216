import styled from "styled-components";
import NumberFormat from "react-number-format";
import PhoneIcon from "../../../images/icon/icon-phone.svg";
import { japaneseList } from "../../../Resources/japaneseList";

const PatientContactResource = japaneseList.Pages.Medical.PatientContact;

const PatientInfor = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #393939;
  padding-top: 10px;
  border-top: 1px solid #eeeeee;
  margin-top: auto;
`;

const PatientName = styled.div``;

const PatientPhone = styled.div``;

const PatientPhoneIcon = styled.img`
  vertical-align: middle;
`;

interface PatientContactProps {
  patientName: string;
  patientPhone: string;
}

export const PatientContact = (props: PatientContactProps) => {
  const { patientName, patientPhone } = props;
  return (
    <PatientInfor>
      <PatientName>
        {patientName} {PatientContactResource.text.j001}
      </PatientName>
      <PatientPhone>
        <PatientPhoneIcon src={PhoneIcon} alt={""} />
        <NumberFormat
          value={patientPhone}
          displayType={"text"}
          format="###-####-####"
        />
      </PatientPhone>
    </PatientInfor>
  );
};
