import React, { useState, useEffect, useContext } from "react";
import { format, parseISO } from "date-fns";
import styled from "styled-components";
import { Card, CardContent } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { isLnln } from "../../../Utils/checkLnln";
import { japaneseList } from "../../../Resources/japaneseList";
import { client, GetInterviewSheetAnswer } from "../../../graphql";
import { InterviewSheetAnswerDetail } from "../patient/InterviewSheetAnswerDetail";
import { MedicineDeliveryDetail } from "./MedicineDeliveryDetail";
import {
  INTERVIEW_SHEET_ANSWER_STATUS,
  FACILITY_TYPE,
} from "../../../Utils/const";
import SiteContext from "../../../contexts/SiteContext";

const InterviewAreaResource = japaneseList.Pages.Medical.InterviewArea;
const MedicineDeliveryResource = japaneseList.Pages.Medical.medicineDelivery;
const DisplayTabs = {
  INTERVIEW_SHEET_ANSWER: "InterviewSheetAnswer",
  MEDICINE_DELIVERY: "medicineDelivery",
};

interface Props {
  appointment: Appointment;
  contentsDateList: contentsDate[];
  patient: Patient;
}

interface Appointment {
  patient: Patient;
  createTime: string;
  companyName: string;
  content: string;
  interviewSheetAnswerStatus: string;
  medicineDelivery: MedicineDelivery;
}

interface contentsDate {
  companyName: string;
  content: string;
  interviewSheetAnswerStatus: string;
  medicineDelivery: MedicineDelivery;
  createTime: string;
  from: string;
}

interface Patient {
  patientId: string;
  familyName: string;
  givenName: string;
  address: Address;
  phoneNumber: string;
}

interface Address {
  prefectureCode: string;
  postalCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
}

interface MedicineDelivery {
  accountStatus: string;
  displaySites: string;
  deliveryMethod: string;
  prefectureCode: string;
  postalCode: string;
  addressCity: string;
  addressStreetNumber: string;
  addressBuilding: string;
  name: string;
  tel: string;
  fax: string;
}

interface Contents {
  companyName?: string;
  content?: string;
  interviewSheetAnswer?: string;
  medicineDelivery?: MedicineDelivery;
  patient?: Patient;
}

const MedicalDetailAreaCard = styled(Card)`
  font-size: 14px;
  flex: 0 1 50px;
  height: calc(100vh - 348px);
`;

const MedicalDetailAreaCardContent = styled(CardContent)`
  padding: 0px !important;
  max-height: 100%;
  margin: 0 0 20px 20px;
`;

const TitleNormal = styled.div`
  display: inline-flex;
  font-weight: bold;
  top: 0px;
  width: 120px;
  padding: 5px 35px;
  background: #eeeeee;
  color: #808080;
  cursor: pointer;
  font-size: 12px;
`;

const TitleNormalRight = styled.div`
  display: inline-flex;
  font-weight: bold;
  top: 0px;
  width: 120px;
  padding: 5px 35px;
  background: #eeeeee;
  color: #808080;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
  padding: 5px 24px;
`;

const TitleSelect = styled.div`
  display: inline-flex;
  font-weight: bold;
  top: 0px;
  width: 120px;
  padding: 5px 35px;
  background: ${(p) => p.theme.color.videoTabBackground};
  color: ${(p) => p.theme.font.color.tab};
  cursor: pointer;
  font-size: 12px;
`;

const TitleSelectRight = styled.div`
  display: inline-flex;
  font-weight: bold;
  top: 0px;
  width: 120px;
  padding: 5px 35px;
  background: ${(p) => p.theme.color.videoTabBackground};
  color: ${(p) => p.theme.font.color.tab};
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
  padding: 5px 24px;
`;

const MedicinDate = styled.div`
  margin: 20px 0 9px 20px;
  display: flex;
  align-items: baseline;
`;

const TabsLeftLine = styled.div`
  width: 120px;
  height: 2px;
  background-color: ${(p) => p.theme.color.primary};
  position: relative;
  top: 30px;
`;

const TabsRightLine = styled.div`
  width: 120px;
  height: 2px;
  background-color: ${(p) => p.theme.color.primary};
  position: relative;
  top: 30px;
  left: 130px;
`;

const MedicinDateText = styled.div`
  font-size: 10px;
  color: #393939;
  width: 50px;
  margin: 2px -12px 0 17px;
  font-weight: bolder;
  padding-left: 4px;
`;

const MedicinDateTextTitle = styled.div`
  font-size: 14px;
  color: #393939;
  font-weight: bolder;
  width: 80px;
`;

const DateSelect = styled(Select)`
  width: 100%;
  margin-top: -5px;
  color: ${(p) => p.theme.color.font};
  &:hover {
    color: #a6a6a6;
    & svg {
      color: #a6a6a6;
    }
  }
  & svg {
    top: calc(50% - 13px);
  }
  & select {
    width: 100%;
    font-size: 12px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 20px;
    padding-left: 10px;
    &:focus {
      background-color: #ffffff;
    }
  }
`;

const Option = styled.option`
  color: ${(p) => p.theme.color.font};
`;

const PrimaryHr = styled.hr`
  width: 250px;
  background-color: ${isLnln() ? "#171F55" : (p) => p.theme.color.primary};
  border: 0;
  height: 2px;
  margin: 0 20px 13px 20px;
`;

const TabsHr = styled.hr`
  width: 100%;
  background-color: #cccccc;
  border: 0;
  height: 1px;
  margin: 0px;
  position: relative;
  top: 98px;
`;

const ContentTextArea = styled.div`
  height: calc(100vh - 438px);
  padding: 20px 0 10px 0;
  width: 270px;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

export const MedicalDetailArea = ({
  appointment,
  contentsDateList,
  patient,
}: Props) => {
  const patientId = appointment.patient.patientId;
  // contents includes companyName,content(consultation content) and interviewSheetAnswer shown in InterviewSheetAnswerDetail
  const [contents, setContents] = useState<Contents>({});
  const [selectedCreateTime, setSelectedCreateTime] = useState<string>(
    appointment.createTime
  );
  const [displayTab, setDisplayTab] = useState<string>(
    DisplayTabs.INTERVIEW_SHEET_ANSWER
  );
  // ToDo:SiteContextの型は初期値がNULLを指定しているので、型指定は一旦anyです。
  const siteContext: {
    hospital: { facilityType: string };
  } = useContext<any>(SiteContext);

  useEffect(() => {
    loadContents(selectedCreateTime);
  }, [selectedCreateTime]);

  const getInterviewSheetAnswer = async (createTime: string) => {
    const { data, errors } = await client.query({
      query: GetInterviewSheetAnswer,
      variables: { patientId, createTime },
    });
    if (errors) {
      console.error("GetInterviewSheetAnswer errors:", JSON.stringify(errors));
    }
    return data ? data.interviewSheetAnswer : null;
  };

  const loadContents = async (createTime: string) => {
    const {
      companyName,
      content,
      interviewSheetAnswerStatus,
      medicineDelivery,
    } =
      (contentsDateList || []).find((item) => item.createTime === createTime) ||
      appointment;
    if (
      interviewSheetAnswerStatus === INTERVIEW_SHEET_ANSWER_STATUS.UNANSWERED ||
      interviewSheetAnswerStatus === INTERVIEW_SHEET_ANSWER_STATUS.ANSWERED
    ) {
      const interviewSheetAnswer = await getInterviewSheetAnswer(createTime);
      setContents({
        companyName,
        content,
        interviewSheetAnswer,
        patient,
        medicineDelivery,
      });
    } else {
      setContents({ companyName, content, patient, medicineDelivery });
    }
  };

  const handleChangeDate = (e: { target: { value: string } }) => {
    setSelectedCreateTime(e.target.value);
    loadContents(e.target.value);
  };

  const handleChangeInterviewTab = () => {
    setDisplayTab(DisplayTabs.INTERVIEW_SHEET_ANSWER);
  };

  const handleChangeMedicineTab = () => {
    setDisplayTab(DisplayTabs.MEDICINE_DELIVERY);
  };

  return (
    <React.Fragment>
      <TabsHr />
      <MedicalDetailAreaCard>
        <MedicinDate>
          <MedicinDateTextTitle>
            {InterviewAreaResource.Typography.j007}
          </MedicinDateTextTitle>
          <MedicinDateText>
            {InterviewAreaResource.Typography.j001}
          </MedicinDateText>
          <div>
            <DateSelect
              id="medical-date-select"
              disableUnderline
              native
              value={selectedCreateTime}
              onChange={handleChangeDate}
            >
              {contentsDateList &&
                contentsDateList.map((item) => (
                  <Option value={item.createTime} key={item.createTime}>
                    {format(
                      parseISO(item.from),
                      InterviewAreaResource.Typography.j005
                    )}
                  </Option>
                ))}
            </DateSelect>
          </div>
        </MedicinDate>
        <PrimaryHr />
        <MedicalDetailAreaCardContent>
          {displayTab === DisplayTabs.INTERVIEW_SHEET_ANSWER && (
            <React.Fragment>
              <TabsLeftLine />
              <TitleSelect
                id="interview-content-tab"
                onClick={handleChangeInterviewTab}
              >
                {InterviewAreaResource.title}
              </TitleSelect>
              {siteContext.hospital &&
                siteContext.hospital.facilityType ===
                  FACILITY_TYPE.HOSPITAL && (
                  <TitleNormalRight
                    id="prescription-desired-destination-tab"
                    onClick={handleChangeMedicineTab}
                  >
                    <React.Fragment>
                      {MedicineDeliveryResource.title}
                    </React.Fragment>
                  </TitleNormalRight>
                )}
              <ContentTextArea>
                <InterviewSheetAnswerDetail contents={contents} />
              </ContentTextArea>
            </React.Fragment>
          )}
          {displayTab === DisplayTabs.MEDICINE_DELIVERY && (
            <React.Fragment>
              <TabsRightLine />
              <TitleNormal
                id="interview-content-tab"
                onClick={handleChangeInterviewTab}
              >
                {InterviewAreaResource.title}
              </TitleNormal>
              <TitleSelectRight
                id="prescription-desired-destination-tab"
                onClick={handleChangeMedicineTab}
              >
                <React.Fragment>
                  {MedicineDeliveryResource.title}
                </React.Fragment>
              </TitleSelectRight>
              <ContentTextArea>
                <MedicineDeliveryDetail contents={contents} />
              </ContentTextArea>
            </React.Fragment>
          )}
        </MedicalDetailAreaCardContent>
      </MedicalDetailAreaCard>
    </React.Fragment>
  );
};
