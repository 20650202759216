/** Color rules */
export const color = {
  //primary: "#fdb1b6",
  primary: "#8EAFED",
  primaryVariant: "#B5CDF8",
  primaryVariant2: "#d3f6f2",
  primaryVariant3: "#ebf0fc",
  primaryVariant4: "#f2feff",
  primaryVariant5: "#ebf0fc",
  primaryVariant6: "#fff1f2",
  secondary: "#cccccc",
  secondaryVariant: "#f1f3f3",
  secondaryVariant2: "#f7f7f7",
  warning: "#ff4d4d",
  warningBackground: "#fff0f0",
  font: "#393939",
  fontVariant: "#999999",
  fontInvert: "#ffffff",
  examIcon1: "#faae1c",
  examIcon2: "#85b7de",
  notInsurance: "#f9a011",
  shadow: "#505050",
  hover: "#b5cdf8",
  link: "#2563c0",
  videoTabBackground: "#ebf0fc",
};

/** font rules */
export const font = {
  color: {
    default: "#393939",
    link: "#ff9fa4",
    error: "#ff4d4d",
    insurance: "#999999",
    placeholder: "#aaa",
    white: "#fff",
    gray: "#999999",
    tab: "#8EAFED",
  },
  size: {
    sss: "0.7rem",
    ss: "0.8rem",
    s: "0.9rem",
    default: "1.0rem",
    m: "1.0rem",
    l: "1.2rem",
    ll: "1.4rem",
  },
  align: {
    default: "left",
    center: "center",
    left: "left",
    right: "right",
  },
  weight: {
    default: "normal",
    normal: "normal",
    bold: "bold",
  },
  whiteSpace: {
    preWrap: "pre-wrap",
  },
};

/** font rules */
export const heading = {
  color: {
    default: "#393939",
    link: "#ff9fa4",
    placeholder: "#aaa",
    white: "#fff",
  },
  bg: {
    default: "#171F55",
  },
  size: {
    sss: "0.7rem",
    ss: "0.8rem",
    s: "0.9rem",
    default: "1.0rem",
    m: "1.0rem",
    l: "1.2rem",
    ll: "1.4rem",
  },
};

/** font rules */
export const button = {
  color: {
    default: "#393939",
    link: "#fdb1b6",
    white: "#fff",
    shadow: "#738e8f",
  },
  bg: {
    default: "#fff",
    ok: "#fdb1b6",
    cancel: "#ffffff",
    alert: "#ff4d4d",
    other: "#ffffff",
    disable: "#cccccc",
  },
  border: {
    default: "#fff",
    link: "#fdb1b6",
  },
  width: {
    default: "auto",
    full: "100%",
    half: "50%",
  },
  padding: {
    default: "10px 20px",
    small: "5px",
  },
};

/** input rules */
export const input = {
  width: {
    full: "100%",
  },
};

/** panel rules */
export const panel = {
  padding: {
    ss: "5px",
    s: "10px",
    default: "15px",
    m: "15px",
    l: "20px",
    ll: "25px",
    vsh0: "10px 0", // virtical horizon
    vmh0: "15px 0", // virtical horizon
  },
};

export const icon = {
  bg: {
    default: "#fdb1b6",
    gray: "#999999",
  },
};
/** font rules */
export const header = {
  color: {
    default: "#fff",
  },
  bg: {
    default: "#ffe8ee",
  },
  tooltip: {
    icon: "#171f55",
    title: "#171f55",
    hover: "#fff0f2",
    underbar: "#171f55",
    color: "#171f55",
  },
  other: {
    font: "#171f55",
    hover: "#ebf0fc",
    color: "#171f55",
    border: "#8eafed",
  },
  border: {
    left: "#efcdd6",
    bottom: "#171f55",
  },
};

/** calendar rules */
export const calendar = {
  bg: {
    color: {
      header: "#936565", // 今日のヘッダー
      today: "#f7999e1a", // 今日の背景
      slot: "#95abd8", // 予約枠
      before: "#f7999e", // 予約あり
    },
  },
  border: {
    color: {
      timeslotgroup: "#dee", //時間区切り
      currenttime: "#fdb1b6", //今の時間
    },
  },
};

export const thumbnailList = {
  backgroundColor: "#ffffff",
  borderColor: "#d5d5d5",
  dateTimeColor: "#a09c99",
};
