import React, { Component } from "react";
import { client, GetPatient } from "../../../../graphql";
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import styled from "styled-components";

import { japaneseList } from "../../../../Resources/japaneseList";
import { toHalfWidthNumbers } from "../../../../Utils/DisplayLogic";

const PatientSearchResource =
  japaneseList.Components.Appointment.Stepper.PatientSearch;

const styles = (theme) => ({
  error: {
    color: "red",
    margin: "8px 0px 5px 0px",
    fontSize: "12px",
    fontWeight: "bold",
    fontFamily: "Hiragino Kaku Gothic ProN",
  },
  tipsMessage: {
    fontSize: "14px",
    marginBottom: "10px",
    color: "#393939",
  },
  textField: {
    margin: "0px 0px 5px 0px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  input: {
    width: "194px",
    height: "36px",
    lineHeight: "36px",
    alignItems: "center",
    verticalAlign: "middle",
    padding: "10px 0px 10px 12px",
    marginRight: "10px",
    fontSize: "14px",
    border: "1px solid #dddddd",
    background: `${theme.color.primaryVariant3} 0% 0% no-repeat padding-box`,
    borderRadius: "3px",
    textAlign: "left",
    color: "#393939",
    "&:focus": {
      outline: "none",
    },
    WebkitBoxShadow: `0 0 0 30px ${theme.color.primaryVariant3} inset !important`,
    backgroundClip: "content-box",
  },
  inputError: {
    width: "194px",
    height: "36px",
    lineHeight: "36px",
    alignItems: "center",
    verticalAlign: "middle",
    padding: "10px 0px 10px 12px",
    marginRight: "10px",
    fontSize: "14px",
    border: "1px solid #dddddd",
    background: `${theme.color.primaryVariant5} 0% 0% no-repeat padding-box`,
    borderRadius: "3px",
    textAlign: "left",
    color: "#393939",
    "&:focus": {
      outline: "none",
    },
    WebkitBoxShadow: `0 0 0 30px ${theme.color.primaryVariant5} inset !important`,
    backgroundClip: "content-box",
  },
  button: {
    width: "48px",
    height: "34px",
    color: "#fff !important",
    fontSize: "14px",
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
});

const RemindText = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  color: #393939;
`;

const Text = styled.div`
  margin: 0px 0px 5px 0px;
  font-size: 12px;
  font-weight: bold;
`;

class PatientSearch extends Component {
  state = {
    phoneNumber: this.props.phoneNumber,
    disable: this.props.phoneNumber ? false : true,
    error: null,
  };

  search = async () => {
    let { phoneNumber } = this.state;
    if (!phoneNumber) {
      return;
    }
    if (phoneNumber.startsWith("0")) {
      phoneNumber = "+81" + phoneNumber.substring(1, phoneNumber.length);
    }
    this.setState({
      disable: true,
    });
    try {
      const { data, errors } = await client.query({
        query: GetPatient,
        variables: { phoneNumber },
      });
      if (errors) {
        if (errors[0].message === "invalid phone number format") {
          this.setState({
            error: PatientSearchResource.search.j001,
          });
        } else {
          this.setState({
            error: PatientSearchResource.search.j002,
          });
        }
      } else {
        this.setState({
          error: null,
          disable: false,
        });
        this.props.setPatient(data);
        this.props.handlePhoneNumberChange(this.state.phoneNumber);
      }
    } catch (err) {
      let errors = err.graphQLErrors;
      const error = errors.find((e) => e.errorType === "E01")
        ? PatientSearchResource.search.j003
        : errors.find((e) => e.errorType === "E02")
        ? PatientSearchResource.search.j004
        : PatientSearchResource.search.j005;

      this.setState({
        error,
        disable: false,
      });
    }
  };

  handleChange = (event) => {
    this.setState({ phoneNumber: event.target.value });
    const phoneNumber = toHalfWidthNumbers(event.target.value);
    if (phoneNumber) {
      this.setState({ disable: false });
    } else {
      this.setState({ disable: true });
    }
  };

  handleBlur = (event) => {
    const phoneNumber = toHalfWidthNumbers(event.target.value);
    this.setState({ phoneNumber: phoneNumber && phoneNumber.slice(0, 11) });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <RemindText>{PatientSearchResource.render.div.Text.j002}</RemindText>
        <Text>{PatientSearchResource.render.div.Text.j001}</Text>
        {this.state.error && (
          <Typography
            variant="subheading"
            gutterBottom
            className={classes.error}
          >
            {this.state.error}
          </Typography>
        )}
        {this.state.error ? (
          <input
            type="tel"
            id="phoneNumber"
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            value={this.state.phoneNumber}
            className={classes.inputError}
          />
        ) : (
          <input
            type="tel"
            id="phoneNumber"
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            value={this.state.phoneNumber}
            className={classes.input}
          />
        )}
        <Button
          type="submit"
          variant="raised"
          color="primary"
          onClick={this.search}
          disabled={this.state.disable}
          className={classes.button}
        >
          {PatientSearchResource.render.div.Button.j001}
        </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PatientSearch);
