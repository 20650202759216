import React, { forwardRef } from "react";
import styled from "styled-components";
import { InterviewSheetAnswerHeader } from "./InterviewSheetAnswerHeader";
import { InterviewSheetAnswerRow } from "./InterviewSheetAnswerRow";
import { japaneseList } from "../../../../Resources/japaneseList";
const InterviewSheetAnswerPrintResource =
  japaneseList.Pages.Patient.InterviewSheetAnswerPrint;

const Table = styled.table`
  border-collapse: collapse;
  font-family: ヒラギノ角ゴ ProN;
`;

const getInterviewSheetAnswerInfo = (contents) => {
  const { interviewSheetAnswer } = contents;
  const QAs = [];

  if (interviewSheetAnswer) {
    const interviewAnswers = (interviewSheetAnswer.interviewAnswers || [])
      .slice()
      .sort((a, b) => {
        return a.order - b.order;
      });
    interviewAnswers.forEach((element) => {
      let answerStr = "";
      let freeText = "";
      if (element.interviewChoiceNos.length > 0) {
        element.interviewChoiceNos.map((choiceNo, index) => {
          const choiceItem = element.interviewChoices.find(
            (choice) => choice.no === choiceNo
          );
          answerStr += choiceItem.text;
          if (index !== element.interviewChoiceNos.length - 1)
            answerStr += InterviewSheetAnswerPrintResource.j001;
          return choiceNo;
        });
        if (element.freeText) {
          answerStr += InterviewSheetAnswerPrintResource.j002;
          freeText = element.freeText;
        }
      } else {
        freeText = element.freeText
          ? element.freeText
          : InterviewSheetAnswerPrintResource.j003;
      }
      QAs.push({
        idNotation: element.idNotation,
        interviewText: element.interviewText,
        answerStr: answerStr,
        freeText: freeText,
      });
    });
  }
  return { interviewAnswers: QAs };
};

export const InterviewSheetAnswerPrint = forwardRef((props, ref) => {
  const { patient, contents } = props;
  const { interviewAnswers } = getInterviewSheetAnswerInfo(contents);

  return (
    <React.Fragment>
      <Table ref={ref}>
        <thead>
          <InterviewSheetAnswerHeader patient={patient} contents={contents} />
        </thead>
        <tbody>
          {interviewAnswers.map((item, index) => {
            return <InterviewSheetAnswerRow key={index} item={item} />;
          })}
        </tbody>
      </Table>
    </React.Fragment>
  );
});
