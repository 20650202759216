import gql from "graphql-tag";

export const ResetDeliveryProcess = gql`
  mutation ResetDeliveryProcess(
    $patientId: String!
    $createTime: String!
    $medicalDoctorId: String!
  ) {
    resetDeliveryProcess(
      patientId: $patientId
      createTime: $createTime
      doctorId: $medicalDoctorId
    ) {
      succeed
    }
  }
`;
