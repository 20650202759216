// library
import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Hub } from "aws-amplify";

// util, resorce
import { japaneseList } from "../../../Resources/japaneseList";
import {
  client,
  DeleteAppointmentSlot,
  deleteRepeatedlyAppointmentSlots,
  IsFinishRepeatedlySlots,
} from "../../../graphql";
import styled from "styled-components";
import { getPhoneTextWithBrackets } from "../../../Utils/DisplayLogic";
import {
  showCustomWeek,
  byDayFromStringToNumber,
  formatDay,
  formatPeriod,
} from "../../../Utils/DateUtil";

import DialogTableItem from "../../molecules/dialogs/DialogTableItem";
import EventBaseItem from "./eventDetails/EventBaseItem";
import EventAppointmentItem from "./eventDetails/EventAppointmentItem";

import { withStyles } from "@material-ui/core/styles";
import { MEDICAL_METHOD } from "../../../Utils/const";
import { LoadingScreenForDialog } from "../../molecules/others/LoadingScreenForDialog";

const EventDetailResource = japaneseList.Components.Calendar.EventDetail;
const menuMethodNames = {
  online: EventDetailResource.menuMethodNames.j001,
  offline: EventDetailResource.menuMethodNames.j002,
};
const tableItemsTitleWidth = "92px";
const FACILTY_TYPE = { hospital: "hospital", pharmacy: "pharmacy" };

const styles = (theme) => ({
  dialogTitle: {
    width: "810px",
    height: "68px",
    margin: "0",
    padding: "24px 0 24px 24px",
    borderBottom: "1px solid #cccccc",
  },
  dialogContent: {
    width: "810px",
    margin: "0",
    padding: "20px 25px 22px 24px",
  },
  dialogActions: {
    width: "810px",
    height: "70px",
    margin: "0",
    padding: "14px 25px 16px 24px",
    borderTop: "1px solid #cccccc",
    "& button": {
      height: "40px",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonCancel: {
    margin: "0 20px 0 0",
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
  dialogActionsButtonCancel: {
    width: "142px",
    padding: "13px 15px",
    borderRadius: "5px",
  },
  dialogActionsRepetitionButtonCancel: {
    width: "217px",
    padding: "13px 15px",
    borderRadius: "5px",
  },
  dialogActionsSlotButtonCancel: {
    width: "173px",
    padding: "13px 15px",
    borderRadius: "5px",
  },
  buttonClose: {
    backgroundColor: "#808080",
    boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
  dialogActionsButtonClose: {
    width: "120px",
    margin: "0",
    padding: "13px 39px",
    borderRadius: "5px",
  },
  toAppointmentList: {
    padding: "0",
    color: "#2563C0",
    fontSize: "14px",
    textDecoration: "underline",
    marginRight: "7px",
    "&:hover": {
      backgroundColor: "inherit",
      textDecoration: "underline",
    },
    "&:active": {
      backgroundColor: "inherit",
      textDecoration: "underline",
    },
  },
  dialogPaper: {
    maxWidth: "none",
    maxHeight: "100%",
    width: "810px",
  },
  preWrap: {
    whiteSpace: "pre-wrap",
  },
  currentAppointmentTitle: {
    width: "100%",
    borderTop: "1px #cccccc solid",
    fontSize: "14px",
    fontWeight: "600",
    height: "36px",
    paddingTop: "17px",
  },
  inline: {
    display: "inline-block",
  },
  finishButton: {
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 1px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
});

const DialogTitleText = styled.div`
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
`;

const FontNormal = styled.span`
  font-size: ${(p) => p.size || "14px"};
`;

const FontNormalBold = styled.span`
  font-size: ${(p) => p.size || "12px"};
  font-weight: bold;
`;

const ButtonCustom = withStyles((_) => ({
  root: {
    marginTop: "12px",
    minHeight: "0",
  },
}))(Button);

class EventDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCanceledEventPopup: false,
      isLoading: false,
    };
  }

  linktoAppointmentList = (e) => {
    this.props.history.push("/appointment");
  };

  gotoAppointmentUnapprovedList = (e) => {
    this.props.history.push("/appointment/unapproved");
  };

  cancelEvent = async () => {
    const { event } = this.props;
    try {
      this.setState({ disable: true, isLoading: true });
      const payload = {
        from: event.start.toISOString(),
        menuMethod: event.menu.medicalMethod,
        medicalDoctorId:
          event.menu.medicalMethod === MEDICAL_METHOD.ONLINE
            ? event.menu.doctorId
            : null,
      };
      const { errors } = await client.mutate({
        mutation: DeleteAppointmentSlot,
        variables: payload,
      });
      if (errors) {
        this._overlapError(EventDetailResource.cancelEvent.j001);
        return;
      }
      this.props.removeEvent(
        event.start,
        event.hospitalId,
        event.menu.medicalMethod
      );
      this.setState({ openCanceledEventPopup: true });
    } catch (error) {
      this._overlapError(EventDetailResource.cancelEvent.j002);
    } finally {
      this.setState({ disable: false, isLoading: false });
    }
  };

  deleteSubsequentEvent = async () => {
    const { start, slotRepetition } = this.props.event;
    try {
      this.setState({ disable: true, isLoading: true });
      const payload = {
        slotRepetitionId: slotRepetition.slotRepetitionId,
        deleteFromDate: start.toISOString(),
      };
      const res = await client.mutate({
        mutation: deleteRepeatedlyAppointmentSlots,
        variables: payload,
      });
      if (res.errors) {
        this._overlapError(EventDetailResource.cancelEvent.j001);
        return;
      }
      if (!!res.data && !!res.data.deleteRepeatedlyAppointmentSlots) {
        this.setState({ duringPolling: true });
        const err = await this._polling(slotRepetition.slotRepetitionId);
        if (!this.state.duringPolling) return;
        this.setState({ duringPolling: false });
        if (err) {
          this._overlapError(err);
        } else {
          this.props.removeEvents();
          this.setState({ openCanceledEventPopup: true });
        }
        return;
      }
    } catch (error) {
      this._overlapError(EventDetailResource.cancelEvent.j002);
    } finally {
      this.setState({ disable: false, isLoading: false });
    }
  };

  sleep = (n) => new Promise((resolve) => setTimeout(resolve, n));

  _isProcessing = async (slotRepetitionId) => {
    const { data, errors } = await client.query({
      query: IsFinishRepeatedlySlots,
      variables: { slotRepetitionId },
    });

    if (!!errors) {
      const { errorInfo, message } = errors[0];
      if (errorInfo.batchStatus === "DELETE_ERROR") {
        if (errorInfo.code === "E02") {
          return [true, EventDetailResource.cancelEvent.j001];
        }
        if (errorInfo.code === "E03") {
          return [true, EventDetailResource.cancelEvent.j003];
        }
      }
      throw new Error(message);
    } else if (!!data && !!data.isFinishRepeatedlySlots) {
      const status = data.isFinishRepeatedlySlots;
      if (status === "RUNNING") {
        return [false, undefined];
      } else if (status === "DELETE_WAITING") {
        return [false, undefined];
      } else if (status === "DONE") {
        return [true, undefined];
      }
      throw new Error();
    }
    return [true, undefined];
  };

  _polling = async (slotRepetitionId) => {
    while (true) {
      await this.sleep(1000);
      const [isFinish, error] = await this._isProcessing(slotRepetitionId);
      if (isFinish) return error;
      if (!this.state.duringPolling) await this.sleep(1000);
    }
  };

  _overlapError = (message) => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message, level: "error" } },
      "Appointment"
    );
  };

  _showRepetitionFreqency = ({ frequency, isCustom }) => {
    const langList =
      japaneseList.Components.Calendar.EventDetail._showRepetitionFreqency;
    if (isCustom) return langList.j001;
    if (frequency === "daily") return langList.j002;
    if (frequency === "weekly") return langList.j003;
    if (frequency === "monthly") return langList.j004;
    if (frequency === "yearly") return langList.j005;
  };

  _showRepetitionEndDate = ({ endDate }) => {
    const langList =
      japaneseList.Components.Calendar.EventDetail._showRepetitionEndDate;
    if (!endDate) return langList.j001;
    return formatDay(endDate);
  };

  onClose = () => {
    this.setState({ duringPolling: false });
    this.props.handleTableOverflow(false);
    this.props.closeEventDetailPopup();
  };

  render() {
    const { event, classes, selectDoctor, facilityType } = this.props;
    if (event) {
      const { start, end, slot, appointment, slotRepetition } = event;
      let byDay = [];
      if (!!slotRepetition) {
        byDay = byDayFromStringToNumber(slotRepetition.byDay);
      }

      let appointmentsText = null;
      let appointmentsTable = [];
      if (event) {
        if (event.appointments && event.appointments.length) {
          appointmentsText = event.appointments
            .map(
              (a) =>
                `${a.patient.familyName} ${a.patient.givenName} ${
                  a.patient.phoneNumber
                    ? getPhoneTextWithBrackets(a.patient.phoneNumber)
                    : ""
                }`
            )
            .join("、");
          appointmentsTable = event.appointments;
        }
        if (event.appointment) {
          appointmentsText = getPhoneTextWithBrackets(
            event.appointment.patient.phoneNumber
          );
          appointmentsTable.push(event.appointment);
        }
      }

      return (
        <div>
          <Dialog
            disableBackdropClick
            open={event !== null}
            onClose={this.onClose}
            aria-labelledby="form-dialog-title"
            PaperProps={{
              classes: appointmentsTable ? { root: classes.dialogPaper } : {},
            }}
          >
            <DialogTitle className={classes.dialogTitle}>
              <DialogTitleText>
                {EventDetailResource.render.div.Dialog.DialogTitle.j001}
              </DialogTitleText>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <Grid
                container
                direction={appointmentsTable ? "column" : "row"}
                spacing={0}
              >
                <EventBaseItem
                  items={[
                    {
                      content: (
                        <FontNormalBold>
                          {
                            EventDetailResource.render.div.Dialog.DialogContent
                              .Grid.Fragment.Grid.j009
                          }
                        </FontNormalBold>
                      ),
                      width: tableItemsTitleWidth,
                    },
                    {
                      content: (
                        <FontNormal className={classes.preWrap}>
                          {slot &&
                          slot.menu.medicalMethod === MEDICAL_METHOD.OFFLINE
                            ? EventDetailResource.render.div.Dialog
                                .DialogContent.Grid.Fragment.Grid.j011
                            : selectDoctor.name}
                        </FontNormal>
                      ),
                    },
                  ]}
                />

                <EventBaseItem
                  items={[
                    {
                      content: (
                        <FontNormalBold>
                          {
                            EventDetailResource.render.div.Dialog.DialogContent
                              .Grid.Fragment.Grid.j010
                          }
                        </FontNormalBold>
                      ),
                      width: tableItemsTitleWidth,
                    },
                    {
                      content: (
                        <FontNormal className={classes.preWrap}>
                          {formatPeriod(start, end)}
                        </FontNormal>
                      ),
                    },
                  ]}
                />

                <EventBaseItem
                  items={[
                    {
                      content: (
                        <FontNormalBold>
                          {
                            EventDetailResource.render.div.Dialog.DialogContent
                              .Grid.Fragment.Grid.j001
                          }
                        </FontNormalBold>
                      ),
                      width: tableItemsTitleWidth,
                    },
                    {
                      content: (
                        <FontNormal>
                          {slot
                            ? menuMethodNames[slot.menu.medicalMethod]
                            : menuMethodNames[appointment.menu.medicalMethod]}
                        </FontNormal>
                      ),
                    },
                  ]}
                />

                {slot && slot.menu.medicalMethod === MEDICAL_METHOD.OFFLINE && (
                  <EventBaseItem
                    items={[
                      {
                        content: (
                          <FontNormalBold>
                            {
                              EventDetailResource.render.div.Dialog
                                .DialogContent.Grid.Fragment.Grid.j002
                            }
                          </FontNormalBold>
                        ),
                        width: tableItemsTitleWidth,
                      },
                      {
                        content: (
                          <FontNormal>{`${event.slot.capacity}${EventDetailResource.render.div.Dialog.DialogContent.Grid.Fragment.Grid.j003}`}</FontNormal>
                        ),
                      },
                    ]}
                  />
                )}
                <React.Fragment>
                  {slotRepetition && (
                    <EventBaseItem
                      items={[
                        {
                          content: (
                            <FontNormalBold>
                              {
                                EventDetailResource.render.div.Dialog
                                  .DialogContent.Grid.Fragment.Grid.j007
                              }
                            </FontNormalBold>
                          ),
                          width: tableItemsTitleWidth,
                          valign: "top",
                          paddingTop: "4px",
                        },
                        {
                          content: (
                            <FontNormal>
                              {slotRepetition.isCustom
                                ? `${this._showRepetitionFreqency(
                                    slotRepetition
                                  )}${showCustomWeek(byDay)}`
                                : this._showRepetitionFreqency(slotRepetition)}
                            </FontNormal>
                          ),
                        },
                      ]}
                    />
                  )}

                  {slotRepetition && (
                    <EventBaseItem
                      items={[
                        {
                          content: (
                            <FontNormalBold>
                              {
                                EventDetailResource.render.div.Dialog
                                  .DialogContent.Grid.Fragment.Grid.j008
                              }
                            </FontNormalBold>
                          ),
                          width: tableItemsTitleWidth,
                          valign: "top",
                          paddingTop: "4px",
                        },
                        {
                          content: (
                            <FontNormal>
                              {this._showRepetitionEndDate(slotRepetition)}
                            </FontNormal>
                          ),
                        },
                      ]}
                    />
                  )}
                  {
                    <div className={classes.currentAppointmentTitle}>
                      {
                        EventDetailResource.render.div.Dialog.DialogContent.Grid
                          .TableTile.j001
                      }
                      {event.appointments && (
                        <div className={classes.inline}>
                          {"："}
                          {event.appointments.length}
                          {
                            EventDetailResource.render.div.Dialog.DialogContent
                              .Grid.TableTile.j002
                          }
                        </div>
                      )}
                    </div>
                  }
                  {((event.appointments && event.appointments.length) ||
                    event.appointment) && (
                    <DialogTableItem
                      items={[
                        {
                          content: (
                            <EventAppointmentItem
                              appointments={event.appointments}
                              appointmentsTable={appointmentsTable}
                              onOverflowChange={this.props.handleTableOverflow}
                            />
                          ) || <FontNormal>{appointmentsText}</FontNormal>,
                        },
                      ]}
                    ></DialogTableItem>
                  )}

                  {event.appointments && event.appointments.length > 0 && (
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container justify={"flex-end"}>
                          <ButtonCustom
                            className={classes.toAppointmentList}
                            disableRipple
                            onClick={this.linktoAppointmentList}
                          >
                            {facilityType === FACILTY_TYPE.hospital
                              ? EventDetailResource.render.div.Dialog
                                  .DialogActions.Button.j005
                              : EventDetailResource.render.div.Dialog
                                  .DialogActions.Button.j008}
                          </ButtonCustom>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {!appointment && !event.appointments.length && slotRepetition && (
                <Button
                  variant="raised"
                  onClick={this.deleteSubsequentEvent}
                  color="primary"
                  disabled={this.state.disable}
                  className={`${classes.buttonCancel} ${classes.dialogActionsRepetitionButtonCancel}`}
                >
                  {
                    EventDetailResource.render.div.Dialog.DialogActions.Button
                      .j006
                  }
                </Button>
              )}
              {!appointment && !event.appointments.length && (
                <Button
                  variant="raised"
                  onClick={this.cancelEvent}
                  color="primary"
                  disabled={this.state.disable}
                  className={`${classes.buttonCancel} ${
                    slotRepetition
                      ? classes.dialogActionsSlotButtonCancel
                      : classes.dialogActionsButtonCancel
                  }`}
                >
                  {!slotRepetition
                    ? EventDetailResource.render.div.Dialog.DialogActions.Button
                        .j002
                    : EventDetailResource.render.div.Dialog.DialogActions.Button
                        .j007}
                </Button>
              )}
              <Button
                variant="raised"
                onClick={this.onClose}
                className={`${classes.buttonClose} ${classes.dialogActionsButtonClose}`}
              >
                {
                  EventDetailResource.render.div.Dialog.DialogActions.Button
                    .j003
                }
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.openCanceledEventPopup}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <DialogTitle id="form-dialog-title">
              {EventDetailResource.render.div.Dialog.DialogTitle.j002}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  {
                    EventDetailResource.render.div.Dialog.DialogContent.Grid
                      .Grid.j007
                  }
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="raised"
                onClick={() => {
                  this.setState({ openCanceledEventPopup: false });
                  this.props.closeEventDetailPopup();
                }}
                color="primary"
                className={classes.finishButton}
              >
                {
                  EventDetailResource.render.div.Dialog.DialogActions.Button
                    .j003
                }
              </Button>
            </DialogActions>
          </Dialog>
          <LoadingScreenForDialog isLoading={this.state.isLoading} />
        </div>
      );
    }
    return null;
  }
}

export default withStyles(styles)(EventDetail);
