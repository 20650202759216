import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Auth } from "aws-amplify";
import { japaneseList } from "../Resources/japaneseList";
import { isLnln } from "./checkLnln";
import TagManager from "react-gtm-module";

async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder("utf-8").encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => ("00" + b.toString(16)).slice(-2))
    .join("");
  return hashHex;
}

const containerId = isLnln()
  ? process.env.REACT_APP_GTM_CONTAINER_ID_LNLN
  : process.env.REACT_APP_GTM_CONTAINER_ID;

TagManager.initialize({
  gtmId: containerId,
});

const Resource = japaneseList.Utils.GoogleTagManager;
class GoogleTagManager extends Component {
  componentDidMount() {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    );
    this.logCustomDimensions();
  }

  componentDidUpdate() {
    const {
      location: { pathname, search },
    } = this.props;

    this.logPageChange(pathname, search);
    this.logCustomDimensions();
  }

  logPageChange = (pathname, search = "") => {
    const page = pathname + search;
    const { location } = window;
    const pageDataLayer = {
      dataLayer: {
        event: "pageView",
        page: page,
        location: `${location.origin}${page}`,
      },
    };
    TagManager.dataLayer(pageDataLayer);
  };

  logCustomDimensions = async () => {
    const user = await Auth.currentUserPoolUser().catch((err) => {
      return null;
    });
    let loggedIn = !!user;

    const dimensionArgs = {
      dataLayer: {
        event: "customDimension",
        dimension1: loggedIn
          ? Resource.logCustomDimensions.j001
          : Resource.logCustomDimensions.j002,
        dimension2: loggedIn
          ? Resource.logCustomDimensions.j003
          : Resource.logCustomDimensions.j004,
        dimension3: Resource.logCustomDimensions.j005,
        dimension4: "Web",
      },
    };
    TagManager.dataLayer(dimensionArgs);

    if (loggedIn) {
      const uid = await sha256(user.username);
      const dimensionLoggedInArgs = {
        dataLayer: {
          event: "customDimension",
          dimension8: uid,
          uid: uid,
        },
      };
      TagManager.dataLayer(dimensionLoggedInArgs);
    }
  };

  render() {
    return null;
  }
}

const RouteTracker = () => <Route component={GoogleTagManager} />;

export default {
  GoogleTagManager,
  RouteTracker,
};
