import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { japaneseList } from "../../../Resources/japaneseList";
import { withRouter } from "react-router";
import { client, GetUnderMaintenanceAppointments } from "../../../graphql";
import { APPOINTMENT_STATUS } from "../../../Utils/const";
import querystring from "querystring";
import { formatDate, endMonthOfNextYear } from "../../../Utils/DateUtil";
import { startOfDay } from "date-fns";
import ImportantNoticeContext from "../../../contexts/ImportantNoticeContext";

const styles = (theme) => ({
  importantNotice: {
    padding: "16px 0 16px 30px",
  },
  noImportantNotice: {
    paddingBottom: "16px",
  },
  MedicalaAppointmentUl: {
    margin: "0",
    padding: "0",
  },
  importantNoticeTittle: {
    background: "#EF5E5E 0% 0% no-repeat padding-box",
    height: "16px",
    color: "#FFFFFF",
    fontFamily:
      "Hiragino Kaku Gothic ProN, HiraKakuProN-W3, Yu Gothic, sans-serif",
    fontSize: "10px",
    textAlign: "center",
    float: "left",
    marginTop: "3px",
    padding: "0 4px",
    display: "inline-block",
    listStyleType: "none",
  },
  importantNoticeTips: {
    fontSize: "14px",
    color: "#393939",
    fontFamily:
      "Hiragino Kaku Gothic ProN, HiraKakuProN-W3, Yu Gothic, sans-serif",
    padding: "0 10px 0 16px",
    float: "left",
    display: "inline-block",
    listStyleType: "none",
  },
  importantNoticeLink: {
    fontFamily:
      "Hiragino Kaku Gothic ProN, HiraKakuProN-W3, Yu Gothic, sans-serif",
    fontSize: "14px",

    color: "#2563C0",
    listStyleType: "none",
  },
  Link: {
    color: "#2563C0",
  },
  doNotDisplay: {
    display: "none",
  },
  underline: {
    boxShadow: "0px 1px 0px #CCCCCC",
    marginLeft: "-30px",
    height: "15px",
  },
});
const importantNoticeResource = japaneseList.importantNotice;

const ImportantNotice = (props) => {
  const importantNoticeContext = useContext(ImportantNoticeContext);
  const [displayMedicalAppointment, setDisplayMedicalAppointment] =
    useState(false);
  const [displayMedicalUnapproved, setDisplayMedicalUnapproved] =
    useState(false);
  const [currentDay, setCurrentDay] = useState(
    formatDate(startOfDay(new Date()).toISOString(), "yyyy-MM-dd")
  );

  useEffect(() => {
    handleDisplayImpotantNotice();
  }, [importantNoticeContext.signalToRefreshImportantNotice]);

  const handleDisplayImpotantNotice = () => {
    if (props.location.pathname === "/medical") {
      setDisplayMedicalAppointment(false);
      setDisplayMedicalUnapproved(false);
    } else {
      setImportantNotice();
    }
  };

  const setImportantNotice = () => {
    client
      .query({
        query: GetUnderMaintenanceAppointments,
        variables: {
          start: startOfDay(new Date()),
          end: endMonthOfNextYear(),
          status: [
            APPOINTMENT_STATUS.BEFORE_EXAM,
            APPOINTMENT_STATUS.UNAPPROVED,
          ],
          isFilterUnderMaintenance: true,
        },
      })
      .then((data) => {
        if (
          data &&
          data.data &&
          data.data.appointments &&
          data.data.appointments.items &&
          data.data.appointments.items.length
        ) {
          const sortedItems = data.data.appointments.items
            .slice()
            .sort((curr, next) => {
              if (curr.from < next.from) return -1;
              return 1;
            });
          const medicalaAppointment = sortedItems.find((item) => {
            return item.status === APPOINTMENT_STATUS.BEFORE_EXAM;
          });
          setDisplayMedicalAppointment(medicalaAppointment !== undefined);
          setCurrentDay(
            medicalaAppointment
              ? formatDate(medicalaAppointment.from, "yyyy-MM-dd")
              : null
          );

          const medicalaUnapproved = sortedItems.find((item) => {
            return item.status === APPOINTMENT_STATUS.UNAPPROVED;
          });
          setDisplayMedicalUnapproved(medicalaUnapproved !== undefined);
        } else {
          setDisplayMedicalAppointment(false);
          setDisplayMedicalUnapproved(false);
        }
      })
      .catch((e) => {
        // TODO: 未ログイン時にGraphQLのQueryが実行されてエラーになってしまうので、一旦握り潰して対処している。適切なエラーハンドリングで修正する。
        console.error("Error GetUnderMaintenanceAppointments", e);
      });
  };

  const { classes } = props;
  return (
    <div
      className={
        displayMedicalAppointment || displayMedicalUnapproved
          ? classes.importantNotice
          : classes.noImportantNotice
      }
    >
      <ul
        className={
          displayMedicalAppointment
            ? classes.MedicalaAppointmentUl
            : classes.doNotDisplay
        }
      >
        <li className={classes.importantNoticeTittle}>
          {importantNoticeResource.j001}
        </li>
        <li className={classes.importantNoticeTips}>
          {importantNoticeResource.j002}
        </li>
        <li
          onClick={() => {
            props.history.push(
              `/appointment?${querystring.stringify({ currentDay })}`
            );
          }}
          className={classes.importantNoticeLink}
        >
          <Link className={classes.Link} to="">
            {importantNoticeResource.j003}
          </Link>
        </li>
      </ul>

      <ul
        className={
          displayMedicalUnapproved
            ? classes.MedicalaAppointmentUl
            : classes.doNotDisplay
        }
      >
        <li className={classes.importantNoticeTittle}>
          {importantNoticeResource.j001}
        </li>
        <li className={classes.importantNoticeTips}>
          {importantNoticeResource.j004}
        </li>
        <li className={classes.importantNoticeLink}>
          <Link className={classes.Link} to="/appointment/unapproved">
            {importantNoticeResource.j005}
          </Link>
        </li>
      </ul>

      <div
        className={
          displayMedicalAppointment || displayMedicalUnapproved
            ? classes.underline
            : classes.doNotDisplay
        }
      ></div>
    </div>
  );
};

export default withStyles(styles)(withRouter(ImportantNotice));
