import gql from "graphql-tag";

export const GetAppointmentTimeSetting = gql`
  query GetAppointmentTimeSetting {
    appointmentSetting {
      onlineMaximumTimeframe
      onlineMinimumTimeframe
      offlineMaximumTimeframe
      offlineMinimumTimeframe
    }
  }
`;