import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { MEDICAL_METHOD } from "../../../Utils/const";

const Resource = japaneseList.Components.Payment.HospitalPaymentList;

const TblCellDoctor = styled.td`
  text-align: left;
  padding-left: 10px;
  border-left: 1px solid #cccccc;
  width: 170px;
`;

const HospitalPaymentRowDoctor = (props) => {
  const { doctor, menu } = props.appo;

  return (
    <TblCellDoctor>
      {menu && menu.medicalMethod === MEDICAL_METHOD.OFFLINE
        ? Resource.render.div.offline
        : `${doctor.familyName} ${doctor.givenName}`}
    </TblCellDoctor>
  );
};

export default HospitalPaymentRowDoctor;
