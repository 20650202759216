import gql from "graphql-tag";

export const GetPatient = gql`
  query GetPatient($phoneNumber: ID!) {
    patient(phoneNumber: $phoneNumber) {
      familyName
      givenName
      familyNameKana
      givenNameKana
      birthdate
      cardStatus
      patientId
      organizationId
      address {
        postalCode
        prefectureCode
        addressCity
        addressStreetNumber
      }
      gender
      phoneNumber
    }
  }
`;
