import React from "react";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import { OPERATOR_TYPE } from "../../../Utils/const";

const Resource = japaneseList.atoms.others.Operator;

const OperatorName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 126px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

export const Operator = ({ operationHistory }) => {
  const operatorName =
    operationHistory.operatorType === OPERATOR_TYPE.PATIENT
      ? Resource.patient
      : operationHistory.operatorType === OPERATOR_TYPE.DOCTOR
      ? operationHistory.operatorDoctorName
      : operationHistory.operatorType === OPERATOR_TYPE.SYSTEM
      ? Resource.system
      : operationHistory.operatorType === OPERATOR_TYPE.ADMINISTRATOR
      ? Resource.administrator
      : "";
  return <OperatorName>{operatorName}</OperatorName>;
};
