// --------------------------------------------------
//
// file upload
//
// --------------------------------------------------

// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// personal modules
// --------------------------------------------------
import FileuploadDialogContent from "./FileuploadDialogContent";
import DialogFileAccessError from "../../../molecules/dialogs/DialogFileAccessError";
import { japaneseList } from "../../../../Resources/japaneseList";
import { APPOINTMENT_STATUS, MEDICAL_METHOD } from "../../../../Utils/const";
import ThumbnailList from "../../../molecules/thumbnail/ThumbnailList";
import Person from "../../../../images/account_circle-24px.svg";
const Resources = japaneseList.Components.Appointment.FileuploadDialog;
const FileAccessErrorResources =
  japaneseList.Components.Appointment.FileUploadAccessErrorDialog;

const _ = require("lodash");

const fns = require("date-fns");

const MAX_NUMBER_UPLOAD_FILES = 5;

// style
// --------------------------------------------------
const styles = (theme) => ({
  dialogTitle: {
    width: "810px",
    height: "68px",
    margin: "0",
    padding: "24px 0 24px 24px",
    borderBottom: "1px solid #cccccc",
  },
  dialogContent: {
    width: "810px",
    margin: "0",
    padding: "22px 25px 20px 24px",
  },
  dialogActions: {
    width: "810px",
    height: "70px",
    margin: "0",
    padding: "14px 25px 16px 24px",
    borderTop: "1px solid #cccccc",
    "& button": {
      height: "40px",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonOk: {
    backgroundColor: theme.button.ok.backgroundColor,
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
  },
  dialogActionsButtonOk: {
    width: "130px",
    margin: "0 0 0 20px",
    padding: "13px 22px",
    borderRadius: "5px",
  },
  buttonCancel: {
    backgroundColor: "#808080",
    boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
  },
  dialogActionsButtonCancel: {
    width: "120px",
    margin: "0",
    padding: "13px 25px",
    borderRadius: "5px",
  },
  grid: {
    margin: "0 0 20px 0",
    height: "42px",
  },
  icon: {
    padding: "0px 10px 0px 0px",
  },
  content: {
    width: "calc(100% - 102px)",
    color: " #393939",
  },
  textTitle: {
    fontWeight: "bold",
    fontSize: "16px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    color: " #393939",
  },
  textContent: {
    fontSize: "12px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    color: " #393939",
  },
});

const FileAccessErrorTitle = styled.span`
  font-size: 14px;
`;

const DialogTitleText = styled.div`
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
`;

const Container = styled.div`
  width: 475px;
  height: 34px;
  display: grid;
  grid-template-columns: 92px auto;
  grid-gap: 20px;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
`;

const Title = styled.div`
  background-color: #eeeeee;
  padding: 0 0 0 10px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #808080;
  font-weight: bold;
  text-align: left;
  white-space: pre-wrap;
`;

const Content = styled.div`
  margin: 0;
  font-size: 14px;
  color: #393939;
  text-align: left;
  height: 32px;
  line-height: 32px;
  word-break: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 362px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

// class define
// --------------------------------------------------
class FileuploadDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpenningDeletedFile: false };
  }

  // default state
  // --------------------------------------------------
  static defaultProps = {
    open: false,
    onClose: function () {},
    title: Resources.title,
    maxWidth: "md",
    buttonUploadText: Resources.buttonUpload,
    handleClickOk: function () {},
    buttonCloseText: Resources.buttonClose,
    handleClickCancel: function () {},
    Errors: Resources.Errors,
    handleChooseFileClick: function () {},
    fileList: [],
    handleNiceNameChange: function () {},
    handleChooseFileChange: function () {},
    undertaker: Resources.undertaker,
  };

  getNumberOfAvailableS3File = (fileList) => {
    let numberOfAvailableS3File = 0;
    fileList.forEach((file) => {
      if (
        !_.isUndefined(file.file.expiredTime) &&
        fns.isAfter(fns.parseISO(file.file.expiredTime), new Date()) &&
        file.file.status !== "deleted" &&
        file.justUpdatedStatus !== "deleted"
      ) {
        numberOfAvailableS3File += 1;
      }
    });
    return numberOfAvailableS3File;
  };

  handleNotExistS3File = () => {
    this.setState({ isOpenningDeletedFile: true });
  };

  handleCloseFileAccessErrorDialog = () => {
    this.setState({ isOpenningDeletedFile: false });
  };

  // render
  // --------------------------------------------------
  render() {
    const {
      appointmentSelected,
      fileList,
      classes,
      open,
      onClose,
      title,
      maxWidth,
      buttonUploadText,
      handleClickOk,
      buttonCloseText,
      handleClickCancel,
      patient,
      doctor,
      selectSomeFiles,
      thumbnailPatientFiles,
      patientFileExpiredDay,
      undertaker,
      newFileList
    } = this.props;
    // [todo] error handle
    let numberOfAvailableS3File = this.getNumberOfAvailableS3File(fileList);
    const medicalMethod =
      appointmentSelected.menu && appointmentSelected.menu.medicalMethod;
    const appointmentStatus = appointmentSelected.status;
    
    const isShowButtonUpload =
      medicalMethod === MEDICAL_METHOD.ONLINE &&
      [
        APPOINTMENT_STATUS.EXAM_COMPLETED,
        APPOINTMENT_STATUS.PAYMENT_COMPLETED,
      ].includes(appointmentStatus) &&
      numberOfAvailableS3File < MAX_NUMBER_UPLOAD_FILES;

    return (
      <Dialog
        disableBackdropClick
        open={open}
        onClose={onClose}
        maxWidth={maxWidth}
      >
        <DialogTitle className={classes.dialogTitle}>
          <DialogTitleText>{title}</DialogTitleText>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <React.Fragment>
            {patient && (
              <Grid container wrap="nowrap" className={classes.grid}>
                <Grid item className={classes.icon}>
                  <img src={Person} alt="" />
                </Grid>
                <Grid item xs className={classes.content}>
                  <Typography className={classes.textTitle}>
                    {patient.familyName} {patient.givenName}
                  </Typography>
                  <Typography className={classes.textContent}>
                    {patient.familyNameKana} {patient.givenNameKana}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {doctor && (
              <Container>
                <Title>{undertaker}</Title>
                <Content>
                  {doctor.familyName} {doctor.givenName}
                </Content>
              </Container>
            )}
            {thumbnailPatientFiles && (
              <ThumbnailList
                title={Resources.thumbnailListTitle}
                contentNoFile={Resources.contentNoFile}
                expiredDay={`${Resources.expiredDay.j001} ${patientFileExpiredDay}${Resources.expiredDay.j002}`}
                thumbnailList={thumbnailPatientFiles}
                handleNotExistS3File={this.handleNotExistS3File}
              />
            )}
            <FileuploadDialogContent
              appointmentSelected={appointmentSelected}
              fileList={this.props.fileList}
              newFileList={this.props.newFileList}
              handleNiceNameChange={(name, index) =>
                this.props.handleNiceNameChange(name, index)
              }
              handleChooseFileChange={(file, fileData, index) =>
                this.props.handleChooseFileChange(file, fileData, index)
              }
              handleDeleteFile={(deletedFile, existFileList) =>
                this.props.handleDeleteFile(deletedFile, existFileList)
              }
              handleNotExistS3File={this.handleNotExistS3File}
            />
          </React.Fragment>
          <DialogFileAccessError
            isOpen={this.state.isOpenningDeletedFile}
            title={
              <FileAccessErrorTitle>
                {FileAccessErrorResources.title}
              </FileAccessErrorTitle>
            }
            cancelText={FileAccessErrorResources.buttonClose}
            handleClose={this.handleCloseFileAccessErrorDialog}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            id="close-shared-file-diaglog-button"
            className={`${classes.buttonCancel} ${classes.dialogActionsButtonCancel}`}
            onClick={handleClickCancel}
          >
            {buttonCloseText}
          </Button>
            {isShowButtonUpload && (
              <Button
                className={`
                  ${
                    selectSomeFiles ? classes.buttonOk : classes.buttonCancel
                  } ${classes.dialogActionsButtonOk}`}
                onClick={(e) => {
                  selectSomeFiles && handleClickOk(e);
                }}
                variant="raised"
                disabled={newFileList && newFileList.length === 0}
              >
                {buttonUploadText}
              </Button>
            )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(FileuploadDialog);
