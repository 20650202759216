import React from "react";
import styled from "styled-components";

import { Favicon } from "../atoms/others/Favicon";
import { TitleTag } from "../atoms/others/TitleTag";
import { Main } from "../atoms/others/Main";
import { Footer } from "../organisms/footers/Footer";
import LoadingScreen from "../molecules/others/LoadingScreen";

import { japaneseList } from "../../Resources/japaneseList";
import { isLnln } from "../../Utils/checkLnln";

const RootWrapper = styled.div`
  background-color: #fafafa;
`;

export default ({ main, title, isLoading }) => {
  const serviceName = japaneseList.service.name;
  const _title = title ? title + " | " + serviceName : serviceName;
  const servicePath = isLnln() ? "lnln" : "carada";

  return (
    <RootWrapper>
      <Favicon servicePath={servicePath} />
      <TitleTag title={_title} />
      <Main>{main}</Main>
      <Footer />
      <LoadingScreen isLoading={isLoading} />
    </RootWrapper>
  );
};
