import gql from "graphql-tag";

export const RejectAppointment = gql`
  mutation RejectAppointment(
    $patientId: String!
    $createTime: String!
    $rejectComment: String!
    $rejectSelectedOption: Int!
    $medicalDoctorId: String!
  ) {
    rejectAppointment(
      patientId: $patientId
      createTime: $createTime
      rejectComment: $rejectComment
      rejectSelectedOption: $rejectSelectedOption
      doctorId: $medicalDoctorId
    ) {
      succeed
    }
  }
`;
