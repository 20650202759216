import React, { useState } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import PatientSearch from "./PatientSearch";
import Appointment from "./Appointment";
import LinkA from "../../../atoms/links/LinkA";
import DialogCancelConfirm from "../../../molecules/dialogs/DialogCancelConfirm";
import { japaneseList } from "../../../../Resources/japaneseList";
import { FLOW } from "./Constants";

const AppointmentDesignationResource =
  japaneseList.Components.Appointment.Stepper.AppointmentDesignation;

const styles = (theme) => {
  return {
    dialogTitle: {
      width: "810px",
      height: "68px",
      margin: "0",
      padding: "24px 0 24px 24px",
      borderBottom: "1px solid #cccccc",
    },
    dialogContent: {
      width: "810px",
      margin: "0",
      padding: "20px 25px 20px 24px",
    },
    dialogActions: {
      width: "810px",
      height: "70px",
      margin: "0",
      padding: "14px 25px 16px 24px",
      borderTop: "1px solid #cccccc",
      "& button": {
        height: "40px",
      },
      "& span": {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#ffffff",
      },
    },
    buttonBack: {
      fontSize: "14px",
      position: "absolute",
      left: "24px",
      color: "#2563c0",
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none",
      },
    },
    buttonOk: {
      backgroundColor: theme.button.ok.backgroundColor,
      boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
      "&:hover": {
        backgroundColor: theme.button.ok.backgroundColorHover,
      },
    },
    dialogActionsButtonOk: {
      width: "120px",
      margin: "0",
      padding: "13px 46px",
      borderRadius: "5px",
    },
    buttonCancel: {
      backgroundColor: "#808080",
      boxShadow: `0px 2px 0px ${theme.button.cancel.shadowColor}`,
      "&:hover": {
        backgroundColor: theme.button.cancel.backgroundColorHover,
      },
    },
    dialogActionsButtonCancel: {
      width: "120px",
      margin: "0 20px 0 0",
      padding: "13px 25px",
      borderRadius: "5px",
    },
  };
};

const DialogTitleText = styled.div`
  height: 20px;
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
`;

const AppointmentDesignation = (props) => {
  const { classes } = props;
  const [displayDialogCancelConfirm, setDisplayDialogCancelConfirm] =
    useState(false);
  const [isUnselectedMenuError, setIsUnselectedMenuError] = useState(false);

  const handleBack = () => {
    if (props.patient) {
      props.setPatient(null);
      props.handleAppointmentChange(null);
      props.setSelectSlotWithBackupSelectSlot();
    } else {
      props.step(0);
    }
  };

  const validateAndSubmitForm = () => {
    if (validateForm()) {
      props.step(2, FLOW.APPOINTMENT, false);
    }
  };

  const validateForm = () => {
    const validationResult = {
      isMenuSelected:
        (props.appointment && props.appointment.selectedMenu) || false,
    };
    setIsUnselectedMenuError(!validationResult.isMenuSelected);
    return !Object.values(validationResult).some((x) => x === false);
  };

  const openDialogCancelConfirm = () => {
    setDisplayDialogCancelConfirm(true);
  };

  const closeDialogCancelConfirm = () => {
    setDisplayDialogCancelConfirm(false);
  };

  const handlePhoneNumberChange = (phoneNumber) => {
    setIsUnselectedMenuError(false);
    props.handlePhoneNumberChange(phoneNumber);
  };

  return (
    <React.Fragment>
      <DialogTitle className={classes.dialogTitle}>
        <DialogTitleText>
          {AppointmentDesignationResource.DialogTitle.title}
        </DialogTitleText>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <PatientSearch
          setPatient={props.setPatient}
          phoneNumber={props.phoneNumber}
          handlePhoneNumberChange={handlePhoneNumberChange}
          step={props.step}
          selectDoctor={props.selectDoctor}
        />
        {props.patient && (
          <Appointment
            step={props.step}
            patient={props.patient}
            hasCharge={props.hasCharge}
            slotFrameLengthes={props.slotFrameLengthes}
            slot={props.slot}
            hospital={props.hospital}
            handleAppointmentChange={props.handleAppointmentChange}
            isUnselectedMenuError={isUnselectedMenuError}
          />
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <LinkA className={classes.buttonBack} onClick={() => handleBack()}>
          {AppointmentDesignationResource.DialogActions.button.j001}
        </LinkA>
        <Button
          className={`${classes.buttonCancel} ${classes.dialogActionsButtonCancel}`}
          onClick={() => openDialogCancelConfirm()}
        >
          {AppointmentDesignationResource.DialogActions.button.j002}
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={`${classes.buttonOk} ${classes.dialogActionsButtonOk}`}
          onClick={() => validateAndSubmitForm()}
          disabled={props.patient ? false : true}
        >
          {AppointmentDesignationResource.DialogActions.button.j003}
        </Button>
      </DialogActions>
      <DialogCancelConfirm
        open={displayDialogCancelConfirm}
        closeDialogCancelConfirm={closeDialogCancelConfirm}
        handleClose={props.closeScheduleSelectionPopup}
      />
    </React.Fragment>
  );
};

export default withStyles(styles)(AppointmentDesignation);
