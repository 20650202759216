import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { japaneseList } from "../../../../Resources/japaneseList";
import { OverflowDetector } from "react-overflow";
import styled from "styled-components";
import { parseISO } from "date-fns";
import { APPOINTMENT_STATUS, MEDICAL_MENU } from "../../../../Utils/const";
import { formatTime } from "../../../../Utils/DateUtil";
import NumberFormat from "react-number-format";
import Button from "@material-ui/core/Button";
import { patientDetail } from "../../../../lib/window/PatientDetail";

const EventDetailResource = japaneseList.Components.Calendar.EventDetail;
const AppointmentListResource =
  japaneseList.Components.Appointment.AppointmentList;

const styles = (theme) => ({
  tableDiv: {
    border: "solid 1px #bbbbbb",
    maxWidth: "none",
    backgroundColor: "#f7f7f7",
    marginTop: "12px",
    overflowY: "auto",
  },
  tbl: {
    fontSize: "12px",
    tableLayout: "fixed",
    width: "auto",
    borderCollapse: "collapse",
    backgroundColor: "#FFF",
  },
  head: {
    backgroundColor: "#ddd",
    textAlign: "center",
    paddingRight: 0,
    fontSize: "12px",
    height: "30px",
  },
  body: {
    textAlign: "center",
    paddingRight: 0,
    fontSize: "13px",
  },
  td: {
    borderRight: "solid 1px #bbbbbb",
    borderTop: "solid 1px #bbbbbb",
    borderBottom: 0,
    wordBreak: "break-all",
    whiteSpace: "pre-wrap",
    height: "38px",
  },
  firstTd: {
    borderRight: "solid 1px #bbbbbb",
    borderTop: "solid 1px #bbbbbb",
    borderBottom: 0,
    borderLeft: 0,
    wordBreak: "break-all",
    whiteSpace: "pre-wrap",
    height: "38px",
  },
  lastTd: {
    width: "50px",
    textAlign: "center",
    borderTop: "solid 1px #bbbbbb",
  },
  redTd: {
    width: "50px",
    textAlign: "center",
    borderTop: "solid 1px #bbbbbb",
    color: "#f26666",
  },
  th: {
    borderRight: "solid 1px #bbbbbb",
    borderTop: 0,
    wordBreak: "break-all",
    whiteSpace: "pre-wrap",
  },
  firstTh: {
    borderRight: "solid 1px #bbbbbb",
    borderTop: 0,
    borderLeft: 0,
    wordBreak: "break-all",
    whiteSpace: "pre-wrap",
  },
  lastTh: {
    borderTop: 0,
    borderRight: 0,
    wordBreak: "break-all",
    whiteSpace: "pre-wrap",
  },
  tblContent: {
    maxHeight: "192px",
  },
  nameCol: {
    width: "100px",
    textAlign: "left",
    paddingLeft: "10px",
  },
  phoneCol: {
    width: "135px",
    textAlign: "left",
    paddingLeft: "10px",
  },
  hourCol: {
    width: "100px",
    textAlign: "left",
    paddingLeft: "10px",
  },
  menuCol: {
    width: "365px",
    textAlign: "left",
    paddingLeft: "10px",
  },
  statusCol: {
    width: "60px",
    textAlign: "center",
  },
  toPatient: {
    padding: "0",
    color: "#2563C0",
    fontSize: "14px",
    textDecoration: "underline",
    marginRight: "7px",
    "&:hover": {
      backgroundColor: "inherit",
      textDecoration: "underline",
    },
    "&:active": {
      backgroundColor: "inherit",
      textDecoration: "underline",
    },
    textAlign: "left",
  },
  iconInsurance: {
    fontSize: "10px",
    border: `1px solid ${theme.color.primary1}`,
  },
  iconNotInsurance: {
    border: `1px solid ${theme.color.notInsurance}`,
    fontSize: "10px",
  },
  headDiv: {
    position: "sticky",
    top: "0",
    left: "0",
    zIndex: "10000000",
    height: "30px",
  },
});

const FontNormal = styled.span`
  font-size: ${(p) => p.size || "14px"};
`;

const FontNormalBorder = styled.span`
  font-size: ${(p) => p.size || "13px"};
  border: 1.5px rgb(180, 229, 229) solid;
  margin-left: 7px;
`;

const FontSmall = styled.div`
  font-size: 10px;
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bbbbbb;
  margin-bottom: -1px;
`;

const ButtonCustom = withStyles((_) => ({
  root: {
    display: "block",
    minWidth: "0",
  },
}))(Button);

const EventAppointmentItem = (props) => {
  const { classes, appointmentsTable } = props;

  return (
    <div className={classes.tableDiv}>
      <div className={classes.headDiv}>
        <table className={classes.tbl}>
          <thead>
            <tr className={classes.head} key={0}>
              <th className={`${classes.firstTh} ${classes.nameCol}`}>
                {
                  EventDetailResource.render.div.Dialog.DialogContent.Grid.Table
                    .th.j001
                }
              </th>
              <th className={`${classes.th} ${classes.phoneCol}`}>
                {
                  EventDetailResource.render.div.Dialog.DialogContent.Grid.Table
                    .th.j002
                }
              </th>
              <th className={`${classes.th} ${classes.hourCol}`}>
                {
                  EventDetailResource.render.div.Dialog.DialogContent.Grid.Table
                    .th.j003
                }
              </th>
              <th className={`${classes.th} ${classes.menuCol}`}>
                {
                  EventDetailResource.render.div.Dialog.DialogContent.Grid.Table
                    .th.j004
                }
              </th>

              <th className={`${classes.lastTh} ${classes.statusCol}`}>
                {
                  EventDetailResource.render.div.Dialog.DialogContent.Grid.Table
                    .th.j005
                }
              </th>
            </tr>
          </thead>
        </table>
        <Border />
      </div>
      <OverflowDetector
        className={classes.tblContent}
        onOverflowChange={props.onOverflowChange}
      >
        <table className={classes.tbl}>
          <tbody>
            {appointmentsTable.map((appointment, index) => {
              return (
                <tr className={classes.body} key={index + 1}>
                  <td className={`${classes.td} ${classes.nameCol}`}>
                    <div>
                      <ButtonCustom
                        className={classes.toPatient}
                        disableRipple
                        onClick={() =>
                          patientDetail.openPatientDetailWindow(
                            appointment.patient.patientId
                          )
                        }
                      >
                        <div>
                          {`${appointment.patient.familyName} ${appointment.patient.givenName}`}
                        </div>
                        <FontSmall>
                          {`${appointment.patient.familyNameKana} ${appointment.patient.givenNameKana}`}
                        </FontSmall>
                      </ButtonCustom>
                    </div>
                  </td>
                  <td className={`${classes.td} ${classes.phoneCol}`}>
                    {appointment.patient && appointment.patient.phoneNumber ? (
                      <NumberFormat
                        value={appointment.patient.phoneNumber}
                        displayType={"text"}
                        format="###-####-####"
                      />
                    ) : (
                      ""
                    )}
                  </td>
                  <td className={`${classes.td} ${classes.hourCol}`}>
                    {appointment.from
                      ? formatTime(parseISO(appointment.from))
                      : ""}
                    ～
                    {appointment.to ? formatTime(parseISO(appointment.to)) : ""}
                  </td>
                  {appointment.menu.medicalMenu &&
                  appointment.menu.medicalMenu ===
                    MEDICAL_MENU.EXAMINATIONWELFARE ? (
                    <td className={`${classes.td} ${classes.menuCol}`}>
                      <FontNormal>
                        {AppointmentListResource.examinationWelfare.j001}
                      </FontNormal>

                      <FontNormalBorder
                        size="12px"
                        className={classes.iconNotInsurance}
                      >
                        {AppointmentListResource.examinationWelfare.j002}
                      </FontNormalBorder>
                    </td>
                  ) : (
                    <td className={`${classes.td} ${classes.menuCol}`}>
                      <FontNormal>{appointment.menu.displayName}</FontNormal>
                      <FontNormalBorder
                        size="12px"
                        className={
                          appointment.menu.supportsInsuranceTreatment
                            ? classes.iconInsurance
                            : classes.iconNotInsurance
                        }
                      >
                        {appointment.menu.supportsInsuranceTreatment
                          ? AppointmentListResource.insuranceCard.ok
                          : AppointmentListResource.insuranceCard.ng}
                      </FontNormalBorder>
                    </td>
                  )}
                  <td
                    className={
                      appointment.status === APPOINTMENT_STATUS.UNAPPROVED
                        ? `${classes.redTd} ${classes.statusCol}`
                        : `${classes.lastTd} ${classes.statusCol}`
                    }
                  >
                    {appointment.status === APPOINTMENT_STATUS.UNAPPROVED
                      ? EventDetailResource.render.div.Dialog.DialogContent.Grid
                          .Table.td.j001
                      : EventDetailResource.render.div.Dialog.DialogContent.Grid
                          .Table.td.j002}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </OverflowDetector>
    </div>
  );
};

export default withStyles(styles)(EventAppointmentItem);
