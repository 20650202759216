import styled from "styled-components";
import CheckIcon from "../../../images/carada/icon/icon-check.svg";
import CheckIconLnln from "../../../images/lnln/icon/icon-check.svg";
import { japaneseList } from "../../../Resources/japaneseList";
import { isLnln } from "../../../Utils/checkLnln";

const CautionOkResource = japaneseList.Pages.Medical.CautionOK;

const Icon = isLnln() ? CheckIconLnln : CheckIcon;

const Container = styled.div`
  padding-top: 103px;
  margin: 0 16px;
  width: 198px;
  height: 285px;
  background-color: ${(p) => p.theme.color.primaryVariant3};
  text-align: center;
`;

const Img = styled.img`
  display: block;
  margin: 0px auto 10px;
`;

const CautionOKText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #393939;
`;

export const CautionOK = () => {
  return (
    <Container>
      <Img src={Icon} alt={""} />
      <CautionOKText>
        {CautionOkResource.text.j001}
        <br />
        {CautionOkResource.text.j002}
      </CautionOKText>
    </Container>
  );
};
